import { useEffect, useState } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import i18n from '@service/I18nService';
import { Button, Empty, Spin, Switch } from 'antd';
import Input from 'antd/es/input/Input';
import { t } from 'i18next';

import { useCompanyPermission } from '../services/mutation';
import { useAuthPermissions, useGetCompanyPermissions } from '../services/queries';

import edit from '../assets/edit.svg';

import style from '../companyPermissionConfig.module.scss';

// Компонент для поиска разрешений компании
const CompanyPermissionSearchPermissions = () => {
  // Получение параметров запроса
  const { searchParams } = useQueryParam<any, any>();
  const companyId = Number(searchParams?.companyId); // Получение ID компании из параметров запроса
  const { data: dataPermissions, isLoading: dataPermissionsIsloading } = useGetCompanyPermissions(companyId); // Получение данных о разрешениях компании
  const { data: getPermissions } = useAuthPermissions(); // Получение всех доступных разрешений
  const [permission, setPermission] = useState<{ permission: string; active: boolean; name: string }[]>([]); // Состояние для хранения разрешений
  const [searchTerm, setSearchTerm] = useState(''); // Состояние для хранения строки поиска
  const companyPermission = useCompanyPermission(); // Хук для мутации разрешений компании
  const [initialInput, setInitialInput] = useState(''); // Изначальное состояние для ввода
  const [currentInput, setCurrentInput] = useState(''); // Текущее состояние ввода
  const [isInputChanged, setIsInputChanged] = useState(false); // Состояние для отслеживания изменений в инпуте
  const [isSwitchChanged, setIsSwitchChanged] = useState(false); // Состояние для отслеживания изменений в переключателе

  useEffect(() => {
    // Установка состояний после получения данных
    if (dataPermissions && getPermissions) {
      setPermission(
        getPermissions?.map(item => ({
          permission: item.permission,
          active: dataPermissions?.permissions?.includes(item?.permission),
          name: item.name[i18n.language as keyof typeof item.name]
        }))
      );
      setInitialInput(dataPermissions?.countRoles.toString());
      setCurrentInput(dataPermissions?.countRoles.toString());
    }
  }, [dataPermissions, getPermissions, companyId]);

  // Обработчик изменения инпута
  const changeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentInput(e.target.value);
    setIsInputChanged(true);
  };

  // Обработчик изменения свитча
  const handleChange = (checked: boolean, permission: string) => {
    setPermission(prev => [...prev.map(item => (item?.permission === permission ? { ...item, active: checked } : item))]);
    setIsSwitchChanged(true);
  };

  // Обработчик сохранения изменений
  const handleSave = () => {
    if (currentInput !== '' && Number(currentInput) >= 0 && dataPermissions?.id) {
      companyPermission.mutate({
        id: dataPermissions?.id,
        countRoles: Number(currentInput),
        permissions: permission?.filter(item => item.active).map(item => item.permission)
      });
    }
  };

  // Обработчик поиска
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Фильтрация разрешений по строке поиска
  const filteredPermissions = permission?.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

  // Если companyId не существует, возвращаем пустое состояние
  if (!companyId) {
    return (
      <div className={style.no_data}>
        <Empty />
      </div>
    );
  }

  // Возвращаем компонент поиска разрешений компании
  return (
    <div className={style.cmpsp}>
      <div>
        <div className={style.cmpsp_flex}>
          <Input type="search" className={style.input} placeholder={t('home.Qidirish')} onChange={handleSearch} />

          <div className={style.cmpsp_wrapper}>
            <p className={style.cmpsp_text}>Maksimal rollar soni:</p>
            <button className={style.cmpsp_button}>
              <input type="number" onChange={changeInput} value={currentInput} placeholder="...." />

              <img src={edit} alt="edit" />
            </button>
            <Button
              onClick={handleSave}
              type="primary"
              className={style.cmpsp_sbutton}
              loading={companyPermission.isLoading}
              disabled={!isInputChanged && !isSwitchChanged} // disable кнопки, если нет изменений в инпуте и свитчерах
            >
              Saqlash
            </Button>
          </div>
        </div>
        <Spin spinning={dataPermissionsIsloading}>
          <ul className={style.cmps_list}>
            {filteredPermissions?.map(item => (
              <li className={style.cmpsp_item} key={item?.permission}>
                <hr className={style.cmpsp_hr} />
                <div className={style.cmpsp_item_flex}>
                  <p>{item?.name}</p>
                  {dataPermissions ? (
                    <Switch
                      checked={item?.active}
                      onChange={checked => {
                        handleChange(checked, item?.permission);
                      }}
                    />
                  ) : (
                    <Switch checked={false} />
                  )}
                </div>
              </li>
            ))}
          </ul>
        </Spin>
      </div>
    </div>
  );
};

export default CompanyPermissionSearchPermissions;
