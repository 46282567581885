import React, { useState } from 'react';
import { Button, Form, Input, Modal, Popover, Spin, message } from 'antd';

import styles from './apiModal.module.scss';
import CopyIcon from '../../../assets/icons/CopyIcon';
import RefreshCircleIcon from '../../../assets/icons/RefreshCircleIcon';
import { useGetGenerationToken } from '../service/queries';
import { useQueryClient } from 'react-query';
import { queryKeys } from '../utils/constants/queryKeys';
import { t } from 'i18next';

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const ApiModal: React.FC<Props> = ({ visible, setVisible }) => {
  const [tokenStatus, setTokenStatus] = useState(false);
  const [copyText, setCopyText] = useState('Copy');
  const { data } = useGetGenerationToken(tokenStatus);
  const qc = useQueryClient();

  //handleCloseModal
  const handleCloseModal = () => {
    setVisible(false);
  };
  return (
    <Modal
      visible={visible}
      onCancel={handleCloseModal}
      title={<span style={{ color: '#464255', fontSize: 20, fontWeight: 700 }}>API</span>}
      footer={false}
      className={styles.tokenModal}
    >
      <div className={styles.container}>
        <label>{t(`my_calls.API_key`)}</label>
        <div className={styles.container_right}>
          <Input value={data} className="customInput" />
          <div className={styles.container_icons}>
            <Popover color="#23262f" content={<span style={{ color: '#fff' }}>{copyText}</span>}>
              <span
                onClick={() => {
                  navigator?.clipboard.writeText(data);
                  setCopyText('Copied!');
                }}
              >
                <CopyIcon />
              </span>
            </Popover>
            <Popover color="#23262f" content={<span style={{ color: '#fff' }}>Tokenni yangilash</span>}>
              <span
                onClick={() => {
                  setTokenStatus(true);
                  qc.invalidateQueries([queryKeys.COMPANY_TOKEN]);
                  message.success('Muvaffaqqiyatli');
                  setCopyText('Copy');
                }}
              >
                <RefreshCircleIcon />
              </span>
            </Popover>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ApiModal;
