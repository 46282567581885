import React from 'react';
import { Route, Routes } from 'react-router-dom';
import bossCrmRoutes from './bossCrmRoutes';
import AccessDenied from '@components/accessDenied/AccessDenied';

const BossCrmRouter = () => {
  return (
    <Routes>
      {bossCrmRoutes?.map((route, index) => {
        return <Route key={index} path={route.exact ? route.path : `${route.path}/*`} element={<route.component />} />;
      })}
      <Route path={'*'} element={<AccessDenied />} />
    </Routes>
  );
};

export default BossCrmRouter;
