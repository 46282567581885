import { setShaxmatka } from '@reducers/ShaxmatkaSlice';
import { FileObject } from '@utils/models/File';
import { FlatId } from '@utils/models/Flat';
import { PriceCalculateType } from '@utils/models/PriceCalculateType';

interface IProps {
  values: any;
  getValueToSavePrice: (value: string | null, oldValue?: number) => number | null | undefined;
  terassaVisible: {
    visible: boolean;
    isDelete: boolean;
  };
  homePlanFileImageId: any;
  buildingLocationImageId: any;
  extraImages: any;
  isSaveImageNew: React.MutableRefObject<boolean>;
  shaxmatka: any[];
  dispatch: (e: any) => void;
  priceCalculateType: keyof typeof PriceCalculateType;
  constantsCopy: any[];
  activeFlats?: FlatId[];
  tableMode: boolean;
}

export const handleFormSave = ({
  values,
  getValueToSavePrice,
  terassaVisible: teresaVisible,
  homePlanFileImageId,
  buildingLocationImageId,
  extraImages,
  isSaveImageNew,
  shaxmatka,
  dispatch,
  priceCalculateType,
  constantsCopy,
  tableMode,
  activeFlats
}: IProps) => {
  const arr: any = [];
  const roomObj = document.querySelectorAll('.selectableItems');

  function getFieldValue(item: any, feilds: any[]) {
    if (item?.type === 'SELECT') {
      const field = feilds?.find(el => el?.fieldId === item?.id);
      const itemName = item.options.find((i: any) => i.id === item?.value)?.name;

      return constantsCopy?.find(el => el?.id === item?.id)?.value || itemName ? itemName : field?.value;
    }
    return constantsCopy?.find(el => el?.id === item?.id)?.value || item?.value ? item?.value : feilds?.find(el => el?.fieldId === item?.id)?.value;
  }

  function fieldsFC(feilds: any[]) {
    const constants = values?.constants
      .map((item: any) => ({
        fieldId: item.id,
        value: getFieldValue(item, feilds)
      }))
      ?.filter((item: any) => item?.value);

    return [...constants];
    // .concat(
    //   feilds
    //     ? feilds.filter((item) =>
    //       constants.some((item2: any) => item?.fieldId !== item2.fieldId)
    //     )
    //     : []
    // )
    // .slice(0, values?.constants?.length)
  }

  const getRooms = (rooms: FlatId, item?: any) => {
    if (rooms.isSelected) {
      const roomArr = {
        ...rooms,
        variationGroupId: values?.variationGroupId === null ? rooms?.variationGroupId : values?.variationGroupId,
        fields: fieldsFC(rooms.fields),
        discountID: values.discount === null ? rooms?.discountID : values.discount,
        pricePerArea:
          priceCalculateType === 'STATIC'
            ? Number(getValueToSavePrice(values?.priceNotRepaired, rooms?.priceNotRepaired)) / Number(getValueToSavePrice(values.area, rooms.area))
            : getValueToSavePrice(values.pricePerArea, rooms.pricePerArea),
        priceNotRepaired:
          priceCalculateType === 'STATIC'
            ? getValueToSavePrice(values?.priceNotRepaired, rooms?.priceNotRepaired)
            : Number(getValueToSavePrice(values.pricePerArea, rooms.pricePerArea)) * Number(getValueToSavePrice(values.area, rooms.area)),
        pricePerAreaRepaired:
          priceCalculateType === 'STATIC'
            ? Number(getValueToSavePrice(values?.priceRepaired, rooms?.priceRepaired)) / Number(getValueToSavePrice(values.area, rooms?.area))
            : getValueToSavePrice(values.pricePerAreaRepaired, rooms.pricePerAreaRepaired),
        priceRepaired:
          priceCalculateType === 'STATIC'
            ? getValueToSavePrice(values?.priceRepaired, rooms?.priceRepaired)
            : Number(getValueToSavePrice(values.pricePerAreaRepaired, rooms.pricePerAreaRepaired)) * Number(getValueToSavePrice(values.area, rooms.area)),
        number: values.number === '*' ? rooms.number : values.number,
        area: getValueToSavePrice(values.area, rooms.area),
        livingArea: getValueToSavePrice(values.livingArea, rooms?.livingArea),
        rooms: getValueToSavePrice(values.rooms, rooms.rooms),
        repaired: values.repaired === null ? rooms.repaired : values.repaired,
        apartment: values.apartment === null ? rooms.apartment : values.apartment,
        nonResidentialType: values.nonResidentialType === null ? rooms.nonResidentialType : values.nonResidentialType,
        prepaymentPercent: values.prepaymentPercent === '*' ? rooms.prepaymentPercent : values.prepaymentPercent,
        delay: getValueToSavePrice(values.delay, rooms.delay),
        // eslint-disable-next-line no-nested-ternary
        terrace: teresaVisible.visible
          ? {
              // eslint-disable-next-line no-nested-ternary
              area: values.terraceArea ? (values.terraceArea === '*' ? rooms?.terrace?.area : values.terraceArea) : null,
              // eslint-disable-next-line no-nested-ternary
              pricePerArea: values.terracePricePerArea
                ? values.terracePricePerArea === '*'
                  ? rooms?.terrace?.pricePerArea
                  : Number(values.terracePricePerArea?.replace(/\D/g, ''))
                : null,
              // eslint-disable-next-line no-nested-ternary
              pricePerAreaRepaired: values.terracePricePerAreaRepaired
                ? values.terracePricePerAreaRepaired === '*'
                  ? rooms?.terrace?.pricePerAreaRepaired
                  : Number(values.terracePricePerAreaRepaired?.replace(/\D/g, ''))
                : null
            }
          : rooms.terrace && !teresaVisible.isDelete
            ? rooms.terrace
            : null,
        gallery:
          // eslint-disable-next-line no-nested-ternary
          homePlanFileImageId || buildingLocationImageId || extraImages.length > 0
            ? [
                // eslint-disable-next-line no-nested-ternary
                homePlanFileImageId
                  ? {
                      position: 'FLAT_PLAN',
                      queue: 0,
                      fileID: homePlanFileImageId
                    }
                  : !isSaveImageNew.current && rooms.gallery && rooms.gallery.find((img: any) => img.position === 'FLAT_PLAN')
                    ? rooms.gallery.find((img: any) => img.position === 'FLAT_PLAN')
                    : null,
                // eslint-disable-next-line no-nested-ternary
                buildingLocationImageId
                  ? {
                      position: 'FLAT_FLOOR',
                      queue: 0,
                      fileID: buildingLocationImageId
                    }
                  : !isSaveImageNew.current && rooms.gallery && rooms.gallery.find((img: any) => img.position === 'FLAT_FLOOR')
                    ? rooms.gallery.find((img: any) => img.position === 'FLAT_FLOOR')
                    : null
              ]
                .concat(
                  extraImages
                    ? extraImages.map((image: FileObject) => ({
                        position: 'FLAT_EXTRA',
                        queue: 0,
                        fileID: image.id,
                        files: image
                      }))
                    : null
                )
                .filter(image => image !== null)
            : rooms?.gallery?.length > 0 && !isSaveImageNew.current
              ? rooms.gallery
              : null,
        category: values?.category
      };

      arr.push(roomArr);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !tableMode && item.setAttribute('data', JSON.stringify(roomArr));
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !tableMode && item.setAttribute('data', JSON.stringify(rooms));
    }
  };

  if (tableMode) {
    activeFlats?.forEach((item: any) => {
      getRooms(item);
    });
  } else {
    roomObj.forEach((item: any) => {
      const rooms = JSON.parse(item.getAttribute('data')) as FlatId;

      getRooms(rooms, item);
    });
  }
  const shaxmatkaCopy = shaxmatka.map((podezd: any) =>
    podezd.map((floor: any) =>
      floor.map((rooms: FlatId) => {
        function Selected(a: FlatId) {
          return a && a.floor === rooms.floor && a.xaxis[0] === rooms.xaxis[0] && a.entrance === rooms.entrance;
        }

        return arr && arr.some((flat: FlatId) => Selected(flat))
          ? {
              ...arr.find((flat: FlatId) => Selected(flat))
            }
          : rooms;
      })
    )
  );

  dispatch(setShaxmatka(shaxmatkaCopy as any));
};
