/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import InternationalPhoneNumber from '@components/international-phone-input/InternationalPhoneNumber';
import EmployeeBranches from '@components/settingEmployee/employee-branches/EmployeeBranches';
import { Button, Col, Form, Input, Modal, Radio, Row, Select, Space, Spin } from 'antd';
import { t } from 'i18next';
import { isValidNumber } from 'libphonenumber-js';
import ReactInputMask from 'react-input-mask';

import CalendarIcon from '../../assets/icons/CalendarIcon';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { SearchParamsCompanyEmployees } from '../../pages/companyEmployees/CompanyEmployees';
import { useCreateAndEditCompanyEmployees } from '../../queries/mutation';
import { useDevBranchCompact, useEmployeeRoleOnCompany, useGetDistrict, useOneCompanyEmployee, useRegions } from '../../queries/queries';
import { editAndCreateModal } from '../../store/reducers/CompanyEmployeesSlice';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import { validateDateString } from '../../utils/helper/validatorDateString';

import 'react-international-phone/style.css';
import styles from '../../pages/login/login.module.scss';

interface FormI {
  fullName: string;
  phone: string;
  phoneCode: string;
  username: string;
  password: string;
  districtId: number;
  address: string;
  roleId: number;
  expiry: string;
  cityId: number;
  status: string;
  roleBranches: Array<{
    roleId: number;
    branchId?: number | null;
  }>;
}

const SettingEmployeeModal = () => {
  const { params, searchParams } = useQueryParam<SearchParamsCompanyEmployees, { companyId: string }>();
  const [regionId, setRegionId] = useState<number>(-1);

  const dispatch = useAppDispatch();

  const { id, editAndCreateModalOpen } = useAppSelector(state => state.companyEmployeesReducer);

  const { data: roleData, isLoading: loadingRole } = useEmployeeRoleOnCompany(params.companyId);
  const { data: regions, isLoading: loadingRegions } = useRegions();
  const { data: districts, isLoading: districtsLoading } = useGetDistrict(regionId, 'region');
  const { data, isLoading } = useOneCompanyEmployee(id as number);

  const { data: branchData, isLoading: loadingBranch } = useDevBranchCompact(params?.companyId);

  const { clientPhones } = useAppSelector(state => state.clientReducer);

  const [form] = Form.useForm<FormI>();

  const createEmployee = useCreateAndEditCompanyEmployees(id, params.companyId, searchParams);

  useEffect(() => {
    if (data) {
      setRegionId(data.cityId);
      form.setFieldsValue({
        roleBranches: data?.roleBranches?.map((item: any) => ({
          branchId: item?.branch?.id || -1,
          roleId: item?.role?.id
        })),
        expiry: data.expiry,
        phone: data.phone,
        phoneCode: data.phoneCode,
        address: data.address,
        roleId: data.roleId,
        username: data.username,
        districtId: data?.districtId,
        cityId: data.cityId,
        fullName: data.fullName,
        status: data.status
      });
    }
  }, [data, form]);

  useEffect(() => {
    if (!editAndCreateModalOpen) {
      form.setFieldsValue({
        fullName: '',
        phone: '',
        phoneCode: '',
        username: '',
        password: '',
        districtId: undefined,
        address: '',
        roleId: undefined,
        expiry: '',
        cityId: undefined,
        roleBranches: []
      });
    }
  }, [editAndCreateModalOpen, form]);

  const onFinish = (values: FormI) => {
    createEmployee.mutate({
      ...values,
      phone: values?.phone,
      phoneCode: values?.phoneCode,
      roleBranches: values.roleBranches?.length > 0 ? values.roleBranches?.map(item => ({ ...item, branchId: item?.branchId === -1 ? null : item?.branchId })) : null
    });
  };

  function handleCancel() {
    setRegionId(-1);
    dispatch(editAndCreateModal({ id: 0, open: false }));
  }

  const changeRegion = (id: number) => {
    form.setFieldsValue({
      districtId: undefined
    });
    setRegionId(id);
  };

  return (
    <Modal
      title={id ? 'Xodim ma’lumotlari' : 'Xodim yaratish'}
      wrapClassName="settingEmployeeModal"
      visible={editAndCreateModalOpen}
      onCancel={handleCancel}
      footer={false}
      width={774}
    >
      <Spin spinning={isLoading}>
        <Form onFinish={onFinish} layout="vertical" form={form}>
          <Row className="d-flex justify-space-between">
            <Col span={11}>
              <Form.Item label="Hodim">
                <Form.Item noStyle name="fullName" rules={[{ required: true, message: '' }]}>
                  <Input />
                </Form.Item>
              </Form.Item>

              {clientPhones.map((phone, index) => (
                <Space direction="horizontal" className="d-flex" key={phone?.id}>
                  <div>
                    <Form.Item name="phoneCode" className="d-none" />

                    <div className="flex flex-col gap-[5px]">
                      <Form.Item
                        label={<span>{t(`changes.client.NaturalClient.Telefon_raqam`)}</span>}
                        name="phone"
                        rules={[
                          {
                            validator: (rule, value) => {
                              if (!(value && isValidNumber(value))) {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise.reject('');
                              }
                              return Promise.resolve();
                            }
                          }
                        ]}
                      >
                        <InternationalPhoneNumber
                          defaultCountry="uz"
                          className={styles.phoneInput}
                          onChange={(e, phone) => {
                            form.setFieldsValue({
                              [`phoneCode`]: `${phone?.country?.iso2?.toUpperCase()}`
                            });
                          }}
                        />
                      </Form.Item>

                      {/* {!isPhoneValid(phoneValue) && <span className="mt-[-10px] text-red-500">{t('profile.Telefon_raqamini_kiriting')}</span>} */}
                    </div>
                  </div>
                </Space>
              ))}

              {id ? (
                <EmployeeBranches roleData={roleData} form={form} loadingRole={loadingRole} branchData={branchData} loadingBranch={loadingBranch} />
              ) : (
                <Form.Item label="Lavozim">
                  <Form.Item name="roleId" noStyle rules={[{ required: true }]}>
                    <Select optionFilterProp="children" showSearch loading={loadingRole}>
                      {roleData?.map(role => (
                        <Select.Option key={role.id} value={role.id}>
                          {getCurrentLanguageWord(role)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form.Item>
              )}

              <Form.Item label="Username">
                <Form.Item name="username" rules={[{ required: true, min: 8 }]} noStyle>
                  <Input prefix={<span className={styles.login_icon_at}>@</span>} />
                </Form.Item>
              </Form.Item>
              <Form.Item label="Amal qilish muddati">
                <Form.Item
                  noStyle
                  name="expiry"
                  rules={[
                    {
                      validator: (rule, value) => {
                        if (value) {
                          form.setFieldsValue({
                            expiry: validateDateString(value, true)
                          });
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <ReactInputMask mask="99.99.9999" maskChar="">
                    {/* @ts-ignore* */}
                    {(inputProps: any) => <Input placeholder="kk oo yy" suffix={<CalendarIcon />} {...inputProps} />}
                  </ReactInputMask>
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item label="Viloyat">
                <Form.Item name="cityId" noStyle rules={[{ required: true }]}>
                  <Select optionFilterProp="children" showSearch loading={loadingRegions} onChange={(i: any) => changeRegion(i)}>
                    {regions?.map(i => (
                      <Select.Option key={i.id} value={i.id}>
                        {getCurrentLanguageWord(i)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item label="Shahar tuman">
                <Form.Item name="districtId" noStyle rules={[{ required: true }]}>
                  <Select optionFilterProp="children" showSearch loading={districtsLoading}>
                    {districts?.map(i => (
                      <Select.Option key={i.id} value={i.id}>
                        {getCurrentLanguageWord(i)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item label="Manzil">
                <Form.Item name="address" rules={[{ required: true }]} noStyle>
                  <Input />
                </Form.Item>
              </Form.Item>
              <Form.Item label="Parol">
                <Form.Item name="password" rules={[{ required: !id, min: 8 }]} noStyle>
                  <Input.Password placeholder="Parolni kiriting" />
                </Form.Item>
              </Form.Item>
              {id ? (
                <Form.Item label="Status">
                  <Form.Item name="status" rules={[{ required: true }]} noStyle>
                    <Radio.Group>
                      <Radio value="ENABLED">Faol</Radio>
                      <Radio value="LOCKED">Bloklangan</Radio>
                      <Radio disabled value="EXPIRED">
                        Muddati o’tgan
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <div className="settingEmployeeModalFooter">
            <Button onClick={handleCancel}>Yopish</Button>
            <Button loading={createEmployee.isLoading} htmlType="submit" type="primary">
              {id ? 'Saqlash' : 'Yaratish'}
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default SettingEmployeeModal;
