import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DotsIcon from '@pages/activityLogs/assets/DotsIcon';
import { queryNames } from '@utils/constants/queryNames';
import { Dropdown, Menu, Spin, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useQueryClient } from 'react-query';

import { useCompanyRolePermissions } from '../../queries/queries';
import { RoleEdit } from '../../utils/models/Roles';

import NextPermissions from './components/next-permissions/NextPermissions';
import PermissionHeader from './components/permission-header/PermissionHeader';
import { useCompanyDeleteRole } from './service/mutation';

import del from '../../pages/security/security-control-permissions/assets/del.svg';
import edit from '../../pages/security/security-control-permissions/assets/edit.svg';

import styles from '../../pages/companyEmployeeRoles/companyEmployeeRoles.module.scss';

interface DataType {
  key: React.ReactNode;
  name: string;
  age: number;
  address: string;
  children?: DataType[];
}

interface Props {
  openEditModal: React.Dispatch<React.SetStateAction<{ visible: boolean; role?: RoleEdit | undefined }>>;
  handleOpenEditEndCreateRoleModal: (role?: RoleEdit) => void;
}

const TableRoles: React.FC<Props> = ({ openEditModal, handleOpenEditEndCreateRoleModal }) => {
  const qc = useQueryClient();
  const params = useParams<{ id: string }>();
  const { data, isLoading } = useCompanyRolePermissions(params.id || '0');
  const deleteRole = useCompanyDeleteRole();
  const [dropdownVisible, setDropdownVisible] = useState(-1);
  const [searchText, setSearchText] = useState('');

  const [permissionState, setPermissionState] = useState<any[]>([]);
  const [nextLevel, setNextLevel] = useState(false);

  const outputArray: any[] = permissionState?.reduce((acc, obj) => {
    const existingObj = acc?.find((item: any) => item?.roleId === obj?.roleId);

    if (existingObj) {
      Object.assign(existingObj.permissions, {
        [Object.keys(obj)[1]]: obj[Object.keys(obj)[1]]
      });
    } else {
      const newObj = {
        roleId: obj?.roleId,
        permissions: {
          [Object.keys(obj)[1]]: obj[Object.keys(obj)[1]]
        }
      };

      acc.push(newObj);
    }
    return acc;
  }, []);

  const hasChange = (record: any, item: any) => {
    const checkedItem = outputArray?.find((role: any) => role?.roleId === item?.id);

    if (
      checkedItem &&
      checkedItem?.permissions?.hasOwnProperty!(record?.name) &&
      item?.permissions?.find((p: any) => p?.permission === record?.name)?.active !== checkedItem?.permissions[record?.name]
    ) {
      return true;
    }
    return false;
  };

  const dataRoles = data?.roles
    ? [
        ...data?.roles?.map(item => ({
          title: (
            <div className={styles.table_head}>
              <span>{item?.name?.uz?.length > 8 ? `${item.name.uz?.substring(0, 8)}...` : item?.name?.uz}</span>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="edit"
                      onClick={() => {
                        openEditModal({
                          visible: true,
                          role: {
                            id: item.id,
                            name: item.name.default,
                            nameEn: item.name.en,
                            nameUz: item.name.uz,
                            nameRu: item.name.ru,
                            nameKr: item.name.kr,
                            nameTr: item.name.tr
                          }
                        });
                      }}
                    >
                      <div className={styles.drop_item}>
                        <img src={edit} alt="edit" />
                        <p>Tahrirlash</p>
                      </div>
                    </Menu.Item>
                    <Menu.Item
                      key="delete"
                      onClick={() => {
                        setDropdownVisible(item?.id);
                        deleteRole.mutate(item?.id);
                      }}
                    >
                      <div className={styles.drop_item}>
                        {deleteRole.isLoading && dropdownVisible === item.id ? (
                          <>
                            <Spin size="small" className={styles.spinner} />
                          </>
                        ) : (
                          <>
                            <img src={del} alt="delete" />
                          </>
                        )}
                        <p>O'chirish</p>
                      </div>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
              >
                <span>
                  <DotsIcon />
                </span>
              </Dropdown>
            </div>
          ),
          width: 210,
          render: (record: any) => {
            const permissions = record.roles.find((role: any) => role.id === item.id);

            if (!record?.key) {
              return (
                <Switch
                  key={`${record?.name}_${permissions?.id}`}
                  onChange={e => {
                    const sendData = {
                      roleId: permissions?.id,
                      [record?.name]: e,
                      currentId: `${record?.name}_${permissions?.id}`
                    };

                    const hasItem = permissionState?.some(item => item.currentId === sendData?.currentId);

                    if (hasItem) {
                      const newArr = permissionState?.filter(item => item?.currentId !== sendData?.currentId);

                      setPermissionState(newArr);
                    } else {
                      setPermissionState(props => [...props, sendData]);
                    }

                    qc.setQueryData([queryNames.COMPANY_ROLE_PERMISSION, params.id || '0'], (data: any) => {
                      const converData = {
                        ...data,
                        table: data?.table?.map((item: any) => ({
                          ...item,
                          roles: item?.roles?.map((permission: any) => ({
                            ...permission,
                            checked: item.name === record?.name && permission?.id === permissions?.id ? e : permission?.checked
                          }))
                        }))
                      };

                      console.log(converData, e);
                      return converData;
                    });
                  }}
                  checked={permissions?.checked}
                  style={{
                    border: hasChange(record, item) ? '5px solid #FFC224' : '',
                    height: hasChange(record, item) ? 32 : '',
                    width: hasChange(record, item) ? 55 : ''
                  }}
                />
              );
            }
            return '';
          },
          align: 'center'
        }))
      ]
    : [];

  const newColumns: ColumnsType<any> = [
    {
      title: 'Huquqlar',
      align: 'left',
      fixed: 'left',
      width: 350,
      render: record => (
        <p style={{ fontSize: record?.key && 16, fontWeight: record?.key && 600, color: record?.key && '#464255', cursor: record?.key && 'pointer' }}>
          {record?.label?.length > 38 ? `${record?.label?.substring(0, 38)}...` : record?.label}
        </p>
      )
    },
    ...(dataRoles as any)
  ];

  const filteredData = data?.table?.filter(item => item.label.toLowerCase().includes(searchText.toLowerCase()));

  const newData = filteredData?.filter(item => permissionState?.some(state => state?.currentId?.replace(`_${state?.roleId}`, '') === item?.name));

  const finalData = nextLevel ? newData : filteredData;

  const groupedData = finalData?.reduce((acc, item) => {
    const { label, name, type, roles, typeNameUz } = item;
    const existingGroup = acc.find((group: any) => group.type === type);

    if (existingGroup) {
      existingGroup.children.push({ label, name, type, roles });
    } else {
      acc.push({ type, children: [{ label, name, type, roles }], label: typeNameUz, name, roles, key: type });
    }

    return acc;
  }, []);

  useEffect(() => {
    setNextLevel(false);
    setPermissionState([]);
  }, [params?.id]);

  return (
    <Spin spinning={isLoading}>
      <div className={styles.EmployeeListContainer_table}>
        <PermissionHeader
          handleOpen={handleOpenEditEndCreateRoleModal}
          next={nextLevel}
          outputArray={outputArray}
          setNext={setNextLevel}
          setSearchText={setSearchText}
          setPermissionState={setPermissionState}
        />
        {nextLevel
          ? groupedData && <NextPermissions newColumns={newColumns} groupedData={groupedData} />
          : groupedData && (
              <Table
                columns={newColumns}
                dataSource={groupedData || []}
                pagination={false}
                scroll={{ x: 1300, y: '76vh' }}
                className={styles.lastTableStyle}
                expandable={{ defaultExpandedRowKeys: groupedData?.map((item: any) => item?.type) }}
              />
            )}
      </div>
    </Spin>
  );
};

export default TableRoles;
