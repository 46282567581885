/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactInputMask from 'react-input-mask';

import { useQueryParam } from '../../hooks/useQueryParams';
import { SearchParamsBuilder } from '../../pages/builderSettings/BuilderSettings';
import { useBuilderEdit, useCreateBuilder } from '../../queries/mutation';
import { useBuilderId, useGetDistrict, useRegions } from '../../queries/queries';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import { helper } from '../../utils/helper/helper';

import styles from '../../pages/builderSettings/builderSettings.module.scss';

interface IProps {
  visible: boolean;
  id: number | null;
  setVisible: React.Dispatch<React.SetStateAction<{ visible: boolean; id: null }>>;
}
interface IForm {
  id: number;
  name: string;
  phone: string;
  account: string;
  mfo: string;
  inn: string;
  oked: string;
  bank: string;
  region: number;
  director: string;
  address: string;
  cityId: string;
  district: number;
  callCenterPhone: string;
}

const CreateBuilderSettings: React.FC<IProps> = ({ visible, setVisible, id }) => {
  const { searchParams } = useQueryParam<SearchParamsBuilder, any>();
  const [form] = Form.useForm<IForm>();
  const { data: regions, isLoading: loadingRegions } = useRegions();
  const [regionId, setRegionId] = useState<number>(0);

  const builderMutation = useCreateBuilder(searchParams);
  const { data, isLoading } = useBuilderId(id as number);
  const { data: districts, isLoading: districtsLoading } = useGetDistrict(regionId, 'region');
  const builderEditMutation = useBuilderEdit(searchParams);

  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data?.name,
        phone: helper.clientPhoneToUser(data.phone),
        account: data.account,
        mfo: data.mfo,
        inn: data?.inn,
        oked: data.oked,
        region: data.city.id,
        bank: data.bank,
        director: data?.director,
        district: data?.district,
        address: data.address,
        callCenterPhone: helper.clientPhoneToUser(data.callCenterPhone)
      });
      setRegionId(data.city.id || -1);
    }
  }, [data, form]);

  const onFinish = (fields: IForm) => {
    // setVisible({ visible: false, id: null });

    if (!!id) {
      builderEditMutation
        .mutateAsync({
          id,
          name: fields.name,
          phone: helper.clientPhoneToServer(helper.deleteSpace(fields.phone)),
          account: fields.account?.trim(),
          mfo: fields.mfo?.trim(),
          inn: fields.inn?.trim(),
          oked: fields.oked?.trim(),
          bank: fields.bank?.trim(),
          director: fields.director,
          district: fields.district,
          address: fields.address,
          callCenterPhone: fields.callCenterPhone ? helper.clientPhoneToServer(helper.deleteSpace(fields.callCenterPhone)) : null
        })
        .then(() => setVisible({ id: null, visible: false }));
    } else {
      const createBuilder = {
        name: fields.name,
        phone: helper.clientPhoneToServer(helper.deleteSpace(fields.phone)),
        account: fields.account,
        mfo: fields.mfo?.trim(),
        inn: fields.inn?.trim(),
        oked: fields.oked?.trim(),
        bank: fields.bank?.trim(),
        director: fields.director,
        district: fields.district,
        address: fields.address,
        callCenterPhone: fields?.callCenterPhone ? helper.clientPhoneToServer(helper.deleteSpace(fields.callCenterPhone)) : null
      };

      builderMutation.mutateAsync(createBuilder as any).then(() => setVisible({ id: null, visible: false }));
    }
  };
  const handleCancel = () => {
    setVisible({ visible: false, id: null });
    form.setFieldsValue({
      name: '',
      phone: '',
      account: '',
      mfo: '',
      inn: '',
      oked: '',
      bank: '',
      director: '',
      region: undefined,
      district: undefined,
      address: ''
    });
  };
  const handleOk = () => {
    setVisible({ visible: false, id: null });
  };

  const changeRegion = (id: number) => {
    form.setFieldsValue({
      district: undefined
    });
    setRegionId(id);
  };

  return (
    <Modal
      width={774}
      className={'createBuilderModal'}
      title={t("titles.Quruvchi_qo'shish")}
      visible={visible}
      onOk={handleOk}
      centered={true}
      footer={false}
      onCancel={handleCancel}
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical" onFinish={onFinish} className={styles.formBuilderModal}>
          <Row>
            <Col className={styles.left} flex={'1'}>
              <Form.Item rules={[{ required: true, message: '' }]} name="name" label={t('home.Korxona_nomi')}>
                <Input />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: '' }]} name="phone" label={t('home.Telefon_raqam')}>
                <ReactInputMask
                  mask="99 999 99 99"
                  // @ts-ignore*
                  maskChar={''}
                >
                  {(inputProps: any) => <Input className={'customInput'} placeholder="XX XXX XX XX" addonBefore="+998" {...inputProps} />}
                </ReactInputMask>
              </Form.Item>
              <Form.Item rules={[{ required: true, message: '' }]} name={'mfo'} label={t('home.MFO')}>
                <Input />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: '' }]} name={'bank'} label={t('home.Bank_nomi')}>
                <Input />
              </Form.Item>
              <Form.Item label={t('home.Viloyat')} name={'region'} rules={[{ required: true, message: '' }]}>
                <Select
                  optionFilterProp={'children'}
                  showSearch
                  className={'customSelect'}
                  placeholder={t('home.Tanlang')}
                  loading={loadingRegions}
                  onChange={i => changeRegion(i)}
                >
                  {regions?.map(region => (
                    <Select.Option key={region.id} value={region.id}>
                      {getCurrentLanguageWord(region)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name={'callCenterPhone'} label={'Call Center'}>
                <ReactInputMask
                  mask="99 999 99 99"
                  // @ts-ignore*
                  maskChar={''}
                >
                  {(inputProps: any) => <Input className={'customInput'} placeholder="XX XXX XX XX" addonBefore="+998" {...inputProps} />}
                </ReactInputMask>
              </Form.Item>
            </Col>
            <Col className={styles.right} flex={'1'}>
              <Form.Item rules={[{ required: true, message: '' }]} name={'director'} label={t('home.Korxona_rahbari')}>
                <Input />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: '' }]} name={'inn'} label={t('home.INN')}>
                <Input />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: '' }]} name={'oked'} label={t('home.OKED')}>
                <Input />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: '' }]} name={'account'} label={t('home.Hisob_raqam')}>
                <Input />
              </Form.Item>
              <Form.Item label={t('home.Shaxar')} name={'district'} rules={[{ required: true, message: '' }]}>
                <Select
                  optionFilterProp={'children'}
                  showSearch
                  className={'customSelect'}
                  placeholder={t('home.Tanlang')}
                  loading={districtsLoading}
                >
                  {districts?.map(district => (
                    <Select.Option key={district.id} value={district.id}>
                      {getCurrentLanguageWord(district)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item rules={[{ required: true, message: '' }]} name={'address'} label={t('home.Manzil')}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.modalFooter}>
            <Button onClick={handleCancel}> {t('home.Yopish')}</Button>
            <Button htmlType="submit" type="primary" loading={builderEditMutation.isLoading || builderMutation.isLoading}>
              {t('home.Saqlash')}
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreateBuilderSettings;
