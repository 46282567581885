import { Button, Col, Form, Input, Modal, Row } from 'antd';
import React, { useEffect } from 'react';
import { RoleEdit } from '../../utils/models/Roles';
import { useCreateAndEditRole } from '../../queries/mutation';
import { useParams } from 'react-router-dom';

interface Props {
  visible: boolean; // Показывает, открыто ли модальное окно
  role?: RoleEdit; // Роль для редактирования
  close: () => void; // Функция для закрытия модального окна
}

// Компонент модального окна для создания и редактирования роли
const CreateAndEditRoleModal: React.FC<Props> = ({ role, close, visible }) => {
  const params = useParams<{ id: string }>(); // Получение параметров запроса

  const [form] = Form.useForm<RoleEdit>(); // Использование формы

  const createAndEditRole = useCreateAndEditRole(
    // Использование функции для создания и редактирования роли
    params.id || '0',
    onSuccess,
    role?.id
  );

  // Заполнение полей формы при загрузке роли
  useEffect(() => {
    if (role) {
      form.setFieldsValue({
        name: role.name,
        nameUz: role.nameUz,
        nameRu: role.nameRu,
        nameEn: role.nameEn,
        nameKr: role.nameKr,
        nameTr: role.nameTr
      });
    }
  }, [role, form]);

  // Обработчик успешного завершения действия
  function onSuccess() {
    handleCancel();
  }

  // Обработчик отправки формы
  const onFinish = (values: RoleEdit) => {
    createAndEditRole.mutate(values);
  };

  // Обработчик закрытия модального окна
  function handleCancel() {
    close();
    form.resetFields();
  }

  // Возвращаем модальное окно для создания и редактирования роли
  return (
    <Modal
      title={!role ? 'Foydalanuvchi qo’shish' : 'Foydalanuvchini tahrirlash'} // Заголовок модального окна
      wrapClassName="settingEmployeeModal" // Классы обертки модального окна
      visible={visible} // Видимость модального окна
      onCancel={handleCancel} // Обработчик закрытия модального окна
      footer={false} // Нет подвала
      width={399} // Ширина модального окна
    >
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Row className={'d-flex justify-space-between'}>
          <Col span={24}>
            {/* Форма для ввода данных о роли на разных языках */}
            <Form.Item label={'Foydalanuvchi'}>
              <Form.Item noStyle name={'name'} rules={[{ required: true, message: '' }]}>
                <Input />
              </Form.Item>
            </Form.Item>
            <Form.Item label={"Foydalanuvchi (o'zbek)"}>
              <Form.Item noStyle name={'nameUz'} rules={[{ required: true, message: '' }]}>
                <Input />
              </Form.Item>
            </Form.Item>
            <Form.Item label={'Foydalanuvchi (krill)'}>
              <Form.Item noStyle name={'nameKr'} rules={[{ required: true, message: '' }]}>
                <Input />
              </Form.Item>
            </Form.Item>
            <Form.Item label={'Foydalanuvchi (ingliz)'}>
              <Form.Item noStyle name={'nameEn'} rules={[{ required: true, message: '' }]}>
                <Input />
              </Form.Item>
            </Form.Item>
            <Form.Item label={'Foydalanuvchi (rus)'}>
              <Form.Item noStyle name={'nameRu'} rules={[{ required: true, message: '' }]}>
                <Input />
              </Form.Item>
            </Form.Item>
            <Form.Item label={'Foydalanuvchi (turk)'}>
              <Form.Item noStyle name={'nameTr'} rules={[{ required: true, message: '' }]}>
                <Input />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <div className="roleFooter">
          <Button loading={createAndEditRole.isLoading} className={'save'} htmlType="submit" type="primary">
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateAndEditRoleModal;
