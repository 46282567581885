/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@hooks/reduxHooks';
import PremiumClientIcon from '@icons/PremiumClientIcon';
import VipClientIcon from '@icons/VipClientIcon';
import { setChecked, setDebtActiveIds, setDebtIgnoreIds } from '@reducers/DebtSlice';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { urlEncode } from '@utils/helper/urlEncodeDecode';
import { Checkbox, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';

import TableOrderIcon from '../../../../assets/icons/TableOrderIcon';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { getClassNameOrder } from '../../../../utils/helper/getClassNameTableOrder';
import { DebtList } from '../../../../utils/models/DebtList';
import { TableConfig } from '../../../../utils/models/TableConfig';
import ClientMessageModal from '../../../client/components/ClientMessageModal';
import { SearchParamsArrearage } from '../../Arrearage';

import Action from './action/Action';
import ArrearageDeleteModal from './arrearageDeleteModal/ArrearageDeleteModal';
import CreateTaskModal from './createTask/CreateTaskModal';

import styles from '../../arrearage.module.scss';

interface TableContractProps {
  data: DebtList[];
  isLoading: boolean;
  tableConfig: TableConfig[];
  tableConfigFetch: boolean;
  isFetching: boolean;
}

const ArrearageTable: React.FC<TableContractProps> = ({ tableConfig, tableConfigFetch, data, isLoading, isFetching }) => {
  const dispatch = useDispatch();
  const [surchargeDeleteModalVisible, setSurchargeDeleteModalVisible] = useState({ id: 0, visible: false });
  const { debtListActiveIds: activeIds, debtListIgnoredIds, checked } = useAppSelector(state => state.debtSlice);
  const [sendMessageModal, setSendMessageModal] = useState({
    id: 0,
    visible: false,
    name: '',
    phoneNumber: '',
    contractNumber: ''
  });

  const [openTaskCreateModal, setOpenTaskCreateModal] = useState<{
    visible: boolean;
    id: undefined | number;
    arrearage: number;
    clientId: number | undefined;
  }>({
    visible: false,
    id: undefined,
    arrearage: 0,
    clientId: undefined
  });

  const { searchParams, location, orderTable } = useQueryParam<SearchParamsArrearage, null>();

  const { t } = useTranslation();

  const initialColumn: ColumnsType<DebtList> | undefined = [
    {
      title: t('home.Shartnoma'),
      dataIndex: 'contractNumber',
      render: (contractNumber: string) => <span style={{ color: '#A3A3A3' }}>{contractNumber}</span>
    },
    {
      title: t('home.Mijoz'),
      render: (record: DebtList) => (
        <div className="flex items-center gap-1">
          <span>{record?.clientName}</span>
          {/* eslint-disable-next-line no-nested-ternary */}
          {record?.clientLevel === 'VIP' ? (
            <Tooltip overlayStyle={{ maxWidth: '490px' }} overlay={<span className="text-xs font-medium text-[#ffffff]">{t('home.Vip_mijoz_text')}</span>}>
              <div className="flex items-center justify-center">
                <VipClientIcon />
              </div>
            </Tooltip>
          ) : record?.clientLevel === 'REGULAR' ? (
            <Tooltip overlay={<span className="text-xs font-medium text-[#ffffff]">{t('home.Premium_mijoz_text')}</span>} overlayStyle={{ maxWidth: 440 }}>
              <div className="flex items-center justify-center">
                <PremiumClientIcon />
              </div>
            </Tooltip>
          ) : (
            ''
          )}
        </div>
      )
    },
    {
      title: t('home.Telefon_raqam'),
      dataIndex: 'clientPhone',
      render: (clientPhone: string) => <span style={{ color: '#A3A3A3' }}>{clientPhone}</span>
    },
    {
      title: (
        <div onClick={() => handleOrders('AMOUNT')} className="orderTableCont">
          <div>{t('home.Qarz_summasi')}</div>
          <div className="orderIcons">
            <TableOrderIcon className="orderTable_ASC" />
            <TableOrderIcon className="orderTable_DESC" />
          </div>
        </div>
      ),
      render: (record: DebtList) => (
        <span style={{ color: '#E53535' }}>
          {record.amount?.toLocaleString('ru')} {record.currency?.ccy}
        </span>
      )
    },
    // {
    //   title: t('home.Maksimal_kechikish_muddati'),
    //   dataIndex: 'maxDelay'
    // },
    {
      title: t('home.Kechikish'),
      dataIndex: 'delay'
    },
    {
      title: (
        <div onClick={() => handleOrders('DATE')} className="orderTableCont">
          <span>{t('home.Sana')}</span>
          <div className="orderIcons">
            <TableOrderIcon className="orderTable_ASC" />
            <TableOrderIcon className="orderTable_DESC" />
          </div>
        </div>
      ),
      dataIndex: 'date',
      render: (date: string) => <span style={{ color: '#A3A3A3' }}>{date}</span>
    },
    {
      width: '80px',
      title: (
        <div onClick={() => handleOrders('SURCHARGE')} className="orderTableCont">
          <div>{t('home.Peniya')}</div>
          <div className="orderIcons">
            <TableOrderIcon className="orderTable_ASC" />
            <TableOrderIcon className="orderTable_DESC" />
          </div>
        </div>
      ),
      render: (record: DebtList) =>
        record.surcharge !== 0 ? (
          <span style={{ color: '#E53535' }}>
            {record.surcharge?.toLocaleString('ru')} {record.currency?.ccy}
          </span>
        ) : (
          <span>-</span>
        )
    },
    {
      title: t('home.Masul'),
      align: 'left',
      dataIndex: 'responsibleBy'
    },
    {
      width: '80px',
      render: (record: DebtList) => (
        <Action
          setSurchargeDeleteModalVisible={setSurchargeDeleteModalVisible}
          record={record}
          handleTaskCreateModalOpen={handleTaskCreateModalOpen}
          handleSendMessageModalOpen={handleSendMessageModalOpen}
        />
      )
    }
  ];
  const [column, setColumn] = useState(initialColumn);

  // console.log('hello');
  useEffect(() => {
    if (tableConfig.length > 0 && !tableConfigFetch) {
      const newConfig: any[] = [];

      tableConfig.forEach(item => {
        if (item.checked) {
          switch (item.name) {
            case 'Sana':
              newConfig.push({
                title: (
                  <div onClick={() => handleOrders('DATE')} className="orderTableCont">
                    <span>{t('home.Sana')}</span>
                    <div className="orderIcons">
                      <TableOrderIcon className={getClassNameOrder('DATE', 'ASC', searchParams.order)} />
                      <TableOrderIcon className={getClassNameOrder('DATE', 'DESC', searchParams.order)} />
                    </div>
                  </div>
                ),
                dataIndex: 'date',
                render: (date: string) => <span style={{ color: '#A3A3A3' }}>{date}</span>
              });
              break;
            case 'Shartnoma':
              newConfig.push({
                title: t('home.Shartnoma'),
                dataIndex: 'contractNumber',
                align: 'left',
                render: (contractNumber: string) => <span style={{ color: '#A3A3A3' }}>{contractNumber}</span>
              });
              break;
            case 'Mijoz':
              newConfig.push({
                title: t('home.Mijoz'),
                render: (record: DebtList) => (
                  <div className="flex items-center gap-1">
                    <span>{record?.clientName}</span>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {record?.clientLevel === 'VIP' ? (
                      <Tooltip overlayStyle={{ maxWidth: '490px' }} overlay={<span className="text-xs font-medium text-[#ffffff]">{t('home.Vip_mijoz_text')}</span>}>
                        <div className="flex items-center justify-center">
                          <VipClientIcon />
                        </div>
                      </Tooltip>
                    ) : record?.clientLevel === 'REGULAR' ? (
                      <Tooltip overlay={<span className="text-xs font-medium text-[#ffffff]">{t('home.Premium_mijoz_text')}</span>} overlayStyle={{ maxWidth: 440 }}>
                        <div className="flex items-center justify-center">
                          <PremiumClientIcon />
                        </div>
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </div>
                ),
                align: 'left',
                key: 3
              });
              break;
            case 'Telefon_raqam':
              newConfig.push({
                title: t('home.Telefon_raqam'),
                dataIndex: 'clientPhone',
                render: (clientPhone: string) => <span style={{ color: '#A3A3A3' }}>{clientPhone}</span>
              });
              break;
            case 'Qarz_summasi':
              newConfig.push({
                align: 'right',
                title: (
                  <div onClick={() => handleOrders('AMOUNT')} className="orderTableCont">
                    <div>{t('home.Qarz_summasi')}</div>
                    <div className="orderIcons">
                      <TableOrderIcon className={getClassNameOrder('AMOUNT', 'ASC', searchParams.order)} />
                      <TableOrderIcon className={getClassNameOrder('AMOUNT', 'DESC', searchParams.order)} />
                    </div>
                  </div>
                ),
                render: (record: DebtList) => (
                  <span style={{ color: '#E53535' }}>
                    {record.amount?.toLocaleString('ru')} {record.currency?.ccy}
                  </span>
                )
              });
              break;
            case 'Peniya':
              newConfig.push({
                align: 'right',
                title: (
                  <div onClick={() => handleOrders('SURCHARGE')} className="orderTableCont">
                    <div>{t('home.Peniya')}</div>
                    <div className="orderIcons">
                      <TableOrderIcon className={getClassNameOrder('SURCHARGE', 'ASC', searchParams.order)} />
                      <TableOrderIcon className={getClassNameOrder('SURCHARGE', 'DESC', searchParams.order)} />
                    </div>
                  </div>
                ),
                render: (record: DebtList) =>
                  record.surcharge !== 0 ? (
                    <span style={{ color: '#E53535' }}>
                      {record.surcharge?.toLocaleString('ru')} {record.currency?.ccy}
                    </span>
                  ) : (
                    <span>-</span>
                  )
              });
              break;
            case 'Maksimal_kechikish_muddati':
              newConfig.push({
                title: t('home.Maksimal_kechikish_muddati'),
                dataIndex: 'maxDelay'
              });
              break;
            case 'Kechikish':
              newConfig.push({
                title: t('home.Kechikish'),
                dataIndex: 'delay'
              });
              break;
            case 'Masul':
              newConfig.push({
                title: t('home.Masul'),
                align: 'left',
                dataIndex: 'responsibleBy'
              });
              break;
            default:
              break;
          }
        }
      });
      newConfig.push({
        width: '80px',
        render: (record: DebtList) => (
          <Action
            setSurchargeDeleteModalVisible={setSurchargeDeleteModalVisible}
            record={record}
            handleTaskCreateModalOpen={handleTaskCreateModalOpen}
            handleSendMessageModalOpen={handleSendMessageModalOpen}
          />
        )
      });
      setColumn(newConfig);
    }
  }, [tableConfig, tableConfigFetch, location.search]);

  useEffect(() => {
    const newConfig: any[] = [...column];

    if (tableConfig.length === 0) {
      newConfig[3] = {
        title: (
          <div onClick={() => handleOrders('AMOUNT')} className="orderTableCont">
            <div>{t('home.Qarz_summasi')}</div>
            <div className="orderIcons">
              <TableOrderIcon className={getClassNameOrder('AMOUNT', 'ASC', searchParams.order)} />
              <TableOrderIcon className={getClassNameOrder('AMOUNT', 'DESC', searchParams.order)} />
            </div>
          </div>
        ),
        render: (record: DebtList) => (
          <span style={{ color: '#E53535' }}>
            {record.amount?.toLocaleString('ru')} {record.currency?.ccy}
          </span>
        )
      };
      newConfig[8] = {
        title: (
          <div onClick={() => handleOrders('DATE')} className="orderTableCont">
            <span>{t('home.Sana')}</span>
            <div className="orderIcons">
              <TableOrderIcon className={getClassNameOrder('DATE', 'ASC', searchParams.order)} />
              <TableOrderIcon className={getClassNameOrder('DATE', 'DESC', searchParams.order)} />
            </div>
          </div>
        ),
        dataIndex: 'date',
        render: (date: string) => <span style={{ color: '#A3A3A3' }}>{date}</span>
      };
      newConfig[6] = {
        title: (
          <div onClick={() => handleOrders('SURCHARGE')} className="orderTableCont">
            <div>{t('home.Peniya')}</div>
            <div className="orderIcons">
              <TableOrderIcon className={getClassNameOrder('SURCHARGE', 'ASC', searchParams.order)} />
              <TableOrderIcon className={getClassNameOrder('SURCHARGE', 'DESC', searchParams.order)} />
            </div>
          </div>
        ),
        render: (record: DebtList) =>
          record.surcharge !== 0 ? (
            <span style={{ color: '#E53535' }}>
              {record.surcharge?.toLocaleString('ru')} {record.currency?.ccy}
            </span>
          ) : (
            <span>-</span>
          )
      };
      newConfig[5] = {
        title: t('home.Maksimal_kechikish_muddati'),
        dataIndex: 'maxDelay'
      };
      newConfig[9] = {
        width: '80px',
        render: (record: DebtList) => (
          <Action
            setSurchargeDeleteModalVisible={setSurchargeDeleteModalVisible}
            record={record}
            handleTaskCreateModalOpen={handleTaskCreateModalOpen}
            handleSendMessageModalOpen={handleSendMessageModalOpen}
          />
        )
      };
      setColumn(newConfig);
    }
  }, [location.search]);

  const handleOrders = (key: string) => {
    orderTable(key);
  };

  const handleSendMessageModalOpen = (id: number, name: string, phoneNumber: string, contractNumber: string) => {
    setSendMessageModal({
      id,
      name,
      phoneNumber,
      contractNumber,
      visible: true
    });
  };

  // handleTaskCreateModalOpen
  const handleTaskCreateModalOpen = (visible: boolean, id?: number, arrearage?: number, clientId?: number) => {
    setOpenTaskCreateModal({
      visible,
      id,
      arrearage: arrearage || 0,
      clientId
    });
  };

  const handleCloseSendMessageModal = () => {
    setSendMessageModal({
      id: 0,
      name: '',
      phoneNumber: '',
      contractNumber: '',
      visible: false
    });
  };
  const onSelectChange = (e: CheckboxChangeEvent) => {
    dispatch(setChecked(e.target.checked));
    if (e.target.checked) {
      dispatch(setDebtIgnoreIds([]));
      dispatch(setDebtActiveIds({ ids: null }));
    } else {
      dispatch(setDebtActiveIds({ ids: [] }));
    }
  };

  const dataIds = data?.map(item => item?.contractId);
  const rowSelection: TableRowSelection<DebtList> | undefined = {
    onSelect: (record, selected) => {
      if (selected) {
        dispatch(setDebtIgnoreIds(debtListIgnoredIds?.filter(item => item !== record?.contractId)));
        if (!checked) {
          dispatch(
            setDebtActiveIds({
              ids: activeIds ? [...activeIds, record?.contractId] : [record?.contractId]
            })
          );
        }
      } else {
        dispatch(setDebtIgnoreIds(debtListIgnoredIds ? [...debtListIgnoredIds, record?.contractId] : [record?.contractId]));
        if (!checked && activeIds) {
          dispatch(
            setDebtActiveIds({
              ids: activeIds?.filter(item => item !== record?.contractId)
            })
          );
        }
      }
    },
    selectedRowKeys: activeIds === null ? dataIds?.filter(item => !debtListIgnoredIds?.some(ignore => ignore === item)) : activeIds,
    columnTitle: (
      <Checkbox indeterminate={(debtListIgnoredIds?.length !== 0 && checked) || (Array.isArray(activeIds) && activeIds?.length > 0)} checked={checked} onChange={onSelectChange} />
    )
  };

  const { push } = useQueryParam();

  const goToOneContract = (e: DebtList) => {
    push(`${rootPaths.MAIN}${MainPaths.CONTRACT}/${e.contractId}/${urlEncode(`№ ${e.contractNumber}`)}`);
  };

  return (
    <>
      <Table
        className={`custom_table contract_table_size table_cursor_pointer ${styles.client_table}`}
        columns={column}
        dataSource={data}
        loading={isLoading || tableConfigFetch || isFetching}
        size="small"
        pagination={false}
        rowSelection={rowSelection}
        rowKey={record => record?.contractId}
        onRow={(e: any) => ({
          onClick: () => goToOneContract(e)
        })}
      />
      <ArrearageDeleteModal visible={surchargeDeleteModalVisible.visible} setVisible={setSurchargeDeleteModalVisible} id={surchargeDeleteModalVisible.id} />
      <ClientMessageModal
        modalWidth={717}
        visible={sendMessageModal.visible}
        close={handleCloseSendMessageModal}
        clientId={sendMessageModal.id}
        phoneNumber={sendMessageModal.phoneNumber}
        name={sendMessageModal.name}
        contractNumber={sendMessageModal.contractNumber}
      />
      <CreateTaskModal
        arrearage={openTaskCreateModal.arrearage}
        visible={openTaskCreateModal.visible}
        onClose={handleTaskCreateModalOpen}
        id={openTaskCreateModal.id || 0}
        clientId={openTaskCreateModal.clientId || 0}
      />
    </>
  );
};

export default ArrearageTable;
