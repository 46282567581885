import React from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { $api } from '@service/RequestService';
import { discountEndpoints } from '@utils/constants/apis';
import { message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { MaxDiscount, maxDiscountSchema } from '../schema';

import { UpdateForm, updateSchema } from './schema';

interface UpdateProps {
  defaultValues?: Partial<UpdateForm>;
  children: (props: UseFormReturn<UpdateForm>) => React.ReactNode;
}

const Update: React.FC<UpdateProps> = ({ children, defaultValues }) => {
  const client = useQueryClient();
  const form = useForm<UpdateForm>({ resolver: zodResolver(updateSchema), defaultValues: { ...defaultValues } });
  const mutation = useMutation<MaxDiscount, any, UpdateForm>(
    async values => {
      const { data } = await $api.post(discountEndpoints.MAX_DISCOUNT, values);
      const maxDiscount = maxDiscountSchema.parse(data.data);

      return maxDiscount;
    },
    {
      onSuccess: async () => {
        await client.invalidateQueries({ queryKey: ['max-discount'] });
        message.success('Muvaffaqqiyatli');
        form.reset();
      }
    }
  );

  const onSubmit = async (data: UpdateForm) => {
    await new Promise(onSettled => mutation.mutate(data, { onSettled }));
  };

  return (
    <form className="w-full" onSubmit={form.handleSubmit(onSubmit)}>
      {children(form)}
    </form>
  );
};

export default Update;
