import { $api } from '@service/RequestService';
import { useMutation } from 'react-query';
import { endPoints } from '../utils/constants/endPoints';
import { ClientLeadModel } from '@utils/models/ClientLeadModel';

export function useCreateDefaultLead() {
  return useMutation({
    mutationFn: async (phoneNumber: string) => {
      const res = await $api.post(endPoints.DEFAULT_CREATE + `?phoneNumber=%2B998${phoneNumber}`);
      return res.data;
    }
  });
}
