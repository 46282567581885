export const endPoints = {
  LEAD_FILTER: '/v1/lead/filter',
  LEAD_FILTER_V2: '/v1/lead/filter/v2',
  CRM_TRIGGERS: '/v1/crm/triggers',
  CRM_TRIGGERS_TASK_TRIGGER: '/v1/crm/triggers/task-trigger',
  CRM_TRIGGERS_PIPE_STATUS_TRIGGER: '/v1/crm/triggers/pipe-status-trigger',
  CRM_TRIGGERS_PIPE_STATUS_TRIGGERS: '/v1/crm/triggers/pipe-status-triggers',
  CRM_TRIGGERS_MANAGER_TRIGGER: '/v1/crm/triggers/manager-trigger',
  REASONS_FOR_REFUSAL: '/v1/reasons-for-refusal/',
  PIPE_STATUS: '/v1/status/',
  PIPE_CREATE: '/v1/pipe/create',
  PIPE: '/v1/pipe/',
  LEAD_TABLE_FILTERS: '/v1/lead/table-filter',
  LEAD_TABLE_FILTERS_V2: '/v1/lead/table-filter/v2',
  LEAD_TABLE_FILTERS_LEAD_IDS: '/v1/lead/table-filter/lead-ids',
  LEAD_GROUP_ACTION: '/v1/lead/group-action',
  LEAD_BY: '/v1/lead/by',
  LEAD_IMPORT: '/v1/lead/import',
  LEAD_IMPORT_EXAMPLE: '/v1/lead/import-example',
  LEAD_EXPORT: '/v1/lead/export',
  ONE_CLIENT: '/v1/client/',
  CLIENT_VIEW: '/v1/client/view/',
  CRM_CURRENT_USER_ROLE: '/v1/crm/crm-role/current-user',
  MAKE_EMPLOYEE_RESPONSIBLE: '/v1/online-pbx/lead-setting'
};
