import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import styles from './amoCrm.module.scss';
import DocumentCopyIcon from '../../../assets/icons/DocumentCopyIcon';
import { useTranslation } from 'react-i18next';
import { AmoCrm, AmoCrmEdit } from '../../../utils/models/AmoCrm';
import { AMO_CRM_REDIRECT_URI } from '../../../utils/constants/amoCrmRedirecUri';
import { loginGenerator, passwordGenerator } from '../../../utils/helper/generators';
import { useCreateAmoCrm, useEditAmoCrm } from '../../../queries/mutation';

interface Props {
  visible: boolean;
  onClose: (open: boolean) => void;
  data: AmoCrm | null | undefined;
}

const AddCrmModal: React.FC<Props> = ({ visible, onClose, data }) => {
  const [form] = Form.useForm<AmoCrmEdit>();
  const [password, setPassword] = useState(passwordGenerator(32));
  const [login, setLogin] = useState(loginGenerator(8));

  const { t } = useTranslation();

  const createAmoCrm = useCreateAmoCrm(onCloseModal);
  const editAmoCrm = useEditAmoCrm(onCloseModal);

  useEffect(() => {
    if (visible && data?.id) {
      form.setFieldsValue({
        client_id: data.client_id,
        code: data.code,
        client_secret: data.client_secret,
        sub_domain: data.subDomain
      });
      setLogin(data.username);
      setPassword(data.password);
    }
  }, [visible, data, form]);

  function onCloseModal() {
    onClose(false);
    form.resetFields();
  }

  const handleCreateAmoCrm = (values: AmoCrmEdit) => {
    createAmoCrm.mutate({
      ...values,
      code: values.code?.trim(),
      sub_domain: values.code?.trim(),
      client_secret: values.client_secret?.trim(),
      client_id: values.client_id?.trim(),
      password,
      username: login,
      redirect_uri: AMO_CRM_REDIRECT_URI
    });
  };

  const handleEditAmoCrm = (values: AmoCrmEdit) => {
    editAmoCrm.mutate({
      ...values,
      code: values.code?.trim(),
      sub_domain: values.code?.trim(),
      client_secret: values.client_secret?.trim(),
      client_id: values.client_id?.trim(),
      password,
      username: login,
      redirect_uri: AMO_CRM_REDIRECT_URI
    });
  };

  const onFinish = (values: AmoCrmEdit) => {
    if (data?.id) {
      handleEditAmoCrm(values);
    } else {
      handleCreateAmoCrm(values);
    }
  };

  const copyClickBoard = (val: string) => {
    navigator.clipboard.writeText(val);
    message.success(t('integration.Nusxa_olindi'));
  };

  return (
    <Modal
      title={t('integration.AmoCRM_ornatish')}
      wrapClassName="settingEmployeeModal"
      visible={visible}
      onCancel={onCloseModal}
      footer={false}
      width={546}
    >
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Row gutter={8} className={'d-flex justify-space-between'}>
          <Col span={24}>
            <p className={styles.createModalLabel}>{t('integration.Redirect_url')}</p>
            <Input
              value={AMO_CRM_REDIRECT_URI}
              className={'customInput'}
              suffix={<DocumentCopyIcon onClick={() => copyClickBoard(AMO_CRM_REDIRECT_URI)} className={styles.copy_button} />}
            />
          </Col>
          <Col span={24}>
            <p className={styles.createModalLabel}>{t('integration.Login')}</p>
            <Input
              value={login}
              className={'customInput'}
              suffix={<DocumentCopyIcon onClick={() => copyClickBoard(login)} className={styles.copy_button} />}
            />
          </Col>
          <Col span={24}>
            <p className={styles.createModalLabel}>{t('integration.Parol')}</p>
            <Input
              value={password}
              className={'customInput'}
              suffix={<DocumentCopyIcon onClick={() => copyClickBoard(password)} className={styles.copy_button} />}
            />
            <hr className={styles.line} />
          </Col>
          <Col span={24}>
            <Form.Item label={t('integration.Integratsiya_ID')} name={'client_id'} rules={[{ required: true, message: '' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={'client_secret'} label={t('integration.Yashirin_kalit')} rules={[{ required: true, message: '' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('integration.Avtorizatsiya_kodi')}
              name="code"
              rules={[
                {
                  required: true,
                  message: ''
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('integration.Subdomen')}
              name="sub_domain"
              rules={[
                {
                  required: true,
                  message: ''
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <div className="settingEmployeeModalFooter">
          <Button onClick={onCloseModal}>{t('integration.Yopish')}</Button>
          <Button loading={createAmoCrm.isLoading || editAmoCrm.isLoading} htmlType="submit" type="primary">
            {t('integration.Ornatish')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddCrmModal;
