import { PriceCalculateType } from '@utils/models/PriceCalculateType';
import { UseMutationResult } from 'react-query';

import { FlatId } from '../../../utils/models/Flat';

interface IProps {
  setSaveLoad: React.Dispatch<React.SetStateAction<boolean>>;
  activeKey: number;
  saveFlat: UseMutationResult<any, any, FlatId[], unknown>;
  shaxmatkaDelete: number[];
  flatDelete: UseMutationResult<any, any, any[], unknown>;
  setSaveAll: (value: React.SetStateAction<boolean>) => void;
  priceCalculateType: keyof typeof PriceCalculateType;
  shaxmatka?: FlatId[];
  tableMode?: boolean;
}

export const allSave = (props: IProps) => {
  const arr: FlatId[] = [];
  const mSpinning: any = document.querySelector('.mSpinning');

  mSpinning.style.display = 'flex';
  props.setSaveLoad(true);

  const pushArr = (room: any) => {
    arr.push({
      ...room,
      buildingID: Number(props.activeKey),
      rooms: room.rooms === 0 ? null : room.rooms,
      area: room.area === 0 ? null : room.area,
      terrace: room.terrace ? room.terrace : null,
      optionIDList: null,
      prepaymentPercent: room.prepaymentPercent ? room.prepaymentPercent : 0,
      // prepayment: room.prepayment === 0 ? null : room.prepayment,
      // price: room.price === 0 ? null : room.price,
      // pricePerArea: room.pricePerArea === 0 ? null : room.pricePerArea,
      // pricePerAreaRepaired: room.pricePerAreaRepaired === 0 ? null : room.pricePerAreaRepaired,
      // priceRepaired: room.priceRepaired === 0 ? null : room.priceRepaired,
      // priceNotRepaired: room.priceNotRepaired === 0 ? null : room.priceNotRepaired,
      gallery: room.gallery
        ? room.gallery
            .filter((image: any) => image !== null)
            .map((image: any) => ({
              id: image.id && image.id,
              position: image.position,
              queue: image.queue,
              fileID: image.files ? image.files.id : image.fileID
            }))
        : null
    });
  };

  if (props?.tableMode) {
    props?.shaxmatka?.flat(2)?.forEach(item => {
      if (!item.new) {
        pushArr(item);
      }
    });
  } else {
    document.querySelectorAll('.selectableItems').forEach((item: any) => {
      const room = JSON.parse(item.getAttribute('data'));

      pushArr(room);
    });
  }

  const saveArr: any = arr?.map(flat => ({
    ...flat,
    pricePerArea: props.priceCalculateType !== 'STATIC' ? flat?.pricePerArea : null,
    priceNotRepaired: props.priceCalculateType !== 'STATIC' ? null : flat?.priceNotRepaired,
    pricePerAreaRepaired: props.priceCalculateType !== 'STATIC' ? flat?.pricePerAreaRepaired : null,
    priceRepaired: props.priceCalculateType !== 'STATIC' ? null : flat?.priceRepaired
  }));

  props.saveFlat.mutate(saveArr);
  if (props.shaxmatkaDelete.length > 0) props.flatDelete.mutate(props.shaxmatkaDelete);
  props.setSaveAll(true);
};
