/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import InternationalPhoneNumber from '@components/international-phone-input/InternationalPhoneNumber';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { setClientPhone, setSearchByPhone } from '@reducers/ClientSlice';
import { BronIdOrFlatIds } from '@utils/models/BronIdOrFlatIds';
import { Button, Col, Form, Input, Modal, Row, Select, Space, Spin } from 'antd';
import { isValidNumber } from 'libphonenumber-js';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

import { useQueryParam } from '../../hooks/useQueryParams';
import { SearchParamsBroker } from '../../pages/broker/Broker';
import { useCreateStaff, useUpdateStaff } from '../../queries/mutation';
import { useGetDistrict, useOneStaff, useRegions } from '../../queries/queries';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import { helper } from '../../utils/helper/helper';
import { ACTION_EVENTS_GA, TYPE_EVENTS_GA } from '../../utils/models/GoogleAnalitks';

import styles from '../../pages/builderSettings/builderSettings.module.scss';

interface IProps {
  visible: boolean;
  onClose: (visible: boolean) => void;
  tableSize: number;
  id?: number;
}

interface IForm {
  fullName: string;
  districtId: number;
  region: number;
  address: string;
  phone: string;
  phoneCode: string;
}

const BrokerModal: React.FC<IProps> = ({ visible, onClose, id, tableSize }) => {
  const { searchParams } = useQueryParam<SearchParamsBroker, any>();
  const [form] = Form.useForm<IForm>();

  const { data: regions, isLoading: loadingRegions } = useRegions();
  const { data, isLoading } = useOneStaff(id || -1);
  const [regionId, setRegionId] = useState<number>(0);

  const handleCancel = () => {
    onClose(false);
    form.setFieldsValue({
      fullName: '',
      districtId: undefined,
      region: undefined,
      address: '',
      phone: ''
    });
  };

  function onSuccess() {
    handleCancel();
  }

  const createStaff = useCreateStaff(tableSize, searchParams, onSuccess);
  const updateStaff = useUpdateStaff(tableSize, searchParams, onSuccess, id || -1);

  const { data: districts, isLoading: districtsLoading } = useGetDistrict(regionId, 'region');

  const { t } = useTranslation();

  const { clientPhones } = useAppSelector(state => state.clientReducer);
  const dispatch = useAppDispatch();
  const params = useParams<{ orderIdOrFlatId: string }>();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        fullName: data.fullName,
        districtId: data.districtId,
        region: data.cityId,
        address: data.address,
        phone: data.phone,
        phoneCode: data.phoneCode
      });
      setRegionId(data?.cityId || 0);
    }
  }, [data, form, visible]);

  // create broker events
  const createBrokerEvent = () => {
    ReactGA.event({
      category: TYPE_EVENTS_GA.KPI,
      action: ACTION_EVENTS_GA.CREATE_BROKER_INSIDE_KPI
    });
  };

  const onFinish = (fields: IForm) => {
    if (!id) {
      createStaff.mutate({
        ...fields,
        fullName: fields.fullName?.trim(),
        phone: fields.phone,
        phoneCode: fields.phoneCode
      });
      createBrokerEvent();
    } else {
      updateStaff.mutate({
        ...fields,
        id,
        fullName: fields.fullName?.trim(),
        phone: fields.phone,
        phoneCode: fields.phoneCode
      });
    }
  };

  const handleOk = () => {
    handleCancel();
  };

  const changeRegion = (id: number) => {
    form.setFieldsValue({
      districtId: undefined
    });
    setRegionId(id);
  };

  const handleSearchClient = (phone: string, index: number) => {
    dispatch(setClientPhone({ index, phone }));
    if (!params.orderIdOrFlatId?.includes(BronIdOrFlatIds.BRON_ID)) {
      const phoneNumber = helper.deleteNotNumbers(phone, 1);

      if (index === 0 && String(phoneNumber).length >= 3) {
        dispatch(setSearchByPhone(phoneNumber));
      }
    }
  };

  return (
    <Modal
      width={774}
      className="createBuilderModal"
      title={id ? t('broker.Vositachini_yangilash') : t('broker.Vositachi_yaratish')}
      visible={visible}
      onOk={handleOk}
      footer={false}
      onCancel={handleCancel}
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical" onFinish={onFinish} className={styles.formBuilderModal}>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item rules={[{ required: true, message: '' }]} name="fullName" label={t('home.Vositachi')}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              {clientPhones.map((phone, index) => (
                <Space direction="horizontal" className="d-flex" key={phone?.id}>
                  <div>
                    <Form.Item name="phoneCode" className="d-none" />

                    <div className="flex flex-col gap-[5px]">
                      <Form.Item
                        label={<span>{t(`changes.client.NaturalClient.Telefon_raqam`)}</span>}
                        name="phone"
                        rules={[
                          {
                            validator: (rule, value) => {
                              if (!(value && isValidNumber(value))) {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise.reject('');
                              }
                              return Promise.resolve();
                            }
                          }
                        ]}
                      >
                        <InternationalPhoneNumber
                          defaultCountry="uz"
                          className={styles.phoneInput2}
                          onChange={(e, phone) => {
                            form.setFieldsValue({
                              [`phoneCode`]: `${phone?.country?.iso2?.toUpperCase()}`
                            });
                          }}
                        />
                      </Form.Item>

                      {/* {!isPhoneValid(phoneValue) && <span className="mt-[-10px] text-red-500">{t('profile.Telefon_raqamini_kiriting')}</span>} */}
                    </div>
                  </div>
                </Space>
              ))}
            </Col>
            <Col span={12}>
              <Form.Item label={t('home.Viloyat')} name="region" rules={[{ required: true, message: '' }]}>
                <Select optionFilterProp="children" showSearch className="customSelect" placeholder={t('home.Tanlang')} loading={loadingRegions} onChange={i => changeRegion(i)}>
                  {regions?.map(region => (
                    <Select.Option key={region.id} value={region.id}>
                      {getCurrentLanguageWord(region)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('home.Shaxar')} name="districtId" rules={[{ required: true, message: '' }]}>
                <Select optionFilterProp="children" showSearch className="customSelect" placeholder={t('home.Tanlang')} loading={districtsLoading}>
                  {districts?.map(district => (
                    <Select.Option key={district.id} value={district.id}>
                      {getCurrentLanguageWord(district)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item rules={[{ required: true, message: '' }]} name="address" label={t('home.Manzil')}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.modalFooter}>
            <Button onClick={handleCancel}> {t('home.Yopish')}</Button>
            <Button loading={createStaff.isLoading || updateStaff.isLoading} htmlType="submit" type="primary">
              {t('home.Saqlash')}
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default BrokerModal;
