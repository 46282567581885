import React, { FC } from 'react';
import { PipeStatuses } from '../../../../utils/models/PipeAll';
import ReasonsForRefusal from './reasonsRefusal/ReasonsRefusal';
import styles from './voronkaCards.module.scss';
import AddTriggerButton from './triggers/addTriggerButton/AddTriggerButton';
import TriggerItem from './triggers/triggerItem/TriggerItem';
import { Spin } from 'antd';
import { useTriggersList } from '../../services/queries';

interface IProps {
  column: PipeStatuses;
}

const VoronkaCardColumnEmty: FC<IProps> = ({ column }) => {
  const { data, isLoading } = useTriggersList(column.id as number);

  return (
    <div className={styles.voronkaCards_column_empty}>
      <Spin spinning={isLoading}>
        {column?.type === 'FAILED' && <ReasonsForRefusal />}
        {Array.from({ length: 40 }).map((_, index) => {
          const trigger = data?.find(item => item.order === index + 1);
          if (trigger) {
            return <TriggerItem pipeStatusId={column.id as number} trigger={trigger} index={index} />;
          }
          return <AddTriggerButton pipeStatusId={column.id as number} index={index} key={index} />;
        })}
      </Spin>
    </div>
  );
};

export default VoronkaCardColumnEmty;
