/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import DeleteIconV2 from '@icons/areaarage/DeleteIconV2';
import FileIcon from '@icons/FileIcon';
import UploadFileIcon from '@icons/UploadFileIcon';
import { useUploadFile } from '@queries/mutation';
import { FileObject } from '@utils/models/File';
import { Button, Col, Form, Input, Modal, Row, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactInputMask from 'react-input-mask';

import CalendarIcon from '../../../../assets/icons/CalendarIcon';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { useCurrencyList, usePaymentConfig } from '../../../../queries/queries';
import { helper } from '../../../../utils/helper/helper';
import { validateDateString } from '../../../../utils/helper/validatorDateString';
import { ChangesQueryParams } from '../../../../utils/models/Changes';
import { PaymentType } from '../../../../utils/models/PaymentType';
import { useCreatePaymentChangesPage } from '../../services/mutation';

interface FileNameProps {
  originalName: string;
}

interface Props {
  visible: boolean;
  onClose: (open: boolean) => void;
}

interface FormValues {
  createdDate: string;
  amount: string;
  type: string;
  number: string;
  currencyId: number | undefined;
  note: string;
}

const { Option } = Select;

const CreatePaymentModal: React.FC<Props> = ({ visible, onClose }) => {
  const { searchParams } = useQueryParam<ChangesQueryParams, any>();
  const [form] = Form.useForm<FormValues>();

  const { data: currencyList, isLoading: currencyLoading } = useCurrencyList();

  const { currency } = useAppSelector(state => state.changesReducer);

  const { data, isLoading } = usePaymentConfig();

  const { t } = useTranslation();

  const selectedPaymentType = Form.useWatch('type', form);

  const [files, setFiles] = useState<FileObject[]>();

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const createPayment = useCreatePaymentChangesPage(searchParams.contractId, onCloseModal);

  useEffect(() => {
    if (currency && visible) {
      form.setFieldsValue({
        currencyId: currency?.id
      });
    }
  }, [currency, visible, form]);

  function onCloseModal() {
    onClose(false);
    form.setFieldsValue({
      createdDate: '',
      amount: '',
      type: '',
      number: '',
      currencyId: currency?.id,
      note: ''
    });
  }

  // const onFinish = (values: FormValues) => {
  //   const findCurrency = currencyList?.find(item => item.id === values.currencyId);

  //   const fileIds = files?.map(file => file?.id);

  //   let paymentData = {
  //     createdDate: values.createdDate,
  //     number: values.number,
  //     amount: helper.parseFloatStringToNumber(values.amount),
  //     type: values.type,
  //     currency: findCurrency,
  //     files: fileIds?.length === 0 ? null : fileIds
  //   }

  //   if (selectedPaymentType !== "OTHERS") {
  //     paymentData = { ...paymentData, files: null }
  //   }

  //   createPayment.mutate(paymentData);
  // };

  const onFinish = (values: FormValues) => {
    const findCurrency = currencyList?.find(item => item.id === values.currencyId);

    // Преобразуем массив fileIds в массив объектов с id
    const fileIds = files?.map(file => ({ id: file?.id }));

    let paymentData = {
      createdDate: values.createdDate,
      number: values.number,
      amount: helper.parseFloatStringToNumber(values.amount),
      type: values.type,
      currency: findCurrency,
      files: fileIds?.length === 0 ? null : fileIds,
      note: values?.note
    };

    if (selectedPaymentType !== 'OTHERS') {
      paymentData = { ...paymentData, files: null };
    }

    createPayment.mutate(paymentData);
  };

  function getFile(data: FileObject[]) {
    if (files) {
      setFiles([...files, ...data]);
    } else {
      setFiles(data);
    }
  }

  const uploadFile = useUploadFile(getFile);

  const changeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0] && e.target.files[0]?.size <= 19000000) {
        const file = e.target.files[0];
        const formData = new FormData();

        formData.append('files', file);
        uploadFile.mutate(formData);
        e.target.value = '';
      }
    }
  };

  const deleteFile = (id: number) => {
    setFiles(files?.filter(item => item?.id !== id));
  };

  const toKilobytes = (bytes: number) => (bytes / 1024).toFixed(2);

  useEffect(() => {
    const documentPaste = (e: ClipboardEvent) => {
      const clipDataArr: any = e.clipboardData?.items;
      const items: any[] = [...clipDataArr];

      items.forEach(item => {
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          const reader = new FileReader();

          reader.onload = function (event) {
            const file = blob;
            const formData = new FormData();

            formData.append('files', file);
            uploadFile.mutate(formData);
          }; // data url!
          reader.readAsDataURL(blob);
        }
      });
    };

    document.addEventListener('paste', documentPaste);
    return () => {
      document.removeEventListener('paste', documentPaste);
    };
  }, [uploadFile]);

  const FileName: React.FC<FileNameProps> = ({ originalName }) => {
    const truncateText = (text: string, maxLength: number) => {
      if (text.length > maxLength) {
        return `${text.slice(0, maxLength - 1)  }...`;
      }
      return text;
    };

    const getTruncatedFileName = (fileName: string, maxLength: number) => {
      const extensionIndex = fileName.lastIndexOf('.');
      const name = fileName.substring(0, extensionIndex);
      const extension = fileName.substring(extensionIndex);

      if (name.length > maxLength) {
        return `${truncateText(name, maxLength)  }(${extension})`;
      }
      return fileName;
    };

    const truncatedFileName = getTruncatedFileName(originalName, 30);

    return (
      <Tooltip title={originalName} overlayInnerStyle={{ borderRadius: '6px', border: '1px solid black' }}>
        <p className="text-[16px] font-medium text-[#344054]">{truncatedFileName}</p>
      </Tooltip>
    );
  };

  return (
    <Modal
      title={t('changes.Tolov_yaratish')}
      wrapClassName="settingEmployeeModal"
      visible={visible}
      // eslint-disable-next-line react/jsx-no-bind
      onCancel={onCloseModal}
      footer={false}
      width={546}
    >
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Row gutter={8} className="d-flex justify-space-between">
          <Col span={12}>
            <Form.Item
              label={t('changes.Tolov_summasi')}
              name="amount"
              rules={[
                {
                  validator: (rule, value) => {
                    if (value) {
                      form.setFieldsValue({
                        // @ts-ignore
                        amount: helper.deleteNotNumbersAndParseFloatNumber(value).string
                      });
                    }
                    return Promise.resolve();
                  }
                },
                { required: true, message: '' }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('changes.Tolov_raqami')} name="number" rules={[{ required: true, message: '' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                  message: ''
                }
              ]}
              label={t('changes.Tolov_turi')}
            >
              <Select loading={isLoading} style={{ border: '6px' }} className="customSelect">
                {data?.types.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Option key={index} value={PaymentType[item]}>
                    {t(`home.${PaymentType[item]}`)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t('changes.Sana')}
              name="createdDate"
              rules={[
                {
                  validator: (rule, value) => {
                    if (value) {
                      form.setFieldsValue({
                        createdDate: validateDateString(value, false)
                      });
                    }
                    return Promise.resolve();
                  }
                },
                {
                  required: true,
                  len: 10,
                  message: ''
                }
              ]}
            >
              <ReactInputMask
                mask="99.99.9999"
                // @ts-ignore*
                maskChar=""
              >
                {/* @ts-expect-error */}
                {(inputProps: any) => <Input placeholder={t('client_create_contract.kk_oo_yyyy')} suffix={<CalendarIcon />} {...inputProps} />}
              </ReactInputMask>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="currencyId"
              rules={[
                {
                  required: true,
                  message: ''
                }
              ]}
              label={t('create_payment.Valyuta')}
            >
              <Select loading={currencyLoading} style={{ border: '6px' }} className="customSelect">
                {currencyList?.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Option key={index} value={item.id}>
                    {item.ccy}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="note" label={t('home.Izoh')}>
          <Input className="customInput" />
        </Form.Item>

        <div className="mb-8">
          {selectedPaymentType === 'OTHERS' && (
            <div>
              <label
                htmlFor="chatFile"
                className="flex cursor-pointer items-center justify-center rounded-xl border-[1px] border-[#EAECF0] px-[24px] py-[16px]"
                style={{ border: '1px solid #EAECF0' }}
              >
                <div>
                  <label htmlFor="chatFile" className="ml-auto mr-auto flex items-center justify-center">
                    {uploadFile.isLoading && <LoadingOutlined />}
                    <span className="cursor-pointer">
                      <UploadFileIcon />
                    </span>
                    <input type="file" id="chatFile" style={{ display: 'none' }} onChange={e => changeFile(e)} />
                  </label>
                  <p className="ml-auto mr-auto text-[12px] font-semibold text-[#1E90FF]">{t('home.Fayl_yuklash_uchun_bosing')}</p>
                  <div className="mt-1px] *Maksimal fayl hajmi 19MB*">
                    <p className="text-[9px] font-medium text-[#2b2828bd]">*{t('home.maximal_size')} 19MB*</p>
                  </div>
                </div>
              </label>

              <div>
                {files?.map(item => (
                  <div key={item?.id} className="mt-3 flex items-center justify-between rounded-xl bg-[#F9FAFB] p-[16px]" style={{ border: '1px solid #E5E7EB' }}>
                    <div className="flex items-center gap-3">
                      <span className="flex items-center justify-center rounded-[10px] border-[1px] bg-[#EFF1F5] p-[8px]" style={{ border: '1px solid #E5E7EB' }}>
                        <FileIcon />
                      </span>
                      <div>
                        <FileName originalName={item?.originalName} />
                        {/* <p className='text-[#344054] font-medium text-[16px]'>{item?.originalName}{' '}</p> */}
                        <p className="text-[16px] font-medium text-[#98A2B3]">{item?.size ? `${toKilobytes(item?.size)} KB` : 'N/A'}</p>
                      </div>
                    </div>
                    <span className="cursor-pointer" onClick={() => deleteFile(item?.id)}>
                      <DeleteIconV2 />
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="settingEmployeeModalFooter">
          <Button onClick={onCloseModal}>{t('changes.Yopish')}</Button>
          <Button loading={createPayment.isLoading} htmlType="submit" type="primary">
            {t('changes.Tasdiqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreatePaymentModal;
