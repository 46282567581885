import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useCurrencyList, useMobileHouse } from '@queries/queries';
import { Checkbox, Dropdown, Select, Spin } from 'antd';
import { DATE_FORMAT } from '@utils/constants/format';
import { useBossPaymentStatistic } from './services/queries';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import ArrowDownIcon from '@icons/ArrowDownIconCrm';
import DatePicker from '@service/datePicker';

import styles from './bossClientStatistic.module.scss';

const { Option } = Select;

const BossClientStatistic = () => {
  const { data: houses } = useMobileHouse();
  const { data: currency } = useCurrencyList();
  const currentCurrency = currency?.find(item => item?.basic === true)?.id;
  const currentValyuta = currency?.find(item => item?.basic === true)?.ccy;
  const [currencyVal, setCurrencyVal] = useState<string | undefined>('');
  const [valyuta, setValyuta] = useState(currentValyuta);

  useEffect(() => {
    setCurrencyVal(String(currentValyuta));
    setValyuta(currentValyuta);
  }, [currentCurrency]);

  const [filter, setFilter] = useState<{
    date: string | undefined;
    house: number[] | null;
  }>({
    date: '',
    house: null
  });
  const { data: radar, isLoading } = useBossPaymentStatistic(filter.date || '', filter.house, currencyVal === currentValyuta ? '' : currencyVal);

  const changeHouse = (e: CheckboxChangeEvent, id: number) => {
    if (e.target.checked) {
      setFilter(prev => ({
        ...prev,
        house: [...(prev.house || []), id]
      }));
    } else {
      setFilter(prev => ({
        ...prev,
        house: prev.house?.filter(item => item !== id) || null
      }));
    }
  };

  const onlyWidth = 100 / radar?.expectedDebt!;
  const onlyWidth2 = 100 / radar?.expectedMonthlyPayment!;

  return (
    <div className={styles.statistic}>
      <div className={styles.top}>
        <div className={styles.top_spin}>
          <h1>{t('boss_payments.Kutulayotgan_tolovlar')}</h1>
          <Spin spinning={isLoading}></Spin>
        </div>
        <div className={styles.top_spin}>
          <Select
            className={styles.currency}
            value={currencyVal}
            onChange={e => {
              setCurrencyVal(e);
              setValyuta(currency?.find(item => item?.id === Number(e))?.ccy);
            }}
            placeholder="Valyuta"
          >
            {currency?.map(item => (
              <Option key={item?.id} value={item?.id}>
                {item?.ccy}
              </Option>
            ))}
          </Select>
          <DatePicker
            picker="month"
            style={{ borderRadius: 6 }}
            placeholder={t('home.Sana')}
            format={'MMM YYYY'}
            onChange={value =>
              setFilter(prev => ({
                ...prev,
                date: value?.startOf('month')?.format(DATE_FORMAT)
              }))
            }
          />
          <Dropdown
            overlay={
              <div className={styles.houses}>
                <Checkbox
                  checked={houses?.length === filter?.house?.length}
                  onChange={e => {
                    if (e.target.checked) {
                      setFilter(prev => ({
                        ...prev,
                        house: [...(houses?.map(item => item?.id) || [])]
                      }));
                    } else {
                      setFilter(prev => ({
                        ...prev,
                        house: null
                      }));
                    }
                  }}
                >
                  {t('home.Barchasi')}
                </Checkbox>
                {houses?.map(item => (
                  <Checkbox key={item?.id} checked={filter.house?.includes(item?.id)} onChange={e => changeHouse(e, item?.id)}>
                    {item?.name}
                  </Checkbox>
                ))}
              </div>
            }
            trigger={['click', 'hover']}
            mouseLeaveDelay={0.1}
            mouseEnterDelay={1000}
          >
            <div className={styles.houseBtn}>
              {filter?.house?.length ? <span>{filter?.house?.length}</span> : ''}
              {filter?.house?.length! > 0 ? t('home.TJM_Tanlandi') : t('home.Barcha_TJM_lar')}
              <ArrowDownIcon />
            </div>
          </Dropdown>
        </div>
      </div>
      <div className={styles.newStyle}>
        <div className={styles.expectedPayments}>
          <p>{t('boss_payments.Grafik_boyicha')}</p>
          <div className={styles.paymentsBottom}>
            <div className={styles.paymentsBottom_top}>
              {radar?.expectedMonthlyPayment ? radar?.expectedMonthlyPayment?.toLocaleString('ru') : 0} {valyuta === 'UZS' ? t('home.sum') : '$'}
            </div>
            <div className={styles.paymentsBottom_bottom}>
              <div
                className={styles.bottomFirst}
                style={{
                  width:
                    Number(radar?.factMonthlyPayment) * onlyWidth2 < 1
                      ? '1%'
                      : radar?.expectedMonthlyPayment === 0
                        ? '50%'
                        : `${Number(radar?.factMonthlyPayment) * onlyWidth2}%`,
                  transition: '0.4s',
                  display: Number(radar?.factMonthlyPayment) * onlyWidth2 === 0 ? 'none' : 'block'
                }}
              ></div>
              <div
                className={styles.bottomLast}
                style={{
                  width:
                    (Number(radar?.expectedMonthlyPayment) - Number(radar?.factMonthlyPayment)) * onlyWidth2 < 1
                      ? '1%'
                      : radar?.expectedMonthlyPayment === 0
                        ? '50%'
                        : `${(Number(radar?.expectedMonthlyPayment) - Number(radar?.factMonthlyPayment)) * onlyWidth2}%`,
                  transition: '0.4s',
                  display: (Number(radar?.expectedMonthlyPayment) - Number(radar?.factMonthlyPayment)) * onlyWidth2 === 0 ? 'none' : 'block'
                }}
              ></div>
            </div>
            <div className={styles.paymentsBottom_text}>
              <span>
                {radar?.factMonthlyPayment ? radar?.factMonthlyPayment?.toLocaleString('ru') : 0} {valyuta === 'UZS' ? t('home.sum') : '$'}
              </span>
              <span>
                {radar?.expectedMonthlyPayment
                  ? (Number(radar?.expectedMonthlyPayment) - Number(radar?.factMonthlyPayment))?.toLocaleString('ru')
                  : 0}{' '}
                {valyuta === 'UZS' ? t('home.sum') : '$'}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.expectedPayments}>
          <p>{t('boss_payments.Muddati_otgan')}</p>
          <div className={styles.paymentsBottom}>
            <div className={styles.paymentsBottom_top}>
              {radar?.expectedDebt ? radar?.expectedDebt?.toLocaleString('ru') : 0} {valyuta === 'UZS' ? t('home.sum') : '$'}
            </div>
            <div className={styles.paymentsBottom_bottom}>
              <div
                className={styles.bottomFirst}
                style={{
                  width:
                    Number(radar?.factDebt) * onlyWidth < 1 ? '1%' : radar?.expectedDebt === 0 ? '50%' : `${Number(radar?.factDebt) * onlyWidth}%`,
                  transition: '0.4s',
                  display: Number(radar?.factDebt) * onlyWidth2 === 0 && radar?.expectedDebt !== 0 ? 'none' : 'block'
                }}
              ></div>
              <div
                className={styles.bottomLast}
                style={{
                  width:
                    (Number(radar?.expectedDebt) - Number(radar?.factDebt)) * onlyWidth < 1
                      ? '1%'
                      : radar?.expectedDebt === 0
                        ? '50%'
                        : `${(Number(radar?.expectedDebt) - Number(radar?.factDebt)) * onlyWidth}%`,
                  transition: '0.4s',
                  display: (Number(radar?.expectedDebt) - Number(radar?.factDebt)) * onlyWidth2 === 0 && radar?.expectedDebt !== 0 ? 'none' : 'block'
                }}
              ></div>
            </div>
            <div className={styles.paymentsBottom_text}>
              <span>
                {radar?.factDebt ? radar?.factDebt?.toLocaleString('ru') : 0} {valyuta === 'UZS' ? t('home.sum') : '$'}
              </span>
              <span>
                {radar?.expectedDebt ? (Number(radar?.expectedDebt) - Number(radar?.factDebt))?.toLocaleString('ru') : 0}{' '}
                {valyuta === 'UZS' ? t('home.sum') : '$'}
              </span>
            </div>
          </div>
        </div>

        <div className={styles.newStyle_bottom}>
          <div className={styles.newStyle_bottom_item}>
            <div className={styles.circle}></div>
            <span>{t('boss_payments.Tolovlar')}</span>
          </div>
          <div className={styles.newStyle_bottom_item}>
            <div className={styles.circle}></div>
            <span>{t('boss_payments.Kutulayotgan_tolovlar')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BossClientStatistic;
