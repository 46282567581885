import React, { useEffect, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useAppSelector } from '@hooks/reduxHooks';
import { Avatar, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import { BASE_URL } from '../../service/RequestService';
import { EmployeeId, EmployeeList } from '../../utils/models/EmployeeList';
import { EmployeeStatus } from '../../utils/models/EmployeeStatus';
import { TableConfig } from '../../utils/models/TableConfig';

import '../../pages/settingEmployee/settingEmployee.module.scss';
import styles from './tableEmployee.module.scss';

interface Props {
  data: EmployeeList[];
  isLoading: boolean;
  tableConfig: TableConfig[];
  tableConfigFetch: boolean;
  isFetching: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<{ visible: boolean; id: null }>>;
}

const TableEmployee: React.FC<Props> = ({ isLoading, tableConfigFetch, tableConfig, data, isFetching, setModalVisible }) => {
  const { t, i18n } = useTranslation();
  const { user } = useAppSelector(state => state.userReducer);

  const initialColumn: ColumnsType<{}> | undefined = [
    {
      dataIndex: 'avatar',
      width: 130,
      render: (i: string) => <Avatar icon={<UserOutlined />} src={`${BASE_URL}/${i}`} />
    },
    {
      title: t('home.Xodim'),
      dataIndex: 'fullName',
      align: 'left'
    },
    {
      title: t('home.Lavozim'),
      align: 'left',
      render: (record: EmployeeId) => (
        <div className="flex gap-2">
          {record?.roleBranches?.length > 0
            ? user?.branchId
              ? record?.roleBranches?.find(item => item?.branch?.id === user?.branchId)?.role?.name
              : record?.roleBranches?.map(item => item?.role?.name)?.join(', ')
            : record?.role?.uz}
        </div>
      )
    },
    {
      title: t('setting.Filial'),
      align: 'left',
      render: (record: EmployeeId) => {
        console.log(record);
        return (
          <div className="flex gap-2">
            {record?.roleBranches?.length > 0
              ? user?.branchId
                ? record?.roleBranches?.find(item => item?.branch?.id === user?.branchId)?.branch?.name
                : record?.roleBranches?.map(item => item?.branch?.name)?.join(', ')
              : record?.roleBranches?.map(item => item?.branch?.name)?.join(', ')}
          </div>
        );
      }
    },
    {
      title: t('home.Telefon_raqam'),
      dataIndex: 'phone'
    },
    {
      title: t('home.Amal_qilish_muddati'),
      dataIndex: 'expiry'
    },
    {
      title: t('home.Status'),
      dataIndex: 'status',
      render: (status: EmployeeStatus) => (
        <span
          className={
            status === EmployeeStatus.ENABLED
              ? 'table_status_active'
              : status === EmployeeStatus.LOCKED
                ? 'table_status_contract_mode'
                : 'table_status_canceled'
          }
        >
          {t(`home.${status}`)}
        </span>
      )
    }
  ];
  const [column, setColumn] = useState(initialColumn);

  useEffect(() => {
    if (tableConfig.length > 0 && !tableConfigFetch) {
      const newConfig: any[] = [
        {
          dataIndex: 'avatar',
          width: 130,
          render: (i: string) => <Avatar icon={<UserOutlined />} src={`${BASE_URL}/${i}`} />
        }
      ];

      tableConfig.forEach(item => {
        if (item.checked) {
          switch (item.name) {
            case 'Xodim':
              newConfig.push({
                title: t('home.Xodim'),
                dataIndex: 'fullName',
                align: 'left'
              });
              break;
            case 'Lavozim':
              newConfig.push({
                title: t('home.Lavozim'),
                align: 'left',
                render: (record: EmployeeId) => (
                  <div className="flex gap-2">
                    {record?.roleBranches?.length > 0
                      ? user?.branchId
                        ? record?.roleBranches?.find(item => item?.branch?.id === user?.branchId)?.role?.name
                        : record?.roleBranches?.map(item => item?.role?.name)?.join(', ')
                      : record?.role?.uz}
                  </div>
                )
              });
              break;
            case 'Filial':
              newConfig.push({
                title: t('setting.Filial'),
                align: 'left',
                render: (record: EmployeeId) => (
                    <div className="flex gap-2">
                      {record?.roleBranches?.length > 0
                        ? user?.branchId
                          ? record?.roleBranches?.find(item => item?.branch?.id === user?.branchId)?.branch?.name
                          : record?.roleBranches?.map(item => item?.branch?.name)?.join(', ')
                        : record?.roleBranches?.map(item => item?.branch?.name)?.join(', ')}
                    </div>
                  )
              });
              break;
            case 'Telefon_raqam':
              newConfig.push({
                title: t('home.Telefon_raqam'),
                dataIndex: 'phone'
              });
              break;
            case 'Amal_qilish_muddati':
              newConfig.push({
                title: t('home.Amal_qilish_muddati'),
                dataIndex: 'expiry'
              });
              break;
            case 'Status':
              newConfig.push({
                title: t('home.Status'),
                dataIndex: 'status',
                render: (status: EmployeeStatus) => (
                  <span
                    className={
                      status === EmployeeStatus.ENABLED
                        ? 'table_status_active'
                        : status === EmployeeStatus.LOCKED
                          ? 'table_status_contract_mode'
                          : 'table_status_canceled'
                    }
                  >
                    {t(`home.${status}`)}
                  </span>
                )
              });
              break;
            default:
              break;
          }
        }
      });
      setColumn(newConfig);
    }
  }, [tableConfig, tableConfigFetch]);

  return (
    <Table
      className={`custom_table contract_table_size table_employee ${styles.table}`}
      columns={column}
      dataSource={data}
      loading={isLoading || isFetching || tableConfigFetch}
      size="small"
      pagination={false}
      onRow={(record: any) => ({
        onClick: () => {
          setModalVisible({ visible: true, id: record.id });
        }
      })}
      scroll={{ y: 700 }}
    />
  );
};

export default TableEmployee;
