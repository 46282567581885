import queryString from 'query-string';
import { useQuery } from 'react-query';

import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { $api } from '../../../service/RequestService';
import { Pagination } from '../../../utils/models/Pagination';
import { obj } from '../components/filterCalls/FilterCalls';
import { checkboxValueObj } from '../components/header/Header';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { dateFormatData } from '../utils/helper/DateFormatData';
import { CallHistoryCardModel } from '../utils/models/CallHistoryCardModel';
import { CallHistoryFilterModel } from '../utils/models/CallHistoryFilterModel';
import { CallHistoryGraphModel } from '../utils/models/CallHistoryGraphModel';
import { CallHistoryModel } from '../utils/models/CallHistoryModel';
import { FirstPieChartData } from '../utils/models/FirstPieChartData';
import { LeadTasksModel } from '../utils/models/LeadTasksModel';
import { SecondPieChartData } from '../utils/models/SecondPieChartData';
import { StatLead, StatLeadDateDto } from '../utils/models/StatLead';
import { ThirdPieChartData } from '../utils/models/ThirdPieChartData';
import { TJMS } from '../utils/models/TJMS';
import { IUniqueCalls } from '../utils/models/UniqueCalls';

export function useGetFailedLead(statType?: string, pipeId?: number | null) {
  return useQuery<{ result: { [key: string]: number }; total: number }>(
    [queryKeys.FAILED_LEAD, statType, pipeId],
    async () => {
      const res = await $api.post(endPoints.FAILED_LEAD, {
        statType,
        pipeId
      });

      return res.data.data;
    },
    {
      enabled: !!statType,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetCallHistory(
  startDate: number,
  finishDate: number,
  pipeId?: number | null,
  employeeId?: number,
  statuses?: string[],
  unique?: boolean,
  durationStart?: number,
  durationFinish?: number,
  talkTimeStart?: number,
  talkTimeFinish?: number
) {
  return useQuery<CallHistoryModel>(
    [queryKeys.CALL_HISTORY, startDate, finishDate, pipeId, employeeId, statuses, unique, durationStart, durationFinish, talkTimeStart, talkTimeFinish],

    async () => {
      const searchParams = queryString.stringify({
        startDate,
        finishDate,
        pipeId,
        employeeId,
        status: statuses,
        unique,
        durationStart,
        durationFinish,
        talkTimeStart,
        talkTimeFinish
      });
      const res = await $api.get(`${endPoints.CALL_HISTORY}?${searchParams}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler,
      enabled: startDate !== undefined && finishDate !== undefined
    }
  );
}

export function useGetCallHistoryCArd(
  startDate: number,
  finishDate: number,
  pipeId?: number | null,
  employeeId?: number,
  unique?: string,
  statuses?: string[],
  durationStart?: number,
  durationFinish?: number,
  talkTimeStart?: number,
  talkTimeFinish?: number
) {
  return useQuery<CallHistoryCardModel>(
    [queryKeys.CALL_HISTORY_CARD, startDate, finishDate, pipeId, employeeId, unique, statuses, durationStart, durationFinish, talkTimeStart, talkTimeFinish],
    async () => {
      const searchParams = queryString.stringify({
        startDate,
        finishDate,
        pipeId,
        employeeId,
        unique: checkboxValueObj[unique!] || false,
        status: statuses,
        durationStart,
        durationFinish,
        talkTimeStart,
        talkTimeFinish
      });
      const res = await $api.get(`${endPoints.CALL_HISTORY_CARD}?${searchParams}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler,
      enabled: startDate !== undefined && finishDate !== undefined
    }
  );
}

export function useGetCallHistoryGrapg(
  startDate: number,
  finishDate: number,
  pipeId?: number | null,
  employeeId?: number,
  statuses?: string[],
  unique?: boolean,
  durationStart?: number,
  durationFinish?: number,
  talkTimeStart?: number,
  talkTimeFinish?: number
) {
  return useQuery<CallHistoryGraphModel>(
    [queryKeys.CALL_HISTORY_GRAPH, startDate, finishDate, pipeId, employeeId, unique, durationStart, durationFinish, talkTimeStart, talkTimeFinish, statuses],
    async () => {
      const searchParams = queryString.stringify({
        startDate,
        finishDate,
        pipeId,
        employeeId,
        unique,
        durationStart,
        durationFinish,
        talkTimeStart,
        talkTimeFinish,
        status: statuses
      });
      const res = await $api.get(`${endPoints.CALL_HISTORY_GRAPH}?${searchParams}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler,
      enabled: startDate !== undefined && finishDate !== undefined
    }
  );
}

export function useGetCallHistoryFilter(
  page: number,
  size: number,
  startDate: number,
  finishDate: number,
  employeeId?: number,
  callType?: string,
  unique?: string,
  statuses?: string[],
  pipeId?: number,
  durationStart?: number,
  durationFinish?: number,
  talkTimeStart?: number,
  talkTimeFinish?: number
) {
  return useQuery<Pagination<CallHistoryFilterModel> | Pagination<IUniqueCalls>>(
    [
      queryKeys.CALL_HISTORY_FILTER,
      page,
      size,
      startDate,
      finishDate,
      employeeId,
      callType,
      unique,
      statuses,
      pipeId,
      durationStart,
      durationFinish,
      talkTimeStart,
      talkTimeFinish
    ],
    async () => {
      const res = await $api.post(
        `${obj[unique!] ? `${endPoints.CALL_HISTORY_FILTER}/unique` : endPoints.CALL_HISTORY_FILTER}?page=${page}&size=${size}&employeeId=${employeeId || ''}&status=${statuses || ''}&pipeId=${pipeId}&durationStart=${durationStart || null}&durationFinish=${durationFinish || null}&talkTimeStart=${talkTimeStart || null}&talkTimeFinish=${talkTimeFinish || null}`,
        {
          page,
          size,
          startDate,
          finishDate,
          employeeId,
          callType,
          // eslint-disable-next-line no-nested-ternary
          statuses: statuses ? (Array.isArray(statuses) ? statuses : [statuses]) : null,
          pipeId,
          durationStart: Number(durationStart) ? Number(durationStart) : undefined,
          durationFinish: Number(durationFinish) ? Number(durationFinish) : undefined,
          talkTimeStart: Number(talkTimeStart) ? Number(talkTimeStart) : undefined,
          talkTimeFinish: Number(talkTimeFinish) ? Number(talkTimeFinish) : undefined
        }
      );

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler,
      enabled: startDate !== undefined && finishDate !== undefined && !!page && !!size
    }
  );
}

export function useGetLeadTasks(employeeList: null | number[], leadTaskStatusFilter: string, startDate: string, finishDate: string) {
  return useQuery<LeadTasksModel>(
    [queryKeys.LEAD_TASK, employeeList, leadTaskStatusFilter, startDate, finishDate],
    async () => {
      const res = await $api.post(`${endPoints.LEAD_TASK}`, {
        employeeList,
        leadTaskStatusFilter,
        startDate,
        finishDate
      });

      return res.data;
    },
    {
      enabled: !!leadTaskStatusFilter,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetPhoneRecord(uuid: string | null, accountcode: string | null, callHistoryType: string | null) {
  return useQuery(
    [queryKeys.PHONE_RECORD, uuid, accountcode, callHistoryType],
    async () => {
      const res = await $api.get(`${endPoints.PHONE_RECORD}?uuid=${uuid}&accountcode=${accountcode}&callHistoryType=${callHistoryType}`);

      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useStatLead(pipeId: number | null, employeeId?: number, startDate?: string, finishDate?: string) {
  return useQuery<StatLead>(
    [queryKeys.STAT_LEAD, pipeId, startDate, finishDate, employeeId],
    async () => {
      const res = await $api.post(endPoints.STAT_LEAD, {
        pipeId,
        employeeList: employeeId && [employeeId],
        startDate,
        finishDate
      });

      return res.data.data;
    },
    {
      // enabled: !!pipeId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useStatLeadDate(pipeId: number | null, employeeId?: number, startDate?: string, finishDate?: string, dateEnum?: string) {
  return useQuery<StatLeadDateDto[]>(
    [queryKeys.STAT_LEAD_DATE, pipeId, startDate, finishDate, employeeId, dateEnum],
    async () => {
      const res = await $api.post(endPoints.STAT_LEAD_DATE, {
        pipeId,
        employeeList: employeeId && [employeeId],
        startDate,
        finishDate,
        dateEnum
      });

      return dateFormatData(dateEnum!, res.data.data, {
        startDate: startDate || '',
        finishDate: finishDate || ''
      });
    },
    {
      // enabled: pipeId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export const useGetFirstPieChartData = (startDate?: string, endDate?: string) =>
  useQuery<FirstPieChartData>(
    [queryKeys.FIRST_PIE_CHART_DATA, startDate, endDate],
    async () => {
      const res = await $api.get(`${endPoints.FIRST_PIE_CHART_DATA}?startDate=${startDate}&finishDate=${endDate}`);

      return res.data?.data;
    },
    {
      retry: false,
      enabled: !!startDate && !!endDate,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler
    }
  );

export const useGetSecondPieChartData = (startDate?: string, endDate?: string) =>
  useQuery<SecondPieChartData>(
    [queryKeys.SECOND_PIE_CHART_DATA, startDate, endDate],
    async () => {
      const res = await $api.get(`${endPoints.SECOND_PIE_CHART_DATA}?startDate=${startDate}&finishDate=${endDate}`);

      return res.data?.data;
    },
    {
      retry: false,
      enabled: !!startDate && !!endDate,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler
    }
  );

export const useGetAllTJMSWithBuildings = () =>
  useQuery<TJMS[]>(
    [queryKeys.TJMS],
    async () => {
      const res = await $api.get(endPoints.TJMS);

      return res.data?.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler
    }
  );

export const useGetThirdPieChartData = (data: {
  startDate?: string;
  finishDate?: string;
  houses?: Array<number>;
  buildings?: Array<number>;
  customFieldId?: number | null;
  tag?: boolean | null;
}) =>
  useQuery<ThirdPieChartData>(
    [queryKeys.THIRD_PIE_CHART_DATA, data],
    async () => {
      const res = await $api.post(endPoints.THIRD_PIE_CHART_DATA, data);

      return res.data?.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      enabled: !!data?.customFieldId || !!data?.tag
    }
  );
