import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import RoboSettingIcon from '@icons/RoboSettingIcon';
import DashboardModal from '@pages/crm/components/dashboard-modal/DashboardModal';
import { createTaskVisible } from '@reducers/TaskCalendarViewSlice';
import { Permissions } from '@utils/constants/permissions';
import { AutoTaskSetting } from '@utils/models/AutoTaskSetting';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import FilterButton from '../filter/FilterButton';
import TaskBotSettingModal from '../taskBotSettingModal/TaskBotSettingModal';

import ChangeViewTypeButtons from './changeViewTypeButtons/ChangeViewTypeButtons';

import styles from './TaskHeader.module.scss';

const TaskHeader = () => {
  const { t } = useTranslation();
  const [botSettingVisible, setBotSettingVisible] = useState(false);
  const [autoTaskBot, setAutoTaskBot] = useState<AutoTaskSetting | any>();

  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);
  const type = useAppSelector(state => state.assigmentSlice.calendarView);
  const dispatch = useAppDispatch();

  // handle bot settings
  const handleBotSetting = () => {
    setBotSettingVisible(true);
  };

  const isAccessSettingsBotAutoTask = permissions?.includes(Permissions.PERMISSION_SETTINGS_AUTO_TASK);

  // handleOpenCreateTaskModal
  const handleOpenCreateTaskModal = () => {
    dispatch(createTaskVisible(true));
  };

  return (
    <>
      <div className={styles.crmHeader}>
        <div className="d-flex align-center" style={{ width: '100%', justifyContent: 'space-between' }}>
          <ChangeViewTypeButtons />
          <div style={{ display: 'flex' }}>
            <div className={styles.crmHeader_right}>
              {isAccessSettingsBotAutoTask && (
                <Button className={styles.crmHeader_right_task} onClick={handleBotSetting}>
                  <RoboSettingIcon />
                  {t('auto_task.Bot_sozlamalari')}
                </Button>
              )}
              {/* {type && (
            <Button
              onClick={handleOpenCreateTaskModal}
              className={styles.crmHeader_button}
              type={'primary'}
            >
              {t('calendar_view.Topshiriq_yaratish')}
            </Button>
          )} */}
            </div>
            <FilterButton />
          </div>
        </div>
      </div>
      <TaskBotSettingModal visible={botSettingVisible} setVisible={setBotSettingVisible} {...autoTaskBot} />
      <DashboardModal />
    </>
  );
};

export default TaskHeader;
