import 'swiper/css/mousewheel';

import React, { useEffect, useRef, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import { Input, Modal, Popover } from 'global';
import { useTranslation } from 'react-i18next';
import { FreeMode, Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import AddCircleIcon from '../../../assets/icons/AddCircleIcon';
import SearchIcon from '../../../assets/icons/SearchIcon';
import SwiperNextIcon from '../../../assets/icons/SwiperNextIcon';
import SwiperPrevIcon from '../../../assets/icons/SwiperPrevIcon';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { MainPaths } from '../../../routing/main/mainPaths';
import { rootPaths } from '../../../routing/rootPaths';
import { SettingsPaths } from '../../../routing/settings/settingsPaths';
import { clearSaleHouses, setPageNumber } from '../../../store/reducers/HouseSlice';
import { Permissions } from '../../../utils/constants/permissions';
import { HouseSale } from '../../../utils/models/House';
import { SearchParamsHome } from '../Home';
import { useSaleHouses } from '../services/queries';

import styles from '../home.module.scss';

const SaleHousesList = () => {
  const [nextPrevVisible, setNextPrevVisible] = useState(false);
  const swiperRef = useRef<HTMLDivElement | null>(null);
  const { searchParams, appendMultiple, remove, appendSingle, push } = useQueryParam<SearchParamsHome, any>();

  const navigationPrevRef = useRef(null);

  const navigationNextRef = useRef(null);

  const { t } = useTranslation();
  const [searchModalVisible, setSearchModalVisible] = useState(false);

  const { page: saleHousesPage, saleHouses, totalPages } = useAppSelector(state => state.houseReducer);

  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const dispatch = useAppDispatch();

  const { isLoading, isFetching } = useSaleHouses(saleHousesPage, totalPages, searchParams.search);

  const handleSearch = (value: string) => {
    dispatch(clearSaleHouses());
    appendSingle('search', value);
  };

  const handleSwipeEnd = () => {
    if (saleHouses.length > 0) {
      dispatch(setPageNumber(saleHousesPage + 1));
    }
  };

  const handleClickHouse = (house: HouseSale) => {
    if (Array.isArray(searchParams.houses)) {
      if (searchParams.houses.includes(`${house.id}`)) {
        remove('houses', `${house.id}`);
      } else {
        appendMultiple('houses', house.id);
      }
    } else if (searchParams.houses === `${house.id}`) {
      remove('houses', `${house.id}`);
    } else {
      appendMultiple('houses', house.id);
    }
  };

  // house name
  const houseName = (name: string) => {
    if (name.length > 15) {
      return `${name.slice(0, 14)}...`;
    }
    return name;
  };

  // clearing sale houses
  useEffect(
    () => () => {
      dispatch(clearSaleHouses());
    },
    [dispatch]
  );

  const getBlankHousesPercent = (data: HouseSale) => {
    const sum = (data?.onSale || 0) + (data?.sold || 0) + (data?.booked || 0);
    const percent = ((data?.onSale || 0) / (sum || 1)) * 100;

    return `${Math.round(percent)}%`;
  };

  const getBookedPercent = (data: HouseSale) => {
    const sum = (data?.onSale || 0) + (data?.sold || 0) + (data?.booked || 0);
    const percent = ((data?.booked || 0) / (sum || 1)) * 100;

    return `${Math.round(percent)}%`;
  };

  const getSoldPercent = (data: HouseSale) => {
    const sum = (data?.onSale || 0) + (data?.sold || 0) + (data?.booked || 0);
    const percent = ((data?.sold || 0) / (sum || 1)) * 100;

    return `${Math.round(percent)}%`;
  };

  const getBlankLeft = (data: HouseSale) => {
    const sum = (data?.onSale || 0) + (data?.sold || 0) + (data?.booked || 0);
    const percent = ((data?.onSale || 0) / (sum || 1)) * 100;

    return `${Math.round(100 - percent)}%`;
  };

  const getSumOfHouses = (data: HouseSale) => (data?.onSale || 0) + (data?.sold || 0) + (data?.booked || 0);

  const isAccessHouseConfig = permissions?.includes(Permissions.PERMISSION_SETTINGS_HOUSE);

  const goToHouseConfigPage = () => {
    push(rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.HOUSE_CONFIG);
  };

  const showIconInput = () => {
    if (searchParams.search) return <CloseOutlined onClick={() => handleSearch('')} className="deleteSearchIcon" />;
    return <SearchIcon />;
  };

  useEffect(() => {
    if (swiperRef.current) {
      setNextPrevVisible(swiperRef.current.offsetWidth < saleHouses.length * 160);
    }

    const windowResize = (e: UIEvent) => {
      setNextPrevVisible(swiperRef.current!.offsetWidth < saleHouses.length * 160);
    };

    window.addEventListener('resize', windowResize);
    return () => {
      window.removeEventListener('resize', windowResize);
    };
  }, [swiperRef, saleHouses]);

  return (
    <div>
      <div className={styles.home_title}>
        <div>
          <h1>{t('home.Turar_joy_majmualari')}</h1>
          <p>{t('home.Turar_joy_majmuasini_tanlang')}</p>
        </div>
        <Input value={searchParams.search} onChange={e => handleSearch(e.target.value)} placeholder={t('home.Uy_nomi_bilan_qidirish')} suffix={showIconInput()} />
      </div>
      <div className={styles.m_swiper} ref={swiperRef}>
        <div className={styles.searchBTN}>
          <button onClick={() => setSearchModalVisible(!searchModalVisible)}>
            <SearchIcon />
          </button>
          <Modal
            title={t('home.Uy_nomi_bilan_qidirish')}
            className="modalSearchSwiper"
            visible={searchModalVisible}
            onCancel={() => setSearchModalVisible(!searchModalVisible)}
            footer={false}
          >
            <Input
              value={searchParams.search}
              onChange={e => handleSearch(e.target.value)}
              style={{
                // width: 266,
                height: 39,
                borderRadius: 'var(--border-25)',
                paddingRight: 24
              }}
              placeholder={t('home.Uy_nomi_bilan_qidirish')}
              suffix={showIconInput()}
            />
          </Modal>
        </div>
        {(saleHouses?.length !== 0 || isLoading || isFetching) && (
          <>
            <Swiper
              spaceBetween={20}
              slidesPerView="auto"
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current
              }}
              freeMode={true}
              modules={[FreeMode, Navigation, Mousewheel]}
              mousewheel={true}
              onReachEnd={handleSwipeEnd}
              onSwiper={(swiper: any) => {
                setTimeout(() => {
                  swiper.params.navigation.prevEl = navigationPrevRef.current;
                  swiper.params.navigation.nextEl = navigationNextRef.current;
                  swiper.navigation.destroy();
                  swiper.navigation.init();
                  swiper.navigation.update();
                });
              }}
            >
              {saleHousesPage === 1 && (isLoading || isFetching) ? (
                <>
                  {Array.from({ length: 8 }).map((_, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <SwiperSlide key={index} className={styles.m_swiper_skeleton}>
                      <Skeleton.Button className={styles.skeletonCont} active />
                    </SwiperSlide>
                  ))}
                </>
              ) : (
                <>
                  {saleHouses?.map((house, index) => (
                    <SwiperSlide
                      onClick={() => handleClickHouse(house)}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      className={`${styles.m_swiper_slide} ${
                        // eslint-disable-next-line no-nested-ternary
                        Array.isArray(searchParams.houses)
                          ? searchParams.houses.includes(String(house.id))
                            ? 'swiper_active'
                            : ''
                          : searchParams.houses === String(house.id)
                            ? 'swiper_active'
                            : ''
                      }`}
                    >
                      <div>
                        <h1 title={house.name} className="house_name">
                          {houseName(house.name)}
                        </h1>
                      </div>
                      <div className={styles.line}>
                        <Popover
                          overlayClassName="popover_content"
                          content={
                            <>
                              <p>{t('home.Shartnoma_qilingan')}:</p>
                              <span>{house.sold + t('pagination.ta')}</span>
                            </>
                          }
                        >
                          <span
                            style={{
                              ['--width' as any]: getSoldPercent(house)
                            }}
                            className={styles.line_green}
                          />
                        </Popover>
                        <Popover
                          overlayClassName="popover_content"
                          content={
                            <>
                              <p>{t('home.Buyurtmalar')}:</p>
                              <span>{house.booked + t('pagination.ta')}</span>
                            </>
                          }
                        >
                          <span
                            style={{
                              ['--left' as any]: getSoldPercent(house),
                              ['--width' as any]: getBookedPercent(house)
                            }}
                            className={styles.line_yellow}
                          />
                        </Popover>
                        <Popover
                          overlayClassName="popover_content"
                          content={
                            <>
                              <p>{t('home.Bosh_xonadonlar')}:</p>
                              <span>{house.onSale + t('pagination.ta')}</span>
                            </>
                          }
                        >
                          <span
                            style={{
                              ['--left' as any]: getBlankLeft(house),
                              ['--width' as any]: getBlankHousesPercent(house)
                            }}
                            className={styles.line_gray}
                          />
                        </Popover>
                      </div>
                      <p>
                        {t('house_config.Xonadonlar_soni')} <span>{getSumOfHouses(house)}</span>
                      </p>
                      <p>
                        {t('home.Bosh_xonadonlar')} <span>{house.onSale}</span>
                      </p>
                      <p>
                        {t('home.Buyurtmalar')} <span>{house.booked}</span>
                      </p>
                    </SwiperSlide>
                  ))}
                  {isLoading && (
                    <>
                      {Array.from({ length: 3 }).map((_, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <SwiperSlide key={index} className={styles.m_swiper_skeleton}>
                          <Skeleton.Button className={styles.skeletonCont} active />
                        </SwiperSlide>
                      ))}
                    </>
                  )}
                </>
              )}
            </Swiper>
            {nextPrevVisible && (
              <>
                <span ref={navigationPrevRef} className={styles.swiper_prev}>
                  <SwiperPrevIcon />
                </span>
                <span ref={navigationNextRef} className={styles.swiper_next}>
                  <SwiperNextIcon />
                </span>
              </>
            )}
          </>
        )}
        {saleHouses?.length === 0 && !isLoading && !isFetching && isAccessHouseConfig && (
          <div onClick={goToHouseConfigPage} className={styles.add_card}>
            <AddCircleIcon />
            <h2>{t('setting.TJM_yaratish')}</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default SaleHousesList;
