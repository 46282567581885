import React, { FC, memo, ReactNode, useCallback, useContext, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import ClipboardTextIcon from '@icons/ClipboardTextIcon';
import DocumentCopyIcon from '@icons/DocumentCopyIcon';
import FlashIcon from '@icons/FlashIcon';
import LockBlackStrokeIcon from '@icons/LockBlackStrokeIcon';
import NotImageIcon from '@icons/NotImageIcon';
import SaleFlatIcon from '@icons/SaleFlatIcon';
import SliderIcon from '@icons/SliderIcon';
import TrushSquareBlackStrokeIcon from '@icons/TrushSquareBlackStrokeIcon';
import { useFlatDelete } from '@queries/mutation';
import { setShaxmatka, setShaxmatkaDelete } from '@reducers/ShaxmatkaSlice';
import { roundFloatNumber } from '@utils/helper/roundFloatNumber';
import { FlatId } from '@utils/models/Flat';
import { FlatStatus } from '@utils/models/FlatStatus';
import { Dropdown, message } from 'antd';
import { useTranslation } from 'react-i18next';

import { ShaxmatkaContext } from '../hooks/ShaxmatkaContext';

import styles from '../shaxmatka.module.scss';

interface ApartamentCardProps {
  i: FlatId;
  setRoom: (i: FlatId) => void;
  floor?: any[];
  repaired: boolean;
  setFlatCopy: (i: FlatId) => void;
  flatCopy: FlatId | undefined;
  activeKey: number | undefined;
  flat?: any;
  setDeleteLoad: (i: boolean) => void;
  setSaveAll: (save: boolean) => void;
  setCreateFloorModal: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      data: {};
    }>
  >;
  children?: ReactNode;
  trigger?: ('contextMenu' | 'click' | 'hover')[] | undefined;
  mouseEnterDelay?: number | undefined;
  mouseLeaveDelay?: number | undefined;
  placement?: 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight' | 'top' | 'bottom' | undefined;
}

const ApartamentCard: FC<ApartamentCardProps> = ({
  i,
  setRoom,
  floor,
  repaired,
  setFlatCopy,
  flatCopy,
  setDeleteLoad,
  setSaveAll,
  setCreateFloorModal,
  children,
  trigger = ['contextMenu'],
  mouseEnterDelay,
  mouseLeaveDelay,
  placement
}) => {
  const { setContext, activeFlats } = useContext(ShaxmatkaContext);
  const elementRef = useRef<any>(null);
  const { data: shaxmatka, tableMode } = useAppSelector(state => state.shaxmatkaReducer);
  const shaxmatkaDelete = useAppSelector(state => state.shaxmatkaReducer.dataDelete);
  const flatDelete = useFlatDelete();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    setDeleteLoad(false);
  }, []);

  const hasGallery = i?.gallery?.some(value => value?.position === 'FLAT_PLAN') && i?.gallery?.some(value => value?.position === 'FLAT_FLOOR');

  const roomEdit = useCallback(
    (e: any, text: string) => {
      const rooms = document.querySelectorAll('.selectableItems');
      const flat = e.target;

      if (e.ctrlKey || text === 'contex') {
        /* empty */
      } else {
        rooms.forEach((item: any) => {
          item.style.border = '';
          item.setAttribute(
            'data',
            JSON.stringify({
              ...JSON.parse(item.getAttribute('data')),
              isSelected: false
            })
          );
        });
      }
      flat.style.border = '1.5px solid #00A389';
      flat.setAttribute(
        'data',
        JSON.stringify({
          ...JSON.parse(flat.getAttribute('data')),
          isSelected: true
        })
      );
      setRoom(i);
    },
    [i, setRoom]
  );

  const flatPaste = () => {
    if (flatCopy) {
      const mSpinning: any = document.querySelector('.mSpinning');

      mSpinning.style.display = 'flex';

      if (mSpinning.style.display === 'flex') {
        setTimeout(() => {
          const rooms = document.querySelectorAll('.selectableItems');
          const arr: any = [];

          if (tableMode) {
            activeFlats?.forEach(item => {
              arr.push(item);
            });
          } else {
            rooms.forEach((item: any) => {
              const flat = JSON.parse(item.getAttribute('data'));

              if (flat.isSelected) {
                arr.push(flat);
              }
            });
          }

          const shaxmatkaCopy = shaxmatka.map((podezd: any) =>
            podezd.map((floor: any) =>
              floor.map((room: FlatId) => {
                function Selected(a: FlatId) {
                  return a && a.floor === room.floor && a.xaxis[0] === room.xaxis[0] && a.entrance === room.entrance;
                }

                return arr.some(Selected)
                  ? {
                      ...flatCopy,
                      status: room.status,
                      id: room.id,
                      customId: room?.customId,
                      isSelected: room.isSelected,
                      number: room.number,
                      floor: room.floor,
                      entrance: room.entrance,
                      xaxis: room.xaxis,
                      yaxis: room.yaxis
                    }
                  : { ...room };
              })
            )
          );

          dispatch(setShaxmatka(shaxmatkaCopy as any));
          message.success('Paste');
        }, 10);
      }
      setSaveAll(false);
    } else {
      message.error('Nusxa olinmagan!');
    }
  };

  const flatClear = () => {
    const mSpinning: any = document.querySelector('.mSpinning');

    mSpinning.style.display = 'flex';

    if (mSpinning.style.display === 'flex') {
      setTimeout(() => {
        const rooms = document.querySelectorAll('.selectableItems');
        const arr: any = [];

        if (tableMode) {
          activeFlats?.forEach(item => {
            arr.push(item);
          });
        } else {
          rooms.forEach((item: any) => {
            const flat = JSON.parse(item.getAttribute('data'));

            if (flat.isSelected) {
              arr.push(flat);
            }
          });
        }

        const shaxmatkaCopy = shaxmatka.map((podezd: any) =>
          podezd.map((floor: any) =>
            floor.map((room: FlatId) => {
              function Selected(a: FlatId) {
                return a && a.floor === room.floor && a.xaxis[0] === room.xaxis[0] && a.entrance === room.entrance;
              }

              return arr.some(Selected)
                ? {
                    ...room,
                    rooms: null,
                    area: null,
                    repaired: null,
                    apartment: true,
                    options: null,
                    prepaymentPercent: null,
                    prepayment: null,
                    delay: null,
                    terrace: null,
                    createdDate: null,
                    deletedDate: null,
                    createdBy: null,
                    deletedBy: null,
                    price: null,
                    pricePerArea: null,
                    pricePerAreaRepaired: null,
                    priceRepaired: null,
                    priceNotRepaired: null,
                    gallery: null,
                    discountID: null
                  }
                : { ...room };
            })
          )
        );

        dispatch(setShaxmatka(shaxmatkaCopy as any));
        message.success('Clear');
      });
    }
    setSaveAll(false);
  };

  const flatBlock = () => {
    const mSpinning: any = document.querySelector('.mSpinning');

    mSpinning.style.display = 'flex';

    if (mSpinning.style.display === 'flex') {
      setTimeout(() => {
        const rooms = document.querySelectorAll('.selectableItems');
        const arr: any = [];

        if (tableMode) {
          activeFlats?.forEach(item => {
            arr.push(item);
          });
        } else {
          rooms.forEach((item: any) => {
            const flat = JSON.parse(item.getAttribute('data'));

            if (flat.isSelected) {
              arr.push(flat);
            }
          });
        }

        const shaxmatkaCopy = shaxmatka.map((podezd: any) =>
          podezd.map((floor: any) =>
            floor.map((room: FlatId) => {
              function Selected(a: FlatId) {
                return a && a.floor === room.floor && a.xaxis[0] === room.xaxis[0] && a.entrance === room.entrance;
              }

              // eslint-disable-next-line no-nested-ternary
              return arr.some(Selected)
                ? room.status === FlatStatus.RESERVE
                  ? {
                      ...room,
                      status: FlatStatus.SALE
                    }
                  : {
                      ...room,
                      status: FlatStatus.RESERVE
                    }
                : { ...room };
            })
          )
        );

        // @ts-expect-error
        dispatch(setShaxmatka(shaxmatkaCopy));
        message.success('Blocked');
      }, 10);
    }
    setSaveAll(false);
  };

  const deleteApartament = () => {
    const mSpinning: any = document.querySelector('.mSpinning');

    mSpinning.style.display = 'flex';
    const del: any = [];
    const arr: any = [];

    const deletedFC = (room: any) => {
      if (room.isSelected && room.status === FlatStatus.SALE) {
        if (room.id) {
          del.push(room.id);
        }
        arr.push(room);
      } else {
        mSpinning.style.display = 'none';
      }
    };

    if (tableMode) {
      activeFlats?.forEach(item => {
        deletedFC(item);
      });
    } else {
      document.querySelectorAll('.selectableItems').forEach((item: any) => {
        const room = JSON.parse(item.getAttribute('data'));

        deletedFC(room);
      });
    }

    const shaxmatkaCopy = shaxmatka.map((podezd: any) =>
      podezd.map((floor: any) =>
        floor.map((room: any) => {
          function Selected(a: FlatId) {
            return a && a.floor === room.floor && a.xaxis[0] === room.xaxis[0] && a.entrance === room.entrance;
          }

          if (arr.some(Selected)) {
            return { ...room, isSelected: false, new: true };
          }
          return room;
        })
      )
    );

    // if (del.length !== 0) {
    //   flatDelete.mutate(del)
    // }
    if (arr.length !== 0) {
      dispatch(setShaxmatka(shaxmatkaCopy as any));
    } else {
      message.info("O'chirib bo'lmadi");
    }

    // @ts-expect-error
    dispatch(setShaxmatkaDelete(shaxmatkaDelete.concat(del).filter((value, index, self) => self.indexOf(value) === index)));
    setSaveAll(false);
  };

  useEffect(() => {
    const mSpinning: any = document.querySelector('.mSpinning');

    if (flatDelete.isSuccess) {
      mSpinning.style.display = 'none';
      const shaxmatkaCopy = shaxmatka.map((podezd: any) =>
        podezd.map((floor: any) =>
          floor.map((room: any) => {
            if (room.isSelected && !room.new && room.status !== FlatStatus.RESERVE) {
              return room.isSelected ? { ...room, isSelected: false, new: true } : room;
            }
            return room;
          })
        )
      );

      // @ts-expect-error
      dispatch(setShaxmatka(shaxmatkaCopy));
    }
    if (flatDelete.isError) {
      mSpinning.style.display = 'none';
    }
  }, [flatDelete]);

  // useEffect(() => {
  //   document.querySelectorAll('.selectableItems').forEach((el: any) => {
  //     el.parentElement.style.gridRow = el.style.gridRow
  //     el.parentElement.style.gridColumn = el.style.gridColumn
  //     el.parentElement.parentElement.style.gridRow = el.style.gridRow
  //     el.parentElement.parentElement.style.gridColumn = el.style.gridColumn
  //   })
  // }, [])
  // useEffect(() => {
  //   document.querySelectorAll('.selectableItems').forEach((el: any) => {
  //     el.parentElement.style.gridRow = el.style.gridRow
  //     el.parentElement.style.gridColumn = el.style.gridColumn
  //     el.parentElement.parentElement.style.gridRow = el.style.gridRow
  //     el.parentElement.parentElement.style.gridColumn = el.style.gridColumn
  //   })
  // }, [])

  const getBackgroundColor = () => {
    if (FlatStatus.SOLD === i?.status) return '#A7EBBA';
    if (FlatStatus.BOOKED === i?.status) return '#FFDCA5';
    if (FlatStatus.RESERVE === i?.status) return '#EFEFEF';
    return '#FFFFFF';
  };

  const getBadgeColor = () => {
    if (!i?.apartment) return '#3772FF';
    return '#00A389';
  };

  const getClassName = `${styles.Shaxmatka_body_container_center_podez_apartment} selectableItems`;

  // handleIncrementPrice
  const handleIncrementPrice = () => {
    setContext({ incrementPrice: 'SELECT' });
  };

  return (
    <Dropdown
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      placement={placement}
      overlay={
        <div className={styles.flat_contex_menu}>
          <div>
            <div onClick={handleIncrementPrice}>
              <SaleFlatIcon /> {t('home.Narxni_ozgartirish')}
            </div>
          </div>
          <div
            onClick={() => {
              setFlatCopy(i);
              message.success('Copy');
            }}
          >
            <div>
              <DocumentCopyIcon /> {t('shaxmatka.Nusxa_olish')}
            </div>
            <span>Ctrl+Alt+C</span>
          </div>
          <div onClick={flatPaste}>
            <div>
              <ClipboardTextIcon /> {t('shaxmatka.Nusxani_joylash')}
            </div>
            <span>Ctrl+Alt+V</span>
          </div>
          <div onClick={() => setCreateFloorModal({ visible: true, data: { ...i } })}>
            <div>
              <SliderIcon /> {t('shaxmatka.qavat_yaratish')}
            </div>
          </div>
          <div onClick={flatClear}>
            <div>
              <FlashIcon /> {t('shaxmatka.Tozalash')}
            </div>
          </div>
          <div onClick={flatBlock}>
            <div>
              <LockBlackStrokeIcon /> {i?.status === FlatStatus.RESERVE ? t('boss_complex.Sotuvda') : t('shaxmatka.Bloklash')}
            </div>
          </div>
          <div onClick={() => deleteApartament()}>
            <div>
              <TrushSquareBlackStrokeIcon /> {t('shaxmatka.Ochirish')}
            </div>
            <span>Del</span>
          </div>
        </div>
      }
      trigger={trigger}
    >
      {children || (
        <div
          className={getClassName}
          onContextMenu={e => roomEdit(e, 'contex')}
          onClick={e => roomEdit(e, 'click')}
          style={{
            gridRow: i?.yaxis[0],
            gridColumn: i?.xaxis[0],
            border: i?.isSelected ? '1.5px solid #00A389' : '',
            background: getBackgroundColor()
          }}
          // @ts-ignore
          data={JSON.stringify(i)}
          ref={elementRef}
        >
          <div style={{ pointerEvents: 'none' }}>
            <div>
              {!hasGallery && <NotImageIcon className={styles.notImage} />}
              <p>{roundFloatNumber(Number(i?.area) + (i?.terrace?.area ? Number(i?.terrace?.area) : 0))}</p>
              <p>
                {i?.rooms}
                {i.rooms ? <span>X</span> : <span style={{ color: 'red' }}>X</span>}
                {i?.category ? ` | ${i?.category?.substring(0, 1)}` : ''}
              </p>
            </div>
            <p>
              <span
                style={{
                  background: getBadgeColor()
                }}
              >
                {i?.number}
              </span>
              {i?.discountID && !Number.isNaN(i.discountID) && <span>%</span>}
            </p>
          </div>
          <p style={{ pointerEvents: 'none' }}>{repaired ? Number(i?.pricePerAreaRepaired).toLocaleString('ru') : Number(i?.pricePerArea).toLocaleString('ru')}</p>
          <h1 style={{ pointerEvents: 'none' }}>{repaired ? Number(i?.priceRepaired).toLocaleString('ru') : Number(i?.priceNotRepaired).toLocaleString('ru')}</h1>
        </div>
      )}
    </Dropdown>
  );
};

export default memo(ApartamentCard);
