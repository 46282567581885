import React, { useEffect, useState } from 'react';
import { CustomFields } from '@components/custom-fields/CustomFields';
import CustomeInput from '@components/custome-input/CustomeInput';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import ArrowIosDownIcon from '@icons/contract/ArrowIosDownIcon';
import CloseIcon from '@icons/contract/CloseIcon';
import FilterIcon from '@icons/FilterIcon';
import RefreshIcon from '@icons/RefreshIcon';
import SearchIcon from '@icons/SearchIcon';
import { useSmartCatalogFilterProperties } from '@pages/showroom-v2/services/queries';
import { showroomPath } from '@pages/showroom-v2/utils/constants/showroomPath';
import { setShowroomNewFilter } from '@reducers/ShowroomSlice';
import { Badge, Button, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './showroomV2Filter.module.scss';

const ShowroomV2Filter = () => {
  const dispatch = useAppDispatch();
  const { location } = useQueryParam();
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [more, setMore] = useState(false);
  const [offsetHeight, setOffsetHeight] = useState(0);
  const [hasFilter, setHasFilter] = useState<string[]>([]);
  const { data } = useSmartCatalogFilterProperties();
  const { flatCount, filter } = useAppSelector(state => state.showroomSlice);
  const houseCurrency = useAppSelector(state => state.currency.houseCurrency);

  const rooms = data?.rooms;

  const onFinish = (values: any) => {
    // console.log(values)
    const fields = values?.fields
      ?.filter((item: any) => item)
      ?.map((item: any) => (item[Object.keys(item)[0]] ? `${Object.keys(item)[0]}:${item[Object.keys(item)[0]]}` : null))
      ?.filter((item: any) => item);

    const copyValue = () => {
      const obj: any = {};

      Object.keys(values).forEach(key => {
        if (values[key] === 0 || values[key]) {
          obj[key as keyof typeof obj] = values[key];
        }
      });

      delete obj.fields;
      return obj;
    };

    dispatch(
      setShowroomNewFilter({
        ...copyValue(),
        ...(values?.commerceStatuses?.length > 0
          ? {
              commerceStatuses: values?.commerceStatuses
            }
          : { commerceStatuses: null }),
        ...(values?.rooms?.length > 0 ? { rooms: values?.rooms } : { rooms: null }),
        ...(fields?.length > 0 ? { fields } : { fields: null })
      })
    );

    setMore(false);
  };

  const handleClear = () => {
    form.resetFields();
    dispatch(setShowroomNewFilter({}));
  };

  useEffect(() => {
    if (filter) {
      const obj = {
        ...filter,
        rooms: filter?.rooms || [],
        commerceStatuses: filter?.commerceStatuses || [],
        apartment: form.getFieldValue('apartment')
      };

      delete obj.fields;

      const objCopy = { ...obj } as any;

      delete objCopy.houseId;
      delete objCopy.page;
      delete objCopy.size;

      form.setFieldsValue({
        ...objCopy
      });
      Object.keys(objCopy).forEach((item: any) => {
        if (objCopy[item]) {
          if (Array.isArray(objCopy[item])) {
            if (objCopy[item].length === 0) {
              delete objCopy[item];
            }
          }
        } else {
          delete objCopy[item];
        }
      });

      setHasFilter(Object.keys(objCopy));
    }
  }, [filter]);

  const formDisable = location.pathname?.includes(showroomPath.VISUAL);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.body} style={{ height: more ? offsetHeight + 60 : 60 }}>
          <div
            ref={ref => {
              setOffsetHeight(ref?.offsetHeight || 0);
            }}
          >
            <Form
              className={styles.form}
              onFinish={onFinish}
              form={form}
              disabled={formDisable}
              onFieldsChange={(field, all) => {
                const arr = [
                  ...all?.filter(item => (Array.isArray(item?.value) ? item?.value?.length > 0 : item?.value)).map(item => item.name?.toString())
                ];

                setHasFilter([...arr]);
              }}
            >
              <div className={styles.left}>
                <div className={styles.grid}>
                  <Form.Item noStyle name="rooms">
                    <Select className="customSelect" placeholder={t('home.Xonalar_soni')} suffixIcon={<ArrowIosDownIcon size={20} />} mode="multiple">
                      {rooms?.map(item => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className={styles.spaceInput}>
                    <Form.Item noStyle name="minPrice">
                      <CustomeInput type="price" placeholder={t('home.dan')} addonBefore={houseCurrency?.ccy || 'UZS'} maxLength={14} />
                    </Form.Item>
                    <Form.Item noStyle name="maxPrice">
                      <CustomeInput type="price" placeholder={t('home.gacha')} maxLength={14} />
                    </Form.Item>
                  </div>
                  <div className={styles.spaceInput}>
                    <Form.Item noStyle name="minArea">
                      <CustomeInput type="number" placeholder={t('home.dan')} addonBefore="m²" />
                    </Form.Item>
                    <Form.Item noStyle name="maxArea">
                      <CustomeInput type="number" placeholder={t('home.gacha')} />
                    </Form.Item>
                  </div>
                  <div className={styles.spaceInput}>
                    <Form.Item noStyle name="minFloor">
                      <CustomeInput type="intNumber" placeholder={t('home.dan')} addonBefore={t('home.Qavat')} maxLength={10} />
                    </Form.Item>
                    <Form.Item noStyle name="maxFloor">
                      <CustomeInput type="intNumber" placeholder={t('home.gacha')} maxLength={10} />
                    </Form.Item>
                  </div>
                </div>
                <div className={styles.grid} style={{ opacity: more ? 1 : 0 }}>
                  <Form.Item noStyle>
                    <div className={styles.spaceInput}>
                      <Form.Item noStyle name="minPerAreaPrice">
                        <CustomeInput type="price" placeholder={t('home.dan')} addonBefore={`${houseCurrency?.ccy || 'UZS'} / m²`} maxLength={14} />
                      </Form.Item>
                      <Form.Item noStyle name="maxPerAreaPrice">
                        <CustomeInput type="price" placeholder={t('home.gacha')} maxLength={14} />
                      </Form.Item>
                    </div>
                  </Form.Item>
                  <Form.Item noStyle name="repaired">
                    <Select className="customSelect" placeholder={t('showroom.Tamir_holati')} suffixIcon={<ArrowIosDownIcon size={20} />}>
                      <Select.Option value={null}>{t('home.Barchasi')}</Select.Option>
                      <Select.Option value={true}>{t('shaxmatka.Tamirli')}</Select.Option>
                      <Select.Option value={false}>{t('shaxmatka.Tamirsiz')}</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item noStyle name="commerceStatuses">
                    <Select className="customSelect" placeholder={t('home.Holati')} suffixIcon={<ArrowIosDownIcon size={20} />} mode="multiple">
                      {data?.commerceStatuses?.map(item => (
                        // eslint-disable-next-line react/jsx-key
                        <Select.Option value={item.value}>{item[i18n.language as keyof typeof item]}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item noStyle name="apartment">
                    <Select className="customSelect" placeholder={t('home.Turi')} suffixIcon={<ArrowIosDownIcon size={20} />}>
                      <Select.Option value="null">{t('home.Barchasi')}</Select.Option>
                      <Select.Option value="true">{t('home_id.Turarjoy')}</Select.Option>
                      <Select.Option value="false">{t('home_id.Noturar_joy')}</Select.Option>
                    </Select>
                  </Form.Item>
                  {data?.fields?.map(field => (
                    <Form.Item noStyle key={field.id} name={['fields', field?.id, String(field?.id)]}>
                      {CustomFields[field?.type]!({
                        options: field?.options,
                        placeholder: field?.name,
                        suffixIcon: <ArrowIosDownIcon size={20} />
                      })}
                    </Form.Item>
                  ))}
                </div>
                <div className={styles.left_footer}>
                  <p>
                    {t('showroom.Topilgan_uylar_soni')}: {flatCount}
                  </p>
                  <span onClick={handleClear}>
                    {t('home.Tozalash')} <CloseIcon size={20} />
                  </span>
                </div>
              </div>
              <div className={styles.right}>
                <div className={styles.spaceButton}>
                  <Badge count={hasFilter?.length}>
                    <Button onClick={() => setMore(!more)}>
                      {more ? <CloseIcon strokeWidth={1.5} size={20} /> : <FilterIcon size={20} />}
                      {more ? t('home.Yopish') : t('showroom.Batafsil')}
                    </Button>
                  </Badge>
                  <Button disabled={!(hasFilter?.length > 0)} type="primary" onClick={() => form.submit()}>
                    <SearchIcon stroke="#ffffff" size={16} /> {t('home.Qidirish').replaceAll('.', '')}
                  </Button>
                  {!more && hasFilter?.length > 0 && <RefreshIcon size={20} onClick={handleClear} className={styles.spaceButton_clear} />}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div
        style={{
          opacity: more ? 1 : 0,
          pointerEvents: more ? 'inherit' : 'none'
        }}
        className={styles.mask}
        onClick={() => setMore(false)}
      />
    </>
  );
};

export default ShowroomV2Filter;
