/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useAppSelector } from '@hooks/reduxHooks';
import ArrowLineDownIcon from '@icons/ArrowLineDownIcon';
import TrashIcon from '@icons/contract/TrashIcon';
import Info from '@icons/Info';
import { useDownloadPaymentBookingToWord } from '@queries/mutation';
import { Permissions } from '@utils/constants/permissions';
import { Button, Spin, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import DotIcon from '../../../../assets/icons/contract/DotIcon';
import InfoIcon from '../../../../assets/icons/InfoIcon';
import DiscountInfo from '../../../../components/discount-info/DiscountInfo';
import { OrderStatus } from '../../../../utils/models/OrderStatus';
import { Order, PaymentListModel } from '../../../../utils/models/OrderTable';
import paymentTypesV2 from '../../utils/helpers/paymentTypesV2';
import OrderV2CancelModal from '../order-id-v2-modals/OrderV2CancelModal';
import OrderV2FirstModal from '../order-id-v2-modals/OrderV2FirstModal';
import OrderV2InfoModal from '../order-id-v2-modals/OrderV2InfoModal';
import OrderV2LastModal from '../order-id-v2-modals/OrderV2LastModal';

import emptyTableImg from '../../../../assets/img/emptytable.png';

import styles from './orderV2Table.module.scss';

type Props = {
  data: Order | undefined;
  isLoading: boolean;
};

const OrderV2Table: React.FC<Props> = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const [openFirstModal, setOpenFirstModal] = useState(false);
  const [openLastModal, setOpenLAstModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [paymentId, setPaymentId] = useState<number | undefined>();
  const [paymentNumber, setPaymentNumber] = useState<string | undefined>();
  const [paymentInfoVisible, setPaymentInfoVisible] = useState(false);
  const [payment, setPayment] = useState<PaymentListModel | undefined>(undefined);

  const [activePaymentDownload, setActivePaymentDownload] = useState(0);

  // const { params, location } = useQueryParam();
  // const query = queryString.parse(location.search);
  // const { data: payments, isLoading: paymentsLoading } = usePayment(params.id || Number(query?.contractId === '0' ? '' : query?.contractId) || '');

  const permisssions = useAppSelector(state => state.userReducer?.user?.role?.permissions);

  const isAccessToCancelPayment = permisssions?.includes(Permissions.PERMISSION_BOOKING_PAYMENT);

  const downloadPaymentBooking = useDownloadPaymentBookingToWord();

  const handleOpenCancelModal = () => {
    data?.status === 'CANCELED' ? setOpenCancelModal(false) : setOpenCancelModal(true);
  };

  const downloadPaymentBookingToWord = (bookingId: number, id: number, number: string) => {
    downloadPaymentBooking.mutate({
      id,
      number,
      bookingId
    });
  };

  const columns = [
    {
      title: '№',
      dataIndex: 'key',
      width: 30,
      render: (text: any, prev: PaymentListModel, index: number) => <span style={{ color: prev?.canceledBy ? '#BBB' : '#464255' }}>{index + 1}</span>
    },
    {
      title: t('home.Tolov_raqami'),
      key: 'number',
      width: 80,
      render: (prev: PaymentListModel) => (
        <span className={styles.paymentSpan} style={{ color: prev?.canceledBy ? '#BBB' : '#464255' }}>
          {prev?.number}
        </span>
      )
    },
    {
      title: t('home.Tolov_summasi'),
      key: 'amount',
      width: 90,
      render: (render: PaymentListModel) => (
        <span className={styles.paymentSpan} style={{ color: render?.canceledBy ? '#BBB' : '#464255' }}>
          {render?.amount?.toLocaleString('ru')} {render?.currency?.ccy}
        </span>
      )
    },
    {
      title: t('contract_id.Tolov_turi'),
      key: 'type',
      width: 80,
      render: (prev: PaymentListModel) =>
        prev?.canceledBy ? (
          <span className={styles.paymentType}>
            <DotIcon color="#bbb" /> {t(`home.${prev?.type}`)}
          </span>
        ) : (
          paymentTypesV2[prev?.type as keyof typeof paymentTypesV2]
        )
    },
    {
      title: t('home.Xodim'),
      key: 'payer',
      width: 140,
      render: (prev: PaymentListModel) => (
        <span className={styles.paymentSpan} style={{ color: prev?.canceledBy ? '#BBB' : '#464255' }}>
          {prev?.createdBy}
        </span>
      )
    },
    {
      title: t('home.Yaratilgan_sana'),
      key: 'date',
      width: 100,
      render: (prev: PaymentListModel) => (
        <span
          style={{
            color: prev?.canceledBy ? '#BBB' : '#464255',
            fontWeight: 500
          }}
        >
          {prev?.createdDate}
        </span>
      )
    },
    {
      title: '',
      key: 'actions',
      width: 70,
      render: (prev: PaymentListModel) => (
        <div style={{ cursor: 'pointer' }}>
          {prev?.canceledBy ? (
            <span>
              <DiscountInfo content={<span>{prev?.cancelNote}</span>} title="Izoh">
                <InfoIcon />
              </DiscountInfo>
            </span>
          ) : data?.status === OrderStatus.CONTRACT || data?.status === OrderStatus.CANCELED ? (
            ''
          ) : (
            <div className="flex items-center gap-2">
              <span
                onClick={() => {
                  setPayment(prev);
                }}
              >
                <Info size={25} />
              </span>
              {/* @ts-ignore */}
              <span
                onClick={() => {
                  downloadPaymentBookingToWord(data?.id!, prev?.id!, data?.number!);
                }}
              >
                {/* <ArrowLineDownIcon color="rgb(52, 64, 84)" size={20} /> */}

                {downloadPaymentBooking?.isLoading && prev?.id === activePaymentDownload ? <LoadingOutlined /> : <ArrowLineDownIcon color="#3772FF" size={24} />}
              </span>
              {isAccessToCancelPayment && (
                <span
                  onClick={() => {
                    setPaymentId(prev?.id);
                    setPaymentNumber(prev?.number);
                    handleOpenCancelModal();
                  }}
                >
                  <TrashIcon />
                </span>
              )}
            </div>
          )}
        </div>
      )
    }
  ];

  // handle open first modal
  const handleOpenFirstModal = () => {
    setOpenFirstModal(true);
  };
  const handleOpenLastModal = () => {
    setOpenLAstModal(true);
  };

  // console.log(payment)

  return (
    <Spin spinning={isLoading}>
      <div className={styles.container}>
        <div className={styles.container_header}>
          <p>{t('boss_home.Tolovlar')}</p>
          {data?.paymentList?.length! > 0 && data?.status !== 'CANCELED' && data?.status !== 'CONTRACT' && isAccessToCancelPayment && (
            <Button onClick={handleOpenFirstModal}>{t('client_balance.Tolov_qilish')}</Button>
          )}
        </div>
        {data?.paymentList?.length! > 0 ? (
          <div className={styles.container_table}>
            <Table columns={columns} dataSource={data?.paymentList} pagination={false} scroll={{ y: 'calc(100vh - 240px)' }} />
          </div>
        ) : (
          <div className={styles.emptyPayment}>
            <img src={emptyTableImg} alt="err" />
            {data?.status === 'CANCELED' || data?.status === 'CONTRACT' ? (
              <p
                style={{
                  color: '#898F98',
                  fontSize: 14,
                  lineHeight: '20px',
                  fontWeight: 500
                }}
              >
                {data?.status === 'CANCELED' ? t('order.Ushbu_buyurtma_bekor_qilingan') : t('home.Shartnoma_qilingan')}
              </p>
            ) : (
              <>{isAccessToCancelPayment && <Button onClick={handleOpenLastModal}>{t('client_balance.Tolov_qilish')}</Button>}</>
            )}
          </div>
        )}
        <OrderV2FirstModal open={openFirstModal} setOpen={setOpenFirstModal} bookingId={data?.id} currency={data?.bookingCurrency} />
        <OrderV2LastModal open={openLastModal} setOpen={setOpenLAstModal} bookingId={data?.id} currency={data?.bookingCurrency} />
        <OrderV2CancelModal open={openCancelModal} setOpen={setOpenCancelModal} paymentIds={paymentId} paymentNumber={paymentNumber} />

        <OrderV2InfoModal payment={payment} setPayment={setPayment} />
      </div>
    </Spin>
  );
};

export default OrderV2Table;
