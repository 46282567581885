import React from 'react';
import ActivePipe from './activePipe/ActivePipe';
import ClosedPipes from './closedPipes/ClosedPipes';
import { Empty, Spin } from 'antd';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useCustomerFlow } from '../../../queries/queries';
import { useTranslation } from 'react-i18next';
import { CLOSED_FAIL, CLOSED_SUCCESSFULLY } from '../../../utils/constants/PipeStatusOrder';

const PipesBody = () => {
  const { selectedPipe, employeeId, leadStatus, finishDatePipe, startDatePipe } = useAppSelector(state => state.crmStatistic);

  const { data, isLoading, isFetching } = useCustomerFlow(selectedPipe?.id, employeeId, leadStatus, finishDatePipe, startDatePipe);

  const { t } = useTranslation();

  return (
    <Spin spinning={isLoading || isFetching}>
      {data?.pipeStatusList?.length === 0 && <Empty description={t('boss_crm.empty')} />}
      {data?.pipeStatusList?.map((item, index) => {
        if (item.order === CLOSED_SUCCESSFULLY) {
          return (
            <ClosedPipes
              name={item.name}
              countLead={item.countLead}
              key={index}
              percent={item.countLeadPercent}
              color={item.color}
              allDate={undefined}
              percentLine={item.percentLine}
            />
          );
        }
        if (item.order === CLOSED_FAIL) {
          return (
            <ClosedPipes
              name={item.name}
              countLead={item.countLead}
              key={index}
              percent={item.countLeadPercent}
              color={item.color}
              allDate={data?.averageDay || 0}
              percentLine={item.percentLine}
            />
          );
        }
        return (
          <ActivePipe
            countLead={item.countLead}
            name={item.name}
            key={index}
            percentSpace={item.spacePercent}
            percentLine={item.percentLine}
            percentBorder={item.borderPercent}
            color={item.color}
            day={item.day}
            percent={item.countLeadPercent}
            percentDate={item.dayPercent}
          />
        );
      })}
    </Spin>
  );
};

export default PipesBody;
