import React from 'react';

const NotificationDeveloperIcon = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 17H7C7 17.5304 7.21071 18.0391 7.58579 18.4142C7.96086 18.7893 8.46957 19 9 19C9.53043 19 10.0391 18.7893 10.4142 18.4142C10.7893 18.0391 11 17.5304 11 17H17C17.2652 17 17.5196 16.8946 17.7071 16.7071C17.8946 16.5196 18 16.2652 18 16C18 15.7348 17.8946 15.4804 17.7071 15.2929C17.5196 15.1054 17.2652 15 17 15V8C17 5.87827 16.1571 3.84344 14.6569 2.34315C13.1566 0.842855 11.1217 0 9 0C6.87827 0 4.84344 0.842855 3.34315 2.34315C1.84285 3.84344 1 5.87827 1 8V15C0.734783 15 0.480429 15.1054 0.292893 15.2929C0.105357 15.4804 0 15.7348 0 16C0 16.2652 0.105357 16.5196 0.292893 16.7071C0.480429 16.8946 0.734783 17 1 17V17ZM3 8C3 6.4087 3.63214 4.88258 4.75736 3.75736C5.88258 2.63214 7.4087 2 9 2C10.5913 2 12.1174 2.63214 13.2426 3.75736C14.3679 4.88258 15 6.4087 15 8V15H3V8Z"
        fill="#00A389"
      />
    </svg>
  );
};

export default NotificationDeveloperIcon;
