import { useMutation, useQueryClient } from 'react-query';
import { message } from 'antd';
import { endPoints } from '../utils/constants/endPoints';
import { $api } from '@service/RequestService';
import { queryNames } from '@utils/constants/queryNames';

export function useClearNotification(type?: 'ALL' | 'UNREAD' | 'CONTRACT' | 'ACTIVE_SESSION_SECURITY') {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async (id: number) => {
      const res = await $api.post(endPoints.POST_CLEAR_NOTIFICATION + `?id=` + `${id}`);
      return res.data;
    },
    onSuccess: (data: any) => {
      qc.fetchQuery([queryNames.NOTIFICATION_ME, type]);
      // message.success(data.message.uz);
    },
    onError: (data: any) => {
      message.error(data.response.data.message.uz);
    }
  });
}
