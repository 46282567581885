import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@hooks/reduxHooks';
import { setCurrentPage } from '@reducers/ClientSlice';
import { setOpenClientMessage } from '@reducers/CrmOneLidSlice';
import { Permissions } from '@utils/constants/permissions';
import { Dropdown, Layout, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import AddSquareBackground from '../../assets/icons/AddSquareBackground';
import ArrowLineDownIcon from '../../assets/icons/ArrowLineDownIcon';
import Download from '../../assets/icons/Download';
import ExcelIcon from '../../assets/icons/ExcelIcon';
import TableSettings from '../../components/filters/TableSettings';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import PaginationCont from '../../components/pagenation/PaginationCont';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useDownloadClientsToExcel } from '../../queries/mutation';
import { useClient, useTableConfig } from '../../queries/queries';
import { MainPaths } from '../../routing/main/mainPaths';
import { rootPaths } from '../../routing/rootPaths';
import { TableKey } from '../../utils/models/TableKey';

import ClientFilter from './components/ClientFilter';
import ClientMessageModal from './components/ClientMessageModal';
import ClientTable from './components/ClientTable';

import stylesButton from '../../components/filters/tableSettings.module.scss';
import styles from './Client.module.scss';

const { Content } = Layout;

export interface SearchParamsClient {
  page?: string;
  size?: string;
  startDate?: string;
  finishDate?: string;
  statuses?: string[];
  cities?: string[];
  search?: string;
  order?: string[];
  roles?: number[];
  expired?: boolean;
}

const Client = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { searchParams, appendMultipleDifferent, push, params } = useQueryParam<SearchParamsClient, any>();

  const { clientId, clientIsVisible, clientName, clientPhone } = useAppSelector(state => state.crmOneLidField);
  const { currentPage } = useAppSelector(state => state.clientReducer);
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const [tableSize, setTableSize] = useState(10);
  const [visibleDownloadMenu, setVisibleDownloadMenu] = useState(false);

  const { data: tableConfig, isFetching: tableConfigFetch } = useTableConfig(TableKey.client);

  const searchObj = { ...searchParams, expired: Boolean(searchParams?.expired) };

  const { data, isLoading, isFetching } = useClient(tableSize, searchObj);
  const download = useDownloadClientsToExcel(tableSize, searchObj);

  useEffect(() => {
    if (tableConfig?.size && params['*']?.includes('client')) {
      appendMultipleDifferent(['page', 'size'], [currentPage, tableConfig.size]);
      setTableSize(tableConfig.size);
    }
  }, [tableConfig?.size]);

  useEffect(() => {
    if (!params['*']?.includes('client')) {
      dispatch(setCurrentPage(1));
    }
  }, [params['*']]);

  const closeClientMessage = () => {
    dispatch(setOpenClientMessage({ id: -1, isVisible: false, name: '', phone: '' }));
  };

  const changePage = (page: number) => {
    dispatch(setCurrentPage(page));
    appendMultipleDifferent(['page', 'size'], [page, tableSize]);
  };

  const downloadExcel = () => {
    download.mutate();
    setVisibleDownloadMenu(false);
  };

  const downloadButton = (
    <Dropdown
      visible={visibleDownloadMenu}
      onVisibleChange={visible => {
        setVisibleDownloadMenu(visible);
      }}
      placement="bottomRight"
      overlay={
        <div className={stylesButton.downloadPopover}>
          <div onClick={downloadExcel} className={stylesButton.downloadPopover_item}>
            <div className={stylesButton.downloadPopover_item_element}>
              <ExcelIcon />
              <span>{t('home.Excel')}</span>
            </div>
            <ArrowLineDownIcon />
          </div>
        </div>
      }
      trigger={['click']}
    >
      <span onClick={() => setVisibleDownloadMenu(true)} className={stylesButton.icon}>
        <Download />
      </span>
    </Dropdown>
  );

  const goToCreateClient = () => {
    push(rootPaths.MAIN + MainPaths.CLIENT + MainPaths.CREATE_NEW_CLIENT);
  };

  const isAccessUpdateClient = permissions?.includes(Permissions.PERMISSION_UPDATE_CLIENT);

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={styles.client_page}>
          <div className="d-flex align-center justify-space-between">
            <ClientFilter />
            <Spin spinning={download.isLoading}>
              <TableSettings downloadButton={downloadButton} tableKey={TableKey.client} />
            </Spin>
            {isAccessUpdateClient && (
              <div onClick={goToCreateClient} className={styles.settingClientAdd}>
                <AddSquareBackground />
              </div>
            )}
          </div>
          <ClientTable
            isFetching={isFetching}
            data={data?.data || []}
            isLoading={isLoading}
            currentPage={searchParams.page ? Number(searchParams.page) : 1}
            pageSize={tableSize}
            tableConfig={tableConfig?.config || []}
            tableConfigFetch={tableConfigFetch}
          />
          <PaginationCont
            total={data?.totalElements !== undefined ? data.totalElements : -1}
            totalPages={data?.totalPages || 1}
            changePage={changePage}
            currentPage={data?.currentPage ? data.currentPage : 1}
            defaultPageSize={tableSize}
          />
          <ClientMessageModal modalWidth={689} visible={clientIsVisible} close={closeClientMessage} name={clientName} phoneNumber={clientPhone} clientId={clientId} />
        </div>
      </Content>
    </>
  );
};

export default Client;
