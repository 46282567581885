import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import CloseIcon from '../../assets/icons/CloseIcon';
import ErrorIcon from '../../assets/icons/ErrorIcon';
import Loading from '../../components/loading/Loading';
import PrivacyColicies from '../../components/privacyColicies/PrivacyColicies';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useSignIn } from '../../queries/mutation';
import { DeveloperPaths } from '../../routing/developer/developerPaths';
import { MainPaths } from '../../routing/main/mainPaths';
import { rootPaths } from '../../routing/rootPaths';
import { LocalStorage } from '../../service/LocalStorage';
import { $api } from '../../service/RequestService';
import { houseEndpoints } from '../../utils/constants/apis';
import { TOKEN_KEY, USER_KEY } from '../../utils/constants/localStorageKeys';
import { Permissions } from '../../utils/constants/permissions';
import { helper } from '../../utils/helper/helper';
import { Login as LoginI } from '../../utils/models/Login';

import bg from '../../assets/img/bg_new.jpg';

import styles from './login.module.scss';

function Login() {
  const [form] = Form.useForm();
  const [checkLogIn, setCheckLogIn] = useState(true);
  const [privacyPolicies, setPrivacyPolicies] = useState(false);
  const { i18n, t } = useTranslation();
  const { push } = useQueryParam();
  const login = useSignIn();

  useEffect(() => {
    const token = LocalStorage.get(TOKEN_KEY);
    const user = LocalStorage.get(USER_KEY);

    if (token && user) {
      $api
        .get(houseEndpoints.COMPACT)
        .then(() => {
          if (user.role.permissions[0] === Permissions.PERMISSION_DEVELOPER) {
            push(`${rootPaths.DEVELOPER}${DeveloperPaths.INDEX}`);
          } else {
            push(`${rootPaths.MAIN}${MainPaths.INDEX}`);
          }
        })
        .catch(() => {
          setCheckLogIn(false);
        });
    } else {
      setCheckLogIn(false);
    }
  }, []);

  const onFinish = (values: LoginI) => {
    login.mutate(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const changeLanguageHandler = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  // handlePrivacyPolicies
  const handlePrivacyPolicies = () => {
    setPrivacyPolicies(true);
  };

  // handlePrivacyPoliciesClose
  const handlePrivacyPoliciesClose = () => {
    setPrivacyPolicies(false);
  };

  // handle restore password
  const handleRestorePassword = () => {
    push(rootPaths.RESTORE_PASSWORD);
  };

  if (checkLogIn) return <Loading />;
  const lan = localStorage.getItem('i18nextLng');

  const message = localStorage.getItem('message')!;
  const errMessage = {
    uz: 'Litsenziya muddati tugaganligi sababli hisobingiz bloklandi!',
    ru: 'Ваша учетная запись заблокирована, так как срок действия вашей лицензии истек!',
    en: 'Your account has been locked because your license has expired!',
    kr: 'Лицензия муддати тугаганлиги сабабли ҳисобингиз блокланди!',
    tr: 'Lisansın süresi dolduğu için hesabınız bloke edildi!'
  };

  return (
    <div className={styles.login}>
      <img src={bg} className={styles.login_login_newYear_bg} alt="bg" />
      <div className={styles.login_container_div}>
        <div className={styles.login_body}>
          <div>
            <h1>{t('login.Kirish')}</h1>
            {login.error && (
              <div className={`${styles.login_error} d-flex align-center`}>
                <ErrorIcon />
                <span className={styles.login_error_title}>{message === 'undefined' ? errMessage[lan as keyof typeof errMessage] : message}</span>
              </div>
            )}
            <Form
              form={form}
              name="basic"
              // labelCol={{ span: 4 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label={<span className={styles.login_title}>{t('login.Login')}</span>}
                validateStatus={login.error ? 'error' : undefined}
                name="username"
                rules={[
                  { required: true, message: '' },
                  {
                    validator: (rule, value) => {
                      form.setFieldsValue({
                        username: helper.deleteSpace(value)
                      });
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input
                  // prefix={<span className={styles.login_icon_at}>@</span>}
                  style={{ borderRadius: 'var(--border-6)' }}
                />
              </Form.Item>
              <Form.Item
                label={<span className={styles.login_title}>{t('login.Parol')}</span>}
                name="password"
                validateStatus={login.error ? 'error' : undefined}
                rules={[{ required: true, message: '' }]}
              >
                <Input.Password style={{ borderRadius: 'var(--border-6)' }} />
              </Form.Item>
              <div className={styles.login_not_remember_password}>
                <span onClick={handleRestorePassword}>{t('login.Parolni_unutdim')}</span>
              </div>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button loading={login.isLoading} type="primary" htmlType="submit" className={styles.login_button}>
                  {t('login.Kirish')}
                </Button>
              </Form.Item>
            </Form>
          </div>
          <footer className={styles.footer} onClick={handlePrivacyPolicies}>
            {t('privacy_policies.Yuqoridagi_tugmani_bosish_orqali_siz_bizning')} <span>&nbsp;{t('privacy_policies.foydalanish_shartlari')} &nbsp;</span> {t('privacy_policies.va')}{' '}
            <span> &nbsp;{t('privacy_policies.maxfiylik_siyosatimizga')}</span>
          </footer>
        </div>
        <div className={`${styles.privacyPoliciesMask} ${privacyPolicies ? styles.privacyPoliciesMask_active : ''}`} onClick={handlePrivacyPoliciesClose} />
        <div className={`${styles.privacyPolicies} ${privacyPolicies ? styles.privacyPolicies_active : ''}`}>
          <span className={styles.privacyPolicies_close} onClick={handlePrivacyPoliciesClose}>
            <CloseIcon />
          </span>
          <PrivacyColicies />
        </div>
        <div className={styles.lang}>
          <span onClick={() => changeLanguageHandler('en')} className={styles.lang_item + (i18n.language === 'en' ? ` ${styles.lang_item_active}` : '')}>
            English
          </span>
          <span className={styles.lang_dot} />
          <span onClick={() => changeLanguageHandler('ru')} className={styles.lang_item + (i18n.language === 'ru' ? ` ${styles.lang_item_active}` : '')}>
            Русский
          </span>
          <span className={styles.lang_dot} />
          <span onClick={() => changeLanguageHandler('uz')} className={styles.lang_item + (i18n.language === 'uz' ? ` ${styles.lang_item_active}` : '')}>
            O’zbek
          </span>
          <span className={styles.lang_dot} />
          <span onClick={() => changeLanguageHandler('tr')} className={styles.lang_item + (i18n.language === 'tr' ? ` ${styles.lang_item_active}` : '')}>
            Turk
          </span>
        </div>
      </div>
    </div>
  );
}

export default Login;
