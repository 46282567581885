import React from 'react';

import styles from './shablonHeader.module.scss';
import { Button, Input } from 'antd';
import SearchIcon from '@icons/SearchIcon';
import PlusIcon from '@icons/PlusIcon';

interface Props {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShablonHeader: React.FC<Props> = ({ setVisible }) => {
  const handleOpen = () => {
    setVisible(true);
  };
  return (
    <div className={styles.container}>
      <Input placeholder="Shablon qidirish" addonAfter={<SearchIcon />} />
      <Button type="primary" onClick={handleOpen}>
        <PlusIcon />
        <span>Yangi yaratish</span>
      </Button>
    </div>
  );
};

export default ShablonHeader;
