import React, { FC, useEffect, useState } from 'react';
import { Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import DocumentTextIcon from '../../../assets/icons/DocumentTextIcon';
import ReceiveSquareIcon from '../../../assets/icons/ReceiveSquareIcon';
import { useDownloadDocumentSample } from '../../../queries/mutation';
import { FileTemplate, FileTemplateLanguage, FileTemplateType } from '../../../utils/models/FileTemplate';
import TemplateFile from '../template-file/TemplateFile';
import UploadTemplate from '../upload-template/UploadTemplate';

import styles from './companyTemplate.module.scss';

interface IProps {
  file: FileTemplate[] | undefined;
  lang: FileTemplateLanguage;
  type: FileTemplateType;
  showTemplate?: boolean;
  tempId?: number;
  header?: FileTemplateLanguage;
}

const CompanyTemplate: FC<IProps> = ({ file, type, lang, showTemplate, tempId, header }) => {
  const [newType, setNewType] = useState(lang);
  const downloadSample = useDownloadDocumentSample(type);
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [fileId, setFileId] = useState<number | undefined>();

  useEffect(() => {
    if (file) {
      setNewType(lang);
    }
  }, [file, lang]);

  // handleUpload
  const handleUpload = () => {
    setVisible(true);
  };

  // handleDownloadSample
  const handleDownloadSample = () => {
    downloadSample.mutate();
  };

  // handleChange
  const handleChange = (lng: FileTemplateLanguage) => {
    // file?.forEach((item) => {
    //   documentTemplate.mutate({
    //     language: lng,
    //     type: type,
    //     fileID: item?.document?.id,
    //   })
    // })
  };

  return (
    <>
      <div className={styles.item}>
        <div className={styles.item_header}>
          <h1>
            {type === FileTemplateType.CONTRACT ||
            type === FileTemplateType.BOOKING ||
            type === FileTemplateType.CONTRACT_CUSTOM ||
            type === FileTemplateType.ACT ||
            type === FileTemplateType.CLIENT_PAYMENT ||
            type === FileTemplateType.BOOKING_PAYMENT ||
            type === FileTemplateType.PAYMENT ? (
              <>{header}</>
            ) : (
              <Select value={newType} className="customSelect" onChange={handleChange}>
                <Select.Option value={FileTemplateLanguage.UZLAT}>[{FileTemplateLanguage.UZLAT}]</Select.Option>
                <Select.Option value={FileTemplateLanguage.UZCYR}>[{FileTemplateLanguage.UZCYR}]</Select.Option>
                <Select.Option value={FileTemplateLanguage.EN}>[{FileTemplateLanguage.EN}]</Select.Option>
                <Select.Option value={FileTemplateLanguage.RU}>[{FileTemplateLanguage.RU}]</Select.Option>
              </Select>
            )}
          </h1>

          <div>
            {showTemplate && (
              <Button onClick={handleDownloadSample} loading={downloadSample.isLoading} className={styles.templateExample}>
                <ReceiveSquareIcon size={16} />
                {t('company_config.Shablon_namunasi')}
              </Button>
            )}
            {file && file?.length > 0 ? (
              <Button className={styles.uploadFile} onClick={handleUpload}>
                + {t('company_config.File_yuklash')}
              </Button>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className={styles.item_container}>
          {file && file?.length > 0 ? (
            file?.map(item => <TemplateFile key={item.id} file={item} type={type} setVisible={setVisible} setFileId={setFileId} />)
          ) : (
            <div className={styles.item_container_uploadFile}>
              <div className={styles.item_container_uploadFile_left}>
                <span>
                  <DocumentTextIcon />
                </span>
                <div>
                  <h3>{t('company_config.Shartnoma_namunasini_yuklash')} </h3>
                  <p>{t('company_config.Shartnoma_shablonini_yuklash_uchun_bosing')}</p>
                </div>
              </div>
              <div className={styles.item_container_uploadFile_right}>
                <Button type="primary" onClick={handleUpload}>
                  + {t('company_config.File_yuklash')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <UploadTemplate setVisible={setVisible} visible={visible} type={type} lang={lang} fileId={fileId} setFileId={setFileId} tempId={tempId} />
    </>
  );
};

export default CompanyTemplate;
