import React from 'react';
import styles from './triggerItem.module.scss';
import TickSquareIcon from '../../../../../../assets/icons/TickSquareIcon';
import { TriggerList, TYPE_TRIGGER } from '../../../../../../utils/models/Trigger';
import { getCurrentLanguageWord } from '../../../../../../utils/helper/getCurrentLanguageWord';
import { setVisible } from '../../../../../../store/reducers/TriggerSlice';
import { useAppDispatch } from '../../../../../../hooks/reduxHooks';
import i18n from '../../../../../../service/I18nService';
import { useTranslation } from 'react-i18next';
import KanbanIcon from '../../../../../../assets/icons/KanbanIcon';
import ProfileTwoUserIcon from '../../../../../../assets/icons/crmIcons/ProfileTwoUserIcon';

interface Props {
  index: number;
  trigger: TriggerList;
  pipeStatusId: number;
}

const TriggerItem: React.FC<Props> = ({ index, trigger, pipeStatusId }) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  // handle add
  const handleOpenModal = () => {
    dispatch(
      setVisible({
        visible: true,
        id: trigger.id,
        pipeStatusId,
        order: trigger.order,
        typeTrigger: trigger.type
      })
    );
  };

  // translate
  const translateBalance = (low: number, high: number) => {
    switch (i18n.language) {
      case 'ru':
        return (
          <>
            Бюджет: от {low?.toLocaleString('ru')} до {high?.toLocaleString('ru')}
          </>
        );
      case 'en':
        return (
          <>
            Budget: {low?.toLocaleString('ru')} to {high?.toLocaleString('ru')}
          </>
        );
      default:
        return (
          <>
            Byudjet: {low?.toLocaleString('ru')} dan {high?.toLocaleString('ru')} gacha
          </>
        );
    }
  };

  if (trigger.type === TYPE_TRIGGER.TASK_TRIGGER)
    return (
      <div onClick={handleOpenModal} key={index} className={styles.container}>
        <div className={styles.container_tags}>
          {trigger.tags?.map((tag, index) => (
            <div key={index} className={styles.container_tags_item}>
              {tag.name}
            </div>
          ))}
        </div>
        {trigger.balance && (
          <div className={styles.container_tags}>
            <div className={styles.container_tags_item}>{translateBalance(trigger.balance.low as number, trigger.balance.high as number)}</div>
          </div>
        )}
        <div className={'d-flex'}>
          <TickSquareIcon className={styles.container_icon} />
          <div className={styles.container_title}>
            <h3>{t('triggers.Topshiriq_yaratish')}</h3>
            <p>{getCurrentLanguageWord(trigger.execute)}</p>
          </div>
        </div>
      </div>
    );

  if (trigger.type === TYPE_TRIGGER.MANAGER_TRIGGER)
    return (
      <div onClick={handleOpenModal} key={index} className={styles.container}>
        <div className={styles.container_tags}>
          {trigger.tags?.map((tag, index) => (
            <div key={index} className={styles.container_tags_item}>
              {tag.name}
            </div>
          ))}
        </div>
        {trigger.balance && (
          <div className={styles.container_tags}>
            <div className={styles.container_tags_item}>{translateBalance(trigger.balance.low as number, trigger.balance.high as number)}</div>
          </div>
        )}
        <div className={'d-flex'}>
          <ProfileTwoUserIcon className={styles.container_icon + ' ' + styles.container_user_icon} />
          <div className={styles.container_title}>
            <h3>{t('triggers.Masul_xodimni_ozgartirish')}</h3>
            <p>{getCurrentLanguageWord(trigger.execute)}</p>
          </div>
        </div>
      </div>
    );
  return (
    <div onClick={handleOpenModal} key={index} className={styles.container}>
      <div className={styles.container_tags}>
        {trigger.tags?.map((tag, index) => (
          <div key={index} className={styles.container_tags_item}>
            {tag.name}
          </div>
        ))}
      </div>
      {trigger.balance && (
        <div className={styles.container_tags}>
          <div className={styles.container_tags_item}>{translateBalance(trigger.balance.low as number, trigger.balance.high as number)}</div>
        </div>
      )}
      <div className={'d-flex'}>
        <KanbanIcon className={styles.container_icon} />
        <div className={styles.container_title}>
          <h3>{t('triggers.Lid_statusini_ozgartirish')}</h3>
          <p>{getCurrentLanguageWord(trigger.execute)}</p>
        </div>
      </div>
    </div>
  );
};

export default TriggerItem;
