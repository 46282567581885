import React, { useState } from 'react';

import styles from './source.module.scss';
import SettingTwoIcon from '../../../assets/icons/SettingTwoIcon';
import ProgressUpIcon from '../../../assets/icons/crmIcons/ProgressUpIcon';
import ProgressDownIcon from '../../../assets/icons/crmIcons/ProgressDownIcon';
import SelectSourceModal from './selectSourceModal/SelectSourceModal';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { usePlanFactCompanyField } from '../../../queries/queries';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

const Source = () => {
  const [selectSource, setSelectSource] = useState(false);

  const { t } = useTranslation();

  const { startDatePipe, finishDatePipe, planFactId, selectedPipe } = useAppSelector(state => state.crmStatistic);

  const { isLoading, data } = usePlanFactCompanyField(startDatePipe, finishDatePipe, planFactId, selectedPipe?.id);

  // handle select source
  const handleSelectSource = (visible: boolean) => {
    setSelectSource(visible);
  };

  return (
    <div className={styles.container}>
      <Spin spinning={isLoading}>
        <div className={styles.container_header}>
          <h2>{data?.companyLeadFieldName}</h2>
          <SettingTwoIcon onClick={() => handleSelectSource(true)} className={styles.container_header_setting} />
        </div>
        <div className={styles.container_count}>
          <h3>
            {data?.totalCount} {t('boss_crm.ta')}
          </h3>
          <p>{t('boss_crm.Umumiy_muroojatlar_soni')}</p>
          <hr />
        </div>
        {data?.fieldCountList.map((item, index) => {
          if (item.progress < 0) {
            return (
              <div key={index} className={styles.container_item}>
                <div className={styles.container_item_title}>
                  <span>{item.name}</span>
                  <p>
                    {item.count} {t('boss_crm.ta')}
                  </p>
                </div>
                <div className={styles.container_item_progress_fail}>
                  <ProgressDownIcon />
                  <span>{item.progress}%</span>
                </div>
              </div>
            );
          }
          return (
            <div key={index} className={styles.container_item}>
              <div className={styles.container_item_title}>
                <span>{item.name}</span>
                <p>
                  {item.count} {t('boss_crm.ta')}
                </p>
              </div>
              <div className={styles.container_item_progress_success}>
                <ProgressUpIcon />
                <span>+{item.progress}%</span>
              </div>
            </div>
          );
        })}
      </Spin>
      <SelectSourceModal id={data?.companyLeadFieldId} visible={selectSource} close={handleSelectSource} />
    </div>
  );
};

export default Source;
