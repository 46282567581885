import { $api } from '@service/RequestService';
import { message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { endPoints } from './endPoints';
import { queryKeys } from './queryKeys';

export function usePatchContractComment() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (data: {}) => {
      const res = await $api.patch(endPoints.PATCH_COMPANY_COMMENT, { ...data });

      return res.data;
    },
    onSuccess: success => {
      qc.invalidateQueries({ queryKey: [queryKeys.GET_COMPANY_COMMENTS] });
    },
    onError: (err: any) => {
      message.error(err?.response?.data?.message?.uz);
    }
  });
}
