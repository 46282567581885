export enum DeveloperPaths {
  INDEX = '/company',
  COMPANY_EMPLOYEES = '/company/:companyId/:companyName',
  PERMISSIONS = '/permissions',
  NOTIFICATION = '/notification',
  MONETARY = '/monetary',
  COMPANY_PERMISSION_CONFIG = '/company-permission-config',
  VORONKA_SHABLON = '/voronka-shablon',
  WEBSITE_USER = '/website-user'
}
