import React, { useState } from 'react';
import { Button, Drawer, Pagination, Spin } from 'antd';
import styles from './costDrawer.module.scss';
import { CloseOutlined } from '@ant-design/icons';

import PaginationPrev from '../../../../assets/icons/PaginationPrev';
import PaginationNext from '../../../../assets/icons/PaginationNext';
import TableCost from '../tableCost/TableCost';
import AddCircleIcon from '../../../../assets/icons/AddCircleIcon';
import CreateCostModal from '../createCostModal/CreateCostModal';
import { usePlanFactCost } from '../../../../queries/queries';
import { useTranslation } from 'react-i18next';

interface Props {
  visible: boolean;
  onClose: (visible: boolean) => void;
}

const CostDrawer: React.FC<Props> = ({ onClose, visible }) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();

  const { isLoading, data } = usePlanFactCost(currentPage, 10);

  //handle add modal
  const handleAddModal = (visible: boolean) => {
    setOpenAddModal(visible);
  };

  // handle close
  const handleClose = () => {
    onClose(false);
  };

  //handlePageChange
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Drawer width={743} headerStyle={{ display: 'none' }} placement="right" onClose={handleClose} visible={visible}>
        <Spin spinning={isLoading}>
          <div className={styles.container}>
            <div className={styles.container_header}>
              <div className={styles.container_header_left}>
                <span>{t('boss_crm.Xarajatlar')}</span>
              </div>
              <CloseOutlined onClick={handleClose} />
            </div>
            <div className={'d-flex align-center'}>
              <Button onClick={() => handleAddModal(true)} className={styles.container_button} type={'primary'}>
                <AddCircleIcon />
                <span>{t('boss_crm.Xarajat_qoshish')}</span>
              </Button>
            </div>
            <TableCost page={currentPage} data={data?.data || []} />
            <div className={styles.container_pagination}>
              <Pagination
                onChange={handlePageChange}
                prevIcon={
                  <div className={styles.container_pagination_next_prev_buttons}>
                    <PaginationPrev />
                  </div>
                }
                nextIcon={
                  <div className={styles.container_pagination_next_prev_buttons}>
                    <PaginationNext />
                  </div>
                }
                pageSize={10}
                showSizeChanger={false}
                current={currentPage}
                total={data?.totalElements || 0}
              />
            </div>
          </div>
        </Spin>
        <CreateCostModal page={currentPage} visible={openAddModal} close={handleAddModal} />
      </Drawer>
    </>
  );
};

export default CostDrawer;
