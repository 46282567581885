import React from 'react';
import { Col, Row } from 'antd';
import styles from './client.module.scss';
import SelectType from './SelectType';
import SearchAndShow from './SearchAndShow';
import NaturalClient from './NaturalClient';
import JuridicalClient from './JuridicalClient';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { ClientType } from '../../../../utils/models/ClientType';

const Client = () => {
  const activeType = useAppSelector(state => state.changesReducer.clientType);

  const getActivePage = () => {
    if (ClientType.JURIDICAL === activeType) {
      return <JuridicalClient />;
    }
    return <NaturalClient />;
  };
  return (
    <div className={styles.cont}>
      <Row>
        <Col span={14}>
          <SelectType />
          {getActivePage()}
        </Col>
        <Col span={10}>
          <SearchAndShow />
        </Col>
      </Row>
    </div>
  );
};

export default Client;
