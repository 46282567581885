import { useQueryParam } from '@hooks/useQueryParams';
import { usePipeAll } from '@queries/queries';
import { TreeSelect } from 'antd';
import { useEffect, useState } from 'react';
import { ActivityLogs } from '../filtrDrawer/FiltrDrawer';

import styles from './changeStatus.module.scss';
import { useTranslation } from 'react-i18next';

const { SHOW_PARENT } = TreeSelect;

const ChangeStatus = () => {
  const { data: dataPipeAll } = usePipeAll();
  const { appendMultipleArray, searchParams } = useQueryParam<ActivityLogs, any>();
  const { t } = useTranslation();

  const treeData = dataPipeAll?.map(item => ({
    title: item?.name,
    value: `0-${item?.id}`,
    key: `0-${item?.id}`,
    children: item?.pipeStatuses?.map(prev => ({
      title: prev?.name,
      value: `0-${item?.id}-${prev?.id}`,
      key: `0-${item?.id}-${prev?.id}`
    }))
  }));

  function findValuesById(array1: any[], array2: any[]) {
    const value = array1?.filter(item => item?.split('-')[2] === undefined)?.map(item => item?.split('-')[1]);
    let resultArray = [];

    for (let i = 0; i < value.length; i++) {
      let idToFind = Number(value[i]);
      for (let j = 0; j < array2.length; j++) {
        if (array2[j].id === idToFind) {
          resultArray.push(array2[j].pipeStatuses);
          break;
        }
      }
    }

    const finalResult = resultArray?.flat(Infinity)?.map(item => item?.id);

    return finalResult;
  }

  const changePipeStatuses = (item: string[], key: string) => {
    appendMultipleArray(key, item);
  };

  const onChange = (newValue: string[]) => {
    const resultArray = findValuesById(newValue, dataPipeAll!);
    const resultArr = newValue?.filter(item => item?.split('-')[2] !== undefined)?.map(item => Number(item?.split('-')[2]));

    const finalArr = [...resultArray, ...resultArr]?.map(item => String(item));
    changePipeStatuses(finalArr, 'previousStatuses');
  };
  const onChange2 = (newValue: string[]) => {
    const resultArray = findValuesById(newValue, dataPipeAll!);
    const resultArr = newValue?.filter(item => item?.split('-')[2] !== undefined)?.map(item => Number(item?.split('-')[2]));

    const finalArr = [...resultArray, ...resultArr]?.map(item => String(item));
    changePipeStatuses(finalArr, 'currentStatuses');
  };
  const getData = (key: string) => {
    const paramData = Array.isArray(searchParams[key as keyof typeof searchParams])
      ? searchParams[key as keyof typeof searchParams]
      : [searchParams[key as keyof typeof searchParams]];
    const newArr =
      dataPipeAll &&
      dataPipeAll
        ?.map(item => {
          const values = item?.pipeStatuses?.filter(prev => paramData?.includes(String(prev?.id)));
          return values;
        })
        ?.filter(item => item?.length > 0)
        ?.flat(Infinity)
        ?.map((item: any) => `0-${item?.pipeId}-${item?.id}`);

    return newArr;
  };
  const tProps = {
    treeData,
    value: getData('previousStatuses'),
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: t('home.Oldingi_qiymat'),
    style: {
      width: '100%'
    }
  };
  const tProps1 = {
    treeData,
    value: getData('currentStatuses'),
    onChange: onChange2,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: t('home.Keyingi_qiymat'),
    style: {
      width: '100%'
    }
  };

  return (
    <div className={styles.container}>
      <TreeSelect {...tProps} maxTagCount={'responsive'} />
      <TreeSelect {...tProps1} maxTagCount={'responsive'} />
    </div>
  );
};

export default ChangeStatus;
