import { baseErrorHandler } from '@queries/baseErrorHandler';
import { showMessage } from '@queries/showMessage';
import i18n from '@service/I18nService';
import { downloadFile } from '@utils/helper/downloadFile';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';

import { $api } from '../../../service/RequestService';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { SuccessfullTableData } from '../utils/models/SuccessfullTableData';
import { UnsuccessfullLeadsTableData } from '../utils/models/UnsuccessfullLeadsTableData';

export function useGetVoiceUrl(uuid: string, accountcode: string, callHistoryType: string) {
  return useMutation(async (url: string) => {
    const res = await $api.get(`${endPoints.PHONE_RECORD}?uuid=${uuid}&accountcode=${accountcode}&callHistoryType=${callHistoryType}`, { url });
    const voice = await $api.get(res.data, {
      responseType: 'blob'
    });

    return voice.data;
  });
}
export function useGetPhoneRecord(uuid: string | null, accountcode: string | null, callHistoryType: string | null) {
  return useMutation(async () => {
    const res = await $api.get(`${endPoints.PHONE_RECORD}?uuid=${uuid}&accountcode=${accountcode}&callHistoryType=${callHistoryType}`);

    return res.data;
  });
}

export function useGetCallHistoryExcel() {
  return useMutation(
    async (data: { page: number; size: number; startDate: number; finishDate: number; employeeId?: number; callType?: string }) => {
      const res = await $api.post(`${endPoints.CALL_HISTORY_EXCEL  }?lang=${i18n.language.toUpperCase()}`, data, { responseType: 'blob' });

      return res.data;
    },
    {
      onSuccess: data => {
        // downloadFile(data, `Qo'ng'iroqlar-${dayjs(Date.now()).format('DD-MM-YYYY')}`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export const useGetUnsuccessfullLeads = () => useMutation<
    UnsuccessfullLeadsTableData,
    any,
    {
      page: number;
      size: number;
      startDate: string;
      finishDate: string;
    }
  >(
    [queryKeys.FAILED_TABLE_DATA],
    async data => {
      const res = await $api.post(endPoints.FAILED_TABLE_DATA, data);

      return res.data?.data;
    },
    { retry: false, onError: baseErrorHandler }
  );

export const useGetSuccesfullLeads = () => useMutation<
    SuccessfullTableData,
    any,
    {
      page: number;
      size: number;
      startDate: string;
      finishDate: string;
      houses: Array<number>;
      buildings: Array<number>;
      customFieldId: number | null;
      tag?: boolean | null;
    }
  >(
    [queryKeys.SUCCESS_TABLE_DATA],
    async data => {
      const res = await $api.post(endPoints.SUCCESS_TABLE_DATA, data);

      return res.data?.data;
    },
    { retry: false, onError: baseErrorHandler }
  );
