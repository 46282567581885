import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { setCurrentPage } from '@reducers/ContractSlice';
import { Dropdown, Layout, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowLineDownIcon from '../../assets/icons/ArrowLineDownIcon';
import Download from '../../assets/icons/Download';
import ExcelIcon from '../../assets/icons/ExcelIcon';
import TableSettings from '../../components/filters/TableSettings';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import PaginationCont from '../../components/pagenation/PaginationCont';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useTableConfig } from '../../queries/queries';
import { TableKey } from '../../utils/models/TableKey';

import ExelModal from './components/exel-modal/ExelModal';
import ContractFilter from './components/filter/ContractFilter';
import ContractTable from './components/table/ContractTable';
import { useDownloadContractsSummaryToExcel, useDownloadContractsToExcel } from './services/mutation';
import { useContracts } from './services/queries';

import './contract.module.scss';
import stylesButton from '../../components/filters/tableSettings.module.scss';

const { Content } = Layout;

export interface SearchParamsContract {
  page?: string;
  size?: string;
  statuses?: string[];
  startDate?: string;
  finishDate?: string;
  search?: string;
  flatNumber?: string;
  formal?: string;
  flatRepaired?: boolean | null;
  order?: string[];
  buildings?: string[];
  houses?: string[];
  color?: string[];
  responsibleByIds?: string[];
  currencies?: string[];
  contractNumber?: string;
  unpaid?: boolean | string;
  discounted?: boolean | string;
}

const Contract = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentPage } = useAppSelector(state => state.contractReducer);
  const { searchParams, appendMultipleDifferent, params, appendSingle } = useQueryParam<SearchParamsContract, any>();
  const [tableSize, setTableSize] = useState(10);
  const [visibleDownloadMenu, setVisibleDownloadMenu] = useState(false);
  const [visible, setVisible] = useState(false);

  const { data: tableConfig, isFetching: tableConfigFetch } = useTableConfig(TableKey.contract);
  const { data, isLoading, isFetching } = useContracts(tableSize, searchParams);
  const downloadTableExcel = useDownloadContractsToExcel(tableSize, searchParams);
  const downloadTableExcelWithSummary = useDownloadContractsSummaryToExcel(tableSize, searchParams);

  useEffect(() => {
    if (tableConfig?.size && params['*']?.includes('contract')) {
      appendSingle('page', currentPage);
      setTableSize(tableConfig.size);
    }
  }, [tableConfig?.size]);

  const changePage = (page: number) => {
    appendMultipleDifferent(['page', 'size'], [page, tableSize]);
    dispatch(setCurrentPage(page));
  };

  const downloadExcel = () => {
    downloadTableExcel.mutate();
    setVisibleDownloadMenu(false);
  };

  const downloadExcelWithSum = () => {
    downloadTableExcelWithSummary.mutate();
    setVisibleDownloadMenu(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
    setVisibleDownloadMenu(false);
  };

  const downloadButton = (
    <Dropdown
      visible={visibleDownloadMenu}
      onVisibleChange={visible => {
        setVisibleDownloadMenu(visible);
      }}
      placement="bottomRight"
      overlay={
        <div className={stylesButton.downloadPopover}>
          <div onClick={downloadExcel} className={stylesButton.downloadPopover_item}>
            <div className={stylesButton.downloadPopover_item_element}>
              <ExcelIcon />
              <span>{t('contract.Jadvalni_yuklash')}</span>
            </div>
            <ArrowLineDownIcon />
          </div>
          <div onClick={downloadExcelWithSum} className={stylesButton.downloadPopover_item}>
            <div className={stylesButton.downloadPopover_item_element}>
              <ExcelIcon />
              <span>{t('contract.Shartnoma_tolovlari')}</span>
            </div>
            <ArrowLineDownIcon />
          </div>
          <div onClick={handleOpenModal} className={stylesButton.downloadPopover_item}>
            <div className={stylesButton.downloadPopover_item_element}>
              <ExcelIcon />
              <span className="!mr-3">{t('contract.Sotilgan_uylar_hisoboti')}</span>
            </div>
            <ArrowLineDownIcon />
          </div>
        </div>
      }
      trigger={['click']}
    >
      <span onClick={() => setVisibleDownloadMenu(true)} className={stylesButton.icon}>
        <Download />
      </span>
    </Dropdown>
  );

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className="contract_page">
          <div className="d-flex align-center justify-space-between">
            <ContractFilter />
            <Spin spinning={downloadTableExcel.isLoading || downloadTableExcelWithSummary.isLoading}>
              <TableSettings downloadButton={downloadButton} tableKey={TableKey.contract} />
            </Spin>
          </div>
          <ContractTable data={data?.data || []} isLoading={isLoading} isFetching={isFetching} tableConfig={tableConfig?.config || []} tableConfigFetch={tableConfigFetch} />

          <PaginationCont
            total={data?.totalElements !== undefined ? data.totalElements : -1}
            totalPages={data?.totalPages || 1}
            changePage={changePage}
            currentPage={data?.currentPage ? data.currentPage : 1}
            defaultPageSize={tableSize}
          />
        </div>
      </Content>

      <ExelModal visible={visible} setVisible={setVisible} />
    </>
  );
};

export default Contract;
