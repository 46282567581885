import { useQuery } from 'react-query';
import { baseErrorHandler } from '@queries/baseErrorHandler';
import { $api } from '@service/RequestService';
import { BaseEntity } from '@utils/models/BaseEntity';
import { queryNames } from '@utils/constants/queryNames';
import { companyEndpoints } from '@utils/constants/apis';
import { queryKeys } from '../utils/constants/queryKeys';
import { endPoints } from '../utils/constants/endPoints';
import { GetCompanyPermissions } from '../utils/models/GetCompanyPermissions';
import { ControlRoles } from '@pages/security/security-control-permissions/utils/models/ControlRoles';

export function useCompanyCompact() {
  return useQuery<BaseEntity[]>(
    [queryNames.COMPANY_COMPACT],
    async () => {
      const res = await $api.get(companyEndpoints.COMPACT);
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetCompanyPermissions(id: number) {
  return useQuery<GetCompanyPermissions>(
    [queryKeys.GET_COMPANY_PERMISSIONS, id],
    async () => {
      const res = await $api.get(endPoints.GET_COMPANY_PERMISSIONS + id);
      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useAuthPermissions() {
  return useQuery<ControlRoles[]>(
    [queryKeys.GET_AUTH_PERMISSIONS],
    async () => {
      const res = await $api.get(endPoints.GET_AUTH_PERMISSIONS);
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}
