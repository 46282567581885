import { useQuery } from 'react-query';
import { queryKeys } from '../utils/constants/queryKeys';
import { $api } from '@service/RequestService';
import { endPoints } from '../utils/constants/endPoints';
import { BossMonthlyPaymentsModel } from '../utils/models/BossMonthlyPaymentsModel';
import { HousesModel } from '../utils/models/HousesModel';
import { baseErrorHandler } from '@queries/baseErrorHandler';

export function useGetBossMonthlyPayments(start: string | undefined, finish: string | undefined, houses: number[] | undefined) {
  return useQuery<BossMonthlyPaymentsModel>(
    [queryKeys.BOSS_MONTHLY_PAYMENT, start, finish, houses],
    async () => {
      const res = await $api.get(`${endPoints.BOSS_MONTHLY_PAYMENTS}?startDate=${start}&finishDate=${finish}&houses=${houses?.toString()}`);
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useGetAllHouses() {
  return useQuery<HousesModel[]>(
    [queryKeys.HOUSES],
    async () => {
      const res = await $api.get(endPoints.HOUSES);
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}
