import React, { useEffect } from 'react';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import styles from './clientBalance.module.scss';
import { helper } from '../../utils/helper/helper';
import { PaymentType } from '../../utils/models/PaymentType';
import { useAutoPrintClientBalanceCheck, useDebitCreate, useDownloadClientPaymentToWord } from '../../queries/mutation';
import { t } from 'i18next';
import { ClientBalanceHistoryList } from '../../utils/models/Client';
import { useCurrencyList, usePaymentConfig } from '../../queries/queries';

const { Option } = Select;

interface Props {
  visible: boolean;
  close: () => void;
  type: 'in' | 'out';
  clientId: string;
}

interface Values {
  amount: string;
  type: PaymentType;
  note: string;
  balanceCurrencyId: number;
  currencyId: number;
}

const CreateDebitModal: React.FC<Props> = ({
  // balance,
  visible,
  close,
  type,
  clientId
}) => {
  const [form] = Form.useForm<Values>();
  const createDebit = useDebitCreate(clientId, type, handleClose);
  const { data: currency, isLoading: loadingCurrency } = useCurrencyList();

  const { data, isLoading } = usePaymentConfig();

  const downloadPayment = useDownloadClientPaymentToWord();
  const autoPrintCheck = useAutoPrintClientBalanceCheck();

  useEffect(() => {
    form.setFieldsValue({
      balanceCurrencyId: currency?.find(item => item.basic)?.id,
      currencyId: currency?.find(item => item.basic)?.id
    });
  }, [currency, form]);

  const printAutoCheck = (id: number) => {
    const type: PaymentType = form.getFieldValue('type');
    if (type !== PaymentType.BANK) {
      autoPrintCheck.mutate({
        id
      });
    }
  };

  function handleClose(data?: ClientBalanceHistoryList) {
    if (data) {
      downloadPayment.mutate({
        id: data.id,
        date: data.createdDate
      });
      printAutoCheck(data.id);
    }
    setTimeout(() => {
      form.resetFields();
      form.setFieldsValue({
        balanceCurrencyId: currency?.find(item => item.basic)?.id,
        currencyId: currency?.find(item => item.basic)?.id
      });
      close();
    }, 0);
  }

  const handleSubmit = (val: Values) => {
    createDebit.mutate({
      amount: helper.parseFloatStringToNumber(val.amount),
      type: val.type,
      note: form.getFieldValue('note'),
      currencyId: val.currencyId,
      balanceCurrencyId: val.balanceCurrencyId
    });
  };

  return (
    <Modal
      title={type === 'in' ? t('client_balance.Mablag_kiritish') : t('client_balance.Mablag_chiqarish')}
      className={styles.debitModal}
      visible={visible}
      width={432}
      onCancel={() => handleClose()}
      footer={null}
    >
      <div className={styles.body}>
        <Form
          form={form}
          name="debit_form"
          onFinish={handleSubmit}
          initialValues={{ remember: true }}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          layout="vertical"
          className={styles.createOrderForm}
        >
          <Form.Item
            label={
              <p
                style={{
                  color: 'var(--philippine-gray)',
                  fontSize: '14px'
                }}
              >
                {type === 'out' ? t('create_payment.Qaysi_valyutadan') : t('create_payment.Qaysi_valyutaga')}
              </p>
            }
          >
            <Form.Item noStyle name={'balanceCurrencyId'} rules={[{ required: true, message: '' }]}>
              <Select loading={loadingCurrency} style={{ border: '6px' }} className="customSelect">
                {currency?.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.ccy}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={
              <p
                style={{
                  color: 'var(--philippine-gray)',
                  fontSize: '14px'
                }}
              >
                {t('home.Tolov_summasi_sum')}
              </p>
            }
          >
            <Form.Item
              noStyle
              name={'amount'}
              rules={[
                {
                  required: true,
                  message: ''
                },
                {
                  validator: (rule, value) => {
                    if (value === undefined || value === null) {
                      return Promise.reject(t('client_balance.Summa_kiritilmagan'));
                    }
                    const amount = helper.deleteNotNumbersAndParseFloatNumber(value);
                    // if (amount && amount.number > balance && type === 'out') {
                    //   form.setFieldsValue({
                    //     amount: amount.string,
                    //   })
                    //   return Promise.reject(
                    //     t(
                    //       'client_balance.Summa_xato_kiritilgan_yoki_mijoz_balansidan_katta'
                    //     )
                    //   )
                    // }
                    form.setFieldsValue({
                      amount: amount.string
                    });
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input className="customInput" />
            </Form.Item>
          </Form.Item>
          <Row gutter={[8, 0]}>
            <Col span={12}>
              <Form.Item
                label={
                  <p
                    style={{
                      color: 'var(--philippine-gray)',
                      fontSize: '14px'
                    }}
                  >
                    {t('home.Tulov_turi')}
                  </p>
                }
              >
                <Form.Item noStyle name={'type'} rules={[{ required: true, message: "To'lov turi tanlanmagan" }]}>
                  <Select loading={isLoading} style={{ border: '6px' }} className="customSelect">
                    {data?.types.map((item, index) => (
                      <Option key={index} value={PaymentType[item]}>
                        {t(`home.${PaymentType[item]}`)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <p
                    style={{
                      color: 'var(--philippine-gray)',
                      fontSize: '14px'
                    }}
                  >
                    {t('create_payment.Valyuta')}
                  </p>
                }
              >
                <Form.Item noStyle name={'currencyId'} rules={[{ required: true, message: '' }]}>
                  <Select loading={loadingCurrency} style={{ border: '6px' }} className="customSelect">
                    {currency?.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.ccy}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Form.Item noStyle name={'note'}>
              <Input.TextArea placeholder={t('flat_condition.Izoh_qoldirish')} autoSize={{ minRows: 4, maxRows: 4 }} className="customInput" />
            </Form.Item>
          </Form.Item>
          <Button loading={createDebit.isLoading} htmlType={'submit'} className={styles.body_pay_simple} type={'primary'}>
            {t('client_balance.Tolov_qilish')}
          </Button>
          <Button onClick={() => handleClose()} className={styles.body_close_button} type={'primary'}>
            {t('arrearage.Yopish')}
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateDebitModal;
