import React from 'react';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import MainHeader from '../../components/headers/MainHeader';
import NotificationDev from '../../components/notificationDeveloper/NotificationDev';

const NotificationDeveloper = () => {
  return (
    <>
      {' '}
      <MainHeader>
        <BreadcrumbHeader />
      </MainHeader>
      <NotificationDev />
    </>
  );
};

export default NotificationDeveloper;
