import DiamondsIcon from '@icons/DiamondsIcon';
import ShablonIcon from '@icons/ShablonIcon';
import WebsiteUserIcon from '@icons/WebsiteUserIcon';

import BankIcon from '../../../assets/icons/BankIcon';
import CompaniesIcon from '../../../assets/icons/CompaniesIcon';
import NotificationDeveloperIcon from '../../../assets/icons/NotificationDeveloperIcon';
import PermissionsIcon from '../../../assets/icons/PermissionsIcon';
import { DeveloperPaths } from '../../../routing/developer/developerPaths';
import { rootPaths } from '../../../routing/rootPaths';
import { LocalStorage } from '../../../service/LocalStorage';
import { USER_KEY } from '../../../utils/constants/localStorageKeys';
import { Permissions } from '../../../utils/constants/permissions';
import { User } from '../../../utils/models/User';

const user = LocalStorage.get<User>(USER_KEY);

const fullNavBarLinks = [
  {
    name: 'Kompaniyalar',
    icon: <CompaniesIcon />,
    path: rootPaths.DEVELOPER + DeveloperPaths.INDEX,
    permission: Permissions.PERMISSION_DEVELOPER
  },
  {
    name: 'Huquqlar',
    icon: <PermissionsIcon />,
    path: rootPaths.DEVELOPER + DeveloperPaths.PERMISSIONS,
    permission: Permissions.PERMISSION_DEVELOPER
  },
  {
    name: 'Bildirishnomalar',
    icon: <NotificationDeveloperIcon />,
    path: rootPaths.DEVELOPER + DeveloperPaths.NOTIFICATION,
    permission: Permissions.PERMISSION_DEVELOPER
  },
  {
    name: 'Monetary',
    icon: <BankIcon />,
    path: rootPaths.DEVELOPER + DeveloperPaths.MONETARY,
    permission: Permissions.PERMISSION_DEVELOPER
  },
  {
    name: 'Funksiyalar',
    icon: <DiamondsIcon />,
    path: rootPaths.DEVELOPER + DeveloperPaths.COMPANY_PERMISSION_CONFIG,
    permission: Permissions.PERMISSION_DEVELOPER
  },
  {
    name: 'Shablon',
    icon: <ShablonIcon />,
    path: rootPaths.DEVELOPER + DeveloperPaths.VORONKA_SHABLON,
    permission: Permissions.PERMISSION_DEVELOPER
  },
  {
    name: 'Website user',
    icon: <WebsiteUserIcon />,
    path: rootPaths.DEVELOPER + DeveloperPaths.WEBSITE_USER,
    permission: Permissions.PERMISSION_DEVELOPER
  }
];

const navbarLinks: typeof fullNavBarLinks = [];

if (typeof user === 'object' && user) {
  fullNavBarLinks?.forEach(link => {
    if (link?.permission) {
      if (user?.role?.permissions?.includes(link.permission)) {
        navbarLinks.push(link);
      }
    } else {
      navbarLinks.push(link);
    }
  });
}

export default navbarLinks;
