import React from 'react';
import Pipes from '../../../../components/bossCrm/pipes/Pipes';
import Chart from '../../../../components/bossCrm/chart/Chart';
import Source from '../../../../components/bossCrm/source/Source';
import PriceOneClient from '../../../../components/bossCrm/priceOneClient/priceOneClient/PriceOneClient';
import FailedLead from '../failed-lead/Index';
import DateSelect from '@components/bossCrm/pipes/header/DateSelect';
import Employee from '@components/bossCrm/pipes/header/Employee';

import styles from '../../bossCrm.module.scss';
import Pipe from '@components/bossCrm/pipes/header/Pipe';

const CommonStatistic = () => {
  return (
    <div className={styles.container}>
      <div className={styles.container_top}>
        <DateSelect />
        <Employee />
        <Pipe />
      </div>
      <Pipes />
      <div className={styles.container_body}>
        <div className={styles.container_body_chart}>
          <Chart />
        </div>
        <div className={styles.container_body_source}>
          <Source />
        </div>
        <div className={styles.container_body_price}>
          <PriceOneClient />
        </div>
      </div>
      <div>
        <FailedLead />
      </div>
    </div>
  );
};

export default CommonStatistic;
