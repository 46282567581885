import React, { FC, useMemo } from 'react';
import { CustomFields } from '@components/custom-fields/CustomFields';
import { usePaymentFieldGet } from '@pages/setting/payment-changes/services/queries';
import { getNameTrl } from '@utils/helper/getNameTrl';
import { urlDecode, urlEncode } from '@utils/helper/urlEncodeDecode';
import { Checkbox, Collapse, Drawer, Space, Spin, Switch } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import ColapseIcon from '../../assets/icons/ColapseIcon';
import FIlterColapseIcon from '../../assets/icons/FIlterColapseIcon';
import FilterReloadIcon from '../../assets/icons/FilterReloadIcon';
import { useQueryParam } from '../../hooks/useQueryParams';
import HouseItem from '../../pages/contract/components/table/houseItem/HouseItem';
import { SearchParamsPayment } from '../../pages/payment/Payment';
import { useCurrencyList, useHousesNameAndID } from '../../queries/queries';
import DatePicker from '../../service/datePicker';
import { DATE_FORMAT } from '../../utils/constants/format';
import { DebitType } from '../../utils/models/Client';
import { ContractStatus } from '../../utils/models/ContractStatus';
import { PaymentBase } from '../../utils/models/PaymentBase';
import { PaymentType } from '../../utils/models/PaymentType';

import '@components/filters/filterDrawer.module.scss';

const { RangePicker } = DatePicker;

const { Panel } = Collapse;

interface PaymentFilterDrawerProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const PaymentFilterDriver: FC<PaymentFilterDrawerProps> = ({ visible, setVisible }) => {
  const { appendMultipleDifferent, searchParams, removeAllSearchParams, remove, appendMultiple, removeMultiple, appendSingle } = useQueryParam<SearchParamsPayment, any>();
  const { t } = useTranslation();
  const { isLoading, data: houses } = useHousesNameAndID();
  const { data: currencyList } = useCurrencyList();
  const { data: paymentFields } = usePaymentFieldGet();
  const paymentFieldActives = useMemo(() => paymentFields?.filter(item => item?.show), [paymentFields]);

  const onClose = () => {
    setVisible(false);
  };

  const deleteAllFilters = () => {
    removeAllSearchParams();
  };

  const removeContractId = () => {
    if (searchParams.contractId) {
      removeMultiple(['contractId'], ['']);
    }
  };

  const changeDate = (values: any, formatString: [string, string]) => {
    appendMultipleDifferent(['startDate', 'finishDate'], formatString);
    removeContractId();
  };

  const changePaymentType = (value: PaymentType) => {
    if (Array.isArray(searchParams.types)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      searchParams.types.includes(value) ? remove('types', value) : appendMultiple('types', value);
    } else if (searchParams.types === value) {
      remove('types', value);
    } else {
      appendMultiple('types', value);
    }
    removeContractId();
  };

  const changePaymentBases = (value: PaymentBase) => {
    if (Array.isArray(searchParams.bases)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      searchParams.bases.includes(value) ? remove('bases', value) : appendMultiple('bases', value);
    } else if (searchParams.bases === value) {
      remove('bases', value);
    } else {
      appendMultiple('bases', value);
    }
    removeContractId();
  };

  const changeValyuta = (value: string) => {
    if (Array.isArray(searchParams.currencies)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      searchParams.currencies.includes(value) ? remove('currencies', value) : appendMultiple('currencies', value);
    } else if (searchParams.currencies === value) {
      remove('currencies', value);
    } else {
      appendMultiple('currencies', value);
    }
    removeContractId();
  };

  const changeContractStatus = (value: ContractStatus) => {
    if (Array.isArray(searchParams.contractStatus)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      searchParams.contractStatus.includes(value) ? remove('contractStatus', value) : appendMultiple('contractStatus', value);
    } else if (searchParams.contractStatus === value) {
      remove('contractStatus', value);
    } else {
      appendMultiple('contractStatus', value);
    }
  };

  const changeDebit = (value: DebitType) => {
    if (Array.isArray(searchParams.debits)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      searchParams.debits.includes(value) ? remove('debits', value) : appendMultiple('debits', value);
    } else if (searchParams.debits === value) {
      remove('debits', value);
    } else {
      appendMultiple('debits', value);
    }
  };

  const changeFields = (id: string, value: string) => {
    const { fieldValue } = searchParams;

    if (Array.isArray(fieldValue)) {
      fieldValue.forEach(item => {
        if (urlDecode(item).split(':')[0] === id) {
          remove('fieldValue', item);
        }
      });
    } else if (fieldValue && urlDecode(fieldValue)?.split(':')[0] === id) {
      remove('fieldValue', fieldValue);
    }
    if (value) {
      appendMultiple('fieldValue', urlEncode(`${id}:${value}`));
    }
  };

  const getValueField = (id: string) => {
    const { fieldValue } = searchParams;
    let value = null;

    if (Array.isArray(fieldValue)) {
      fieldValue.forEach(item => {
        if (urlDecode(item).split(':')[0] === String(id)) {
          // eslint-disable-next-line prefer-destructuring
          value = urlDecode(item).split(':')[1];
        }
      });
    } else if (urlDecode(fieldValue || '')?.split(':')[0] === String(id)) {
      value = urlDecode(fieldValue || '')?.split(':')[1];
    }
    return urlDecode(value || '') || null;
  };

  const changeCanceled = (value: boolean) => {
    if (value) {
      appendSingle('canceled', String(value));
    } else {
      remove('canceled', 'false');
    }
  };

  return (
    <Drawer width={265} headerStyle={{ display: 'none' }} placement="left" onClose={onClose} visible={visible} className="filter_drawer">
      <Spin spinning={isLoading}>
        <h1>
          <p>{t('home.Filtr')}</p>
          <span onClick={deleteAllFilters}>
            <FilterReloadIcon />
            {t('home.Tozalash')}
          </span>
        </h1>
        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          expandIconPosition="right"
          className="filter_drawer_colapse"
          expandIcon={({ isActive }) => (
            <span
              style={{
                transform: `${isActive ? 'rotate(0deg)' : 'rotate(90deg)'}`,
                transition: '0.3s'
              }}
            >
              <ColapseIcon />
            </span>
          )}
        >
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Sana')}
              </>
            }
            key="1"
          >
            <RangePicker
              format={DATE_FORMAT}
              value={searchParams.startDate && searchParams.finishDate ? [dayjs(searchParams.startDate, DATE_FORMAT), dayjs(searchParams.finishDate, DATE_FORMAT)] : [null, null]}
              onChange={changeDate}
              className="CustomRangePicker"
              placeholder={[t('changes.contract.kk.oo.yyyy'), t('changes.contract.kk.oo.yyyy')]}
            />
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('premium_modal.Shartnoma_holati')}
              </>
            }
            key="2"
          >
            <Space direction="vertical">
              <Checkbox
                onChange={() => changeContractStatus(ContractStatus.ACTIVE)}
                checked={
                  Array.isArray(searchParams.contractStatus) ? searchParams.contractStatus.includes(ContractStatus.ACTIVE) : searchParams.contractStatus === ContractStatus.ACTIVE
                }
              >
                {t(`home.${ContractStatus.ACTIVE}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changeContractStatus(ContractStatus.CANCELLED)}
                checked={
                  Array.isArray(searchParams.contractStatus)
                    ? searchParams.contractStatus.includes(ContractStatus.CANCELLED)
                    : searchParams.contractStatus === ContractStatus.CANCELLED
                }
              >
                {t(`home.${ContractStatus.CANCELLED}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changeContractStatus(ContractStatus.STARTED)}
                checked={
                  Array.isArray(searchParams.contractStatus) ? searchParams.contractStatus.includes(ContractStatus.STARTED) : searchParams.contractStatus === ContractStatus.STARTED
                }
              >
                {t(`home.${ContractStatus.STARTED}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changeContractStatus(ContractStatus.FINISHED)}
                checked={
                  Array.isArray(searchParams.contractStatus)
                    ? searchParams.contractStatus.includes(ContractStatus.FINISHED)
                    : searchParams.contractStatus === ContractStatus.FINISHED
                }
              >
                {t(`home.${ContractStatus.FINISHED}`)}
              </Checkbox>
            </Space>
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Tulov_turi')}
              </>
            }
            key="3"
          >
            <Space direction="vertical">
              <Checkbox
                onChange={() => changePaymentType(PaymentType.CASH)}
                checked={Array.isArray(searchParams.types) ? searchParams.types.includes(PaymentType.CASH) : searchParams.types === PaymentType.CASH}
              >
                {t(`home.${PaymentType.CASH}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changePaymentType(PaymentType.BANK)}
                checked={Array.isArray(searchParams.types) ? searchParams.types.includes(PaymentType.BANK) : searchParams.types === PaymentType.BANK}
              >
                {t(`home.${PaymentType.BANK}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changePaymentType(PaymentType.CARD)}
                checked={Array.isArray(searchParams.types) ? searchParams.types.includes(PaymentType.CARD) : searchParams.types === PaymentType.CARD}
              >
                {t(`home.${PaymentType.CARD}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changePaymentType(PaymentType.BALANCE)}
                checked={Array.isArray(searchParams.types) ? searchParams.types.includes(PaymentType.BALANCE) : searchParams.types === PaymentType.BALANCE}
              >
                {t(`home.${PaymentType.BALANCE}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changePaymentType(PaymentType.TRANSFER)}
                checked={Array.isArray(searchParams.types) ? searchParams.types.includes(PaymentType.TRANSFER) : searchParams.types === PaymentType.TRANSFER}
              >
                {t(`home.${PaymentType.TRANSFER}`)}
              </Checkbox>

              <Checkbox
                onChange={() => changePaymentType(PaymentType.PAYME)}
                checked={Array.isArray(searchParams.types) ? searchParams.types.includes(PaymentType.PAYME) : searchParams.types === PaymentType.PAYME}
              >
                {t(`home.${PaymentType.PAYME}`)}
              </Checkbox>

              <Checkbox
                onChange={() => changePaymentType(PaymentType.MY_UZCARD)}
                checked={Array.isArray(searchParams.types) ? searchParams.types.includes(PaymentType.MY_UZCARD) : searchParams.types === PaymentType.MY_UZCARD}
              >
                {t(`home.${PaymentType.MY_UZCARD}`)}
              </Checkbox>

              <Checkbox
                onChange={() => changePaymentType(PaymentType.UZUM)}
                checked={Array.isArray(searchParams.types) ? searchParams.types.includes(PaymentType.UZUM) : searchParams.types === PaymentType.UZUM}
              >
                {t(`home.${PaymentType.UZUM}`)}
              </Checkbox>

              <Checkbox
                onChange={() => changePaymentType(PaymentType.OTHERS)}
                checked={Array.isArray(searchParams.types) ? searchParams.types.includes(PaymentType.OTHERS) : searchParams.types === PaymentType.OTHERS}
              >
                {t(`home.${PaymentType.OTHERS}`)}
              </Checkbox>
            </Space>
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Tulov_maqsadi')}
              </>
            }
            key="4"
          >
            <Space direction="vertical">
              <Checkbox
                onChange={() => changePaymentBases(PaymentBase.CONTRACT)}
                checked={Array.isArray(searchParams.bases) ? searchParams.bases.includes(PaymentBase.CONTRACT) : searchParams.bases === PaymentBase.CONTRACT}
              >
                {t(`home.PAYMENT_${PaymentBase.CONTRACT}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changePaymentBases(PaymentBase.SURCHARGE)}
                checked={Array.isArray(searchParams.bases) ? searchParams.bases.includes(PaymentBase.SURCHARGE) : searchParams.bases === PaymentBase.SURCHARGE}
              >
                {t(`home.PAYMENT_${PaymentBase.SURCHARGE}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changePaymentBases(PaymentBase.CLIENT)}
                checked={Array.isArray(searchParams.bases) ? searchParams.bases.includes(PaymentBase.CLIENT) : searchParams.bases === PaymentBase.CLIENT}
              >
                {t(`home.PAYMENT_${PaymentBase.CLIENT}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changePaymentBases(PaymentBase.BOOKING)}
                checked={Array.isArray(searchParams.bases) ? searchParams.bases.includes(PaymentBase.BOOKING) : searchParams.bases === PaymentBase.BOOKING}
              >
                {t(`home.PAYMENT_${PaymentBase.BOOKING}`)}
              </Checkbox>
            </Space>
          </Panel>
          <Panel
            className="house_item"
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Turar_joy_majmuasi')}
              </>
            }
            key="5"
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              {houses?.map((house, index) => <HouseItem key={index} id={house.id || -1} name={house.name} />)}
            </Space>
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {`${t('home.INCOME')} ${t('home.OUTCOME')}`}
              </>
            }
            key="6"
          >
            <Space direction="vertical">
              <Checkbox
                onChange={() => changeDebit(DebitType.INCOME)}
                checked={Array.isArray(searchParams.debits) ? searchParams.debits.includes(DebitType.INCOME) : searchParams.debits === DebitType.INCOME}
              >
                {t(`home.${DebitType.INCOME}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changeDebit(DebitType.OUTCOME)}
                checked={Array.isArray(searchParams.debits) ? searchParams.debits.includes(DebitType.OUTCOME) : searchParams.debits === DebitType.OUTCOME}
              >
                {t(`home.${DebitType.OUTCOME}`)}
              </Checkbox>
            </Space>
          </Panel>
          {currencyList && currencyList?.length > 1 && (
            <Panel
              header={
                <>
                  <FIlterColapseIcon />
                  {t('create_payment.Valyuta')}
                </>
              }
              key="7"
            >
              <Space direction="vertical">
                {currencyList?.map(item => (
                  <Checkbox
                    key={item.id}
                    onChange={() => changeValyuta(String(item.id))}
                    checked={Array.isArray(searchParams.currencies) ? searchParams.currencies.includes(String(item.id)) : searchParams.currencies === String(item.id)}
                  >
                    {item.ccy}
                  </Checkbox>
                ))}
              </Space>
            </Panel>
          )}
        </Collapse>
        <div className="fieldContainer">
          <hr />
          <label>
            {t('payment.Bekor_qilingan_tolovlar')} &nbsp;
            <Switch checked={Boolean(searchParams?.canceled)} onChange={changeCanceled} />
          </label>
          <hr />
          {paymentFieldActives?.map(item => (
            // eslint-disable-next-line react/jsx-key
            <label>
              {getNameTrl(item)}
              {CustomFields[item?.type]!({
                hasValue: true,
                options: item?.options,
                value: getValueField(String(item?.id)),
                onChange(value) {
                  changeFields(String(item?.id), value);
                }
              })}
            </label>
          ))}
        </div>
      </Spin>
    </Drawer>
  );
};

export default PaymentFilterDriver;
