/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from "react"
import PaginationCont from "@components/pagenation/PaginationCont";
import TjmFilterV3 from "@components/tjm-filter/tjm-filter-soft/TJMFilterSoft";
import { useQueryParam } from "@hooks/useQueryParams";
import SearchIcon from "@icons/SearchIcon";
import { cx } from "@utils/cx";
import { Input, message, Table } from "antd";
import { Button, Modal, Select } from "global"
import { t } from "i18next";

import { useGetAllMonthlyArrears } from "./services/queries";
import { MonthlyArrearsMonthSelectType } from "./utils/models/MonthlyArrearsMonthSelectType";
import { MonthleArrearsTableType, SearchParamsMonthlyArrears } from "./utils/models/MonthlyArrearsType";

import styles from './monthleArrearsModal.module.scss';


interface TProps {
    isModalOpen: boolean;
    handleCancel: () => void;
}

const { Option } = Select;

const MonthleArrearsModal: React.FC<TProps> = ({ isModalOpen, handleCancel }) => {
    const [month, setMonth] = useState<{ startMonth?: number; endMonth?: number }>({ startMonth: undefined, endMonth: undefined });
    const [tempMonth, setTempMonth] = useState<{ startMonth?: number; endMonth?: number }>({ startMonth: undefined, endMonth: undefined });
    const [houses, setHouses] = useState<number[] | any>([]);
    const [buildings, setBuildings] = useState<number[] | any>([]);
    const [specialModal, setSpecialModal] = useState(false);
    const [text, setText] = useState('');
    const { appendMultipleDifferent, searchParams } = useQueryParam<SearchParamsMonthlyArrears, any>();
    const { data: allMonthlyArrears, isLoading, isFetching } = useGetAllMonthlyArrears(searchParams, month.startMonth, month.endMonth, houses, buildings, text);

    const handleChange = (value: string) => {
        setText(value);
    };

    const changePage = (page: number) => {
        appendMultipleDifferent(['page', 'size'], [page, 10]);
    };



    const handleSelect = (value: string) => {
        switch (value) {
            case MonthlyArrearsMonthSelectType.ONE_MONTH:
                setOneMonth();
                break;
            case MonthlyArrearsMonthSelectType.ONE_THREE_MONTHS:
                setOneThreeMonths();
                break;
            case MonthlyArrearsMonthSelectType.THREE_SIX_MONTHS:
                setThreeSixMonths();
                break;
            case MonthlyArrearsMonthSelectType.SIX_TWELVE_MONTHS:
                setSixTwelveMonths();
                break;
            case MonthlyArrearsMonthSelectType.TWELVE_PLUS:
                setTwelvePlusMonths();
                break;
            default:
                setNow();
                break;
        }
    };

    const setNow = () => {
        setMonth({
            startMonth: undefined,
            endMonth: undefined
        });
    };

    const setOneMonth = () => {
        setMonth({
            startMonth: 1,
            endMonth: 1
        });
    };

    const setOneThreeMonths = () => {
        setMonth({
            startMonth: 1,
            endMonth: 3
        });
    };

    const setThreeSixMonths = () => {
        setMonth({
            startMonth: 3,
            endMonth: 6
        });
    };

    const setSixTwelveMonths = () => {
        setMonth({
            startMonth: 6,
            endMonth: 12
        });
    };

    const setTwelvePlusMonths = () => {
        setMonth({
            startMonth: 12,
            endMonth: 20
        });
    };

    const setSpecial = () => {
        handleSpecialModal(true);
    };


    useEffect(() => {
        if (isModalOpen) {
            setTempMonth(month);
        }
    }, [isModalOpen, month]);


    const handleSpecialModal = (visible: boolean) => {
        setSpecialModal(visible);
    };


    const handleInputChange = (field: string, value: string) => {
        const numericValue = Number(value);

        setTempMonth((prevState: any) => {
            const updatedState = { ...prevState };

            if (field === 'startMonth') {
                updatedState.startMonth = numericValue > 0 ? numericValue : undefined;

                if (updatedState.startMonth > updatedState.endMonth) {
                    updatedState.endMonth = numericValue > 0 ? numericValue : undefined;
                }
            } else if (field === 'endMonth') {
                updatedState.endMonth = numericValue > 0 ? numericValue : undefined;
            }

            return updatedState;
        });
    };


    const handleSubmit = () => {
        if (tempMonth.startMonth !== undefined && tempMonth.endMonth !== undefined) {
            setMonth({
                startMonth: tempMonth.startMonth,
                endMonth: tempMonth.endMonth,
            });

            setSpecialModal(false);
            appendMultipleDifferent(
                {
                    ...searchParams,
                    // @ts-expect-error
                    startMonth: tempMonth.startMonth,
                    endMonth: tempMonth.endMonth,
                },
                undefined
            );
        } else {
            message.warn(t('home.Boshlanish_oyi_va_tugaydigan_oyni_kiriting!'));
        }
    };

    const defaultFooter = () => (
        <div className="flex items-center justify-between">
            <p className="text-[16px] font-medium">{t('home.Jami_qarzdorlik')}:</p>
            <p className="font-semibold mr-[18px]">{allMonthlyArrears?.sum?.toLocaleString('ru')} {allMonthlyArrears?.ccy}</p>
        </div>
    );

    const columns = [
        {
            title: t('home.Shartnoma_raqami'),
            dataIndex: 'contractNumber',
            width: 150,
            align: 'center'
        },
        {
            title: t('home.TJM_nomi'),
            dataIndex: 'houseName',
            width: 110
        },
        {
            title: t('home.Mijoz_ismi'),
            dataIndex: 'clientFullName',
            width: 180
        },
        {
            title: t('home.Oxirgi_tolov_sanasi'),
            dataIndex: 'lastPaidDate',
            width: 120,
            align: 'center',
        },
        {
            title: t('home.Mijoz_raqami'),
            width: 200,
            align: 'center',
            render: (record: MonthleArrearsTableType) => {
                const firstPhone = record?.clientPhones?.split(',')[0]?.trim() || '';

                return (
                    <p>
                        {firstPhone}
                    </p>
                )
            }
        },
        {
            title: t('home.Summasi'),
            width: 120,
            align: 'center',
            render: (record: MonthleArrearsTableType) => (
                <p>
                    {record?.debt.toLocaleString('ru')} {record?.contractCcy}
                </p>
            )
        }
    ];

    return (
        <Modal
            title={
                <h1 className="text-[#101828] text-[18px] font-semibold leading-7">{t('boss_home.Oylik_qarzdorlik')}</h1>
            }
            visible={isModalOpen}
            onCancel={handleCancel}
            footer={false}
            width={1600}
            wrapClassName="mt-[-80px]"
        >
            <div className="flex items-center justify-between mb-[20px]">
                <Input
                    placeholder={t('home.Qidirish')}
                    className="w-[320px] rounded-[2px]"
                    prefix={<SearchIcon />}
                    value={text}
                    onChange={(e) => handleChange(e.target.value)}
                />

                <div className="flex items-center gap-[10px]">
                    <Select
                        onChange={handleSelect}
                        className={cx('!w-[200px]', styles.selectStyle)}
                        placeholder={t('home.Tanlang')}
                        allowClear
                    >
                        <Option value={MonthlyArrearsMonthSelectType.ONE_MONTH}>{t('home.1_oy')}</Option>
                        <Option value={MonthlyArrearsMonthSelectType.ONE_THREE_MONTHS}>{t('home.1_3_oy')}</Option>
                        <Option value={MonthlyArrearsMonthSelectType.THREE_SIX_MONTHS}>{t('home.3_6_oy')}</Option>
                        <Option value={MonthlyArrearsMonthSelectType.SIX_TWELVE_MONTHS}>{t('home.6_12_oy')}</Option>
                        <Option value={MonthlyArrearsMonthSelectType.TWELVE_PLUS}>{t('home.12+_oy')}</Option>
                        {/* <Option value={MonthlyArrearsMonthSelectType.SPECIAL}>{t('boss_complex.Maxsus')}</Option> */}
                        <Option
                            value={MonthlyArrearsMonthSelectType.SPECIAL}
                            onMouseDown={() => setSpecial()}
                        >
                            {t('boss_complex.Maxsus')}
                        </Option>
                    </Select>

                    <div className="w-[200px]">
                        <TjmFilterV3
                            onHousesChange={setHouses}
                            onBuildingsChange={setBuildings}
                        />
                    </div>
                </div >
            </div >

            <Table
                // @ts-expect-error
                columns={columns}
                dataSource={allMonthlyArrears?.debts?.data}
                pagination={false}
                scroll={{ y: `calc(100vh - 350px)` }}
                loading={isLoading || isFetching}
                rowClassName='!cursor-text'
                className="custom_table_roboto !cursor-text"
                footer={defaultFooter}
            />
            <PaginationCont
                currentPage={allMonthlyArrears?.debts?.currentPage ? allMonthlyArrears?.debts?.currentPage : 1}
                defaultPageSize={10}
                total={allMonthlyArrears?.debts?.totalElements !== undefined ? allMonthlyArrears?.debts?.totalElements : -1}
                totalPages={allMonthlyArrears?.debts?.totalPages || 1}
                changePage={changePage}
            />

            <Modal
                title={t('boss_complex.Maxsus_sana_tanlash')}
                visible={specialModal}
                onCancel={() => setSpecialModal(false)}
                footer={false}
            >
                <p className='text-[14px] mb-3'>{t('home.Boshlanish_va_tugash_oylarini_kiriting_(raqamda)')}:</p>
                <div className='flex items-center gap-3'>
                    {/* startMonth */}
                    <Input
                        title={t('home.Boshlanadigan_oy')}
                        type='number'
                        value={tempMonth.startMonth ?? ''}
                        onChange={e => handleInputChange('startMonth', e.target.value)}
                        className={tempMonth.startMonth !== undefined && tempMonth.startMonth <= 0 ? 'border-red-500' : ''}
                        required
                    />

                    {/* endMonth */}
                    <Input
                        title={t('home.Tugaydigan_oy')}
                        type='number'
                        value={tempMonth.endMonth ?? ''}
                        onChange={e => handleInputChange('endMonth', e.target.value)}
                        className={tempMonth.endMonth !== undefined && tempMonth.endMonth <= 0 ? 'border-red-500' : ''}
                        required
                    />
                </div>
                <div>
                    <Button
                        className='rounded-[3px] mt-6 flex items-center ml-auto'
                        type='primary'
                        size="large"
                        onClick={handleSubmit}
                        disabled={
                            tempMonth.startMonth === undefined ||
                            tempMonth.endMonth === undefined ||
                            tempMonth.startMonth <= 0 ||
                            tempMonth.endMonth <= 0
                        }
                    >
                        {t('home.Yuborish')}
                    </Button>
                </div>
            </Modal>
        </Modal >
    )
}

export default MonthleArrearsModal;