import { useMutation, useQueryClient } from 'react-query';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { baseErrorHandler } from '@queries/baseErrorHandler';
import { $api } from '@service/RequestService';
import { showMessage } from '@queries/showMessage';

export function usePostSMSBot() {
  const qc = useQueryClient();
  return useMutation(
    async (data: { originator: string; leadTaskTypeId: number; times: string[]; text: string; active: boolean }) => {
      const res = await $api.post(endPoints.POST_SMSBOT, data);
      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryKeys.GET_SMSBOT]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteSMSBot() {
  const qc = useQueryClient();
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(endPoints.DELETE_MESSAGE + '/' + id);
      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.GET_MESSAGE]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useGetChangeStatus() {
  const qc = useQueryClient();
  return useMutation(
    async (active: boolean) => {
      const res = await $api.get(endPoints.CHANGE_STATUS + '/' + active);
      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.GET_SMSBOT]);
      },
      onError: baseErrorHandler
    }
  );
}
