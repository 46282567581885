import React, { useRef } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar as AvatarAnt, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../hooks/reduxHooks';
import { useEditProfile, useUploadFile } from '../../queries/mutation';
import { useGetImageByImageId } from '../../queries/queries';
import { BASE_URL } from '../../service/RequestService';
import { addAvatar, clearAvatar } from '../../store/reducers/UserSlice';
import { FileObject } from '../../utils/models/File';
import { Profile } from '../../utils/models/Profile';

import styles from './userInformation.module.scss';

interface Props {
  profile?: Profile;
}

const Avatar: React.FC<Props> = ({ profile }) => {
  const inputUpload = useRef();

  const editProfile = useEditProfile();
  const { data, isLoading, isFetching } = useGetImageByImageId(profile?.avatarId || 0);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  // get uploaded file
  function getFile(data: FileObject[]) {
    if (data.length > 0 && profile) {
      dispatch(addAvatar(data[0].thumbAbsolutePath as string));
      editProfile.mutate({
        ...profile,
        avatarId: data[0].id
      });
    }
  }

  const uploadFile = useUploadFile(getFile);

  // handle remove avatar from local storage
  const handleRemoveAvatar = () => {
    dispatch(clearAvatar());
  };

  // for handle upload
  const handleUpload = () => {
    // @ts-ignore
    inputUpload.current.click();
  };

  // upload image
  const handleUploadInput = (e: any) => {
    if (e.target?.files[0] && e.target.files[0].size <= 10000000) {
      const file = e.target.files[0];
      const formData = new FormData();

      formData.append('files', file);
      uploadFile.mutate(formData);
    }
  };

  // delete avatar
  const handleDeleteAvatar = () => {
    if (profile && profile.avatarId) {
      editProfile.mutate({
        ...profile,
        avatarId: null
      });
      handleRemoveAvatar();
    }
  };

  return (
    <Spin spinning={isLoading || isFetching || editProfile.isLoading || uploadFile.isLoading}>
      <AvatarAnt
        src={data?.fileObject.absolutePath && `${BASE_URL}/${data?.fileObject.thumbAbsolutePath || data?.fileObject.absolutePath}`}
        className={styles.container_avatar}
        icon={<UserOutlined />}
      />
      <div className={styles.container_avatar_buttons}>
        <span onClick={handleUpload} className={styles.container_avatar_buttons_upload}>
          {t('profile.Yangilash')}
        </span>
        <span onClick={handleDeleteAvatar} className={styles.container_avatar_buttons_delete}>
          {t('profile.Ochirish')}
        </span>
      </div>
      <input onChange={handleUploadInput} accept={'image/*'} ref={inputUpload as any} type="file" className="d-none" />
    </Spin>
  );
};

export default Avatar;
