import React, { FC, useState } from 'react';
import { Input } from 'antd';
import DostsSixVertical from '../../../../../assets/icons/DotsSixVertical';
import TrushSquareIcon from '../../../../../assets/icons/TrushSquareIcon';
import { ReasonsRefusal } from '../../../../../utils/models/ReasonsRefusal';
import styles from './reasonsRefusal.module.scss';
import { LoadingOutlined } from '@ant-design/icons';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useReasonsForRefusalCreate, useReasonsForRefusalDelete } from '../../../services/mutation';

interface IProps extends ReasonsRefusal {
  dragHandleProps?: DraggableProvidedDragHandleProps | undefined;
  setChangeAllText: React.Dispatch<React.SetStateAction<boolean>>;
  changeAllText: boolean;
}

const ReasonsRefusalCard: FC<IProps> = props => {
  const [value, setValue] = useState(props.value);
  const reasonsRefusalDelete = useReasonsForRefusalDelete(onSuccessDelete);
  const createReasonsForRefusal = useReasonsForRefusalCreate(onSucces);

  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (e.target.value !== props.value) {
      if (!props.changeAllText) {
        props.setChangeAllText(true);
      }
    } else {
      if (props.changeAllText) {
        props.setChangeAllText(false);
      }
    }
  };

  const handleDelete = () => {
    reasonsRefusalDelete.mutate(props.id!);
  };

  const changeEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  const handleSave = () => {
    if (value) {
      createReasonsForRefusal.mutate([
        {
          id: props.id,
          orders: props.orders,
          value: value
        }
      ]);
    } else {
      handleDelete();
    }
  };

  function onSucces() {
    props.setChangeAllText(false);
  }

  function onSuccessDelete() {
    if (value !== props.value) {
      props.setChangeAllText(false);
    }
  }

  return (
    <div className={styles.container_reasons_card}>
      <span {...props.dragHandleProps} style={{ cursor: 'grab' }}>
        <DostsSixVertical />
      </span>

      <Input
        defaultValue={value}
        className="customInput"
        onChange={e => changeValue(e)}
        onKeyDown={e => changeEnter(e)}
        suffix={createReasonsForRefusal.isLoading ? <LoadingOutlined /> : <span></span>}
      />
      {!reasonsRefusalDelete.isLoading ? (
        <span onClick={handleDelete}>
          <TrushSquareIcon />
        </span>
      ) : (
        <span>
          <LoadingOutlined />
        </span>
      )}
    </div>
  );
};

export default ReasonsRefusalCard;
