import ThreeDots from '@pages/bossCrm/components/conversion/assets/icons/ThreeDots';
import { ConversionContext } from '@pages/bossCrm/hooks/ConversionContext';
import { useGetUnsuccessfullLeads } from '@pages/bossCrm/services/mutate';
import { SecondPieChartData } from '@pages/bossCrm/utils/models/SecondPieChartData';
import { UnsuccessfullLeadsTableData } from '@pages/bossCrm/utils/models/UnsuccessfullLeadsTableData';
import { colors } from '@utils/constants/showRoomColors';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UnsuccessfulLeads from '../../../../modals/unsuccessful-leads/UnsuccessfulLeads';
import MoreBtn from '../more-btn/MoreBtn';
import OtherValues from '../other-values/OtherValues';
import styles from './leftStats.module.scss';

type Props = {
  pieChartData: SecondPieChartData;
};
const LeftStats: React.FC<Props> = ({ pieChartData }) => {
  const { t } = useTranslation();
  const { setContext, startDate, endDate, page } = useContext(ConversionContext);
  const firstTableDataMutation = useGetUnsuccessfullLeads();
  const [visible, setVisible] = useState(false);
  const [isHaveMore, setIsHaveMore] = useState<boolean>(false);
  const onCancel = () => {
    setVisible(false);
    setContext({ name: '' });
  };
  const others =
    pieChartData?.leads!?.length > 5
      ? pieChartData?.leads?.slice(5, pieChartData?.leads?.length).map(el => ({
          name: el?.value,
          value: el?.count,
          color: colors[0]
        }))
      : [];

  return (
    <div className={styles.s_row_stats_container}>
      <div
        className={styles.title_container}
        onClick={() => {
          setVisible(true);
          setContext({ name: 'first', isTableDataLoading: true });
          firstTableDataMutation
            ?.mutateAsync({
              startDate: startDate!,
              finishDate: endDate!,
              page: page!,
              size: 10
            })
            .then((data: UnsuccessfullLeadsTableData) => setContext({ firstTableData: data, isTableDataLoading: false }));
        }}
      >
        {t('boss_crm_conversion.Muvaffaqiyatsiz_Lidlar_sabab')}
        <ThreeDots />
      </div>
      <div
        className={styles.all_legends_container}
        style={{
          height: isHaveMore ? '320px' : ''
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
          }}
        >
          {pieChartData?.leads!?.length > 0 &&
            pieChartData
              ?.leads!?.slice(0, 5)
              ?.map((el, i) => ({
                name: el?.value ? el?.value : t('boss_crm_conversion.Tagsiz'),
                value: el.count,
                color: colors[i]
              }))
              ?.map(item => (
                <div className={styles.s_row_legends_container} key={item.color}>
                  <div className={styles.legends_item}>
                    <span className={styles.legends_item_circle} style={{ border: `3.48px solid ${item.color}` }} />
                    {item.name}
                  </div>
                  <span className={styles.legends_item_value}>{item.value}</span>
                </div>
              ))}
        </div>
        <OtherValues data={others} isHaveMore={isHaveMore} />
      </div>
      {others!?.length > 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <MoreBtn isHaveMore={isHaveMore} setIsHaveMore={setIsHaveMore} />
        </div>
      )}
      {visible && <UnsuccessfulLeads visible={visible} onCancel={onCancel} />}
    </div>
  );
};

export default LeftStats;
