/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import CancelBronGarbage from '../../assets/icons/CancelBronGarbage';
import ClientsIcon from '../../assets/icons/ClientsIcon';
import EditIcon from '../../assets/icons/EditIcon';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { editAndCreateModal } from '../../store/reducers/CompaniesSlice';
import { urlDecode } from '../../utils/helper/urlEncodeDecode';
import { CompaniesList, CompaniesStatus } from '../../utils/models/Companies';

import styles from '../../pages/companies/companies.module.scss';

interface TableContractProps {
  data: CompaniesList[];
  isLoading: boolean;
  isFetching: boolean;
}

const CompaniesTable: React.FC<TableContractProps> = ({ data, isLoading, isFetching }) => {
  const { push, location } = useQueryParam();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const openEditModal = (id: number) => {
    dispatch(editAndCreateModal({ id, open: true }));
  };

  const handleDelete = (id: number) => {
    // console.log(id);
  };

  const goToEmployees = (id: number, name: string) => {
    push(`${location.pathname}/${id}/${urlDecode(name)}`);
  };

  const initialColumn: ColumnsType<CompaniesList> | undefined = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '5%'
    },
    {
      title: t('home.Kompaniya_brendi'),
      dataIndex: 'brand'
    },
    {
      title: t('home.Kompaniya_nomi'),
      dataIndex: 'name'
    },
    {
      title: t('home.Kompaniya_rahbari'),
      dataIndex: 'director'
    },
    {
      title: t('integration.Telefon_raqam'),
      dataIndex: 'phone'
    },
    {
      title: t('home.Status'),
      dataIndex: 'status',
      render: (item: CompaniesStatus) => <span className={item === CompaniesStatus.ACTIVE ? 'table_client_status_active' : 'table_status_canceled'}>{t(`home.${item}`)}</span>
    },
    {
      title: t('security.Songi_faollik'),
      render: (props: CompaniesList) => {
        const { lastContractDay, lastPaymentDay } = props;
        const day = Math.min(lastContractDay, lastPaymentDay);

        if (day >= 0 && day <= 2) {
          const dayObj: Record<number, string> = {
            0: t('boss_complex.Bugun'),
            1: t('boss_complex.Kecha')
          };

          return <span style={{ color: '#00A389' }}>{dayObj[day] || ` ${day} ${t('home.kun_avval')}`}</span>;
        }
        if (day >= 3 && day <= 10) {
          return <span style={{ color: '#FFB400' }}>{` ${day} ${t('home.kun_avval')}`}</span>;
        }
        return <span style={{ color: '#E53535' }}>{` ${day} ${t('home.kun_avval')}`}</span>;
      }
    },
    {
      // title: t('home.Litsenziya'),
      title: 'Litsenziya',
      dataIndex: 'expiryDay',
      render(day: number | null) {
        if (day === null) {
          return '';
        }
        if (day >= 10) {
          return <span style={{ color: '#00A389' }}>{`${day} ${t('boss_crm.kun')}`}</span>;
        }
        if (day < 10 && day >= 3) {
          return <span style={{ color: '#FFB400' }}>{`${day} ${t('boss_crm.kun')}`}</span>;
        }
        return <span style={{ color: '#E53535' }}>{`${day} ${t('boss_crm.kun')}`}</span>;
      }
    },
    {
      title: '',
      render: (company: CompaniesList) => (
        <div className="d-flex align-center justify-center">
          <span onClick={() => openEditModal(company.id)} className={styles.table_icons}>
            <EditIcon />
          </span>
          <Popconfirm
            title={t('home.Kompaniyani_ochirishni_tasdiqlang_!')}
            onConfirm={() => handleDelete(company.id)}
            okText={t('arrearage.Tasdiqlash')}
            cancelText={t('crm.Bekor_qilish')}
          >
            <span className={styles.table_icons}>
              <CancelBronGarbage />
            </span>
          </Popconfirm>
          <span onClick={() => goToEmployees(company.id, company.name)} className={styles.table_icons}>
            <ClientsIcon />
          </span>
        </div>
      )
    }
  ];

  return (
    <>
      <Table
        className="custom_table custom_table_size_developer table_cursor_default"
        columns={initialColumn}
        dataSource={data}
        scroll={{ y: `calc(100vh - 310px)` }}
        loading={isLoading || isFetching}
        pagination={false}
      />
    </>
  );
};

export default CompaniesTable;
