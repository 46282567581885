/* eslint-disable default-case */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import TrushSquareIcon from '../../../../../../../assets/icons/TrushSquareIcon';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/reduxHooks';
import { useCrmFields } from '../../../../../../../queries/queries';
import { clearAll, setErrorList } from '../../../../../../../store/reducers/TriggerSlice';
import { ErrorList, FieldsCondition, TriggerConditionErrorType, TriggerConditionType, TYPE_TRIGGER } from '../../../../../../../utils/models/Trigger';
import { useCreateEditTrigger, useCreateEditTriggerManager, useCreateEditTriggerPipe, useDeleteTrigger } from '../../../../../services/mutation';

import styles from '../triggers.module.scss';

const Buttons = () => {
  const { data } = useCrmFields();

  const { t } = useTranslation();

  const trigger = useAppSelector(state => state.triggerSlice);
  const dispatch = useAppDispatch();

  const createOrEditTrigger = useCreateEditTrigger(trigger.pipeStatusId, handleSuccess);
  const createOrEditTriggerPipe = useCreateEditTriggerPipe(trigger.pipeStatusId, handleSuccess);

  const createOrEditTriggerManager = useCreateEditTriggerManager(trigger.pipeStatusId, handleSuccess);

  const deleteTrigger = useDeleteTrigger(trigger.pipeStatusId, trigger.id as number, handleSuccess);

  // handle delete trigger
  const handleDeleteTrigger = () => {
    deleteTrigger.mutate();
  };

  // handleSuccess
  function handleSuccess() {
    handleCancel();
  }

  // handle cancel
  const handleCancel = () => {
    dispatch(clearAll());
  };

  // save task trigger
  const saveTaskTrigger = () => {
    const tags = trigger.tag ? { ...trigger.tag } : undefined;
    const errorList: ErrorList[] = [];

    if (tags && tags?.tags?.length === 0) {
      errorList.push({
        type: TriggerConditionErrorType.TAG
      });
    }
    const employee = trigger.employee ? { ...trigger.employee } : undefined;

    if (employee && employee?.employees?.length === 0) {
      errorList.push({
        type: TriggerConditionErrorType.EMPLOYEE
      });
    }
    const balance = trigger.balance ? { ...trigger.balance } : undefined;

    if (balance && (balance.low === null || balance.high === null)) {
      errorList.push({
        type: TriggerConditionErrorType.BUDGET
      });
    }
    const { fields } = trigger;

    if (fields) {
      fields.forEach(field => {
        if (!field.logic && field.values.length === 0) {
          const findField = data?.find(item => item.id === field.fieldId);

          errorList.push({
            type: TriggerConditionErrorType.FIELD,
            fieldId: findField?.id || undefined
          });
        }
      });
    }

    if (!trigger.taskTypeId) {
      errorList.push({
        type: TriggerConditionErrorType.TASK
      });
    }

    if (errorList.length > 0) {
      dispatch(setErrorList(errorList));
      return;
    }
    dispatch(setErrorList([]));


    const newFields: FieldsCondition[] = [];

    if (trigger.orderTriggerCondition) {
      trigger.orderTriggerCondition.forEach(order => {
        switch (order.type) {
          case TriggerConditionType.TAG:
            if (tags) {
              tags.order = order.order;
            }
            break;
          case TriggerConditionType.EMPLOYEE:
            if (employee) {
              employee.order = order.order;
            }
            break;
          case TriggerConditionType.BUDGET:
            if (balance) {
              balance.order = order.order;
            }
            break;
          case TriggerConditionType.FIELD:
            fields?.forEach(field => {
              if (field.fieldId === order.fieldId) {
                newFields.push({
                  ...field,
                  order: order.order
                });
              }
            });
            break;
        }
      });
    }

    const requestBody: any = {
      id: trigger.id,
      fields: newFields.length > 0 ? newFields : undefined,
      employee,
      tag: tags,
      order: trigger.order,
      taskTypeId: Number(trigger.taskTypeId),
      pipeStatusId: trigger.pipeStatusId,
      deadLineAfter: trigger.deadLineAfter,
      deadLineAfterStatus: trigger.deadLineAfterStatus,
      execAfter: trigger.execAfter,
      execAfterStatus: trigger.execAfterStatus,
      execStatus: trigger.execStatus,
      assignById: trigger.assignById,
      text: trigger.note
    };

    if (balance && (balance.low !== 0 || balance.high !== 0 || balance.lowString !== "" || balance.highString !== "")) {
      requestBody.balance = balance;
    } else {
      requestBody.balance = null;
    }

    createOrEditTrigger.mutate({
      all: trigger.useAll,
      body: requestBody
    });
  };

  // save pipe trigger
  const savePipeTrigger = () => {
    const tags = trigger.tag ? { ...trigger.tag } : undefined;
    const errorList: ErrorList[] = [];

    if (tags && tags?.tags?.length === 0) {
      errorList.push({
        type: TriggerConditionErrorType.TAG
      });
    }
    const employee = trigger.employee ? { ...trigger.employee } : undefined;

    if (employee && employee?.employees?.length === 0) {
      errorList.push({
        type: TriggerConditionErrorType.EMPLOYEE
      });
    }
    const balance = trigger.balance ? { ...trigger.balance } : undefined;

    if (balance && (balance.low === null || balance.high === null)) {
      errorList.push({
        type: TriggerConditionErrorType.BUDGET
      });
    }
    const { fields } = trigger;

    if (fields) {
      fields.forEach(field => {
        if (!field.logic && field.values.length === 0) {
          const findField = data?.find(item => item.id === field.fieldId);

          errorList.push({
            type: TriggerConditionErrorType.FIELD,
            fieldId: findField?.id || undefined
          });
        }
      });
    }

    if (trigger.toPipeStatusId === -1) {
      errorList.push({
        type: TriggerConditionErrorType.PIPE
      });
    }

    if (errorList.length > 0) {
      dispatch(setErrorList(errorList));
      return;
    }
    dispatch(setErrorList([]));


    const newFields: FieldsCondition[] = [];

    if (trigger.orderTriggerCondition) {
      trigger.orderTriggerCondition.forEach(order => {
        switch (order.type) {
          case TriggerConditionType.TAG:
            if (tags) {
              tags.order = order.order;
            }
            break;
          case TriggerConditionType.EMPLOYEE:
            if (employee) {
              employee.order = order.order;
            }
            break;
          case TriggerConditionType.BUDGET:
            if (balance) {
              balance.order = order.order;
            }
            break;
          case TriggerConditionType.FIELD:
            fields?.forEach(field => {
              if (field.fieldId === order.fieldId) {
                newFields.push({
                  ...field,
                  order: order.order
                });
              }
            });
            break;
        }
      });
    }

    const requestBody: any = {
      id: trigger.id,
      fields: newFields.length > 0 ? newFields : undefined,
      employee,
      tag: tags,
      order: trigger.order,
      pipeStatusId: trigger.pipeStatusId,
      execAfter: trigger.execAfter,
      execAfterStatus: trigger.execAfterStatus,
      execStatus: trigger.execStatus,
      toPipeStatusId: trigger.toPipeStatusId,
      employees: []
    };

    if (balance && (balance.low !== 0 || balance.high !== 0 || balance.lowString !== "" || balance.highString !== "")) {
      requestBody.balance = balance;
    } else {
      requestBody.balance = null;
    }

    createOrEditTriggerPipe.mutate({
      all: trigger.useAll,
      body: requestBody
    });
  };

  // saveManagerTrigger
  const saveManagerTrigger = () => {
    const tags = trigger.tag ? { ...trigger.tag } : undefined;
    const errorList: ErrorList[] = [];

    if (tags && tags?.tags?.length === 0) {
      errorList.push({
        type: TriggerConditionErrorType.TAG
      });
    }
    const employee = trigger.employee ? { ...trigger.employee } : undefined;

    if (employee && employee?.employees?.length === 0) {
      errorList.push({
        type: TriggerConditionErrorType.EMPLOYEE
      });
    }
    const balance = trigger.balance ? { ...trigger.balance } : undefined;

    if (balance && (balance.low === null || balance.high === null)) {
      errorList.push({
        type: TriggerConditionErrorType.BUDGET
      });
    }
    const { fields } = trigger;

    if (fields) {
      fields.forEach(field => {
        if (!field.logic && field.values.length === 0) {
          const findField = data?.find(item => item.id === field.fieldId);

          errorList.push({
            type: TriggerConditionErrorType.FIELD,
            fieldId: findField?.id || undefined
          });
        }
      });
    }

    if (trigger.changeEmployees.length === 0) {
      errorList.push({
        type: TriggerConditionErrorType.CHANGE_EMPLOYEE
      });
    }

    if (errorList.length > 0) {
      dispatch(setErrorList(errorList));
      return;
    }
    dispatch(setErrorList([]));


    const newFields: FieldsCondition[] = [];

    if (trigger.orderTriggerCondition) {
      trigger.orderTriggerCondition.forEach(order => {
        switch (order.type) {
          case TriggerConditionType.TAG:
            if (tags) {
              tags.order = order.order;
            }
            break;
          case TriggerConditionType.EMPLOYEE:
            if (employee) {
              employee.order = order.order;
            }
            break;
          case TriggerConditionType.BUDGET:
            if (balance) {
              balance.order = order.order;
            }
            break;
          case TriggerConditionType.FIELD:
            fields?.forEach(field => {
              if (field.fieldId === order.fieldId) {
                newFields.push({
                  ...field,
                  order: order.order
                });
              }
            });
            break;
        }
      });
    }
    const requestBody: any = {
      id: trigger.id,
      fields: newFields.length > 0 ? newFields : undefined,
      employee,
      tag: tags,
      order: trigger.order,
      pipeStatusId: trigger.pipeStatusId,
      execAfter: trigger.execAfter,
      execAfterStatus: trigger.execAfterStatus,
      execStatus: trigger.execStatus,
      toPipeStatusId: trigger.toPipeStatusId,
      employees: trigger.changeEmployees
    };

    if (balance && (balance.low !== 0 || balance.high !== 0 || balance.lowString !== "" || balance.highString !== "")) {
      requestBody.balance = balance;
    } else {
      requestBody.balance = null;
    }

    createOrEditTriggerManager.mutate({
      all: trigger.useAll,
      body: requestBody
    });
  };

  // handle save
  const handleSaveTrigger = () => {
    if (trigger.typeTrigger === TYPE_TRIGGER.TASK_TRIGGER) {
      saveTaskTrigger();
    } else if (trigger.typeTrigger === TYPE_TRIGGER.PIPE_STATUS_TRIGGER) {
      savePipeTrigger();
    } else if (trigger.typeTrigger === TYPE_TRIGGER.MANAGER_TRIGGER) {
      saveManagerTrigger();
    }
  };

  return (
    <div className="d-flex align-center justify-space-between">
      {trigger.id && (
        <Spin spinning={deleteTrigger.isLoading}>
          <div onClick={handleDeleteTrigger} className={styles.container_body_delete}>
            <TrushSquareIcon />
            <span>{t('triggers.Ochirish')}</span>
          </div>
        </Spin>
      )}
      <div className={styles.container_body_modalFooter}>
        <Button onClick={handleCancel} htmlType="button">
          {t('triggers.Bekor_qilish')}
        </Button>
        <Button
          loading={createOrEditTrigger.isLoading || createOrEditTriggerPipe.isLoading || createOrEditTriggerManager.isLoading}
          onClick={handleSaveTrigger}
          htmlType="button"
          type="primary"
        >
          {t('triggers.Saqlash')}
        </Button>
      </div>
    </div>
  );
};

export default Buttons;
