import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './../../pages/builderSettings/builderSettings.module.scss';
import { Profile } from '../../utils/models/Profile';
import { useEditProfile } from '../../queries/mutation';
import { useGetDistrict, useRegions } from '../../queries/queries';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';

interface Props {
  visible: boolean;
  onClose: (visible: boolean) => void;
  profile?: Profile;
}

interface IForm {
  address: string;
  region: number;
  districtId: number;
}

const ChangeAddress: React.FC<Props> = ({ visible, onClose, profile }) => {
  const [form] = Form.useForm<IForm>();

  const [regionId, setRegionId] = useState<number>(0);

  const { data: regions, isLoading: loadingRegions } = useRegions();
  const { data: districts, isLoading: districtsLoading } = useGetDistrict(regionId, 'region');

  const { t } = useTranslation();

  const editProfile = useEditProfile(handleCancel);

  useEffect(() => {
    if (profile) {
      form.setFieldsValue({
        districtId: profile.districtId,
        region: profile.cityId,
        address: profile.address
      });
      setRegionId(profile?.cityId || 0);
    }
  }, [profile, form, visible]);

  // handle close modal
  function handleCancel() {
    onClose(false);
    form.resetFields();
  }

  // handle submit form
  const onFinish = (values: IForm) => {
    if (profile) {
      editProfile.mutate({
        ...profile,
        address: values.address,
        cityId: values.region,
        districtId: values.districtId
      });
    }
  };

  // handle change region
  const changeRegion = (id: number) => {
    form.setFieldsValue({
      districtId: undefined
    });
    setRegionId(id);
  };

  return (
    <Modal
      width={546}
      className={'createBuilderModal'}
      title={t('profile.Manzilni_ozgartirish')}
      visible={visible}
      footer={false}
      onCancel={handleCancel}
    >
      <Form onFinish={onFinish} form={form} layout="vertical" className={styles.formBuilderModal}>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item
              label={t('home.Viloyat')}
              name="region"
              rules={[
                {
                  required: true,
                  message: ''
                }
              ]}
            >
              <Select
                optionFilterProp={'children'}
                showSearch
                className={'customSelect'}
                placeholder={t('home.Tanlang')}
                loading={loadingRegions}
                onChange={i => changeRegion(i)}
              >
                {regions?.map(region => (
                  <Select.Option key={region.id} value={region.id}>
                    {getCurrentLanguageWord(region)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('home.Shaxar')}
              name="districtId"
              rules={[
                {
                  required: true,
                  message: ''
                }
              ]}
            >
              <Select optionFilterProp={'children'} showSearch className={'customSelect'} placeholder={t('home.Tanlang')} loading={districtsLoading}>
                {districts?.map(district => (
                  <Select.Option key={district.id} value={district.id}>
                    {getCurrentLanguageWord(district)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('profile.Manzili')}
              name="address"
              rules={[
                {
                  required: true,
                  message: ''
                }
              ]}
            >
              <Input className={'customInput'} />
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.modalFooter}>
          <Button onClick={handleCancel}> {t('home.Yopish')}</Button>
          <Button loading={editProfile.isLoading} htmlType="submit" type="primary">
            {t('profile.Tasdiqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangeAddress;
