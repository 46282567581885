import React, { useState } from 'react';
import styles from './showroomList.module.scss';
import { Table } from 'antd';
import ArrowLeftIcon from '@icons/contract/ArrowLeftIcon';
import ArrowRightIcon from '@icons/contract/ArrowRightIcon';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { useSmartCatalogTable } from '@pages/showroom-v2/services/queries';
import DotIcon from '@icons/contract/DotIcon';
import { mathFloor } from '@utils/helper/mathFloor';
import { FlatStatus } from '@utils/models/FlatStatus';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { setListSettingDrawer, setShowroomApartments, setShowroomFilter, setShowroomPetition } from '@reducers/ShowroomSlice';
import { IShowroomFlat } from '@pages/showroom-v2/utils/models/IShowroomFlat';
import TableConfigDrawer from '@components/filters/TableConfigDrawer';
import { TableKey } from '@utils/models/TableKey';
import { useTableConfig } from '@queries/queries';
import { TableConfig } from '@utils/models/TableConfig';
import { tableExamples } from '@utils/constants/tableExamples';
import { clear } from '@reducers/CreateContractSlice';
import { setApartments } from '@reducers/AllHouseFilter';
import { setCompanyId } from '@reducers/CompanySlice';

const ShowroomList = () => {
  const dispatch = useAppDispatch();
  const [offsetTop, setOffsetTop] = useState(0);
  const { data, isLoading } = useSmartCatalogTable();
  const { t } = useTranslation();
  const { listSettingDrawer, apartments, apartmentActiveIndex, filter } = useAppSelector(state => state.showroomSlice);
  const { data: tableConfig, isLoading: tableConfigLoading } = useTableConfig(TableKey.showroom_list);

  const flatStatus = {
    BOOKED: (
      <span className={styles.flatStatus} style={{ color: '#FFB400', background: '#FFB40020' }}>
        {t('home.BOOKED')}
      </span>
    ),
    SALE: (
      <span className={styles.flatStatus} style={{ color: '#63CBA5', background: '#63CBA520' }}>
        {t('home.SALE')}
      </span>
    ),
    SOLD: (
      <span className={styles.flatStatus} style={{ color: '#FF6565', background: '#FF656520' }}>
        {t('home.SOLD')}
      </span>
    ),
    RESERVE: (
      <span className={styles.flatStatus} style={{ color: '#A6A6A6', background: '#A6A6A620' }}>
        {t('home.RESERVE')}
      </span>
    )
  };

  const columns: ColumnsType<IShowroomFlat> | undefined | any = [
    {
      title: t('home.TJM_nomi'),
      dataIndex: 'houseName',
      align: 'left',
      width: 202,
      key: 1
    },
    {
      title: (
        <div className={'orderTableCont'}>
          <span>{t('home.Blok')}</span>
        </div>
      ),
      align: 'center',
      dataIndex: 'buildingName',
      width: 100,
      key: 2
    },
    {
      title: (
        <div className={'orderTableCont'}>
          <span>{t('home.Xonadon_raqami')}</span>
        </div>
      ),
      align: 'center',
      dataIndex: 'number',
      width: 100,
      key: 3
    },
    {
      title: (
        <div className={'orderTableCont'}>
          <span>{t('home.Qavat')}</span>
        </div>
      ),
      align: 'center',
      dataIndex: 'floor',
      width: 90,
      key: 4
    },
    {
      title: (
        <div>
          <span>{t('home.Xonalar_soni')}</span>
        </div>
      ),
      dataIndex: 'rooms',
      align: 'center',
      width: 120,
      render: (prev: number) => (
        <span className={styles.number}>
          <DotIcon size={12} color="#12B76A" /> {prev}
        </span>
      ),
      key: 5
    },
    {
      title: (
        <div>
          <span>{t('home.Maydoni')}</span>
        </div>
      ),
      align: 'center',
      width: 120,
      render: (item: any) => {
        if (item.terraceDTO)
          return (
            <span>
              {mathFloor(item.area + item.terraceDTO.area, 2)} {t('create_table_payments.m2')}
            </span>
          );
        return (
          <span>
            {item.area} {t('create_table_payments.m2')}
          </span>
        );
      },
      key: 6
    },
    {
      title: (
        <div className={styles.header_price}>
          <span className={styles.header_price_label}>{t('home.1_m2_uchun')}</span>{' '}
        </div>
      ),
      align: 'center',
      key: 8,
      children: [
        {
          title: (
            <div className={'orderTableCont'}>
              <span>{t('home.Tamirsiz')}</span>
            </div>
          ),
          name: 'Tamirsiz',
          width: 150,
          render: (item: any) => (
            <div className={styles.body_price_item}>
              {item?.pricePerAreaNotRepaired ? (
                <>
                  <span className={styles.body_price_item_price}>{item?.pricePerAreaNotRepaired?.toLocaleString('ru')}</span>
                  <span className={styles.body_price_item_currency}>{item?.currency?.ccy}</span>
                </>
              ) : (
                <span style={{ margin: '0 auto' }}>-</span>
              )}
            </div>
          )
        },
        {
          title: (
            <div className={'orderTableCont'}>
              <span>{t('home.Tamirli')}</span>
            </div>
          ),
          name: 'Tamirli',
          width: 150,
          render: (item: any) => (
            <div className={styles.body_price_item}>
              {item?.pricePeraAreaRepaired ? (
                <>
                  <span className={styles.body_price_item_price}>{item?.pricePeraAreaRepaired?.toLocaleString('ru')}</span>
                  <span className={styles.body_price_item_currency}>{item?.currency?.ccy}</span>
                </>
              ) : (
                <span style={{ margin: '0 auto' }}>-</span>
              )}
            </div>
          )
        }
      ]
    },
    {
      title: (
        <div className={styles.header_price}>
          <span className={styles.header_price_label}>{t('home.Umumiy_narx')}</span>{' '}
        </div>
      ),
      align: 'center',
      key: 9,
      children: [
        {
          title: (
            <div className={'orderTableCont'}>
              <span>{t('home.Tamirsiz')}</span>
            </div>
          ),
          name: 'Tamirsiz',
          width: 150,
          render: (item: IShowroomFlat) => (
            <div className={styles.body_price_item}>
              {item.priceNotRepaired ? (
                <>
                  <span className={styles.body_price_item_price}>{item?.priceNotRepaired?.toLocaleString('ru')}</span>
                  <span className={styles.body_price_item_currency}>{item?.currency?.ccy}</span>
                </>
              ) : (
                <span style={{ margin: '0 auto' }}>-</span>
              )}
            </div>
          )
        },
        {
          title: (
            <div className={'orderTableCont'}>
              <span>{t('home.Tamirli')}</span>
            </div>
          ),
          name: 'Tamirli',
          width: 150,
          render: (item: IShowroomFlat) => (
            <div className={styles.body_price_item}>
              {item?.priceRepaired ? (
                <>
                  <span className={styles.body_price_item_price}>{item?.priceRepaired?.toLocaleString('ru')}</span>
                  <span className={styles.body_price_item_currency}>{item?.currency?.ccy}</span>
                </>
              ) : (
                <span style={{ margin: '0 auto' }}>-</span>
              )}
            </div>
          )
        }
      ]
    },
    {
      title: t('home.Tamirli_holati'),
      width: 130,
      dataIndex: 'repaired',
      align: 'center',
      render: (item: boolean) => <div>{item ? <span>{t('home.Tamirli')}</span> : <span>{t('home.Tamirsiz')}</span>}</div>,
      key: 10
    },
    {
      title: t('home.Holati'),
      dataIndex: 'commerceStatus',
      align: 'center',
      width: 129,
      render: (prev: string) => flatStatus[prev as keyof typeof FlatStatus],
      key: 11
    }
  ];

  const hasChildren = (item: TableConfig, column: any) => {
    return {
      ...column,
      children: column?.children
        ? column?.children
            ?.map((child: any) => ({
              ...(item.values?.some(val => val?.name === child?.name && val?.checked) && child)
            }))
            .filter((item: any) => item?.name)
        : []
    };
  };

  const columnsSort = (tableConfig?.config && tableConfig?.config?.length > 0 ? tableConfig?.config : tableExamples['showroom-list'])
    ?.map(item => ({
      ...(item.checked &&
        hasChildren(
          item,
          columns?.find((col: any) => col?.key === item?.id)
        ))
    }))
    .filter(item => item?.title);

  const onCloseSetting = (visible: boolean) => {
    dispatch(setListSettingDrawer(visible));
  };

  return (
    <div className={styles.container}>
      <Table
        dataSource={data?.data || []}
        columns={columnsSort}
        className={`${styles.container_table}`}
        rowKey={prev => prev.id}
        style={{ height: `calc(100vh - ${offsetTop + 16}px)` }}
        scroll={{
          y: `calc(100vh - ${offsetTop + 150}px)`
        }}
        ref={ref => {
          setOffsetTop(ref?.offsetTop || 0);
        }}
        onChange={pagination => {
          dispatch(
            setShowroomFilter({
              page: pagination.current,
              size: pagination.pageSize
            })
          );
        }}
        onRow={record => {
          return {
            onClick: () => {
              if (apartments?.length === 1 && !apartments?.some(item => item?.id === record?.id)) {
                dispatch(clear());
                dispatch(
                  setShowroomApartments([
                    {
                      id: record?.id,
                      bookingId: record?.bookingId,
                      bookingNumber: record?.bookingNumber,
                      number: record?.number,
                      rooms: record?.rooms,
                      status: record?.commerceStatus
                    }
                  ])
                );
                dispatch(
                  setShowroomPetition({
                    visible: true,
                    houseName: record?.houseName
                  })
                );
              } else {
                dispatch(
                  setShowroomApartments([
                    {
                      id: record?.id,
                      bookingId: record?.bookingId,
                      bookingNumber: record?.bookingNumber,
                      number: record?.number,
                      rooms: record?.rooms,
                      status: record?.commerceStatus
                    }
                  ])
                );
                dispatch(
                  setShowroomPetition({
                    visible: true,
                    houseName: record?.houseName
                  })
                );
              }

              dispatch(setCompanyId(record?.companyId));
            }
          };
        }}
        loading={isLoading || tableConfigLoading}
        pagination={
          data?.totalPages !== 1
            ? {
                total: data?.totalElements !== undefined ? data.totalElements : 1,
                current: data?.currentPage ? data.currentPage : 1,
                className: 'tableNewStyle_pagination',
                prevIcon: (
                  <div className={'tableNewStyle_pagination_prev'}>
                    <ArrowLeftIcon /> {t('my_calls.Ortga')}
                  </div>
                ),
                pageSize: 10,
                nextIcon: (
                  <div className={'tableNewStyle_pagination_next'}>
                    {t('crm_ins.Keyingisi')} <ArrowRightIcon />
                  </div>
                )
              }
            : false
        }
      />
      <TableConfigDrawer tableKey={TableKey.showroom_list} visible={listSettingDrawer} onClose={onCloseSetting} />
    </div>
  );
};

export default ShowroomList;
