import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClientLeadModel } from '@utils/models/ClientLeadModel';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useCreateAndUpdateNaturalPerson, useLeadSave, useUploadFile } from '../../../queries/mutation';
import { useGetDistrict, useRegions } from '../../../queries/queries';
import {
  addClientPhone,
  changeCountryCode,
  checkClientPhone,
  deleteClientPhones,
  setNaturalPersonImage,
  setSearchByPhone,
  setSearchPhone
} from '../../../store/reducers/ClientSlice';
import { BronIdOrFlatIds } from '../../../utils/models/BronIdOrFlatIds';
import { ClientPhone, NaturalPersonCreate } from '../../../utils/models/Client';
import { FileObject } from '../../../utils/models/File';

import ClientMainData from './ClientMainData';
import SearchClientWithPassportData from './SearchClientWithPassportData';

import styles from '../../../pages/clientCreateContract/clientCreateContract.module.scss';

export interface FormAddress {
  passportSerial: string;
  passportNumber: string;
  dateBirth: string;
  passport: string;
}

export interface FormMainData {
  phone1: string;
  phone2: string;
  phone3: string;
  phone4: string;
  phone5: string;
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  code5: string;
  districtId: number;
  address: string;
  cityID: number;
  firstname: string;
  lastname: string;
  fatherName: string;
  passportPlace: string;
  passportDate: string;
  passportExpiredDate: string;
  workPlace: string;
  inn: string;
  pinfl: string;
  countryCode: string;
  [key: string]: any; // для других возможных полей
}

const Natural = () => {
  const { t } = useTranslation();
  const params = useParams<{ orderIdOrFlatId: string }>();
  const dispatch = useAppDispatch();
  const [formPassport] = Form.useForm<FormAddress>();
  const [formMain] = Form.useForm<FormMainData>();
  const [leadId, setLeadId] = useState<number>(0);
  const updateNewLead = useLeadSave();
  const { clientPhones, naturalPerson: clientData, passportOneId, passportTwoId, leadName, leadContact, leadContactName } = useAppSelector(state => state.clientReducer);

  useEffect(() => {
    if (clientData?.phones[0]?.code) {
      dispatch(changeCountryCode(clientData?.phones[0]?.code));
    } else {
      dispatch(changeCountryCode('UZ'));
    }
  }, [clientData]);

  const [typeAddress, setTypeAddress] = useState<{
    id: number;
    type: 'district' | 'region';
  }>({
    id: -1,
    type: 'region'
  });

  const typeImage = useRef<'first' | 'second'>('first');

  const { data: regions, isLoading: loadingRegions } = useRegions();
  const { data: districts, isLoading: districtsLoading } = useGetDistrict(typeAddress.id, typeAddress.type);
  const [selectLead, setSelectLead] = useState<ClientLeadModel | undefined>();

  useEffect(() => {
    if (setLeadId && selectLead?.id) {
      setLeadId(selectLead?.id);
    }
  }, [selectLead]);

  const currentClientLead = (selectLead && (clientData?.lead === null || clientData?.lead === undefined)) || clientData === null ? selectLead : clientData?.lead;

  const createAndUpdateClient = useCreateAndUpdateNaturalPerson(params.orderIdOrFlatId);

  function getFile(data: FileObject[]) {
    dispatch(
      setNaturalPersonImage({
        id: data[0].id,
        type: typeImage.current
      })
    );
  }

  const uploadFile = useUploadFile(getFile);

  const changeRegion = (value: number) => {
    formMain.setFieldsValue({
      districtId: undefined
    });
    setTypeAddress({
      type: 'region',
      id: value
    });
  };

  useEffect(() => {
    if (clientData?.id || clientData?.passportNumber) {
      formMain.setFieldsValue({
        lastname: clientData?.lastname,
        firstname: clientData?.firstname,
        fatherName: clientData?.fatherName,
        passportPlace: clientData?.passportPlace,
        passportDate: clientData?.passportDate,
        passportExpiredDate: clientData?.passportExpiredDate,
        workPlace: clientData?.workPlace,
        districtId: clientData?.districtId,
        address: clientData?.address,
        cityID: clientData?.cityID,
        inn: clientData?.inn,
        pinfl: clientData?.pinfl,
        countryCode: clientData?.countryCode ? clientData?.countryCode : 'uz'
      });
      formPassport.setFieldsValue({
        passportSerial: clientData?.passportSerial,
        passportNumber: clientData?.passportNumber,
        dateBirth: clientData?.dateBirth,
        passport: clientData?.passportSerial
      });
      for (let i = 0; i < clientData.phones.length; i++) {
        formMain.setFieldsValue({
          [`phone${i + 1}`]: clientData.phones[i].phone,
          [`code${i + 1}`]: clientData.phones[i]?.code ? clientData.phones[i]?.code : 'UZ'
        });
      }
      if (clientData.districtId) {
        setTypeAddress({
          id: clientData.districtId,
          type: 'district'
        });
      }
    } else {
      formMain.setFieldsValue({
        phone1: '',
        phone2: '',
        phone3: '',
        phone4: '',
        phone5: '',
        districtId: undefined,
        address: undefined,
        cityID: undefined,
        firstname: undefined,
        lastname: undefined,
        fatherName: undefined,
        passportPlace: undefined,
        passportDate: '',
        passportExpiredDate: '',
        workPlace: undefined,
        inn: '',
        pinfl: '',
        countryCode: 'uz'
      });
      formPassport.setFieldsValue({
        dateBirth: '',
        passport: undefined,
        passportNumber: '',
        passportSerial: ''
      });
    }
  }, [clientData, formMain, formPassport]);

  const handleAddClientPhone = () => {
    dispatch(addClientPhone());
  };

  const handlePassportImage = (e: any, type: 'first' | 'second') => {
    if (e.target?.files[0] && e.target.files[0].size <= 10000000) {
      const file = e.target.files[0];
      const formData = new FormData();

      formData.append('files', file);
      typeImage.current = type;
      uploadFile.mutate(formData);
    }
  };

  const handleRemoveClientPhone = (index: number) => {
    for (let i = 0; i < 5; i++) {
      if (index === i) {
        formMain.setFieldsValue({
          [`phone${i + 1}`]: '',
          [`code${i + 1}`]: ''
        });
      } else if (i > index) {
        formMain.setFieldsValue({
          [`phone${i}`]: formMain.getFieldValue(`phone${i + 1}`),
          [`code${i}`]: formMain.getFieldValue(`code${i + 1}`)
        });
      }
    }
    formMain.setFieldsValue({
      [`phone5`]: '',
      [`code5`]: ''
    });
    dispatch(deleteClientPhones(index));
  };

  const handleCheckedPhone = (index: number) => {
    dispatch(checkClientPhone(index));
  };

  const contactsCopy = (contacts?: any[]) => {
    const arr = [...(contacts || [])];

    arr[0].name = leadContactName;
    arr[0].phones = [leadContact];

    return arr;
  };

  const handleSubmit = async () => {
    Promise.all([formMain.validateFields(), formPassport.validateFields()]).then(values => {
      const newPhones: ClientPhone[] = [];

      for (let i = 0; i < clientPhones.length && i < 5; i++) {
        newPhones.push({
          ...clientPhones[i],
          phone: String(values[0][`phone${i + 1}` as keyof (typeof values)[0]])?.includes('+')
            ? String(`${values[0][`phone${i + 1}` as keyof (typeof values)[0]]}`)
            : String(`+${values[0][`phone${i + 1}` as keyof (typeof values)[0]]}`),
          code: String(values[0][`code${i + 1}` as keyof (typeof values)[0]])
        });
      }
      const newClientData: NaturalPersonCreate = {
        phones: newPhones,
        firstname: values[0].firstname,
        lastname: values[0].lastname,
        fatherName: values[0].fatherName,
        dateBirth: values[1].dateBirth,
        workPlace: values[0].workPlace,
        passportPlace: values[0].passportPlace,
        passportDate: values[0].passportDate,
        passportExpiredDate: values[0].passportExpiredDate,
        districtId: values[0].districtId,
        address: values[0].address,
        passportSerial: values[1].passportSerial,
        passportNumber: values[1].passportNumber,
        id: clientData?.id,
        passportImg1Id: passportOneId,
        passportImg2Id: passportTwoId,
        inn: values[0]?.inn,
        pinfl: values[0]?.pinfl,
        countryCode: values[0]?.countryCode,
        leadId
      };

      createAndUpdateClient.mutateAsync(newClientData).then(res => {
        const updateLidData = {
          id: currentClientLead?.id,
          contacts: contactsCopy(currentClientLead?.contacts),
          balance: currentClientLead?.balance,
          responsibleById: currentClientLead?.responsibleById,
          pipeStatusId: currentClientLead?.pipeStatusResponse?.id,
          tagIds: currentClientLead?.tags?.map(item => item?.id),
          fields: currentClientLead?.fields,
          name: leadName,
          autoClosedLeadTask: false
        };

        setSelectLead(undefined);
        updateNewLead.mutateAsync(updateLidData as any).then(() => dispatch(changeCountryCode('UZ')));
      });
      // console.log(values);
    });
    dispatch(setSearchPhone(-1));
    dispatch(setSearchByPhone(null));
  };

  const getTitleSaveButton = () => {
    if (params.orderIdOrFlatId?.includes(BronIdOrFlatIds.BRON_ID) || params.orderIdOrFlatId?.includes(BronIdOrFlatIds.FLAT_IDS)) return t('client_create_contract.Keyingisi');
    return t('shaxmatka.Saqlash');
  };

  return (
    <div className={styles.client_cont}>
      <SearchClientWithPassportData form={formPassport} />
      <ClientMainData
        loadingRegions={loadingRegions}
        handleAddClientPhone={handleAddClientPhone}
        changeRegion={changeRegion}
        districts={districts}
        districtsLoading={districtsLoading}
        handleCheckedPhone={handleCheckedPhone}
        handlePassportImage={handlePassportImage}
        handleRemoveClientPhone={handleRemoveClientPhone}
        regions={regions}
        form={formMain}
        passportTwoId={passportTwoId}
        passportOneId={passportOneId}
        clientData={clientData}
        clientPhones={clientPhones}
        setSelectLead={setSelectLead}
        currentClientLead={currentClientLead}
      />
      <div className="d-flex justify-center" style={{ width: '100%', marginTop: '20px' }}>
        <Button disabled className={styles.conditionCanceledBtn} onClick={() => {}}>
          {t('home.Bekor_qilish')}
        </Button>
        <Button loading={createAndUpdateClient.isLoading} className={styles.conditionSubmitBtn} type="primary" onClick={handleSubmit}>
          {getTitleSaveButton()}
        </Button>
      </div>
    </div>
  );
};

export default Natural;
