import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const ProfileTwoUserIcon: React.FC<Props> = ({ onClick, className }) => {
  return (
    <svg className={className} onClick={onClick} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5 2.30566C6.88 2.30566 4.75 4.43566 4.75 7.05566C4.75 9.62566 6.76 11.7057 9.38 11.7957C9.46 11.7857 9.54 11.7857 9.6 11.7957C9.62 11.7957 9.63 11.7957 9.65 11.7957C9.66 11.7957 9.66 11.7957 9.67 11.7957C12.23 11.7057 14.24 9.62566 14.25 7.05566C14.25 4.43566 12.12 2.30566 9.5 2.30566Z"
        fill="#A9A9A9"
      />
      <path
        d="M14.58 14.4557C11.79 12.5957 7.23996 12.5957 4.42996 14.4557C3.15996 15.3057 2.45996 16.4557 2.45996 17.6857C2.45996 18.9157 3.15996 20.0557 4.41996 20.8957C5.81996 21.8357 7.65996 22.3057 9.49996 22.3057C11.34 22.3057 13.18 21.8357 14.58 20.8957C15.84 20.0457 16.54 18.9057 16.54 17.6657C16.53 16.4357 15.84 15.2957 14.58 14.4557Z"
        fill="#A9A9A9"
      />
      <path
        d="M20.49 7.64566C20.65 9.58566 19.27 11.2857 17.36 11.5157C17.35 11.5157 17.35 11.5157 17.34 11.5157H17.31C17.25 11.5157 17.19 11.5157 17.14 11.5357C16.17 11.5857 15.28 11.2757 14.61 10.7057C15.64 9.78566 16.23 8.40566 16.11 6.90566C16.04 6.09566 15.76 5.35566 15.34 4.72566C15.72 4.53566 16.16 4.41566 16.61 4.37566C18.57 4.20566 20.32 5.66566 20.49 7.64566Z"
        fill="#A9A9A9"
      />
      <path
        d="M22.49 16.8957C22.41 17.8657 21.79 18.7057 20.75 19.2757C19.75 19.8257 18.49 20.0857 17.24 20.0557C17.96 19.4057 18.38 18.5957 18.46 17.7357C18.56 16.4957 17.97 15.3057 16.79 14.3557C16.12 13.8257 15.34 13.4057 14.49 13.0957C16.7 12.4557 19.48 12.8857 21.19 14.2657C22.11 15.0057 22.58 15.9357 22.49 16.8957Z"
        fill="#A9A9A9"
      />
    </svg>
  );
};

export default ProfileTwoUserIcon;
