import React, { useEffect, useState } from 'react';
import PlusIcon from '@icons/PlusIcon';
import { useTranslation } from 'react-i18next';

import { useUzumConfig } from 'modules/uzum-integration';

import CreateNewConfig from './CreateNewConfig';

import logo from '../../../../assets/img/uzum.png';

const UzumContent = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { data } = useUzumConfig();

  useEffect(() => {
    if (data?.username && data?.password) {
      setOpen(true);
    } else setOpen(false);
  }, [data]);

  const handleOpenForm = () => {
    setOpen(true);
  };

  return (
    <div className="mt-4 flex items-start gap-2">
      <div className="!w-[280px] rounded-md bg-white px-12 py-11">
        <img src={logo} alt="err" />
      </div>

      {!open ? (
        <div className="flex h-[148px] w-[calc(100%-280px)] cursor-pointer items-center justify-center rounded-md bg-white py-6" onClick={handleOpenForm}>
          <PlusIcon />
          <p className="text-xl font-medium text-[#00A389]">{t('crm.Yangi_yaratish')}</p>
        </div>
      ) : (
        <div className="w-[calc(100%-280px)] rounded-md bg-white px-11 py-6">
          <CreateNewConfig data={data} setOpen={setOpen} />
        </div>
      )}
    </div>
  );
};

export default UzumContent;
