import React, { useState } from 'react';
import { Button, Spin } from 'antd';
import { useGetSMSBot } from '../service/queries';
import { t } from 'i18next';
import styles from '../../../integration.module.scss';
import { useGetChangeStatus } from '../service/mutation';
import Active from '@pages/integration/assets/icons/Active';
import Deactive from '@pages/integration/assets/icons/Deactive';

const AutoSMSTriggerActivate = () => {
  const { data: dataSMSBot } = useGetSMSBot();
  const changeStatus = useGetChangeStatus();
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleStatus = () => {
    setIsLoading(true);
    changeStatus.mutateAsync(!dataSMSBot?.active).then(() => setIsLoading(false));
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      <Button
        type="primary"
        onClick={handleToggleStatus}
        loading={isLoading}
        className={styles.greeBtn}
        style={{ background: dataSMSBot?.active ? '' : '#ff4d4f' }}
      >
        <Spin spinning={isLoading} size="small" style={{ marginRight: '2px' }}>
          {dataSMSBot?.active ?? false ? (
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <Active />
              <p>{t('integration.Aktiv')}</p>
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <Deactive />
              <p>{t('integration.Aktivlashtirish')}</p>
            </div>
          )}
        </Spin>
      </Button>
    </div>
  );
};

export default AutoSMSTriggerActivate;

// Мы поняли что с бекенда приходит true или false.
// Но, с бекенда может приходить null, и для него тоже нужно сделать состояние.
