import React, { useState } from 'react';
import Header from './components/header/Header';
import MyResponsiveBar from './components/my-chart-bar/MyResponsiveBar';

import styles from './bossMonthlyPayments.module.scss';
import { useTranslation } from 'react-i18next';

const BossMonthlyPayment = () => {
  const { t } = useTranslation();
  const [currency, setCurrency] = useState('UZS');
  const [multiple, setMultiple] = useState<number[]>();

  return (
    <div className={styles.monthlyPayment}>
      <Header multiple={multiple} setMultiple={setMultiple} setCurrency={setCurrency} />
      <div className={styles.monthlyPayment_chart}>
        <div className={styles.monthlyPayment_chart_top}>
          <div className={styles.circle}></div>
          <span>
            {t('boss_home.Reja')} ({t('boss_home.oylik_tolov_grafigi')})
          </span>
          <div className={styles.circle1}></div>
          <span>{t('boss_home.Tolovlar')}</span>
        </div>
        <MyResponsiveBar multiple={multiple} setMultiple={setMultiple} currency={currency} />
      </div>
    </div>
  );
};

export default BossMonthlyPayment;
