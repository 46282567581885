import React, { useState } from 'react';
import { Checkbox, Dropdown, Segmented, Select } from 'antd';
import { DATE_FORMAT } from '@utils/constants/format';
import { useDispatch } from 'react-redux';
import { setChangeDefaultValue1, setDate4 } from '@reducers/CrmStatisticsSlice';
import { useGetAllHouses } from '../../services/queries';
import dayjs from 'dayjs';
import DatePicker from '@service/datePicker';

import styles from './header.module.scss';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import ArrowDownIcon from '@icons/ArrowDownIcon';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@hooks/reduxHooks';

type Props = {
  setCurrency: React.Dispatch<React.SetStateAction<string>>;
  multiple: number[] | undefined;
  setMultiple: React.Dispatch<React.SetStateAction<number[] | undefined>>;
};

const { RangePicker } = DatePicker;

const Header: React.FC<Props> = ({ setCurrency, setMultiple }) => {
  const { start3, finish3 } = useAppSelector(state => state.crmStatistic);

  const [values, setValues] = useState<any[] | null>([dayjs.unix(Number(start3)), dayjs.unix(Number(finish3))]);
  const [filter, setFilter] = useState<{ house?: number[] | null }>({});

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const handleValues = (value: string[] | null) => {
    setValues(value);
  };
  const { data } = useGetAllHouses();

  const handleSelectDate = (date: string[]) => {
    dispatch(setChangeDefaultValue1(`${date[0]}-${date[1]}`));
    dispatch(
      setDate4({
        finishData: String(dayjs(date[1], DATE_FORMAT).endOf('d').unix()),
        startDate: String(dayjs(date[0], DATE_FORMAT).unix())
      })
    );
  };

  const handleSelectTime = (value: string[]) => {
    if (value![0] === '' && value![1] === '') {
      handleSelectDate(null!);
      handleValues(null);
    } else {
      handleSelectDate(value);
      handleValues(value);
    }
  };

  const changeHouse = (e: CheckboxChangeEvent, id: number) => {
    if (e.target.checked) {
      setFilter(prev => ({
        ...prev,
        house: [...(prev.house || []), id]
      }));
    } else {
      setFilter(prev => ({
        ...prev,
        house: prev.house?.filter(item => item !== id) || null
      }));
    }
  };

  return (
    <div className={styles.header}>
      <div className={styles.segments}>
        <Segmented onChange={e => setCurrency(String(e))} options={['UZS', 'USD']} />
      </div>
      <div className={styles.selects}>
        <RangePicker
          value={values ? ([dayjs(values[0] || '', DATE_FORMAT), dayjs(values[1] || '', DATE_FORMAT)] as any) : null}
          format={'MMM YYYY'}
          onChange={(date, dateString) => {
            handleSelectTime(date as any);
          }}
          picker="month"
          placeholder={[t('boss_crm.Boshlangich_sana'), t('boss_crm.Oxirgi_sana')]}
        />
        <Dropdown
          mouseEnterDelay={1000}
          mouseLeaveDelay={0.5}
          trigger={['click', 'hover']}
          onVisibleChange={() => setMultiple(filter?.house!)}
          placement="bottomRight"
          overlay={
            <div className={styles.houses}>
              <Checkbox
                style={{ padding: '0 8px' }}
                checked={data?.length === filter?.house?.length}
                onChange={e => {
                  if (e.target.checked) {
                    setFilter(prev => ({
                      ...prev,
                      house: [...(data?.map(item => item?.id) || [])]
                    }));
                  } else {
                    setFilter(prev => ({
                      ...prev,
                      house: null
                    }));
                  }
                }}
              >
                {t('home.Barchasi')}
              </Checkbox>
              {data?.map(item => (
                <div key={item?.id} title={item?.name?.length > 24 ? item?.name : ''}>
                  <Checkbox checked={filter.house?.includes(item?.id)} onChange={e => changeHouse(e, item?.id)}>
                    {item?.name?.length > 24 ? `${item?.name?.substring(0, 24)}...` : item?.name}
                  </Checkbox>
                </div>
              ))}
            </div>
          }
        >
          <div>
            {filter?.house?.length! > 0 && <span className={styles.countHouses}>{filter?.house?.length}</span>}
            {filter?.house?.length! > 0 ? t('home.TJM_Tanlandi') : t('home.Barcha_TJM_lar')}
            <ArrowDownIcon />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default Header;
