import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseIconV2 from '@icons/CloseIconV2';
import RobotoCallsIcon from '@icons/RobotoCallsIcon';
import CreateRobotCall from '@pages/roboto-calls/components/create-robot-call/CreateRobotCall';
import { setBalanceAndIds, setOpenDublicateModal } from '@reducers/CrmSlice';
import { Permissions } from '@utils/constants/permissions';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import FolderOpenIcon from '../../../../../assets/icons/FolderOpenIcon';
import Message3Icon from '../../../../../assets/icons/Message3Icon';
import ProfileAddIcon from '../../../../../assets/icons/ProfileAddIcon';
import ProgrammingArrowsIcon from '../../../../../assets/icons/ProgrammingArrowsIcon';
import SmsTrackingWhiteIcon from '../../../../../assets/icons/SmsTrackingWhiteIcon';
import TagTwoIcon from '../../../../../assets/icons/TagTwoIcon';
import TaskSquareIcon from '../../../../../assets/icons/TaskSquareIcon';
import TrushWhiteIcon from '../../../../../assets/icons/TrushWhiteIcon';
import { useAppSelector } from '../../../../../hooks/reduxHooks';

import DublicateControllModal from './dublicate-controll-modal/DublicateControllModal';
import BrickTag from './modals/BrickTag';
import ChangeEmployee from './modals/ChangeEmployee';
import ChangePipeStatus from './modals/ChangePipeStatus';
import CreateTask from './modals/CreateTask';
import DeleteLead from './modals/DeleteLead';
import SendChatMessage from './modals/SendChatMessage';
import SendMessageModal from './modals/SendMessageModal';

import styles from './actions.module.scss';

const Actions = () => {
  const [openCreteTask, setOpenCreteTask] = useState(false);
  const [openEmployeeChange, setOpenEmployeeChange] = useState(false);
  const [openDeleteLead, setOpenDeleteLead] = useState(false);
  const [openSendMessage, setOpenSendMessage] = useState(false);
  const [openSendChatMessage, setOpenSendChatMessage] = useState(false);
  const [openBrickTag, setOpenBrickTag] = useState(false);
  const [openChangePipeStatus, setOpenChangePipeStatus] = useState(false);
  const [openRobotCall, setOpenRobotCall] = useState(false);
  const dispatch = useDispatch();

  const activeIds = useAppSelector(state => state.assigmentSlice?.listViewActiveIds);
  const balance = useAppSelector(state => state.assigmentSlice.balance);
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const isAccessRobotCall = permissions?.includes(Permissions.PERMISSION_ROBOT_CALL);

  const { t } = useTranslation();

  // handle create task modal
  const handleCreateTaskModal = (visible: boolean) => {
    setOpenCreteTask(visible);
  };

  // handleChangeEmployee
  const handleChangeEmployee = (visible: boolean) => {
    setOpenEmployeeChange(visible);
  };

  // handleDeleteLead
  const handleDeleteLead = (visible: boolean) => {
    setOpenDeleteLead(visible);
  };

  // handleSendMessage
  const handleSendMessage = (visible: boolean) => {
    setOpenSendMessage(visible);
  };

  // handleSendMessage
  const handleSendChatMessage = (visible: boolean) => {
    setOpenSendChatMessage(visible);
  };

  // handleBrickTag
  const handleBrickTag = (visible: boolean) => {
    setOpenBrickTag(visible);
  };

  // handleChangePipeStatus
  const handleChangePipeStatus = (visible: boolean) => {
    setOpenChangePipeStatus(visible);
  };

  const handleOpenRobotCall = () => {
    setOpenRobotCall(true);
  };

  // handleSendMessage
  const handleClear = () => {
    dispatch(
      setBalanceAndIds({
        ids: [],
        balance: 0
      })
    );
  };

  if (activeIds.length === 0) return null;

  return (
    <div className={styles.container}>
      <div>
        <span className={styles.container_count}>
          {activeIds.length} {t('list_view.ta_belgilangan')}
        </span>
        <span className={styles.container_budget}>
          {t('list_view.Budjet')}: {balance?.toLocaleString('ru')} {t('list_view.som')}
        </span>
      </div>
      <div className="ml-[-140px] flex">
        <Tooltip title={<span>{t('list_view.Masul_shaxsni_ozgartirish')}</span>} placement="bottom" align={{ offset: [1, -2] }}>
          <span className={styles.container_icon} onClick={() => handleChangeEmployee(true)}>
            <i>
              <ProfileAddIcon />
            </i>
          </span>
        </Tooltip>

        <Tooltip title={<span>{t('home.Birlashtirish')}</span>} placement="bottom" align={{ offset: [1, -2] }}>
          <span className={styles.container_icon} onClick={() => dispatch(setOpenDublicateModal(true))}>
            <i>
              <ProgrammingArrowsIcon />
            </i>
          </span>
        </Tooltip>

        <Tooltip title={<span className={styles.container_tool_tip_title}>{t('list_view.Statusni_ozgartirish')}</span>} placement="bottom" align={{ offset: [1, -2] }}>
          <span className={styles.container_icon} onClick={() => handleChangePipeStatus(true)}>
            <i>
              <FolderOpenIcon />
            </i>
          </span>
        </Tooltip>

        <Tooltip title={<span className={styles.container_tool_tip_title}>{t('list_view.Topshiriq_yaratish')}</span>} placement="bottom" align={{ offset: [1, -2] }}>
          <span className={styles.container_icon} onClick={() => handleCreateTaskModal(true)}>
            <i>
              <TaskSquareIcon />
            </i>
          </span>
        </Tooltip>

        <Tooltip title={<span className={styles.container_tool_tip_title}>{t('list_view.Ochirish')}</span>} placement="bottom" align={{ offset: [1, -2] }}>
          <span className={styles.container_icon} onClick={() => handleDeleteLead(true)}>
            <i>
              <TrushWhiteIcon />
            </i>
          </span>
        </Tooltip>

        <Tooltip title={<span className={styles.container_tool_tip_title}>{t('list_view.Umumiy_teglar')}</span>} placement="bottom" align={{ offset: [1, -2] }}>
          <span className={styles.container_icon} onClick={() => handleBrickTag(true)}>
            <i>
              <TagTwoIcon />
            </i>
          </span>
        </Tooltip>

        <Tooltip title={<span className={styles.container_tool_tip_title}>{t('list_view.Chat_xabarini_yuborish')}</span>} placement="bottom" align={{ offset: [1, -2] }}>
          <span className={styles.container_icon} onClick={() => handleSendChatMessage(true)}>
            <i>
              <Message3Icon />
            </i>
          </span>
        </Tooltip>

        <Tooltip title={<span className={styles.container_tool_tip_title}>{t('client.Xabar_yuborish')}</span>} placement="bottom" align={{ offset: [1, -2] }}>
          <span className={styles.container_icon} onClick={() => handleSendMessage(true)}>
            <i>
              <SmsTrackingWhiteIcon />
            </i>
          </span>
        </Tooltip>
        {isAccessRobotCall && (
          <Tooltip title={<span className={styles.container_tool_tip_title}>{t('titles.roboto_calls')}</span>} placement="bottom" align={{ offset: [1, -2] }}>
            <span className={styles.container_icon} onClick={handleOpenRobotCall}>
              <i>
                <RobotoCallsIcon size={20} />
              </i>
            </span>
          </Tooltip>
        )}
      </div>
      <Tooltip title={<span className={styles.container_tool_tip_title}>{t('home.Belgilangan_lidlarni_tozalash')}</span>} placement="bottomRight" align={{ offset: [10, 8] }}>
        <div className="mr-[20px] cursor-pointer" onClick={() => handleClear()}>
          <i className={styles.clearIcon}>
            <CloseIconV2 />
          </i>
        </div>
      </Tooltip>
      <CreateTask ids={activeIds} visible={openCreteTask} close={handleCreateTaskModal} balance={balance} />
      <ChangeEmployee ids={activeIds} visible={openEmployeeChange} close={handleChangeEmployee} />
      <DeleteLead visible={openDeleteLead} close={handleDeleteLead} ids={activeIds} />
      <SendChatMessage visible={openSendChatMessage} close={handleSendChatMessage} ids={activeIds} />
      <SendMessageModal visible={openSendMessage} close={handleSendMessage} ids={activeIds} balance={balance} />
      <BrickTag visible={openBrickTag} close={handleBrickTag} ids={activeIds} balance={balance} />
      <ChangePipeStatus visible={openChangePipeStatus} close={handleChangePipeStatus} ids={activeIds} balance={balance} />
      <DublicateControllModal />
      {isAccessRobotCall && <CreateRobotCall place="crm" setVisible={setOpenRobotCall} visible={openRobotCall} />}
    </div>
  );
};

export default Actions;
