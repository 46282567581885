import React from 'react';

const UnionIcon = () => {
  return (
    <svg width="21" height="60" viewBox="0 0 21 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_23328_60610)">
        <g filter="url(#filter0_d_23328_60610)">
          <path
            d="M20 19.5122C20 15.4505 17.9454 11.6645 14.5399 9.45091L0 0V60L14.5399 50.5491C17.9454 48.3355 20 44.5495 20 40.4878V19.5122Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter id="filter0_d_23328_60610" x="-1" y="-1" width="22" height="62" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" /> {/* изменен цвет на зеленый */}
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_23328_60610" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_23328_60610" result="shape" />
        </filter>
        <clipPath id="clip0_23328_60610">
          <rect width="21" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UnionIcon;
