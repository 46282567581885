import { ResponsiveLine } from '@nivo/line';
import styles from './conversionBar.module.scss';
import { useTranslation } from 'react-i18next';
import { AnalyticRatio } from '@pages/boss/bi-analytics/utils/models/AnalyticRatio';
import { DATE_FORMAT } from '@utils/constants/format';
import dayjs from 'dayjs';

const ConversionBar = ({ conversionData, arrayOfMonths }: { conversionData: AnalyticRatio; arrayOfMonths: string[] }) => {
  const { t } = useTranslation();
  let newData: any = [];
  Object.entries(conversionData || {}).map(item => {
    newData.push({
      id: item?.[0] === 'currentCompany' ? t('bi_analytics.Konversiya') : t("bi_analytics.Mintaqadagi_o'rtacha_konversiya"),
      data: arrayOfMonths.map(month => ({
        x: t(`boss_payments.${dayjs(month, DATE_FORMAT).format('MMM')}`),
        y: item?.[1].find(el => el?.date === month) ? item?.[1].find(el => el?.date === month)?.ratio : 0
      }))
    });
  });
  return (
    <div className={styles.conversion_bar_container}>
      <ResponsiveLine
        data={newData || []}
        colors={['#699BF7', '#E74141']}
        margin={{ top: 30, right: 25, bottom: 52, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 0
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          format: value => `${value}%`
        }}
        pointSize={6}
        pointBorderWidth={2}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-left',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 52,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 190,
            itemHeight: 20,
            itemOpacity: 1,
            symbolSize: 12,
            symbolShape: 'circle'
          }
        ]}
        enableSlices="x"
      />
    </div>
  );
};

export default ConversionBar;
