import React, { FC, useEffect, useRef, useState } from 'react';
import { cx } from '@utils/cx';
import { FlatMore } from '@utils/models/FlatMore';
import { Button, message, Modal } from 'antd';
import queryString from 'query-string';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

import PrinterIcon from '../../../../assets/icons/PrinterIcon';
import PrintFlatInformation from '../../../../components/printFlatInformation/PrintFlatInformation';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { useRealtorConfigCheckPermission } from '../../../../queries/queries';
import { MainPaths } from '../../../../routing/main/mainPaths';
import { rootPaths } from '../../../../routing/rootPaths';
import { clear } from '../../../../store/reducers/CreateContractSlice';
import { Permissions } from '../../../../utils/constants/permissions';
import { BronIdOrFlatIds } from '../../../../utils/models/BronIdOrFlatIds';
import { ACTION_EVENTS_GA, TYPE_EVENTS_GA } from '../../../../utils/models/GoogleAnalitks';
import { useBookingLeadFlatCreate } from '../../services/mutation';
import MainPage from '../detailed/main-page/MainPage';

import styles from '../../homeId.module.scss';
import modal from '../detailed/navbar/navbar.module.scss';

interface FooterButtonsProps {
  idFlats?: number[];
  houseName?: string;
  flat: FlatMore | undefined;
}

const FooterButtons: FC<FooterButtonsProps> = ({ idFlats, houseName, flat }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [print, setPrint] = useState(false);
  const [beta2, setBeta2] = useState(false);
  const { user } = useAppSelector(state => state.userReducer);
  const { companyId } = useAppSelector(state => state.companyReducer);

  const { params, push, location, searchParams } = useQueryParam<
    {
      idFlat: string;
      nameHouse: string;
      company: string;
    },
    null
  >();

  const companyIdConverter = searchParams.company ? Number(atob(searchParams.company)) : companyId;

  const { data: realtorCheckPermission, isLoading: realtorCheckPermissionLoading } = useRealtorConfigCheckPermission(companyIdConverter);

  const bookingLeadFlatCreate = useBookingLeadFlatCreate();

  const { leadId } = queryString.parseUrl(location.search).query;

  const isPressButtons = useRef(false);

  const { t } = useTranslation();

  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const { currency, variation } = useAppSelector(state => state.createContract);

  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      if (!isPressButtons.current) {
        dispatch(clear());
      }
    },
    []
  );

  const isAccessBooking = permissions?.includes(Permissions.PERMISSION_CREATE_BOOKING);

  const isAccessCreateContract = permissions?.includes(Permissions.PERMISSION_CREATE_CONTRACT) && companyIdConverter === user?.companyId;
  const isMultiPayment = permissions?.includes(Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT);
  const isMultiPaymentEdit = permissions?.includes(Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT);

  const goToCreateContractPage = () => {
    isPressButtons.current = true;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (idFlats) goToCreateContractPageInsideShowroomEvent();
    let url = rootPaths.MAIN + MainPaths.CONTRACT;

    if (params?.idFlat) {
      url += `/${params?.idFlat}${MainPaths.CLIENT_INFO}`;
    } else {
      url += `/${BronIdOrFlatIds.FLAT_IDS}=`;
      idFlats?.forEach((id, index) => {
        if (index === 0) url += `${id}`;
        else url += `,${id}`;
      });
      url += `${MainPaths.CLIENT_INFO}`;
    }
    push(url);
  };

  const goToCreateOrder = () => {
    if (idFlats) {
      if (idFlats.length === 1) {
        push(`${rootPaths.MAIN + MainPaths.INDEX}/${idFlats[0]}/${houseName}${`${MainPaths.ORDER + MainPaths.CREATE_ORDER}?showroom=true&currency=${currency?.id}`}`);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        goToBronPageInsideShowroomEvent();
      } else {
        message.info('Bu funksiya ishlab chiqilmoqda');
      }
    } else {
      const ids = params?.idFlat.split('=')[1].split(',');

      if (ids.length === 1) {
        push(`${rootPaths.MAIN + MainPaths.INDEX}/${ids[0]}/${params.nameHouse}${MainPaths.ORDER + MainPaths.CREATE_ORDER}?currency=${currency?.id}`);
      } else {
        message.info('Bu funksiya ishlab chiqilmoqda');
      }
    }
  };

  // go to bron page inside showroom events
  const goToBronPageInsideShowroomEvent = () => {
    ReactGA.event({
      category: TYPE_EVENTS_GA.SHOWROOM,
      action: ACTION_EVENTS_GA.GO_TO_BRON_PAGE_INSIDE_SHOWROOM
    });
  };

  // go to create contract page inside showroom events
  const goToCreateContractPageInsideShowroomEvent = () => {
    ReactGA.event({
      category: TYPE_EVENTS_GA.SHOWROOM,
      action: ACTION_EVENTS_GA.GO_TO_CREATE_CONTRACT_INSIDE_SHOWROOM
    });
  };

  const handleToLead = () => {
    const prevUrl: any = queryString?.parseUrl(location?.search)?.query?.prev;
    const prev = atob(prevUrl);

    bookingLeadFlatCreate
      .mutateAsync({
        flatId: idFlats || [],
        leadId: Number(leadId)
      })
      .then(() => {
        push(prev);
      });
  };

  // handle print
  const handlePrint = (isPrint: boolean) => {
    setPrint(isPrint);
  };

  //

  const showModal = (beta2: boolean) => {
    setIsModalOpen(true);
    setBeta2(beta2);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={styles.home_id_content_footer}>
        <div className="d-flex">
          <Button onClick={() => handlePrint(true)} className={styles.home_id_content_footer_print_button} ghost type="primary">
            <PrinterIcon />
            <span> {t('home_id.Chop_etish_reja')}</span>
          </Button>
          <Button onClick={() => showModal(false)} className={styles.print} loading={bookingLeadFlatCreate.isLoading}>
            <PrinterIcon />
            <span>{t(`home_id.Chop_etish`)}</span>
            <h5 className={styles.beta}>BETA</h5>
          </Button>
          <Button onClick={() => showModal(true)} className={cx(styles.print, 'ml-[8px]')} loading={bookingLeadFlatCreate.isLoading}>
            <PrinterIcon />
            <span>{t(`home_id.Chop_etish`)}</span>
            <h5 className={styles.beta}>BETA 2</h5>
          </Button>
          <Modal footer={false} onCancel={handleCancel} visible={isModalOpen} className={modal.modal} centered width={1420}>
            <MainPage beta2={beta2} handleCancel={handleCancel} idFlats={idFlats} visible={isModalOpen} />
          </Modal>
        </div>
        {/* <button onClick={()=> new Error("This is test error")}>Error</button> */}
        {flat?.flat?.status !== 'BOOKED' ? (
          <div>
            {leadId && (
              <Button
                type="primary"
                className={styles.home_id_content_footer_cancel_button}
                style={{ marginRight: 16 }}
                onClick={handleToLead}
                loading={bookingLeadFlatCreate.isLoading}
              >
                Leadga biriktirish
              </Button>
            )}
            {isAccessBooking && (
              <Button onClick={goToCreateOrder} type="primary" className={styles.home_id_content_footer_cancel_button}>
                {t('home_id.Buyurtma_berish')}
              </Button>
            )}
            {isAccessCreateContract && realtorCheckPermission && (
              <Button
                onClick={goToCreateContractPage}
                className={styles.home_id_content_footer_next_button}
                type="primary"
                loading={realtorCheckPermissionLoading}
                disabled={isMultiPayment && !isMultiPaymentEdit ? !variation : false}
              >
                {t('home_id.Shartnoma_yaratish')}
              </Button>
            )}
          </div>
        ) : (
          <div>
            <Button
              onClick={() => {
                push(`${rootPaths.MAIN + MainPaths.ORDER}/${flat.bookingId}/№${encodeURIComponent(String(flat.bookingNumber))}`);
              }}
              className={styles.home_id_content_footer_next_button}
              type="primary"
              disabled={!flat.bookingId}
              style={{ background: '#ffb400' }}
            >
              {t('showroom.Booking')} №{flat.bookingNumber}
            </Button>
          </div>
        )}
      </div>
      <PrintFlatInformation print={print} close={handlePrint} idFlats={idFlats} />
    </>
  );
};

export default FooterButtons;
