import React, { useState } from 'react';
import CheckIcon from '@icons/CheckIcon';
import { useDownloadDraftExcel } from '@pages/contract/services/mutation';
import { useHousesNameAndID } from '@queries/queries';
import DatePicker from '@service/datePicker';
import { DATE_FORMAT } from '@utils/constants';
import { Dropdown, Form, Modal, Radio } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

interface IProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExelModal: React.FC<IProps> = ({ visible, setVisible }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { data: houses } = useHousesNameAndID();

  const [values, setValues] = useState<string[] | null>(null);
  const [houseIds, setHouseIds] = useState<number[]>([]);

  const downloadDraftExcel = useDownloadDraftExcel();

  const handleSetHouseId = (id: number) => {
    if (houseIds?.includes(id)) {
      const newHouseIds = houseIds?.filter(item => item !== id);

      setHouseIds(newHouseIds);
    } else {
      setHouseIds(() => [...houseIds!, id]);
    }
  };

  const handleSetAllHouses = () => {
    if (houseIds?.length === houses?.length) {
      setHouseIds([]);
    } else {
      const newHouseIds = houses?.map(item => item?.id!);

      setHouseIds(newHouseIds!);
    }
  };

  const houseNames = houses?.filter(item => houseIds?.includes(item?.id!));

  const handleClose = () => {
    setVisible(false);
    form.resetFields();
    setValues(null);
    setHouseIds([]);
  };

  const onFinish = (val: { repaired: string | null }) => {
    downloadDraftExcel
      .mutateAsync({
        finishDate: values![1],
        startDate: values![0],
        // eslint-disable-next-line no-nested-ternary
        repaired: val?.repaired === 'repaired' ? true : val?.repaired === 'not_repaired' ? false : null,
        houses: houseIds
      })
      .then(handleClose);
  };

  const onOk = () => form.submit();

  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      onOk={onOk}
      okText={t('client_balance.Yuklab_olish')}
      cancelText={t('boss_crm.Yopish')}
      okButtonProps={{ style: { borderRadius: 50 }, disabled: values === null }}
      cancelButtonProps={{ style: { borderRadius: 50 } }}
      closeIcon={<></>}
      width={400}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item label="Sana" rules={[{ required: true, message: '' }]}>
          {/* @ts-ignore */}
          <RangePicker
            className="w-full rounded-md"
            placeholder={[t('boss_complex.Boshlanish'), t('boss_complex.Tugash')]}
            value={values ? [dayjs(values[0], DATE_FORMAT), dayjs(values[1], DATE_FORMAT)] : null}
            format={DATE_FORMAT}
            onChange={(date, dateString) => {
              setValues([dateString[0], dateString[1]]);
            }}
          />
        </Form.Item>
        <Form.Item label={t('home.Tamirli_holati')} name="repaired" className="!mb-3">
          <Radio.Group defaultValue="all">
            <Radio value="all">{t('home.Barchasi')}</Radio>
            <Radio value="repaired">{t('home.Tamirli')}</Radio>
            <Radio value="not_repaired">{t('home.Tamirsiz')}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="houses" label={t('home.Turar_joy_majmuasi')} className="!mb-0">
          <Dropdown
            overlay={
              <div className="max-h-[300px] w-full overflow-auto rounded-md bg-white p-1" style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)' }}>
                <div
                  className="mb-1 flex cursor-pointer items-center justify-between rounded-md px-2 py-1 hover:bg-slate-200"
                  style={{ backgroundColor: houseIds?.length === houses?.length ? '#e2e8f0' : '' }}
                  onClick={handleSetAllHouses}
                >
                  <span>{t('home.Barchasi')}</span>
                  {houseIds?.length === houses?.length && <CheckIcon size={16} stroke="#007aff" />}
                </div>
                {houses?.map(item => (
                  <div
                    className="mb-1 flex cursor-pointer items-center justify-between rounded-md px-2 py-1 transition-all hover:bg-slate-200"
                    key={item?.id}
                    onClick={() => handleSetHouseId(item?.id!)}
                    style={{ backgroundColor: houseIds?.includes(item?.id!) ? '#e2e8f0' : '' }}
                  >
                    <span>{item?.name}</span>
                    {houseIds?.includes(item?.id!) && <CheckIcon size={16} stroke="#007aff" />}
                  </div>
                ))}
              </div>
            }
            trigger={['click', 'hover']}
            mouseEnterDelay={1000}
            mouseLeaveDelay={0.3}
          >
            {houseIds?.length > 0 ? (
              <div className="flex h-8 cursor-pointer items-center justify-between rounded-md border border-solid border-[#d9d9d9] px-2">
                <div className="w-[90%] overflow-hidden text-ellipsis whitespace-nowrap">
                  {houseIds?.length === houses?.length ? 'Barchasi' : houseNames?.map(item => item?.name)?.join(', ')}
                </div>
                {houseIds?.length > 0 && <div className="flex min-h-6 min-w-6 items-center justify-center rounded-full bg-[#007aff] text-white">{houseIds?.length}</div>}
              </div>
            ) : (
              <div className="flex h-8 cursor-pointer items-center justify-between rounded-md border border-solid border-[#d9d9d9] px-2 text-gray">{t('home.Tanlang')}</div>
            )}
          </Dropdown>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ExelModal;
