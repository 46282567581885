import axios, { AxiosRequestConfig } from 'axios';

import { authEndpoints } from '../utils/constants/apis';
import { REDIRECT_URL, TOKEN_KEY, USER_KEY } from '../utils/constants/localStorageKeys';
import { User } from '../utils/models/User';

import { LocalStorage } from './LocalStorage';

export const BASE_URL = import.meta.env.VITE_BASE_URL;
export const BASE_URL_VISUAL = import.meta.env.VITE_BASE_URL_VISUAL;

let isRefreshing = false;
let failedQueue: any = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const addToken = (config: AxiosRequestConfig) => {
  const token = LocalStorage.get<string>(TOKEN_KEY);

  if (token) {
    config.headers!.Authorization = `Bearer ${token}`;
  }
  return config;
};

const resentRequest = async (err: any) => {
  const originalRequest = err?.config;

  // eslint-disable-next-line no-underscore-dangle
  if (err.response.status === 401 && !originalRequest._retry) {
    if (!LocalStorage.get<string>(TOKEN_KEY)) {
      LocalStorage.set(REDIRECT_URL, window.location.href);
      window.location.replace(window.location.origin);
    }
    if (isRefreshing) {
      // If I'm refreshing the token I send request to a queue
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then(token => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axios(originalRequest);
        })
        .catch(err => {
          // empty
        });
    }
    // If header of the request has changed, it means I've refreshed the token
    const token = LocalStorage.get<string>(TOKEN_KEY);

    if (originalRequest.headers.Authorization !== `Bearer ${token}`) {
      originalRequest.headers.Authorization = `Bearer ${token}`;
      return Promise.resolve(axios(originalRequest));
    }
    // eslint-disable-next-line no-underscore-dangle
    originalRequest._retry = true; // mark request a retry
    isRefreshing = true; // set the refreshing var to true

    // If none of the above, refresh the token and process the queue
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL + authEndpoints.REFRESH, {
          withCredentials: true
        }) // The method that refreshes my token
        .then(response => {
          const user = LocalStorage.get(USER_KEY) as User;
          let isEqual = true;
          const resCopy: User = response.data?.data;
          const branch = resCopy?.roleTokens?.find(item => item?.branch?.id === user?.branchId);

          if (typeof user === 'object') {
            if (user?.role?.permissions.length !== response.data?.data?.role?.permissions.length) {
              isEqual = false;
            }
            user?.role?.permissions.forEach(permission => {
              if (response.data?.data?.role?.permissions.indexOf(permission) === -1) {
                isEqual = false;
              }
            });
          }
          LocalStorage.set(TOKEN_KEY, response.data?.data?.accessToken || branch?.accessToken); // The method that sets my token to localstorage/Redux/whatever
          processQueue(null, response.data?.data?.accessToken || branch?.accessToken); // Resolve queued
          if (!isEqual) {
            LocalStorage.set(USER_KEY, JSON.stringify({ ...response.data?.data, role: branch?.role, branchId: user?.branchId }));
            window.location.reload();
          }
          originalRequest.headers.Authorization = `Bearer ${response.data?.data?.accessToken || branch?.accessToken}`;
          resolve(axios(originalRequest)); // Resolve current
        })
        .catch(err => {
          LocalStorage.remove(TOKEN_KEY); // The method that removes my token from localstorage/Redux/whatever
          LocalStorage.remove(USER_KEY);
          LocalStorage.set(REDIRECT_URL, window.location.pathname);
          window.location.replace(window.location.origin);
          processQueue(err, null);
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  }
  throw err;
};

export const $api = axios.create({ baseURL: BASE_URL });

export const $apiCustom = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  withCredentials: true,
});

$api.interceptors.request.use(addToken);

$api.interceptors.response.use(undefined, resentRequest);

export const $apiVisual = axios.create({ baseURL: BASE_URL_VISUAL });
