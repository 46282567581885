import React, { useState } from 'react';
import { Table } from 'antd';

import EditIcon from '../../assets/icons/EditIcon';
import { useNotification } from '../../queries/queries';
import PaginationCont from '../pagenation/PaginationCont';

import NotificationDeveloperCreate from './notificationDeveloperCreate/NotificationDeveloperCreate';
import NotificationDeveloperHeader from './notificationDeveloperHeader/NotificationDeveloperHeader';

import styles from './notificationDev.module.scss';

const NotificationDev = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { data, isLoading } = useNotification(page, 10, search);
  const [openModal, setOpenModal] = useState({
    visible: false,
    id: 0
  });

  const handleEdit = (id: number) => {
    setOpenModal({ id, visible: true });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => (
        <span className={styles.container_editTable} onClick={() => handleEdit(id)}>
          <EditIcon />
        </span>
      )
    }
  ];

  const changePage = (page: number) => {
    setPage(page);
  };

  return (
    <div className={styles.container}>
      <NotificationDeveloperHeader setVisible={setOpenModal} setSearch={setSearch} />
      <div className={styles.container_table}>
        <Table dataSource={data?.data} className="custom_table" columns={columns} pagination={false} loading={isLoading} />
      </div>
      <div className={styles.container_pagination}>
        <PaginationCont changePage={changePage} currentPage={page} defaultPageSize={1} total={data?.totalPages!} totalPages={data?.totalElements!} />
      </div>
      <NotificationDeveloperCreate visible={openModal.visible} id={openModal.id} setVisible={setOpenModal} />
    </div>
  );
};

export default NotificationDev;
