import React from 'react';

const ProgressDownIcon = () => {
  return (
    <svg width="26" height="15" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.016 2.05842C25.3166 1.77346 25.3293 1.29875 25.0443 0.998139C24.7593 0.697525 24.2846 0.684836 23.984 0.9698L25.016 2.05842ZM16.9516 8.66949L16.4174 9.19589C16.7041 9.48691 17.1711 9.49487 17.4676 9.2138L16.9516 8.66949ZM11.4091 3.04449L11.9433 2.51809C11.8001 2.37274 11.6039 2.29202 11.3998 2.29455C11.1958 2.29707 11.0016 2.38261 10.862 2.53145L11.4091 3.04449ZM0.841297 13.5382C0.854591 13.9522 1.20098 14.277 1.61498 14.2637L8.3615 14.0471C8.7755 14.0338 9.10034 13.6874 9.08705 13.2734C9.07375 12.8594 8.72736 12.5346 8.31336 12.5479L2.31645 12.7404L2.12389 6.74352C2.1106 6.32952 1.76421 6.00468 1.35021 6.01797C0.936211 6.03127 0.611374 6.37766 0.624666 6.79166L0.841297 13.5382ZM14.1804 5.85699L13.6461 6.38339L14.1804 5.85699ZM23.984 0.9698L16.4356 8.12518L17.4676 9.2138L25.016 2.05842L23.984 0.9698ZM10.862 2.53145L1.04383 13.0011L2.13799 14.0271L11.9562 3.55753L10.862 2.53145ZM17.4858 8.14309L14.7146 5.33059L13.6461 6.38339L16.4174 9.19589L17.4858 8.14309ZM14.7146 5.33059L11.9433 2.51809L10.8749 3.57089L13.6461 6.38339L14.7146 5.33059Z"
        fill="#E74141"
      />
    </svg>
  );
};

export default ProgressDownIcon;
