import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { SearchParamsCrm } from '../components/filter/FilterButton';
import { CrmLeadList, CrmLeadListV2 } from '../../../utils/models/CrmLeadList';
import { $api } from '../../../service/RequestService';
import { getCrmBody } from '../../../utils/helper/getSearchAndDownloadBodyAllTables';
import { queryKeys } from '../utils/constants/queryKeys';
import { endPoints } from '../utils/constants/endPoints';
import { TriggerList } from '../../../utils/models/Trigger';
import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { ReasonsRefusal } from '../../../utils/models/ReasonsRefusal';
import { OneLid } from '../../../utils/models/OneLid';
import queryString from 'query-string';
import { CrmUserRoleModel } from '../utils/models/CrmUserRoleModel';

// Lead filter
export function useLeadFilter(pipeStatusId: number, searchParams: SearchParamsCrm, enabled?: boolean) {
  const qc = useQueryClient();
  return useInfiniteQuery<CrmLeadListV2>(
    [queryKeys.CRM_LEAD_FILTER, pipeStatusId, searchParams],
    async ({ pageParam = 1 }) => {
      if (enabled) {
        const res = await $api.post(endPoints.LEAD_FILTER_V2, {
          ...getCrmBody(searchParams),
          page: pageParam,
          size: 20,
          pipeStatusIds: [pipeStatusId]
        });
        return res.data.data;
      } else {
        const scrollDataState = qc.getQueryData([queryKeys.CRM_LEAD_FILTER, pipeStatusId, searchParams]) as any;

        return scrollDataState?.pages[0] || undefined;
      }
    },
    {
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage?.leadList?.totalPages) {
          return page.length + 1;
        } else {
          return undefined;
        }
      },
      refetchInterval: Infinity,
      refetchOnWindowFocus: false
    }
  );
}

export function useTriggersList(pipeStatusId: number) {
  return useQuery<TriggerList[]>(
    [queryKeys.TRIGGERS_LIST, pipeStatusId],
    async () => {
      const res = await $api.get(endPoints.CRM_TRIGGERS + `?pipeStatusId=${pipeStatusId}`);
      return res.data.data;
    },
    {
      enabled: !!pipeStatusId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useReasonsForRefusal() {
  return useQuery<ReasonsRefusal[]>(
    [queryKeys.REASONS_FOR_REFUSAL],
    async () => {
      const res = await $api.get(endPoints.REASONS_FOR_REFUSAL);
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useLeadList(pipeId: number, searchParams: SearchParamsCrm) {
  return useQuery<CrmLeadList>(
    [queryKeys.CRM_LEAD_LIST, pipeId, JSON.stringify(searchParams)],
    async () => {
      const res = await $api.post(endPoints.LEAD_TABLE_FILTERS_V2, {
        ...getCrmBody(searchParams, true),
        pipeId
      });
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchInterval: 60000,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useLeadListIds(pipeId: number, searchParams: SearchParamsCrm) {
  return useQuery<{ totalBalance: number; leadIds: number[] }>(
    [queryKeys.CRM_LEAD_LIST_IDS, pipeId, JSON.stringify(searchParams)],
    async () => {
      const res = await $api.post(endPoints.LEAD_TABLE_FILTERS_LEAD_IDS, {
        ...getCrmBody(searchParams, true),
        pipeId
      });
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchInterval: 60000,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useCrmLeadBy(ids: number[]) {
  return useQuery<OneLid[]>(
    [queryKeys.SEARCH_LEAD_EXTRA, ids],
    async () => {
      const res = await $api.get(endPoints.LEAD_BY + `?${queryString.stringify({ leadIds: ids })}`);
      return res.data.data.sort(function (a: any, b: any) {
        return ids.indexOf(a.id) - ids.indexOf(b.id);
      });
    },
    {
      enabled: !!(ids.length > 0),
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useGetCrmCurrentUserRole() {
  return useQuery<CrmUserRoleModel>(
    [queryKeys.CRM_CURRENT_USER_ROLE],
    async () => {
      const res = await $api.get(endPoints.CRM_CURRENT_USER_ROLE);
      return res.data.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}
