import React, { useRef, useState } from 'react';
import styles from './importExport.module.scss';
import ImportDropZone from './ImportDropZone';
import ImportTable from './ImportTable';
import ImportModal from './ImportModal';
import { message } from 'antd';
import { $api } from '../../service/RequestService';
import { transferDataEndpoints } from '../../utils/constants/apis';
import { queryNames } from '../../utils/constants/queryNames';
import { baseErrorHandler } from '../../queries/baseErrorHandler';
import axios from 'axios';
import { useQueryClient } from 'react-query';
import ImportingComponent from './ImportingComponent';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { changeSocketStatus, showTransferModal } from '../../store/reducers/TransferDataSlice';
import { useTranslation } from 'react-i18next';

const ImportCont = () => {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const uploadingFile = useRef<File>();
  const cancelSource = useRef(axios.CancelToken.source());
  const qc = useQueryClient();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleCancelUpload = () => {
    cancelSource.current.cancel();
    uploadingFile.current = undefined;
    setProgress(0);
    setIsUploading(false);
  };

  const handleUpload = async (file: File) => {
    uploadingFile.current = file;
    setIsUploading(true);
    const formData = new FormData();
    formData.append('data ', file);
    try {
      await $api.post(transferDataEndpoints.INDEX, formData, {
        onUploadProgress: progressEvent => {
          const { loaded, total } = progressEvent;
          setProgress(Math.round((loaded / total) * 100));
          if (loaded === total) {
            dispatch(showTransferModal());
          }
        },
        cancelToken: cancelSource.current.token
      });
      dispatch(changeSocketStatus(false));
      setIsUploading(false);
      await qc.invalidateQueries([queryNames.TRANSFER_DATA_LIST]);
    } catch (error) {
      dispatch(changeSocketStatus(false));
      setIsUploading(false);
      await qc.invalidateQueries([queryNames.TRANSFER_DATA_LIST]);
      baseErrorHandler(error);
    }
  };

  const handleDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length === 1) {
      const type = acceptedFiles[0].name.split('.')[1];
      if (type === 'xlsx') {
        handleUpload(acceptedFiles[0]);
      } else {
        message.error(t('company_config.Fayl_formati_notogri'));
      }
    } else {
      message.error(t('company_config.Bittadan_ortiq_fayl_tanlangan'));
    }
  };

  return (
    <div className={styles.main_body}>
      {isUploading ? (
        <ImportingComponent onCancel={handleCancelUpload} file={uploadingFile.current as any} uploadProgress={progress} />
      ) : (
        <ImportDropZone handleDrop={handleDrop} />
      )}
      <ImportTable />
      <ImportModal />
    </div>
  );
};

export default ImportCont;
