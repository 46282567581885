import React, { useEffect } from 'react';
import { Button, Divider, Form, Grid, Input, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import CalendarIcon from '../../assets/icons/CalendarIcon';
import PaginationNext from '../../assets/icons/PaginationNext';
import PaginationPrev from '../../assets/icons/PaginationPrev';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useChangeTableMonthlyPayments } from '../../queries/mutation';
import DatePicker from '../../service/datePicker';
import { setDelay, setDelayInterval, setFirstPaymentDate } from '../../store/reducers/MonthlyPaymentsSlice';
import { DATE_FORMAT } from '../../utils/constants/format';
import { helper } from '../../utils/helper/helper';

import styles from '../../pages/contractTableUpdate/contractTableUpdate.module.scss';

interface FormValues {
  delay: number;
  delayInterval: number;
  cancelText: string;
}

interface Props {
  date: string;
}

const MainInputs: React.FC<Props> = ({ date }) => {
  const [form] = Form.useForm();
  const { xxl } = Grid.useBreakpoint();

  const { t } = useTranslation();
  const {
    contractNumber,
    amount,
    firstPaymentDate,
    delayInterval,
    clientName,
    delay,
    monthlyPaymentPartDtos,
    contractId,
    residueTablePayments,
    currency
  } = useAppSelector(state => state.monthlyPaymentsReducer);

  const changeTable = useChangeTableMonthlyPayments(contractId, contractNumber);

  const dispatch = useAppDispatch();

  function disabledDate(current: Dayjs) {
    return current && current < dayjs(date, DATE_FORMAT);
  }

  useEffect(() => {
    form.setFieldsValue({
      delay,
      delayInterval
    });
  }, [delay, delayInterval, form]);

  const incrementAndDecrement = (type: 'increment' | 'decrement') => {
    if (type === 'increment') {
      const nextDelay = (delay || 0) + 1;

      dispatch(setDelay(nextDelay));
      form.setFieldsValue({ delay: nextDelay });
    } else {
      if (delay && delay <= 1) return;
      const nextDelay = (delay || 2) - 1;

      dispatch(setDelay(nextDelay));
      form.setFieldsValue({ delay: nextDelay });
    }
  };

  const handleChangeDelay = (e: any) => {
    const delay = helper.deleteNotNumbers(e.target.value, null);

    dispatch(setDelay(delay));
  };

  const handleChangeDelayInterval = (e: any) => {
    const delayInterval = helper.deleteNotNumbers(e.target.value, null);

    dispatch(setDelayInterval(delayInterval));
  };

  const changeFirstPaymentDate = (date: string) => {
    dispatch(setFirstPaymentDate(date));
  };

  const handleSuccess = (e: FormValues) => {
    if (residueTablePayments === 0) {
      changeTable.mutate({
        cancelText: e.cancelText,
        monthlyPaymentPartDtos: monthlyPaymentPartDtos || [],
        contractId: contractId || 0
      });
    } else {
      message.error(t('create_table_payments.Tolov_jadvali_xato_toldirilgan'));
    }
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSuccess}
        name="tableMonthlyPayments"
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: false }}
        autoComplete="off"
      >
        <div>
          <h1>{t('contract_table_update.Shartnoma_raqami')}</h1>
          <p>{contractNumber}</p>
        </div>
        <div>
          <h1>{t('client.Mijoz')}</h1>
          <p>{clientName}</p>
        </div>
        <div>
          <h1>{t('contract_table_update.Qolgan_summa')}</h1>
          <p>
            {amount?.toLocaleString('ru')} {currency?.ccy}
          </p>
        </div>
        <Divider style={{ marginBottom: 25 }} />
        <div>
          <h1>{t('contract_table_update.Tolov_muddati_oy')}</h1>
          <div>
            <span onClick={() => incrementAndDecrement('decrement')}>
              <PaginationPrev />
            </span>
            <Form.Item style={{ height: '20px' }} name="delay" rules={[{ required: true, message: '' }]}>
              <Input onChange={handleChangeDelay} className={styles.inputDelay} min={1} type="number" />
            </Form.Item>
            <span onClick={() => incrementAndDecrement('increment')}>
              <PaginationNext />
            </span>
          </div>
        </div>
        <div>
          <h1>{t('contract_table_update.Boshlangich_tolov_sanasi')}</h1>
          <DatePicker
            disabledDate={disabledDate}
            onChange={(date, dateString) => changeFirstPaymentDate(dateString)}
            placeholder={t('client_create_contract.kk_oo_yyyy')}
            allowClear={false}
            value={firstPaymentDate ? dayjs(firstPaymentDate, DATE_FORMAT) : null}
            size="small"
            style={{
              borderRadius: '6px',
              width: 240,
              height: xxl ? 35 : 35 * 0.7
            }}
            suffixIcon={<CalendarIcon />}
            format={DATE_FORMAT}
          />
        </div>
        <div>
          <h1>{t('contract_table_update.Vaqt_oraligi_oy')}</h1>
          <div>
            <Form.Item style={{ height: '20px' }} name="delayInterval" rules={[{ required: true, message: '' }]}>
              <Input className={styles.inputDelayInterval} min={1} type="text" onChange={handleChangeDelayInterval} />
            </Form.Item>
          </div>
        </div>
        <Divider style={{ marginBottom: 25 }} />
        <div>
          <h1>{t('contract.Izoh')}</h1>
        </div>
        <Form.Item className="textareaMainInput" name="cancelText" rules={[{ required: true, message: '' }]}>
          <Input.TextArea className={styles.inputCancelText} placeholder={t('contract_id.Izoh_kiriting')} autoSize={{ minRows: 3, maxRows: 3 }} />
        </Form.Item>
        <Button loading={changeTable.isLoading} type="primary" htmlType="submit">
          {t('arrearage.Tasdiqlash')}
        </Button>
      </Form>
    </div>
  );
};

export default MainInputs;
