import React from 'react';
import ClientMainData from './ClientMainData';
import styles from '../../../pages/clientCreateContract/clientCreateContract.module.scss';

const Juridical = () => {
  return (
    <div className={styles.client_cont}>
      <ClientMainData />
    </div>
  );
};

export default Juridical;
