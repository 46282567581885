/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useAppSelector } from '@hooks/reduxHooks';
import SearchIcon from '@icons/SearchIcon';
import { usePaymentFieldGet } from '@pages/setting/payment-changes/services/queries';
import { Permissions } from '@utils/constants/permissions';
import { tableExamples } from '@utils/constants/tableExamples';
import { Dropdown, Input } from 'antd';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import ArrowLeftMainHeader from '../../assets/icons/ArrowLeftMainHeader';
import ArrowLineDownIcon from '../../assets/icons/ArrowLineDownIcon';
import BankIcon from '../../assets/icons/BankIcon';
import ArrowIosDownIcon from '../../assets/icons/contract/ArrowIosDownIcon';
import ExcelIcon from '../../assets/icons/ExcelIcon';
import FilterIcon from '../../assets/icons/FilterIcon';
import NoteAddIcon from '../../assets/icons/NoteAddIcon';
import PlusCircleIcon from '../../assets/icons/PlusCircleIcon';
import ReceiveSquareIcon from '../../assets/icons/ReceiveSquareIcon';
import Setting3Icon from '../../assets/icons/Setting3Icon';
import TableConfigDrawer from '../../components/filters/TableConfigDrawer';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import PaymentFilterDriver from '../../components/payment/PaymentFilterDriver';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useContractId, useTableConfig } from '../../queries/queries';
import { MainPaths } from '../../routing/main/mainPaths';
import { rootPaths } from '../../routing/rootPaths';
import { TableKey } from '../../utils/models/TableKey';
import ContractIdDownload from '../contractIdV2/components/contract-id-header/ContractIdDownload';
import { SearchParamsPayment } from '../payment/Payment';

import PaymentClient from './components/payment-client/PaymentClient';
import PyamentContractMoney from './components/payment-contract-money/PyamentContractMoney';
import PaymentContractTable from './components/payment-contract-table/PaymentContractTable';
import PaymentHistoryTable from './components/payment-history-table/PaymentHistoryTable';
import PaymentMoney from './components/payment-money/PaymentMoney';
import PaymentPagination from './components/payment-pagination/PaymentPagination';
import { useDownloadContractAct, useDownloadPaymentsToExcel } from './services/mutation';
import { usePaymentsList } from './services/queries';
import { updateTableConfig } from './utils/helpers/updatedTableConfig';

import styles from './paymentV2.module.scss';

const PaymentV2 = () => {
  const { t } = useTranslation();
  const { searchParams, appendMultipleDifferent, push, appendSingle, remove } = useQueryParam<SearchParamsPayment, any>();
  const [visibleDownloadMenu, setVisibleDownloadMenu] = useState(false);
  const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);
  const [countFilters, setCountFilters] = useState(0);
  const [visibleDriver, setVisibleDriver] = useState(false);
  const { data: customFields } = usePaymentFieldGet();

  const { data: paymentFields } = usePaymentFieldGet();
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const { data: tableConfig, isFetching: tableConfigFetch } = useTableConfig(TableKey.payment);

  // console.log('tableConfig', tableConfig);
  // console.log('tableConfigFetch', tableConfigFetch);

  const { data: contractIdData } = useContractId(Number(searchParams?.contractId || 0));
  const { contractId } = searchParams;
  const tableSize = tableConfig?.size || 10;
  const { isLoading, data } = usePaymentsList(tableSize, searchParams);
  const download = useDownloadPaymentsToExcel(tableSize, searchParams);
  const downloadAct = useDownloadContractAct(Number(contractId) || -1);

  // handleClick
  const handleClick = () => {
    setFilterDrawerVisible(true);
  };

  // handleClick
  const handleSettingTableClick = (visible: boolean) => {
    setVisibleDriver(visible);
  };

  // changePage
  const changePage = (page: number) => {
    appendMultipleDifferent(['page', 'size'], [page, tableSize]);
  };

  // handleAkt
  const handleAkt = () => {
    downloadAct.mutate();
  };
  const updatedTableConfigData = updateTableConfig(tableConfig?.config || tableExamples.payment, customFields!);

  useEffect(() => {
    let count = 0;

    if (searchParams.houses) {
      if (Array.isArray(searchParams.houses)) {
        count = searchParams.houses.length;
      } else {
        count = 1;
      }
    }
    if (searchParams.types) {
      if (Array.isArray(searchParams.types)) {
        count = searchParams.types.length;
      } else {
        count = 1;
      }
    }
    if (searchParams.bases) {
      if (Array.isArray(searchParams.bases)) {
        count += searchParams.bases.length;
      } else {
        count += 1;
      }
    }
    if (searchParams.currencies) {
      if (Array.isArray(searchParams.currencies)) {
        count += searchParams.currencies.length;
      } else {
        count += 1;
      }
    }
    if (searchParams.startDate && searchParams.finishDate) {
      count += 1;
    }
    if (searchParams.search) {
      count += 1;
    }
    if (searchParams.canceled) {
      count += 1;
    }

    setCountFilters(count);
  }, [searchParams]);

  // downloadExcel
  const downloadExcel = () => {
    download.mutate();
    setVisibleDownloadMenu(false);
  };

  // handlePayment
  const handlePayment = () => {
    push({
      search: queryString.stringify({
        ...searchParams,
        contractId: 0
      })
    });
  };

  const historyBack = () => {
    const searchPr = searchParams;

    delete searchParams.contractId;

    push({
      pathname: rootPaths.MAIN + MainPaths.PAYMENT,
      search: queryString.stringify(searchPr)
    });
  };

  const handleSearch = (value: string) => {
    setTimeout(() => {
      if (value) appendSingle('search', value);
      else remove('search', undefined);
    });
  };

  const isAccessPayment = permissions?.includes(Permissions.PERMISSION_CREATE_PAYMENT);

  return (
    <>
      <MainHeader>
        <h1 className={styles.container_title}>
          {contractId && <ArrowLeftMainHeader onClick={historyBack} />} {t('one_lid.To’lov')}
        </h1>
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <div className={styles.container}>
        <div className={styles.container_header}>
          <div className={styles.container_header_left}>
            {!contractId && (
              <div className={styles.container_header_item} onClick={handleClick}>
                <div className={styles.container_header_item_content}>
                  <FilterIcon size={20} /> {t('crm_filter.Filtr')}
                  {countFilters > 0 && <span className={styles.container_header_item_content_badge}>{countFilters}</span>}
                </div>
              </div>
            )}
            {Number(contractId || 0) ? (
              <div className={styles.container_header_item} onClick={handleAkt}>
                <div className={styles.container_header_item_content}>
                  <NoteAddIcon />
                  <p className={styles.downloadAkt_label}>{t('company_config.Akt')}</p>
                  {downloadAct.isLoading && <LoadingOutlined />}
                </div>
              </div>
            ) : (
              ''
            )}
            {contractId ? (
              <ContractIdDownload data={contractIdData}>
                <div className={styles.container_header_item}>
                  <div className={styles.container_header_item_content}>
                    <ReceiveSquareIcon size={20} stroke="#444444" strokeWidth={1.5} />
                    <p className={styles.downloadAkt_label}>{t('client_balance.Yuklab_olish')}</p>
                    <ArrowIosDownIcon />
                  </div>
                </div>
              </ContractIdDownload>
            ) : (
              <div className={styles.container_header_item}>
                <Dropdown
                  visible={visibleDownloadMenu}
                  onVisibleChange={visible => {
                    setVisibleDownloadMenu(visible);
                  }}
                  placement="bottomRight"
                  overlay={
                    <div className={styles.downloadPopover}>
                      <div onClick={downloadExcel} className={styles.downloadPopover_item}>
                        <div className={styles.downloadPopover_item_element}>
                          <ExcelIcon />
                          <span>{t('home.Excel')}</span>
                        </div>
                        <ArrowLineDownIcon />
                      </div>
                    </div>
                  }
                  trigger={['click']}
                >
                  <div className={styles.container_header_item_content}>
                    <ReceiveSquareIcon size={20} stroke="#444444" strokeWidth={1.5} />
                    <p className={styles.downloadAkt_label}>{t('client_balance.Yuklab_olish')}</p>
                    <ArrowIosDownIcon />
                    {download.isLoading && <LoadingOutlined />}
                  </div>
                </Dropdown>
              </div>
            )}
            {!contractId && (
              <div className={styles.container_header_item}>
                <div className={styles.container_header_item_content} onClick={() => handleSettingTableClick(true)}>
                  <Setting3Icon /> {t('home.Jadval_sozlamalari')}
                </div>{' '}
              </div>
            )}
            <div className={styles.container_header_item}>
              <div className={styles.container_header_item_content} onClick={() => push(rootPaths.MAIN + MainPaths.MONETARY)}>
                <BankIcon /> {t('home.BANK')}
              </div>{' '}
            </div>
          </div>
          <div className={styles.container_header_right} style={{ paddingRight: !isAccessPayment ? 16 : 0 }}>
            <div className={styles.container_header_right_search}>
              <SearchIcon />
              <Input value={searchParams?.search} onChange={e => handleSearch(e.target.value)} placeholder={t('home.Qidirish')} />
            </div>

            {!contractId && isAccessPayment && (
              <div className={styles.container_header_right_addPayment} onClick={handlePayment}>
                <PlusCircleIcon /> {t('one_lid.To’lov')}
              </div>
            )}
          </div>
        </div>
        <div className={styles.container_body}>
          <div className={styles.container_body_left} style={{ width: contractId ? 'calc(100% - 532px)' : '100%' }}>
            {contractId ? (
              <>
                <PyamentContractMoney contractIdData={contractIdData} />
                <PaymentContractTable contractIdData={contractIdData} />
              </>
            ) : (
              <>
                <PaymentMoney countFilters={countFilters} />
                <div>
                  <PaymentHistoryTable data={data?.data} loading={isLoading} tableConfig={updatedTableConfigData} tableConfigFetch={tableConfigFetch} customFields={customFields} />
                  <PaymentPagination
                    total={
                      contractId
                        ? Number(contractId)
                          ? data?.totalElements !== undefined
                            ? data.totalElements
                            : -1
                          : -1
                        : data?.totalElements !== undefined
                          ? data.totalElements
                          : -1
                    }
                    pageSize={tableSize}
                    current={data?.currentPage ? data.currentPage : 1}
                    onChange={changePage}
                    hideOnSinglePage={false}
                  />
                </div>
              </>
            )}
          </div>
          {contractId && (
            <div className={styles.container_body_right}>
              <PaymentClient contractIdData={contractIdData} />
            </div>
          )}
        </div>
      </div>
      <PaymentFilterDriver visible={filterDrawerVisible} setVisible={setFilterDrawerVisible} />
      <TableConfigDrawer tableKey={TableKey.payment} visible={visibleDriver} onClose={handleSettingTableClick} tableData={data?.data} />
    </>
  );
};

export default PaymentV2;
