import { $api } from '@service/RequestService';
import { useMutation, useQueryClient } from 'react-query';
import { endPoints } from '../utils/constants/endPoints';
import { CreateWidgetModel } from '../utils/models/CreateWidgetModel';
import { message } from 'antd';
import { baseErrorHandler } from '@queries/baseErrorHandler';
import { queryKeys } from '../utils/constants/queryKeys';
import { WidgetModel } from '../utils/models/WidgetModel';
import { DashboardModel, DashboardUpdateModel } from '../utils/models/DashboardModel';
import { UpdateWidgetModel } from '../utils/models/UpdateWidgetModel';
import i18n from '@service/I18nService';

export function useCreateDashboard() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async (data: DashboardModel) => {
      const res = await $api.post(endPoints.DASHBOARD, data);
      return res.data;
    },
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: [queryKeys.DASHBOARD]
      });
      // message.success("Muvaffaqqiyatli")
    },
    onError: baseErrorHandler
  });
}

export function useCreateWidget() {
  return useMutation({
    mutationFn: async (data: CreateWidgetModel) => {
      const res = await $api.post(endPoints.WIDGETS, data);
      return res.data.data;
    },
    onSuccess: () => {
      message.success('Muvaffaqqiyatli');
    },
    onError: baseErrorHandler
  });
}

export function useDeleteWidget() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async (id: number) => {
      const res = await $api.delete(endPoints.WIDGETS + '/' + id);
      return res.data;
    },
    onSuccess: () => {
      message.success('Muvaffaqqiyatli');
      qc.invalidateQueries({
        queryKey: [queryKeys.WIDGETS]
      });
    },
    onError: baseErrorHandler
  });
}

export function useUpdateWidgets() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async (data: WidgetModel[]) => {
      const res = await $api.put(endPoints.WIDGET_UPDATE, data);
      return res.data;
    },
    onSuccess: () => {
      // message.success("Muvaffaqqiyatli")
      qc.invalidateQueries({
        queryKey: [queryKeys.WIDGETS]
      });
    },
    onError: baseErrorHandler
  });
}

export function useUpdateWidgetName(id: number) {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async (data: UpdateWidgetModel) => {
      const res = await $api.patch(endPoints.WIDGETS + `/${id}`, data);
      return res.data;
    },
    onSuccess: () => {
      message.success('Muvaffaqqiyatli');
      qc.invalidateQueries({
        queryKey: [queryKeys.WIDGETS]
      });
    },
    onError: baseErrorHandler
  });
}

export function useDeleteDashboard() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async (id: number) => {
      const res = await $api.delete(endPoints.DASHBOARD + '/' + id);
      return res.data;
    },
    onSuccess: () => {
      message.success('Muvaffaqqiyatli');
      qc.invalidateQueries({
        queryKey: [queryKeys.DASHBOARD]
      });
    },
    onError: baseErrorHandler
  });
}

export function useUpdateDashboard() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async (data: DashboardUpdateModel[] | undefined) => {
      const res = await $api.patch(endPoints.DASHBOARD_UPDATE, data);
      return res.data;
    },
    onSuccess: () => {
      // message.success("Muvaffaqqiyatli")
      qc.invalidateQueries({
        queryKey: [queryKeys.DASHBOARD]
      });
    },
    onError: baseErrorHandler
  });
}

export function useShareDashboard() {
  return useMutation({
    mutationFn: async (data: { id: number; employees: { employeeId: number; permission: string }[] }) => {
      const res = await $api.post(endPoints.SHARE, data);
      return res.data;
    },
    onSuccess: res => {
      console.log(res);
      message.success(res.message[i18n.language]);
    },
    onError: baseErrorHandler
  });
}
