import { $api } from '@service/RequestService';
import { useMutation } from 'react-query';
import { endPoints } from '../utils/constants/endPoints';
import { GivePermissionModel } from '../utils/models/GivePermissionModel';
import { message } from 'antd';
import { baseErrorHandler } from '@queries/baseErrorHandler';

export function useGivePermission() {
  return useMutation({
    mutationFn: async (data: GivePermissionModel) => {
      const res = await $api.post(endPoints.FORCE_CLIENT_LEAD, data);
      return res.data;
    },
    onSuccess: () => {
      message.success('Muvaffaqqiyatli');
    },
    onError: baseErrorHandler
  });
}
