import React, { FC, useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Select, Spin } from 'antd';
import styles from './createPlan.module.scss';
import { Input } from 'antd/es';
import { usePlanFactPipePipes } from '../../../../queries/queries';
import { FactPipeStatusList, PlanFactPipe } from '../../../../utils/models/PlanFactPipe';
import { PipeStatuses } from '../../../../utils/models/PipeAll';
import { helper } from '../../../../utils/helper/helper';
import { useSavePlanFact } from '../../../../queries/mutation';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface IProps {
  visible: boolean;
  close: (visible: boolean) => void;
  factPipe: PlanFactPipe | undefined;
}

const CreatePlanModal: FC<IProps> = ({ visible, close, factPipe }) => {
  const [pipeId, setPipeId] = useState(0);
  const [planAmount, setPlanAmount] = useState('0');
  const [pipeStatuses, setPipeStatuses] = useState<PipeStatuses[]>([]);
  const [activePipeStatuses, setActivePipeStatuses] = useState<FactPipeStatusList[]>([]);
  const { isLoading, data } = usePlanFactPipePipes();

  const { t } = useTranslation();

  const { startDate, finishData } = useAppSelector(state => state.crmStatistic);

  const save = useSavePlanFact(startDate, finishData, handleSuccess);

  useEffect(() => {
    if (data && factPipe && close!) {
      const pipe = data.find(item => item.id === factPipe.pipeId);
      if (pipe) {
        setPipeStatuses(pipe.pipeStatuses);
      }
      setActivePipeStatuses(factPipe.factPipeStatusList);
      setPipeId(factPipe.pipeId);
      setPlanAmount(factPipe.planAmount.toLocaleString('ru'));
    }
  }, [data, factPipe, close]);

  // handle cancel modal
  const handleCancel = () => {
    close(false);
  };

  // handle success
  function handleSuccess() {
    handleCancel();
  }

  // handle change pipe
  const handleChangePipe = (val: number) => {
    setActivePipeStatuses([]);
    setPipeId(val);
    setPlanAmount('0');
    const pipe = data?.find(item => item.id === val);
    if (pipe) {
      setPipeStatuses(pipe.pipeStatuses);
    }
  };

  // get not active statuses
  const getActiveStatuses = () => {
    return pipeStatuses.filter(item => !activePipeStatuses.find(pipe => pipe.pipeStatusResponse.id === item.id));
  };

  // change plan amount
  const changePriceAmount = (val: string) => {
    setPlanAmount(helper.deleteNotNumbersAndParseFloatNumber(val).string);
  };

  // add active status
  const addActiveStatus = (val: number) => {
    const pipe = pipeStatuses?.find(item => item.id === val);
    if (pipe) {
      const active = [...activePipeStatuses];
      active.push({
        pipeStatusResponse: pipe,
        planFactPipeId: factPipe?.id,
        planLeadCount: 0,
        actualLeadCount: 0
      });
      setActivePipeStatuses(active);
    }
  };

  // delete active status
  const deleteActiveStatus = (id: number) => {
    const activeStatusNew = activePipeStatuses.filter(item => item.pipeStatusResponse.id !== id);
    setActivePipeStatuses(activeStatusNew);
  };

  // add active status
  const changeOneStatusValue = (val: string, id: number) => {
    const newStatus: FactPipeStatusList[] = [];
    for (let i = 0; i < activePipeStatuses.length; i++) {
      if (id === activePipeStatuses[i].pipeStatusResponse.id) {
        newStatus.push({
          ...activePipeStatuses[i],
          planLeadCount: Number(helper.deleteNotNumbers(val, 0))
        });
      } else {
        newStatus.push(activePipeStatuses[i]);
      }
    }
    setActivePipeStatuses(newStatus);
  };

  // handle save
  const handleSave = () => {
    let pipeStatusMap = {};
    activePipeStatuses.forEach(item => {
      pipeStatusMap = {
        ...pipeStatusMap,
        [item.pipeStatusResponse.id as number]: item.planLeadCount
      };
    });

    save.mutate({
      pipeId,
      planAmount: helper.parseFloatStringToNumber(planAmount),
      id: Number(factPipe?.id),
      pipeStatusMap
    });
  };

  return (
    <Modal className={styles.container} width={546} footer={false} visible={visible} title={t('boss_crm.Rejani_belgilash')} onCancel={handleCancel}>
      <Spin spinning={isLoading}>
        <Row gutter={[4, 0]}>
          <Col span={12}>
            <label className={styles.container_left_label} htmlFor={'pipe'}>
              {t('boss_crm.Sotuv_varonkasi')}
            </label>
            <Select
              id={'pipe'}
              value={pipeId}
              optionFilterProp={'children'}
              showSearch
              onChange={handleChangePipe}
              className={'customSelect ' + styles.container_full_with_select}
              placeholder={t('boss_crm.Manbaalarni_tanlang')}
            >
              {data?.map((item, index) => (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={12}>
            <label className={styles.container_right_label}>{t('boss_crm.Bitimlar_soni')}</label>
            {activePipeStatuses.map((item, index) => (
              <Row key={index} className={styles.container_sub_row} gutter={[6, 0]}>
                <Col span={15}>
                  <Select
                    className={'customSelect ' + styles.container_full_with_select}
                    onChange={e => {
                      if (e === -1) {
                        deleteActiveStatus(Number(item.pipeStatusResponse.id));
                      }
                    }}
                    value={item.pipeStatusResponse.id}
                  >
                    <Option value={item.pipeStatusResponse.id}>{item.pipeStatusResponse.name}</Option>
                    <Option value={-1}>{t('boss_crm.Ochirish')}</Option>
                  </Select>
                </Col>
                <Col span={9}>
                  <Input
                    onChange={e => changeOneStatusValue(e.target.value, Number(item.pipeStatusResponse.id))}
                    value={item.planLeadCount?.toLocaleString('ru')}
                    className={'customInput'}
                  />
                </Col>
              </Row>
            ))}
            {getActiveStatuses().length !== 0 && (
              <Select
                optionFilterProp={'children'}
                showSearch
                onChange={addActiveStatus}
                value={null}
                className={'customSelect ' + styles.container_full_with_select}
                placeholder={t('boss_crm.Manbaalarni_tanlang')}
              >
                {getActiveStatuses().map((status, index) => (
                  <Option key={index} value={status.id}>
                    {status.name}
                  </Option>
                ))}
              </Select>
            )}
          </Col>
        </Row>
        <Row className={styles.container_sale_weight} gutter={[4, 0]}>
          <Col span={12}>
            <label className={styles.container_left_label} htmlFor={'saleWeight'}>
              {t('boss_crm.Sotuv_hajmi')}
            </label>
            <Input onChange={e => changePriceAmount(e.target.value)} value={planAmount} id={'saleWeight'} className={'customInput'} />
          </Col>
        </Row>
        <div className={styles.container_modal_footer}>
          <Button onClick={handleCancel}>{t('boss_crm.Yopish')}</Button>
          <Button onClick={handleSave} loading={save.isLoading} type="primary">
            {t('boss_crm.Tasdiqlash')}
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

export default CreatePlanModal;
