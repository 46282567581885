/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import CancelBronGarbage from '../../assets/icons/CancelBronGarbage';
import EditIcon from '../../assets/icons/EditIcon';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { editAndCreateModal } from '../../store/reducers/CompanyEmployeesSlice';
import { CompaniesList } from '../../utils/models/Companies';
import { EmployeeList } from '../../utils/models/EmployeeList';
import { EmployeeStatus } from '../../utils/models/EmployeeStatus';

import styles from '../../pages/companies/companies.module.scss';

interface TableContractProps {
  data: EmployeeList[];
  isLoading: boolean;
  isFetching: boolean;
}

const CompanyEmployeesTable: React.FC<TableContractProps> = ({ data, isLoading, isFetching }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const initialColumn: ColumnsType<EmployeeList> | undefined = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '100px'
    },
    {
      title: t('home.Xodim'),
      dataIndex: 'fullName'
    },
    {
      title: t('integration.Telefon_raqam'),
      dataIndex: 'phone'
    },
    {
      title: t('settingEmployee.Lavozim'),
      render: (record: EmployeeList) => <span>{record?.roleBranches?.map(item => item?.role?.name)?.join(', ')}</span>
    },
    {
      title: t('home.Status'),
      dataIndex: 'status',
      render: (status: EmployeeStatus) => (
        <span
          className={
            // eslint-disable-next-line no-nested-ternary
            status === EmployeeStatus.ENABLED ? 'table_status_active' : status === EmployeeStatus.LOCKED ? 'table_status_contract_mode' : 'table_status_canceled'
          }
        >
          {t(`home.${status}`)}
        </span>
      )
    },
    {
      title: '',
      render: (company: CompaniesList) => (
        <div className="d-flex align-center justify-center">
          <span onClick={() => openEditModal(company.id)} className={styles.table_icons}>
            <EditIcon />
          </span>
          <Popconfirm
            title={t('home.Xodimni_ochirishni_tasdiqlang!')}
            onConfirm={() => handleDelete(company.id)}
            okText={t('arrearage.Tasdiqlash')}
            cancelText={t('one_lid.Bekor_qilish')}
          >
            <span className={styles.table_icons}>
              <CancelBronGarbage />
            </span>
          </Popconfirm>
        </div>
      )
    }
  ];

  const openEditModal = (id: number) => {
    dispatch(editAndCreateModal({ id, open: true }));
  };

  const handleDelete = (id: number) => {
    // console.log(id);
  };

  return (
    <>
      <Table
        className="custom_table custom_table_size_developer table_cursor_default"
        columns={initialColumn}
        dataSource={data}
        scroll={{ y: `calc(100vh - 310px)` }}
        loading={isLoading || isFetching}
        pagination={false}
      />
    </>
  );
};

export default CompanyEmployeesTable;
