import React, { FC } from 'react';
import { Spin } from 'antd';

import { useCompanyTemplates } from '../../../queries/queries';
import { FileTemplateLanguage, FileTemplateType } from '../../../utils/models/FileTemplate';
import CompanyTemplate from '../company-template/CompanyTemplate';

import styles from './companySetting.module.scss';

interface IProps {
  type: FileTemplateType;
  tempId?: number;
}

const CompanySetting: FC<IProps> = ({ type, tempId }) => {
  const { data, isLoading } = useCompanyTemplates(type, tempId);

  const getCurrentTemplate = (lang: FileTemplateLanguage) => data?.filter(template => template.language === lang);

  return (
    <Spin spinning={isLoading}>
      <div className={styles.container}>
        {type === FileTemplateType.CONTRACT ||
        type === FileTemplateType.BOOKING ||
        type === FileTemplateType.CONTRACT_CUSTOM ||
        type === FileTemplateType.ACT ||
        type === FileTemplateType.CLIENT_PAYMENT ||
        type === FileTemplateType.BOOKING_PAYMENT ||
        type === FileTemplateType.PAYMENT ? (
          <>
            <CompanyTemplate
              showTemplate
              file={getCurrentTemplate(FileTemplateLanguage.UZLAT)}
              lang={FileTemplateLanguage.UZLAT}
              header={FileTemplateLanguage.UZBEK}
              type={type}
              tempId={tempId}
            />
            <CompanyTemplate
              file={getCurrentTemplate(FileTemplateLanguage.UZCYR)}
              lang={FileTemplateLanguage.UZCYR}
              header={FileTemplateLanguage.KIRIL}
              type={type}
              tempId={tempId}
            />
            <CompanyTemplate
              file={getCurrentTemplate(FileTemplateLanguage.EN)}
              lang={FileTemplateLanguage.EN}
              header={FileTemplateLanguage.English}
              type={type}
              tempId={tempId}
            />
            <CompanyTemplate
              file={getCurrentTemplate(FileTemplateLanguage.RU)}
              lang={FileTemplateLanguage.RU}
              header={FileTemplateLanguage.RUSSIAN}
              type={type}
              tempId={tempId}
            />
          </>
        ) : (
          <CompanyTemplate
            file={data}
            lang={data ? data[0]?.language || FileTemplateLanguage.UZLAT : FileTemplateLanguage.UZLAT}
            type={type}
            tempId={tempId}
          />
        )}
      </div>
    </Spin>
  );
};

export default CompanySetting;
