import { Button, Form, Modal, Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './createShablon.module.scss';
import InitialForm from './InitialForm';
import { useQueryParam } from '@hooks/useQueryParams';
import { ValidateErrorEntity } from 'rc-field-form/es/interface';
import { useCreateCause, useCreateVoronkaShablon, useUpdatePipeTemplate } from '@pages/voronka-shablon/services/mutation';
import { useGetOnePipeTemplete } from '@pages/voronka-shablon/services/queries';

interface Props {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  visible: boolean;
  setShablonId: React.Dispatch<React.SetStateAction<number | undefined>>;
  shablonId: number | undefined;
}

const CreateShablon: React.FC<Props> = ({ setVisible, visible, setShablonId, shablonId }) => {
  const [form] = Form.useForm();
  const { appendSingle } = useQueryParam<any, any>();

  const [activeTab, setActiveTab] = useState('Uz');

  const { data, isLoading } = useGetOnePipeTemplete(shablonId!);
  const createCause = useCreateCause();
  const createShablon = useCreateVoronkaShablon();
  const updateShablon = useUpdatePipeTemplate();

  const initialState = [
    {
      nameUz: 'Ilk kontakt',
      nameRu: 'Первый контакт',
      nameEn: 'First contact',
      nameTr: 'İlk temas',
      list: [
        { nameUz: 'Incoming leads', nameRu: 'Входящие лиды', nameEn: 'Incoming leads', nameTr: 'Incoming leads', color: '#87898E', isDelete: true }
      ]
    },
    {
      nameUz: 'Natija',
      nameRu: 'Результат',
      nameEn: 'Result',
      nameTr: 'Sonuç',
      list: [
        {
          nameUz: 'Muvaffaqqiyatli',
          nameRu: 'Успешный',
          nameEn: 'Successful',
          nameTr: 'Başarılı',
          color: '#4F1F9F',
          isDelete: true
        },
        { nameUz: 'Muvaffaqqiyatsiz', nameRu: 'Неудачный', nameEn: 'Unsuccessful', nameTr: 'Başarısız', color: '#FFC682', isDelete: true }
      ]
    }
  ];
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        nameTop: data?.name,
        type: data?.type,
        items: data?.causes?.map((item: any) => ({
          nameUz: item?.nameUz,
          nameRu: item?.nameRu,
          nameEn: item?.nameEn,
          nameTr: item?.nameTr,
          list: item?.pipelineNames?.map((prev: any, index: number) => ({
            ...prev,
            nameUz: prev?.nameUz,
            nameRu: prev?.nameRu,
            nameEn: prev?.nameEn,
            nameTr: prev?.nameTr,
            color: prev?.color,
            order: prev?.pipeStatusType === 'SUCCESS' ? 1001 : prev?.pipeStatusType === 'FAILED' ? 1002 : index + 1
          })),
          id: item?.id
        }))
      });
    } else {
      form.setFieldsValue({
        nameTop: '',
        items: initialState
      });
    }
  }, [data]);

  const handleClose = () => {
    form.resetFields();
    setVisible(false);
    setShablonId(undefined);
    setActiveTab('Uz');
    appendSingle('lang', 'Uz');
  };

  function handleFinish(values: any) {
    const sendData = values?.items?.map((prev: any, prevIndex: number) => ({
      causeName: {
        nameUz: prev?.nameUz,
        nameRu: prev?.nameRu,
        nameEn: prev?.nameEn,
        nameTr: prev?.nameTr,
        nameKr: prev?.nameTr
      },
      pipelines: prev?.list?.map((item: any, index: number) => ({
        nameUz: item?.nameUz,
        nameRu: item?.nameRu,
        nameEn: item?.nameEn,
        nameTr: item?.nameRu,
        nameKr: item?.nameRu,
        order: prevIndex === 1 && index === 0 ? 1001 : prevIndex === 1 && index === 1 ? 1002 : index + 1,
        pipeStatusType:
          prevIndex === 0 && index === 0
            ? 'FIRST'
            : prevIndex === 1 && index === 0
              ? 'SUCCESS'
              : prevIndex === 1 && index === 1
                ? 'FAILED'
                : 'SIMPLE',
        color: item?.color ? item?.color : '#000000'
      }))
    }));
    const sendUpdate = {
      type: values?.type,
      name: values?.nameTop,
      causes: values?.items?.map((prev: any, prevIndex: number) => ({
        nameUz: prev?.nameUz,
        nameRu: prev?.nameRu,
        nameEn: prev?.nameEn,
        nameKr: prev?.nameRu,
        nameTr: prev?.nameTr,
        pipelineNames: prev?.list?.map((item: any, index: number) => ({
          nameUz: item?.nameUz,
          nameRu: item?.nameRu,
          nameEn: item?.nameEn,
          nameTr: item?.nameRu,
          nameKr: item?.nameRu,
          order: prevIndex === 1 && index === 0 ? 1001 : prevIndex === 1 && index === 1 ? 1002 : index + 1,
          pipeStatusType:
            prevIndex === 0 && index === 0
              ? 'FIRST'
              : prevIndex === 1 && index === 0
                ? 'SUCCESS'
                : prevIndex === 1 && index === 1
                  ? 'FAILED'
                  : 'SIMPLE',
          color: item?.color ? item?.color : '#000000'
        })),
        id: prev?.id
      })),
      id: data?.id,
      createdDate: data?.createdDate
    };
    if (data) {
      updateShablon.mutateAsync(sendUpdate).then(handleClose);
    } else {
      createCause.mutateAsync(sendData).then((res: any) => {
        createShablon.mutateAsync({ name: values?.nameTop, type: values?.type, causes: res?.map((item: any) => item?.id) }).then(handleClose);
      });
    }
  }

  const handleChangeTabs = (activeKey: string) => {
    setActiveTab(activeKey);
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    const name = errorInfo?.errorFields[0]?.name[errorInfo?.errorFields[0]?.name?.length - 1] as string;
    const tabs = ['Uz', 'Ru', 'En', 'Tr'];
    const errorTab = name.slice(name.length - 2);

    if (tabs.includes(errorTab)) {
      setActiveTab(errorTab);
    }

    setTimeout(() => {
      document.getElementById(name)?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }, 200);
  };

  return (
    <Modal visible={visible} onCancel={handleClose} title="Shablon yaratish" width={568} footer={false} className={styles.createModal} centered>
      <Spin spinning={isLoading}>
        <Form
          form={form}
          name="dynamic_form_complex"
          autoComplete="off"
          initialValues={{ nameTop: data ? data?.name : '', items: initialState }}
          layout="vertical"
          onFinish={handleFinish}
          onFinishFailed={onFinishFailed}
        >
          <Tabs
            activeKey={activeTab}
            onChange={e => {
              appendSingle('lang', e);
              handleChangeTabs(e);
            }}
          >
            <Tabs.TabPane key={'Uz'} tab="Uz" forceRender={true}>
              <InitialForm data={data} language="Uz" form={form} />
            </Tabs.TabPane>
            <Tabs.TabPane key={'Ru'} tab="Ru" forceRender={true}>
              <InitialForm data={data} language="Ru" form={form} />
            </Tabs.TabPane>
            <Tabs.TabPane key={'En'} tab="En" forceRender={true}>
              <InitialForm data={data} language="En" form={form} />
            </Tabs.TabPane>
            <Tabs.TabPane key={'Tr'} tab="Tr" forceRender={true}>
              <InitialForm data={data} language="Tr" form={form} />
            </Tabs.TabPane>
          </Tabs>

          <div className={styles.footer}>
            <Button onClick={handleClose}>Yopish</Button>
            <Button type="primary" htmlType="submit" loading={createShablon?.isLoading || updateShablon?.isLoading}>
              Saqlash
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreateShablon;
