import React from 'react';

const DostsSixVertical = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.353 3.83906C9.86084 3.83906 10.2725 3.42737 10.2725 2.91953C10.2725 2.41169 9.86084 2 9.353 2C8.84516 2 8.43347 2.41169 8.43347 2.91953C8.43347 3.42737 8.84516 3.83906 9.353 3.83906Z"
        fill="black"
      />
      <path
        d="M5.66465 3.83906C6.17249 3.83906 6.58418 3.42737 6.58418 2.91953C6.58418 2.41169 6.17249 2 5.66465 2C5.1568 2 4.74512 2.41169 4.74512 2.91953C4.74512 3.42737 5.1568 3.83906 5.66465 3.83906Z"
        fill="black"
      />
      <path
        d="M9.353 8.34345C9.86084 8.34345 10.2725 7.93177 10.2725 7.42392C10.2725 6.91608 9.86084 6.50439 9.353 6.50439C8.84516 6.50439 8.43347 6.91608 8.43347 7.42392C8.43347 7.93177 8.84516 8.34345 9.353 8.34345Z"
        fill="black"
      />
      <path
        d="M5.66465 8.34345C6.17249 8.34345 6.58418 7.93177 6.58418 7.42392C6.58418 6.91608 6.17249 6.50439 5.66465 6.50439C5.1568 6.50439 4.74512 6.91608 4.74512 7.42392C4.74512 7.93177 5.1568 8.34345 5.66465 8.34345Z"
        fill="black"
      />
      <path
        d="M9.353 12.8481C9.86084 12.8481 10.2725 12.4364 10.2725 11.9286C10.2725 11.4207 9.86084 11.009 9.353 11.009C8.84516 11.009 8.43347 11.4207 8.43347 11.9286C8.43347 12.4364 8.84516 12.8481 9.353 12.8481Z"
        fill="black"
      />
      <path
        d="M5.66465 12.8481C6.17249 12.8481 6.58418 12.4364 6.58418 11.9286C6.58418 11.4207 6.17249 11.009 5.66465 11.009C5.1568 11.009 4.74512 11.4207 4.74512 11.9286C4.74512 12.4364 5.1568 12.8481 5.66465 12.8481Z"
        fill="black"
      />
    </svg>
  );
};

export default DostsSixVertical;
