import React, { useState } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import { SearchParamsCrm } from '@pages/crm/components/filter/FilterButton';
import { useAttachClient } from '@pages/oneLid/services/mutation';
import { useGetSearchLeadTasks } from '@queries/queries';
import { ClientLeadModel } from '@utils/models/ClientLeadModel';
import { OneLid } from '@utils/models/OneLid';
import { Button, Input, Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './connectLead.module.scss';

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenCreateModal: () => void;
  clientId: number;
  setSelectLead: React.Dispatch<React.SetStateAction<ClientLeadModel | undefined>>;
};

const ConnectLeadModal: React.FC<Props> = ({ setVisible, visible, handleOpenCreateModal, setSelectLead }) => {
  const { t } = useTranslation();
  const attachClient = useAttachClient();
  const { searchParams } = useQueryParam<SearchParamsCrm, any>();
  const [searchValue, setSearchValue] = useState(searchParams.search!);
  const [clientData, setClientData] = useState<OneLid>();
  const [scrolling, setScrolling] = useState(true);
  const { data, isLoading, fetchNextPage } = useGetSearchLeadTasks({ notMerge: true, search: searchValue?.length! >= 3 ? searchValue! : '' });

  const handleClose = () => {
    setVisible(false);
    setSearchValue('');
    setClientData(undefined);
  };

  const handleAttachLead = () => {
    setSelectLead(clientData as any);
    handleClose();
  };

  const onScroll = (e: any) => {
    if (e.target.scrollHeight - 200 < e.target.scrollTop + e.target.offsetHeight) {
      setScrolling(false);
      scrolling &&
        fetchNextPage().then(() => {
          setScrolling(true);
        });
    }
  };

  return (
    <Modal visible={visible} title={t('one_lid.lid_biriktirish')} onCancel={handleClose} className={styles.clientModal} footer={false}>
      <div className={styles.clientModal_body}>
        <span>{t('one_lid.lidni_qidiring')}</span>
        <Input
          value={searchValue}
          onChange={e => {
            setSearchValue(e.target.value);
          }}
          placeholder={t('one_lid.lid_qidirish')}
        />
      </div>
      <div className={styles.clientModal_searchClients} onScroll={e => onScroll(e)}>
        <Spin spinning={isLoading}>
          {!clientData?.id ? (
            data?.pages?.map(data =>
              data?.data?.map(client => (
                <div
                  className={styles.findClient}
                  onClick={() => {
                    setClientData(client);
                  }}
                  key={client?.id}
                >
                  <div className={styles.findClient_item}>
                    <label>{t('integration.Lid_nomi')}</label>
                    <span>{client.name}</span>
                  </div>
                  <div className={styles.findClient_item}>
                    <label>{t('one_lid.Qadam')}</label>
                    <div className={styles.topRight}>
                      <div>{client?.pipeStatusResponse?.pipeName}</div>
                      <div
                        style={{
                          backgroundColor: client?.pipeStatusResponse?.color,
                          borderBottomColor: client?.pipeStatusResponse?.color,
                          borderRightColor: client?.pipeStatusResponse?.color,
                          borderTopColor: client?.pipeStatusResponse?.color
                        }}
                      >
                        {client?.pipeStatusResponse?.name}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )
          ) : (
            <div className={clientData?.id ? styles.findClient_test : styles.findClient}>
              <div className={styles.findClient_item}>
                <label>{t('integration.Lid_nomi')}</label>
                <span>{clientData?.name}</span>
              </div>
              <div className={styles.findClient_item}>
                <label>{t('one_lid.Qadam')}</label>
                <div className={styles.topRight}>
                  <div>{clientData?.pipeStatusResponse?.pipeName}</div>
                  <div
                    style={{
                      backgroundColor: clientData?.pipeStatusResponse?.color,
                      borderBottomColor: clientData?.pipeStatusResponse?.color,
                      borderRightColor: clientData?.pipeStatusResponse?.color,
                      borderTopColor: clientData?.pipeStatusResponse?.color
                    }}
                  >
                    {clientData?.pipeStatusResponse?.name}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Spin>
      </div>
      <div className={styles.footer}>
        <Button onClick={handleOpenCreateModal}>{t('home.Yangi_lid_yaratish')}</Button>
        <Button
          disabled={!clientData?.id}
          style={{
            backgroundColor: clientData?.id ? '#00A389' : '',
            color: clientData?.id ? '#fff' : '',
            borderColor: clientData?.id ? '#00A389' : ''
          }}
          onClick={handleAttachLead}
          loading={attachClient?.isLoading}
        >
          {t('one_lid.lid_biriktirish')}
        </Button>
      </div>
    </Modal>
  );
};

export default ConnectLeadModal;
