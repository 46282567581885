import React, { memo } from 'react';
import { useGetActivityLogsEmployee } from '@pages/activityLogs/services/queries';
import { BASE_URL } from '@service/RequestService';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import CheveronBottom from '../../../../../assets/icons/CheveronBottom';
import CheckmarkIcon from '../../../../../assets/icons/crmIcons/CheckmarkIcon';
import SubtractIcon from '../../../../../assets/icons/SubtractIcon';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { useCrmEmployee } from '../../../../../queries/queries';

import Unassignee from '../../../assets/images/Unassigne.png';

import styles from './filterDrawer.module.scss';

interface Props {
  labelFilter: 'responsibleById' | 'createdById' | 'responsibleByIds';
  ids: string[] | string | undefined;
  title: string;
  type?: string;
}

const EmployeeFilter: React.FC<Props> = ({ labelFilter, title, ids, type }) => {
  const { appendSingle, appendMultiple, remove } = useQueryParam<{ responsibleById: string }, null>();

  const { data } = useCrmEmployee();
  const { data: contractEmployee } = useGetActivityLogsEmployee(type!);

  const currentData = type ? contractEmployee : data;

  const newCurrentData = [
    {
      id: 0,
      fullName: 'Belgilanmagan',
      avatar: Unassignee,
      haveAccess: false
    },
    ...(currentData! || [])
  ];

  const { t } = useTranslation();

  // change
  const handleChange = (value: string) => {
    if (Array.isArray(ids)) {
      if (ids.includes(value)) {
        remove(labelFilter, value);
      } else {
        appendMultiple(labelFilter, value);
      }
    } else if (ids === value) {
      remove(labelFilter, value);
    } else {
      appendMultiple(labelFilter, value);
    }
  };

  // select All
  const selectAll = () => {
    appendSingle(labelFilter, undefined);
  };

  // is has responsibleBy
  const isHasResponsibleBy = (id: number) => {
    if (!ids) {
      return false;
    }
    if (Array.isArray(ids)) {
      return ids.includes(String(id));
    }
    return ids === String(id);
  };

  // responsibleBy tags
  const responsibleByTags = () => {
    if (ids) {
      if (Array.isArray(ids)) {
        return (
          <>
            {ids.map((id, index) => {
              const emp = type ? currentData?.find(emp => emp.id === Number(id)) : newCurrentData?.find(emp => emp.id === Number(id));

              if (emp) {
                return (
                  <div key={emp.id} className={styles.container_employee_items}>
                    <span className={styles.container_employee_item}>
                      {emp.fullName}
                      <SubtractIcon
                        onClick={e => {
                          handleChange(String(emp.id));
                          e.stopPropagation();
                        }}
                      />
                    </span>
                  </div>
                );
              }
              return null;
            })}
          </>
        );
      }
      const emp = type ? currentData?.find(emp => emp.id === Number(ids)) : newCurrentData?.find(emp => emp.id === Number(ids));

      if (emp || Number(ids) === 0) {
        return (
          <div className={styles.container_employee_items}>
            <span className={styles.container_employee_item}>
              {emp?.fullName || 'Belgilanmagan'}
              <SubtractIcon
                onClick={e => {
                  handleChange(String(emp?.id || 0));
                  e.stopPropagation();
                }}
              />
            </span>
          </div>
        );
      }
      return null;
    }
    return <span className={styles.container_employee_placeholder}>{t(`crm_filter.${title}`)}</span>;
  };

  return (
    <Dropdown
      placement="bottom"
      overlayClassName={styles.container_employee_dropdown}
      overlay={
        <>
          <div onClick={selectAll} className={styles.container_employee_dropdown_all}>
            <span className={styles.container_employee_dropdown_all_name}>{t('crm_filter.Sotuv_bolimi')}</span>
            <span className={styles.container_employee_dropdown_all_button}>{t('crm_filter.Barchaga')}</span>
          </div>
          {type
            ? currentData?.map((emp, index) => (
                <div onClick={() => handleChange(String(emp.id))} key={emp.id} className={styles.container_employee_dropdown_one}>
                  <div className="flex h-[35px] w-[35px] items-center justify-center  rounded-[50%]">
                    {emp.avatar ? (
                      <img
                        className="h-[100%] w-[100%] justify-center rounded-[50%]  object-cover"
                        src={`${emp.id > 0 ? `${BASE_URL}/${emp.avatar}` : `${emp.avatar}`} `}
                        alt="error img"
                      />
                    ) : (
                      <div
                        className="flex 
                  h-[100%] 
                  w-[100%] 
                  items-center 
                  justify-center  
                  rounded-[50%] 
                  border 
                  border-solid
                  border-red-500
                  bg-red-500
                 text-white"
                      >
                        <span>{emp.fullName?.substring(0, 1)}</span>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <span className={styles.container_step_dropdown_one_name}>{emp.fullName}</span>
                  </div>

                  {isHasResponsibleBy(emp.id) && <CheckmarkIcon />}
                </div>
              ))
            : newCurrentData?.map((emp, index) => (
                <div onClick={() => handleChange(String(emp.id))} key={emp.id} className={styles.container_employee_dropdown_one}>
                  <div className="flex h-[35px] w-[35px] items-center justify-center  rounded-[50%]">
                    {emp.avatar ? (
                      <img
                        className="h-[100%] w-[100%] justify-center rounded-[50%]  object-cover"
                        src={`${emp.id > 0 ? `${BASE_URL}/${emp.avatar}` : `${emp.avatar}`} `}
                        alt="error img"
                      />
                    ) : (
                      <div
                        className="flex 
                    h-[100%] 
                    w-[100%] 
                    items-center 
                    justify-center  
                    rounded-[50%] 
                    border 
                    border-solid
                    border-red-500
                    bg-red-500
                   text-white"
                      >
                        <span>{emp.fullName?.substring(0, 1)}</span>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <span className={styles.container_step_dropdown_one_name}>{emp.fullName}</span>
                    {emp.id > 0 && !emp.haveAccess && (
                      <span className="text-[8px] font-medium leading-[16px]" style={{ color: 'rgba(255, 101, 101, 1)' }}>
                        Huquq yo'q
                      </span>
                    )}
                  </div>

                  {isHasResponsibleBy(emp.id) && <CheckmarkIcon />}
                </div>
              ))}
        </>
      }
      trigger={['click']}
    >
      <div className={styles.container_employee}>
        {responsibleByTags()}
        <CheveronBottom className={styles.container_employee_icon} />
      </div>
    </Dropdown>
  );
};

export default memo(EmployeeFilter);
