import { IShowroomFlat } from '../models/IShowroomFlat';

export const getShowroomData = (data: IShowroomFlat[]) => {
  const buildingIds: { name: string; id: number }[] = [];
  const floors: number[] = [];
  let nLength = 1;

  data.forEach(flat => {
    if (!buildingIds.some(building => flat?.buildingId === building?.id)) {
      buildingIds.push({ id: flat?.buildingId, name: flat?.buildingName });
    }
    if (!floors.includes(flat?.floor)) {
      floors.push(flat?.floor);
    }

    if (flat?.number?.length > nLength) {
      nLength = flat?.number?.length;
    }
  });

  const dataConvert = {
    flatCount: data?.length,
    currency: data[0]?.currency,
    nLength,
    floors: floors
      ?.sort((a, b) => b - a)
      ?.map(floor => ({
        floor,
        buildings: buildingIds?.map(building => {
          const entrances: number[] = [];

          data
            ?.filter(flat => flat?.buildingId === building.id)
            ?.forEach(flat => {
              if (!entrances.includes(flat?.entrance)) {
                entrances.push(flat?.entrance);
              }
            });
          return {
            name: building?.name,
            entrances: entrances
              ?.sort((a, b) => a - b)
              ?.map(entrance => {
                const xaxiss: number[] = [];
                let entranceName = '';

                data
                  ?.filter(flat => flat?.entrance === entrance && flat?.buildingId === building.id)
                  ?.forEach(flat => {
                    if (!entranceName) entranceName = flat?.entranceName;
                    if (!xaxiss.includes(Number(flat?.xaxis))) {
                      xaxiss.push(Number(flat?.xaxis));
                    }
                  });
                return {
                  entrance,
                  entranceName,
                  flats: xaxiss
                    ?.sort((a, b) => a - b)
                    ?.map(xaxis => {
                      const apartment = data?.find(
                        item => item?.buildingId === building?.id && item?.entrance === entrance && item?.floor === floor && Number(item?.xaxis) === xaxis
                      );

                      if (apartment) {
                        return {
                          nLength,
                          ...apartment
                        };
                      }
                      return {
                        nLength,
                        buildingId: building?.id,
                        entrance,
                        floor,
                        xaxis: String(xaxis)
                      } as IShowroomFlat;
                    })
                };
              })
          };
        })
      }))
  };

  // console.log(dataConvert)

  // const dataConvertMatritsa = {
  //   flatCount: data?.length,
  //   floors: floors?.sort((a, b) => b - a),
  //   currency: data[0]?.currency,
  //   buildings: buildingIds.map((building) => {
  //     const entrances: number[] = []
  //     data
  //       ?.filter((flat) => flat?.buildingId === building.id)
  //       ?.forEach((flat) => {
  //         if (!entrances.includes(flat?.entrance)) {
  //           entrances.push(flat?.entrance)
  //         }
  //       })
  //     return {
  //       name: building?.name,
  //       entrances: entrances
  //         ?.sort((a, b) => a - b)
  //         ?.map((entrance) => {
  //           const xaxiss: number[] = []
  //           data
  //             ?.filter(
  //               (flat) =>
  //                 flat?.entrance === entrance &&
  //                 flat?.buildingId === building.id
  //             )
  //             ?.forEach((flat) => {
  //               if (!xaxiss.includes(Number(flat?.xaxis))) {
  //                 xaxiss.push(Number(flat?.xaxis))
  //               }
  //             })

  //           return {
  //             entrance,
  //             floors: floors
  //               ?.sort((a, b) => b - a)
  //               ?.map((floor) => {
  //                 return {
  //                   floor,
  //                   flats: xaxiss
  //                     ?.sort((a, b) => a - b)
  //                     ?.map((xaxis) => {
  //                       const apartment = data?.find(
  //                         (item) =>
  //                           item?.buildingId === building?.id &&
  //                           item?.entrance === entrance &&
  //                           item?.floor === floor &&
  //                           Number(item?.xaxis) === xaxis
  //                       )
  //                       if (apartment) {
  //                         return {
  //                           ...apartment,
  //                         }
  //                       } else {
  //                         return {
  //                           buildingId: building?.id,
  //                           entrance,
  //                           floor,
  //                           xaxis: String(xaxis),
  //                         } as IShowroomFlat
  //                       }
  //                     }),
  //                 }
  //               }),
  //           }
  //         }),
  //     }
  //   }),
  // }

  return dataConvert;
};
