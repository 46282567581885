import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import DashboardVidget4 from '@icons/DashboardVidget4';
import DashboardVidget5 from '@icons/DashboardVidget5';
import DashboardVidjet1 from '@icons/DashboardVidjet1';
import DashboardVidjet2 from '@icons/DashboardVidjet2';
import DashboardVidjet3 from '@icons/DashboardVidjet3';
import { WidgetsModel } from '@pages/crm/utils/models/WidgetsModel';
import { useCreateWidget } from '@pages/dashboard/services/mutations';
import { useGetDashboards, useGetWidgets } from '@pages/dashboard/services/queries';
import { CreateWidgetModel } from '@pages/dashboard/utils/models/CreateWidgetModel';
import { useCrmFields } from '@queries/queries';
import { setOpenDashboard } from '@reducers/CrmSlice';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { BASE_URL } from '@service/RequestService';
import { DATE_FORMAT } from '@utils/constants/format';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import TableSettingsModal from '../dashboard-table-settings/TableSettingsModal';

import styles from './dashboardModal.module.scss';

const { Option } = Select;

const DashboardModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { searchParams, params } = useQueryParam<any, any>();
  const { data: dashboard } = useGetDashboards();
  const [selectItem, setSelectItem] = useState<{ id: number; type: string }>({
    id: 0,
    type: ''
  });
  const [nameVal, setNameVal] = useState<string>('');
  const [openSettings, setOpenSettings] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectDashboard, setSelectDashboard] = useState<number>();
  const [optionVal, setOptionVal] = useState<string>('');
  const { data: fields } = useCrmFields();
  const { data } = useGetWidgets(selectDashboard!);
  const { openDashboard } = useAppSelector(state => state.assigmentSlice);
  const selectedDash = dashboard?.find(item => item?.id === selectDashboard);
  const createWidget = useCreateWidget();
  const startWeek = dayjs().startOf('week').add(1, 'day').format(DATE_FORMAT);
  const endWeek = dayjs().endOf('week').add(1, 'day').format(DATE_FORMAT);

  const vidgets: WidgetsModel[] = [
    {
      id: 1,
      title: t('emp_dashboard.Raqamli'),
      desc: t('emp_dashboard.raqamli_text'),
      icon: <DashboardVidjet1 />,
      selectData: undefined,
      settings: '',
      type: 'NUMBER'
    },
    {
      id: 2,
      title: `${t('emp_dashboard.Statik_pie_chart')?.substring(0, 18)}...`,
      desc: `${t('emp_dashboard.pie_text')?.substring(0, 35)}...`,
      icon: <DashboardVidjet2 />,
      selectData: fields?.filter(item => item?.type === 'SELECT'),
      settings: '',
      type: 'PIE_CHART'
    },
    {
      id: 3,
      title: t('emp_dashboard.Grafik_korinish'),
      desc: t('emp_dashboard.pie_text'),
      icon: <DashboardVidjet3 />,
      selectData: fields?.filter(item => item?.type === 'SELECT'),
      settings: '',
      type: 'CHART'
    },
    {
      id: 4,
      title: 'Timeline ko’rinish',
      desc: "Ma'lumotlar namunasining timeline ko'rinishi",
      icon: <DashboardVidget5 />,
      selectData: undefined,
      settings: '',
      type: 'TIMELINE',
      permission: ['crm', 'task']
    },
    {
      id: 5,
      title: t('emp_dashboard.Jadval_korinish'),
      desc: t('emp_dashboard.table_text'),
      icon: <DashboardVidget4 />,
      selectData: undefined,
      settings: t('emp_dashboard.Jadval_sozlamalari'),
      type: 'LIST'
    }
  ];

  const handleClose = () => {
    dispatch(setOpenDashboard(false));
    setSelectItem({ id: 0, type: '' });
    form.resetFields();
  };

  const handleOpenSettingsModal = () => {
    setOpenSettings(true);
  };

  const convertData = () => {
    const maxX = 0;
    let maxY = 0;

    data?.forEach(item => {
      if (item?.position[1] + item?.position[3] > maxY) {
        maxY = item?.position[1] + item?.position[3];
      }
    });
    return { maxX, maxY };
  };
  const compName = params['*'];

  const searchParamsNotMultifield = () => {
    const objCopy = { ...searchParams };
    // delete objCopy.multiField

    return objCopy;
  };

  const onFinish = (values: CreateWidgetModel) => {
    const obj: { [key: string]: number[] } = {
      LIST: [convertData().maxX, convertData().maxY, 6, 4],
      PIE_CHART: [convertData().maxX, convertData().maxY, 5, 6],
      CHART: [convertData().maxX, convertData().maxY, 5, 6],
      NUMBER: [convertData().maxX, convertData().maxY, 3, 2],
      TIMELINE: [convertData().maxX, convertData().maxY, 5, 5]
    };

    const objDataActionType = JSON.stringify({
      ...searchParamsNotMultifield(),
      actionTypes: Array.isArray(searchParams?.actionTypes) ? searchParams?.actionTypes : [searchParams?.actionTypes],
      pipeId: Number(searchParams?.voronka),
      page: searchParams?.page ? Number(searchParams?.page) : 1,
      // eslint-disable-next-line no-nested-ternary
      responsibleByIds: searchParams?.responsibleByIds ? (Array.isArray(searchParams?.responsibleByIds) ? searchParams?.responsibleByIds : [searchParams?.responsibleByIds]) : [],
      currentStatuses: Array.isArray(searchParams?.currentStatuses) ? searchParams?.currentStatuses : [searchParams?.currentStatuses],
      previousStatuses: Array.isArray(searchParams?.previousStatuses) ? searchParams?.previousStatuses : [searchParams?.previousStatuses]
    }).replaceAll(/"/g, "'");

    const objDataCrm = JSON.stringify({
      ...searchParamsNotMultifield(),
      pipeId: Number(searchParams?.voronka),
      page: searchParams?.page ? Number(searchParams?.page) : 1
    }).replaceAll(/"/g, "'");

    const objDataTask = JSON.stringify({
      ...searchParamsNotMultifield(),
      page: searchParams?.page ? Number(searchParams?.page) : 1,
      // eslint-disable-next-line no-nested-ternary
      employeeList: searchParams?.employeeList ? (Array.isArray(searchParams?.employeeList) ? searchParams?.employeeList : [searchParams?.employeeList]) : []
    }).replaceAll(/"/g, "'");

    const sendData: CreateWidgetModel = {
      ...values,
      type: selectItem?.type,
      objectData: compName === 'crm' ? objDataCrm : objDataActionType,
      position: obj[selectItem?.type],
      url:
        // eslint-disable-next-line no-nested-ternary
        selectItem?.type === 'PIE_CHART' || selectItem?.type === 'CHART'
          ? `${BASE_URL}/v1/dashboard/widget/chart/v2`
          : // eslint-disable-next-line no-nested-ternary
            selectItem?.type === 'TIMELINE'
            ? selectedDash?.startDate && selectedDash?.finishDate
              ? `${BASE_URL}/v1/activity-logs/filter/widget?startDate=${selectedDash?.startDate}&finishDate=${selectedDash?.finishDate}`
              : `${BASE_URL}/v1/activity-logs/filter/widget?startDate=${startWeek}&finishDate=${endWeek}`
            : // eslint-disable-next-line no-nested-ternary
              selectItem?.type === 'NUMBER'
              ? // eslint-disable-next-line no-nested-ternary
                compName === 'crm'
                ? `${BASE_URL}/v1/lead/table-filter/number?dashboardId=${selectDashboard}`
                : compName === 'task'
                  ? `${BASE_URL}/v1/lead-task/table/filter/number?dashboardId=${selectDashboard}`
                  : `${BASE_URL}/v1/activity-logs/filter/number?dashboardId=${selectDashboard}`
              : // eslint-disable-next-line no-nested-ternary
                selectItem?.type === 'LIST'
                ? // eslint-disable-next-line no-nested-ternary
                  compName === 'crm'
                  ? `${BASE_URL}/v1/lead/table-filter`
                  : compName === 'task'
                    ? `${BASE_URL}/v1/lead-task/table/filter`
                    : `${BASE_URL}/v1/activity-logs/filter/widget`
                : '',
      requestType: 'POST',
      fields: JSON.stringify(selectedItems?.map(item => item?.replaceAll(/"/g, "'"))),
      groupBy: selectItem?.type === 'NUMBER' || selectItem?.type === 'TIMELINE' || selectItem?.type === 'LIST' ? null : optionVal.split(':')[0],
      customFieldId: Number(optionVal.split(':')[1]),
      // eslint-disable-next-line no-nested-ternary
      objectType: compName === 'crm' ? 'LEAD' : compName === 'task' ? 'TASK' : 'LOG'
    };
    const sendData1: CreateWidgetModel = {
      ...values,
      type: selectItem?.type,
      objectData:
        compName === 'task'
          ? objDataTask
          : JSON.stringify({
              ...searchParamsNotMultifield(),
              // eslint-disable-next-line no-nested-ternary
              responsibleByIds: searchParams?.responsibleByIds
                ? Array.isArray(searchParams?.responsibleByIds)
                  ? searchParams?.responsibleByIds
                  : [searchParams?.responsibleByIds]
                : [],
              page: searchParams?.page ? Number(searchParams?.page) : 1
            }).replaceAll(/"/g, "'"),
      position: obj[selectItem?.type],
      url:
        // eslint-disable-next-line no-nested-ternary
        selectItem?.type === 'PIE_CHART' || selectItem?.type === 'CHART'
          ? `${BASE_URL}/v1/dashboard/widget/chart/v2`
          : // eslint-disable-next-line no-nested-ternary
            selectItem?.type === 'TIMELINE'
            ? selectedDash?.startDate && selectedDash?.finishDate
              ? `${BASE_URL}/v1/activity-logs/filter/widget?startDate=${selectedDash?.startDate}&finishDate=${selectedDash?.finishDate}`
              : `${BASE_URL}/v1/activity-logs/filter/widget?startDate=${startWeek}&finishDate=${endWeek}`
            : // eslint-disable-next-line no-nested-ternary
              selectItem?.type === 'NUMBER'
              ? // eslint-disable-next-line no-nested-ternary
                compName === 'crm'
                ? `${BASE_URL}/v1/lead/table-filter/number?dashboardId=${selectDashboard}`
                : compName === 'task'
                  ? `${BASE_URL}/v1/lead-task/table/filter/number?dashboardId=${selectDashboard}`
                  : `${BASE_URL}/v1/activity-logs/filter/number?dashboardId=${selectDashboard}`
              : // eslint-disable-next-line no-nested-ternary
                selectItem?.type === 'LIST'
                ? // eslint-disable-next-line no-nested-ternary
                  compName === 'crm'
                  ? `${BASE_URL}/v1/lead/table-filter`
                  : compName === 'task'
                    ? `${BASE_URL}/v1/lead-task/table/filter`
                    : `${BASE_URL}/v1/activity-logs/filter/widget`
                : '',
      requestType: 'POST',
      fields: JSON.stringify(selectedItems?.map(item => item?.replaceAll(/"/g, "'"))),
      groupBy: selectItem?.type === 'NUMBER' || selectItem?.type === 'TIMELINE' || selectItem?.type === 'LIST' ? null : optionVal.split(':')[0],
      customFieldId: Number(optionVal.split(':')[1]),
      // eslint-disable-next-line no-nested-ternary
      objectType: compName === 'crm' ? 'LEAD' : compName === 'task' ? 'TASK' : 'LOG'
    };

    const finalData = searchParams?.actionTypes || searchParams?.voronka ? sendData : sendData1;

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    finalData?.type
      ? createWidget.mutateAsync(finalData).then(() => {
          navigate(`${rootPaths.MAIN + MainPaths.DASHBOARD}?dashboardId=${selectDashboard!}`);
          handleClose();
        })
      : message.warn('Widget turini tanlang !');
  };

  return (
    <Modal visible={openDashboard} onCancel={handleClose} className={styles.dashboardModal} width={1210} footer={false}>
      <div className={styles.vidgets}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label={t('emp_dashboard.Vidjet_nomi')} name="name" rules={[{ required: true, message: '' }]}>
            <Input value={nameVal} onChange={e => setNameVal(e.target.value)} />
          </Form.Item>
          <Form.Item label={t('titles.Dashboard')} name="dashboardId" rules={[{ required: true, message: '' }]}>
            <Select onChange={e => setSelectDashboard(e)} placeholder={t('home.Tanlang')}>
              {dashboard?.map(
                item =>
                  item?.dashboardUsersDto![0]?.permission !== 'READ' && (
                    <Option key={item?.id} value={item?.id}>
                      {item?.name}
                    </Option>
                  )
              )}
            </Select>
          </Form.Item>
          <div className={styles.allVidgets}>
            {vidgets
              ?.filter(prev => !prev?.permission?.includes(params['*']))
              ?.map(item => (
                <div
                  className={styles.allVidgets_item}
                  onClick={() => setSelectItem({ id: item?.id, type: item?.type })}
                  key={item?.id}
                  style={{
                    borderColor: selectItem?.id === item?.id ? '#00A389' : ''
                  }}
                >
                  {item?.icon}
                  <div className={styles.allVidgets_item_text}>
                    <p>{item?.title}</p>
                    <span>{item?.desc}</span>
                  </div>
                  {item?.settings && (
                    <span className={styles.allVidgets_item_settings} onClick={handleOpenSettingsModal}>
                      {item?.settings}
                    </span>
                  )}
                  {item?.selectData && (
                    <Form.Item name={item?.type}>
                      <Select placeholder={t('home.Tanlang')} value={optionVal} onChange={e => setOptionVal(e)}>
                        {compName === 'crm' && <Option value="PIPE_STATUS">Manba bo'yicha</Option>}
                        {/* eslint-disable-next-line no-nested-ternary, no-nested-ternary, no-nested-ternary, no-nested-ternary */}
                        {compName === 'crm' ? (
                          <>
                            <Option value="EMPLOYEE">{t('emp_dashboard.Masul_xodim_boyicha')}</Option>
                            {item?.selectData?.map(item => (
                              <Option key={item?.id} value={`CUSTOM_FIELD:${item?.id}`}>
                                {item?.name}
                              </Option>
                            ))}
                          </>
                        ) : compName === 'task' ? (
                          <>
                            <Option value="EMPLOYEE">{t('emp_dashboard.Masul_xodim_boyicha')}</Option>
                            <Option value="TASK_TYPE">{t('triggers.Topshiriq_turi')}</Option>
                          </>
                        ) : (
                          <>
                            <Option value="EMPLOYEE">{t('emp_dashboard.Masul_xodim_boyicha')}</Option>
                            <Option value="EVENT_TYPE">{t('home.Voqea_turi')}</Option>
                          </>
                        )}
                      </Select>
                    </Form.Item>
                  )}
                </div>
              ))}
          </div>
          <div className={styles.dashboardModal_footer}>
            <Button onClick={handleClose}>{t('one_lid.Yopish')}</Button>
            <Button htmlType="submit" loading={createWidget.isLoading}>
              {t('settingEmployee.Yaratish')}
            </Button>
          </div>
        </Form>
      </div>
      <TableSettingsModal selectedItems={selectedItems} setSelectedItems={setSelectedItems} visible={openSettings} setVisible={setOpenSettings} />
    </Modal>
  );
};

export default DashboardModal;
