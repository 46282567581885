import React, { useRef, useState } from 'react';
import { Dropdown, Input, message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import telegram from '../../../assets/telegram.svg';

import styles from './contacts.module.scss';
import AddCircleIcon from '../../../../../assets/icons/AddCircleIcon';
import { addPhoneInsideContact, changePhoneInsideContact } from '../../../../../store/reducers/CrmOneLidSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/reduxHooks';
import CallIcon from '../../../../../assets/icons/CallIcon';
import SmsIcon from '../../../../../assets/icons/SmsIcon';
import DocumentCopyIcon from '../../../../../assets/icons/DocumentCopyIcon';
import Edit2Icon from '../../../../../assets/icons/Edit2Icon';
import { handleOpen } from '../../../../../store/reducers/SendMessageCrmSlice';
import { useCallOnlinePbx } from '../../../../../queries/mutation';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { MyCallsMessageType } from '../../../../../utils/models/MyCalls';
import { helper } from '../../../../../utils/helper/helper';
import { setCalling, setResedTimer, setRunningCall, setStartTimer } from '../../../../../store/reducers/WebRTCSlice';
import { phoneCleanCode } from '../../../../../utils/helper/phoneCleanCode';
import { useOnlinePbxCanMakeCall } from '../../../services/queries';
import { useMyCallsMakeCall } from '../../../services/mutation';

interface Props {
  indexContact: number;
  indexPhone: number;
  phone: string;
  contractPhoneLength: number;
  isMoiZvonki: boolean;
}

const OneNumber: React.FC<Props> = ({ contractPhoneLength, phone, indexPhone, indexContact, isMoiZvonki }) => {
  const { vertoRef, disable } = useAppSelector(state => state?.webRTCReducer);
  const { params } = useQueryParam<any, { id: string }>();

  const { data: canMakeCAll } = useOnlinePbxCanMakeCall();

  const [isEditable, setIsEditable] = useState(phone === '');
  const [dropdown, setDropdown] = useState(false);

  const inputRef = useRef(null);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const makeCall = useMyCallsMakeCall();
  const call = useCallOnlinePbx(handleSuccessCall);

  // handle success call
  function handleSuccessCall() {
    handleDropDown(false);
  }

  // add phone number inside
  const handleAddPhoneNumberOneContact = (indexContact: number) => {
    dispatch(addPhoneInsideContact({ indexContact }));
  };

  // handle open send message modal
  const handleMessageModal = (type: MyCallsMessageType) => {
    dispatch(
      handleOpen({
        visible: true,
        type,
        phone
      })
    );
    handleDropDown(false);
  };

  // handle dropdown
  const handleDropDown = (visible: boolean) => {
    setDropdown(visible);
  };

  // change editable
  const changeEditable = (editable: boolean) => {
    setIsEditable(editable);
  };

  // handle blur
  const handleBlur = () => {
    if (phone) {
      changeEditable(false);
    }
  };

  // change value one phone
  const handleChangeValueOnePhone = (indexContact: number, indexPhone: number, value: string) => {
    dispatch(
      changePhoneInsideContact({
        value: helper.deleteSpace(value),
        indexPhone,
        indexContact
      })
    );
  };

  // handle copy
  const handleCopy = () => {
    navigator.clipboard.writeText(phone);
    message.success(t('integration.Nusxa_olindi'));
    handleDropDown(false);
  };

  // handle click editable
  const handleClickEditable = () => {
    changeEditable(true);
    handleDropDown(false);
    setTimeout(() => {
      // @ts-ignore
      inputRef.current!.focus({
        cursor: 'end'
      });
    });
  };

  // make call
  const callHandler = () => {
    if (params.id !== 'new') {
      makeCall.mutate({
        to: phone,
        leadId: Number(params.id)
      });
    }
    handleDropDown(false);
  };

  // make call
  const callOnlinePbxHandler = async () => {
    if (disable) {
      let stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      let calling = vertoRef?.call(stream.getTracks(), phone, {
        callee_id_name: canMakeCAll?.employeeName || '',
        callee_id_number: phone,
        caller_id_name: canMakeCAll?.employeeName || '',
        caller_id_number: phone
      });
      dispatch(setCalling(true));
      dispatch(setRunningCall(calling));
      calling?.subscribeEvent('track', track => {
        if (track.kind !== 'audio') return;
        let stream = new MediaStream();
        stream.addTrack(track);
        let el: any = document.getElementById('webRtcVideo');
        el.srcObject = stream;
      });

      calling?.subscribeEvent('answer', () => {
        dispatch(setResedTimer(false));
        dispatch(setStartTimer(true));
      });

      calling?.subscribeEvent('bye', (cause: any) => {
        dispatch(setCalling(false));
        dispatch(setStartTimer(false));
        dispatch(setResedTimer(true));
      });
    } else {
      if (params.id !== 'new' && canMakeCAll) {
        call.mutate({
          domain: canMakeCAll.onlinePbxDto?.domain as string,
          from: canMakeCAll?.num,
          to: phone,
          key: canMakeCAll.onlinePbxDto?.key as string,
          keyId: canMakeCAll.onlinePbxDto?.keyId as string
        });
      }
    }
  };

  return (
    <div className={styles.container_body_item}>
      <span>{indexPhone === 0 && t('home.Telefon_raqam')}</span>
      <div>
        {contractPhoneLength - 1 === indexPhone && <AddCircleIcon onClick={() => handleAddPhoneNumberOneContact(indexContact)} />}
        <Input
          onChange={e => handleChangeValueOnePhone(indexContact, indexPhone, e.target.value)}
          ref={inputRef}
          onBlur={handleBlur}
          value={phone}
          bordered={false}
          placeholder={'...'}
          style={{
            display: isEditable ? 'inline-block' : 'none'
          }}
          className={styles.input}
        />
        {!isEditable && (
          <Dropdown
            visible={dropdown}
            onVisibleChange={handleDropDown}
            overlayClassName={styles.dropdown}
            overlay={
              <Spin spinning={call.isLoading}>
                {canMakeCAll ? (
                  <div onClick={callOnlinePbxHandler} className={styles.dropdown_item}>
                    <CallIcon className={styles.dropdown_item_icon} />
                    <span className={styles.dropdown_item_title}>{t('home.Qongiroq_qilish')}</span>
                  </div>
                ) : (
                  <a href={'tel:' + phoneCleanCode(phone)} className={styles.dropdown_item}>
                    <CallIcon className={styles.dropdown_item_icon} />
                    <span className={styles.dropdown_item_title}>{t('home.Qongiroq_qilish')}</span>
                  </a>
                )}
                {isMoiZvonki && (
                  <>
                    <div onClick={callHandler} className={styles.dropdown_item}>
                      <CallIcon className={styles.dropdown_item_icon} />
                      <span className={styles.dropdown_item_title}>{t('my_calls.Qongiroq_qilish')}</span>
                    </div>
                    <div onClick={() => handleMessageModal(MyCallsMessageType.MOI_ZVONKI)} className={styles.dropdown_item}>
                      <SmsIcon className={styles.dropdown_item_icon} />
                      <span className={styles.dropdown_item_title}>{t('my_calls.SMS_yuborish_moi_zvonki')}</span>
                    </div>
                  </>
                )}

                <div onClick={() => handleMessageModal(MyCallsMessageType.PLAY_MOBILE)} className={styles.dropdown_item}>
                  <SmsIcon className={styles.dropdown_item_icon} />
                  <span className={styles.dropdown_item_title}>{t('my_calls.SMS_yuborish')}</span>
                </div>
                <div
                  onClick={() => {
                    const phoneNumber = phone.startsWith('+998') ? phone : '+998' + phone;
                    window.open(`https://t.me/${phoneNumber}`, '_blank');
                  }}
                  className={styles.dropdown_item}
                >
                  <img src={telegram} className={styles.dropdown_item_icon} />
                  <span className={styles.dropdown_item_title}>{t('home.Telegramda_ochish')}</span>
                </div>

                <div onClick={handleCopy} className={styles.dropdown_item}>
                  <DocumentCopyIcon className={styles.dropdown_item_icon} />
                  <span className={styles.dropdown_item_title}>{t('my_calls.Nusxalash')}</span>
                </div>
                <div onClick={handleClickEditable} className={styles.dropdown_item}>
                  <Edit2Icon className={styles.dropdown_item_icon} />
                  <span className={styles.dropdown_item_title}>{t('my_calls.Tahrirlash')}</span>
                </div>
              </Spin>
            }
            trigger={['click']}
          >
            <div onClick={() => handleDropDown(true)} className={styles.phone}>
              {phone}
            </div>
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export default OneNumber;
