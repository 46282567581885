/* eslint-disable react/jsx-key */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { useGetCurrency } from '@pages/order-id-v2/services/queries';
import { OneStaff, StaffTable } from '@utils/models/Staff';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

import { usePayStaff } from '../../queries/mutation';
import { helper } from '../../utils/helper/helper';
import { ACTION_EVENTS_GA, TYPE_EVENTS_GA } from '../../utils/models/GoogleAnalitks';
import { PaymentType } from '../../utils/models/PaymentType';

import styles from '../../pages/builderSettings/builderSettings.module.scss';

interface IProps {
  visible: boolean;
  onClose: (visible: boolean) => void;
  id: number;
  date: string[];
  staff?: StaffTable;
  data: OneStaff; // Add data prop
}

interface IForm {
  amount: string;
  type: PaymentType;
  currencyId: number;
  balanceId: number;
  currency?: {
    id?: number;
  };
}

const { Option } = Select;

const OutComeModal: React.FC<IProps> = ({ visible, onClose, id, date, data }) => {
  const [form] = Form.useForm<IForm>();
  const { data: dataCurrency } = useGetCurrency();

  // console.log('dduck1', data?.balances?.find((item) => item?.currencyDto?.id === form.getFieldValue("currencyId"))?.id);
  // console.log('dduck2', data);

  // console.log('object', form.getFieldValue('currencyId'));

  const createPayment = usePayStaff(id, handleCancel, date);

  const { t } = useTranslation();

  const onFinish = (fields: IForm) => {
    createPayment.mutate({
      amount: helper.parseFloatStringToNumber(fields.amount),
      type: fields.type,
      currency: {
        id: fields.currencyId
      },
      balanceId: fields.balanceId
    });
    outComeMoneyEvent();
  };

  function handleCancel() {
    onClose(false);
    form.resetFields();
  }

  const handleOk = () => {
    handleCancel();
  };

  // outcome money events
  const outComeMoneyEvent = () => {
    ReactGA.event({
      category: TYPE_EVENTS_GA.KPI,
      action: ACTION_EVENTS_GA.CONFIRM_OUTCOME_MONEY_KPI
    });
  };

  return (
    <Modal width={546} className="createBuilderModal" title={t('broker.Pul_chiqarish')} visible={visible} onOk={handleOk} footer={false} onCancel={handleCancel}>
      <Form form={form} layout="vertical" onFinish={onFinish} className={styles.formBuilderModal}>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item name="balanceId" style={{ display: 'none' }} />
            <Form.Item
              name="amount"
              rules={[
                {
                  required: true,
                  message: ''
                },
                {
                  validator: (rule, value) => {
                    if (value === undefined || value === null) {
                      return Promise.reject('');
                    }
                    form.setFieldsValue({
                      amount: helper.deleteNotNumbersAndParseFloatNumber(value).string
                    });
                    return Promise.resolve();
                  }
                }
              ]}
              label={t('broker.Tolov_summasi')}
            >
              <Input placeholder="..." />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t('broker.Tolov_turi')} name="type" rules={[{ required: true, message: '' }]}>
              <Select className="customSelect" placeholder="...">
                <Option value={PaymentType.CASH}>{t(`home.${PaymentType.CASH}`)}</Option>
                <Option value={PaymentType.CARD}>{t(`home.${PaymentType.CARD}`)}</Option>
                <Option value={PaymentType.BANK}>{t(`home.${PaymentType.BANK}`)}</Option>
                <Option value={PaymentType.TRANSFER}>{t(`home.${PaymentType.TRANSFER}`)}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <div className="mb-[20px] flex items-center justify-between">
              <Form.Item
                name="currencyId"
                initialValue={dataCurrency?.find((currency: any) => currency.ccy === 'UZS')?.id}
                rules={[{ message: '', required: true }]}
                className="mr-[35px]"
                label={
                  <label
                    style={{
                      color: 'var(--philippine-gray)',
                      fontSize: '14px'
                    }}
                  >
                    {t('create_payment.Valyuta')}
                  </label>
                }
              >
                <Select className="customSelect" style={{ width: 230 }} placeholder={dataCurrency?.find((currency: any) => currency.ccy === 'UZS')?.ccy}>
                  {data?.balances?.map((item: any) => <Option value={item?.currencyDto?.id}>{item?.currencyDto?.ccy}</Option>)}
                </Select>
              </Form.Item>

              <Form.Item
                name="balanceId"
                // initialValue={dataCurrency?.find((currency: any) => currency.ccy === 'UZS')?.id}
                rules={[{ message: '', required: true }]}
                label={
                  <label
                    style={{
                      color: 'var(--philippine-gray)',
                      fontSize: '14px'
                    }}
                  >
                    {t('client_balance.Balans')}
                  </label>
                }
              >
                <Select className="customSelect" style={{ width: 230 }} placeholder={dataCurrency?.find((currency: any) => currency.ccy === 'UZS')?.ccy}>
                  {data?.balances?.map((item: any) => <Option value={item?.id}>{item?.currencyDto?.ccy}</Option>)}
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>

        <div className={styles.modalFooter}>
          <Button onClick={handleCancel}> {t('home.Yopish')}</Button>
          <Button loading={createPayment.isLoading} htmlType="submit" type="primary">
            {t('broker.Tasdiqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default OutComeModal;
