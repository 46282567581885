import ShowroomList from '@pages/showroom-v2/pages/showroom-list/ShowroomList';
import { showroomPath } from './showroomPath';
import ShowroomShaxmatka from '@pages/showroom-v2/pages/showroom-shaxmatka/ShowroomShaxmatka';
import ShowroomShaxmatkaPlus from '@pages/showroom-v2/pages/showrrom-shaxmatka-plus/ShowroomShaxmatkaPlus';
import ShowroomVisual from '@pages/showroom-v2/pages/showroom-visual/ShowroomVisual';

export const showroomRoutes = [
  {
    path: showroomPath.LIST,
    component: ShowroomList,
    exact: true
  },
  {
    path: showroomPath.SHAXMATKA,
    component: ShowroomShaxmatka,
    exact: true
  },
  {
    path: showroomPath.SHAXMATKA_PLUS,
    component: ShowroomShaxmatkaPlus,
    exact: true
  },
  {
    path: showroomPath.VISUAL,
    component: ShowroomVisual,
    exact: false
  }
];
