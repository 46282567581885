import { $api } from '@service/RequestService';
import { message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { WebsiteUserType } from '../utils/models/WebsiteUserType';

export function usePostWebsiteUser() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (data: any) => {
      const { roles, ...rest } = data;
      const res = await $api.post(endPoints.POST_WEBSITE_USER, { ...rest });

      return res.data;
    },
    onSuccess: success => {
      qc.invalidateQueries({ queryKey: [queryKeys.GET_WEBSITE_ALL_COMPANY] });
      message.success(success?.message?.uz);
    },
    onError: (err: any) => {
      message.error(err?.response?.data?.message?.uz);
    }
  });
}

export function usePutWebsiteUser() {
  const qc = useQueryClient();

  return useMutation<any, any, { id: number; data: WebsiteUserType }>({
    mutationFn: async props => {
      const res = await $api.put(`${endPoints.PUT_WEBSITE_USER}/${props?.id}`, props?.data);

      return res.data;
    },
    onSuccess: data => {
      qc.invalidateQueries({ queryKey: [queryKeys.GET_WEBSITE_ALL_COMPANY] });
      message.success(data.message.uz);
    },
    onError: (data: any) => {
      message.error(data.response.data.message.uz);
    }
  });
}

export function useDeleteWebsiteUser() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => {
      const res = await $api.delete(`${endPoints.DELETE_WEBSITE_USER}/${id}`);

      return res.data;
    },
    onSuccess: data => {
      qc.invalidateQueries({ queryKey: [queryKeys.GET_WEBSITE_ALL_COMPANY] });
      message.success(data.message.uz);
    },
    onError: (data: any) => {
      message.error(data.response.data.message.uz);
    }
  });
}
