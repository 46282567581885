import { useMutation } from 'react-query';
import { endPoints } from '../utils/constants/endPoints';
import { $api } from '@service/RequestService';
import { baseErrorHandler } from '@queries/baseErrorHandler';
import { showMessage } from '@queries/showMessage';

export function useCompanyPermission() {
  return useMutation(
    async (data: { id: number; countRoles: number; permissions: string[] }) => {
      const res = await $api.post(endPoints.COMPANY_PERMISSION, data);
      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}
