import { Button, Col, Form, Input, Modal, Row, Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';

import styles from './createCostModal.module.scss';
import { useTranslation } from 'react-i18next';
import ReactInputMask from 'react-input-mask';
import InfoIcon from '../../../../assets/icons/InfoIcon';
import { validateDateString } from '../../../../utils/helper/validatorDateString';
import CalendarIcon from '../../../../assets/icons/CalendarIcon';
import { useBasicCurrency, useCurrencyList, usePlanFactCompanyLeadField } from '../../../../queries/queries';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { CrmFieldsType } from '../../../../utils/models/CrmFieldsType';
import { helper } from '../../../../utils/helper/helper';
import { useSavePlanFactCost } from '../../../../queries/mutation';
import { Currency, CurrencyItem } from '../../../../utils/models/Currency';

const { Option } = Select;

interface IProps {
  visible: boolean;
  page: number;
  close: (visible: boolean) => void;
}

interface IForm {
  startDate: string;
  finishDate: string;
  fieldOptionId: number;
  amount: string;
}

const CreateBuilderSettings: React.FC<IProps> = ({ visible, close, page }) => {
  const [activeCurrency, setActiveCurrency] = useState<CurrencyItem | undefined>(undefined);
  const [form] = Form.useForm<IForm>();

  const { t } = useTranslation();

  const { data: currency } = useCurrencyList();

  const { planFactId, startDate, finishData, costTypeLead } = useAppSelector(state => state.crmStatistic);

  const save = useSavePlanFactCost(startDate, finishData, page, costTypeLead, handleSuccess);

  const { isLoading, data: field } = usePlanFactCompanyLeadField(planFactId);
  const onFinish = (fields: IForm) => {
    save.mutate({
      amount: helper.parseFloatStringToNumber(fields.amount),
      startDate: fields.startDate,
      finishDate: fields.finishDate,
      currency: activeCurrency?.ccy,
      currencyId: activeCurrency?.id,
      companyLeadFieldId: field?.id as number,
      fieldOptionId: field?.type === CrmFieldsType.SELECT ? fields.fieldOptionId : undefined
    });
  };
  const handleCancel = () => {
    close(false);
    form.resetFields();
  };

  // handle success
  function handleSuccess() {
    handleCancel();
  }

  useEffect(() => {
    if (currency) {
      setActiveCurrency(currency?.find(item => item?.basic));
    }
  }, [currency]);

  return (
    <Modal
      width={546}
      className={styles.container}
      title={t('boss_crm.Xarajat_qoshish')}
      visible={visible}
      centered={true}
      footer={false}
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} className={styles.container_form}>
        <Row>
          <Col className={styles.container_form_left} flex={'1'}>
            <Form.Item
              label={
                <div className={'d-flex align-center'}>
                  <span>{t('boss_crm.Xarajat_turi')}</span>
                  <Tooltip title={t('boss_crm.hover_info')}>
                    <span className={styles.container_info_icon}>
                      <InfoIcon />
                    </span>
                  </Tooltip>
                </div>
              }
              name={'fieldOptionId'}
              rules={[{ required: true, message: '' }]}
            >
              <Select loading={isLoading} optionFilterProp={'children'} showSearch className={'customSelect'} placeholder={t('home.Tanlang')}>
                {field?.type === CrmFieldsType.SELECT ? (
                  field?.options?.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))
                ) : (
                  <Option value={field?.id}>{field?.name}</Option>
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label={t('boss_crm.Sana_dan')}
              name="startDate"
              rules={[
                {
                  validator: (rule, value) => {
                    if (value) {
                      form.setFieldsValue({
                        startDate: validateDateString(value, true)
                      });
                    }
                    return Promise.resolve();
                  }
                },
                {
                  required: true,
                  len: 10,
                  message: ''
                }
              ]}
            >
              <ReactInputMask
                mask="99.99.9999"
                // @ts-ignore*
                maskChar={''}
              >
                {(inputProps: any) => (
                  <Input className={'customInput'} placeholder={t('client_create_contract.kk_oo_yyyy')} suffix={<CalendarIcon />} {...inputProps} />
                )}
              </ReactInputMask>
            </Form.Item>
          </Col>
          <Col className={styles.container_form_right} flex={'1'}>
            <Form.Item label={t('boss_crm.Xarajat_qilingan_summa')} name={'amount'} rules={[{ required: true, message: '' }]}>
              <Form.Item
                noStyle
                name={'amount'}
                rules={[
                  { required: true, message: '' },
                  {
                    validator: (rule, value) => {
                      if (value === undefined || value === null) {
                        return Promise.reject('');
                      }
                      form.setFieldsValue({
                        amount: helper.deleteNotNumbersAndParseFloatNumber(value).string
                      });
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input className={styles.container_input} />
              </Form.Item>
              <Select
                value={activeCurrency?.id}
                onChange={value => {
                  setActiveCurrency(currency?.find(item => item?.id === value));
                }}
                className={'customSelect ' + styles.container_select}
              >
                {currency?.map(item => (
                  <Option key={item?.id} value={item?.id}>
                    {item?.ccy}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t('boss_crm.Sana_gacha')}
              name="finishDate"
              rules={[
                {
                  validator: (rule, value) => {
                    if (value) {
                      form.setFieldsValue({
                        finishDate: validateDateString(value, true)
                      });
                    }
                    return Promise.resolve();
                  }
                },
                {
                  required: true,
                  len: 10,
                  message: ''
                }
              ]}
            >
              <ReactInputMask
                mask="99.99.9999"
                // @ts-ignore*
                maskChar={''}
              >
                {(inputProps: any) => (
                  <Input className={'customInput'} placeholder={t('client_create_contract.kk_oo_yyyy')} suffix={<CalendarIcon />} {...inputProps} />
                )}
              </ReactInputMask>
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.container_footer}>
          <Button onClick={handleCancel}> {t('home.Yopish')}</Button>
          <Button loading={save.isLoading} htmlType="submit" type="primary">
            {t('home.Saqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateBuilderSettings;
