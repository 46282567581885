/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from 'react';
import InternationalPhoneNumber from '@components/international-phone-input/InternationalPhoneNumber';
import { Button, Col, Form, Input, Modal, Radio, Row, Space, Spin } from 'antd';
import { t } from 'i18next';
import { isValidNumber } from 'libphonenumber-js';
import ReactInputMask from 'react-input-mask';

import CalendarIcon from '../../assets/icons/CalendarIcon';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { SearchParamsCompanies } from '../../pages/companies/Companies';
import { useCreateAndEditCompanies } from '../../queries/mutation';
import { useOneCompany } from '../../queries/queries';
import { editAndCreateModal } from '../../store/reducers/CompaniesSlice';
import { validateDateString } from '../../utils/helper/validatorDateString';
import { CompaniesStatus } from '../../utils/models/Companies';

import 'react-international-phone/style.css';
import styles from '../../pages/login/login.module.scss';

interface FormI {
  name: string;
  phone: string;
  phoneCode: string;
  director: string;
  status: CompaniesStatus;
  expiry: string;
  flatsLimit: number | null;
  paymentDate: string;
  brand: string;
}

const CompanyCreateAndEditModal = () => {
  const [form] = Form.useForm<FormI>();
  const { searchParams } = useQueryParam<SearchParamsCompanies, any>();
  const { id, editAndCreateModalOpen } = useAppSelector(state => state.companiesReducer);
  const dispatch = useAppDispatch();
  const { isLoading, isFetching, data } = useOneCompany(id);

  const { clientPhones } = useAppSelector(state => state.clientReducer);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        phone: data.phone,
        phoneCode: data.phoneCode
      });
    }
  }, [data, form]);

  const saveCompany = useCreateAndEditCompanies(id, searchParams);

  const onFinish = (values: FormI) => {
    saveCompany.mutate({
      ...values,
      flatsLimit: Number(values?.flatsLimit) || null
    });
  };

  useEffect(() => {
    if (!editAndCreateModalOpen) {
      form.setFieldsValue({
        director: '',
        phone: '',
        phoneCode: '',
        name: '',
        status: CompaniesStatus.ACTIVE,
        expiry: '',
        flatsLimit: null,
        paymentDate: '',
        brand: ''
      });
    }
  }, [editAndCreateModalOpen, form]);

  function handleCancel() {
    dispatch(editAndCreateModal({ id: 0, open: false }));
  }

  return (
    <Modal
      title={id === 0 ? 'Kompaniya qo‘shish' : "Kompaniya ma'lumotlarini tahrirlash"}
      wrapClassName="settingEmployeeModal"
      visible={editAndCreateModalOpen}
      onCancel={handleCancel}
      footer={false}
      width={774}
    >
      <Spin spinning={isLoading || isFetching}>
        <Form onFinish={onFinish} layout="vertical" form={form}>
          <Row className="d-flex justify-space-between">
            <Col span={11}>
              <Form.Item label="Kompaniya nomi">
                <Form.Item noStyle name="name" rules={[{ required: true, message: '' }]}>
                  <Input />
                </Form.Item>
              </Form.Item>

              {clientPhones.map((phone, index) => (
                <Space direction="horizontal" className="d-flex" key={phone?.id}>
                  <div>
                    <Form.Item name="phoneCode" className="d-none" />

                    <div className="flex flex-col gap-[5px]">
                      <Form.Item
                        label={<span>{t(`changes.client.NaturalClient.Telefon_raqam`)}</span>}
                        name="phone"
                        rules={[
                          {
                            validator: (rule, value) => {
                              if (!(value && isValidNumber(value))) {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise.reject('');
                              }
                              return Promise.resolve();
                            }
                          }
                        ]}
                      >
                        <InternationalPhoneNumber
                          defaultCountry="uz"
                          className={styles.phoneInput}
                          onChange={(e, phone) => {
                            form.setFieldsValue({
                              [`phoneCode`]: `${phone?.country?.iso2?.toUpperCase()}`
                            });
                          }}
                        />
                      </Form.Item>

                      {/* {!isPhoneValid(phoneValue) && <span className="mt-[-10px] text-red-500">{t('profile.Telefon_raqamini_kiriting')}</span>} */}
                    </div>
                  </div>
                </Space>
              ))}

              <Form.Item label="Kompaniya Brendi">
                <Form.Item noStyle name="brand">
                  <Input />
                </Form.Item>
              </Form.Item>
              <Form.Item label="Status">
                <Form.Item name="status" rules={[{ required: true }]} noStyle>
                  <Radio.Group>
                    <Radio value={CompaniesStatus.ACTIVE}>Faol</Radio>
                    <Radio value={CompaniesStatus.BLOCKED}>Faol emas</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item label="Kompaniya rahbari">
                <Form.Item name="director" rules={[{ required: true }]} noStyle>
                  <Input />
                </Form.Item>
              </Form.Item>
              <Form.Item label="Amal qilish muddati">
                <Form.Item
                  noStyle
                  name="expiry"
                  rules={[
                    { message: '', len: 10 },
                    {
                      validator: (rule, value) => {
                        if (value) {
                          form.setFieldsValue({
                            expiry: validateDateString(value, true)
                          });
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <ReactInputMask mask="99.99.9999" maskChar="">
                    {/* @ts-ignore* */}
                    {(inputProps: any) => <Input placeholder="kk.oo.yyyy" suffix={<CalendarIcon />} {...inputProps} />}
                  </ReactInputMask>
                </Form.Item>
              </Form.Item>
              <Form.Item label={"To'lov sanasi"}>
                <Form.Item
                  noStyle
                  name="paymentDate"
                  rules={[
                    { message: '', len: 10 },
                    {
                      validator: (rule, value) => {
                        if (value) {
                          form.setFieldsValue({
                            paymentDate: validateDateString(value, true)
                          });
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <ReactInputMask mask="99.99.9999" maskChar="">
                    {/* @ts-ignore* */}
                    {(inputProps: any) => <Input placeholder="kk.oo.yyyy" suffix={<CalendarIcon />} {...inputProps} />}
                  </ReactInputMask>
                </Form.Item>
              </Form.Item>
              <Form.Item label="Xonadonlar soni">
                <Form.Item noStyle name="flatsLimit">
                  <Input type="number" />
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <div className="settingEmployeeModalFooter">
            <Button onClick={handleCancel}>Yopish</Button>
            <Button loading={saveCompany.isLoading} htmlType="submit" type="primary">
              Saqlash
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CompanyCreateAndEditModal;
