import React, { memo, useRef, useState } from 'react';
import styles from '../../pages/messageSettings/MessageSettings.module.scss';
import { Input, Switch, Spin } from 'antd';
import SuccessIcon from '../../assets/icons/SuccessIcon';
import { helper } from '../../utils/helper/helper';
import { MessageConfig } from '../../utils/models/MessageConfig';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import { useUpdateSmsConfig } from '../../queries/mutation';
import { useTranslation } from 'react-i18next';

interface Props {
  message: MessageConfig;
}

const SendingMessageCard: React.FC<Props> = ({ message }) => {
  const [interval, setInterval] = useState<number | null>(message.interval);
  const updateType = useRef<'active' | 'interval'>('active');
  const { t } = useTranslation();
  const updateMessage = useUpdateSmsConfig(message.smsTypeDifComDto.type);

  const handleIntervalChange = (e: any) => {
    setInterval(helper.deleteNotNumbers(e.target.value, null));
  };

  const handleSaveInterval = () => {
    updateType.current = 'interval';
    if (interval) {
      updateMessage.mutate({
        active: message.active,
        interval
      });
      return;
    }
  };

  const handleChangeActive = (e: boolean) => {
    updateType.current = 'active';
    updateMessage.mutate({
      active: e,
      interval
    });
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardTitle}>
        <p>{getCurrentLanguageWord(message.smsTypeDifComDto.name)}</p>
        <Switch
          loading={updateMessage.isLoading && updateType.current === 'active'}
          onChange={handleChangeActive}
          checked={message.active}
          size="small"
        />
      </div>
      <p>{getCurrentLanguageWord(message.smsTypeDifComDto.def)}</p>
      {message.interval && (
        <>
          <div className={styles.cardTitle + ' ' + styles.cardTitle_interval}>
            <p>{t('message_settings.SMS_yuborish_intervali_kun')}</p>
          </div>
          <div className={styles.interval}>
            <Input
              value={interval as any}
              onChange={handleIntervalChange}
              className={interval ? styles.interval_input : styles.interval_input_error}
            />
            <Spin spinning={updateMessage.isLoading && updateType.current === 'interval'}>
              <p onClick={handleSaveInterval} className={styles.saveButton}>
                <span>
                  <SuccessIcon />
                </span>
                {t('home.Saqlash')}
              </p>
            </Spin>
          </div>
        </>
      )}
    </div>
  );
};

const isEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.message.active === nextProps.message.active && prevProps.message.interval === nextProps.message.interval;
};

export default memo(SendingMessageCard, isEqual);
