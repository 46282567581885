import React from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import SearchIcon from '@icons/SearchIcon';
import { Input } from 'antd';

import { WebsiteUserType } from '../utils/models/WebsiteUserType';

import styles from '../../../components/filters/mainFilters.module.scss';

const WebsiteUserFilter = () => {
  const { searchParams, remove, appendSingle } = useQueryParam<WebsiteUserType, any>();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setTimeout(() => {
      if (e.target.value) appendSingle('search', e.target.value);
      else remove('search', undefined);
    });
  };

  return (
    <div>
      <Input suffix={<SearchIcon />} onChange={handleSearch} defaultValue={searchParams.search} placeholder="Qidirish" className={styles.developer_search} />
    </div>
  );
};

export default WebsiteUserFilter;
