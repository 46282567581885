import { baseErrorHandler } from '@queries/baseErrorHandler';
import { showMessage } from '@queries/showMessage';
import { $api } from '@service/RequestService';
import { message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { endPoints } from '../utils/helpers/endPoints';
import { queryKeys } from '../utils/helpers/queryKeys';
import { TGPremiumType } from '../utils/models/TGPremiumType';

export function usePostTGChatBotUser() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (data: TGPremiumType) => {
      const res = await $api.post(endPoints.TG_CHATBOT, { ...data });

      return res.data;
    },
    onSuccess: data => {
      qc.invalidateQueries({ queryKey: [queryKeys.TG_CHATBOT] });
      showMessage(data.message, data.accept);
    },
    onError: baseErrorHandler
  });
}

export function usePatchTGChatBotConfigUser() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (data: TGPremiumType) => {
      const res = await $api.patch(endPoints.PATCH_TG_CHATBOT_USERS, { ...data });

      return res.data;
    },
    onSuccess: data => {
      qc.invalidateQueries({ queryKey: [queryKeys.PATCH_TG_CHATBOT_USERS] });
      showMessage(data.message, data.accept);
    },
    onError: baseErrorHandler
  });
}

export function useDeleteTGChatBotConfigUser() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => {
      const res = await $api.delete(`${endPoints.DELETE_TG_CHATBOT_USER}/${id}`);

      return res.data;
    },
    onSuccess: data => {
      qc.invalidateQueries({ queryKey: [queryKeys.TG_CHATBOT] });
      message.success(data.message.uz);
    },
    onError: (data: any) => {
      message.error(data.response.data.message.uz);
    }
  });
}

export function useDeleteTGChatBotConfig() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => {
      const res = await $api.delete(`${endPoints.DELETE_TG_CHATBOT}`);

      return res.data;
    },
    onSuccess: data => {
      qc.invalidateQueries({ queryKey: [queryKeys.TG_CHATBOT] });
      message.success(data.message.uz);
    },
    onError: (data: any) => {
      message.error(data.response.data.message.uz);
    }
  });
}
