import { useMutation, useQueryClient } from 'react-query';

import { useAppDispatch } from '../../../hooks/reduxHooks';
import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { showMessage } from '../../../queries/showMessage';
import { $api } from '../../../service/RequestService';
import { clearTableMonthlyPayments, setSelectedClient, setSelectedContract } from '../../../store/reducers/ChangesSlice';
import { JuridicalPersonCreate, NaturalPersonCreate } from '../../../utils/models/Client';
import { ContractChanges } from '../../../utils/models/ContractId';
import { MonthlyPayment } from '../../../utils/models/CreateContract';
import { CurrencyItem } from '../../../utils/models/Currency';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';

export function useDeletePaymentChangesPage(contractId: string, paymentId: number, handleClose: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete(`${endPoints.CONTRACT_CHANGES}/${contractId}/payment/${paymentId}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.CHANGES_PAYMENT, contractId]);
        handleClose();
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreatePaymentChangesPage(contractId: string, handleClose: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { number?: string; amount: number; createdDate: string; type: string; currency: CurrencyItem | undefined }) => {
      const res = await $api.post(`${endPoints.CONTRACT_CHANGES}/${contractId}/payment`, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.CHANGES_PAYMENT, contractId]);
        handleClose();
      },
      onError: baseErrorHandler
    }
  );
}

export function useChangeTableMonthlyPaymentsChangesPage(id: string) {
  return useMutation(
    async (data: MonthlyPayment[]) => {
      const res = await $api.post(`${endPoints.CONTRACT_CHANGES}/${id}/monthlyPayment`, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateAndUpdateContract(id?: string) {
  const dispatch = useAppDispatch();
  const qc = useQueryClient();

  return useMutation(
    async (data: ContractChanges) => {
      let res;

      if (data?.id) {
        res = await $api.patch(`${endPoints.CONTRACT_CHANGES}/${data.id}`, data);
      } else {
        res = await $api.post(endPoints.CONTRACT_CHANGES, data);
      }
      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryKeys.CHANGES_CONTRACT_LIST, id]);
        dispatch(clearTableMonthlyPayments());
        dispatch(setSelectedContract(data.data));
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateAndUpdateNaturalPersonChanges() {
  const dispatch = useAppDispatch();

  return useMutation(
    async (data: NaturalPersonCreate) => {
      let res;

      if (data?.id) {
        res = await $api.patch(endPoints.CLIENT_CHANGES_NATURAL, data);
      } else {
        res = await $api.post(endPoints.CLIENT_CHANGES_NATURAL, data);
      }
      return res.data;
    },
    {
      onSuccess: data => {
        dispatch(
          setSelectedClient({
            data: data?.data,
            phones: data?.data.phones
          })
        );
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateAndUpdateJuridicalPersonChanges() {
  const dispatch = useAppDispatch();

  return useMutation(
    async (data: JuridicalPersonCreate) => {
      let res;

      if (data?.id) {
        res = await $api.patch(endPoints.CLIENT_CHANGES_JURIDICAL, data);
      } else {
        res = await $api.post(endPoints.CLIENT_CHANGES_JURIDICAL, data);
      }
      return res.data;
    },
    {
      onSuccess: data => {
        dispatch(
          setSelectedClient({
            data: data?.data,
            phones: data?.data.phones
          })
        );
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}
