import { Button, Form, Image, Input, Modal, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import PaperClipIcon from '../../../assets/icons/PaperClipIcon';
import { useNotificationCreate, useUploadFileDeveloper } from '../../../queries/mutation';
import { useGetImageByImageIdDeveloper, useNotificationId } from '../../../queries/queries';
import { BASE_URL } from '../../../service/RequestService';
import { defaultImage } from '../../../utils/constants/defaultImage';
import { FileObject } from '../../../utils/models/File';
import styles from './notificationDeveloperCreate.module.scss';
import { t } from 'i18next';

interface IProps {
  visible: boolean;
  id: number;
  setVisible: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      id: number;
    }>
  >;
}

const NotificationDeveloperCreate: FC<IProps> = ({ setVisible, visible, id }) => {
  const [form] = Form.useForm();
  const createNotificationMutate = useNotificationCreate();
  const [files, setFiles] = useState<FileObject | null>();
  const { data: imageData, isLoading: imageDataLoading } = useGetImageByImageIdDeveloper(files?.id ? files.id : 0);
  const { data, isLoading } = useNotificationId(id);

  const uploadFile = useUploadFileDeveloper(getFile);
  const handleClose = async () => {
    await form.resetFields();
    setFiles(null);
    setVisible({
      id: 0,
      visible: false
    });
  };

  function getFile(data: FileObject[]) {
    setFiles(data[0]);
  }

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0] && e.target.files[0]?.size <= 10000000) {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('files', file);
        uploadFile.mutate(formData);
      }
    }
  };

  const onFinish = (values: any) => {
    if (id) {
      createNotificationMutate.mutate({
        id: id,
        title: values.name,
        description: values.description,
        attachment: files?.absolutePath ? files?.absolutePath! : data?.attachment!,
        attachmentThumbnail: files?.thumbAbsolutePath ? files?.thumbAbsolutePath! : data?.attachmentThumbnail!
      });
    } else {
      createNotificationMutate.mutate({
        title: values.name,
        description: values.description,
        attachment: files?.absolutePath!,
        attachmentThumbnail: files?.thumbAbsolutePath!
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      name: data?.title,
      description: data?.description
    });
    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    handleClose();
    //eslint-disable-next-line
  }, [createNotificationMutate.isSuccess]);

  return (
    <Modal visible={visible} onCancel={handleClose} title="Yangi bildirishnoma yuborish" wrapClassName={styles.container} footer={false}>
      <Spin spinning={isLoading}>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item name={'name'} label="Title" rules={[{ required: true, message: '' }]}>
            <Input className="customInput" />
          </Form.Item>
          <Form.Item name={'description'} label="Description" rules={[{ required: true, message: '' }]}>
            <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} className="customInput" />
          </Form.Item>
          <div className={styles.container_fileUpload}>
            <Spin spinning={imageDataLoading}>
              {(imageData || data?.attachment) && (
                <Image
                  src={imageData?.fileBlob ? URL.createObjectURL(imageData?.fileBlob) : data?.attachment ? BASE_URL + '/' + data?.attachment : ''}
                  width={144}
                  height={144}
                  fallback={defaultImage}
                  style={{ borderRadius: 8 }}
                  preview={{ mask: t('client.Korish') }}
                />
              )}
            </Spin>
            <label htmlFor="notificationDevModalFile">
              <PaperClipIcon /> {t('bot_message.Fayl_biriktirish')}
            </label>
            <input type="file" id="notificationDevModalFile" accept="image/*" onChange={handleFile} />
          </div>
          <div className={styles.container_footer}>
            <Button onClick={handleClose}>{t('home.Bekor_qilish')}</Button>
            <Button type="primary" htmlType="submit" loading={createNotificationMutate.isLoading}>
              {t('home.Yuborish')}
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default NotificationDeveloperCreate;
