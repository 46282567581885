import React, { useEffect, useState } from 'react';
import { Pagination, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import PaginationNext from '../../assets/icons/PaginationNext';
import PaginationPrev from '../../assets/icons/PaginationPrev';
import { useQueryParam } from '../../hooks/useQueryParams';
import { BossPaymentsSearchParams } from '../../pages/bossPayments/BossPayments';
import { useBasicCurrency, useMobilePaymentsType } from '../../queries/queries';
import { DATE_FORMAT } from '../../utils/constants/format';
import { BossHomePayments } from '../../utils/models/BossHome';
import { BossPayments, BossPaymentsType } from '../../utils/models/BossPayments';
import { PaymentType } from '../../utils/models/PaymentType';

import styles from './tablePayments.module.scss';

interface Props {
  data: BossPayments[] | undefined;
}

const TablePayments: React.FC<Props> = ({ data }) => {
  const { data: paymentsType } = useMobilePaymentsType();
  const [currentPage, setCurrentPage] = useState(1);

  // console.log('DATA', data?.map((item) => item?.payment?.others));

  const { data: currency } = useBasicCurrency();

  const { searchParams } = useQueryParam<BossPaymentsSearchParams, any>();

  const { t } = useTranslation();

  useEffect(() => {
    setCurrentPage(1);
  }, [searchParams.type]);

  const columns: ColumnsType<BossPayments> | undefined = [
    {
      title: t('boss_payments.Sana'),
      dataIndex: 'date',
      align: 'left',
      render: (date: string) => {
        switch (searchParams.type) {
          case BossPaymentsType.MONTH:
            return `${t(`boss_payments.${dayjs(date, DATE_FORMAT).format('MMM')}`)} ${dayjs(date, DATE_FORMAT).format('YYYY')}`;
          case BossPaymentsType.YEAR:
            return dayjs(date, DATE_FORMAT).format('YYYY');
          default:
            return `${dayjs(date, DATE_FORMAT).format('DD')} ${t(`boss_payments.${dayjs(date, DATE_FORMAT).format('MMM')}`)}`;
        }
      }
    },
    {
      title: t('boss_payments.Naqd'),
      dataIndex: 'payment',
      align: 'left',
      render: (payment: BossHomePayments) => (
        <span>
          {payment?.cash.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.CASH
    },
    {
      title: t('boss_payments.Karta'),
      dataIndex: 'payment',
      align: 'left',
      render: (payment: BossHomePayments) => (
        <span>
          {payment?.card.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.CARD
    },
    {
      title: t('boss_payments.Bank'),
      dataIndex: 'payment',
      align: 'left',
      render: (payment: BossHomePayments) => (
        <span>
          {payment?.bank.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.BANK
    },
    {
      title: t('boss_payments.Pul_otkazmasi'),
      dataIndex: 'payment',
      align: 'left',
      render: (payment: BossHomePayments) => (
        <span>
          {payment?.transfer.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.TRANSFER
    },
    {
      title: t('boss_payments.Balansdan'),
      dataIndex: 'payment',
      align: 'left',
      render: (payment: BossHomePayments) => (
        <span>
          {payment?.balance.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.BALANCE
    },
    {
      title: 'My Uzcard',
      dataIndex: 'payment',
      align: 'left',
      render: (payment: BossHomePayments) => (
        <span>
          {payment?.myUzCard.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.MY_UZCARD
    },
    {
      title: 'Payme',
      dataIndex: 'payment',
      align: 'left',
      render: (payment: BossHomePayments) => (
        <span>
          {payment?.payme.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.PAYME
    },
    {
      title: 'Uzum',
      dataIndex: 'payment',
      align: 'left',
      render: (payment: BossHomePayments) => (
        <span>
          {payment?.apelsin?.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.UZUM
    },
    {
      title: 'Boshqa',
      dataIndex: 'payment',
      align: 'left',
      render: (payment: BossHomePayments) => (
        <span>
          {console.log('others', payment?.others)}
          {payment?.others?.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.OTHERS
    }
  ];

  // handle pagination
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // get table data
  const getTableData = () => {
    if (data) {
      const tableData = [...data];

      return tableData.splice((currentPage - 1) * 10, 10);
    }
    return [];
  };

  return (
    <>
      <div className={styles.table}>
        <Table columns={columns.filter(item => !item.key || paymentsType?.includes(item?.key as string))} dataSource={getTableData()} loading={false} pagination={false} />
      </div>
      <div className={styles.pagination}>
        <Pagination
          onChange={handlePageChange}
          prevIcon={
            <div className={styles.pagination_next_prev_buttons}>
              <PaginationPrev />
            </div>
          }
          nextIcon={
            <div className={styles.pagination_next_prev_buttons}>
              <PaginationNext />
            </div>
          }
          pageSize={10}
          showSizeChanger={false}
          current={currentPage}
          total={data?.length}
        />
      </div>
    </>
  );
};

export default TablePayments;
