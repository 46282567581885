import React, { useEffect } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { useGetActionTypes } from '@pages/activityLogs/services/queries';
import EmployeeFilter from '@pages/crm/components/filter/drawer/EmployeeFilter';
import i18n from '@service/I18nService';
import { textCapitalize } from '@utils/helper/textConverter';
import { Checkbox, Collapse, Drawer, Input, Radio, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';

import ColapseIcon from '../../../../assets/icons/ColapseIcon';
import FilterReloadIcon from '../../../../assets/icons/FilterReloadIcon';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import DatePicker from '../../../../service/datePicker';
import { DATE_FORMAT } from '../../../../utils/constants/format';
import ChangeEmployee from '../change-employee/ChangeEmployee';
import ChangeStatus from '../change-status/ChangeStatus';

import styles from './filterDrawer.module.scss';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

type Props = {
  setOpenFiltrDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  openFiltrDrawer: boolean;
};

export type ActivityLogs = {
  responsibleById: string | string[] | undefined;
  responsibleByIds?: string | string[] | undefined;
  startDate: string;
  finishDate: string;
  actionTypes: string[] | string | undefined;
  previous: string;
  current: string;
  previousStatuses: string[];
  currentStatuses: string[];
  objectStartDate: string;
  objectFinishDate: string;
};

const FiltrDrawer: React.FC<Props> = ({ setOpenFiltrDrawer, openFiltrDrawer }) => {
  const { removeAllSearchParams, searchParams, appendMultipleDifferent, appendSingle, remove, appendMultiple } = useQueryParam<ActivityLogs, any>();

  const { data } = useGetActionTypes();
  const pipeName = useAppSelector(state => state.crmOneLidField.pipeName);
  const pipeStatusId = useAppSelector(state => state.crmOneLidField.pipeStatusId);
  const pipeStatusName = useAppSelector(state => state.crmOneLidField.pipeStatusName);
  const oldPipeStatusId = useAppSelector(state => state.crmOneLidField.oldPipeStatusId);

  const deleteAllFilters = () => {
    removeAllSearchParams();
  };

  // handleClose
  const handleClose = () => {
    setOpenFiltrDrawer(false);
  };

  const changeDate = (values: any, formatString: [string, string]) => {
    appendMultipleDifferent(['startDate', 'finishDate'], formatString);
  };
  const changeDateObject = (values: any, formatString: [string, string]) => {
    appendMultipleDifferent(['objectStartDate', 'objectFinishDate'], formatString);
  };

  const changeObjectType = (objectType: string) => {
    appendSingle('objectType', objectType);
  };

  const changeOrderStatus = (value: string) => {
    remove('current', '');
    remove('previous', '');
    if (Array.isArray(searchParams.actionTypes)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      searchParams.actionTypes.includes(value) ? remove('actionTypes', value) : appendMultiple('actionTypes', value);
    } else if (searchParams.actionTypes === value) {
      remove('actionTypes', value);
    } else {
      appendMultiple('actionTypes', value);
    }
  };

  const handleChangeInput = (key: string, value: string) => {
    appendSingle(key, value);
  };

  // useEffect(() => {
  //   if (
  //     Array.isArray(searchParams?.actionTypes) &&
  //     searchParams?.actionTypes.includes('CHANGE_STATUS') &&
  //     (searchParams?.previous || searchParams?.current) &&
  //     (searchParams?.previous === String(oldPipeStatusId) ||
  //       searchParams?.current === String(pipeStatusId))
  //   ) {
  //     remove('previous', '')
  //     remove('current', '')
  //   }
  // }, [searchParams?.actionTypes])

  // eslint-disable-next-line consistent-return
  const checkDisabled = () => {
    if (Array.isArray(searchParams?.actionTypes)) {
      return false;
    }
    if (
      searchParams?.actionTypes?.includes('DELETE_LEAD') ||
      searchParams?.actionTypes?.includes('CREATE_LED') ||
      searchParams?.actionTypes?.includes('RECOVERY_LEAD')
    ) {
      return true;
    }
  };

  useEffect(() => {
    if (Array.isArray(searchParams?.actionTypes)) {
      remove('current', undefined);
      remove('previous', undefined);
    }
  }, []);

  return (
    <Drawer width={339} placement="left" visible={openFiltrDrawer} onClose={handleClose} headerStyle={{ display: 'none' }}>
      <div className={styles.filter_header}>
        <p>{t('home.Filtr')}</p>
        <span className={styles.filter_clear} onClick={deleteAllFilters}>
          <FilterReloadIcon />
          {t('home.Tozalash')}
        </span>
      </div>
      <Collapse
        bordered={false}
        className={styles.filterCollapse}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <span
            style={{
              transform: `${isActive ? 'rotate(0deg)' : 'rotate(90deg)'}`,
              transition: '0.3s'
            }}
          >
            <ColapseIcon />
          </span>
        )}
      >
        <Panel header={<>{t('home.Sana')}</>} key="1">
          <RangePicker
            format={DATE_FORMAT}
            value={
              searchParams.startDate && searchParams.finishDate
                ? [dayjs(searchParams.startDate, DATE_FORMAT), dayjs(searchParams.finishDate, DATE_FORMAT)]
                : [null, null]
            }
            onChange={changeDate}
            className="CustomRangePicker"
            placeholder={[t('changes.contract.kk.oo.yyyy'), t('changes.contract.kk.oo.yyyy')]}
          />
        </Panel>
        <EmployeeFilter title="Masul_xodim" labelFilter="responsibleByIds" ids={searchParams.responsibleByIds} type="allEmployees" />
        <Panel header={t('home.Obyekt_turi')} key={2} className={styles.objectTypePanel}>
          <Radio.Group onChange={e => changeObjectType(e.target.value)}>
            <Radio key={1} value="LEAD">
              Lid
            </Radio>
            <Radio key={3} value="TASK">
              {t('one_task.Topshirig')}
            </Radio>
            <Tooltip title={t('home.Tez_kunda')} placement="rightTop">
              <Radio key={2} value="CONTRACT" disabled>
                {t('home.Shartnoma')}
              </Radio>
            </Tooltip>
            <Tooltip title={t('home.Tez_kunda')} placement="rightTop">
              <Radio key={4} value="BOOKING" disabled>
                {t('titles.order')}
              </Radio>
            </Tooltip>
          </Radio.Group>
        </Panel>
        <Panel header={t('home.Voqea_turi')} key={3} className={styles.objectTypePanel}>
          <div className={styles.actionType}>
            {data?.actionType?.map((item, i: number) => (
              <Checkbox
                // eslint-disable-next-line react/no-array-index-key
                key={item.name + i}
                onChange={() => {
                  changeOrderStatus(item?.name);
                }}
                checked={
                  Array.isArray(searchParams.actionTypes) ? searchParams.actionTypes.includes(item?.name) : searchParams.actionTypes === item?.name
                }
              >
                {item[`name${textCapitalize(i18n.language)}` as keyof typeof item]?.length > 28
                  ? `${item[`name${textCapitalize(i18n.language)}` as keyof typeof item].substring(0, 28)}...`
                  : item[`name${textCapitalize(i18n.language)}` as keyof typeof item]}
              </Checkbox>
            ))}
          </div>
        </Panel>
        <Panel header={t('home.Obyekt_yaratilgan_sana')} key={4}>
          <RangePicker
            format={DATE_FORMAT}
            value={
              searchParams.objectStartDate && searchParams.objectFinishDate
                ? [dayjs(searchParams.objectStartDate, DATE_FORMAT), dayjs(searchParams.objectFinishDate, DATE_FORMAT)]
                : [null, null]
            }
            onChange={changeDateObject}
            className="CustomRangePicker"
            placeholder={[t('changes.contract.kk.oo.yyyy'), t('changes.contract.kk.oo.yyyy')]}
          />
        </Panel>
        <div className={styles.oldLastValue}>
          {
            // eslint-disable-next-line no-nested-ternary
            searchParams?.actionTypes?.includes('CHANGE_STATUS') && !Array.isArray(searchParams?.actionTypes) ? (
              <ChangeStatus />
            ) : searchParams?.actionTypes?.includes('CHANGE_LEAD_EMPLOYEE') && !Array.isArray(searchParams?.actionTypes) ? (
              <ChangeEmployee />
            ) : (
              <div className={styles.oldLastValue_inputs}>
                <Input
                  disabled={checkDisabled()}
                  placeholder={t('home.Oldingi_qiymat')}
                  value={searchParams?.previous}
                  onChange={e => {
                    handleChangeInput('previous', e.target.value);
                  }}
                />
                <Input
                  disabled={checkDisabled()}
                  placeholder={t('home.Keyingi_qiymat')}
                  value={searchParams?.current === String(pipeStatusId) ? `${pipeName}, ${pipeStatusName} ` : searchParams?.current}
                  onChange={e => handleChangeInput('current', e.target.value)}
                />
              </div>
            )
          }
        </div>
      </Collapse>
    </Drawer>
  );
};

export default FiltrDrawer;
