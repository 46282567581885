import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { editAndCreateModal } from '@reducers/WebsiteUserSlice';
import { Button, Form, Input, Modal, Select } from 'antd';

import { usePostWebsiteUser, usePutWebsiteUser } from '../service/mutation';
import { useGetCompanyAllCompanies, useGetWebsiteUser } from '../service/queries';
import { WebsiteUserType } from '../utils/models/WebsiteUserType';

import styles from './addAndEditWebsiteUserModal.module.scss';

interface FormI {
  companyId?: number | null;
  username?: string;
  password?: string;
  roles?: string[];
  role?: string;
}

const AddAndEditWebsiteUserModal = () => {
  const [form] = Form.useForm<FormI>();
  const { id, editAndCreateModalOpen } = useAppSelector(state => state.websiteUserReducer);
  const { data: dataGetAllCompanies } = useGetCompanyAllCompanies();
  const { data: dataWebsiteUsers } = useGetWebsiteUser();

  console.log('dataWebsiteUsers', dataWebsiteUsers?.data);

  const createWebsiteUser = usePostWebsiteUser();
  const editWebsiteUser = usePutWebsiteUser();

  const dispatch = useAppDispatch();

  const oneCompanyInfo = dataWebsiteUsers?.data?.find((el: WebsiteUserType) => el?.id === id);

  const handleCancel = () => {
    form.resetFields();
    dispatch(editAndCreateModal({ id: 0, open: false }));
  };

  useEffect(() => {
    if (dataWebsiteUsers?.data && id) {
      form.setFieldsValue({
        companyId: oneCompanyInfo.companyId,
        username: oneCompanyInfo.username,
        password: oneCompanyInfo.password,
        role: oneCompanyInfo.roles[0]
      });
    }
  }, [dataWebsiteUsers?.data, form, id]);

  const onFinish = (values: FormI) => {
    const updatedValues = {
      companyId: values?.companyId,
      username: values?.username,
      password: values?.password,
      role: values?.role
    };

    if (!id) {
      createWebsiteUser.mutateAsync(updatedValues).then(() => handleCancel());
    } else {
      // @ts-expect-error
      editWebsiteUser.mutateAsync({ data: updatedValues, id }).then(() => handleCancel());
    }
  };

  useEffect(() => {
    if (!id) {
      form.setFieldsValue({
        companyId: null,
        username: '',
        password: '',
        roles: []
      });
    }
  }, [id, form]);

  return (
    <Modal
      title={id === 0 ? 'Website user yaratish' : 'Website user tahrirlash'}
      wrapClassName={styles.container}
      visible={editAndCreateModalOpen}
      onCancel={handleCancel}
      footer={false}
      width={690}
    >
      <Form form={form} onFinish={onFinish}>
        <div>
          <h3 className="text-[14px] font-medium text-[#344054]">Kompaniya nomi</h3>
          <Form.Item name="companyId" rules={[{ required: true, message: '' }]}>
            <Select className="customSelect" placeholder="Tanlang">
              {dataGetAllCompanies?.map((item: { name: string; id: number }) => (
                <Select.Option key={item?.id} value={item?.id}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-[14px] font-medium text-[#344054]">Username</h3>
            <Form.Item name="username" style={{ width: 315 }} rules={[{ required: true, message: '' }]}>
              <Input className="customInput" placeholder="..." />
            </Form.Item>
          </div>
          <div>
            <h3 className="text-[14px] font-medium text-[#344054]">Parol</h3>
            <Form.Item name="password" style={{ width: 315 }} rules={[{ required: true, message: '' }]}>
              <Input.Password className="customInput" placeholder="..." />
            </Form.Item>
          </div>
        </div>
        <div>
          <h3 className="text-[14px] font-medium text-[#344054]">Role</h3>
          <Form.Item name="role" rules={[{ required: true, message: '' }]}>
            <Select className="customSelect" placeholder="Tanlang">
              <Select.Option value="WEBSITE">WEBSITE</Select.Option>
              <Select.Option value="DEVELOPER">DEVELOPER</Select.Option>
              <Select.Option value="SYSTEM">SYSTEM</Select.Option>
            </Select>
          </Form.Item>
        </div>

        <div className={`${styles.modal_footer} mt-[40px]`}>
          <Button type="primary" onClick={handleCancel} className={styles.btn_cancel}>
            Bekor qilish
          </Button>
          <Button type="primary" htmlType="submit" loading={createWebsiteUser.isLoading} className={styles.btn_confirm} style={{ backgroundColor: '#00A389' }}>
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddAndEditWebsiteUserModal;
