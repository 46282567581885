/* eslint-disable no-nested-ternary */
import React from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from '@hooks/reduxHooks';
import { crmIcons } from '@icons/crmIcons/crmIcons';
import { useGetActivityPipes } from '@pages/activityLogs/services/queries';
import { EventElements } from '@pages/activityLogs/utils/helpers/EventElements';
import { eventListInitialColumn, leadInitialColumn, taskInitialColumn } from '@pages/crm/components/dashboard-table-settings/tableColumns';
import { WidgetModel } from '@pages/dashboard/utils/models/WidgetModel';
import { convertLocation } from '@pages/oneLid/components/information/info/Info';
import { useCrmEmployee, useCrmFields } from '@queries/queries';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import i18n from '@service/I18nService';
import { encodeString } from '@utils/helper';
import { textCapitalize } from '@utils/helper/textConverter';
import { urlEncode } from '@utils/helper/urlEncodeDecode';
import { CrmField } from '@utils/models/CrmField';
import { Spin, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './tableWidget.module.scss';

type Props = {
  item: WidgetModel;
  data: any | undefined;
  loading: boolean;
  scrollColumn: (e: any) => void;
};

const TableWidget: React.FC<Props> = ({ data, item, loading, scrollColumn }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: crmFields } = useCrmFields();
  const { data: activityPipes } = useGetActivityPipes();
  const { data: crmEmployee } = useCrmEmployee();
  const { updateWidget } = useAppSelector(state => state.assigmentSlice);
  const objectType = item?.objectType;
  const taskData = data?.pages?.reduce((p: any, c: any) => (p = { ...c, data: [...p.data, ...c.data] }), { data: [] });
  const dataSource = taskData?.data;

  const goToLead = (id: number, name: string) => {
    navigate(`${rootPaths.MAIN + MainPaths.CRM}/${id}/${encodeString(name)}`);
  };
  const goToClient = (id: number, name: string) => {
    navigate(`${rootPaths.MAIN + MainPaths.CLIENT}/${id}/${urlEncode(name)}`);
  };

  const obj = {
    CHANGE_STATUS: (id: any) => (
      <div
        className={styles.activity_previus}
        style={{
          background: activityPipes?.data?.find(item => item.id === Number(id))?.color
        }}
      >
        <span>{activityPipes?.data?.find(item => item.id === Number(id))?.pipeName}</span>
        <span
          style={{
            background: activityPipes?.data?.find(item => item.id === Number(id))?.color
          }}
        >
          {activityPipes?.data.find(item => item.id === Number(id))?.name}
        </span>
      </div>
    ),
    SET_VALUE: (current: any, objectId?: string) => {
      const locationType = crmFields?.find(item => item.id === Number(objectId))?.type;

      if (locationType === 'LOCATION') {
        return convertLocation(current)?.name;
      }
      return current;
    },
    CHANGE_LEAD_EMPLOYEE: (current: any) => {
      const employeeType = crmEmployee?.find(item => item.id === Number(current))?.fullName;

      return employeeType;
    },
    CREATE_STATUS: (id: any) => (
      <div
        className={styles.activity_previus}
        style={{
          background: activityPipes?.data?.find(item => item.id === Number(id))?.color
        }}
      >
        <span>{activityPipes?.data?.find(item => item.id === Number(id))?.pipeName}</span>
        <span
          style={{
            background: activityPipes?.data?.find(item => item.id === Number(id))?.color
          }}
        >
          {activityPipes?.data.find(item => item.id === Number(id))?.name}
        </span>
      </div>
    )
  };

  const fc = (type: string, current: any, objectId?: any) => {
    if (type in obj) {
      const getCurrent = obj[type as keyof typeof obj](current, objectId);

      return getCurrent;
    }
    return current;
  };

  const tableColumn =
    // eslint-disable-next-line no-nested-ternary
    item?.fields && JSON.parse(item?.fields)?.length > 0
      ? JSON.parse(item?.fields)
      : // eslint-disable-next-line no-nested-ternary
      objectType === 'LEAD'
        ? leadInitialColumn?.map(item => item?.column)
        : objectType === 'TASK'
          ? taskInitialColumn?.map(item => item?.column)
          : eventListInitialColumn?.map(item => item?.column);

  const column = tableColumn?.map((item: any, index: any) => ({
    key: String(index),
    title: `${t(`crm.${item}`)}`,
    dataIndex: item,
    // eslint-disable-next-line no-nested-ternary
    render: (prev: any, render: any) =>
      // eslint-disable-next-line no-nested-ternary
      item === 'pipeStatusName' ? (
        <p
          className={styles.pipeStatusName}
          style={{
            color: render?.pipeStatusColor,
            background: `${render.pipeStatusColor}15`
          }}
        >
          {prev}
        </p>
      ) : // eslint-disable-next-line no-nested-ternary
        item === 'fullName' ? (
          <span>{render?.responsibleBy?.fullName}</span>
        ) : // eslint-disable-next-line no-nested-ternary
          item === 'leadTaskType' ? (
            <p
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 5
              }}
            >
              {crmIcons[render?.type.icon.split('#')[0]]?.icon(`#${render?.type.icon.split('#')[1]}`)} <span>{render?.type.name}</span>
            </p>
          ) : // eslint-disable-next-line no-nested-ternary
            item === 'clientName' ? (
              <span>{render?.clientName ? render?.clientName : render?.leadName}</span>
            ) : // eslint-disable-next-line no-nested-ternary
              item === 'state' ? (
                <div
                  className={styles.taskType}
                  style={{
                    backgroundColor: render?.state === 'ACTIVE' ? '#EFF8FF' : '#ECFDF3'
                  }}
                >
                  <span
                    style={{
                      color: render?.state === 'ACTIVE' ? '#175CD3' : '#027A48',
                      fontSize: '14px',
                      fontWeight: 500
                    }}
                  >
                    {render?.state === 'ACTIVE' ? t('home.ACTIVE') : t('home.FINISHED')}
                  </span>
                </div>
              ) : // eslint-disable-next-line no-nested-ternary
                item === 'actionType' ? (
                  <span>
                    <b>
                      {render?.actionType === 'SET_VALUE' || render?.actionType === 'DELETE_VALUE' ? (
                        <q>
                          {crmFields?.find(item => item?.id === Number(render?.objectId)) &&
                            (crmFields?.find(item => item?.id === Number(render?.objectId))![`name${textCapitalize(i18n.language)}` as keyof CrmField] ||
                              crmFields?.find(item => item?.id === Number(render?.objectId))?.name)}
                        </q>
                      ) : // eslint-disable-next-line no-nested-ternary
                        render?.actionType === 'LEAD_MERGE' ? (
                          t('one_lid.Lead_birlashtirildi')
                        ) : // eslint-disable-next-line no-nested-ternary
                          render?.actionType === 'REMOVE_CLIENT_FROM_LEAD' ? (
                            t('one_lid.Leaddan_olib_tashlandi')
                          ) : // eslint-disable-next-line no-nested-ternary
                            render?.actionType === 'ATTACH_LEAD_TO_CLIENT' ? (
                              t('one_lid.Leadga_biriktirildi')
                            ) : // eslint-disable-next-line no-nested-ternary
                              render?.actionType === 'TASK_TEXT_CHANGED' ? (
                                t('one_task.Topshiriq_matni_ozgartirildi')
                              ) : // eslint-disable-next-line no-nested-ternary
                                render?.actionType === 'CREATE_TASK' ? (
                                  t('one_task.Topshiriq_yaratildi')
                                ) : // eslint-disable-next-line no-nested-ternary
                                  render?.actionType === 'TASK_COMPLETED' ? (
                                    'Topshiriq bajarildi'
                                  ) : render?.actionType === 'TASK_RESULT_ADDED' ? (
                                    t('one_task.Topshiriq_natijasi_qoshildi')
                                  ) : (
                                    ''
                                  )}
                    </b>
                    {t(EventElements[render?.actionType as keyof typeof EventElements])}
                  </span>
                ) : // eslint-disable-next-line no-nested-ternary
                  item === 'previous' ? (
                    fc(
                      render?.actionType,
                      render?.prevAddress
                        ? `${render?.prevAddress.country_uz}, ${render?.prevAddress?.region_uz} ${render?.prevAddress?.city_uz ? `,${render?.prevAddress?.city_uz}` : ''}`
                        : render.previous,
                      render.objectId
                    )
                  ) : // eslint-disable-next-line no-nested-ternary
                    item === 'current' ? (
                      fc(
                        render.actionType,
                        render?.currentAddress
                          ? `${render?.currentAddress?.country_uz}, ${render?.currentAddress?.region_uz} ${render?.currentAddress?.city_uz ? render?.currentAddress?.city_uz : ''}`
                          : render?.current,
                        render?.objectId
                      )
                    ) : // eslint-disable-next-line no-nested-ternary
                      item === 'date' && objectType === 'TASK' ? (
                        <span>{render?.createdDate}</span>
                      ) : item === 'text' ? (
                        <span>
                          {render?.text?.substring(0, 30)}
                          {render?.text?.length > 30 && '...'}
                        </span>
                      ) : (
                        prev
                      )
  }));

  return (
    <div className={styles.tableSpin}>
      <Spin spinning={loading}>
        <div
          className={styles.tableWidget}
          style={{
            cursor: updateWidget ? 'grab' : '',
            overflow: 'scroll'
          }}
          onScroll={scrollColumn}
        >
          <Table
            dataSource={dataSource}
            columns={column}
            pagination={false}
            onRow={(render: any) => ({
              onClick: () => {
                if (render?.leadId) {
                  return goToLead(Number(render?.leadId), render?.objectLog ? String(render?.name) : String(render?.leadName));
                }
                if (render?.clientId) {
                  return goToClient(Number(render?.clientId), String(render?.clientName));
                }
                return '';
              },
              onMouseDown: e => e.stopPropagation()
            })}
          />
        </div>
      </Spin>
    </div>
  );
};

export default TableWidget;
