import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDaysWord } from '@utils/helper/getDaysWord';
import { Input, Pagination, Segmented, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import PaginationNext from '../../assets/icons/PaginationNext';
import PaginationPrev from '../../assets/icons/PaginationPrev';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useMobileDebt } from '../../queries/queries';
import { MobileDebt } from '../../utils/models/MobileDebt';

import styles from './bossContent.module.scss';
import { cx } from '@utils/cx';

const { Search } = Input;

const BossTable = () => {
  const { houses, buildings } = useAppSelector(state => state.bossCurrency);
  const location = useLocation();
  const [totalSize, setTotalSize] = useState(10);
  const [totalPageSize, setTotalPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) : 1);
  const [order, setOrder] = useState<'AMOUNT' | 'DATE'>('DATE');
  const [search, setSearch] = useState('');

  const currency = useAppSelector(state => state.bossCurrency.currency);

  const { data, isLoading, isFetching } = useMobileDebt(order, search, currency?.id || 0, totalPage, totalPageSize, houses, buildings);

  const { t } = useTranslation();

  const columns: ColumnsType<MobileDebt> = [
    {
      title: t('boss_arrearage.Mijoz'),
      dataIndex: 'clientName',
      render: i => <span className={styles.boss_table_name}>{i}</span>
    },
    {
      title: t('boss_arrearage.Qarzdorlik_summasi'),
      render: (record: MobileDebt) => (
        <span className={styles.boss_table_age}>{`${record?.amount?.toLocaleString('ru')} ${record?.currency?.ccy}`}</span>
      )
    },
    {
      title: t('boss_arrearage.Kechikish_muddati'),
      dataIndex: 'delay',
      align: 'center',
      render: i => {
        const daysWord = getDaysWord(i, t);

        return (
          <span className={styles.boss_table_address}>
            {i} {daysWord}
          </span>
        );
      }
    },
    {
      title: t('home.Maksimal_kechikish_muddati'),
      dataIndex: 'prevDelay',
      align: 'center',
      render: i => {
        const daysWord = getDaysWord(i, t);

        return (
          <div className='flex items-center justify-center'>
            <div className={cx('!bg-red-700 !w-[100px] flex justify-center', styles.boss_table_address)}>
              {i} {daysWord}
            </div>
          </div>
        );
      }
    }
  ];

  // searching for username
  const onSearch = (value: any) => {
    setSearch(value.target.value);
    setTotalPage(1);
  };

  // segment, moving to another option
  const changeOrders = (value: any) => {
    setOrder(value);
    setTotalPage(1);
  };

  // setting total pages with declared useState
  const setPageSize = (page: any, size: any) => {
    setTotalPage(page);
    setTotalPageSize(size);
  };

  useEffect(() => {
    if (data) {
      // navigate(location.pathname + '?page=' + data?.currentPage)
      setTotalSize(data?.totalElements);
    }
  }, [data]);

  return (
    <div className={styles.debt_category}>
      <div className={styles.ants}>
        <Segmented
          options={[
            {
              value: 'DATE',
              label: t('boss_arrearage.Kechikish_muddati_boyicha')
            },
            {
              value: 'AMOUNT',
              label: t('boss_arrearage.Qarzi_boyicha')
            }
          ]}
          className={styles.segmented}
          onChange={changeOrders}
        />
        <Search placeholder={t('boss_arrearage.Mijoz_orqali_qidirish')} onChange={onSearch} style={{ width: 499 }} />
      </div>
      <div>
        <div style={{ marginBottom: 16 }} />
        <div className={styles.debt_category_table}>
          <Table columns={columns} dataSource={data?.data} loading={isLoading || isFetching} pagination={false} className="boss_page_table" />
        </div>
        <div className={styles.pagination_boss}>
          <Pagination
            size="small"
            showSizeChanger={false}
            current={totalPage}
            pageSize={totalPageSize}
            total={totalSize}
            prevIcon={
              <div className={styles.debt_category_next_prev_buttons}>
                <PaginationPrev />
              </div>
            }
            nextIcon={
              <div className={styles.debt_category_next_prev_buttons}>
                <PaginationNext />
              </div>
            }
            onChange={setPageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default BossTable;
