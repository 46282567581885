import React, { FC, useEffect, useState } from 'react';
import CallCallingIcon from '@icons/crmIcons/CallCallingIcon';
import { Tooltip } from 'antd';

import styles from './TextEditTable.module.scss';

interface EditableProps {
  value?: string;
  onInput?: (value: string) => void;
  fields: {
    label: string;
    key: string;
  }[];
  dropdown?: boolean;
  id?: string;
  isEmpty?: boolean; // Новый пропс для определения пустоты строки
}

const TextEditable: FC<EditableProps> = ({ value, fields, onInput, dropdown, id, isEmpty }) => {
  const [text, setText] = useState(value || '');

  useEffect(() => {
    if (!text && value) {
      setText(value);
    }
  }, [value, text]);

  const textReplace = text?.replace(
    /&(.*?)&/g,
    `<span contenteditable='false' class='editable_span' key='&$1&'>
        &$1& 
        <span class="editable_span_delete" onClick="this.parentElement.remove()">
          &times;
        </span>
      </span>`
  );

  async function pasteHtmlAtCaret(html: any) {
    let range: Range;
    const sel = await window.getSelection();
    const focusNode: any = document.querySelector(`#editor${id}`);

    await focusNode.focus();
    if (sel?.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0);
      range.deleteContents();
      const el = document.createElement('div');

      el.innerHTML = `<span contenteditable="false" class='editable_span' key='${html}'>
          ${html}
          <span class="editable_span_delete" onClick="this.parentElement.remove()">
            &times;
          </span>
        </span>&nbsp;`;
      const frag = document.createDocumentFragment();
      let node;
      let lastNode;

      // eslint-disable-next-line no-cond-assign
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node);
      }
      range.insertNode(frag);

      if (lastNode) {
        range = range.cloneRange();
        range.setStartAfter(lastNode);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    handleChange(focusNode);
  }

  const handleChange = (target: any) => {
    const textArr: any[] = [];

    const childrenRec = (childrens: any) => {
      childrens?.forEach((item: any) => {
        if (item.nodeName === '#text') {
          textArr.push(item.data);
        } else if (item.getAttribute('key')) {
          textArr.push(item.getAttribute('key'));
        } else if (item?.childNodes && item?.childNodes.length > 0) {
          childrenRec(item?.childNodes);
        }
      });
    };

    childrenRec(target?.childNodes);

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onInput && onInput(textArr?.join('').replace(/\u00a0/g, ' '));
  };

  useEffect(() => {
    const editor = document.querySelector(`#editor${id}`);
    const observer = new MutationObserver(() => {
      handleChange(editor);
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    editor &&
      observer.observe(editor, {
        characterData: true,
        attributes: false,
        childList: true,
        subtree: true
      });

    return () => {
      observer.disconnect();
    };
  }, [id]);

  return (
    <div className={`${styles.editable}`}>
      {' '}
      {/* Используем условие для применения стилей при пустой строке */}
      {dropdown ? (
        <>
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <div
              className={`${styles.aboutHome1}`}
              id={`editor${id}`}
              contentEditable
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: textReplace }}
              onInput={e => handleChange(e.currentTarget)}
            />

            <div>
              {fields.map(field => (
                <span
                  key={field.key}
                  onClick={() => {
                    pasteHtmlAtCaret(field.key);
                  }}
                  style={{ display: 'flex', userSelect: 'none' }}
                >
                  <Tooltip title={field?.label}>
                    <span>
                      <CallCallingIcon />
                    </span>
                  </Tooltip>
                </span>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`${styles.aboutHome} ${isEmpty ? styles.emptyBorder : ''}`}
            id={`editor${id}`}
            contentEditable
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: textReplace }}
            onInput={e => handleChange(e.currentTarget)}
          />

          <p style={{ marginTop: '15px', display: 'flex', userSelect: 'none' }}>
            {fields.map(field => (
              <span
                key={field.key}
                onClick={() => {
                  pasteHtmlAtCaret(field.key);
                }}
                className={styles.btn}
                style={{ display: 'flex' }}
              >
                {field?.label}
              </span>
            ))}
          </p>
        </>
      )}
    </div>
  );
};

export default TextEditable;
