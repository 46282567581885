export interface CompaniesList {
  id: number;
  name: string;
  director: string;
  phone: string;
  phoneCode?: string;
  status: CompaniesStatus;
  expiry: string;
  paymentDate: string;
  lastContractDay: number;
  lastPaymentDay: number;
  expiryDay: number | null;
}

export enum CompaniesStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED'
}
