/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from 'react';
import Info from '@icons/Info';
import { Pagination, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import CancelBronGarbage from '../../../../assets/icons/CancelBronGarbage';
import PaginationNext from '../../../../assets/icons/PaginationNext';
import PaginationPrev from '../../../../assets/icons/PaginationPrev';
import { PaymentListItemChange } from '../../../../utils/models/PaymentChange';
import { PaymentType } from '../../../../utils/models/PaymentType';

import DeleteModal from './DeleteModal';
import TablePaymentsInfoModal from './TablePaymentsInfoModal';

import styles from './payments.module.scss';

interface Props {
  data: PaymentListItemChange[];
}

const TablePayments: React.FC<Props> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [openDeleteModal, setOpenDeleteModal] = useState({
    isOpen: false,
    id: 0
  });
  const [payment, setPayment] = useState<any>(undefined);

  const { t } = useTranslation();

  const columns = [
    {
      title: '№',
      align: 'start',
      width: '10%',
      render: (text: any, record: PaymentListItemChange, index: number) => currentPage * 10 - 9 + index
    },
    {
      title: t('changes.Tolov_qilingan_sana'),
      dataIndex: 'createdDate',
      align: 'center'
    },
    {
      title: t('changes.Tolov_raqami'),
      dataIndex: 'number'
    },
    {
      title: t('changes.Tolov_summasi'),
      align: 'center',
      render: (record: PaymentListItemChange) => (
        <span>
          {record.amount?.toLocaleString('ru')} {record.currency?.ccy}
        </span>
      )
    },
    {
      title: t('changes.Tolov_turi'),
      align: 'center',
      dataIndex: 'type',
      render: (type: PaymentType) => <span className={`payment_table_${type}`}>{t(`home.${type}`)}</span>
    },
    {
      title: '',
      align: 'center',
      width: '10%',
      render: (text: any, record: PaymentListItemChange) => (
        <div>
          <div className="flex items-center justify-center gap-3">
            {/* {record?.type === "OTHERS" && ( */}
            <span
              className="mt-[3px] cursor-pointer"
              onClick={() => {
                setPayment(record?.id);
              }}
            >
              <Info size={25} />
            </span>
            {/* )} */}

            <span onClick={() => handleDeleteItem(true, record.id)} className={styles.table_delete_span}>
              <CancelBronGarbage />
            </span>
          </div>
        </div>
      )
    }
  ];

  const handleDeleteItem = (isOpen: boolean, id?: number) => {
    setOpenDeleteModal({ isOpen, id: id || 0 });
  };

  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className={styles.table}>
      <Table
        className="custom_table contract_table table_cursor_default"
        columns={columns as any}
        rowKey="sum"
        dataSource={data?.slice((currentPage - 1) * 10, currentPage * 10)}
        size="small"
        pagination={false}
      />
      <div className={styles.table_footer}>
        <div className={styles.table_footer_pagination_cont}>
          <Pagination
            onChange={changePage}
            total={data?.length}
            showSizeChanger={false}
            current={currentPage}
            prevIcon={
              <div className={styles.next_prev_buttons}>
                <PaginationPrev />
              </div>
            }
            nextIcon={
              <div className={styles.next_prev_buttons}>
                <PaginationNext />
              </div>
            }
          />
        </div>
      </div>
      <DeleteModal id={openDeleteModal.id} visible={openDeleteModal.isOpen} onCancel={handleDeleteItem} />
      <TablePaymentsInfoModal payment={payment} setPayment={setPayment} data={data} />
    </div>
  );
};

export default TablePayments;
