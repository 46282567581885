import React from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import ArrowLeftIcon from '@icons/contract/ArrowLeftIcon';
import PlusCircleIcon from '@icons/PlusCircleIcon';
import SearchIcon from '@icons/SearchIcon';
import { useUpdateAllPermissionsDev } from '@pages/security/security-control-permissions/services/mutation';
import { RoleEdit } from '@utils/models/Roles';
import { Button, Input } from 'antd';
import { t } from 'i18next';

import styles from './permissionHeader.module.scss';

type Props = {
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  handleOpen: (role?: RoleEdit) => void;
  setNext: React.Dispatch<React.SetStateAction<boolean>>;
  next: boolean;
  outputArray: any[];
  setPermissionState: React.Dispatch<React.SetStateAction<any[]>>;
};

const PermissionHeader: React.FC<Props> = ({ setSearchText, handleOpen, setNext, next, outputArray, setPermissionState }) => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const updatePermissions = useUpdateAllPermissionsDev(params?.id!);

  const handleUpdatePermissions = () => {
    updatePermissions.mutateAsync({ permissionState: outputArray }).then(() => {
      setNext(false);
      setPermissionState([]);
    });
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.currentTarget.value);
  };

  // go back
  function goBack() {
    navigate(-1);
  }

  return (
    <div className={styles.wrapper}>
      <Button icon={<ArrowLeftIcon />} className={styles.backSide} onClick={() => goBack()} style={{ opacity: next ? 1 : 0 }}>
        Orqaga
      </Button>
      <div className={styles.container}>
        <Input placeholder={t('home.Qidirish')} onChange={handleSearch} suffix={<SearchIcon />} className={styles.searchInput} />
        <Button
          className={styles.container_save}
          onClick={() => {
            setNext(true);
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            next && handleUpdatePermissions();
          }}
          loading={updatePermissions?.isLoading}
          disabled={outputArray?.length < 1}
        >
          {next ? t('integration.Saqlash') : 'Keyingi bosqich'}
        </Button>
        <Button className={styles.container_add} onClick={() => handleOpen()}>
          <PlusCircleIcon />
          <span>{t('crm.Role_qoshish')}</span>
        </Button>
      </div>
    </div>
  );
};

export default PermissionHeader;
