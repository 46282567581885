import React from 'react';
import { Button, Layout } from 'antd';

import PlusIcon from '../../assets/icons/PlusIcon';
import CompanyEmployeeCreateAndEditModal from '../../components/companyEmployees/CompanyEmployeeCreateAndEditModal';
import CompanyEmployeesFilter from '../../components/companyEmployees/CompanyEmployeesFilter';
import CompanyEmployeesTable from '../../components/companyEmployees/CompanyEmployeesTable';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import MainHeader from '../../components/headers/MainHeader';
import PaginationCont from '../../components/pagenation/PaginationCont';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useOneCompanyEmployees } from '../../queries/queries';
import { editAndCreateModal } from '../../store/reducers/CompanyEmployeesSlice';

import styles from '../../components/filters/mainFilters.module.scss';

const { Content } = Layout;

export interface SearchParamsCompanyEmployees {
  page?: string;
  size?: string;
  search?: string;
}

const CompanyEmployees = () => {
  const { searchParams, appendMultipleDifferent, params } = useQueryParam<SearchParamsCompanyEmployees, { companyId: string }>();

  const dispatch = useAppDispatch();

  const { data, isLoading, isFetching } = useOneCompanyEmployees(params.companyId, searchParams);

  const changePage = (page: number) => {
    appendMultipleDifferent(['page', 'size'], [page, 10]);
  };

  const openCreateModal = () => {
    dispatch(editAndCreateModal({ id: 0, open: true }));
  };

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div>
          <div className={`d-flex align-center justify-space-between ${styles.developer}`}>
            <CompanyEmployeesFilter />
            <Button onClick={openCreateModal} className={styles.developer_button} type="primary">
              <PlusIcon />
              <span>Xodim qo'shish</span>
            </Button>
          </div>
          <CompanyEmployeesTable data={data?.data || []} isLoading={isLoading} isFetching={isFetching} />
          <PaginationCont
            total={data?.totalElements !== undefined ? data.totalElements : -1}
            totalPages={data?.totalPages || 1}
            changePage={changePage}
            currentPage={data?.currentPage ? data.currentPage : 1}
            defaultPageSize={10}
          />
          <CompanyEmployeeCreateAndEditModal />
        </div>
      </Content>
    </>
  );
};

export default CompanyEmployees;
