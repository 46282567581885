import React, { useEffect, useState } from 'react';
import { Permissions } from '@utils/constants/permissions';
import { cx } from '@utils/cx';
import { Button, Dropdown, Form, message } from 'antd';
import { Input as GInput } from 'global';
import { useTranslation } from 'react-i18next';

import DiscountShapeIcon from '../../../../assets/icons/DiscountShapeIcon';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { changeOneFlatAmount, setChangePartId, setDiscountText } from '../../../../store/reducers/CreateContractSlice';
// import { helper } from '../../../../utils/helper/helper';
import { roundFloatNumber } from '../../../../utils/helper/roundFloatNumber';
import { CurrencyItem } from '../../../../utils/models/Currency';
import { FlatMore } from '../../../../utils/models/FlatMore';

import styles from './discountPricePerArea.module.scss';

interface Props {
  flat: FlatMore;
  currency: CurrencyItem | undefined;
}

const TotalAmount: React.FC<Props> = ({ flat, currency }) => {
  const { user } = useAppSelector(state => state.userReducer);

  const isMultiPayment = user?.role?.permissions?.some(p => p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT || p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT);

  const [form] = Form.useForm<{ priceString: number; percent: number; percentAmount: number }>();

  // const [priceString, setPriceString] = useState('');
  const [visibleDiscount, setVisibleDiscount] = useState(false);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const totalPercentPrice = flat.totalAmount / 100;

  useEffect(() => {
    const price = flat.totalAmount - (flat.discountAmount || 0);

    // setPriceString(price.toLocaleString('ru'));
    form.setFieldsValue({
      priceString: price,
      percent: roundFloatNumber((flat.totalAmount - price) / totalPercentPrice),
      percentAmount: flat.totalAmount - price
    });
  }, [flat.discountAmount, flat.totalAmount, form, visibleDiscount]);

  // handle visible discount
  const handleVisibleDiscount = (visible: boolean) => {
    setVisibleDiscount(visible);
  };

  // handle calculate discount full
  // const handleCalculateDiscountFull = () => roundFloatNumber(flat.totalAmount - helper.parseFloatStringToNumber(priceString)).toLocaleString('ru');

  // handle change price per area
  const handleChangePricePerArea = () => {
    const amount = form.getFieldValue('priceString');

    if (amount < flat.firstPayment) {
      message.error("Chegirma summasi boshlang'ich tolovdan katta");
      return;
    }
    dispatch(
      changeOneFlatAmount({
        amount,
        id: flat.flat.id
      })
    );
    dispatch(setDiscountText(`${amount.toLocaleString('ru')} ${currency?.ccy} chegirma qo'llanildi`));
    dispatch(setChangePartId(0));
    handleVisibleDiscount(false);
  };

  return (
    <>
      <Dropdown
        overlayClassName={styles.container_dropdown_sum}
        overlay={
          <>
            <div>
              <p>{t('home.Asosiy_narx')}</p>
              <span>
                {flat.totalAmount.toLocaleString('ru')} {currency?.ccy}
              </span>
            </div>
            <div>
              <p>{t('home.Chegirmadan_keyingi_narx')}</p>
              <span>
                {roundFloatNumber(flat.totalAmount - (flat.discountAmount || 0)).toLocaleString('ru')} {currency?.ccy}
              </span>
            </div>
          </>
        }
        trigger={['hover']}
        placement="top"
      >
        <p onClick={() => handleVisibleDiscount(true)} className={styles.container_sum}>
          {roundFloatNumber(flat.totalAmount - (flat.discountAmount || 0)).toLocaleString('ru')} {currency?.ccy}
        </p>
      </Dropdown>
      <Dropdown
        overlayClassName={styles.container_dropdown}
        {...(isMultiPayment ? { visible: false } : { visible: visibleDiscount })}
        onVisibleChange={handleVisibleDiscount}
        overlay={
          <Form onFinish={handleChangePricePerArea} form={form} name="discountForm">
            <p className={styles.container_dropdown_title}>{t('home_id.Chegirmadan_keyingi_summani_kiriting')}</p>
            <Form.Item label="" name="priceString" rules={[{ required: true, message: '' }]}>
              <GInput
                onChange={value => {
                  form.setFieldsValue({
                    priceString: value,
                    percent: roundFloatNumber((flat.totalAmount - value) / totalPercentPrice),
                    percentAmount: flat.totalAmount - value
                  });
                }}
                className="customInput"
                type="currency"
                returnType="number"
                maskOption={{ max: flat.totalAmount }}
              />
            </Form.Item>
            <div className="mt-2">
              <p className={cx(styles.container_dropdown_title, 'flex items-center gap-[6px]')}>
                <DiscountShapeIcon /> {t('home_id.Chegirma')}
              </p>
              <div className="flex w-full gap-[8px]">
                <Form.Item label="" name="percentAmount" rules={[{ required: false, message: '' }]} className="w-full">
                  <GInput
                    onChange={value => {
                      const priceString = flat.totalAmount - value;

                      form.setFieldsValue({
                        priceString,
                        percent: roundFloatNumber(value / totalPercentPrice)
                      });
                      // setPriceString(priceString?.toLocaleString('ru') || '');
                    }}
                    className="w-full"
                    suffix={currency?.ccy}
                    type="currency"
                    maskOption={{ max: flat.totalAmount }}
                    returnType="number"
                  />
                </Form.Item>
                <Form.Item label="" name="percent" rules={[{ required: false, message: '' }]}>
                  <GInput
                    onChange={value => {
                      const priceString = roundFloatNumber(flat.totalAmount - totalPercentPrice * (value || 0));

                      form.setFieldsValue({
                        priceString: roundFloatNumber(priceString),
                        percentAmount: roundFloatNumber(flat.totalAmount - priceString)
                      });
                      // setPriceString(priceString?.toLocaleString('ru') || '');
                    }}
                    className="w-[70px]"
                    suffix="%"
                    type="number"
                    maskOption={{ max: 100, min: 0 }}
                    returnType="number"
                  />
                </Form.Item>
              </div>
            </div>
            <div className={styles.container_dropdown_button_cont}>
              <Button htmlType="submit" className={styles.container_dropdown_button_cont_button} type="primary">
                {t('home_id.Tasdiqlash')}
              </Button>
            </div>
          </Form>
        }
        placement="bottom"
        trigger={['click']}
      >
        <div className={styles.container_empty_div} />
      </Dropdown>
    </>
  );
};

export default TotalAmount;
