import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import InternationalPhoneNumber from '@components/international-phone-input/InternationalPhoneNumber';
import { countrCode } from '@pages/changes/utils/helpers/CountryCodes';
import ConnectLeadModal from '@pages/createOrder/components/connect-lead/ConnectLeadModal';
import CreateLeadModal from '@pages/createOrder/components/create-lead/CreateLeadModal';
import { useGetForceData } from '@pages/crm-force/services/queries';
import { useLeadSave } from '@queries/mutation';
import { changeCountryCode } from '@reducers/ClientSlice';
import i18n from '@service/I18nService';
import { ClientLeadModel } from '@utils/models/ClientLeadModel';
import { Button, Checkbox, Col, Form, Input, Row, Select, Space } from 'antd';
import { isValidNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';

import TelPlusIcon from '../../../../assets/icons/TelPlusIcon';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { useGetDistrict, useRegions } from '../../../../queries/queries';
import { addClientPhone, checkClientPhone, deleteClientPhones, setSearchClient } from '../../../../store/reducers/ChangesSlice';
import { getCurrentLanguageWord } from '../../../../utils/helper/getCurrentLanguageWord';
import { helper } from '../../../../utils/helper/helper';
import { BaseLanguageEntity } from '../../../../utils/models/BaseLanguageEntity';
import { ClientPhone, JuridicalPersonCreate } from '../../../../utils/models/Client';
import { useCreateAndUpdateJuridicalPersonChanges } from '../../services/mutation';

import ClientLead from './ClientLead';

import img from '../../../../assets/img/addLead.png';

import styles from './client.module.scss';

const { Option } = Select;

interface FormValues {
  name: string;
  inn: string;
  phone0: string;
  phone1: string;
  phone2: string;
  phone3: string;
  phone4: string;
  code0: string;
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  accountNumber: string;
  oked: string;
  bankName: string;
  mfo: string;
  districtId: number;
  cityID: number;
  director: string;
  address: string;
  countryCode: string;
  country?: string;
}

const JuridicalClient = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const clientPhones = useAppSelector(state => state.changesReducer.clientPhones);
  const selectedClient = useAppSelector(state => state.changesReducer.selectedClient as JuridicalPersonCreate);
  const [form] = Form.useForm<FormValues>();
  const [country, setCountry] = useState<string>('uz');
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openLeadModal, setOpenLeadModal] = useState(false);
  const [selectLead, setSelectLead] = useState<ClientLeadModel | undefined>();
  const { data: force } = useGetForceData();
  const { leadContact, leadName, leadContactName, countryCode: code } = useAppSelector(state => state.clientReducer);

  useEffect(() => {
    if (selectedClient?.phones[0]?.code) {
      dispatch(changeCountryCode(selectedClient?.phones[0]?.code));
    } else {
      dispatch(changeCountryCode('UZ'));
    }
  }, [selectedClient]);
  const updateNewLead = useLeadSave();

  const handleOpenLeadModal = () => {
    setOpenLeadModal(true);
  };

  const handleOpenCreateModal = () => {
    setOpenLeadModal(false);
    setOpenCreateModal(true);
  };

  const [typeAddress, setTypeAddress] = useState<{
    id: number;
    type: 'district' | 'region';
  }>({
    id: -1,
    type: 'region'
  });

  const { data: regions, isLoading: loadingRegions } = useRegions();
  const { data: districts, isLoading: districtsLoading } = useGetDistrict(typeAddress.id, typeAddress.type);

  const createAndUpdateClient = useCreateAndUpdateJuridicalPersonChanges();

  const currentClientLead = (selectLead && selectedClient?.lead === null) || selectedClient?.lead === undefined ? selectLead : selectedClient?.lead;

  const isCliendLead = !!selectedClient?.lead;

  const contactsCopy = (contacts?: any[]) => {
    const arr = [...(contacts || [])];

    arr[0].name = leadContactName;
    arr[0].phones = [leadContact];

    return arr;
  };

  useEffect(() => {
    if (selectedClient?.id) {
      form.setFieldsValue({
        name: selectedClient.name,
        inn: selectedClient.inn,
        accountNumber: selectedClient.accountNumber,
        bankName: selectedClient.bankName,
        cityID: selectedClient.cityID,
        oked: selectedClient.oked,
        director: selectedClient.director,
        mfo: selectedClient.mfo,
        districtId: selectedClient.districtId,
        address: selectedClient.address,
        countryCode: country
      });
      for (let i = 0; i < selectedClient.phones.length; i++) {
        form.setFieldsValue({
          [`phone${i + 1}`]: selectedClient.phones[i].phone,
          [`code${i + 1}`]: selectedClient.phones[i]?.code ? selectedClient.phones[i]?.code : 'UZ'
        });
      }
      if (selectedClient.districtId) {
        setTypeAddress({
          id: selectedClient.districtId,
          type: 'district'
        });
      }
    } else {
      form.setFieldsValue({
        name: undefined,
        inn: undefined,
        phone0: '',
        phone1: '',
        phone2: '',
        phone3: '',
        phone4: '',
        accountNumber: undefined,
        oked: undefined,
        bankName: undefined,
        mfo: undefined,
        districtId: undefined,
        cityID: undefined,
        director: undefined,
        address: undefined,
        countryCode: 'uz',
        country: 'uz'
      });
    }
  }, [selectedClient, form]);
  const onFinish = (values: FormValues) => {
    const newPhones: ClientPhone[] = [];

    for (let i = 0; i < clientPhones.length && i < 5; i++) {
      newPhones.push({
        ...clientPhones[i],
        phone: String(values[`phone${i + 1}` as keyof typeof values])?.includes('+')
          ? String(`${values[`phone${i + 1}` as keyof typeof values]}`)
          : String(`+${values[`phone${i + 1}` as keyof typeof values]}`),
        code: String(values[`code${i + 1}` as keyof typeof values])
      });
    }
    const newClientData: JuridicalPersonCreate = {
      ...values,
      phones: newPhones,
      id: selectedClient?.id,
      mfo: values.mfo?.trim(),
      bankName: values.bankName?.trim(),
      accountNumber: values.accountNumber?.trim(),
      director: values.director?.trim(),
      inn: values.inn?.trim(),
      oked: values.oked?.trim(),
      name: values.name?.trim(),
      countryCode: values?.country,
      leadId: selectLead ? selectLead?.id : currentClientLead?.id
    };

    createAndUpdateClient.mutateAsync(newClientData).then(() => {
      const updateLidData = {
        id: currentClientLead?.id,
        contacts: contactsCopy(currentClientLead?.contacts),
        balance: currentClientLead?.balance,
        responsibleById: currentClientLead?.responsibleById,
        pipeStatusId: currentClientLead?.pipeStatusResponse?.id,
        tagIds: currentClientLead?.tags?.map(item => item?.id),
        fields: currentClientLead?.fields,
        name: leadName,
        autoClosedLeadTask: false
      };

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      currentClientLead?.isDefaultCreate && updateNewLead.mutateAsync(updateLidData as any);
    });
  };

  const changeRegion = (value: number) => {
    form.setFieldsValue({
      districtId: undefined
    });
    setTypeAddress({
      type: 'region',
      id: value
    });
  };

  const handleAddClientPhone = () => {
    dispatch(addClientPhone());
  };

  const handleRemoveClientPhone = (index: number) => {
    for (let i = 0; i < 5; i++) {
      if (index === i) {
        form.setFieldsValue({
          [`phone${i}`]: '',
          [`code${i}`]: ''
        });
      } else if (i > index) {
        form.setFieldsValue({
          [`phone${i - 1}`]: form.getFieldValue(`phone${i}`),
          [`code${i - 1}`]: form.getFieldValue(`code${i}`)
        });
      }
    }
    form.setFieldsValue({
      [`phone4`]: '',
      [`code4`]: ''
    });
    dispatch(deleteClientPhones(index));
  };

  const handleCheckedPhone = (index: number) => {
    dispatch(checkClientPhone(index));
  };

  const translateWords = (wordObj: BaseLanguageEntity) => getCurrentLanguageWord(wordObj);

  // handleSearchClient
  const handleSearchClient = (phone: string, index: number) => {
    const phoneNumber = helper.deleteNotNumbers(phone, 1);

    if (index === 0 && String(phoneNumber).length >= 3) {
      dispatch(setSearchClient(String(phoneNumber)));
    }
  };

  const changeCountry = (value: string) => {
    form.setFieldsValue({
      country: 'uz'
    });
    setCountry(value);
  };

  return (
    <div>
      <Form form={form} labelCol={{ span: 24 }} name="basic" initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off">
        <h2>{t(`changes.client.NaturalClient.Mijoz_ma’lumotlari`)}</h2>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: `${t(`changes.client.JuridicalClient.Ma'lumot_kiritilmagan`)}`
                }
              ]}
              label={<span className={styles.formLabel}>{t(`changes.client.JuridicalClient.Korxona_nomi`)}</span>}
            >
              <Input className="customInput" style={{ width: '95%' }} />
            </Form.Item>
            {/* {clientPhones.map((phone, index) => (
              <Space direction="horizontal" className="d-flex" key={phone?.id}>
                <div>
                  <Form.Item name={`code${index + 1}`} className="d-none" />
                  <div className="flex flex-col gap-[5px]">
                    <Form.Item
                      name={`phone${index + 1}`}
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      label={<span className={styles.formLabel}>{t(`changes.client.NaturalClient.Telefon_raqam`)}</span>}
                    >
                      <PhoneInput
                        defaultCountry="uz"
                        onChange={(e, phone) => {
                          setPhone(phone.inputValue);

                          form.setFieldsValue({ [`code${index + 1}`]: `${phone?.country?.iso2?.toUpperCase()}` });
                          const searchPhone = e?.replace(`${phone?.country?.dialCode}`, '');

                          handleSearchClient(searchPhone, index);
                        }}
                        countries={defaultCountries}
                        inputStyle={{ borderColor: !isPhoneValid(phoneValue) ? 'red' : '' }}
                        inputClassName="ant-input"
                      />
                    </Form.Item>

                    {!isPhoneValid(phoneValue) && <span className="mt-[-10px] text-red-500">{t('profile.Telefon_raqamini_kiriting')}</span>}
                  </div>
                </div>
                <Checkbox style={{ transform: 'translateY(15px)' }} onChange={() => handleCheckedPhone(index)} checked={phone.active} />
                {index !== 0 && (
                  <Button onClick={() => handleRemoveClientPhone(index)} style={{ marginLeft: 5, transform: 'translateY(15px)' }} shape="circle">
                    <CloseOutlined />
                  </Button>
                )}
              </Space>
            ))} */}
            {clientPhones.map((phone, index) => (
              <Space direction="horizontal" className="d-flex" key={phone?.id}>
                <div>
                  <Form.Item name={`code${index + 1}`} className="d-none" />

                  <div className="flex flex-col gap-[5px]">
                    <Form.Item
                      label={<span>{t(`changes.client.NaturalClient.Telefon_raqam`)}</span>}
                      name={`phone${index + 1}`}
                      rules={[
                        {
                          validator: (rule, value) => {
                            if (!(value && isValidNumber(value))) {
                              // eslint-disable-next-line prefer-promise-reject-errors
                              return Promise.reject('');
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                    >
                      <InternationalPhoneNumber
                        defaultCountry="uz"
                        className={styles.phoneInput2}
                        onChange={(e, phone) => {
                          // form.setFieldsValue({
                          //   [`phoneCode`]: `${phone?.country?.iso2?.toUpperCase()}`
                          // });
                          form.setFieldsValue({ [`code${index + 1}`]: `${phone?.country?.iso2?.toUpperCase()}` });

                          const searchPhone = e?.replace(`${phone?.country?.dialCode}`, '');

                          handleSearchClient(searchPhone, index);
                        }}
                      />
                    </Form.Item>

                    {/* {!isPhoneValid(phoneValue) && <span className="mt-[-10px] text-red-500">{t('profile.Telefon_raqamini_kiriting')}</span>} */}
                  </div>
                </div>
                <Checkbox style={{ transform: 'translateY(15px)' }} onChange={() => handleCheckedPhone(index)} checked={phone.active} />
                {index !== 0 && (
                  <Button onClick={() => handleRemoveClientPhone(index)} style={{ marginLeft: 5, transform: 'translateY(15px)' }} shape="circle">
                    <CloseOutlined />
                  </Button>
                )}
              </Space>
            ))}
            {clientPhones.length < 5 && (
              <p onClick={handleAddClientPhone} className={styles.clientPhonePlus}>
                <TelPlusIcon />
                {t(`changes.client.NaturalClient.Telefon_raqam_qo'shish`)}
              </p>
            )}
          </Col>
          <Col span={12}>
            <Form.Item name="countryCode" label={<span className={styles.formLabel}>{t('client_create_contract.Mamlakat')}</span>}>
              <Select value={country} onChange={e => changeCountry(e)} optionFilterProp="children" showSearch style={{ width: '95%' }}>
                {countrCode?.map(item => (
                  <Option key={item?.code} value={item?.code}>
                    {item[`country_${i18n.language}` as keyof typeof item]}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {country === 'uz' && (
              <>
                <Form.Item
                  name="cityID"
                  rules={[
                    {
                      required: true,
                      message: `${t(`changes.client.JuridicalClient.Ma'lumot_kiritilmagan`)}`
                    }
                  ]}
                  label={<span className={styles.formLabel}>{t(`changes.client.NaturalClient.Viloyat`)}</span>}
                >
                  <Select
                    className="customSelect"
                    loading={loadingRegions}
                    onChange={changeRegion}
                    optionFilterProp="children"
                    showSearch
                    placeholder={t('client_create_contract.Hududni_tanlang')}
                    style={{ width: '95%' }}
                  >
                    {regions?.map(region => (
                      <Option key={region.id} value={region.id}>
                        {translateWords(region)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="districtId"
                  rules={[
                    {
                      required: true,
                      message: `${t(`changes.client.JuridicalClient.Ma'lumot_kiritilmagan`)}`
                    }
                  ]}
                  label={<span className={styles.formLabel}>{t(`changes.client.NaturalClient.Shahar/tuman`)}</span>}
                >
                  <Select
                    optionFilterProp="children"
                    showSearch
                    className="customSelect"
                    loading={districtsLoading}
                    placeholder={`${t(`changes.client.NaturalClient.Hududni_tanlang`)}`}
                    style={{ width: '95%' }}
                  >
                    {districts?.map(district => (
                      <Option key={district.id} value={district.id}>
                        {translateWords(district)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}
            <Form.Item
              name="address"
              rules={[
                {
                  required: true,
                  message: `${t(`changes.client.JuridicalClient.Ma'lumot_kiritilmagan`)}`
                }
              ]}
              label={<span className={styles.formLabel}>{t(`changes.client.NaturalClient.Uy`)}</span>}
            >
              <Input className="customInput" style={{ width: '95%' }} />
            </Form.Item>
          </Col>
        </Row>
        <h2>{t(`changes.client.JuridicalClient.Mijozning_yuridik_ma’lumotlari`)}</h2>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name="inn"
              rules={[
                {
                  required: true,
                  message: `${t(`changes.client.JuridicalClient.Ma'lumot_kiritilmagan`)}`
                }
              ]}
              label={<span className={styles.formLabel}>{t(`changes.client.JuridicalClient.INN`)}</span>}
            >
              <Input className="customInput" style={{ width: '95%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="oked"
              rules={[
                {
                  required: true,
                  message: `${t(`changes.client.JuridicalClient.Ma'lumot_kiritilmagan`)}`
                }
              ]}
              label={<span className={styles.formLabel}>{t(`changes.client.JuridicalClient.OKED`)}</span>}
            >
              <Input className="customInput" style={{ width: '95%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="mfo"
              rules={[
                {
                  required: true,
                  message: `${t(`changes.client.JuridicalClient.Ma'lumot_kiritilmagan`)}`
                }
              ]}
              label={<span className={styles.formLabel}>{t(`changes.client.JuridicalClient.MFO`)}</span>}
            >
              <Input className="customInput" style={{ width: '95%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="bankName"
              rules={[
                {
                  required: true,
                  message: `${t(`changes.client.JuridicalClient.Ma'lumot_kiritilmagan`)}`
                }
              ]}
              label={<span className={styles.formLabel}>{t(`changes.client.JuridicalClient.Bank_nomi`)}</span>}
            >
              <Input className="customInput" style={{ width: '95%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="accountNumber"
              rules={[
                {
                  required: true,
                  message: `${t(`changes.client.JuridicalClient.Ma'lumot_kiritilmagan`)}`
                }
              ]}
              label={<span className={styles.formLabel}>{t(`changes.client.JuridicalClient.Hisob-raqami`)}</span>}
            >
              <Input className="customInput" style={{ width: '95%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="director"
              rules={[
                {
                  required: true,
                  message: `${t(`changes.client.JuridicalClient.Ma'lumot_kiritilmagan`)}`
                }
              ]}
              label={<span className={styles.formLabel}>{t(`changes.client.JuridicalClient.Direktor`)}</span>}
            >
              <Input className="customInput" style={{ width: '95%' }} />
            </Form.Item>
          </Col>
        </Row>
        {force?.status && (
          <>
            <div className={styles.connectLead}>
              <span className={styles.connectLead_title}>{t('one_lid.Lead_malumotlari')}</span>
              {currentClientLead ? (
                <ClientLead currentClientLead={currentClientLead} setSelectLead={setSelectLead} isCliendLead={isCliendLead} />
              ) : (
                <Form.Item name="test" rules={[{ required: true, message: 'test' }]}>
                  <div className={styles.connectLead_body}>
                    <img src={img} alt="err" />
                    <span onClick={handleOpenLeadModal}>{t('one_lid.lid_biriktirish')}</span>
                  </div>
                </Form.Item>
              )}
            </div>
            <ConnectLeadModal
              visible={openLeadModal}
              setVisible={setOpenLeadModal}
              handleOpenCreateModal={handleOpenCreateModal}
              clientId={selectedClient?.id || 0}
              setSelectLead={setSelectLead}
            />
            <CreateLeadModal visible={openCreateModal} setVisible={setOpenCreateModal} setSelectLead={setSelectLead} />
          </>
        )}
        <Button htmlType="submit" loading={createAndUpdateClient.isLoading} className={styles.save_button} type="primary">
          {selectedClient?.id ? `${t(`changes.client.NaturalClient.Saqlash`)}` : `${t(`changes.client.NaturalClient.Yaratish`)}`}
        </Button>
      </Form>
    </div>
  );
};

export default JuridicalClient;
