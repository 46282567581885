import { baseErrorHandler } from '@queries/baseErrorHandler';
import { $api } from '@service/RequestService';
import { CrmLeadList, CrmPieChartModel } from '@utils/models/CrmLeadList';
import { Method } from 'axios';
import { useInfiniteQuery, useQuery } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { ChartDataModel } from '../utils/models/ChartDataModel';
import { DashboardModel } from '../utils/models/DashboardModel';
import { TimeLineDataModel } from '../utils/models/TimeLineDataModel';
import { WidgetModel } from '../utils/models/WidgetModel';

export function useGetDashboards() {
  return useQuery<DashboardModel[]>({
    queryKey: [queryKeys.DASHBOARD],
    queryFn: async () => {
      const res = await $api.get(endPoints.DASHBOARD);

      return res.data.data;
    },
    onError: baseErrorHandler,
    retry: false,
    refetchOnWindowFocus: false
  });
}

export function useGetWidgets(dashboardId: number) {
  return useQuery<WidgetModel[]>({
    queryFn: async () => {
      const res = await $api.get(`${endPoints.WIDGETS}/${dashboardId}`);

      return res.data.data;
    },
    queryKey: [queryKeys.WIDGETS, dashboardId],
    onError: baseErrorHandler,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!dashboardId
  });
}

export function useGetWidget(url: string, body: any, requestType: string, widgetType: string, enabled: boolean) {
  if (widgetType === 'LIST') {
    return useInfiniteQuery<any>({
      queryFn: async ({ pageParam = 1 }) => {
        body.page = pageParam;
        body.size = 10;
        const res = await $api.request({
          method: requestType as Method,
          url,
          data: body
        });

        function conventr() {
          let obj = {
            currentPage: 0,
            totalElements: 0,
            totalPages: 0,
            data: []
          };

          if (res.data.data?.data) {
            obj = res.data.data;
          } else if (res.data.data?.content) {
            obj = {
              data: res.data.data?.content,
              ...res.data.data
            };
          } else if (res.data.data?.leadList) {
            obj = {
              ...res.data.data,
              ...res.data.data?.leadList
            };
          }

          return obj;
        }
        return conventr();
      },
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage.totalPages) {
          return page.length + 1;
        }
        return undefined;
      },
      queryKey: [queryKeys.WIDGET, body, requestType, url, enabled],
      onError: baseErrorHandler,
      retry: false,
      refetchOnWindowFocus: false
      // enabled: enabled
    });
  }
  return useQuery<CrmLeadList & ChartDataModel & TimeLineDataModel & CrmPieChartModel>({
    queryFn: async () => {
      const res = await $api.request({
        method: requestType as Method,
        url,
        data: body
      });

      return res.data.data;
    },
    queryKey: [queryKeys.WIDGET, body, requestType, url],
    onError: baseErrorHandler,
    retry: false,
    refetchOnWindowFocus: false
  });
}

export function useGetSharedUsers(id: number) {
  return useQuery<{ employeeId: number; permission: string }[]>({
    queryKey: [queryKeys.SHARED_USERS, id],
    queryFn: async () => {
      const res = await $api.get(endPoints.SHARED_USERS + id);

      return res.data.data;
    },
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
    onError: baseErrorHandler
  });
}
