import { Button, Input } from 'antd';
import React, { FC } from 'react';
import SearchIcon from '../../../assets/icons/SearchIcon';
import styles from './notificationDeveloperHeader.module.scss';

interface IProps {
  setVisible: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      id: number;
    }>
  >;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const NotificationDeveloperHeader: FC<IProps> = ({ setVisible, setSearch }) => {
  const createNotificationModal = () => {
    setVisible({
      id: 0,
      visible: true
    });
  };
  const changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <div className={styles.container}>
      <Input suffix={<SearchIcon />} placeholder="Bildirishnomalardan izlash" className={styles.container_searchInput} onChange={changeSearch} />
      <Button type="primary" className={styles.container_createButton} onClick={createNotificationModal}>
        + Yangi yaratish
      </Button>
    </div>
  );
};

export default NotificationDeveloperHeader;
