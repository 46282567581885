import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Input, Spin } from 'antd';
import { t } from 'i18next';

import { useQueryParam } from '../../hooks/useQueryParams';
import { useCompanyCompact } from '../../queries/queries';
import { DeveloperPaths } from '../../routing/developer/developerPaths';
import { rootPaths } from '../../routing/rootPaths';
import { urlEncode } from '../../utils/helper/urlEncodeDecode';

import styles from '../../pages/companyEmployeeRoles/companyEmployeeRoles.module.scss';

const CompanyEmployeeRolesNavbar = () => {
  const { data, isLoading } = useCompanyCompact();
  const navigate = useNavigate();
  const { splitUrl } = useQueryParam();
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (data && splitUrl.length < 4) {
      if (data.length > 0) {
        navigate(`${rootPaths.DEVELOPER + DeveloperPaths.PERMISSIONS}/${data[0].id}/${data[0].name}`);
      }
    }
  }, [data, navigate, splitUrl.length]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.currentTarget.value);
  };

  const filteredData = data?.filter(item => item.name.toLowerCase().includes(searchText.toLowerCase()));

  return (
    <div className={styles.EmployeeRolesNavbar}>
      <div className={styles.input_wrp}>
        <Input type="search" placeholder={t('home.Qidirish')} onChange={handleSearch} className={styles.input_sc} />
      </div>
      {isLoading && <Spin />}
      {filteredData?.map(item => (
        <NavLink key={item.id} to={String(`${item.id}/${urlEncode(item.name)}`)}>
          {item.name}
        </NavLink>
      ))}
    </div>
  );
};

export default CompanyEmployeeRolesNavbar;
