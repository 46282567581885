import React from 'react';
import { useCreateDefaultLead } from '@pages/createOrder/services/mutation';
import { helper } from '@utils/helper/helper';
import { ClientLeadModel } from '@utils/models/ClientLeadModel';
import { Button, Form, Input, Modal } from 'antd';
import { t } from 'i18next';
import ReactInputMask from 'react-input-mask';

import styles from './createLead.module.scss';

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectLead: React.Dispatch<React.SetStateAction<ClientLeadModel | undefined>>;
};

const CreateLeadModal: React.FC<Props> = ({ setVisible, visible, setSelectLead }) => {
  const [form] = Form.useForm();
  const createDefaultLead = useCreateDefaultLead();

  const handleClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const onFinish = (value: { phone: string }) => {
    const phone = helper.parseFloatStringToNumber(value?.phone);

    createDefaultLead.mutateAsync(String(phone)).then(res => {
      setSelectLead(res?.data);
      handleClose();
    });
  };

  return (
    <Modal title={t('home.Yangi_lid_yaratish')} visible={visible} onCancel={handleClose} footer={false} className={styles.createLead}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: ''
            }
          ]}
        >
          <ReactInputMask
            mask="99 999 99 99"
            // @ts-ignore*
            maskChar=""
          >
            {(inputProps: any) => <Input addonBefore="+998" placeholder="XX XXX XX XX" className="customInput" {...inputProps} />}
          </ReactInputMask>
        </Form.Item>
        <div className={styles.footer}>
          <Button htmlType="submit" loading={createDefaultLead.isLoading}>
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateLeadModal;
