import { CrmFieldsType } from './CrmFieldsType';
import { FileObject } from './File';
import { LeadTaskNotClosed } from './LeadTaskNotClosed';

export enum LEAD_DATA_TYPE {
  LEAD_EVENT = 'LEAD_EVENT',
  LEAD_TASK = 'LEAD_TASK',
  LEAD_NOTE = 'LEAD_NOTE',
  LEAD_MESSAGE = 'LEAD_MESSAGE',
  LEAD_CONVERSATION = 'LEAD_CONVERSATION',
  EVENT_CLIENT = 'EVENT_CLIENT'
}

export enum LEAD_EVENT_TYPE {
  CREATE_LED = 'CREATE_LED',
  CREATE_CONTACT = 'CREATE_CONTACT',
  CREATE_TAG = 'CREATE_TAG',
  REMOVE_TAG = 'REMOVE_TAG',
  CHANGE_STATUS = 'CHANGE_STATUS',
  CHANGE_VALUE = 'CHANGE_VALUE',
  SET_VALUE = 'SET_VALUE',
  DELETE_VALUE = 'DELETE_VALUE',
  CHANGE_LEAD_NAME = 'CHANGE_LEAD_NAME',
  CHANGE_LEAD_BALANCE = 'CHANGE_LEAD_BALANCE',
  CHANGE_LEAD_EMPLOYEE = 'CHANGE_LEAD_EMPLOYEE',
  SET_LEAD_EMPLOYEE = 'SET_LEAD_EMPLOYEE',
  CHANGE_CONTACT_NAME = 'CHANGE_CONTACT_NAME',
  INSTAGRAM = 'INSTAGRAM',
  TELEGRAM = 'TELEGRAM',
  FACEBOOK = 'FACEBOOK',
  CREATE_CONTACT_PHONE = 'CREATE_CONTACT_PHONE',
  REMOVE_CONTACT_PHONE = 'REMOVE_CONTACT_PHONE',
  CREATE_REASONS_FOR_REFUSAL = 'CREATE_REASONS_FOR_REFUSAL',
  CHANGE_REASONS_FOR_REFUSAL = 'CHANGE_REASONS_FOR_REFUSAL',
  REMOVE_CONTACT = 'REMOVE_CONTACT',
  PHONE = 'PHONE',
  SIMPLE = 'SIMPLE',
  SMS = 'SMS',
  DELETE_LEAD = 'DELETE_LEAD',
  RECOVERY_LEAD = 'RECOVERY_LEAD',
  CREATE_STATUS = 'CREATE_STATUS',
  LEAD_MERGE = 'LEAD_MERGE',
  ATTACH_LEAD_TO_CLIENT = 'ATTACH_LEAD_TO_CLIENT',
  REMOVE_CLIENT_FROM_LEAD = 'REMOVE_CLIENT_FROM_LEAD',
  NOTE = 'NOTE',
  EVENT = 'EVENT'
}

export enum FIELD_TYPE {
  LOCATION = 'LOCATION'
}

export interface OneEvent extends LeadTaskNotClosed {
  callStatus?: string | undefined;
  dialogDuration?: number | undefined;
  callId: string | null;
  date: string;
  createdById: number;
  createdBy: string;
  type: LEAD_EVENT_TYPE;
  leadCurrentName: string;
  current: string;
  fieldId: number;
  fieldType: CrmFieldsType;
  fieldName: string;
  currentTagName: string;
  previousId: number;
  previous: string;
  previousColor: string;
  currentId: number;
  currentColor: string;
  previousTagName: string;
  contactId: number;
  contactCurrentName: string;
  leadPreviousName: string;
  previousBalance: number;
  currentBalance: number;
  previousEmployeeId: number;
  previousEmployeeName: string;
  currentEmployeeId: number;
  currentEmployeeName: string;
  contactPreviousName: string;
  pin: boolean;
  leadId: number;
  text: string;
  textNote: string;
  files: FileObject[] | FileObject;
  id: number;
  wasRead: boolean;
  income: boolean;
  createdDate: string;
  from: ChatPerson;
  to: null | ChatPerson;
  number: string;
  chats: OneChatInformation[];
  contactCurrentPhone: string;
  contactPreviousPhone: string;
  duration: number;
  incoming: boolean;
  missedCall?: boolean;
  fromName: string;
  fromPhoneNumber: string;
  toName: string | null;
  toPhoneNumber: string;
  reasonsPreviousValue: string | null;
  mergeLeadId: number | undefined;
  mergeLeadName: string | undefined;
  events?: ClientEventModel[];
  clientName?: string;
  clientId?: number;
  leadDto: {
    balance: number;
    pipeStatusId: number;
    name: string;
    responsibleById: null;
    tagIds: number[];
    contacts: null;
    fields: null;
    reasonsId: null;
    id: number;
    companyId: null;
    createdBy: null;
    createdDate: string;
    autoClosedLeadTask: boolean;
  };
  new?: string;
  old?: string;
  typeChangeEvent?: string;
  bookingDto: {
    id: number;
    number: string;
  };
  paymentDto?: {
    id: number;
    number: number;
    amount: number;
    type: string;
    debet: string;
    createdBy: string;
    createdDate: string;
    canceledBy: string;
    canceledDate: string;
    cancelNote: string;
    payerId: number;
    payer: string;
    currency: {
      name: {
        uz: string;
        ru: string;
        en: string;
        kr: string;
        tr: string;
        default: null;
      };
      ccy: string;
      value: string;
      basic: boolean;
      updateDate: string;
      id: number;
    };
    fields: [];
  };
  contractShortDto: {
    clientName: string;
    createdByName: string;
    amount: number;
    houseName: string;
    number: string;
    createdDate: string;
    id: number;
    currency: {
      name: {
        uz: string;
        ru: string;
        en: string;
        kr: string;
        tr: string;
        default: null;
      };
      ccy: string;
      value: string;
      basic: boolean;
      updateDate: string;
      id: number;
    };
  };
  currentAddress: {
    street: string;
    index: null;
    zone: string;
    country_en: string;
    country_ru: string;
    country_uz: string;
    country_kr: string;
    country_tr: string;
    region_en: string;
    region_ru: string;
    region_uz: string;
    region_kr: string;
    region_tr: string;
    city_en: string;
    city_ru: string;
    city_uz: string;
    city_kr: string;
    city_tr: string;
  };
  prevAddress: {
    street: string;
    index: null;
    zone: string;
    country_en: string;
    country_ru: string;
    country_uz: string;
    country_kr: string;
    country_tr: string;
    region_en: string;
    region_ru: string;
    region_uz: string;
    region_kr: string;
    region_tr: string;
    city_en: string;
    city_ru: string;
    city_uz: string;
    city_kr: string;
    city_tr: string;
  };
  eventType?: string;
  employee?: string;
  analysed?: boolean;
  descriptionCall?: OneEventCallDescription[];
}

export interface OneEventCallDescription {
  key: string;
  value: string;
  fieldId: number | null;
  type: string;
  fieldType: string;
}

export interface OneChatInformation {
  id: number;
  text: string;
  files: FileObject | null;
  wasRead: boolean;
  income: boolean;
  leadId: number;
  createdDate: string;
  from: ChatPerson;
  to: ChatPerson | null;
  link?: string;
  stories?: FileObject | null;
  flatBuilding?: string;
  flatId?: number;
  flatNumber?: string;
  houseName?: string;
  flatFloor?: number;
  flatRooms?: number;
  flatArea?: number;
  houseId?: number;
}

export interface ChatPerson {
  id: number;
  name: string;
  avatar: null | string;
}

export interface LeadEventOrTask {
  date: string;
  leadDataType: LEAD_DATA_TYPE;
  eventType?: LEAD_DATA_TYPE;
  res: OneEvent;
  events?: OneEvent[];
}

export interface LeadEventsAndTasksList {
  date: string;
  events: LeadEventOrTask[];
}
export interface ClientEventModel {
  id: number;
  type: string;
  text: {
    uz: string;
    ru: string;
    en: string;
    kr: string;
    tr: string;
    default: string | null;
  };
  date: string;
  closedDate: null;
  employee: string;
  payment: null;
  closedText: null;
  closedBy: null;
  deadlineStart: null;
  deadlineFinish: null;
  leadTaskTypeDto: null;
  paymentDto: null;
  contractShortDto: null;
  bookingDto: null;
  leadDto: {
    balance: number;
    pipeStatusId: number;
    name: string;
    responsibleById: null;
    tagIds: [];
    contacts: null;
    fields: null;
    reasonsId: null;
    id: number;
    companyId: null;
    createdBy: null;
    createdDate: string;
    autoClosedLeadTask: boolean;
  };
  typeChangeEvent: string;
  old: null;
  new: null;
  fieldName: null;
}
