import React, { useState } from 'react';
import { Checkbox, Collapse, Space, Spin } from 'antd';
import { useBuildingCompactByHouseId } from '../../../../../queries/queries';
import { SearchParamsContract } from '../../../Contract';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import ColapseIcon from '../../../../../assets/icons/ColapseIcon';

interface Props {
  id: number;
  name: string;
}

const { Panel } = Collapse;

const HouseItem: React.FC<Props> = ({ name, id }) => {
  const [activeKeys, setActiveKeys] = useState<string | number | (string | number)[] | undefined>([]);

  const activekey = Array.isArray(activeKeys) ? activeKeys[0] : activeKeys;

  const { data: buildings, isLoading } = useBuildingCompactByHouseId(Number(activekey || 0));

  const { searchParams, remove, appendMultiple } = useQueryParam<SearchParamsContract, any>();

  const changeHouse = (id: string) => {
    if (Array.isArray(searchParams.houses)) {
      searchParams.houses?.includes(id) ? remove('houses', id) : appendMultiple('houses', id);
    } else {
      if (searchParams.houses === id) {
        remove('houses', id);
      } else {
        appendMultiple('houses', id);
      }
    }
  };

  const changeBuilding = (id: string) => {
    if (Array.isArray(searchParams.buildings)) {
      searchParams.buildings?.includes(id) ? remove('buildings', id) : appendMultiple('buildings', id);
    } else {
      if (searchParams.buildings === id) {
        remove('buildings', id);
      } else {
        appendMultiple('buildings', id);
      }
    }
  };

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={[searchParams.houses?.includes(id.toString()) ? id : '0']}
      activeKey={activeKeys}
      expandIconPosition={'right'}
      onChange={setActiveKeys}
      expandIcon={({ isActive }) => (
        <span
          style={{
            transform: `${isActive ? 'rotate(0deg)' : 'rotate(90deg)'}`,
            transition: '0.3s'
          }}
        >
          <ColapseIcon />
        </span>
      )}
    >
      <Panel
        header={
          <div className={'house_header'}>
            <Checkbox
              checked={Array.isArray(searchParams.houses) ? searchParams.houses.includes(String(id)) : searchParams.houses === String(id)}
              onClick={e => e.stopPropagation()}
              onChange={e => {
                e.stopPropagation();
                changeHouse(String(id));
              }}
            />
            <span className={'house_header_title'}>{name}</span>
          </div>
        }
        key={id}
      >
        <Spin spinning={isLoading}>
          <Space direction="vertical" style={{ width: '100%' }}>
            {buildings?.map((building, index) => (
              <Checkbox
                checked={
                  Array.isArray(searchParams.buildings)
                    ? searchParams.buildings.includes(String(building.id))
                    : searchParams.buildings === String(building.id)
                }
                onChange={() => {
                  changeBuilding(String(building.id));
                }}
                key={index}
              >
                {building.name}
              </Checkbox>
            ))}
          </Space>
        </Spin>
      </Panel>
    </Collapse>
  );
};

export default HouseItem;
