import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useCreateContract } from '../../queries/mutation';
import { clear, setFirstPaymentDate, setPrepaymentDate } from '../../store/reducers/CreateContractSlice';
import { DATE_FORMAT } from '../../utils/constants/format';
import { validateDateString } from '../../utils/helper/validatorDateString';
import { CreateContract } from '../../utils/models/CreateContract';
import DatePickerGlobal from '../date-picker/DatePicker';

import "../../pages/contract/contract.module.scss";

const CardConfiguration = () => {
  const [form] = Form.useForm();
  const params = useParams<{
    idClient: string;
  }>();
  const { splitUrl, push } = useQueryParam();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isPressButtons = useRef(false);

  const createContract = useCreateContract();
  const {
    prepaymentDate,
    firstPaymentDate,
    delay,
    flats,
    delayInterval,
    employeeId,
    brokerId,
    prePayment,
    monthlyPaymentPartDtos,
    fields,
    discountText,
    discountSum,
    contractNumber,
    residueTablePayments,
    changeContractNumber,
    currency
  } = useAppSelector(state => state.createContract);

  useEffect(() => {
    if (prepaymentDate && firstPaymentDate) {
      form.setFieldsValue({
        prepaymentDate: dayjs(prepaymentDate, DATE_FORMAT),
        firstPaymentDate: dayjs(firstPaymentDate, DATE_FORMAT)
      });
    }
  }, [prepaymentDate, firstPaymentDate, form]);

  useEffect(() => 
     () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (!isPressButtons.current) {
        dispatch(clear());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);

  const changePrepaymentDate = (value: Dayjs) => {
    dispatch(setPrepaymentDate(value.format(DATE_FORMAT)));
  };

  const changeFirstPaymentDate = (value: Dayjs) => {
    dispatch(setFirstPaymentDate(value.format(DATE_FORMAT)));
  };

  const goBack = () => {
    isPressButtons.current = true;
    let url = '';

    for (let i = 0; i <= splitUrl.length - 3; i++) {
      url += splitUrl[i];
      if (i !== splitUrl.length - 3) {
        url += '/';
      }
    }
    push(url);
  };

  const handleCreateContract = () => {
    if (residueTablePayments === 0) {
      const flatsForCreate: { flatId: number; repaired: boolean }[] = [];

      flats.forEach(flat => {
        flatsForCreate.push({
          flatId: Number(flat.flat.id),
          repaired: flat.repaired
        });
      });
      const createContractData: CreateContract = {
        flats: flatsForCreate,
        delay: delay || 6,
        delayInterval: delayInterval || 1,
        discount: discountSum || undefined,
        discountDescription: discountText || undefined,
        prepayment: prePayment,
        clientId: Number(params.idClient?.split('=')[1]),
        monthlyPaymentPartDtos: monthlyPaymentPartDtos || [],
        brokerId,
        prepaymentDate,
        number: changeContractNumber ? contractNumber : null,
        fields,
        responsibleBy: employeeId,
        currencyId: currency?.id || 0
      };

      createContract.mutate(createContractData);
    } else {
      message.error(t('create_table_payments.Tolov_jadvali_xato_toldirilgan'));
    }
  };

  return (
    <Form form={form} className="cardConfiguration">
      <h1>{t('create_table_payments.Shartnoma_malumotlari')}</h1>
      <div>
        <div>
          <h1>{t('create_table_payments.Boshlangich_tolov_sanasi')}</h1>
          <DatePickerGlobal form={form} formItemName="prepaymentDate" onChange={changePrepaymentDate} formClassName="changesInput" />
        </div>
        <div>
          <h1>{t('create_table_payments.Birinchi_tolov_sanasi')}</h1>
          <Form.Item
            noStyle
            rules={[
              {
                validator: (rule, value) => {
                  if (value) {
                    form.setFieldsValue({
                      firstPaymentDate: validateDateString(value, true)
                    });
                  }
                  return Promise.resolve();
                }
              },
              {
                required: true,
                len: 10,
                message: ''
              }
            ]}
            name="firstPaymentDate"
            className="changesInput"
          >
            <DatePickerGlobal form={form} formItemName="firstPaymentDate" onChange={changeFirstPaymentDate} formClassName="changesInput" />
          </Form.Item>
        </div>
      </div>
      <div>
        <Button className="buttonCardConfiguration" onClick={goBack}>
          {t('client_create_contract.Orqaga')}
        </Button>
        <Button className="buttonCardConfiguration" onClick={handleCreateContract} loading={createContract.isLoading} type="primary">
          {t('create_table_payments.Shartnoma_yaratish')}
        </Button>
      </div>
    </Form>
  );
};

export default CardConfiguration;
