import { $api } from '@service/RequestService';
import { useMutation, useQueryClient } from 'react-query';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { CrmSettingsModel } from '../utils/models/CrmSettingsModel';
import { message } from 'antd';
import { baseErrorHandler } from '@queries/baseErrorHandler';

export function useOneCrmSettingRole() {
  const qc = useQueryClient();
  return useMutation(
    async (data: CrmSettingsModel) => {
      const res = await $api.post(endPoints.CRM_SETTINGS_ROLE, data);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success('Muvaffaqqiyatli');
        qc.invalidateQueries([queryKeys.CRM_SETTINGS]);
        qc.invalidateQueries([queryKeys.CRM_SETTINGS_COMPACT]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useUpdateCrmEmployeeRole() {
  const qc = useQueryClient();
  return useMutation(
    async (data: { employeeId: number; crmRoleId: number | null }) => {
      const res = await $api.patch(endPoints.CRM_SETTINGS_ROLE, data);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success('Muvaffaqqiyatli');
        qc.invalidateQueries([queryKeys.CRM_SETTINGS]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteRole(id: number | undefined) {
  const qc = useQueryClient();
  return useMutation(
    async () => {
      const res = await $api.delete(`${endPoints.CRM_SETTINGS_ROLE}/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.CRM_SETTINGS]);
        qc.invalidateQueries([queryKeys.CRM_SETTINGS_COMPACT]);
        message.success('Muvaffaqqiyatli');
      },
      onError: baseErrorHandler
    }
  );
}
