import React from 'react';
import BossLineChart from '../../components/bossArrearage/BossLineChart';
import BossTable from '../../components/bossArrearage/BossTable';
import styles from './bossArrearage.module.scss';
import MainHeader from '../../components/headers/MainHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import { Layout } from 'antd';

const { Content } = Layout;

const BossArrearage = () => {
  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <NotificationButtons />
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={styles.container}>
          <BossLineChart />
          <BossTable />
        </div>
      </Content>
    </>
  );
};

export default BossArrearage;
