import React, { useState } from 'react';

import styles from './statisticsOfEmployee.module.scss';
import ArrowDownIconCrm from '../../../../assets/icons/ArrowDownIconCrm';
import ShapeIconCrm from '../../../../assets/icons/crmIcons/ShapeIconCrm';
import { PlanFactPipe } from '../../../../utils/models/PlanFactPipe';
import { useTranslation } from 'react-i18next';

interface Props {
  data: PlanFactPipe | undefined;
}

const StatisticsOfEmployee: React.FC<Props> = ({ data }) => {
  const [show, setShow] = useState(false);

  const { t } = useTranslation();

  // handle show
  const handleShow = () => {
    setShow(!show);
  };

  const getName = (name: string) => {
    if (name.length > 15) {
      return name.slice(0, 15) + '...';
    }
    return name;
  };

  return (
    <div className={styles.container}>
      {show && (
        <div className={styles.container_item_cont}>
          {data?.factEmployeeDtoList.map((factEmployee, index) => {
            const list: { color: string; count: number }[] = [];

            for (let key in factEmployee.factPipeStatusMap) {
              list.push({
                color: key,
                count: factEmployee.factPipeStatusMap[key]
              });
            }

            return (
              <div key={index} className={styles.container_item_cont_item}>
                <span title={factEmployee.employeeCompactDto.fullName}>
                  {window.innerWidth <= 1280 ? getName(factEmployee.employeeCompactDto.fullName) : factEmployee.employeeCompactDto.fullName}
                </span>
                <div className={styles.container_item_cont_item_count}>
                  {list.map((item, i) => (
                    <div key={i} className={styles.container_item_cont_item_count_one}>
                      <ShapeIconCrm color={item.color} />
                      <span className={styles.container_item_cont_item_count_one_value}>{item.count}</span>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div onClick={handleShow} className={styles.container_button}>
        {show ? (
          <>
            <span>{t('boss_crm.Yopish')}</span>
            <ArrowDownIconCrm className={styles.container_button_top} />
          </>
        ) : (
          <>
            <span>{t('boss_crm.Batafsil_korsatish')}</span>
            <ArrowDownIconCrm />
          </>
        )}
      </div>
    </div>
  );
};

export default StatisticsOfEmployee;
