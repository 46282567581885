import { $api } from "@service/RequestService";
import { useQuery } from "react-query";

import { File } from "modules/common";

import { queryKeys } from "../constants";

export const useJobProgressFiles = () => useQuery<File[]>({
    queryKey: [queryKeys.JOB_PROGRESS_FILES],
    queryFn: async () =>{
        const res = await $api.get('/v1/job/progress-files');

        return res.data.data
    }
})