import React from 'react';
import { Route, Routes } from 'react-router-dom';
import styles from './companyEmployeeRoles.module.scss';
import CompanyEmployeeRolesNavbar from '../../components/companyEmployeeRoles/CompanyEmployeeRolesNavbar';
import { companyEmployeeRolesRoutes } from '../../routing/companyEmployeeRoles/companyEmployeeRolesRoutes';
import MainHeader from '../../components/headers/MainHeader';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import { Layout } from 'antd';

const { Content } = Layout;

const CompanyEmployeeRoles = () => {
  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
      </MainHeader>
      <Content
        style={{
          padding: '1rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={styles.EmployeeRolesContent}>
          <CompanyEmployeeRolesNavbar />
          <Routes>
            {companyEmployeeRolesRoutes.map((route, index) => (
              <Route key={index} path={route.exact ? route.path : `${route.path}/*`} element={<route.component />} />
            ))}
          </Routes>
        </div>
      </Content>
    </>
  );
};

export default CompanyEmployeeRoles;
