import React, { useEffect, useState } from 'react';
import ArrowDownIcon from '@icons/ArrowDownIcon';
import DotsIcon from '@icons/DotsIcon';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { Checkbox, Dropdown, Segmented, Switch } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { SegmentedValue } from 'antd/lib/segmented';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import ArrowSquareDown from '../../assets/icons/ArrowSquareDown';
import { useQueryParam } from '../../hooks/useQueryParams';
import { BossPaymentsSearchParams } from '../../pages/bossPayments/BossPayments';
import { useBasicCurrency, useMobileHouse } from '../../queries/queries';
import { DATE_FORMAT } from '../../utils/constants/format';
import { priceSeparator } from '../../utils/helper/priceSeparator';
import { TotalPaymentByBaseEnum } from '../../utils/models/BossHome';
import { BossPayments, BossPaymentsType } from '../../utils/models/BossPayments';

import PaymentsModal from './PaymentsModal';

import styles from './barChartContainer.module.scss';

interface Props {
  data: BossPayments[] | undefined;
}

const BarChartContainer: React.FC<Props> = ({ data }) => {
  const { data: houses } = useMobileHouse();
  const [filter, setFilter] = useState<{ house?: number[] | null }>({});
  const [chartData, setChartData] = useState<BarDatum[]>([]);
  const [keys, setKeys] = useState<string[]>([]);
  const [legends, setLegends] = useState<string[]>([]);
  const [sumPayments, setSumPayments] = useState(0);
  const [openModalPayments, setOpenModalPayments] = useState({
    visible: false,
    date: '',
    base: '',
    debit: ''
  });
  const [outcome, setOutcome] = useState(false);

  const { appendMultipleDifferent, appendMultipleArray, searchParams } = useQueryParam<BossPaymentsSearchParams, any>();

  const { data: currency } = useBasicCurrency();

  const { t } = useTranslation();

  // minus date
  const addDate = (addDate: number) => {
    let startDate = dayjs(`01.${dayjs(new Date()).format('MM.YYYY')}`, DATE_FORMAT);

    if (searchParams.start) {
      startDate = dayjs(searchParams.start, DATE_FORMAT);
    }

    const start = startDate.add(addDate, 'month').format(DATE_FORMAT);
    const finish = dayjs(startDate, DATE_FORMAT)
      .add(addDate + 1, 'month')
      .add(-1, 'day')
      .format(DATE_FORMAT);

    appendMultipleDifferent(['type', 'start', 'finish'], [BossPaymentsType.DAY, start, finish]);
  };

  // minus month
  const addMonth = (addMonth: number) => {
    const dateString = dayjs(searchParams.start, DATE_FORMAT).add(addMonth, 'year').format(DATE_FORMAT);

    appendMultipleDifferent(['type', 'start', 'finish'], [BossPaymentsType.MONTH, dateString, dateString]);
  };

  // minus year
  const addYear = (addYear: number) => {
    const start = dayjs(searchParams.start, DATE_FORMAT).add(addYear, 'year').format(DATE_FORMAT);
    const finish = dayjs(searchParams.finish, DATE_FORMAT).add(addYear, 'year').format(DATE_FORMAT);

    appendMultipleDifferent(['type', 'start', 'finish'], [BossPaymentsType.YEAR, start, finish]);
  };

  // append day
  const appendDay = () => {
    const startDate = `01.${dayjs(new Date()).format('MM.YYYY')}`;
    const finishDate = dayjs(startDate, DATE_FORMAT).add(1, 'month').add(-1, 'day').format(DATE_FORMAT);

    appendMultipleDifferent(['type', 'start', 'finish'], [BossPaymentsType.DAY, startDate, finishDate]);
  };

  // append month
  const appendMonth = () => {
    const dateString = dayjs(new Date()).format(DATE_FORMAT);

    appendMultipleDifferent(['type', 'start', 'finish'], [BossPaymentsType.MONTH, dateString, dateString]);
  };

  // append year
  const appendYear = () => {
    const start = dayjs(new Date()).add(-4, 'year').format(DATE_FORMAT);
    const finish = dayjs(new Date()).format(DATE_FORMAT);

    appendMultipleDifferent(['type', 'start', 'finish'], [BossPaymentsType.YEAR, start, finish]);
  };

  // header value month
  const headerValueMonth = () => dayjs(searchParams.start, DATE_FORMAT).format('YYYY');

  // header value year
  const headerValueYear = () => {
    const start = dayjs(searchParams.start, DATE_FORMAT);
    const finish = dayjs(searchParams.finish, DATE_FORMAT);

    return `${start.format('YYYY')} - ${finish.format('YYYY')}`;
  };

  // header value day
  const headerValueDay = () => {
    let date = dayjs(new Date());

    if (searchParams.start) {
      date = dayjs(searchParams.start, DATE_FORMAT);
    }
    return `${t(`boss_payments.${date.format('MMMM')}`)}, ${date.format('YYYY')}`;
  };

  useEffect(() => {
    if (data) {
      const newChartData: BarDatum[] = [];
      const keysArr: string[] = [];
      let sum = 0;

      data.forEach((item: BossPayments) => {
        const copyItem: any = item?.payment?.totalPaymentByBase;
        const newItem: BarDatum = {
          date: item.date,
          data: copyItem,
          ...(outcome
            ? {
                [TotalPaymentByBaseEnum.OUTCOME]: item.payment.totalPaymentByBase?.OUTCOME ? item.payment.totalPaymentByBase?.OUTCOME?.all || 0 : 0,
                [`${TotalPaymentByBaseEnum.OUTCOME}Color`]: '#FF0000'
              }
            : {
                [TotalPaymentByBaseEnum.SURCHARGE]: item.payment.totalPaymentByBase?.SURCHARGE ? item.payment.totalPaymentByBase?.SURCHARGE?.all || 0 : 0,
                [`${TotalPaymentByBaseEnum.SURCHARGE}Color`]: '#FE9649',
                [TotalPaymentByBaseEnum.CLIENT]: item.payment?.totalPaymentByBase?.CLIENT ? item.payment?.totalPaymentByBase?.CLIENT.all || 0 : 0,
                [`${TotalPaymentByBaseEnum.CLIENT}Color`]: '#917EF7',
                [TotalPaymentByBaseEnum.CONTRACT]: item.payment.totalPaymentByBase?.CONTRACT ? item.payment.totalPaymentByBase?.CONTRACT?.all || 0 : 0,
                [`${TotalPaymentByBaseEnum.CONTRACT}Color`]: '#4D968A',
                [TotalPaymentByBaseEnum.BOOKING]: item.payment.totalPaymentByBase?.BOOKING ? item.payment.totalPaymentByBase?.BOOKING?.all || 0 : 0,
                [`${TotalPaymentByBaseEnum.BOOKING}Color`]: '#FFD023'
              })
        };

        newChartData.push(newItem);

        if (outcome) {
          if (item?.payment?.totalPaymentByBase.OUTCOME) {
            keysArr.unshift(TotalPaymentByBaseEnum.OUTCOME);
          }
        } else {
          if (item?.payment?.totalPaymentByBase.CONTRACT) {
            keysArr.unshift(TotalPaymentByBaseEnum.CONTRACT);
          }
          if (item?.payment?.totalPaymentByBase.CLIENT) {
            keysArr.unshift(TotalPaymentByBaseEnum.CLIENT);
          }
          if (item?.payment?.totalPaymentByBase.BOOKING) {
            keysArr.unshift(TotalPaymentByBaseEnum.BOOKING);
          }
          if (item?.payment?.totalPaymentByBase.SURCHARGE) {
            keysArr.unshift(TotalPaymentByBaseEnum.SURCHARGE);
          }
        }

        sum += item.payment.all;
      });

      const copykeysArr: any = new Set([...keysArr]);

      setLegends([...copykeysArr]);
      setKeys([...copykeysArr]);
      setChartData(newChartData);
      setSumPayments(sum);
    }
  }, [data, outcome]);

  // handle change type
  const changeType = (type: SegmentedValue) => {
    switch (type) {
      case BossPaymentsType.MONTH:
        appendMonth();
        break;
      case BossPaymentsType.YEAR:
        appendYear();
        break;
      default:
        appendDay();
        break;
    }
  };

  // handle click arrow left
  const handleArrowLeft = () => {
    switch (searchParams.type) {
      case BossPaymentsType.MONTH:
        addMonth(-1);
        break;
      case BossPaymentsType.YEAR:
        addYear(-5);
        break;
      default:
        addDate(-1);
        break;
    }
  };

  // handle click arrow right
  const handleArrowRight = () => {
    switch (searchParams.type) {
      case BossPaymentsType.MONTH:
        addMonth(1);
        break;
      case BossPaymentsType.YEAR:
        addYear(5);
        break;
      default:
        addDate(1);
        break;
    }
  };

  // header value
  const headerValue = () => {
    switch (searchParams.type) {
      case BossPaymentsType.MONTH:
        return headerValueMonth();
      case BossPaymentsType.YEAR:
        return headerValueYear();
      default:
        return headerValueDay();
    }
  };

  // handle modal payments
  const handleModalPayments = (visible: boolean, date?: string, base?: string, debit?: string) => {
    setOpenModalPayments({
      visible,
      date: date || '',
      base: base!,
      debit: debit!
    });
  };

  const changeHouse = (e: CheckboxChangeEvent, id: number) => {
    if (e.target.checked) {
      setFilter(prev => ({
        ...prev,
        house: [...(prev.house || []), id]
      }));
    } else {
      setFilter(prev => ({
        ...prev,
        house: prev.house?.filter(item => item !== id) || null
      }));
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_header}>
        <Dropdown
          placement="bottomRight"
          overlay={
            <div className={styles.dropdown}>
              <div className={styles.dropdown_item}>
                {t('home.Chiqimlarni_korsatish')}
                <Switch onChange={setOutcome} checked={outcome} />
              </div>
              {/* <div className={styles.dropdown_item}>
                Umumiy summani ko’rish <Switch />
              </div> */}
            </div>
          }
          trigger={['click']}
        >
          <div>
            <DotsIcon cursor="pointer" />
          </div>
        </Dropdown>
        <Segmented
          defaultValue={BossPaymentsType.DAY}
          value={searchParams.type}
          onChange={changeType}
          className={styles.container_header_segment}
          size="large"
          options={[
            {
              label: <div className={styles.container_header_segment_item}>{t('boss_payments.Kunlik')}</div>,
              value: BossPaymentsType.DAY
            },
            {
              label: <div className={styles.container_header_segment_item}>{t('boss_payments.Oylik')}</div>,
              value: BossPaymentsType.MONTH
            },
            {
              label: <div className={styles.container_header_segment_item}>{t('boss_payments.Yillik')}</div>,
              value: BossPaymentsType.YEAR
            }
          ]}
        />
        <Dropdown
          mouseEnterDelay={1000}
          mouseLeaveDelay={0.5}
          trigger={['click', 'hover']}
          onVisibleChange={() => {
            if (filter?.house) {
              appendMultipleArray('houses', filter?.house);
            }
          }}
          placement="bottomRight"
          overlay={
            <div className={styles.houses}>
              <Checkbox
                style={{ padding: '0 8px' }}
                checked={houses?.length === filter?.house?.length}
                onChange={e => {
                  if (e.target.checked) {
                    setFilter(prev => ({
                      ...prev,
                      house: [...(houses?.map(item => item?.id) || [])]
                    }));
                  } else {
                    setFilter(prev => ({
                      ...prev,
                      house: []
                    }));
                  }
                }}
              >
                {t('home.Barchasi')}
              </Checkbox>
              {houses?.map(item => (
                <div key={item?.id} title={item?.name?.length > 24 ? item?.name : ''}>
                  <Checkbox checked={filter.house?.includes(item?.id)} onChange={e => changeHouse(e, item?.id)}>
                    {item?.name?.length > 24 ? `${item?.name?.substring(0, 24)}...` : item?.name}
                  </Checkbox>
                </div>
              ))}
            </div>
          }
        >
          <div className={styles.selects}>
            {filter?.house?.length! > 0 && <span className={styles.countHouses}>{filter?.house?.length}</span>}
            {filter?.house?.length! > 0 ? t('home.TJM_Tanlandi') : t('home.Barcha_TJM_lar')}
            <ArrowDownIcon />
          </div>
        </Dropdown>
      </div>
      <div className={styles.container_body}>
        <div className={styles.container_body_top_slider}>
          <ArrowSquareDown onClick={handleArrowLeft} className={styles.container_body_top_slider_arrow_left} />
          <div className={styles.container_body_top_slider_middle}>
            <h2>{headerValue()}</h2>
            <p>
              {sumPayments.toLocaleString('ru')} {currency?.ccy}
            </p>
          </div>
          <ArrowSquareDown onClick={handleArrowRight} className={styles.container_body_top_slider_arrow_right} />
        </div>
        <div className={styles.container_body_chart}>
          <ResponsiveBar
            onClick={e => {
              const base = e?.id === TotalPaymentByBaseEnum.OUTCOME ? '' : String(e?.id);
              const debit = e?.id === TotalPaymentByBaseEnum.OUTCOME ? String(e?.id) : '';

              handleModalPayments(true, String(e.indexValue), base, debit);
            }}
            data={chartData}
            keys={[...keys].filter(item => legends?.includes(item))}
            indexBy="date"
            margin={{ top: 20, right: 100, bottom: 80, left: 100 }}
            padding={0.3}
            groupMode="stacked"
            valueScale={{ type: 'linear', nice: true }}
            indexScale={{ type: 'band', round: true }}
            colors={({ id, data }: any) => data[`${id}Color`]}
            axisLeft={{
              format: value => priceSeparator(value)
            }}
            axisBottom={{
              format: value => {
                switch (searchParams.type) {
                  case BossPaymentsType.MONTH:
                    return t(`boss_payments.${dayjs(value, DATE_FORMAT).format('MMM')}`);
                  case BossPaymentsType.YEAR:
                    return dayjs(value, DATE_FORMAT).format('YYYY');
                  default:
                    return dayjs(value, DATE_FORMAT).format('DD');
                }
              }
            }}
            axisTop={null}
            axisRight={null}
            enableLabel={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]]
            }}
            tooltip={e => {
              const data: any = e?.data?.data;
              const allData: any = Object.keys(data)?.reduce(
                (prev: any, current) => ({
                  ...prev,
                  ...(legends.includes(current) &&
                    Object.keys(data[current])?.reduce(
                      (prev2, current2) => ({
                        ...prev2,
                        [current2]: data[current][current2] + (prev[current2] || 0)
                      }),
                      {}
                    ))
                }),
                {}
              );

              // if (outcome) {
              //   for (let key in allData) {
              //     if (allData[key] && data?.OUTCOME && data?.OUTCOME[key]) {
              //       allData[key] -= data?.OUTCOME[key]
              //     }
              //   }
              // }
              return (
                <div className={styles.container_body_chart_tooltip}>
                  {/* <div className={styles.container_body_chart_tooltip_label}>
                    {e?.id === 'CLIENT'
                      ? t(`home.Mijoz_balansi`)
                      : t(`home.PAYMENT_${e?.id}`)}
                  </div> */}
                  <div className={styles.container_body_chart_tooltip_value}>
                    <span>{t('home.Barchasi')}:</span> {allData?.all.toLocaleString('ru')} {currency?.ccy}
                  </div>
                  {allData?.cash ? (
                    <div className={styles.container_body_chart_tooltip_value}>
                      <span>{t('home.CASH')}:</span> {allData?.cash?.toLocaleString('ru')} {currency?.ccy}
                    </div>
                  ) : (
                    ''
                  )}
                  {allData?.bank ? (
                    <div className={styles.container_body_chart_tooltip_value}>
                      <span>{t('home.BANK')}:</span> {allData?.bank?.toLocaleString('ru')} {currency?.ccy}
                    </div>
                  ) : (
                    ''
                  )}
                  {allData?.balance ? (
                    <div className={styles.container_body_chart_tooltip_value}>
                      <span>{t('home.BALANCE')}:</span> {allData?.balance?.toLocaleString('ru')} {currency?.ccy}
                    </div>
                  ) : (
                    ''
                  )}
                  {allData?.uzum ? (
                    <div className={styles.container_body_chart_tooltip_value}>
                      <span>{t('home.UZUM')}:</span> {allData?.uzum?.toLocaleString('ru')} {currency?.ccy}
                    </div>
                  ) : (
                    ''
                  )}
                  {allData?.card ? (
                    <div className={styles.container_body_chart_tooltip_value}>
                      <span>{t('home.CARD')}:</span> {allData?.card?.toLocaleString('ru')} {currency?.ccy}
                    </div>
                  ) : (
                    ''
                  )}
                  {allData?.payme ? (
                    <div className={styles.container_body_chart_tooltip_value}>
                      <span>{t('home.PAYME')}:</span> {allData?.payme?.toLocaleString('ru')} {currency?.ccy}
                    </div>
                  ) : (
                    ''
                  )}
                  {allData?.transfer ? (
                    <div className={styles.container_body_chart_tooltip_value}>
                      <span>{t('home.TRANSFER')}:</span> {allData?.transfer?.toLocaleString('ru')} {currency?.ccy}
                    </div>
                  ) : (
                    ''
                  )}
                  {allData?.others ? (
                    <div className={styles.container_body_chart_tooltip_value}>
                      <span>{t('home.OTHERS')}:</span> {allData?.others?.toLocaleString('ru')} {currency?.ccy}
                    </div>
                  ) : (
                    ''
                  )}
                  {/* {allData?.outcome ? (
                    <div className={styles.container_body_chart_tooltip_value}>
                      <span>{t('home.OUTCOME')}:</span>{' '}
                      {allData?.outcome?.toLocaleString('ru')} {currency?.ccy}
                    </div>
                  ) : (
                    ''
                  )} */}
                </div>
              );
            }}
            legendLabel={e => (e?.id === 'CLIENT' ? t(`home.Mijoz_balansi`) : t(`home.PAYMENT_${e?.id}`))}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 60,
                itemsSpacing: 70,
                itemWidth: 108,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                onClick: datum => {
                  setLegends(prev => {
                    if (prev.includes(String(datum?.id))) {
                      return prev.filter(item => item !== datum?.id);
                    }
                    return [...prev, String(datum?.id)];
                  });
                },
                data:
                  (keys?.map(e => ({
                    id: e,
                    color: chartData[0][`${e}Color`] + (legends?.includes(e) ? '' : '50'),
                    label: t(`home.PAYMENT_${e}`)
                  })) as any) || [],
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            // eslint-disable-next-line react/jsx-no-bind, func-names
            barAriaLabel={function (e) {
              return `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`;
            }}
          />
        </div>
      </div>
      <PaymentsModal date={openModalPayments.date} visible={openModalPayments.visible} base={openModalPayments.base} debit={openModalPayments.debit} close={handleModalPayments} />
    </div>
  );
};

export default BarChartContainer;
