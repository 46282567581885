export enum Permissions {
  PERMISSION_READ_FLATS = 2201,
  PERMISSION_CREATE_BOOKING = 1000,
  PERMISSION_CREATE_CONTRACT = 1100,
  PERMISSION_READ_BOOKINGS = 1001,
  PERMISSION_CANCEL_BOOKING = 1002,
  PERMISSION_CREATE_BOOKING_CONTRACT = 1004,
  PERMISSION_CANCEL_CONTRACT = 1105,
  PERMISSION_UPDATE_CONTRACT_AMOUNT = 1104,
  PERMISSION_TRANSFER_CONTRACT = 1103,
  PERMISSION_UPDATE_CONTRACT_MONTHLY_PAYMENT = 1102,
  PERMISSION_READ_CONTRACTS = 1101,
  PERMISSION_READ_PAYMENTS = 1200,
  PERMISSION_CREATE_PAYMENT = 1201,
  PERMISSION_CANCEL_PAYMENT = 1202,
  PERMISSION_UPDATE_CLIENT = 1301,
  PERMISSION_SEND_MESSAGE_TO_CLIENT = 1302,
  PERMISSION_READ_CLIENTS = 1300,
  PERMISSION_SEND_MESSAGE_TO_DEBTS = 1400,
  PERMISSION_CANCEL_CONTRACT_SURCHARGE = 1108,
  PERMISSION_READ_DEBTS = 2000,
  PERMISSION_READ_MESSAGES = 1401,
  PERMISSION_SEND_MESSAGE = 1402,
  PERMISSION_SETTINGS_HOUSE = 1501,
  PERMISSION_SETTINGS_MESSAGE = 1502,
  PERMISSION_SETTINGS_INTERFACE = 1503,
  PERMISSION_SETTINGS_EMPLOYEE = 1504,
  PERMISSION_SETTINGS_DISCOUNT = 1507,
  PERMISSION_SETTINGS_COMPANY = 1506,
  PERMISSION_SETTINGS_TRANSFER_DATA = 1508,
  PERMISSION_SETTINGS_BUILDER = 1505,
  PERMISSION_SETTINGS_BRANCH = 1500,
  PERMISSION_DEVELOPER = 2500,
  PERMISSION_TABLE_SETTINGS = 1510,
  PERMISSION_CHANGES = 1900,
  PERMISSION_INTEGRATION = 1519,
  PERMISSION_SETTINGS_AMOCRM = 1509,
  PERMISSION_SHOWROOM = 2200,
  PERMISSION_READ_STAFF = 2900,
  PERMISSION_SETTINGS_KPI = 1511,
  PERMISSION_MOBILE = 2100,
  PERMISSION_SETTINGS_AUTO_TASK = 1512,
  PERMISSION_PAGE_CRM = 1800,
  PERMISSION_SETTINGS_CRM = 1513,
  PERMISSION_CHANGE_CONTRACT_RESPONSIBLE = 1106,
  PERMISSION_MONETARY_PAYMENT = 1205,
  PERMISSION_REALTOR = 1516,
  PERMISSION_CHANGE_PASSWORD_EMPLOYEE = 1517,
  PERMISSION_STATS_MAIN = 1600,
  PERMISSION_STATS_PAYMENT = 1601,
  PERMISSION_STATS_DEBT = 1602,
  PERMISSION_STATS_HOUSE = 1603,
  PERMISSION_STATS_CONTRACT = 1604,
  PERMISSION_STATS_CRM_GENERAL = 1605,
  PERMISSION_STATS_CRM_CALLS = 1606,
  PERMISSION_STATS_CRM_TASKS = 1607,
  PERMISSION_STATS_CRM_LEADS = 1608,
  PERMISSION_STATS_SALE = 1609,
  PERMISSION_CALL_HISTORY = 3000,
  PERMISSION_CRM_CHAT_BOX = 1801,
  PERMISSION_CRM_LEAD_IMPORT_AND_EXPORT = 1802,
  PERMISSION_CRM_LEAD_GROUP_FUNCTION = 1803,
  PERMISSION_ACTIVITY_LOGS = 2300,
  PERMISSION_CONTRACT_PAYMENT = 1107,
  PERMISSION_PAST_DATE_PAYMENT = 1203,
  PERMISSION_LEAD_TASK_ACTIONS = 2400,
  PERMISSION_MACRO_ANALYTICS = 1611,
  PERMISSION_EDIT_MAIN_DASHBOARD = 1700,
  PERMISSION_PAGE_DASHBOARD = 1701,
  PERMISSION_ONEC = 1521,
  PERMISSION_SETTINGS_PAYMENT = 1522,
  PERMISSION_EXPO = 2700,
  PERMISSION_GIVE_DISCOUNT = 3200,
  PERMISSION_BOOKING_PAYMENT = 1003,
  PERMISSION_CALL_ANALYSE = 3201,
  PERMISSION_CHECK_PAYMENT = 1204,
  PERMISSION_MARKETPLACE = 1514,
  PERMISSION_UYQUR = 1515,
  PERMISSION_APELSIN = 1518,
  PERMISSION_COMPANY_ROLE = 1520,
  PERMISSION_CHECK_PAYMENT_REPORT = 1610,
  PERMISSION_AMOCRM = 1804,
  PERMISSION_COMPANY_OWNER = 2600,
  PERMISSION_EXPO_PRINT = 2800,
  PERMISSION_ADD_EXTRA_DAY = 3100,
  PERMISSION_MULTI_PAYMENT_CONTRACT = 1109,
  PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT = 1110,
  PERMISSION_ROBOT_CALL = 3300,
  PERMISSION_TELEGRAM_CHAT_BOT = 3400
}
