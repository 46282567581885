import { useState } from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'; // Используйте соответствующие иконки из вашей иконок библиотеки
import PaginationCont from '@components/pagenation/PaginationCont';
import { useAppDispatch } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import DeleteIconV3 from '@icons/DeleteIconV3';
import EditIconV3 from '@icons/EditIconV3';
import { editAndCreateModal } from '@reducers/WebsiteUserSlice';
import { Table } from 'antd';

import DeleteWebsiteUserModal from '../components/DeleteWebsiteUserModal';
import { useGetWebsiteUser } from '../service/queries';
import { WebsiteUserType } from '../utils/models/WebsiteUserType';

import styles from './websiteUser.module.scss';

const WebsiteUser = () => {
  const { data: dataWebsiteUsers, isLoading } = useGetWebsiteUser();
  const { searchParams, appendMultipleDifferent } = useQueryParam<WebsiteUserType, any>();
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useAppDispatch();

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const changePage = (page: number) => {
    appendMultipleDifferent(['page', 'size'], [page, 10]);
  };

  const openEditModal = (id: number) => {
    dispatch(editAndCreateModal({ id, open: true }));

    console.log('duck-website', id);
  };

  const columns = [
    {
      title: 'Kompaniya nomi',
      dataIndex: 'companyName',
      key: 'companyName'
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: (
        <div className="flex items-center gap-1">
          <p>Parol</p>
          <button onClick={toggleVisibility} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
            {isVisible ? <EyeInvisibleOutlined size={35} /> : <EyeOutlined size={35} />}
          </button>
        </div>
      ),
      render: (record: WebsiteUserType) => (
        <div className="w-[100px]">
          <span>{isVisible ? record?.password : '********'}</span>
        </div>
      )
    },
    {
      title: 'Role',
      render: (record: WebsiteUserType) => {
        const roles = record?.roles || [];

        const getRoleElement = (role: string) => {
          switch (role) {
            case 'SYSTEM':
              return <p className="rounded-[16px] bg-[#F5F3FF] px-[8px] py-[2px] text-[12px] font-medium text-[#875BF7]">SYSTEM</p>;
            case 'DEVELOPER':
              return <p className="rounded-[16px] bg-[#FFF1F3] px-[8px] py-[2px] text-[12px] font-medium text-[#F63D68]">DEVELOPER</p>;
            case 'WEBSITE':
              return <p className="rounded-[16px] bg-[#FAFAF9] px-[8px] py-[2px] text-[12px] font-medium text-[#79716B]">WEBSITE</p>;
            default:
              return <p className="rounded-[16px] bg-[#FAFAF9] px-[8px] py-[2px] text-[12px] font-medium text-[#79716B]">{role}</p>;
          }
        };

        return (
          <>
            {roles.map((role, index) => (
              <div key={index} className="inline-block items-center justify-center bg-[#FAFAF9] text-[12px] font-medium text-[#79716B]">
                {getRoleElement(role)}
              </div>
            ))}
          </>
        );
      }
    },
    {
      title: '',
      render: (record: any) => (
        <div className="d-flex align-center justify-center">
          <button onClick={() => openEditModal(record?.id)} className="cursor-pointer border-none bg-[transparent]">
            <EditIconV3 />
          </button>
          <DeleteWebsiteUserModal id={record?.id}>
            <button className="cursor-pointer border-none bg-[transparent]">
              <DeleteIconV3 />
            </button>
          </DeleteWebsiteUserModal>
        </div>
      )
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.container_table}>
        <Table dataSource={dataWebsiteUsers?.data} className="custom_table" columns={columns} loading={isLoading} pagination={false} />
      </div>
      <div className={styles.container_pagination}>
        <PaginationCont
          total={dataWebsiteUsers?.totalElements !== undefined ? dataWebsiteUsers.totalElements : -1}
          totalPages={dataWebsiteUsers?.totalPages || 1}
          changePage={changePage}
          currentPage={dataWebsiteUsers?.currentPage ? dataWebsiteUsers.currentPage : 1}
          defaultPageSize={10}
        />
      </div>
    </div>
  );
};

export default WebsiteUser;
