/* eslint-disable no-nested-ternary */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getCountryNameByCode } from '@pages/changes/utils/helpers/CountryCodes';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import i18n from '@service/I18nService';
import { encodeString } from '@utils/helper';
import { urlEncode } from '@utils/helper/urlEncodeDecode';
import { OneEvent } from '@utils/models/LeadEventsAndTaskList';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import NoteType from '../typeNote/TypeNote';

import styles from './clientEvent.module.scss';

type Props = {
  data: OneEvent;
};

const ClientEvent: React.FC<Props> = ({ data }) => {
  const navigation = useNavigate();
  const { t } = useTranslation();

  const goToOneLead = (id: number, name: string) => {
    navigation(`${rootPaths.MAIN + MainPaths.CRM}/${id}/${encodeString(name)}`);
  };
  const goToContract = (id: number, name: string) => {
    navigation(`${rootPaths.MAIN + MainPaths.CONTRACT}/${id}/${encodeString(name)}`);
  };

  const goToBooking = (id: number, name: string) => {
    navigation(`${rootPaths.MAIN + MainPaths.ORDER}/${id}/${urlEncode(`№ ${name}`)}?company=${btoa(String(id || ''))}`);
  };

  if (data.eventType === 'CREATE_CONTRACT') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}</span>
          <span className={styles.clientEvent_item_text}>{t('client_events.Shartnoma_yaratildi')}</span>
          <span className={styles.clientEvent_item_name} onClick={() => goToContract(data?.contractShortDto?.id, data?.contractShortDto?.number)}>
            №{data?.contractShortDto?.number}
          </span>
        </div>
      </div>
    );
  }

  if (data.eventType === 'LEAD_ATTACHED_CLIENT') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}</span>
          <span className={styles.clientEvent_item_text}>{t('client_events.lead_sizga_biriktirildi')}:</span>
          <span className={styles.clientEvent_item_name} onClick={() => goToOneLead(data?.leadDto?.id, data?.leadDto?.name)}>
            {data?.leadDto?.name}
          </span>
        </div>
      </div>
    );
  }
  if (data.eventType === 'CREATE_BOOKING') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}</span>
          <span className={styles.clientEvent_item_text}>{t('client_events.Booking_yaratildi')}:</span>
          <span className={styles.clientEvent_item_name} onClick={() => goToBooking(data?.bookingDto?.id, data?.bookingDto?.number)}>
            №{data?.bookingDto?.number}
          </span>
        </div>
      </div>
    );
  }
  if (data.eventType === 'PREPAYMENT_BOOKING') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}</span>
          <span className={styles.clientEvent_item_text}>
            {t('client_events.Buyurtmaga_boshlangich_tolov')?.replace('{0}', String(data?.paymentDto?.amount))?.replace('{1}', String(data?.paymentDto?.currency?.ccy))}:
          </span>
          <span className={styles.clientEvent_item_name} onClick={() => goToBooking(data?.bookingDto?.id, data?.bookingDto?.number)}>
            №{data?.bookingDto?.number}
          </span>
        </div>
      </div>
    );
  }
  if (data.eventType === 'CANCEL_PAYMENT_BOOKING') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>
            {t('client_events.Buyurtma_uchun_tolov_bekor_qilindi')
              ?.replace('{0}', String(data?.paymentDto?.amount?.toLocaleString('ru')))
              ?.replace('{1}', String(data?.paymentDto?.currency?.ccy))}
          </span>
        </div>
      </div>
    );
  }
  if (data.eventType === 'CANCEL_PAYMENT') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>
            {t('client_events.Shartnomaga_tolov_bekor_qilindi')
              ?.replace('{0}', String(data?.paymentDto?.amount?.toLocaleString('ru')))
              ?.replace('{1}', String(data?.paymentDto?.currency?.ccy))}
          </span>
        </div>
      </div>
    );
  }
  if (data.eventType === 'CHANGE_MONTHLY_PAYMENT') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}</span>
          <span className={styles.clientEvent_item_text}>
            {t('client_events.Shartnoma_tolov_grafigi_ozgartirildi')?.replace('{0}', String(data?.old))?.replace('{1}', String(data?.new))}
          </span>
          <span className={styles.clientEvent_item_name} onClick={() => goToContract(data?.contractShortDto?.id, data?.contractShortDto?.number)}>
            № {data?.contractShortDto?.number}
          </span>
        </div>
      </div>
    );
  }

  if (data.eventType === 'CHANGES_FIELD_CLIENT') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}</span>

          {/* <span className={styles.clientEvent_item_text}>
            {t('client_events.dan_ga_ozgartirildi')
              ?.replace('{0}', t(`client.${data?.fieldName}`))
              ?.replace('{1}', String(data?.old))
              ?.replace('{2}', String(data?.new))}
          </span> */}

          <span className={styles.clientEvent_item_text}>
            {t('client_events.dan_ga_ozgartirildi')
              ?.replace('{0}', t(`client.${data?.fieldName}`))
              ?.replace('{1}', getCountryNameByCode(data?.old) || data?.old)
              ?.replace('{2}', getCountryNameByCode(data?.new) || data?.new)}
          </span>
        </div>
      </div>
    );
  }

  if (data.eventType === 'CREATE_PAYMENT') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}</span>
          <span className={styles.clientEvent_item_text}>
            {t('client_events.Shartnomaga_tolov')
              ?.replace('{0}', String(data?.paymentDto?.amount?.toLocaleString('ru')))
              ?.replace('{1}', String(data?.paymentDto?.currency?.ccy))}
          </span>
        </div>
      </div>
    );
  }
  if (data.eventType === 'CREATE_CLIENT') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}</span>
          <span className={styles.clientEvent_item_text}>{t('client_events.Mijoz_yaratildi')}</span>
        </div>
      </div>
    );
  }
  if (data.eventType === 'ADD_FIELD_CLIENT') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}</span>
          <span className={styles.clientEvent_item_text}>
            {t('client_events.qoshildi')
              ?.replace('{0}', data?.old ? String(data?.old) : String(data?.new))
              ?.replace('{1}', String(data?.new))}
          </span>
        </div>
      </div>
    );
  }
  if (data.eventType === 'LEAD_CANCEL_CLIENT') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}</span>
          <span className={styles.clientEvent_item_text}>{t('client_events.lead_sizdan_olib_tashlandi')}</span>
          <span className={styles.clientEvent_item_name} onClick={() => goToOneLead(data?.leadDto?.id, data?.leadDto?.name)}>
            {data?.leadDto?.name}
          </span>
        </div>
      </div>
    );
  }
  if (data.eventType === 'CANCEL_CONTRACT') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}</span>
          <span className={styles.clientEvent_item_text}>{t('client_events.Shartnoma_bekor_qilindi')}</span>
          <span className={styles.clientEvent_item_name} onClick={() => goToContract(data?.contractShortDto?.id, data?.contractShortDto?.number)}>
            №{data?.contractShortDto?.number}
          </span>
        </div>
      </div>
    );
  }
  if (data.eventType === 'BOOKING_STATUS_CONTRACT') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}</span>
          <span className={styles.clientEvent_item_text}>{t('client_events.Booking_shartnomaga_biriktilildi')}</span>
        </div>
      </div>
    );
  }
  if (data.eventType === 'ACTIVATION_CONTRACT') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}</span>
          <span className={styles.clientEvent_item_text}>{t('home.Shartnoma_aktivlashtirildi')}</span>
        </div>
      </div>
    );
  }
  if (data.eventType === 'FINISH_CONTRACT') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}</span>
          <span className={styles.clientEvent_item_text}>Shartnoma yakunlandi</span>
        </div>
      </div>
    );
  }
  if (data.eventType === 'CLIENT_LEVEL_CHANGE') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}</span>
          <span className={styles.clientEvent_item_text}>
            {i18n?.language === 'uz'
              ? `Mijoz holati ${t(`home.${data?.old}`)} dan ${t(`home.${data?.new}`)} ga o'zgartirildi`
              : i18n?.language === 'ru'
                ? `Изменен статус клиента с ${t(`home.${data?.old}`)} на ${t(`home.${data?.new}`)}.`
                : i18n?.language === 'en'
                  ? `Changed client status from ${t(`home.${data?.old}`)} to ${t(`home.${data?.new}`)}`
                  : `Müşteri durumu ${t(`home.${data?.old}`)} ten ${t(`home.${data?.new}`)} a değiştirildi`}
          </span>
        </div>
      </div>
    );
  }
  if (data.eventType === 'CLIENT_LEVEL_ADDED') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}</span>
          <span className={styles.clientEvent_item_text}>{`${t('home.Mijoz_holati_ozgartirdi')}: ${t(`home.${data?.new}`)}`}</span>
        </div>
      </div>
    );
  }

  if (data.eventType === 'CONTRACT_CLIENT_CHANGE') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{t('client_events.shartnoma_mijozi')}:</span>
          <span className={styles.clientEvent_item_text}>
            {i18n?.language === 'uz'
              ? `${data?.old} dan ${data?.new} ga o'zgardi`
              : i18n?.language === 'ru'
                ? `Изменен с ${data?.old} на ${data?.new}`
                : i18n?.language === 'en'
                  ? `Changed from ${data?.old} to ${data?.new}`
                  : `${data?.old}'dan ${data?.new}'a değiştirildi`}
          </span>
          <span className={styles.clientEvent_item_name} onClick={() => goToContract(data?.contractShortDto?.id, data?.contractShortDto?.number)}>
            №{data?.contractShortDto?.number}
          </span>
        </div>
      </div>
    );
  }

  if (data.eventType === 'CONTRACT_RESPONSIBLE_CHANGE') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}:</span>
          <span className={styles.clientEvent_item_text}>
            {i18n?.language === 'uz'
              ? `Shartnoma masul xodimini ${data?.old} dan ${data?.new} ga o'zgatririldi`
              : i18n?.language === 'ru'
                ? `Контракт изменил ответственного офицера с ${data?.old}а на ${data?.new}а.`
                : i18n?.language === 'en'
                  ? `The contract changed the officer in charge from ${data?.old} to ${data?.new}`
                  : `Sözleşme, sorumlu memuru ${data?.old}'dan ${data?.new}'a değiştirdi`}
          </span>
        </div>
      </div>
    );
  }
  if (data.eventType === 'CONTRACT_AMOUNT_CHANGE') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}:</span>
          <span className={styles.clientEvent_item_text}>
            {i18n?.language === 'uz'
              ? `Shartnoma summasi ${Number(data?.old).toLocaleString('ru')} ${data?.contractShortDto?.currency?.name?.uz} dan ${Number(data?.new).toLocaleString('ru')} ${data?.contractShortDto?.currency?.name?.uz} ga o'zgatririldi`
              : i18n?.language === 'ru'
                ? `Сумма контракта изменена с ${Number(data?.old).toLocaleString('ru')} ${data?.contractShortDto?.currency?.name?.ru}а на ${Number(data?.new).toLocaleString('ru')} ${data?.contractShortDto?.currency?.name?.ru}а.`
                : i18n?.language === 'en'
                  ? `Contract amount changed from ${Number(data?.old).toLocaleString('ru')} ${data?.contractShortDto?.currency?.name?.en} to ${Number(data?.new).toLocaleString('ru')} ${data?.contractShortDto?.currency?.name?.en}`
                  : `Sözleşme tutarı ${Number(data?.old).toLocaleString('ru')} ${data?.contractShortDto?.currency?.name?.tr}'dan ${Number(data?.new).toLocaleString('ru')} ${data?.contractShortDto?.currency?.name?.tr}'a değiştirdi`}
          </span>
        </div>
      </div>
    );
  }

  if (data.eventType === 'CONTRACT_COMMENT_CREATE') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}</span>
          <span className={styles.clientEvent_item_text}>{t('client_events.Shartnoma_izohi_yaratildi')}:</span>
          <span className={styles.clientEvent_item_text}>"{data?.new}"</span>
        </div>
      </div>
    );
  }

  if (data.eventType === 'CONTRACT_COMMENT_CHANGE') {
    const truncateText = (text: string, maxLength: number) => {
      if (typeof text !== 'string') return String(text);
      return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    const oldValue = truncateText(String(data?.old), 20);
    const newValue = truncateText(String(data?.new), 20);

    const fullText = t('client_events.dan_ga_ozgartirildi')
      ?.replace('{0}', t(`client.${data?.fieldName}`))
      ?.replace('{1}', `"${String(data?.old)}"`)
      ?.replace('{2}', `"${String(data?.new)}"`);

    const displayText = t('client_events.dan_ga_ozgartirildi')
      ?.replace('{0}', t(`client.${data?.fieldName}`))
      ?.replace('{1}', `"${oldValue}"`)
      ?.replace('{2}', `"${newValue}"`);

    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}</span>

          <span className={styles.clientEvent_item_text}>
            <Tooltip title={fullText} placement="topLeft" overlayStyle={{ maxWidth: '700px' }}>
              <span className={styles.clientEvent_item_text}>{displayText}</span>
            </Tooltip>
          </span>
        </div>
      </div>
    );
  }

  if (data?.eventType === 'CONTRACT_CUSTOM_FIELD_CHANGE') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}:</span>
          <span className={styles.clientEvent_item_text}>
            {data?.old ? (
              <>
                <b>{data?.fieldName}</b>
                {t('client_events.dan_ga_ozgartirildi')
                  ?.replace('{0}', '')
                  ?.replace('{1}', getCountryNameByCode(data?.old) || data?.old)
                  ?.replace('{2}', getCountryNameByCode(data?.new) || data?.new)
                  .replace(',', '')}
              </>
            ) : (
              <>
                <b>{data?.fieldName}</b> {t('emp_dashboard.Qoshildi').toLowerCase()} {data?.new}
              </>
            )}
          </span>
        </div>
      </div>
    );
  }

  if (data?.eventType === 'CONTRACT_NUMBER_CHANGE') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}:</span>
          <span className={styles.clientEvent_item_text}>
            {t('client_events.dan_ga_ozgartirildi')
              ?.replace('{0}', t('changes.contract.Shartnoma_raqami'))
              ?.replace('{1}', getCountryNameByCode(data?.old) || data?.old)
              ?.replace('{2}', getCountryNameByCode(data?.new) || data?.new)
              .replace(',', '')}
          </span>
        </div>
      </div>
    );
  }

  if (data?.eventType === 'CONTRACT_PREPAYMENT_DATE_CHANGE') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}:</span>
          <span className={styles.clientEvent_item_text}>
            {t('client_events.dan_ga_ozgartirildi')
              ?.replace('{0}', t('changes.contract.Shartnoma_boshlangich_tolov_sanasi'))
              ?.replace('{1}', getCountryNameByCode(data?.old) || data?.old)
              ?.replace('{2}', getCountryNameByCode(data?.new) || data?.new)
              .replace(',', '')}
          </span>
        </div>
      </div>
    );
  }

  if (data?.eventType === 'CONTRACT_PREPAYMENT_CHANGE') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}:</span>
          <span className={styles.clientEvent_item_text}>
            {t('client_events.dan_ga_ozgartirildi')
              ?.replace('{0}', t('changes.contract.Shartnoma_boshlangich_tolov_summasi'))
              ?.replace('{1}', Number(data?.old).toLocaleString('ru'))
              ?.replace('{2}', Number(data?.new).toLocaleString('ru'))
              .replace(',', '')}
          </span>
        </div>
      </div>
    );
  }

  if (data?.eventType === 'CONTRACT_CREATED_DATE_CHANGE') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}:</span>
          <span className={styles.clientEvent_item_text}>
            {t('client_events.dan_ga_ozgartirildi')
              ?.replace('{0}', t('changes.contract.Shartnoma_yaratilgan_sana'))
              ?.replace('{1}', data?.old || '')
              ?.replace('{2}', data?.new || '')
              .replace(',', '')}
          </span>
        </div>
      </div>
    );
  }

  if (data?.eventType === 'CONTRACT_DISCOUNT_CHANGE') {
    return (
      <div className={styles.clientEvent}>
        <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
          <span className={styles.clientEvent_item_text}>{data?.date}</span>
          <span className={styles.clientEvent_item_text}>{data?.employee}:</span>
          <span className={styles.clientEvent_item_text}>
            {data?.old ? (
              <>
                {t('crm.Shartnoma_chegirmasi')}
                {t('client_events.dan_ga_ozgartirildi')
                  ?.replace('{0}', '')
                  ?.replace('{1}', Number(data?.old)?.toLocaleString('ru'))
                  ?.replace('{2}', Number(data?.new)?.toLocaleString('ru'))
                  .replace(',', '')}
              </>
            ) : (
              <>
                {t('crm.Shartnoma_chegirmasi')} {t('emp_dashboard.Qoshildi').toLowerCase()} {data?.new}
              </>
            )}
          </span>
        </div>
      </div>
    );
  }

  if (data.type === 'NOTE') {
    return <NoteType event={data} type="client" />;
  }

  return (
    <div className={styles.clientEvent}>
      <div className={styles.clientEvent_item} style={{ marginBottom: 5 }}>
        <span className={styles.clientEvent_item_text}>{data?.date}</span>
        <span className={styles.clientEvent_item_text}>{data?.text}</span>
        <span className={styles.clientEvent_item_name} onClick={() => goToOneLead(data?.leadDto?.id, data?.leadDto?.name)}>
          {data?.leadDto?.name}
        </span>
      </div>
    </div>
  );
};

export default ClientEvent;
