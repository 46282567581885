import { useCrmEmployee } from '@queries/queries';
import { Select } from 'antd';
import React from 'react';

import styles from './changeEmployee.module.scss';
import { useQueryParam } from '@hooks/useQueryParams';
import { useTranslation } from 'react-i18next';
import { ActivityLogs } from '../filtrDrawer/FiltrDrawer';

const { Option } = Select;

const ChangeEmployee = () => {
  const { t } = useTranslation();
  const { data } = useCrmEmployee();
  const { searchParams, appendSingle } = useQueryParam<ActivityLogs, any>();
  const handleChangeInput = (key: string, value: string) => {
    appendSingle(key, value);
  };
  return (
    <div className={styles.container}>
      <Select
        placeholder={t('home.Oldingi_qiymat')}
        value={searchParams?.previous && Number(searchParams?.previous)}
        onChange={e => {
          handleChangeInput('previous', String(e));
        }}
      >
        {data?.map(item => (
          <Option key={item?.id} value={item?.id}>
            {item?.fullName}
          </Option>
        ))}
      </Select>
      <Select
        placeholder={t('home.Keyingi_qiymat')}
        value={searchParams?.current && Number(searchParams?.current)}
        onChange={e => {
          handleChangeInput('current', String(e));
        }}
      >
        {data?.map(item => (
          <Option key={item?.id} value={item?.id}>
            {item?.fullName}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default ChangeEmployee;
