import React, { useState } from 'react';
import styles from './payments.module.scss';
import { Button } from 'antd';
import CreatePaymentModal from './CreatePaymentModal';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import PaymentInfoCard from '../../../../assets/icons/PaymentInfoCard';

interface Props {
  sumPayment: number;
}

const CardAndButton: React.FC<Props> = ({ sumPayment }) => {
  const [addPayment, setAddPayment] = useState(false);

  const { currency } = useAppSelector(state => state.changesReducer);

  const { t } = useTranslation();

  const handleAppPayment = (open: boolean) => {
    setAddPayment(open);
  };
  return (
    <>
      <div className={styles.cards_cont}>
        <div className={`d-flex align-center ${styles.cards_cont_card}`}>
          <div className={styles.cards_cont_card_sum_chash}>
            <PaymentInfoCard />
          </div>
          <div>
            <p className={styles.cards_cont_card_title}>{t('changes.Umumiy_tolangan_summa')}</p>
            <p className={styles.cards_cont_card_amount}>
              {sumPayment.toLocaleString('ru')} {currency?.ccy}
            </p>
          </div>
        </div>
        <Button onClick={() => handleAppPayment(true)} className={styles.buttons_item} type="primary" ghost>
          {t('changes.Tolov_kiritish')}
        </Button>
      </div>
      <CreatePaymentModal visible={addPayment} onClose={handleAppPayment} />
    </>
  );
};

export default CardAndButton;
