import { companyEmployeeRolesPaths } from './companyEmployeeRolesPath';
import CompanyEmployeeRolesContainer from '../../components/companyEmployeeRoles/CompanyEmployeeRolesContainer';

export const companyEmployeeRolesRoutes = [
  {
    component: CompanyEmployeeRolesContainer,
    path: companyEmployeeRolesPaths.id + companyEmployeeRolesPaths.name,
    exact: false
  }
];
