import JumpNextIcon from '@pages/bossCrm/components/conversion/assets/icons/JumpNextIcon';
import JumpPrevIcon from '@pages/bossCrm/components/conversion/assets/icons/JumpPrevIcon';
import { ConversionContext } from '@pages/bossCrm/hooks/ConversionContext';
import { useGetSuccesfullLeads, useGetUnsuccessfullLeads } from '@pages/bossCrm/services/mutate';
import { SuccessfullTableData } from '@pages/bossCrm/utils/models/SuccessfullTableData';
import { UnsuccessfullLeadsTableData } from '@pages/bossCrm/utils/models/UnsuccessfullLeadsTableData';
import { Pagination } from 'antd';
import React, { useContext } from 'react';
import styles from './tablePagination.module.scss';
type Props = {
  current?: number;
  total?: number;
  pageSize?: number;
};
const TablePagination: React.FC<Props> = ({ current, total, pageSize }) => {
  const { setContext, startDate, endDate, name, tjmIds, buildingIds, customFieldIds, secondTablePage, page } = useContext(ConversionContext);
  const firstTableMutation = useGetUnsuccessfullLeads();
  const secondTableMutation = useGetSuccesfullLeads();
  return (
    <div className={styles.pagination_container}>
      <Pagination
        current={current}
        className={styles.pagination}
        total={total}
        pageSize={pageSize}
        onChange={(currentPage: number) => {
          setContext({
            page: name === 'first' ? currentPage : page,
            secondTablePage: name === 'second' ? currentPage : secondTablePage,
            isTableDataLoading: true
          });
          if (name === 'first') {
            firstTableMutation
              ?.mutateAsync({
                page: currentPage,
                startDate: startDate!,
                finishDate: endDate!,
                size: 10
              })
              .then((data: UnsuccessfullLeadsTableData) => {
                setContext({ firstTableData: data, isTableDataLoading: false });
              });
          } else {
            secondTableMutation
              ?.mutateAsync({
                page: currentPage!,
                size: 10,
                startDate: startDate!,
                finishDate: endDate!,
                houses: tjmIds!?.length > 0 ? tjmIds! : [],
                buildings: buildingIds!?.length > 0 ? buildingIds! : [],
                customFieldId: customFieldIds && customFieldIds !== 'tag' ? (customFieldIds as number) : null,
                tag: customFieldIds === 'tag' ? true : null
              })
              .then((data: SuccessfullTableData) => {
                setContext({ secondTableData: data });
              });
          }
        }}
        nextIcon={<JumpNextIcon />}
        prevIcon={<JumpPrevIcon />}
      />
    </div>
  );
};

export default TablePagination;
