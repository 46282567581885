/* eslint-disable arrow-body-style */
import React from 'react';

const RoboSettingIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.25 6.07292V9.91958C2.25 11.3329 2.25 11.3329 3.58333 12.2329L7.25 14.3529C7.80333 14.6729 8.70333 14.6729 9.25 14.3529L12.9167 12.2329C14.25 11.3329 14.25 11.3329 14.25 9.92625V6.07292C14.25 4.66625 14.25 4.66625 12.9167 3.76625L9.25 1.64625C8.70333 1.32625 7.80333 1.32625 7.25 1.64625L3.58333 3.76625C2.25 4.66625 2.25 4.66625 2.25 6.07292Z"
        stroke="#464255"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 10C9.35457 10 10.25 9.10457 10.25 8C10.25 6.89543 9.35457 6 8.25 6C7.14543 6 6.25 6.89543 6.25 8C6.25 9.10457 7.14543 10 8.25 10Z"
        stroke="#464255"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RoboSettingIcon;
