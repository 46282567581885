import React, { useRef } from 'react';
import styles from './tableCost.module.scss';
import { Spin, Table } from 'antd';
import TrushIcon from '../../../../assets/icons/crmIcons/TrushIcon';
import { PlanCostTableItem } from '../../../../utils/models/PlanFactLeadCost';
import { useDeletePlanFactCost } from '../../../../queries/mutation';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { useTranslation } from 'react-i18next';

interface Props {
  data: PlanCostTableItem[];
  page: number;
}

const TableCost: React.FC<Props> = ({ data, page }) => {
  const idRef = useRef<number>();

  const { t } = useTranslation();

  const { startDate, finishData, costTypeLead } = useAppSelector(state => state.crmStatistic);

  const deleteCost = useDeletePlanFactCost(page, startDate, finishData, costTypeLead);

  const initialColumn = [
    {
      title: t('boss_crm.Xarajat_nomi'),
      align: 'left',
      dataIndex: 'name'
    },
    {
      title: t('boss_crm.Tolov_miqdori'),
      dataIndex: 'amount',
      align: 'left',
      render: (amount: number, record: PlanCostTableItem) => (
        <>
          {amount.toLocaleString('ru')} {record?.currency}
        </>
      )
    },
    {
      title: t('boss_crm.Amal_qilish_muddati'),
      align: 'left',
      render: (record: PlanCostTableItem) => (
        <>
          {record.startDate}-{record.finishDate}
        </>
      ),
      width: '45%'
    },
    {
      title: '',
      width: '10%',
      dataIndex: 'id',
      render: (id: number) => (
        <Spin spinning={deleteCost.isLoading && id === idRef.current}>
          <span onClick={() => handleDeleteCost(id)} className={'delete'}>
            <TrushIcon />
          </span>
        </Spin>
      )
    }
  ];

  // handleDeleteCost
  const handleDeleteCost = (id: number) => {
    idRef.current = id;
    deleteCost.mutate(id);
  };

  return (
    <div className={styles.container}>
      <Table columns={initialColumn as any} dataSource={data} rowKey={'id'} pagination={false} />
    </div>
  );
};

export default TableCost;
