import React from 'react';
import MainHeader from '../../components/headers/MainHeader';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import styles from './monetary.module.scss';
import MonetaryLogin from './monetary-login/MonetaryLogin';
import MonetarySetting from './monetary-setting/MonetarySetting';
import { useMonetaryConfig } from '../../queries/queries';

const Monetary = () => {
  const { data } = useMonetaryConfig();

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
      </MainHeader>
      <div className={styles.container}>
        <MonetaryLogin data={data} />
        <MonetarySetting data={data} />
      </div>
    </>
  );
};

export default Monetary;
