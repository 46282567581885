import React from 'react';

const DiamondsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.29 2.15002H7.70002C6.00002 2.15002 5.25001 3.00002 4.79001 4.04002L2.23001 9.80002C1.77001 10.84 2.02001 12.39 2.79001 13.23L9.65001 20.77C10.95 22.19 13.07 22.19 14.36 20.77L21.21 13.22C21.98 12.37 22.23 10.83 21.76 9.79002L19.2 4.03002C18.74 3.00002 17.99 2.15002 16.29 2.15002Z"
        stroke="#00A389"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3.5 8H20.5" stroke="#00A389" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default DiamondsIcon;
