import { Button, Form, Input, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import TrushSquareIcon from '../../assets/icons/TrushSquareIcon';
import styles from './settingPlayMobile.module.scss';
import playMobaileImage from '../../assets/img/PlayMobaile.png';
import eskiz from '../../assets/img/eskiz.png';
import LinearEditIcon from '../../assets/icons/LinearEditIcon';
import SettingPlayMobileModal from '../../components/settingPlayMobile/SettingPlayMobileModal';
import { usePlayMobileConf } from '../../queries/queries';
import { usePlayMobileConfDelete, usePlayMobileConfMutation } from '../../queries/mutation';
import { OriginatorHouse } from '../../utils/models/PlayMobile';
import SettingPlayMobileDeleteOrigination from '../../components/settingPlayMobile/SettingPlayMobileDeleteOrigination';
import TrashIcon from '../../assets/icons/TrashIcon';
import { useTranslation } from 'react-i18next';

const SettingPlayMobile = () => {
  const smsConfImages = {
    PLAY_MOBILE: playMobaileImage,
    ESKIZ: eskiz
  };
  const [form] = Form.useForm();
  const { data: playMobiles, isLoading, isFetching } = usePlayMobileConf();
  const [playMobileVisible, setPlayMobileVisible] = useState({
    type: '',
    visible: false
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [playMobileEdit, setPlayMobileEdit] = useState<OriginatorHouse | any>();
  const { t } = useTranslation();
  const playMobileConfigMutation = usePlayMobileConfMutation();
  const playMobileConfigDelete = usePlayMobileConfDelete();

  useEffect(() => {
    if (!!playMobiles) {
      setPlayMobileVisible({ type: playMobiles.type, visible: true });
      form.setFieldsValue({
        username: playMobiles.username,
        password: playMobiles.password
      });
    }
  }, [playMobiles, isFetching, form]);

  useEffect(() => {
    setPlayMobileVisible(prev => ({ ...prev, visible: false }));
    setVisibleDelete(false);
    form.resetFields();
  }, [playMobileConfigDelete.isSuccess, form]);

  const onFinish = (values: any) => {
    playMobileConfigMutation.mutate({
      username: values.username,
      password: values.password,
      type: playMobileVisible.type
    });
  };

  // handleDelete
  const handleDelete = () => {
    if (!!playMobiles) {
      setVisibleDelete(true);
    } else {
      setPlayMobileVisible(prev => ({ ...prev, visible: false }));
    }
  };

  return (
    <div className={styles.settingPlayMobile}>
      <div className={styles.settingPlayMobileHeader}>
        <h1>{t('widgets.Xabarnoma_tizimini_tanlang')}</h1>
        {playMobileVisible.visible && (
          <Button shape="circle" onClick={handleDelete}>
            <TrushSquareIcon />
          </Button>
        )}
      </div>
      <Spin spinning={isLoading || isFetching}>
        {!playMobileVisible.visible ? (
          <div className={styles.container_createSection}>
            <div className={styles.settingPlayMobileNew} onClick={() => setPlayMobileVisible({ type: 'PLAY_MOBILE', visible: true })}>
              <img src={playMobaileImage} alt="" />
            </div>
            <div className={styles.settingPlayMobileNew} onClick={() => setPlayMobileVisible({ type: 'ESKIZ', visible: true })}>
              <img src={eskiz} alt="" />
            </div>
          </div>
        ) : (
          <div className={styles.settingPlayMobileBody}>
            <div className={styles.settingPlayMobileBodyLeft}>
              <img src={smsConfImages[playMobileVisible.type as keyof typeof smsConfImages]} alt="PlayMobile" />
              <Form layout="vertical" className={'settingPlayMobileForm'} onFinish={onFinish} form={form}>
                <Form.Item
                  label={t('integration.Login')}
                  name={'username'}
                  rules={[
                    {
                      required: true,
                      message: t('setting_play_mobile.Maydonni_toldiring')
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('login.Parol')}
                  name={'password'}
                  rules={[
                    {
                      required: true,
                      message: t('setting_play_mobile.Maydonni_toldiring')
                    }
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: '100%',
                    borderRadius: '50px',
                    height: 38,
                    marginTop: 20
                  }}
                  loading={playMobileConfigMutation.isLoading}
                >
                  {t('home.Saqlash')}
                </Button>
              </Form>
            </div>
            <div className={styles.settingPlayMobileBodyRight}>
              <div className={styles.settingPlayMobileBodyRightHeader}>
                <p>{t('setting_play_mobile.Originators')}</p>
                {playMobiles?.originators.length > 0 && (
                  <Button
                    onClick={() => {
                      setPlayMobileEdit(null);
                      setModalVisible(true);
                    }}
                  >
                    + {t('house_config.Yangi_yaratish')}
                  </Button>
                )}
              </div>
              <div className={styles.settingPlayMobileBodyRightContent}>
                {playMobiles?.originators?.length === 0 ? (
                  <Button
                    style={{
                      height: 38,
                      borderRadius: 50,
                      width: 209,
                      margin: '100px auto',
                      color: '#00A389',
                      fontSize: 16,
                      fontWeight: '500',
                      border: '1px solid #00A389'
                    }}
                    onClick={() => {
                      setPlayMobileEdit(null);
                      setModalVisible(true);
                    }}
                  >
                    + {t('house_config.Yangi_yaratish')}
                  </Button>
                ) : (
                  <>
                    {playMobiles?.originators.map((item: OriginatorHouse) => (
                      <div key={item.id}>
                        <h1>
                          {item.name.slice(0, 20)} {item.name.length > 20 ? '...' : null}
                        </h1>
                        <div>
                          <span
                            onClick={() => {
                              setPlayMobileEdit(item);
                              setModalVisible(true);
                            }}
                          >
                            <LinearEditIcon /> {t('client.Tahrirlash')}
                          </span>
                          <SettingPlayMobileDeleteOrigination id={item.id} />
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </Spin>
      <SettingPlayMobileModal visible={modalVisible} setVisible={setModalVisible} edit={playMobileEdit} />
      <Modal
        visible={visibleDelete}
        closable={false}
        title={false}
        footer={false}
        onCancel={() => setVisibleDelete(false)}
        centered
        width={420}
        wrapClassName="settingPlayMobileDeleteModal"
      >
        <span>
          <TrashIcon />
        </span>
        <p>{t('discount_setting.Ochirmoqchimisiz')}</p>
        <div>
          <Button onClick={() => setVisibleDelete(false)}>{t('home.Yopish')}</Button>
          <Button
            type="primary"
            onClick={() => {
              playMobileConfigDelete.mutate();
            }}
          >
            {t('discount_setting.Ochirish')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default SettingPlayMobile;
