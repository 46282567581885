/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable prefer-const */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { JuridicalClientForm } from '@components/contractCreateClient/juridical/ClientMainData';
import ClientMainDataJuridical from '@components/contractCreateClient/juridical/ClientMainDataJuridical';
import ListFindClients from '@components/contractCreateClient/ListFindClients';
import ClientMainData from '@components/contractCreateClient/natural/ClientMainData';
import { FormMainData } from '@components/contractCreateClient/natural/Natural';
import SearchClientWithPassportData from '@components/contractCreateClient/natural/SearchClientWithPassportData';
import InternationalPhoneNumber from '@components/international-phone-input/InternationalPhoneNumber';
import DeleteIconV2 from '@icons/areaarage/DeleteIconV2';
import FileIcon from '@icons/FileIcon';
import UploadFileIcon from '@icons/UploadFileIcon';
import ClientLead from '@pages/changes/components/client/ClientLead';
import { useGetForceData } from '@pages/crm-force/services/queries';
import { useGetOneClient } from '@pages/oneLid/services/queries';
import CreateOrderClientsList from '@pages/order/components/create-order-clients-list/CreateOrderClientsList';
import { addClientPhone, checkClientPhone, clearClientReducer, deleteClientPhones, setNaturalPersonImage } from '@reducers/ClientSlice';
import { Permissions } from '@utils/constants/permissions';
import { ClientPhone } from '@utils/models/Client';
import { ClientLeadModel } from '@utils/models/ClientLeadModel';
import { FileObject } from '@utils/models/File';
import { Button, Col, Form, Input, Layout, Popover, Radio, Row, Select, Space, Switch, Tabs, Tooltip } from 'antd';
import { isValidNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';

import CardAddIcon from '../../assets/icons/contract/CardAddIcon';
import CloseIcon from '../../assets/icons/contract/CloseIcon';
import TabCaseIcon from '../../assets/icons/TabCaseIcon';
import TabUserIcon from '../../assets/icons/TabUserIcon';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useCreateOrder, useLeadSave, useUploadFile } from '../../queries/mutation';
import { useBookingClientSearch, useCompanyInformationForPdf, useGetDistrict, usePaymentConfig, useRegions } from '../../queries/queries';
import { helper } from '../../utils/helper/helper';
import { urlEncode } from '../../utils/helper/urlEncodeDecode';
import { ClientType } from '../../utils/models/ClientType';
import { CreateOrderSearchClients } from '../../utils/models/CreateOrder';
import AfterCreateOrderModal from '../order/components/AfterCreateOrderModal';

import ConnectLeadModal from './components/connect-lead/ConnectLeadModal';
import CreateLeadModal from './components/create-lead/CreateLeadModal';

import img from '../../assets/img/addLead.png';

import styles from './createOrder.module.scss';

const { Content } = Layout;

export interface FormAddress {
  passportSerial: string;
  passportNumber: string;
  dateBirth: string;
  passport: string;
}

interface FileNameProps {
  originalName: string;
}

interface IForm {
  phone: string;
  firstname: string;
  lastname: string;
  fatherName?: string;
  name: string;
  prePayment: string;
  delay: number;
  note: string;
  currencyId: number;
  refundablePayment?: true;
  type?: string;
  phone1: string;
}

const { TabPane } = Tabs;

const CreateOrder = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const permissions = useAppSelector(state => state.userReducer?.user?.role?.permissions);
  const params = useParams();
  const { dealPrice } = useAppSelector(state => state.createContract);
  const { data: paymentTypes } = usePaymentConfig();
  const { data: CompanyInformationForPdf } = useCompanyInformationForPdf([String(params?.idFlat)]);

  const isAccessToMakePayment = permissions?.includes(Permissions.PERMISSION_BOOKING_PAYMENT);
  const [clientId, setClientId] = useState<number>(0);
  const { data: oneClient } = useGetOneClient(clientId);
  const { data: regions, isLoading: loadingRegions } = useRegions();
  const { data: force } = useGetForceData();
  const [formPassport] = Form.useForm<FormAddress>();
  const [formMain] = Form.useForm<FormMainData>();
  const [formJuridical] = Form.useForm<JuridicalClientForm>();
  const uploadFile = useUploadFile(getFile);
  const [openLeadModal, setOpenLeadModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  function getFile(data: FileObject[]) {
    dispatch(
      setNaturalPersonImage({
        id: data[0].id,
        type: typeImage.current
      })
    );
  }
  const typeImage = useRef<'first' | 'second'>('first');
  const [typeAddress, setTypeAddress] = useState<{
    id: number;
    type: 'district' | 'region';
  }>({
    id: -1,
    type: 'region'
  });

  const { data: districts, isLoading: districtsLoading } = useGetDistrict(typeAddress.id, typeAddress.type);

  const handleOpenLeadModal = () => {
    setOpenLeadModal(true);
  };

  const handleOpenCreateModal = () => {
    setOpenLeadModal(false);
    setOpenCreateModal(true);
  };

  const { clientPhones, naturalPerson: clientData, passportOneId, passportTwoId } = useAppSelector(state => state.clientReducer);

  const companyId = CompanyInformationForPdf && CompanyInformationForPdf.length ? CompanyInformationForPdf[0]?.companyId : 0;
  const { searchParams } = useQueryParam<{ currency: string }, null>();
  const [type, setType] = useState<ClientType>(ClientType.NATURAL);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [searchPhone, setSearchPhone] = useState('');
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [createPaymentOpen, setCreatePaymentOpen] = useState(false);
  const [moreInfoVisible, setMoreInfoVisible] = useState(false);
  const [selectLead, setSelectLead] = useState<ClientLeadModel | undefined>();
  const [countSearchClient, setCountSearchClient] = useState(0);
  const timer = useRef<NodeJS.Timeout>();
  const selectedClientId = useRef(-1);
  const [form] = Form.useForm<IForm>();
  const updateNewLead = useLeadSave();
  const { leadContact, leadName, leadContactName } = useAppSelector(state => state.clientReducer);

  const createOrder = useCreateOrder(type, setOpenSuccessModal);

  const { isLoading, data, fetchNextPage, isFetching } = useBookingClientSearch(
    `?type=${type === ClientType.JURIDICAL ? 'JURIDICAL' : 'NATURAL'}&phone=${urlEncode(searchPhone)}&companyId=${companyId}`,
    Number(searchPhone)
  );
  const { currencyCompany } = useAppSelector(state => state.createContract);
  const currentClientLead = oneClient?.lead ? oneClient?.lead : selectLead;

  const isCliendLead = oneClient?.lead ? true : false;

  const selectedPaymentType = Form.useWatch('type', form);

  useEffect(() => {
    dispatch(clearClientReducer());
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      delay: 5
    });
  }, [form]);

  useEffect(() => {
    if (searchParams.currency) {
      form.setFieldsValue({
        currencyId: Number(searchParams.currency)
      });
    }
  }, [searchParams]);

  const handlePopover = (isOpen: boolean) => {
    setPopoverOpen(isOpen);
  };

  const changeType = (key: any) => {
    form.setFieldsValue({
      phone: '',
      firstname: '',
      lastname: '',
      fatherName: '',
      name: '',
      prePayment: '',
      delay: 5,
      note: '',
      currencyId: undefined
    });
    setSelectLead(undefined);
    selectedClientId.current = -1;
    setType(key);
  };

  const contactsCopy = (contacts?: any[]) => {
    let arr = [...(contacts || [])];

    arr[0].name = leadContactName;
    arr[0].phones = [leadContact];

    return arr;
  };

  const onFinish = async (values: IForm) => {
    Promise.all([formMain.validateFields(), formPassport.validateFields(), formJuridical.validateFields()]).then(formValues => {
      const clientId = selectedClientId.current !== -1 ? selectedClientId.current : undefined;

      const newPhones: ClientPhone[] = clientPhones.map((phone, index) => {
        const phoneKey = `phone${index + 1}` as keyof IForm;
        const codeKey = `code${index + 1}` as keyof FormMainData;

        const phoneValue: string = formValues[0][phoneKey as keyof FormMainData];

        const formattedPhone: string = phoneValue ? `${phoneValue}` : values[phoneKey]?.toString() || '';

        const codeValue: string = form.getFieldValue(codeKey) || formValues[0][codeKey as keyof FormMainData];

        return {
          phone: formattedPhone,
          active: phone.active,
          code: codeValue
        };
      });

      if (type === ClientType.NATURAL) {
        const fileIds = files?.map(file => file?.id);

        let createOrderData = {
          phones: newPhones,
          prePayment: values.prePayment ? helper.parseFloatStringToNumber(values.prePayment) : null,
          delay: values.delay,
          firstname: values.firstname || formValues[0]?.firstname,
          lastname: values.lastname || formValues[0]?.lastname,
          fatherName: values.fatherName || formValues[0]?.fatherName,
          clientId,
          flatId: Number(params.idFlat),
          note: values?.note,
          currencyId: values.currencyId,
          companyId,
          refundablePayment: values.refundablePayment,
          type: values.type,
          dateBirth: formValues[1].dateBirth,
          workPlace: formValues[0].workPlace,
          passportPlace: formValues[0].passportPlace,
          passportDate: formValues[0].passportDate,
          passportExpiredDate: formValues[0].passportExpiredDate,
          districtId: formValues[0].districtId,
          cityID: formValues[0].cityID,
          countryCode: formValues[0].countryCode,
          address: formValues[0].address,
          passportSerial: formValues[1].passportSerial,
          passportNumber: formValues[1].passportNumber,
          id: clientData?.id,
          passportImg1Id: passportOneId,
          passportImg2Id: passportTwoId,
          inn: formValues[0]?.inn,
          pinfl: formValues[0]?.pinfl,
          ...(dealPrice && { dealPrice }),
          files: fileIds?.length === 0 ? null : fileIds
          // leadId: selectLead ? selectLead?.id : currentClientLead?.id
        };

        if (selectedPaymentType !== 'OTHERS') {
          createOrderData = { ...createOrderData, files: null };
        }

        createOrder.mutateAsync(createOrderData).then(() => {
          const updateLidData = {
            id: currentClientLead?.id,
            contacts: contactsCopy(currentClientLead?.contacts),
            balance: currentClientLead?.balance,
            responsibleById: currentClientLead?.responsibleById,
            pipeStatusId: currentClientLead?.pipeStatusResponse?.id,
            tagIds: currentClientLead?.tags?.map(item => item?.id),
            fields: currentClientLead?.fields,
            name: leadName,
            autoClosedLeadTask: false
          };

          setSelectLead(undefined);
          updateNewLead.mutateAsync(updateLidData as any);
        });
      } else {
        const fileIds = files?.map(file => file?.id);

        let creareOrderDataJuridical = {
          phones: newPhones,
          prePayment: values.prePayment ? helper.parseFloatStringToNumber(values.prePayment) : null,
          delay: values.delay,
          name: values.name || formValues[2]?.name?.trim(),
          clientId,
          flatId: Number(params.idFlat),
          note: values.note,
          currencyId: values.currencyId,
          companyId,
          refundablePayment: values.refundablePayment,
          type: values.type,
          districtId: formValues[2]?.districtId,
          cityID: formValues[2]?.cityID,
          countryCode: formValues[2]?.countryCode,
          address: formValues[2]?.address,
          mfo: formValues[2]?.mfo?.trim(),
          bankName: formValues[2]?.bankName?.trim(),
          accountNumber: formValues[2]?.accountNumber?.trim(),
          director: formValues[2]?.director?.trim(),
          inn: formValues[2]?.inn?.trim(),
          oked: formValues[2]?.oked?.trim(),
          ...(dealPrice && { dealPrice }),
          files: fileIds?.length === 0 ? null : fileIds
        };

        if (selectedPaymentType !== 'OTHERS') {
          creareOrderDataJuridical = { ...creareOrderDataJuridical, files: null };
        }

        createOrder.mutateAsync(creareOrderDataJuridical).then(() => {
          const updateLidData = {
            id: currentClientLead?.id,
            contacts: contactsCopy(currentClientLead?.contacts),
            balance: currentClientLead?.balance,
            responsibleById: currentClientLead?.responsibleById,
            pipeStatusId: currentClientLead?.pipeStatusResponse?.id,
            tagIds: currentClientLead?.tags?.map(item => item?.id),
            fields: currentClientLead?.fields,
            name: leadName,
            autoClosedLeadTask: false
          };

          setSelectLead(undefined);
          updateNewLead.mutateAsync(updateLidData as any);
        });
      }
    });
  };

  const handleCheckedPhone = (index: number) => {
    dispatch(checkClientPhone(index));
  };

  const handleAddClientPhone = () => {
    dispatch(addClientPhone());
  };

  const handleRemoveClientPhone = (index: number) => {
    for (let i = 0; i < 5; i++) {
      if (index === i) {
        form.setFieldsValue({
          [`phone${i}`]: ''
        });
      } else if (i > index) {
        form.setFieldsValue({
          [`phone${i - 1}`]: form.getFieldValue(`phone${i}`)
        });
      }
    }
    form.setFieldsValue({
      [`phone4` as any]: ''
    });
    dispatch(deleteClientPhones(index));
  };

  const changeRegion = (value: number) => {
    formMain.setFieldsValue({
      districtId: undefined
    });
    setTypeAddress({
      type: 'region',
      id: value
    });
  };

  const handlePassportImage = (e: any, type: 'first' | 'second') => {
    if (e.target?.files[0] && e.target.files[0].size <= 10000000) {
      const file = e.target.files[0];
      const formData = new FormData();

      formData.append('files', file);
      typeImage.current = type;
      uploadFile.mutate(formData);
    }
  };

  useEffect(() => {
    if (clientData?.id || clientData?.passportNumber) {
      formMain.setFieldsValue({
        lastname: clientData?.lastname,
        firstname: clientData?.firstname,
        fatherName: clientData?.fatherName,
        passportPlace: clientData?.passportPlace,
        passportDate: clientData?.passportDate,
        passportExpiredDate: clientData?.passportExpiredDate,
        workPlace: clientData?.workPlace,
        districtId: clientData?.districtId,
        address: clientData?.address,
        cityID: clientData?.cityID,
        countryCode: clientData?.districtId && clientData?.cityID ? 'uz' : clientData?.countryCode,
        inn: clientData?.inn,
        pinfl: clientData?.pinfl
      });
      formPassport.setFieldsValue({
        passportSerial: clientData?.passportSerial,
        passportNumber: clientData?.passportNumber,
        dateBirth: clientData?.dateBirth,
        passport: clientData?.passportSerial
      });
      for (let i = 0; i < clientData.phones.length; i++) {
        formMain.setFieldsValue({
          [`phone${i + 1}`]: clientData.phones[i].phone
        });
      }
      if (clientData.districtId) {
        setTypeAddress({
          id: clientData.districtId,
          type: 'district'
        });
      }
    } else {
      formMain.setFieldsValue({
        phone1: '',
        phone2: '',
        phone3: '',
        phone4: '',
        phone5: '',
        districtId: undefined,
        address: undefined,
        cityID: undefined,
        countryCode: 'uz',
        firstname: undefined,
        lastname: undefined,
        fatherName: undefined,
        passportPlace: undefined,
        passportDate: '',
        passportExpiredDate: '',
        workPlace: undefined,
        inn: '',
        pinfl: ''
      });
      formPassport.setFieldsValue({
        dateBirth: '',
        passport: undefined,
        passportNumber: '',
        passportSerial: ''
      });
    }
  }, [clientData, formMain, formPassport]);

  const selectClient = (client: CreateOrderSearchClients) => {
    setClientId(client?.id!);

    const phoneValue = client.phone;
    const formattedPhone = phoneValue ? `${phoneValue}` : '';

    const newPhones = [
      {
        phone: formattedPhone,
        active: true,
        code: ''
      }
    ];

    if (type === ClientType.NATURAL) {
      form.setFieldsValue({
        firstname: client.firstname,
        lastname: client.lastname,
        fatherName: client.fatherName,
        phone1: newPhones[0].phone
      });
    } else {
      form.setFieldsValue({
        name: client.name,
        phone1: newPhones[0].phone
      });
    }

    selectedClientId.current = client?.id || -1;
    handlePopover(false);
  };

  const searchClient = (e: any) => {
    const number = helper.deleteSpace(e.target.value);

    selectedClientId.current = -1;
    if (number && number.length >= 5) {
      setPopoverOpen(true);
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        setSearchPhone(number);
      }, 300);
    }
  };

  const getCountFindClients = (count: number) => {
    setCountSearchClient(count);
  };

  // const showFindClients = () => countSearchClient > 0;
  const showFindClients = () => moreInfoVisible && countSearchClient > 0;

  const uploadFileV2 = useUploadFile(getFileV2);
  const [files, setFiles] = useState<FileObject[]>();

  function getFileV2(data: FileObject[]) {
    if (files) {
      setFiles([...files, ...data]);
    } else {
      setFiles(data);
    }
  }

  const changeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0] && e.target.files[0]?.size <= 19000000) {
        const file = e.target.files[0];
        const formData = new FormData();

        formData.append('files', file);
        uploadFileV2.mutate(formData);
        e.target.value = '';
      }
    }
  };

  const deleteFile = (id: number) => {
    setFiles(files?.filter(item => item?.id !== id));
  };

  const toKilobytes = (bytes: number) => (bytes / 1024).toFixed(2);

  useEffect(() => {
    const documentPaste = (e: ClipboardEvent) => {
      let clipDataArr: any = e.clipboardData?.items;
      let items: any[] = [...clipDataArr];

      items.forEach(item => {
        if (item.kind === 'file') {
          let blob = item.getAsFile();
          let reader = new FileReader();

          reader.onload = function (event) {
            const file = blob;
            const formData = new FormData();

            formData.append('files', file);
            uploadFile.mutate(formData);
          }; // data url!
          reader.readAsDataURL(blob);
        }
      });
    };

    document.addEventListener('paste', documentPaste);
    return () => {
      document.removeEventListener('paste', documentPaste);
    };
  }, [uploadFile]);

  const FileName: React.FC<FileNameProps> = ({ originalName }) => {
    const truncateText = (text: string, maxLength: number) => {
      if (text.length > maxLength) {
        return `${text.slice(0, maxLength - 1)  }...`;
      }
      return text;
    };

    const getTruncatedFileName = (fileName: string, maxLength: number) => {
      const extensionIndex = fileName.lastIndexOf('.');
      const name = fileName.substring(0, extensionIndex);
      const extension = fileName.substring(extensionIndex);

      if (name.length > maxLength) {
        return `${truncateText(name, maxLength)  }(${extension})`;
      }
      return fileName;
    };

    const truncatedFileName = getTruncatedFileName(originalName, 66);

    return (
      <Tooltip title={originalName} overlayInnerStyle={{ borderRadius: '6px', border: '1px solid black' }}>
        <p className="text-[16px] font-medium text-[#344054]">{truncatedFileName}</p>
      </Tooltip>
    );
  };

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
        className={styles.container}
      >
        <Row gutter={[16, 0]}>
          <Col span={showFindClients() ? 17 : 24}>
            <div className={styles.createOrder}>
              <div className="d-flex align-center justify-space-between">
                <Tabs defaultActiveKey="1" onChange={changeType} className="OrderTab">
                  <TabPane
                    tab={
                      <span>
                        <TabUserIcon />
                        {t('createOrder.Jismoniy_shaxs')}
                      </span>
                    }
                    key={ClientType.NATURAL}
                  />
                  <TabPane
                    tab={
                      <span>
                        <TabCaseIcon />
                        {t('createOrder.Yuridik_shaxs')}
                      </span>
                    }
                    key={ClientType.JURIDICAL}
                  />
                </Tabs>
              </div>
              <div className={styles.moreInfo}>
                <Switch
                  onChange={chechked => {
                    selectedClientId.current = -1;
                    form.resetFields();
                    setMoreInfoVisible(chechked);
                  }}
                />
                {t('home.Koproq_malumotlar')}
              </div>
              {moreInfoVisible &&
                (type === ClientType.NATURAL ? (
                  <>
                    <div className={styles.passportInfo}>
                      <SearchClientWithPassportData form={formPassport} />
                      <ClientMainData
                        loadingRegions={loadingRegions}
                        handleAddClientPhone={handleAddClientPhone}
                        changeRegion={changeRegion}
                        districts={districts}
                        districtsLoading={districtsLoading}
                        handleCheckedPhone={handleCheckedPhone}
                        handlePassportImage={handlePassportImage}
                        handleRemoveClientPhone={handleRemoveClientPhone}
                        regions={regions}
                        form={formMain}
                        passportTwoId={passportTwoId}
                        passportOneId={passportOneId}
                        clientData={clientData}
                        clientPhones={clientPhones}
                        moreInfoVisible={moreInfoVisible}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.passportInfo}>
                      <ClientMainDataJuridical
                        form={formJuridical}
                        changeRegion={changeRegion}
                        clientPhones={clientPhones}
                        districts={districts}
                        districtsLoading={districtsLoading}
                        handleCheckedPhone={handleCheckedPhone}
                        handleRemoveClientPhone={handleRemoveClientPhone}
                        loadingRegions={loadingRegions}
                        regions={regions}
                      />
                    </div>
                  </>
                ))}
              <Form form={form} name="basic" initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off" layout="vertical" className={styles.createOrderForm}>
                {!moreInfoVisible && (
                  <>
                    <p>{t(`changes.client.NaturalClient.Telefon_raqam`)}</p>
                    {clientPhones.map((phone, index) => (
                      <Space direction="horizontal" className="d-flex" key={phone?.id}>
                        <div>
                          <Form.Item name={`code${index + 1}`} className="d-none" />
                          <div className="flex flex-col gap-[5px]">
                            <Popover
                              // eslint-disable-next-line max-len
                              content={
                                <CreateOrderClientsList
                                  selectClient={selectClient}
                                  isLoading={isLoading}
                                  isFetching={isFetching}
                                  data={data?.pages?.map(data => data?.data)?.flat(Infinity) as any}
                                  type={type}
                                  fetchNextPage={fetchNextPage}
                                />
                              }
                              overlayClassName="client_search_popover"
                              placement="bottom"
                              trigger="click"
                              visible={popoverOpen}
                              onVisibleChange={handlePopover}
                            >
                              <Form.Item
                                // label={<span>{t(`changes.client.NaturalClient.Telefon_raqam`)}</span>}
                                name={`phone${index + 1}`}
                                rules={[
                                  {
                                    validator: (rule, value) => {
                                      if (!(value && isValidNumber(value))) {
                                        return Promise.reject('');
                                      }
                                      return Promise.resolve();
                                    }
                                  }
                                ]}
                              >
                                <InternationalPhoneNumber
                                  defaultCountry="uz"
                                  className="w-[730px]"
                                  onChange={(e, phone) => {
                                    form.setFieldsValue({ [`code${index + 1}`]: `${phone?.country?.iso2?.toUpperCase()}` });

                                    const fullPhoneNumber = e;

                                    searchClient({ target: { value: fullPhoneNumber } });
                                  }}
                                />
                              </Form.Item>
                            </Popover>
                          </div>
                        </div>
                      </Space>
                    ))}
                  </>
                )}

                {!moreInfoVisible &&
                  (type === ClientType.NATURAL ? (
                    <>
                      <Row gutter={[16, 0]}>
                        <Col span={12}>
                          {' '}
                          <Form.Item
                            label={
                              <label
                                style={{
                                  color: 'var(--philippine-gray)',
                                  fontSize: '14px'
                                }}
                              >
                                {t('client_create_contract.Ism')}
                              </label>
                            }
                          >
                            <Form.Item
                              name="firstname"
                              noStyle
                              rules={[
                                {
                                  required: true,
                                  message: t('createOrder.Ismi_kiritilmagan')
                                }
                              ]}
                            >
                              <Input className="customInput" />
                            </Form.Item>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label={
                              <label
                                style={{
                                  color: 'var(--philippine-gray)',
                                  fontSize: '14px'
                                }}
                              >
                                {t('client_create_contract.Familya')}
                              </label>
                            }
                          >
                            <Form.Item
                              name="lastname"
                              noStyle
                              rules={[
                                {
                                  required: true,
                                  message: t('createOrder.Familiya_kiritilmagan')
                                }
                              ]}
                            >
                              <Input className="customInput" />
                            </Form.Item>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item
                        name="fatherName"
                        label={
                          <label
                            style={{
                              color: 'var(--philippine-gray)',
                              fontSize: '14px'
                            }}
                          >
                            {t('createOrder.Otasini_ismi_majburiy_emas')}
                          </label>
                        }
                      >
                        <Input className="customInput" />
                      </Form.Item>
                    </>
                  ) : (
                    <Form.Item
                      label={
                        <label
                          style={{
                            color: 'var(--philippine-gray)',
                            fontSize: '14px'
                          }}
                        >
                          {t('createOrder.Tashkilot_nomi')}
                        </label>
                      }
                    >
                      <Form.Item
                        name="name"
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: t('createOrder.Nomi_kiritilmagan')
                          }
                        ]}
                      >
                        <Input className="customInput" />
                      </Form.Item>
                    </Form.Item>
                  ))}
                <div className="d-flex align-end">
                  <Form.Item
                    label={
                      <label
                        style={{
                          color: 'var(--philippine-gray)',
                          fontSize: '14px'
                        }}
                      >
                        {t('createOrder.Muddat_kun')}
                      </label>
                    }
                  >
                    <Form.Item noStyle name="delay" rules={[{ required: true, message: '' }]}>
                      <div className="order_delay_buttons">
                        <Radio.Group>
                          <Radio className="timeRadioButton" value={3}>
                            3
                          </Radio>
                          <Radio className="timeRadioButton" value={5}>
                            5
                          </Radio>
                          <Radio className="timeRadioButton" value={7}>
                            7
                          </Radio>
                        </Radio.Group>
                      </div>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item
                      name="delay"
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: t('createOrder.Muddat_kiritilmagan')
                        }
                      ]}
                    >
                      <Input
                        onChange={e => {
                          form.setFieldsValue({
                            delay: Number(helper.deleteNotNumbers(e.target.value, null))
                          });
                        }}
                        className="customInput"
                        style={{ width: 280 }}
                      />
                    </Form.Item>
                  </Form.Item>
                </div>
                <Form.Item>
                  <Form.Item noStyle name="note">
                    <Input.TextArea placeholder={t('flat_condition.Izoh_qoldirish')} autoSize={{ minRows: 1, maxRows: 4 }} className="customInput" />
                  </Form.Item>
                </Form.Item>
                {force?.status && (
                  <>
                    <div className={styles.connectLead}>
                      <span className={styles.connectLead_title}>{t('one_lid.Lead_malumotlari')}</span>
                      {currentClientLead ? (
                        <ClientLead currentClientLead={currentClientLead} setSelectLead={setSelectLead} isCliendLead={isCliendLead} />
                      ) : (
                        <Form.Item name="test" rules={[{ required: true, message: 'test' }]}>
                          <div className={styles.connectLead_body}>
                            <img src={img} alt="err" />
                            <span onClick={handleOpenLeadModal}>{t('one_lid.lid_biriktirish')}</span>
                          </div>
                        </Form.Item>
                      )}
                    </div>
                    <ConnectLeadModal
                      visible={openLeadModal}
                      setVisible={setOpenLeadModal}
                      handleOpenCreateModal={handleOpenCreateModal}
                      clientId={clientId}
                      setSelectLead={setSelectLead}
                    />
                    <CreateLeadModal visible={openCreateModal} setVisible={setOpenCreateModal} setSelectLead={setSelectLead} />
                  </>
                )}
                {createPaymentOpen && (
                  <>
                    <hr color="#E8EAEE" />
                    <h3>{t('createOrder.Tolov_malumotlari')}</h3>
                    <Form.Item
                      label={
                        <label
                          style={{
                            color: 'var(--philippine-gray)',
                            fontSize: '14px'
                          }}
                        >
                          {t('broker.Tolov_summasi')}
                        </label>
                      }
                    >
                      <Form.Item
                        noStyle
                        name="prePayment"
                        rules={[
                          {
                            validator: (rule, value) => {
                              if (!value || value === '0') {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise.reject('');
                              }
                              form.setFieldsValue({
                                prePayment: helper.deleteNotNumbersAndParseFloatNumber(value).string
                              });
                              return Promise.resolve();
                            }
                          },
                          {
                            required: true,
                            message: t('createOrder.Boshlangich_tolov_kiritilmagan')
                          }
                        ]}
                      >
                        <Input className="customInput" />
                      </Form.Item>
                    </Form.Item>
                    <Row gutter={[16, 0]}>
                      <Col span={12}>
                        <Form.Item
                          label={
                            <label
                              style={{
                                color: 'var(--philippine-gray)',
                                fontSize: '14px'
                              }}
                            >
                              {t("create_payment.To'lov_turi")}
                            </label>
                          }
                        >
                          <Form.Item noStyle name="type" rules={[{ required: true, message: '' }]}>
                            <Select className="customSelect">
                              {paymentTypes?.types?.map((item, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Select.Option key={index} value={item}>
                                  {t(`home.${item}`)}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label={
                            <label
                              style={{
                                color: 'var(--philippine-gray)',
                                fontSize: '14px'
                              }}
                            >
                              {t('create_payment.Valyuta')}
                            </label>
                          }
                        >
                          <Form.Item
                            noStyle
                            name="currencyId"
                            rules={[
                              {
                                required: true,
                                message: ''
                              }
                            ]}
                          >
                            <Select
                              // loading={loadingCurrency}
                              className="customSelect"
                            >
                              {currencyCompany?.map((item, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Select.Option value={item.id} key={index}>
                                  {item.ccy}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* <div>
                      <Form.Item
                        label={
                          <label
                            style={{
                              color: 'var(--philippine-gray)',
                              fontSize: '14px'
                            }}
                          >
                            {t('home.Izoh')}
                          </label>
                        }
                        name="note"
                      >
                        <Input.TextArea autoSize={{ maxRows: 5, minRows: 1 }} className="customInput" />
                      </Form.Item>
                    </div> */}

                    {selectedPaymentType === 'OTHERS' && (
                      <div>
                        <label
                          htmlFor="chatFile"
                          className="flex cursor-pointer items-center justify-center rounded-xl border-[1px] border-[#EAECF0] px-[24px] py-[16px]"
                          style={{ border: '1px solid #EAECF0' }}
                        >
                          <div>
                            <label htmlFor="chatFile" className="ml-auto mr-auto flex items-center justify-center">
                              {uploadFile.isLoading && <LoadingOutlined />}
                              <span className="cursor-pointer">
                                <UploadFileIcon />
                              </span>
                              <input type="file" id="chatFile" style={{ display: 'none' }} onChange={e => changeFile(e)} />
                            </label>
                            <p className="ml-auto mr-auto text-[12px] font-semibold text-[#1E90FF]">{t('home.Fayl_yuklash_uchun_bosing')}</p>
                            <div className="mt-1px] *Maksimal fayl hajmi 19MB*">
                              <p className="text-[9px] font-medium text-[#2b2828bd]">*{t('home.maximal_size')} 19MB*</p>
                            </div>
                          </div>
                        </label>

                        <div>
                          {files?.map(item => (
                            <div key={item?.id} className="mt-3 flex items-center justify-between rounded-xl bg-[#F9FAFB] p-[16px]" style={{ border: '1px solid #E5E7EB' }}>
                              <div className="flex items-center gap-3">
                                <span className="flex items-center justify-center rounded-[10px] border-[1px] bg-[#EFF1F5] p-[8px]" style={{ border: '1px solid #E5E7EB' }}>
                                  <FileIcon />
                                </span>
                                <div>
                                  <FileName originalName={item?.originalName} />
                                  {/* <p className='text-[#344054] font-medium text-[16px]'>{item?.originalName}{' '}</p> */}
                                  <p className="text-[16px] font-medium text-[#98A2B3]">{item?.size ? `${toKilobytes(item?.size)} KB` : 'N/A'}</p>
                                </div>
                              </div>
                              <span className="cursor-pointer" onClick={() => deleteFile(item?.id)}>
                                <DeleteIconV2 />
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    <Form.Item>
                      <Form.Item
                        noStyle
                        name="refundablePayment"
                        rules={[
                          {
                            required: true,
                            message: ''
                          }
                        ]}
                        initialValue={false}
                      >
                        <Radio.Group className={styles.refundablePayment}>
                          <Radio value={true}>
                            <h3>{t('createOrder.Tolov_qaytariladi')}</h3>
                            <p>{t('createOrder.Tolov_qaytadi')}</p>
                          </Radio>
                          <Radio value={false}>
                            <h3>{t('createOrder.Tolov_qaytarilmaydi')}</h3>
                            <p>{t('createOrder.Tolov_qaytmaydi')}</p>
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Form.Item>
                  </>
                )}
                {isAccessToMakePayment && (
                  <div className={styles.createPayment} onClick={() => setCreatePaymentOpen(prev => !prev)}>
                    {createPaymentOpen ? <CloseIcon size={20} stroke="#1E91E4" /> : <CardAddIcon color="#1E91E4" />}{' '}
                    {createPaymentOpen ? t('createOrder.Tolov_qilish_yopish') : t('titles.create-payment')}
                  </div>
                )}
                <Form.Item shouldUpdate>
                  {() => (
                    <Button loading={createOrder.isLoading} type="primary" htmlType="submit" className="customFormButton" style={{ float: 'right' }} disabled={false}>
                      {t('createOrder.Buyurtmani_saqlash')}
                    </Button>
                  )}
                </Form.Item>
              </Form>
              <AfterCreateOrderModal visible={openSuccessModal} />
            </div>
          </Col>
          <Col span={showFindClients() ? 7 : 0}>{moreInfoVisible && <ListFindClients setClientId={setClientId} getCount={getCountFindClients} />}</Col>
        </Row>
      </Content>
    </>
  );
};

export default CreateOrder;
