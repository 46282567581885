import React from 'react';
import { Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './myCalls.module.scss';
import CreateMyCall from './CreateMyCall';
import EditMyCall from './EditMyCall';
import { useMyCallsConfig } from '../../../../../queries/queries';

interface Props {
  visible: boolean;
  onCancel: (visible: boolean) => void;
}

const MyCalls: React.FC<Props> = ({ visible, onCancel }) => {
  const { data: myCalls, isLoading, isFetching } = useMyCallsConfig();

  const { t } = useTranslation();

  // handle cancel
  const handleCancel = () => {
    onCancel(false);
  };

  return (
    <Modal className={styles.container} width={530} footer={false} visible={visible} title={'Мои звонки'} onCancel={handleCancel}>
      <Spin spinning={isFetching || isLoading}>
        <div className={styles.container_body}>
          <h3 className={styles.container_body_information}>{t('my_calls.Qongiroqlar_orqali_mijozlarni_toplang_suhbatlarni_saqlang')}</h3>
          {myCalls ? <EditMyCall data={myCalls} /> : <CreateMyCall />}
        </div>
      </Spin>
    </Modal>
  );
};

export default MyCalls;
