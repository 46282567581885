import React from 'react';
import { SuccessfullTableData } from '../utils/models/SuccessfullTableData';
import { UnsuccessfullLeadsTableData } from '../utils/models/UnsuccessfullLeadsTableData';

export interface ContextDataState {
  tjmIds?: Array<number>;
  customFieldIds?: number | string;
  name?: string;
  startDate?: string;
  endDate?: string;
  page?: number;
  firstTableData?: UnsuccessfullLeadsTableData;
  secondTableData?: SuccessfullTableData;
  buildingIds?: Array<number>;
  secondTablePage?: number;
  isTableDataLoading?: boolean;
}

export interface ContextData extends ContextDataState {
  setContext: (value: ContextDataState) => void;
}

export const ConversionContext = React.createContext<ContextData>({
  setContext: () => {},
  tjmIds: [],
  buildingIds: [],
  page: 1,
  secondTablePage: 1
});
