import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import ArrowSquireDown from '@icons/ArrowSquareDown';
import { setChangeDiscountCurrency, setChangeType } from '@reducers/BossCurrencySlice';
import { DATE_FORMAT } from '@utils/constants/format';
import { Segmented } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import styles from './sunburstHeader.module.scss';

export type BossDiscountParam = {
  startDate: string;
  finishDate: string;
  monthStart: string;
  monthFinish: string;
};

const SunburstHeader = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { discountCurrency, type } = useAppSelector(state => state.bossCurrency);
  const { appendSingle } = useQueryParam<BossDiscountParam, any>();
  const [count, setCount] = useState<number>(0);
  const currentYear = dayjs().get('year');
  const startDate = dayjs()?.add(count, 'year')?.startOf('year')?.format(DATE_FORMAT);
  const finishDate = dayjs()?.add(count, 'year')?.endOf('year')?.format(DATE_FORMAT);
  const year = dayjs()?.add(count, 'year')?.startOf('year')?.get('year');

  const handleArrowLeft = () => {
    if (year === 2021) {
      setCount(count => count);
    } else {
      setCount(count => count - 1);
    }
  };
  const handleArrowRight = () => {
    if (currentYear + 1 === year) {
      setCount(count => count);
    } else {
      setCount(count => count + 1);
    }
  };

  useEffect(() => {
    appendSingle('startDate', startDate);
    appendSingle('finishDate', finishDate);
  }, [count]);

  return (
    <div className={styles.container}>
      <h3>{t('boss_complex.TJM_boyicha_chegirmalar_statistikasi')}</h3>
      <div className={styles.container_right}>
        <Segmented
          defaultValue={discountCurrency}
          options={[
            { label: 'UZS', value: 'UZS' },
            { label: 'USD', value: 'USD' }
          ]}
          onChange={e => dispatch(setChangeDiscountCurrency(e as string))}
        />
        <Segmented
          defaultValue={type}
          options={[
            { label: t('home.Summa'), value: 'price' },
            { label: t('broker.Tolov_miqdori'), value: 'count' }
          ]}
          onChange={e => dispatch(setChangeType(e as string))}
        />
        <div className={styles.container_arrows}>
          <ArrowSquireDown className={styles.arrow_left} onClick={handleArrowLeft} />
          <div className={styles.line_chart_middle_body}>
            <h2>{year}</h2>
          </div>
          <ArrowSquireDown className={styles.arrow_right} onClick={handleArrowRight} />
        </div>
      </div>
    </div>
  );
};

export default SunburstHeader;
