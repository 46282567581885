import React, { FC, useEffect } from 'react';
import styles from './monetaryLogin.module.scss';
import MonetaryImage from '../../../assets/img/MonetaryImage.png';
import { Button, Form, Input } from 'antd';
import { useMonetaryConfigSet } from '../../../queries/mutation';
import { MonetaryConfig } from '../../../utils/models/MonetaryConfig';

interface IProps {
  data: MonetaryConfig | undefined;
}

const MonetaryLogin: FC<IProps> = ({ data }) => {
  const [form] = Form.useForm();
  const monetaryConfig = useMonetaryConfigSet();

  const onFinish = (values: { username: string; password: string }) => {
    monetaryConfig.mutate({
      active: !data?.active,
      password: values.password,
      username: values.username,
      ...(data?.companies && { companies: data?.companies })
    });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        username: data?.username,
        password: data?.password
      });
    }
  }, [data, form]);
  return (
    <div className={styles.container}>
      <Form layout="vertical" className={styles.form} autoComplete="off" onFinish={onFinish} form={form}>
        <img src={MonetaryImage} alt="MonetaryImage" />
        <h1>Monetary</h1>
        <Form.Item label="Username" name={'username'}>
          <Input size="large" className="customInput" placeholder="Enter your email" />
        </Form.Item>
        <Form.Item label="Parol" name={'password'}>
          <Input size="large" className="customInput" placeholder="Enter your password" />
        </Form.Item>
        <Button size="large" loading={monetaryConfig.isLoading} htmlType="submit" style={{ borderColor: data?.active ? 'red' : '' }}>
          Aktivlashtirish
        </Button>
      </Form>
    </div>
  );
};

export default MonetaryLogin;
