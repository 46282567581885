import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDebounce } from '@hooks/useDebounce';
import { Checkbox, Collapse, Drawer, Input, Radio, Space, Spin, Switch } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import ColapseIcon from '../../../../assets/icons/ColapseIcon';
import EllipseIcon from '../../../../assets/icons/EllipseIcon';
import FIlterColapseIcon from '../../../../assets/icons/FIlterColapseIcon';
import FilterReloadIcon from '../../../../assets/icons/FilterReloadIcon';
import SlashIcon from '../../../../assets/icons/SlashIcon';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { useCurrencyList, useHousesNameAndID } from '../../../../queries/queries';
import DatePicker from '../../../../service/datePicker';
import { DATE_FORMAT } from '../../../../utils/constants/format';
import { tableStatusColor } from '../../../../utils/constants/TableStatusColor';
import { ContractStatus } from '../../../../utils/models/ContractStatus';
import { SearchParamsContract } from '../../Contract';
import HouseItem from '../table/houseItem/HouseItem';

import EmployeeFilter from './employee/EmployeeFilter';

const { RangePicker } = DatePicker;

const { Panel } = Collapse;

interface ContractFilterDrawerProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const ContractFilterDrawer: FC<ContractFilterDrawerProps> = ({ visible, setVisible }) => {
  const { appendMultipleDifferent, searchParams, removeAllSearchParams, appendSingle, remove, appendMultiple } = useQueryParam<
    SearchParamsContract,
    any
  >();
  const { t } = useTranslation();
  const { isLoading, data: houses } = useHousesNameAndID();
  const { data: currencyList } = useCurrencyList();

  const [value, setValue] = useState<string>('');
  const debouncedValue = useDebounce<string>(value, 800);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (debouncedValue) {
      appendSingle('contractNumber', debouncedValue);
    } else {
      remove('contractNumber', undefined);
    }
  }, [debouncedValue]);

  const onClose = () => {
    setVisible(false);
  };

  const deleteAllFilters = () => {
    removeAllSearchParams();
  };

  const changeDate = (values: any, formatString: [string, string]) => {
    appendMultipleDifferent(['startDate', 'finishDate'], formatString);
  };

  const changeContractStatus = (value: ContractStatus) => {
    if (Array.isArray(searchParams.statuses)) {
      searchParams.statuses.includes(value) ? remove('statuses', value) : appendMultiple('statuses', value);
    } else if (searchParams.statuses === value) {
      remove('statuses', value);
    } else {
      appendMultiple('statuses', value);
    }
  };

  const changeIsFormal = (e: string) => {
    if (searchParams.formal && String(searchParams?.formal) === e) {
      remove('formal', undefined);
    } else {
      appendSingle('formal', e);
    }
  };


  const changeIsFlatRepaired = (e: boolean | null | any) => {
    if (e === null) {
      remove('flatRepaired', undefined);
    } else if (searchParams.flatRepaired && searchParams.flatRepaired === e) {
      remove('flatRepaired', undefined);
    } else {
      appendSingle('flatRepaired', e);
    }
  };


  const changeFlatNumber = (e: any) => {
    if (e.target.value) {
      appendSingle('flatNumber', e.target.value);
    } else {
      remove('flatNumber', undefined);
    }
  };

  const handleColor = (key: string) => {
    searchParams.color?.includes('NOTHING') && remove('color', 'NOTHING');
    searchParams.color?.includes(key) ? remove('color', key) : appendMultiple('color', key);
  };

  const clearColor = () => {
    if (searchParams.color) {
      for (let i = 0; i <= searchParams.color.length; i++) {
        remove('color', searchParams.color[i]);
      }
    }
    searchParams.color?.includes('NOTHING') ? remove('color', 'NOTHING') : appendMultiple('color', 'NOTHING');
  };

  const changeValyuta = (value: string) => {
    if (Array.isArray(searchParams.currencies)) {
      searchParams.currencies.includes(value) ? remove('currencies', value) : appendMultiple('currencies', value);
    } else if (searchParams.currencies === value) {
      remove('currencies', value);
    } else {
      appendMultiple('currencies', value);
    }
  };

  const changeUnpaid = (value: boolean) => {
    if (value) {
      appendSingle('unpaid', String(value));
    } else {
      remove('unpaid', 'false');
    }
  };
  const changeDiscounted = (value: boolean) => {
    if (value) {
      appendSingle('discounted', String(value));
    } else {
      remove('discounted', 'false');
    }
  };

  return (
    <Drawer width={265} headerStyle={{ display: 'none' }} placement="left" onClose={onClose} visible={visible} className="filter_drawer">
      <Spin spinning={isLoading}>
        <h1>
          <p>{t('home.Filtr')}</p>
          <span onClick={deleteAllFilters}>
            <FilterReloadIcon />
            {t('home.Tozalash')}
          </span>
        </h1>
        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          expandIconPosition="right"
          className="filter_drawer_colapse"
          expandIcon={({ isActive }) => (
            <span
              style={{
                transform: `${isActive ? 'rotate(0deg)' : 'rotate(90deg)'}`,
                transition: '0.3s'
              }}
            >
              <ColapseIcon />
            </span>
          )}
        >
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Sana')}
              </>
            }
            key="1"
          >
            <RangePicker
              format={DATE_FORMAT}
              value={
                searchParams.startDate && searchParams.finishDate
                  ? [dayjs(searchParams.startDate, DATE_FORMAT), dayjs(searchParams.finishDate, DATE_FORMAT)]
                  : [null, null]
              }
              onChange={changeDate}
              className="CustomRangePicker"
              placeholder={['kk.oo.yyyy', 'kk.oo.yyyy']}
            />
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Holati')}
              </>
            }
            key="2"
          >
            <Space direction="vertical">
              <Checkbox
                onChange={() => changeContractStatus(ContractStatus.ACTIVE)}
                checked={
                  Array.isArray(searchParams.statuses)
                    ? searchParams.statuses.includes(ContractStatus.ACTIVE)
                    : searchParams.statuses === ContractStatus.ACTIVE
                }
              >
                {t(`home.${ContractStatus.ACTIVE}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changeContractStatus(ContractStatus.CANCELLED)}
                checked={
                  Array.isArray(searchParams.statuses)
                    ? searchParams.statuses.includes(ContractStatus.CANCELLED)
                    : searchParams.statuses === ContractStatus.CANCELLED
                }
              >
                {t(`home.${ContractStatus.CANCELLED}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changeContractStatus(ContractStatus.STARTED)}
                checked={
                  Array.isArray(searchParams.statuses)
                    ? searchParams.statuses.includes(ContractStatus.STARTED)
                    : searchParams.statuses === ContractStatus.STARTED
                }
              >
                {t(`home.${ContractStatus.STARTED}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changeContractStatus(ContractStatus.FINISHED)}
                checked={
                  Array.isArray(searchParams.statuses)
                    ? searchParams.statuses.includes(ContractStatus.FINISHED)
                    : searchParams.statuses === ContractStatus.FINISHED
                }
              >
                {t(`home.${ContractStatus.FINISHED}`)}
              </Checkbox>
            </Space>
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Rasmiyligi')}
              </>
            }
            key="3"
          >
            <Radio.Group value={searchParams.formal}>
              <Space direction="vertical">
                <Radio onClick={() => changeIsFormal('true')} value="true">
                  {t('home.Tasdiqlangan')}
                </Radio>
                <Radio onClick={() => changeIsFormal('false')} value="false">
                  {t('home.Tasdiqlanmagan')}
                </Radio>
              </Space>
            </Radio.Group>
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Xonadon_raqami')}
              </>
            }
            key="4"
          >
            <label className="label_number" htmlFor="numberInput">
              {t('home.Xonadon_raqamini_kiriting')}
            </label>
            <Input value={searchParams.flatNumber} onChange={changeFlatNumber} className="input_number" id="numberInput" />
          </Panel>
          <Panel
            className="house_item"
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Turar_joy_majmuasi')}
              </>
            }
            key="5"
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              {houses?.map((house, index) => <HouseItem key={index} id={house.id || -1} name={house.name} />)}
            </Space>
          </Panel>
          <Panel
            className="colorPanel"
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Belgi_ranglari')}
              </>
            }
            key="6"
          >
            <div>
              <span onClick={clearColor}>
                <SlashIcon />
              </span>
              {Object.values(tableStatusColor).map(item =>
                item.type === 'NOTHING' ? null : (
                  <span
                    key={item.color}
                    onClick={() => handleColor(item.type)}
                    className={
                      Array.isArray(searchParams.color)
                        ? searchParams.color.includes(item.type)
                          ? 'activeColor'
                          : ''
                        : searchParams.color === item.type
                          ? 'activeColor'
                          : ''
                    }
                  >
                    <EllipseIcon background={item.color} stroke={item.color} />
                  </span>
                )
              )}
            </div>
          </Panel>
          <Panel
            className="colorPanel"
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Xodimlar')}
              </>
            }
            key="7"
          >
            <EmployeeFilter ids={searchParams.responsibleByIds} />
          </Panel>
          {currencyList && currencyList?.length > 1 && (
            <Panel
              header={
                <>
                  <FIlterColapseIcon />
                  {t('create_payment.Valyuta')}
                </>
              }
              key="8"
            >
              <Space direction="vertical">
                {currencyList?.map(item => (
                  <Checkbox
                    key={item.id}
                    onChange={() => changeValyuta(String(item.id))}
                    checked={
                      Array.isArray(searchParams.currencies)
                        ? searchParams.currencies.includes(String(item.id))
                        : searchParams.currencies === String(item.id)
                    }
                  >
                    {item.ccy}
                  </Checkbox>
                ))}
              </Space>
            </Panel>
          )}
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('boss_contract.Shartnoma_raqami')}
              </>
            }
            key="9"
          >
            <label className="label_number" htmlFor="numberInput">
              {t('boss_contract.Shartnoma_raqami')}
            </label>
            <Input value={value} onChange={handleChange} className="input_number" id="numberInput" />
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('crm.contract_pay')}
              </>
            }
            key="10"
          >
            <label className="label_number" htmlFor="numberInput" style={{ marginRight: 10 }}>
              {t('crm.unpaid')}
            </label>
            <Switch
              checked={Boolean(searchParams?.unpaid)}
              onChange={e => {
                changeUnpaid(e);
              }}
            />
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('crm.Shartnoma_chegirmasi')}
              </>
            }
            key="11"
          >
            <label className="label_number" htmlFor="numberInput" style={{ marginRight: 10 }}>
              {t('crm.discounted')}
            </label>
            <Switch
              checked={Boolean(searchParams?.discounted)}
              onChange={e => {
                changeDiscounted(e);
              }}
            />
          </Panel>

          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Tamirli_holati')}
              </>
            }
            key="12"
          >
            
            <Radio.Group value={searchParams.flatRepaired}>
              <Space direction="vertical">
                <Radio onClick={() => changeIsFlatRepaired(null)}>
                  {t('home.Barchasi')}
                </Radio>
                <Radio onClick={() => changeIsFlatRepaired(true)} value="true">
                  {t('home.Tamirli')}
                </Radio>
                <Radio onClick={() => changeIsFlatRepaired(false)} value="false">
                  {t('home.Tamirsiz')}
                </Radio>
              </Space>
            </Radio.Group>
          </Panel>
        </Collapse>
      </Spin>
    </Drawer>
  );
};

export default ContractFilterDrawer;
