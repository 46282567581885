import React, { useState } from 'react';
import styles from '../../pages/companyEmployeeRoles/companyEmployeeRoles.module.scss';
import { Button } from 'antd';
import { RoleEdit } from '../../utils/models/Roles';
import CreateAndEditRoleModal from './CreateAndEditRoleModal';
import TableRoles from './TableRoles';

// Компонент-контейнер для управления ролями сотрудников компании
const CompanyEmployeeRolesContainer = () => {
  // Состояние для управления модальным окном редактирования и создания роли
  const [editEndCreateRoleModal, setEditEndCreateRoleModal] = useState<{
    visible: boolean;
    role?: RoleEdit;
  }>({
    visible: false
  });

  // Закрытие модального окна редактирования и создания роли
  const handleCloseEditEndCreateRoleModal = () => {
    setEditEndCreateRoleModal({
      visible: false,
      role: undefined
    });
  };

  // Открытие модального окна редактирования и создания роли
  const handleOpenEditEndCreateRoleModal = (role?: RoleEdit) => {
    setEditEndCreateRoleModal({
      visible: true,
      role
    });
  };

  return (
    <div className={styles.EmployeeListContainer}>
      <TableRoles
        openEditModal={setEditEndCreateRoleModal}
        handleOpenEditEndCreateRoleModal={handleOpenEditEndCreateRoleModal} // Передача обоих пропсов
      />
      <CreateAndEditRoleModal
        role={editEndCreateRoleModal?.role} // Роль для создания и редактирования
        visible={editEndCreateRoleModal.visible} // Видимость модального окна
        close={handleCloseEditEndCreateRoleModal} // Обработчик закрытия модального окна
      />
    </div>
  );
};

export default CompanyEmployeeRolesContainer;
