import React from 'react';
import TrushIcon from '@icons/crmIcons/TrushIcon';
import EditIcon from '@icons/EditIcon';
import DeleteConfirm1 from '@pages/dashboard/components/confirm-modal/DeleteConfirm1';
import { useDeletePipeTemplate } from '@pages/voronka-shablon/services/mutation';
import { PipeTemplateModel } from '@pages/voronka-shablon/utils/models/PipeTemplateModel';
import { Table } from 'antd';
import { useGetAllPipeTemplates } from '@pages/voronka-shablon/services/queries';

type Props = {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setShablonId: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const ShablonTable: React.FC<Props> = ({ setVisible, setShablonId }) => {
  const { data } = useGetAllPipeTemplates();
  const deletePipeTemplate = useDeletePipeTemplate();

  const handleDeleteDashboard = (id: number) => deletePipeTemplate.mutateAsync(id);

  const column = [
    {
      key: '0',
      title: 'Id',
      render: (record: PipeTemplateModel, index: any, i: any) => <span>{i + 1}</span>,
      width: 80
    },
    {
      key: '1',
      dataIndex: 'name',
      title: 'Shablon nomi'
    },
    {
      key: '2',
      dataIndex: 'type',
      title: 'Shablon  turi'
    },
    {
      key: '3',
      dataIndex: 'createdDate',
      title: 'Yaratilgan sana'
    },
    {
      key: '4',
      render: (record: PipeTemplateModel) => {
        return (
          record?.type !== 'CUSTOM' && (
            <div className="flex items-center justify-center gap-4">
              <EditIcon
                stroke="#000000"
                onClick={() => {
                  setVisible(true);
                  setShablonId(record?.id);
                }}
              />
              <DeleteConfirm1
                children={<TrushIcon className="mt-1 cursor-pointer" />}
                onConfirm={() => handleDeleteDashboard(Number(record?.id))}
                text={`${record?.name} nomli shablonni o'chirmoqchimisiz?`}
                isLoading={deletePipeTemplate?.isLoading}
              />
            </div>
          )
        );
      }
    }
  ];

  const shablonTypes = {
    SALES_DEPARTMENT: "Sotuv bo'limi",
    CALL_CENTER: 'Call markaz',
    CUSTOM: 'CUSTOM'
  };

  const dataColumn = data?.map((item, index) => ({
    ...item,
    name: item?.name,
    type: shablonTypes[item?.type as keyof typeof shablonTypes],
    createdDate: item?.createdDate
  }));

  return (
    <div style={{ height: 'calc(100vh - 180px)' }} className="overflow-hidden rounded-lg bg-white">
      <Table columns={column} dataSource={dataColumn} pagination={false} />
    </div>
  );
};

export default ShablonTable;
