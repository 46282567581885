import { FileObject } from './File';

export interface FileTemplate {
  length: number;
  id: number;
  language: FileTemplateLanguage;
  document: FileObject;
}

export enum FileTemplateLanguage {
  UZLAT = 'UZLAT',
  UZCYR = 'UZCYR',
  RU = 'RU',
  EN = 'EN',
  English = "English",
  UZBEK = "O'zbek",
  RUSSIAN = "Русский",
  KIRIL = "Кирилл"
}

export enum FileTemplateType {
  CONTRACT = 'CONTRACT',
  PAYMENT = 'PAYMENT',
  CLIENT_PAYMENT = 'CLIENT_PAYMENT',
  ACT = 'ACT',
  BOOKING = 'BOOKING',
  CONTRACT_CUSTOM = 'CONTRACT_CUSTOM',
  BOOKING_PAYMENT = 'BOOKING_PAYMENT'
}
