import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@hooks/reduxHooks';
import AddDashboardIcon from '@icons/AddDashboardIcon';
import { setOpenDashboard } from '@reducers/CrmSlice';
import { Permissions } from '@utils/constants/permissions';
import { Input, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowLeftIcon from '../../../../assets/icons/contract/ArrowLeftIcon';
import FilterIcon from '../../../../assets/icons/FilterIcon';
import SearchIcon from '../../../../assets/icons/SearchIcon';
import NotificationButtons from '../../../../components/headers/components/NotificationButtons';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { MainPaths } from '../../../../routing/main/mainPaths';
import { rootPaths } from '../../../../routing/rootPaths';
import TableSettingsIcon from '../../assets/TableSettingsIcon';

import styles from './header.module.scss';

type Props = {
  handleOpenFiltrDrawer: () => void;
  handleOpenSettingsDrawer: () => void;
  totalElements: number | undefined;
};

const Header: React.FC<Props> = ({ handleOpenFiltrDrawer, handleOpenSettingsDrawer, totalElements }) => {
  const { t } = useTranslation();
  const { searchParams, appendMultipleDifferent } = useQueryParam<any, any>();
  const [search, setSearch] = useState(searchParams.search);

  const changeSearch = (value: string) => {
    setSearch(value);
    appendMultipleDifferent(['search'], [value]);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const isAccessCreateDashboard = permissions?.includes(Permissions.PERMISSION_PAGE_DASHBOARD);

  return (
    <div className={styles.header}>
      <div className={styles.header_top}>
        <div className={styles.header_top_left}>
          <ArrowLeftIcon onClick={() => window?.history.back()} />
          <span>{t('setting.Voqealar_royhati')}</span>
        </div>
        <div className={styles.header_top_right}>
          {isAccessCreateDashboard ? (
            <Tooltip
              overlay={
                <div className={styles.dashboardTooltip} onClick={() => dispatch(setOpenDashboard(true))}>
                  <AddDashboardIcon />
                  <span style={{ color: '#000' }}>{t('home.Widget_yaratish')}</span>
                </div>
              }
              color="#fff"
            >
              <span className={styles.dashboardTooltip_item}>
                {t('home.Umumiy_bitimlar')} : {totalElements}
              </span>
            </Tooltip>
          ) : (
            <span className={styles.dashboardTooltip_item}>
              {t('home.Umumiy_bitimlar')} : {totalElements}
            </span>
          )}

          <NotificationButtons />
        </div>
      </div>
      <div className={styles.header_bottom}>
        <div className={styles.header_bottom_left}>
          <div className={styles.header_bottom_left_child}>
            <div className={styles.header_bottom_left_child_in} onClick={handleOpenFiltrDrawer}>
              <FilterIcon />
              <span>{t('home.Filtr')}</span>
            </div>
          </div>
          <div className={styles.header_bottom_left_child}>
            <div className={styles.header_bottom_left_child_in} onClick={handleOpenSettingsDrawer}>
              <TableSettingsIcon />
              <span>{t('home.Jadval_sozlamalari')}</span>
            </div>
          </div>
        </div>
        <div className={styles.header_bottom_right}>
          <Input
            placeholder={t('home.Qidirish')}
            className="customInput"
            prefix={<SearchIcon />}
            value={search}
            onChange={e => changeSearch(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
