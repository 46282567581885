import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EditIconV3Blue from '@icons/EditIconV3Blue';
import { Button, Input, message, Modal, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import DocumentCopyIcon from '../../assets/icons/DocumentCopyIcon';
import InfoCircleIcon from '../../assets/icons/InfoCircleIcon';
import { CustomFieldShowData } from '../../utils/models/CustomField';

import { usePatchContractComment } from './service/mutation';

import styles from './contractId.module.scss';

interface Props {
  visible: boolean;
  onClose: (visible: boolean) => void;
  payNumber: string;
  createdDate: string;
  fields: CustomFieldShowData[];
  comment?: string | undefined;
  createdBy: string | undefined;
  responsibleBy: string | undefined;
  broker?: string;
}

const { TextArea } = Input;

const ContractInfoModal: React.FC<Props> = ({ createdDate, payNumber, comment, onClose, visible, fields, createdBy, responsibleBy, broker }) => {
  const { t } = useTranslation();
  const [localComment, setLocalComment] = useState(comment || '');
  const [isEditing, setIsEditing] = useState(false);
  const params = useParams();
  const id = params?.id;

  const editComment = usePatchContractComment();

  useEffect(() => {
    setLocalComment(comment || '');
  }, [comment]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLocalComment(e.target.value);
  };

  const handleSubmit = () => {
    if (localComment.trim() !== comment) {
      editComment
        ?.mutateAsync({
          id,
          comment: localComment
        })
        .then(() => {
          setIsEditing(false);
        });
    } else {
      setIsEditing(false);
    }
  };

  const handleCloseModal = () => {
    onClose(false);
  };

  const copyClickBoard = () => {
    navigator.clipboard.writeText(payNumber);
    message.success(t('integration.Nusxa_olindi'));
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return `${text.slice(0, maxLength)}...`;
  };

  return (
    <Modal
      className={styles.contract_info_modal}
      closable={false}
      visible={visible}
      onCancel={handleCloseModal}
      footer={false}
      width={454}
      title={
        <div>
          <InfoCircleIcon />
          <span>{t('contract_id.Shartnoma_malumotlari')}</span>
        </div>
      }
    >
      <div className={styles.contract_info_modal_item}>
        <p>{t('contract_id.Tolov_id')}</p>
        <div>
          <span>{payNumber}</span>
          <DocumentCopyIcon onClick={copyClickBoard} />
        </div>
      </div>
      <div className={styles.contract_info_modal_item}>
        <p>{t('contract_id.Shartnoma_yaratilgan_sana')}</p>
        <div>
          <span>{createdDate}</span>
        </div>
      </div>
      <div className={styles.contract_info_modal_item}>
        <p>{t('contract_id.Shartnoma_yaratuvchisi')}</p>
        <div>
          <span>{createdBy}</span>
        </div>
      </div>
      <div className={styles.contract_info_modal_item}>
        <p>{t('contract_id.Shartnoma_masul_xodimi')}</p>
        <div>
          <span>{responsibleBy}</span>
        </div>
      </div>
      {broker ? (
        <div className={styles.contract_info_modal_item}>
          <p>{t('home.Vositachi')}</p>
          <div>
            <span>{broker}</span>
          </div>
        </div>
      ) : (
        ''
      )}

      <div className="mb-[10px] mt-[10px]">
        {isEditing ? (
          <div className="mb-[35px]">
            <p className="mb-2 text-[12px] font-normal leading-[14px] text-[#8c8c8c]">{t('contract_id.Shartnoma_izohi')}</p>
            <div className="w-full transition-all duration-300 ease-in-out">
              <TextArea placeholder="......." className="customInput" value={localComment} onChange={handleChange} autoSize={{ minRows: 2, maxRows: 6 }} autoFocus />
              <Button type="primary" onClick={handleSubmit} className="float-right mt-[10px] rounded-[50px] px-[30px] py-[8px] text-[13px] font-medium leading-[16px]">
                {t('home.Saqlash')}
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-between">
            <p className="mt-[-5px] text-[12px] font-normal leading-[14px] text-[#8c8c8c]">{t('contract_id.Shartnoma_izohi')}</p>
            <div className="flex items-center gap-[10px]">
              <Tooltip title={localComment}>
                <p className="mt-[-5px] text-[12px] font-semibold leading-[14px] text-[#464255]">{truncateText(localComment, 35)}</p>
              </Tooltip>
              <span className="cursor-pointer" onClick={() => setIsEditing(true)}>
                <EditIconV3Blue />
              </span>
            </div>
          </div>
        )}
      </div>

      {fields.map((field, index) => (
        <div key={index} className={styles.contract_info_modal_item}>
          <p>{field.name}</p>
          <div>
            <span>{field.value}</span>
          </div>
        </div>
      ))}
    </Modal>
  );
};

export default ContractInfoModal;
