import React, { useState } from 'react';

import styles from './priceOneClient.module.scss';
import SettingTwoIcon from '../../../../assets/icons/SettingTwoIcon';
import ArrowDownCrmIcon from '../../../../assets/icons/ArrowDownCrmIcon';
import { Dropdown, Spin } from 'antd';
import ProfileTwoUserIcon from '../../../../assets/icons/crmIcons/ProfileTwoUserIcon';
import CostDrawer from '../costDrawer/CostDrawer';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { setCostTypeLead } from '../../../../store/reducers/CrmStatisticsSlice';
import { useBasicCurrency, usePlanFactOneLeadCost } from '../../../../queries/queries';
import { mathFloor } from '../../../../utils/helper/mathFloor';
import { useTranslation } from 'react-i18next';

const PriceOneClient = () => {
  const [openChangeLeadType, setOpenChangeLeadType] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const { data: currency } = useBasicCurrency();

  const { t } = useTranslation();

  const { startDatePipe, finishDatePipe, planFactId, costTypeLead, selectedPipe } = useAppSelector(state => state.crmStatistic);
  const dispatch = useAppDispatch();

  const { isLoading, data } = usePlanFactOneLeadCost(startDatePipe, finishDatePipe, costTypeLead, planFactId, selectedPipe?.id);

  // handleOpenChangeLeadType
  const handleOpenChangeLeadType = (isOpen: boolean) => {
    setOpenChangeLeadType(isOpen);
  };

  // handle change type cost
  const handleChangeTypeCost = (val: boolean) => {
    dispatch(setCostTypeLead(val));
    handleOpenChangeLeadType(false);
  };

  // handle drawer
  const handleDrawer = (visible: boolean) => {
    setOpenDrawer(visible);
  };

  return (
    <div className={styles.container}>
      <Spin spinning={isLoading}>
        <div className={styles.container_header}>
          <div className={'d-flex align-center'}>
            <h2>{t('boss_crm.Bir_dona')}</h2>
            <Dropdown
              visible={openChangeLeadType}
              overlayClassName={styles.drop_down}
              overlay={
                <>
                  <div onClick={() => handleChangeTypeCost(false)}>{t('boss_crm.mijoz')}</div>
                  <div onClick={() => handleChangeTypeCost(true)}>{t('boss_crm.lead')}</div>
                </>
              }
              onVisibleChange={e => handleOpenChangeLeadType(e)}
              trigger={['click']}
              placement={'bottomCenter'}
            >
              <div onClick={() => handleOpenChangeLeadType(true)} className={styles.container_header_select}>
                <span>{costTypeLead ? t('boss_crm.lead') : t('boss_crm.mijoz')}</span>
                <ArrowDownCrmIcon />
              </div>
            </Dropdown>
          </div>
          <SettingTwoIcon onClick={() => handleDrawer(true)} className={styles.container_header_setting} />
        </div>
        <div className={styles.container_count}>
          <h3>{mathFloor(data?.averageSum || 0, 0).toLocaleString('ru')}</h3>
          <p>
            {t('boss_crm.Bir_dona')} {costTypeLead ? t('boss_crm.lead') : t('boss_crm.mijoz')} {t('boss_crm.uchun_ortacha_tolov')}
          </p>
          <hr />
        </div>
        {data?.oneLeadCostCountList.map((item, index) => (
          <div key={index} className={styles.container_item}>
            <div className={styles.container_item_title}>
              <span>{item.name}</span>
              <div className={styles.container_item_title_info}>
                <p className={styles.container_item_title_info_money}>
                  {mathFloor(item.averageAmount, 0).toLocaleString('ru')} {currency?.ccy}
                </p>
                <div className={styles.container_item_title_info_client}>
                  <ProfileTwoUserIcon />
                  <span>{item.count}</span>
                </div>
              </div>
            </div>
            <div className={styles.container_item_money}>
              {mathFloor(item.totalAmount, 0).toLocaleString('ru')} {currency?.ccy}
            </div>
          </div>
        ))}
      </Spin>
      <CostDrawer visible={openDrawer} onClose={handleDrawer} />
    </div>
  );
};

export default PriceOneClient;
