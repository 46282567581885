import React from 'react';
import styles from './../../pages/messageSettings/MessageSettings.module.scss';
import SendingMessageCard from './SendingMessageCard';
import { useMessageConfig } from '../../queries/queries';
import { Skeleton } from 'antd';

const SendingMessage = () => {
  const { data, isLoading } = useMessageConfig();

  if (isLoading) {
    return (
      <div className={styles.messageSending}>
        <Skeleton.Button className={styles.skeleton} active />
        <Skeleton.Button className={styles.skeleton} active />
        <Skeleton.Button className={styles.skeleton} active />
        <Skeleton.Button className={styles.skeleton} active />
        <Skeleton.Button className={styles.skeleton} active />
        <Skeleton.Button className={styles.skeleton} active />
        <Skeleton.Button className={styles.skeleton} active />
        <Skeleton.Button className={styles.skeleton} active />
        <Skeleton.Button className={styles.skeleton} active />
      </div>
    );
  }
  return <div className={styles.messageSending}>{data?.map((item, index) => <SendingMessageCard message={item} key={index} />)}</div>;
};

export default SendingMessage;
