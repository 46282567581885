import React from 'react';
import { Empty, message, Spin } from 'antd';
import styles from './createPayment.module.scss';
import { SearchClientBalance } from '../../utils/models/SearchClientBalance';
import { useTranslation } from 'react-i18next';

interface Props {
  isLoading: boolean;
  data: SearchClientBalance[] | undefined;
  selectClient: (client: SearchClientBalance) => void;
}

const PopoverClient: React.FC<Props> = ({ data, isLoading, selectClient }) => {
  const { t } = useTranslation();

  if (data?.length === 0) {
    return (
      <div className={styles.searchClientsContainer}>
        <Empty description={t('create_payment.Mijoz_topilmadi')} />
      </div>
    );
  }

  const handleSelectClient = (client: SearchClientBalance) => {
    if (client.balance > 0) {
      selectClient(client);
      return;
    }
    message.warn(t("create_payment.Mijoz_balansida_mablag'_mavjud_emas"));
  };

  return (
    <Spin spinning={isLoading}>
      <div className={styles.searchClientsContainer}>
        <div className={styles.list}>
          {data?.map(client => (
            <div onClick={() => handleSelectClient(client)} className={styles.item}>
              <div className={styles.balance}>
                {client.balance?.toLocaleString('ru')} {t("create_payment.so'm")}
              </div>
              <div className={styles.client}>{client.name}</div>
              <div className={styles.phone}>{client.activePhone}</div>
              <div className={styles.line} />
            </div>
          ))}
        </div>
      </div>
    </Spin>
  );
};

export default PopoverClient;
