import { BaseEntity } from './BaseEntity';
import { BaseLanguageEntity } from './BaseLanguageEntity';

export enum TriggerConditionType {
  TAG = 'TAG',
  EMPLOYEE = 'EMPLOYEE',
  BUDGET = 'BUDGET',
  FIELD = 'FIELD'
}

export enum TriggerConditionErrorType {
  TAG = 'TAG',
  EMPLOYEE = 'EMPLOYEE',
  BUDGET = 'BUDGET',
  FIELD = 'FIELD',
  TASK = 'TASK',
  PIPE = 'PIPE',
  CHANGE_EMPLOYEE = 'CHANGE_EMPLOYEE'
}

export interface OrderTriggerCondition {
  type: TriggerConditionType;
  order: number;
  fieldId?: number;
}

export interface ErrorList {
  type: TriggerConditionErrorType;
  fieldId?: number;
}

export enum ExecStatus {
  WHEN_CREATE_THIS_STAGE = 'WHEN_CREATE_THIS_STAGE',
  WHEN_MOVED_THIS_STAGE = 'WHEN_MOVED_THIS_STAGE',
  WHEN_CREATE_OR_MOVED_THIS_STAGE = 'WHEN_CREATE_OR_MOVED_THIS_STAGE',
  WHEN_RESPONSIBLE_USER_CHANGE = 'WHEN_RESPONSIBLE_USER_CHANGE '
}

export enum ExecAfterStatus {
  NOW = 'NOW',
  AFTER_10_MINUTES = 'AFTER_10_MINUTES',
  AFTER_5_MINUTES = 'AFTER_5_MINUTES',
  AFTER_DAY = 'AFTER_DAY'
}

export enum DeadLineAfterStatus {
  NOW = 'NOW',
  END_OF_DAY = 'END_OF_DAY',
  END_OF_WEEKEND = 'END_OF_WEEKEND',
  AFTER_3_DAYS = 'AFTER_3_DAYS',
  IN_1_DAY = 'IN_1_DAY',
  IN_3_DAYS = 'IN_3_DAYS'
}

export enum LogicTag {
  OR = 'OR',
  AND = 'AND'
}

export enum LogicFields {
  MATCHES = 'MATCHES',
  NOT_MATCHES = 'NOT_MATCHES',
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  EMPTY = 'EMPTY',
  NOT_EMPTY = 'NOT_EMPTY '
}

export interface BudgetCondition {
  order: number;
  low: number | null;
  lowString: string;
  high: number | null;
  highString: string;
}

export interface TagCondition {
  order: number;
  logic: LogicTag;
  tags: {
    order?: number;
    tagId: number;
  }[];
}

export interface EmployeeCondition {
  order: number;
  employees: {
    order?: number;
    employeeId: number;
  }[];
}

export interface FieldsCondition {
  fieldId: number;
  values: {
    order?: number;
    value: string;
  }[];
  order: number;
  logic?: LogicFields;
}

export interface TriggerBody {
  id?: number;
  order: number;
  pipeStatusId: number;
  execStatus?: ExecStatus;
  execAfter?: number;
  execAfterStatus?: ExecAfterStatus;
  employee?: EmployeeCondition;
  tag?: TagCondition;
  balance?: BudgetCondition;
  fields?: FieldsCondition[];
  taskTypeId: number;
  assignById: number | null;
  deadLineAfter?: number;
  deadLineAfterStatus?: DeadLineAfterStatus;
  toPipeStatusId?: number;
  text?: string;
  employees?: number[];
}

export interface TriggerBodyPipe {
  id?: number;
  order: number;
  pipeStatusId: number;
  execStatus?: ExecStatus;
  execAfter?: number;
  execAfterStatus?: ExecAfterStatus;
  employee?: EmployeeCondition;
  tag?: TagCondition;
  balance?: BudgetCondition;
  fields?: FieldsCondition[];
  toPipeStatusId: number;
  employees: number[];
}

export interface TriggerList {
  tags?: BaseEntity[];
  balance?: BudgetCondition;
  execute: BaseLanguageEntity;
  order: number;
  id: number;
  type: TYPE_TRIGGER;
}

export enum TYPE_TRIGGER {
  PIPE_STATUS_TRIGGER = 'PIPE_STATUS_TRIGGER',
  TASK_TRIGGER = 'TASK_TRIGGER',
  MANAGER_TRIGGER = 'MANAGER_TRIGGER'
}
