import React from 'react';
import styles from './pipies.module.scss';
import Header from './header/Header';
import PipesBody from './PipesBody';

const Pipes = () => {
  return (
    <div className={styles.container}>
      <Header />
      <PipesBody />
    </div>
  );
};

export default Pipes;
