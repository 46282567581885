import React, { useEffect, useState } from 'react';
import { ResponsiveRadialBar } from '@nivo/radial-bar';

import styles from './chartContainer.module.scss';
import ShapeIconCrm from '../../../../assets/icons/crmIcons/ShapeIconCrm';
import { PlanFactPipe } from '../../../../utils/models/PlanFactPipe';
import { priceSeparator } from '../../../../utils/helper/priceSeparator';
import { mathFloor } from '../../../../utils/helper/mathFloor';
import { useTranslation } from 'react-i18next';

interface Props {
  data: PlanFactPipe | undefined;
}

const ChartContainer: React.FC<Props> = ({ data }) => {
  const [chartData, setChartData] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      let newData: any[] = [];
      let maxPlan = 1;

      data.factPipeStatusList.forEach(item => {
        if (item?.planLeadCount && item.planLeadCount > maxPlan) {
          maxPlan = item.planLeadCount;
        }
      });
      data.factPipeStatusList.forEach(item => {
        let percent = mathFloor((Number(item?.actualLeadCount) || 1) / (item?.planLeadCount || maxPlan), 2) * 100;
        let residue = 0;
        if (percent >= 100) {
          percent = 100;
        } else {
          residue = 100 - percent;
        }
        newData.push({
          id: item.pipeStatusResponse.name,
          data: [
            {
              x: item.pipeStatusResponse.name,
              y: percent,
              color: item.pipeStatusResponse.color
            },
            {
              x: item.pipeStatusResponse.name + '$20$',
              y: residue,
              color: '#F0F3FF'
            }
          ]
        });
      });
      setChartData(newData as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={styles.container}>
      <ResponsiveRadialBar
        data={chartData}
        startAngle={-180}
        endAngle={180}
        maxValue={100}
        innerRadius={0.5}
        padding={0.6}
        cornerRadius={4}
        tracksColor="#F0F3FF"
        margin={{ top: 32, right: 32, bottom: 32, left: 32 }}
        colors={{ datum: 'data.color' }}
        enableRadialGrid={false}
        isInteractive={true}
        tooltip={e => {
          let name = e.bar.category;
          name = name.replace('$20$', '');
          const pipe = data?.factPipeStatusList.find(item => item.pipeStatusResponse.name === name);
          return (
            <div className={styles.container_tooltip}>
              <div className={styles.container_tooltip_item}>
                <ShapeIconCrm color={pipe?.pipeStatusResponse.color as string} />
                <span className={styles.container_tooltip_item_name}>{pipe?.pipeStatusResponse.name}</span>
                <span className={styles.container_tooltip_item_value}>
                  {pipe?.planLeadCount} / {pipe?.actualLeadCount}
                </span>
              </div>
            </div>
          );
        }}
        radialAxisStart={null}
        enableCircularGrid={false}
        circularAxisOuter={null}
      />
      <div className={styles.container_center}>
        <span className={styles.container_center_all_sum}>{priceSeparator(Number(data?.planAmount))}</span>
        <span className={styles.container_center_all_sum}>{t('boss_crm.Sotuv_summasi')}</span>
        <span className={styles.container_center_get_sum}>{priceSeparator(Number(data?.actualAmount))}</span>
      </div>
    </div>
  );
};

export default ChartContainer;
