import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DashboardModal from '@pages/crm/components/dashboard-modal/DashboardModal';
import i18n from '@service/I18nService';
import { encodeString } from '@utils/helper';
import { textCapitalize } from '@utils/helper/textConverter';
import { CrmField } from '@utils/models/CrmField';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { t } from 'i18next';

import ArrowLeftIcon from '../../assets/icons/contract/ArrowLeftIcon';
import ArrowRightIcon from '../../assets/icons/contract/ArrowRightIcon';
import TableConfigDrawer from '../../components/filters/TableConfigDrawer';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useCrmEmployee, useCrmFields, useTableConfig } from '../../queries/queries';
import { MainPaths } from '../../routing/main/mainPaths';
import { rootPaths } from '../../routing/rootPaths';
import { tableExamples } from '../../utils/constants/tableExamples';
import { TableConfig } from '../../utils/models/TableConfig';
import { TableKey } from '../../utils/models/TableKey';
import { SearchParamsHome } from '../home/Home';
import { convertLocation } from '../oneLid/components/information/info/Info';

import DotsIcon from './assets/DotsIcon';
import FiltrDrawer from './components/filtrDrawer/FiltrDrawer';
import Header from './components/header/Header';
import { useGetActivityLogs, useGetActivityPipes } from './services/queries';
import { EventElements } from './utils/helpers/EventElements';
import { ActivityLogsModel } from './utils/models/ActivityLogsModel';

import styles from './activityLogs.module.scss';

const ActivityLogs: React.FC = () => {
  const { appendMultipleDifferent, searchParams } = useQueryParam<SearchParamsHome, any>();
  const { data, isLoading, isFetching } = useGetActivityLogs(searchParams, 18);
  const [openFiltrDrawer, setOpenFiltrDrawer] = useState<boolean>(false);
  const [openSettingsDrawer, setOpenSettingsDrawer] = useState<boolean>(false);
  const { data: tableConfig } = useTableConfig(TableKey.activity_logs);
  const { data: activityPipes } = useGetActivityPipes();
  const { data: crmFields } = useCrmFields();
  const { data: crmEmployee } = useCrmEmployee();

  // changePage
  const changePage = (page: number, size: number = 18) => {
    appendMultipleDifferent(['page', 'size'], [page, size || 18]);
  };

  // handleOpenFiltrDrawer
  const handleOpenFiltrDrawer = () => {
    setOpenFiltrDrawer(true);
  };

  // handleOpenSettingsDrawer
  const handleOpenSettingsDrawer = () => {
    setOpenSettingsDrawer(true);
  };
  const handleCloseSettingsDrawer = () => {
    setOpenSettingsDrawer(false);
  };

  const obj = {
    CHANGE_STATUS: (id: any) => {
      const elm = activityPipes?.data?.find(item => item.id === Number(id));

      return (
        <div
          className={styles.activity_previus}
          style={{
            border: elm?.pipeName && id ? '' : 'none'
          }}
        >
          <span title={elm?.pipeName}>{elm?.pipeName}</span>
          <span
            style={{
              background: elm?.color
            }}
            title={elm?.name}
          >
            {elm?.name}
          </span>
        </div>
      );
    },
    SET_VALUE: (current: any, objectId?: string) => {
      const locationType = crmFields?.find(item => item.id === Number(objectId))?.type;

      if (locationType === 'LOCATION') {
        return convertLocation(current)?.name;
      }
      return current;
    },
    CHANGE_LEAD_EMPLOYEE: (current: any) => {
      const employeeType = crmEmployee?.find(item => item.id === Number(current))?.fullName;

      return employeeType;
    },
    CREATE_STATUS: (id: any) => {
      const elm = activityPipes?.data?.find(item => item.id === Number(id));

      return (
        <div
          className={styles.activity_previus}
          style={{
            border: !id || !elm ? 'none' : ''
          }}
        >
          <span title={elm?.pipeName}>{elm?.pipeName}</span>
          <span
            style={{
              background: elm?.color
            }}
            title={elm?.name}
          >
            {elm?.name}
          </span>
        </div>
      );
    },
    SET_LEAD_EMPLOYEE: (current: any) => {
      const employeeType = crmEmployee?.find(item => item.id === Number(current))?.fullName;

      return employeeType;
    }
  };

  const fc = (type: string, current: any, objectId?: any) => {
    if (type in obj) {
      const getCurrent = obj[type as keyof typeof obj](current, objectId);

      return getCurrent;
    }
    return current;
  };

  const columns: ColumnsType<ActivityLogsModel> | undefined = [
    {
      key: 1,
      align: 'left',
      title: t(`home.Sana`),
      render: (date: ActivityLogsModel) => <span>{date?.createdDate}</span>,
      width: 200
    },
    {
      title: t(`home.Xodim`),
      key: 2,
      dataIndex: 'responsibleBy',
      width: 250
    },
    {
      title: t(`home.obyekt`),
      key: 3,
      dataIndex: 'objectLog',
      width: 120
    },
    {
      title: t(`home.Nomi`),
      key: 4,
      dataIndex: 'name',
      render: (value, record) => <Link to={`${rootPaths.MAIN + MainPaths.CRM}/${record?.leadId}/${encodeString(record?.name)}`}>{value}</Link>,
      width: 300
    },
    {
      title: t(`home.event`),
      key: 5,
      render: (event: ActivityLogsModel) => (
        <span>
          <b>
            {
              // eslint-disable-next-line no-nested-ternary
              event?.actionType === 'SET_VALUE' || event?.actionType === 'DELETE_VALUE' ? (
                <q>
                  {crmFields?.find(item => item?.id === Number(event?.objectId)) &&
                    (crmFields?.find(item => item?.id === Number(event?.objectId))![`name${textCapitalize(i18n.language)}` as keyof CrmField] ||
                      crmFields?.find(item => item?.id === Number(event?.objectId))?.name)}
                </q>
              ) : // eslint-disable-next-line no-nested-ternary
                event?.actionType === 'LEAD_MERGE' ? (
                  t('one_lid.Lead_birlashtirildi')
                ) : // eslint-disable-next-line no-nested-ternary
                  event?.actionType === 'REMOVE_CLIENT_FROM_LEAD' ? (
                    t('one_lid.Leaddan_olib_tashlandi')
                  ) : // eslint-disable-next-line no-nested-ternary
                    event?.actionType === 'ATTACH_LEAD_TO_CLIENT' ? (
                      t('one_lid.Leadga_biriktirildi')
                    ) : // eslint-disable-next-line no-nested-ternary
                      event?.actionType === 'TASK_TEXT_CHANGED' ? (
                        t('one_task.Topshiriq_matni_ozgartirildi')
                      ) : // eslint-disable-next-line no-nested-ternary
                        event?.actionType === 'CREATE_TASK' ? (
                          t('one_task.Topshiriq_yaratildi')
                        ) : // eslint-disable-next-line no-nested-ternary
                          event?.actionType === 'TASK_COMPLETED' ? (
                            'Topshiriq bajarildi'
                          ) : event?.actionType === 'TASK_RESULT_ADDED' ? (
                            t('one_task.Topshiriq_natijasi_qoshildi')
                          ) : (
                            ''
                          )
            }
          </b>
          {t(EventElements[event?.actionType as keyof typeof EventElements])}
        </span>
      ),
      width: 300
    },
    {
      title: t(`home.Korsatkichdan_oldin`),
      key: 6,
      render: (previus: ActivityLogsModel) =>
        fc(
          previus?.actionType,
          previus?.prevAddress
            ? `${previus?.prevAddress.country_uz}, ${previus?.prevAddress?.region_uz} ${previus?.prevAddress?.city_uz ? `,${previus?.prevAddress?.city_uz}` : ''}`
            : previus.previous,
          previus.objectId
        ),
      width: 350
    },
    {
      title: t(`home.Korsatkichdan_keyin`),
      key: 7,
      render: (current: ActivityLogsModel) =>
        fc(
          current.actionType,
          current?.currentAddress
            ? `${current?.currentAddress?.country_uz}, ${current?.currentAddress?.region_uz} ${current?.currentAddress?.city_uz ? current?.currentAddress?.city_uz : ''}`
            : current?.current,
          current?.objectId
        ),
      width: 350
    },
    {
      title: '',
      key: 8,
      dataIndex: 'actions',
      render: () => (
        <span className={styles.actions}>
          <DotsIcon />
        </span>
      ),
      width: 80
    }
  ];

  const hasChildren = (item: TableConfig, column: any) => ({
    ...column,
    children: column?.children
      ? column?.children
        ?.map((child: any) => ({
          ...(item.values?.some(val => val?.name === child?.name && val?.checked) && child)
        }))
        .filter((item: any) => item?.name)
      : []
  });

  const columnsSort = (tableConfig?.config && tableConfig?.config?.length > 0 ? tableConfig?.config : tableExamples['activity-logs'])
    ?.map((item: TableConfig) => ({
      ...(item.checked &&
        hasChildren(
          item,
          columns?.find((col: any) => col?.key === item?.id)
        ))
    }))
    .filter((item: { title: any }) => item?.title);

  return (
    <div className={styles.container}>
      <Header handleOpenFiltrDrawer={handleOpenFiltrDrawer} handleOpenSettingsDrawer={handleOpenSettingsDrawer} totalElements={data?.totalElements} />
      <div className={styles.eventsList}>
        <div className={styles.eventsList_table}>
          <Table
            columns={columnsSort}
            dataSource={data?.data || data?.content}
            className="tableNewStyle"
            pagination={{
              className: 'tableNewStyle_pagination',
              prevIcon: (
                <div className="tableNewStyle_pagination_prev">
                  <ArrowLeftIcon /> {t('my_calls.Ortga')}
                </div>
              ),
              onChange(page) {
                changePage(page, 18);
              },
              pageSize: 18,
              total: data?.totalElements,
              current: Number(searchParams?.page) || 1,
              nextIcon: (
                <div className="tableNewStyle_pagination_next">
                  {t('crm_ins.Keyingisi')} <ArrowRightIcon />
                </div>
              )
            }}
            scroll={{ y: `calc(100vh - 280px` }}
            loading={isLoading || isFetching}
          />
          <FiltrDrawer setOpenFiltrDrawer={setOpenFiltrDrawer} openFiltrDrawer={openFiltrDrawer} />
          <TableConfigDrawer visible={openSettingsDrawer} tableKey={TableKey.activity_logs} onClose={handleCloseSettingsDrawer} />
        </div>
      </div>
      <DashboardModal />
    </div>
  );
};

export default ActivityLogs;
