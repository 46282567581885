import { urlDecode } from '@utils/helper/urlEncodeDecode';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';

import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { $api } from '../../../service/RequestService';
import { DATE_FORMAT } from '../../../utils/constants/format';
import { getPaymentsBody } from '../../../utils/helper/getSearchAndDownloadBodyAllTables';
import { ContractDataForCreatePayment } from '../../../utils/models/ContractDataForCreatePayment';
import { Pagination } from '../../../utils/models/Pagination';
import { PaymentList } from '../../../utils/models/PaymentList';
import { PaymentSum } from '../../../utils/models/PaymentSum';
import { SearchParamsPayment } from '../../payment/Payment';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';

// get payments list
export function usePaymentsList(size: number, searchObj: SearchParamsPayment) {
  return useQuery<Pagination<PaymentList>>([queryKeys.PAYMENT, size, searchObj.page, JSON.stringify(searchObj)], async () => {
    const res = await $api.post(endPoints.PAYMENT_FILTER, getPaymentsBody(size, searchObj));

    return res.data.data;
  });
}

export function usePaymentsSum(searchObj: SearchParamsPayment) {
  const newSearchObj = { ...searchObj };
  const types: string[] = [];
  const bases: string[] = [];
  const buildings: string[] = [];
  const currencies: string[] = [];
  const houses: string[] = [];
  const contractStatus: string[] = [];
  const debits: string[] = [];
  const fieldValue: string[] = [];

  if (Array.isArray(newSearchObj.fieldValue)) {
    newSearchObj.fieldValue.forEach(id => {
      fieldValue.push(urlDecode(id));
    });
  } else if (newSearchObj.fieldValue) {
    fieldValue.push(urlDecode(newSearchObj.fieldValue));
  }
  if (Array.isArray(newSearchObj.types)) {
    newSearchObj.types.forEach(id => {
      types.push(id);
    });
  } else if (newSearchObj.types) {
    types.push(newSearchObj.types);
  }
  if (Array.isArray(newSearchObj.bases)) {
    newSearchObj.bases.forEach(id => {
      bases.push(id);
    });
  } else if (newSearchObj.bases) {
    bases.push(newSearchObj.bases);
  }
  if (Array.isArray(newSearchObj.buildings)) {
    newSearchObj.buildings.forEach(id => {
      buildings.push(id);
    });
  } else if (newSearchObj.buildings) {
    buildings.push(newSearchObj.buildings);
  }
  if (Array.isArray(newSearchObj.currencies)) {
    newSearchObj.currencies.forEach(id => {
      currencies.push(id);
    });
  } else if (newSearchObj.currencies) {
    currencies.push(newSearchObj.currencies);
  }
  if (Array.isArray(newSearchObj.houses)) {
    newSearchObj.houses.forEach(id => {
      houses.push(id);
    });
  } else if (newSearchObj.houses) {
    houses.push(newSearchObj.houses);
  }
  if (Array.isArray(newSearchObj.contractStatus)) {
    newSearchObj.contractStatus.forEach(id => {
      contractStatus.push(id);
    });
  } else if (newSearchObj.contractStatus) {
    contractStatus.push(newSearchObj.contractStatus);
  }
  if (Array.isArray(newSearchObj.debits)) {
    newSearchObj.debits.forEach(id => {
      debits.push(id);
    });
  } else if (newSearchObj.debits) {
    debits.push(newSearchObj.debits);
  }

  newSearchObj.types = types.length > 0 ? (types as any) : undefined;
  newSearchObj.bases = bases.length > 0 ? (bases as any) : undefined;
  newSearchObj.fieldValue = fieldValue.length > 0 ? (fieldValue as any) : undefined;
  newSearchObj.buildings = buildings.length > 0 ? (buildings as any) : undefined;
  newSearchObj.currencies = currencies.length > 0 ? (currencies as any) : undefined;
  newSearchObj.houses = houses.length > 0 ? (houses as any) : undefined;
  if (!searchObj.startDate && Object.keys(searchObj).length === 0) {
    newSearchObj.startDate = dayjs(new Date()).format(DATE_FORMAT);
    newSearchObj.finishDate = dayjs(new Date()).format(DATE_FORMAT);
  }
  newSearchObj.contractStatus = contractStatus.length > 0 ? contractStatus : undefined;
  newSearchObj.debits = debits.length > 0 ? debits : undefined;
  return useQuery<PaymentSum>(
    [queryKeys.PAYMENT, 'sum', JSON.stringify(searchObj)],
    async () => {
      const res = await $api.post(endPoints.PAYMENT_FILTER_SUM, newSearchObj);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchInterval: 60000,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useGetContractDataForPayment(contractId: string) {
  const url = `${endPoints.CONTRACT + contractId}/for-payment-plus`;

  return useQuery<ContractDataForCreatePayment>(
    [queryKeys.CONTRACT_DATA_FOR_CREATE_PAYMENT, contractId],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!contractId,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}
