export const pagesNameList = [
  'home',
  'create-order',
  'order',
  'contract',
  'update-table',
  'client-info',
  'house-info',
  'table-payment',
  'payment',
  'search-client',
  'client-balance',
  'create-payment',
  'client',
  'arrearage',
  'message',
  'showroom',
  'setting',
  'house-config',
  'sending-message',
  'templates-message',
  'message-system',
  'templates',
  'numeration-template',
  'discount',
  'interface-config',
  'builder-config',
  'employee-config',
  'export',
  'import',
  'discount-config',
  'branch-config',
  'company',
  'permissions',
  'new-client',
  'changes',
  'automatic-payment',
  'remote-payment',
  'integration-config',
  'telegram-bot-config',
  'surcharge',
  'web-site-config',
  'amo-task-config',
  'bot-interface',
  'broker',
  'kpi-config',
  'profile',
  'complex',
  'task',
  'crm',
  'payment-ways',
  'contract-fields',
  'currency',
  'security',
  'control-permissions',
  'roboto-calls',
  'discount-settings',
  'calls'
];
