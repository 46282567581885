import { LoadingOutlined } from '@ant-design/icons';
import { Icon } from '@components/index';
import { useAppDispatch } from '@hooks/reduxHooks';
import { openFilesHistory } from '@reducers/IOServiceSlice';
import { $api, BASE_URL } from '@service/RequestService';
import { downloadFile } from '@utils/helper/downloadFile';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { cx, formatBytes } from 'utils';

import { File } from 'modules/common';
import { useJobCancelProgress } from 'modules/io-service';

const icon = {
  PENDING: (
    <div className="flex h-[22px] w-[22px] items-center justify-center rounded-[50%] bg-[#F79009]">
      <Icon name="pending" className="h-[14px] w-[14px]" />
    </div>
  ),
  ERROR: (
    <div className="flex h-[22px] w-[22px] items-center justify-center rounded-[50%] bg-[#F04438]">
      <Icon name="x" className="h-[12px] w-[12px]" />
    </div>
  ),
  CANCELED: (
    <div className="flex h-[22px] w-[22px] items-center justify-center rounded-[50%] bg-[#F04438]">
      <Icon name="x" className="h-[12px] w-[12px]" />
    </div>
  ),
  DONE: (
    <div className="flex h-[22px] w-[22px] items-center justify-center rounded-[50%] bg-[#12B76A]">
      <Icon name="Check" className="h-[14px] w-[14px] text-white" />
    </div>
  ),
  IN_PROGRESS: (
    <div className="flex h-[22px] w-[22px] items-center justify-center rounded-[50%]">
      <LoadingOutlined />
    </div>
  )
};

interface IProps {
  item?: File;
}

const NotificationCard = ({ item }: IProps) => {
  const { t } = useTranslation();
  const cancelFile = useJobCancelProgress();
  const dispatch = useAppDispatch();

  const handleDownload = useMutation(
    async () => {
      const res = await $api.get(`${BASE_URL}/${item?.absolutePath}`, { responseType: 'blob' });

      return res.data;
    },
    {
      onSuccess: res => {
        downloadFile(res, item?.originalName || item?.name || '', item?.format || 'xlsx');
      }
    }
  );

  return (
    <div className={cx('relative flex  h-[calc(100%-16px)] w-[400px] gap-[20px] rounded-[12px] border-[1px] border-solid border-[#D0D5DD] bg-white p-[16px]')}>
      <Icon name={item?.format as any} className="h-[42px] min-w-[40px]" />
      <div className="w-full">
        <h1 className="w-[88%] overflow-hidden text-ellipsis whitespace-nowrap text-[14px] font-[600] leading-[20px] text-[#1D2939]" title={item?.originalName}>
          {item?.originalName}
        </h1>
        {item?.size ? <p className="text-[14px] leading-[20px] text-[#475467]">{formatBytes({ bytes: item?.size })}</p> : null}
        <div className="flex gap-[12px]">
          <span className="cursor-pointer text-[14px] font-[600] text-[#475467]" onClick={() => dispatch(openFilesHistory(true))}>
            {t('file_management.Files')}
          </span>
          {!item?.size ? (
            <span className="cursor-pointer text-[14px] font-[600] text-[#F04438]" onClick={() => cancelFile.mutate(item?.id)}>
              {t('home.Bekor_qilish')}
            </span>
          ) : (
            <div className="cursor-pointer text-[14px] font-[600] text-[#2E90FA]" onClick={() => handleDownload.mutate()}>
              {t('client_balance.Yuklab_olish')} <Spin indicator={<LoadingOutlined />} spinning={handleDownload.isLoading} size="small" />
            </div>
          )}
        </div>
      </div>
      <span className="absolute right-[16px] top-[16px]">{icon[item?.status as keyof typeof icon]}</span>
    </div>
  );
};

export default NotificationCard;
