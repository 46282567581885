import React from 'react';
import { t } from 'i18next';

import DotIcon from '../../../../assets/icons/contract/DotIcon';

import styles from '../../orderIdV2.module.scss';

const paymentTypesV2 = {
  CASH: (
    <span className={styles.paymentType}>
      <DotIcon color="#12B76A" /> {t('home.CASH')}
    </span>
  ),
  BANK: (
    <span className={styles.paymentType} style={{ color: '#FFBB54', background: '#FFFFE0' }}>
      <DotIcon color="#FFBB54" /> {t('home.BANK')}
    </span>
  ),
  BALANCE: (
    <span className={styles.paymentType} style={{ color: '#3772FF', background: '#ECFDF3' }}>
      <DotIcon color="#3772FF" /> {t('home.BALANCE')}
    </span>
  ),
  CARD: (
    <span className={styles.paymentType} style={{ color: '#FFBB54', background: '#FFFFE0' }}>
      <DotIcon color="#FFBB54" /> {t('home.CARD')}
    </span>
  ),
  UZUM: (
    <span className={styles.paymentType} style={{ color: '#FFBB54', background: '#FFFFE0' }}>
      <DotIcon color="#FFBB54" /> {t('home.UZUM')}
    </span>
  ),
  PAYME: (
    <span className={styles.paymentType} style={{ color: '#3772FF', background: '#ECFDF3' }}>
      <DotIcon color="#3772FF" /> {t('home.PAYME')}
    </span>
  ),
  MY_UZCARD: (
    <span className={styles.paymentType} style={{ color: '#FFBB54', background: '#FFFFE0' }}>
      <DotIcon color="#FFBB54" /> {t('home.MY_UZCARD')}
    </span>
  ),
  TRANSFER: (
    <span className={styles.paymentType}>
      <DotIcon color="#12B76A" /> {t('home.TRANSFER')}
    </span>
  ),
  OTHERS: (
    <span className={styles.paymentType} style={{ color: '#6927DA', background: '#F5F3FF' }}>
      <DotIcon color="#6927DA" /> {t('home.OTHERS')}
    </span>
  )
};

export default paymentTypesV2;
