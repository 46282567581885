import React, { useEffect, useState } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import DashboardVidget4 from '@icons/DashboardVidget4';
import DashboardVidget5 from '@icons/DashboardVidget5';
import DashboardVidjet1 from '@icons/DashboardVidjet1';
import DashboardVidjet2 from '@icons/DashboardVidjet2';
import DashboardVidjet3 from '@icons/DashboardVidjet3';
import TableSettingsModal from '@pages/crm/components/dashboard-table-settings/TableSettingsModal';
import { WidgetsModel } from '@pages/crm/utils/models/WidgetsModel';
import { useUpdateWidgetName } from '@pages/dashboard/services/mutations';
import { useGetDashboards } from '@pages/dashboard/services/queries';
import { CreateWidgetModel } from '@pages/dashboard/utils/models/CreateWidgetModel';
import { WidgetModel } from '@pages/dashboard/utils/models/WidgetModel';
import { useCrmFields } from '@queries/queries';
import { BASE_URL } from '@service/RequestService';
import { DATE_FORMAT } from '@utils/constants/format';
import { Button, Form, Input, Modal, Select } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import styles from './dashboardModal.module.scss';

const { Option } = Select;

type Props = {
  openDashboard: boolean;
  setOpenDashboard: React.Dispatch<React.SetStateAction<boolean>>;
  item: WidgetModel;
};

const DashboardModal: React.FC<Props> = ({ openDashboard, setOpenDashboard, item }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [selectItem, setSelectItem] = useState<{ id: number; type: string }>({
    id: 0,
    type: ''
  });
  const [nameVal, setNameVal] = useState<string>('');
  const { data: dashboard } = useGetDashboards();
  const { data: fields } = useCrmFields();
  const [openSettings, setOpenSettings] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [optionVal, setOptionVal] = useState<string>('');
  const updateWidget = useUpdateWidgetName(item?.id);
  const { searchParams } = useQueryParam<any, any>();
  const startWeek = dayjs().startOf('week').add(1, 'day').format(DATE_FORMAT);
  const endWeek = dayjs().endOf('week').add(1, 'day').format(DATE_FORMAT);

  const selectedDash = dashboard?.find(item => item?.id === Number(searchParams?.dashboardId));

  const vidgets: WidgetsModel[] = [
    {
      id: 1,
      title: t('emp_dashboard.Raqamli'),
      desc: t('emp_dashboard.raqamli_text'),
      icon: <DashboardVidjet1 />,
      selectData: undefined,
      settings: '',
      type: 'NUMBER'
    },
    {
      id: 2,
      title: `${t('emp_dashboard.Statik_pie_chart')?.substring(0, 18)}...`,
      desc: `${t('emp_dashboard.pie_text')?.substring(0, 35)}...`,
      icon: <DashboardVidjet2 />,
      selectData: fields?.filter(item => item?.type === 'SELECT'),
      settings: '',
      type: 'PIE_CHART'
    },
    {
      id: 3,
      title: t('emp_dashboard.Grafik_korinish'),
      desc: t('emp_dashboard.pie_text'),
      icon: <DashboardVidjet3 />,
      selectData: fields?.filter(item => item?.type === 'SELECT'),
      settings: '',
      type: 'CHART'
    },
    {
      id: 4,
      title: 'Timeline ko’rinish',
      desc: "Ma'lumotlar namunasining timeline ko'rinishi",
      icon: <DashboardVidget5 />,
      selectData: undefined,
      settings: '',
      type: 'TIMELINE',
      permission: ['crm', 'LEAD', 'TASK']
    },
    {
      id: 5,
      title: t('emp_dashboard.Jadval_korinish'),
      desc: t('emp_dashboard.table_text'),
      icon: <DashboardVidget4 />,
      selectData: undefined,
      settings: t('emp_dashboard.Jadval_sozlamalari'),
      type: 'LIST'
    }
  ];

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        ...item,
        [selectItem?.type]: item?.groupBy === 'CUSTOM_FIELD' ? `CUSTOM_FIELD:${item?.customFieldId}` : item?.groupBy
      });
      const itemId = vidgets?.find(prev => prev?.type === item?.type)?.id;

      setSelectItem({ id: itemId!, type: item?.type });
    }
  }, [item, openDashboard]);

  const handleClose = (e: any) => {
    setOpenDashboard(false);
    setSelectItem({ id: 0, type: '' });
    form.resetFields();
    e.stopPropagation();
  };

  const handleOpenSettingsModal = () => {
    setOpenSettings(true);
  };

  const onFinish = (values: CreateWidgetModel) => {
    const obj: { [key: string]: number[] } = {
      LIST: [item?.position[0], item?.position[1], 6, 4],
      PIE_CHART: [item?.position[0], item?.position[1], 5, 6],
      CHART: [item?.position[0], item?.position[1], 5, 6],
      NUMBER: [item?.position[0], item?.position[1], 2, 2],
      TIMELINE: [item?.position[0], item?.position[1], 5, 5]
    };

    const sendData: CreateWidgetModel = {
      ...values,
      dashboardId: searchParams?.dashboardId,
      type: selectItem?.type,
      objectData:
        // eslint-disable-next-line no-nested-ternary
        item?.objectType === 'LEAD'
          ? JSON.stringify({
              ...searchParams,
              actionTypes: Array.isArray(searchParams?.actionTypes),
              pipeId: Number(searchParams?.voronka),
              page: searchParams?.page ? searchParams?.page : 1
            }).replaceAll(/"/g, "'")
          : item?.objectType === 'TASK'
            ? JSON.stringify({
                ...searchParams,
                page: searchParams?.page ? searchParams?.page : 1
              }).replaceAll(/"/g, "'")
            : JSON.stringify({
                ...searchParams,
                page: searchParams?.page ? searchParams?.page : 1
              }).replaceAll(/"/g, "'"),
      position: obj[selectItem?.type],
      url:
        // eslint-disable-next-line no-nested-ternary
        selectItem?.type === 'PIE_CHART' || selectItem?.type === 'CHART'
          ? `${BASE_URL}/v1/dashboard/widget/chart/v2`
          : // eslint-disable-next-line no-nested-ternary
            selectItem?.type === 'TIMELINE'
            ? selectedDash?.startDate && selectedDash?.finishDate
              ? `${BASE_URL}/v1/activity-logs/filter/widget?startDate=${selectedDash?.startDate}&finishDate=${selectedDash?.finishDate}`
              : `${BASE_URL}/v1/activity-logs/filter/widget?startDate=${startWeek}&finishDate=${endWeek}`
            : // eslint-disable-next-line no-nested-ternary
              selectItem?.type === 'NUMBER'
              ? // eslint-disable-next-line no-nested-ternary
                item?.objectType === 'LEAD'
                ? `${BASE_URL}/v1/lead/table-filter/number?dashboardId=${item?.dashboardId}`
                : item?.objectType === 'TASK'
                  ? `${BASE_URL}/v1/lead-task/table/filter/number?dashboardId=${item?.dashboardId}`
                  : `${BASE_URL}/v1/activity-logs/filter/number?dashboardId=${item?.dashboardId}`
              : // eslint-disable-next-line no-nested-ternary
                selectItem?.type === 'LIST'
                ? // eslint-disable-next-line no-nested-ternary
                  item?.objectType === 'LEAD'
                  ? `${BASE_URL}/v1/lead/table-filter`
                  : item?.objectType === 'TASK'
                    ? `${BASE_URL}/v1/lead-task/table/filter`
                    : `${BASE_URL}/v1/activity-logs/filter/widget`
                : '',
      requestType: 'POST',
      fields: JSON.stringify(selectedItems?.map(item => item?.replaceAll(/"/g, "'"))),
      groupBy: selectItem?.type === 'NUMBER' || selectItem?.type === 'TIMELINE' || selectItem?.type === 'LIST' ? null : optionVal.split(':')[0],
      customFieldId: Number(optionVal.split(':')[1]),
      objectType: item?.objectType
    };

    updateWidget.mutateAsync(sendData).then(handleClose);
  };

  return (
    <div className={styles.vidgets} onMouseDown={e => e.stopPropagation()}>
      <Modal
        visible={openDashboard}
        onCancel={e => {
          handleClose(e);
        }}
        className={styles.dashboardModal}
        width={1210}
        footer={false}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label={t('emp_dashboard.Vidjet_nomi')} name="name" rules={[{ required: true, message: '' }]}>
            <Input value={nameVal} onChange={e => setNameVal(e.target.value)} />
          </Form.Item>
          <div className={styles.allVidgets}>
            {vidgets
              ?.filter(prev => !prev?.permission?.includes(item?.objectType))
              ?.map(element => (
                <div
                  className={styles.allVidgets_item}
                  onClick={() => setSelectItem({ id: element?.id, type: element?.type })}
                  key={element?.id}
                  style={{
                    borderColor: selectItem?.id === element?.id ? '#00A389' : ''
                  }}
                >
                  {element?.icon}
                  <div className={styles.allVidgets_item_text}>
                    <p>{element?.title}</p>
                    <span>{element?.desc}</span>
                  </div>
                  {element?.settings && (
                    <span className={styles.allVidgets_item_settings} onClick={handleOpenSettingsModal}>
                      {element?.settings}
                    </span>
                  )}
                  {element?.selectData && (
                    <Form.Item name={element?.type}>
                      <Select placeholder={t('home.Tanlang')} value={optionVal} onChange={e => setOptionVal(e)}>
                        {item?.objectType === 'LEAD' && <Option value="PIPE_STATUS">Manba bo'yicha</Option>}
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {item?.objectType === 'LEAD' ? (
                          <>
                            <Option value="EMPLOYEE">{t('emp_dashboard.Masul_xodim_boyicha')}</Option>
                            {element?.selectData?.map(item => (
                              <Option key={item?.id} value={`CUSTOM_FIELD:${item?.id}`}>
                                {item?.name}
                              </Option>
                            ))}
                          </>
                        ) : item?.objectType === 'TASK' ? (
                          <>
                            <Option value="EMPLOYEE">{t('emp_dashboard.Masul_xodim_boyicha')}</Option>
                            <Option value="TASK_TYPE">{t('triggers.Topshiriq_turi')}</Option>
                          </>
                        ) : (
                          <>
                            <Option value="EMPLOYEE">{t('emp_dashboard.Masul_xodim_boyicha')}</Option>
                            <Option value="EVENT_TYPE">{t('home.Voqea_turi')}</Option>
                          </>
                        )}
                      </Select>
                    </Form.Item>
                  )}
                </div>
              ))}
          </div>
          <div className={styles.dashboardModal_footer}>
            <Button onClick={handleClose} onMouseDown={e => e.stopPropagation()}>
              {t('one_lid.Yopish')}
            </Button>
            <Button htmlType="submit" loading={updateWidget.isLoading} onMouseDown={e => e.stopPropagation()}>
              {t('client.Tahrirlash')}
            </Button>
          </div>
        </Form>

        <TableSettingsModal selectedItems={selectedItems} setSelectedItems={setSelectedItems} visible={openSettings} setVisible={setOpenSettings} item={item} />
      </Modal>
    </div>
  );
};

export default DashboardModal;
