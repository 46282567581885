import React, { FC, useEffect } from 'react';
import { Button, Form, Modal, Select } from 'antd';
import styles from './selectSourceModal.module.scss';
import { useCrmFields } from '../../../../queries/queries';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { useSavePlanFact } from '../../../../queries/mutation';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface IProps {
  visible: boolean;
  close: (visible: boolean) => void;
  id: number | undefined;
}

const SelectSourceModal: FC<IProps> = ({ visible, close, id }) => {
  const [form] = Form.useForm<{ employee: number }>();
  const { isLoading } = useCrmFields();

  const { t } = useTranslation();

  const { led } = useAppSelector(state => state.crmField);

  const { startDate, finishData, planFactId } = useAppSelector(state => state.crmStatistic);

  const save = useSavePlanFact(startDate, finishData, handleSuccess);

  useEffect(() => {
    if (id && visible) {
      form.setFieldsValue({
        employee: id
      });
    }
  }, [id, visible, form]);

  // handle cancel modal
  const handleCancel = () => {
    close(false);
    form.resetFields();
  };

  // handle success
  function handleSuccess() {
    handleCancel();
  }

  // on finish
  const onFinish = (val: { employee: number }) => {
    save.mutate({
      id: planFactId,
      companyLeadFieldId: val.employee
    });
  };

  return (
    <Modal
      className={styles.container}
      width={546}
      footer={false}
      visible={visible}
      title={t('boss_crm.Manbaalarni_tanlang')}
      onCancel={handleCancel}
    >
      <Form onFinish={onFinish} form={form} name={'selectSource'}>
        <Form.Item label={''} name={'employee'} rules={[{ required: true, message: '' }]}>
          <Select
            optionFilterProp={'children'}
            showSearch
            className={'customSelect'}
            placeholder={t('boss_crm.Manbaalarni_tanlang')}
            loading={isLoading}
          >
            {led?.map((input, index) => (
              <Option key={index} value={input.id}>
                {input.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className={styles.container_modal_footer}>
          <Button onClick={handleCancel}>{t('boss_crm.Yopish')}</Button>
          <Button loading={save.isLoading} htmlType="submit" type="primary">
            {t('boss_crm.Tasdiqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SelectSourceModal;
