/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import LidIcon from '@pages/bossCrm/components/conversion/assets/icons/LidIcon';
import { ConversionContext } from '@pages/bossCrm/hooks/ConversionContext';
import { SuccessLeads } from '@pages/bossCrm/utils/models/SuccessLeads';
import { Leads } from '@pages/bossCrm/utils/models/UnsuccessfulLeads';
import { encodeString } from '@utils/helper';
import { Table } from 'antd';
import { TableColumnsType } from 'antd/es';
import { useTranslation } from 'react-i18next';

import TablePagination from '../table-pagination/TablePagination';

import styles from './unsuccessfulLeadsTable.module.scss';

const UnsuccessfulLeadsTable: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { name, firstTableData, secondTableData, customFieldIds, isTableDataLoading } = useContext(ConversionContext);

  const columns: TableColumnsType<Leads> = [
    {
      title: t('boss_crm_conversion.Lid'),
      key: 'lidName',
      render: (record: Leads) => (
        <span
          className={styles.lidName}
          style={{
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
          onClick={() => {
            navigate(`/main/crm/${record?.leadId}/${encodeString(record?.leadName!)}?prev=true`);
          }}
        >
          {record.leadName}
          <LidIcon />
        </span>
      )
    },
    {
      title: t('boss_crm_conversion.Sabab'),
      key: 'reason',
      dataIndex: 'reason'
    },
    {
      title: t('boss_crm_conversion.Pipeline_va_bosqichi'),
      key: 'pipeLine',
      render: (record: Leads) => (
        <span>
          {record.pipeName} {record.pipeStatusName}
        </span>
      )
    },
    {
      title: t("boss_crm_conversion.Ma'sul"),
      key: 'masul',
      dataIndex: 'responsibleName',
      align: 'center'
    }
  ];

  const secondColumns: TableColumnsType<SuccessLeads> = [
    {
      title: t('boss_crm_conversion.Lid'),
      key: 'lidName',
      render: (record: SuccessLeads) => (
        <span
          className={styles.lidName}
          style={{
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
          onClick={() => {
            navigate(`/main/crm/${record?.leadId}/${encodeString(record?.leadName!)}?prev=true`);
          }}
        >
          {record.leadName}
          <LidIcon />
        </span>
      )
    },
    {
      title: customFieldIds === 'tag' ? t('boss_crm_conversion.Taglar') : t('crm.Nomi'),
      key: 'value',
      render: (record: SuccessLeads) => (
        <div className={styles.tags_container}>
          {customFieldIds !== 'tag'
            ? record.value
            : record?.tags!?.length > 5
              ? record.tags?.slice(0, 5)?.map((tag, i) => (
                <>
                  <span key={tag + i} className={styles.tag}>
                    {tag}
                  </span>
                </>
              ))
              : record.tags?.map((tag, i) => (
                <span key={tag + i} className={styles.tag}>
                  {tag}
                </span>
              ))}
          {record.tags!?.length > 5 && '....'}
        </div>
      )
    },
    {
      title: t('boss_crm_conversion.Pipeline_va_bosqichi'),
      key: 'pipeLine',
      render: (record: SuccessLeads) => (
        <span>
          {record.pipeName} {record.pipeStatusName}
        </span>
      )
    },
    {
      title: t("boss_crm_conversion.Ma'sul"),
      key: 'masul',
      dataIndex: 'responsibleName',
      align: 'center'
    }
  ];

  return (
    <div className={styles.container}>
      <Table
        pagination={false}
        loading={isTableDataLoading}
        className={styles.table}
        dataSource={name === 'first' ? firstTableData!?.data : secondTableData!?.data}
        // eslint-disable-next-line eqeqeq
        columns={name == 'first' ? columns : secondColumns}
        scroll={{ y: 340 }}
      />
      <TablePagination
        current={name === 'first' ? firstTableData?.currentPage : secondTableData?.currentPage}
        total={name === 'first' ? firstTableData?.totalElements : secondTableData?.totalElements}
        pageSize={10}
      />
    </div>
  );
};

export default UnsuccessfulLeadsTable;
