import { Button, Col, Form, Input, Modal, Row, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBranchId, useGetDistrict, useRegions } from '../../queries/queries';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import styles from '../../pages/builderSettings/builderSettings.module.scss';
import { useCreateBranch, useUpdateBranch } from '../../queries/mutation';

interface Iprops {
  visible: boolean;
  id: number | null;
  setVisible: React.Dispatch<React.SetStateAction<{ visible: boolean; id: number | null }>>;
}

interface IForm {
  name: string;
  region: number;
  district: number;
  address: string;
}

const CreateBranch: React.FC<Iprops> = ({ id, setVisible, visible }) => {
  const [form] = Form.useForm();
  const { data, isLoading } = useBranchId(id as number);
  const { data: regions, isLoading: loadingRegions } = useRegions();
  const [regionId, setRegionId] = useState<number>(0);
  const { data: districts, isLoading: districtsLoading } = useGetDistrict(regionId, 'region');
  const { t } = useTranslation();
  const createBranch = useCreateBranch(setVisible);
  const updateBranch = useUpdateBranch(setVisible);

  // modal title
  const modalTitle = () => {
    if (id) {
      return t('home.Filialni_tahrirlash');
    } else return t('home.Filial_yaratish');
  };

  // after close
  const afterClose = () => {
    form.resetFields();
    form.setFieldsValue({
      district: undefined,
      region: undefined
    });
  };

  // hondle cancel
  const handleCancel = () => {
    setVisible({ visible: false, id: null });
    afterClose();
  };

  // change region
  const changeRegion = (region: number) => {
    setRegionId(region);
    form.setFieldsValue({
      district: undefined
    });
  };

  // handle submit
  const onFinish = (fields: IForm) => {
    const data = {
      name: fields.name?.trim(),
      districtID: fields.district,
      address: fields.address
    };
    if (!!id) {
      updateBranch.mutate({ ...data, id });
    } else {
      createBranch.mutate(data);
    }
  };

  // initial values
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        region: data.city.id,
        district: data.district.id,
        address: data.address
      });
      setRegionId(data.city.id);
    }
  }, [data, form]);

  return (
    <Modal
      width={774}
      title={modalTitle()}
      visible={visible}
      onCancel={handleCancel}
      afterClose={afterClose}
      footer={false}
      centered
      className={'createBuilderModal'}
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical" onFinish={onFinish} className={styles.formBuilderModal}>
          <Row>
            <Col flex={'1'}>
              <Form.Item rules={[{ required: true, message: '' }]} name="name" label={t('home.Nomi')}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col className={styles.left} flex={'1'}>
              <Form.Item label={t('home.Viloyat')} name={'region'} rules={[{ required: true }]}>
                <Select
                  optionFilterProp={'children'}
                  showSearch
                  placeholder={t('home.Tanlang')}
                  loading={loadingRegions}
                  onChange={i => changeRegion(i)}
                >
                  {regions?.map(region => (
                    <Select.Option key={region.id} value={region.id}>
                      {getCurrentLanguageWord(region)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className={styles.right} flex={'1'}>
              <Form.Item label={t('home.Shaxar')} name={'district'} rules={[{ required: true }]}>
                <Select optionFilterProp={'children'} showSearch placeholder={t('home.Tanlang')} loading={districtsLoading}>
                  {districts?.map(district => (
                    <Select.Option key={district.id} value={district.id}>
                      {getCurrentLanguageWord(district)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col flex={'1'}>
              <Form.Item rules={[{ required: true, message: '' }]} name={'address'} label={t('home.Manzil')}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.modalFooter}>
            <Button onClick={handleCancel}> {t('home.Yopish')}</Button>
            <Button htmlType="submit" type="primary" loading={createBranch.isLoading || updateBranch.isLoading}>
              {t('home.Saqlash')}
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreateBranch;
