import React, { ReactNode, useState } from 'react';
import DeleteTaskIcon from '@icons/DeleteTaskIcon';
import { Button, Modal } from 'antd';

import { useDeleteWebsiteUser } from '../service/mutation';

import styles from './addAndEditWebsiteUserModal.module.scss';

type Props = {
  id: number;
  children: ReactNode;
};

const DeleteWebsiteUserModal: React.FC<Props> = ({ id, children }) => {
  const deleteWebsiteUser = useDeleteWebsiteUser();

  const [visible, setVisible] = useState<boolean>(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleDelete = (id: number) => {
    deleteWebsiteUser?.mutateAsync(id);
    setVisible(false);
  };

  return (
    <>
      <div onClick={showModal} className="cursor-pointer border-none bg-[transparent]">
        {children}
      </div>
      <Modal visible={visible} onCancel={handleCancel} footer={null} width={400} className={styles.container}>
        <div>
          <span className={`${styles.icon}`}>
            <DeleteTaskIcon />
          </span>
        </div>
        <h1 className="mb-1 text-[18px] font-semibold text-[#101828]">Web site userni o‘chirish</h1>
        <p className="mb-8 text-[14px] font-normal text-[#475467]">Haqiqatan ham tanlangan user 'ni oʻchirib tashlamoqchimisiz?</p>
        <div className="d-flex align-center justify-center">
          <Button type="primary" onClick={handleCancel} className={styles.btn_cancel}>
            Bekor qilish
          </Button>
          <Button onClick={() => handleDelete(id)} danger loading={deleteWebsiteUser?.isLoading} type="primary" className={styles.btn_confirm}>
            O’chirish
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default DeleteWebsiteUserModal;
