import React, { useEffect, useState } from 'react';
import styles from './onlinePBX.module.scss';
import { Button, Dropdown, Spin } from 'antd';
import CheveronBottom from '../../../../../assets/icons/CheveronBottom';
import Edit2Icon from '../../../../../assets/icons/Edit2Icon';
import TrushSquareIcon from '../../../../../assets/icons/TrushSquareIcon';
import PlusIconAddCircle from '../../../../../assets/icons/PlusIconAddCircle';
import CreateAutoContract from './CreateAutoContract';
import { OnlinePbx, OnlinePbxContact, OnlinePbxPipes, OnlinePbxPipeSettingsBody } from '../../../../../utils/models/OnlinePbx';
import { useOnlinePbxContacts, useOnlinePbxPipeSettings, usePipeAll } from '../../../../../queries/queries';
import { useDeletePipeOnlinePbx, useEditPipeOnlinePbxPipeStatus } from '../../../../../queries/mutation';
import { PipeAll } from '../../../../../utils/models/PipeAll';
import SubtractIcon from '../../../../../assets/icons/SubtractIcon';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd';
import { useMakeEmployeeAsResPonsibleForPhoneCall } from '@pages/crm/services/mutation';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@hooks/reduxHooks';
import { setIsDefaultChecked } from '@reducers/CrmSlice';
interface Props {
  onlinePbx: OnlinePbx | null;
  onCancel: (visible: boolean) => void;
}

const ConfigPBX: React.FC<Props> = ({ onlinePbx, onCancel }) => {
  const [editOnlinePbxPipe, setEditOnlinePbxPipe] = useState<OnlinePbxPipes | undefined>();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const isDefaultChecked = useAppSelector(state => state.assigmentSlice.isDefaultChecked);
  const dispatch = useDispatch();
  const [notActivePipes, setNotActivePipes] = useState<PipeAll[]>([]);
  const [notActiveContacts, setNotActiveContacts] = useState<OnlinePbxContact[]>([]);

  const { t } = useTranslation();

  const { data: pipes } = usePipeAll();
  const { isLoading, data } = useOnlinePbxPipeSettings(onlinePbx?.id || -1);
  const { data: contacts } = useOnlinePbxContacts(onlinePbx?.id || -1);
  const makeEmployeeResponsible = useMakeEmployeeAsResPonsibleForPhoneCall();
  const deletePipeSetting = useDeletePipeOnlinePbx(onlinePbx?.id || -1);
  const editPipeSettings = useEditPipeOnlinePbxPipeStatus(onlinePbx?.id || -1);

  useEffect(() => {
    if (pipes && data) {
      let notActive = [...pipes];
      data.forEach(pipeConfig => {
        notActive = notActive.filter(cnt => cnt.id !== pipeConfig.pipeDto.id);
      });
      setNotActivePipes(notActive);
    }
  }, [pipes, data]);

  useEffect(() => {
    if (data && contacts) {
      let notActive = [...contacts];
      data.forEach(pipeConfig => {
        pipeConfig.onlinePbxContactDtos.forEach(cont => {
          notActive = notActive.filter(cnt => cnt.id !== cont.id);
        });
      });
      setNotActiveContacts(notActive);
    }
  }, [data, contacts]);

  // handle edit online pbx
  const handleEditOnlinePbxPipeSettings = (pipeSettings: OnlinePbxPipes | undefined) => {
    setEditOnlinePbxPipe(pipeSettings);
  };

  // pipe settings mutation
  const pipeSettingsMutation = (val: OnlinePbxPipeSettingsBody) => {
    editPipeSettings.mutate(val);
  };

  // handle create new
  const handleCreateNewPipeSettings = (pipeId: number) => {
    pipeSettingsMutation({
      pipeId: pipeId,
      onlinePbxConfigId: Number(onlinePbx?.id)
    });
  };

  // handle add contact
  const handleAddContactPipeSettings = (pipeStatus: OnlinePbxPipes, id: number) => {
    const onlinePbxContactIds: number[] = [];
    pipeStatus.onlinePbxContactDtos.forEach(item => {
      onlinePbxContactIds.push(item.id);
    });
    onlinePbxContactIds.push(id);
    pipeSettingsMutation({
      pipeId: pipeStatus.pipeDto.id,
      onlinePbxConfigId: pipeStatus.onlinePbxConfigId,
      id: pipeStatus.id,
      pipeStatusId: pipeStatus.pipeStatusResponse?.id,
      addNumberTag: pipeStatus.addNumberTag,
      autoTask: pipeStatus.autoTask,
      callTypeTag: pipeStatus.callTypeTag,
      failedCall: pipeStatus.failedCall,
      incomingCall: pipeStatus.incomingCall,
      outComingCall: pipeStatus.outComingCall,
      recordingCall: pipeStatus.recordingCall,
      responsibleUserId: pipeStatus.responsibleUserId,
      onlinePbxContactIds
    });
  };

  // handle add contact
  const handleAddAllContactPipeSettings = (pipeStatus: OnlinePbxPipes) => {
    const onlinePbxContactIds: number[] = [];
    pipeStatus.onlinePbxContactDtos.forEach(item => {
      onlinePbxContactIds.push(item.id);
    });
    notActiveContacts.forEach(cont => {
      onlinePbxContactIds.push(cont.id);
    });
    pipeSettingsMutation({
      pipeId: pipeStatus.pipeDto.id,
      onlinePbxConfigId: pipeStatus.onlinePbxConfigId,
      id: pipeStatus.id,
      pipeStatusId: pipeStatus.pipeStatusResponse?.id,
      addNumberTag: pipeStatus.addNumberTag,
      autoTask: pipeStatus.autoTask,
      callTypeTag: pipeStatus.callTypeTag,
      failedCall: pipeStatus.failedCall,
      incomingCall: pipeStatus.incomingCall,
      outComingCall: pipeStatus.outComingCall,
      recordingCall: pipeStatus.recordingCall,
      responsibleUserId: pipeStatus.responsibleUserId,
      onlinePbxContactIds
    });
  };

  // handle add contact
  const handleRemoveContactPipeSettings = (pipeStatus: OnlinePbxPipes, id: number) => {
    const onlinePbxContactIds: number[] = [];
    pipeStatus.onlinePbxContactDtos.forEach(item => {
      if (item.id !== id) onlinePbxContactIds.push(item.id);
    });
    pipeSettingsMutation({
      pipeId: pipeStatus.pipeDto.id,
      onlinePbxConfigId: pipeStatus.onlinePbxConfigId,
      id: pipeStatus.id,
      pipeStatusId: pipeStatus.pipeStatusResponse?.id,
      addNumberTag: pipeStatus.addNumberTag,
      autoTask: pipeStatus.autoTask,
      callTypeTag: pipeStatus.callTypeTag,
      failedCall: pipeStatus.failedCall,
      incomingCall: pipeStatus.incomingCall,
      outComingCall: pipeStatus.outComingCall,
      recordingCall: pipeStatus.recordingCall,
      responsibleUserId: pipeStatus.responsibleUserId,
      onlinePbxContactIds
    });
  };

  // handleDeletePipeSettings
  const handleDeletePipeSettings = (id: number) => {
    deletePipeSetting.mutate(id);
  };

  const onSwitchChange = (checked: boolean) => {
    setIsChecked(checked);
  };

  const onClickSaveBtn = () => {
    makeEmployeeResponsible
      .mutateAsync({
        onlinePbxConfigId: onlinePbx?.id!,
        leadResponsibleAutoChange: isChecked
      })
      .then(data => {
        dispatch(setIsDefaultChecked(data?.data?.leadResponsibleAutoChange));
        onCancel(false);
      });
  };

  if (editOnlinePbxPipe)
    return <CreateAutoContract onlinePbx={onlinePbx} close={handleEditOnlinePbxPipeSettings} onlinePbxPipe={editOnlinePbxPipe} />;

  return (
    <Spin spinning={isLoading || deletePipeSetting.isLoading || editPipeSettings.isLoading}>
      <div className={styles.container_body_employee}>
        <div className={styles.container_body_employee_extra}>
          <div>
            <h3 className={styles.container_body_employee_title}>"Умный перевод"</h3>
            <p className={styles.container_body_employee_sub_title}>{t('online_pbx.Umniy_Perevod')}</p>
          </div>
          <div className={styles.container_with_switch}>
            <span className={styles.container_body_config_name}>{t('online_pbx.Avtomatik_masul_xodim_ozgarishi')}</span>
            <Switch defaultChecked={isDefaultChecked ? isDefaultChecked : onlinePbx?.leadResponsibleAutoChange} onChange={onSwitchChange} />
          </div>
        </div>
        <hr className={styles.container_body_hr} />
        <h3 className={styles.container_body_employee_title}>{t('online_pbx.Varonkalarni_sozlash')}</h3>
        <p className={styles.container_body_employee_sub_title}>{t('online_pbx.Bitta_varonkaga_bir_nechta_raqam_yoki_bir')}</p>
        {data?.map((item, index) => (
          <div key={index}>
            <div className={styles.container_body_config}>
              <span className={styles.container_body_config_name}>{item.pipeDto.name}</span>
              <div className={styles.container_body_config_inputs}>
                <Dropdown
                  placement={'bottom'}
                  overlayClassName={styles.container_body_config_dropdown}
                  overlay={
                    <>
                      {notActiveContacts.length > 1 && (
                        <div onClick={() => handleAddAllContactPipeSettings(item)} className={styles.container_body_config_dropdown_item}>
                          <span className={styles.container_body_config_dropdown_item_name}>{t('online_pbx.Barcha_raqamlar')}</span>
                        </div>
                      )}
                      {notActiveContacts?.map((contact, index) => (
                        <div
                          onClick={() => handleAddContactPipeSettings(item, contact.id)}
                          key={index}
                          className={styles.container_body_config_dropdown_item}
                        >
                          <span className={styles.container_body_config_dropdown_item_name}>{contact.number}</span>
                        </div>
                      ))}
                    </>
                  }
                  trigger={['click']}
                >
                  <div className={styles.container_body_config_inputs_phone_cont}>
                    {item.onlinePbxContactDtos.map(cont => (
                      <span className={styles.container_body_config_inputs_phone_cont_one_contact}>
                        <span className={styles.container_body_config_inputs_phone_cont_one_contact_name}>{cont.number}</span>
                        <SubtractIcon
                          onClick={e => {
                            handleRemoveContactPipeSettings(item, cont.id);
                            e.stopPropagation();
                          }}
                          className={styles.container_body_config_inputs_phone_cont_one_contact_delete}
                        />
                      </span>
                    ))}
                    <CheveronBottom />
                  </div>
                </Dropdown>
                <Edit2Icon onClick={() => handleEditOnlinePbxPipeSettings(item)} className={styles.container_body_config_inputs_edit} />
                <TrushSquareIcon onClick={() => handleDeletePipeSettings(item.id)} className={styles.container_body_config_inputs_delete} />
              </div>
            </div>
            <hr />
          </div>
        ))}
        {notActiveContacts.length !== 0 && notActivePipes.length !== 0 && (
          <div className={'d-flex align-center justify-space-between'}>
            <Dropdown
              placement={'bottom'}
              overlayClassName={styles.container_body_config_dropdown}
              overlay={
                <>
                  {notActivePipes?.map((pipe, index) => (
                    <div onClick={() => handleCreateNewPipeSettings(pipe.id)} key={index} className={styles.container_body_config_dropdown_item}>
                      <span className={styles.container_body_config_dropdown_item_name}>{pipe.name}</span>
                    </div>
                  ))}
                </>
              }
              trigger={['click']}
            >
              <div className={styles.container_body_config_button}>
                <PlusIconAddCircle className={styles.container_body_config_button_icon} />
                <span>{t('online_pbx.Yangi_qoshish')}</span>
              </div>
            </Dropdown>
          </div>
        )}
        <div className={styles.config_modal_footer}>
          <Button type="primary" onClick={onClickSaveBtn}>
            {t('home.Saqlash')}
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default ConfigPBX;
