import React, { useState } from 'react';
import { Button, Input, message, Table } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import CalendarIcon from '../../assets/icons/CalendarIcon';
import CancelBronGarbage from '../../assets/icons/CancelBronGarbage';
import DocumentCopyIcon from '../../assets/icons/DocumentCopyIcon';
import DocumentNormalIcon from '../../assets/icons/DocumentNormalIcon';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import DatePicker from '../../service/datePicker';
import { changeOnePaymentAmount, changeOnePaymentDate, deleteItemTable } from '../../store/reducers/MonthlyPaymentsSlice';
import { DATE_FORMAT } from '../../utils/constants/format';
import { helper } from '../../utils/helper/helper';
import { MonthlyPayment } from '../../utils/models/CreateContract';
import PaginationCont from '../pagenation/PaginationCont';

import Buttons from './Buttons';
import Cards from './Cards';

import styles from '../../pages/contractTableUpdate/contractTableUpdate.module.scss';

const TableMonthlyPayments = () => {
  const [copiedValue, setCopiedValue] = useState<null | number>();
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const monthlyPaymentPartDtos = useAppSelector(state => state.monthlyPaymentsReducer.monthlyPaymentPartDtos);

  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleChangeDate = (date: string, index: number) => {
    dispatch(
      changeOnePaymentDate({
        date,
        index: (currentPage - 1) * 10 + index
      })
    );
  };

  const handleCopy = (value: number) => {
    message.success(t('contract_table_update.Nusxa_olindi'));
    setCopiedValue(value);
  };

  const pasteValue = (index: number) => {
    if (copiedValue) {
      dispatch(
        changeOnePaymentAmount({
          index: (currentPage - 1) * 10 + index,
          amount: copiedValue.toLocaleString('ru')
        })
      );
    } else {
      message.error(t('contract_table_update.Nusxa_olinmagan'));
    }
  };

  const handleChangeAmount = (value: string, index: number) => {
    dispatch(
      changeOnePaymentAmount({
        amount: helper.deleteNotNumbersAndParseFloatNumber(value).string,
        index: (currentPage - 1) * 10 + index
      })
    );
  };

  const handleDelete = (index: number) => {
    dispatch(deleteItemTable(index));
  };

  const columns = [
    {
      title: '№',
      width: 50,
      render: (text: any, record: MonthlyPayment, index: number) => currentPage * 10 - 9 + index
    },
    {
      title: t('contract_table_update.Tolov_qilish_sanasi'),
      render: (text: any, record: MonthlyPayment, index: number) => (
        <DatePicker
          onChange={(date, dateString) => handleChangeDate(dateString, index)}
          allowClear={false}
          format={DATE_FORMAT}
          value={record.date ? dayjs(record.date, DATE_FORMAT) : undefined}
          style={{ borderRadius: '6px' }}
          placeholder={t('client_create_contract.kk_oo_yyyy')}
          suffixIcon={<CalendarIcon />}
        />
      )
    },
    {
      title: t('contract_table_update.Qolgan_summa_som'),
      render: (item: MonthlyPayment) => (
        <Input disabled={true} value={item?.sum?.toLocaleString('ru')} style={{ textAlign: 'center', borderRadius: '6px', width: 135 }} />
      )
    },
    {
      title: t('home.Tolov_summasi_sum'),
      render: (text: any, record: MonthlyPayment, index: number) => (
        <Input
          onChange={e => handleChangeAmount(e.target.value, index)}
          value={record.amountString}
          style={{
            textAlign: 'center',
            borderRadius: '6px',
            width: 135,
            borderColor: record.amount ? 'var(--bright-gray)' : 'var(--radical-red)'
          }}
        />
      )
    },
    {
      title: t('contract_table_update.Amallar'),
      render: (text: any, record: MonthlyPayment, index: number) => (
        <div>
          <Button
            onClick={() => handleCopy(record.amount)}
            title={t('contract_table_update.nusxa_olish')}
            shape="circle"
            style={{
              border: 'none',
              marginRight: 5
            }}
          >
            <DocumentCopyIcon />
          </Button>
          <Button
            onClick={() => pasteValue(index)}
            title={t('contract_table_update.Joylash')}
            shape="circle"
            style={{ border: 'none', marginRight: 5 }}
          >
            <DocumentNormalIcon />
          </Button>
          <Button
            onClick={() => handleDelete(currentPage * 10 - 10 + index)}
            title={t('contract_table_update.Ochirish')}
            shape="circle"
            style={{ border: 'none' }}
          >
            <CancelBronGarbage />
          </Button>
        </div>
      )
    }
  ];

  return (
    <div>
      <div className={styles.header_table}>
        <Cards />
        <Buttons />
      </div>
      <Table
        className="custom_table contract_table"
        columns={columns}
        dataSource={monthlyPaymentPartDtos?.slice((currentPage - 1) * 10, currentPage * 10)}
        size="small"
        pagination={false}
        rowClassName={(row: any) => `row_size row_size_${row.payedPercent}`}
      />
      <PaginationCont
        total={monthlyPaymentPartDtos?.length || 0}
        totalPages={Math.ceil(monthlyPaymentPartDtos?.length || 0 / 10)}
        changePage={changePage}
        currentPage={currentPage}
        defaultPageSize={10}
      />
    </div>
  );
};

export default TableMonthlyPayments;
