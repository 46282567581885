import React, { useState } from 'react';

import styles from './chart.module.scss';
import SettingTwoIcon from '../../../assets/icons/SettingTwoIcon';
import StatisticsOfEmployee from './statisticsOfEmployee/StatisticsOfEmployee';
import CreatePlanModal from './createPlanModal/CreatePlanModal';
import ChartContainer from './chartContainer/ChartContainer';
import { usePlanFactPipe } from '../../../queries/queries';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

const Chart = () => {
  const [openPlanModal, setOpenPlanModal] = useState(false);

  const { t } = useTranslation();

  const { finishDatePipe, startDatePipe } = useAppSelector(state => state.crmStatistic);

  const { data, isLoading } = usePlanFactPipe(startDatePipe, finishDatePipe);

  // handle open plan modal
  const handlePlanModal = (visible: boolean) => {
    setOpenPlanModal(visible);
  };

  return (
    <div className={styles.container}>
      <Spin spinning={isLoading}>
        <div className={styles.container_header}>
          <h2>
            {t('boss_crm.Plan_Fakt')} {data?.pipeName} {t('boss_crm.varonka')}
          </h2>
          <SettingTwoIcon onClick={() => handlePlanModal(true)} className={styles.container_header_setting} />
        </div>
        <ChartContainer data={data} />
        <StatisticsOfEmployee data={data} />
      </Spin>
      <CreatePlanModal factPipe={data} visible={openPlanModal} close={handlePlanModal} />
    </div>
  );
};

export default Chart;
