import React from 'react';
import BossCrmRouter from '../../routing/bossCrm/BossCrmRouter';
import MainHeader from '../../components/headers/MainHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';

import styles from './bossCrm.module.scss';
import { t } from 'i18next';
import NavBar from '../../components/navbars/bossCrm/NavBar';

const BossCrm = () => {
  return (
    <>
      <MainHeader>
        <h1 className={styles.header}>{t(`titles.crm`)}</h1>
        <NotificationButtons />
      </MainHeader>
      <div className={styles.wrapper}>
        <NavBar />
        <BossCrmRouter />
      </div>
    </>
  );
};

export default BossCrm;
