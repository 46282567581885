import { useQuery } from 'react-query';
import { queryKeys } from '../utils/constants/queryKeys';
import { $api } from '../../../../service/RequestService';
import { endPoints } from '../utils/constants/endPoints';
import { BossPaymentStatisticModel } from '../utils/models/BossPaymentStatisticModel';

export function useBossPaymentStatistic(date: string | undefined, house: number[] | null, currency: string | undefined) {
  const houseList = house ? house?.map(item => '&houses=' + item).join('') : '';
  return useQuery<BossPaymentStatisticModel>([queryKeys.BOSS_PAYMENT_STATISTIC, date, house, currency], async () => {
    const res = await $api.get(endPoints.BOSS_PAYMENT_STATISTIC + `?${date ? 'date=' + date : ''}${houseList}&currencyId=${currency}`);
    return res.data.data;
  });
}
