import MainHeader from '@components/headers/MainHeader';
import styles from './biAnalytics.module.scss';
import NotificationButtons from '@components/headers/components/NotificationButtons';
import { useTranslation } from 'react-i18next';
import NumberOfPurchase from './components/numberOfPurchase/NumberOfPurchase';
import LedAndNumberOfPurchase from './components/ledAndNumberOfPurchase/LedAndNumberOfPurchase';
import Conversion from './components/conversion/Conversion';
const BiAnalytics = () => {
  const { t } = useTranslation();
  return (
    <>
      <MainHeader>
        <h1 className="header_title">{t('titles.BI_Analitics')}</h1>
        <div className={'d-flex align-center'}>
          <NotificationButtons />
        </div>
      </MainHeader>
      <div
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto'
        }}
      >
        <div className={styles.container}>
          <div>
            <NumberOfPurchase />
          </div>
          <div className={styles.lead_and_conversion_container}>
            <LedAndNumberOfPurchase />
            <Conversion />
          </div>
        </div>
      </div>
    </>
  );
};

export default BiAnalytics;
