import React, { useEffect, useState } from 'react';
import { Col, Empty, Input, Modal, Pagination, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import PaginationNext from '../../assets/icons/PaginationNext';
import PaginationPrev from '../../assets/icons/PaginationPrev';
import SearchIcon from '../../assets/icons/SearchIcon';
import { useQueryParam } from '../../hooks/useQueryParams';
import { BossPaymentsSearchParams } from '../../pages/bossPayments/BossPayments';
import { useBossPaymentsClient } from '../../queries/queries';
import { DATE_FORMAT } from '../../utils/constants/format';
import { BossPaymentsType } from '../../utils/models/BossPayments';

import styles from './paymentsModal.module.scss';

interface Props {
  visible: boolean;
  close: (visible: boolean) => void;
  date: string;
  base: string;
  debit?: string;
}

const PaymentsModal: React.FC<Props> = ({ visible, close, date, base, debit }) => {
  const [search, setSearch] = useState('');
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();

  const { searchParams } = useQueryParam<BossPaymentsSearchParams, any>();

  const { isLoading, isFetching, data } = useBossPaymentsClient(currentPage, date, searchParams.type || BossPaymentsType.DAY, search, base, debit);

  useEffect(() => {
    if (data) {
      setTotalElements(data.totalElements);
    }
  }, [data]);

  // handel cancel
  const handleCancel = () => {
    close(false);
  };

  // handle pagination
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // handle search
  const handleSearch = (value: string) => {
    setSearch(value);
  };

  // title modal
  const titleModal = () => {
    const dateObj = dayjs(date, DATE_FORMAT);

    switch (searchParams.type) {
      case BossPaymentsType.MONTH:
        return `${t(`boss_payments.${dateObj.format('MMMM')}`)}, ${dateObj.format('YYYY')}`;
      case BossPaymentsType.YEAR:
        return dateObj.format('YYYY');
      default:
        return date;
    }
  };

  return (
    <Modal width={782} className={styles.container} title={titleModal()} visible={visible} footer={false} onCancel={handleCancel}>
      <div className={styles.container_input_cont}>
        <Input
          value={search}
          onChange={e => handleSearch(e.target.value)}
          placeholder={t('boss_payments.Mijoz_orqali_qidirish')}
          className={styles.container_input_cont_input}
          prefix={<SearchIcon />}
        />
      </div>
      <Spin spinning={isLoading || isFetching}>
        <Row className={styles.container_table_item}>
          <Col span={12} className={styles.container_header_label}>
            {t('boss_payments.Mijoz')}
          </Col>
          <Col span={6} className={styles.container_header_label}>
            {t('boss_payments.Toluv_turi')}
          </Col>
          <Col span={6} className={styles.container_header_label}>
            {t('boss_payments.Toluv_summasi')}
          </Col>
        </Row>
        {!data?.data && <Empty className={styles.container_empty} />}
        {data?.data.map((item, index) => (
          <Row key={index} className={styles.container_table_item}>
            <Col span={12} className={styles.container_body_label}>
              {item.name}
            </Col>
            <Col span={6} className={styles.container_body_label}>
              {t(`home.${item.type}`)}
            </Col>
            <Col span={6} className={styles.container_sum}>
              {item.amount.toLocaleString('ru')} {item.currency?.ccy}
            </Col>
          </Row>
        ))}
      </Spin>
      <div className={styles.pagination}>
        <Pagination
          onChange={handlePageChange}
          prevIcon={
            <div className={styles.pagination_next_prev_buttons}>
              <PaginationPrev />
            </div>
          }
          nextIcon={
            <div className={styles.pagination_next_prev_buttons}>
              <PaginationNext />
            </div>
          }
          pageSize={6}
          showSizeChanger={false}
          current={currentPage}
          total={totalElements}
        />
      </div>
    </Modal>
  );
};

export default PaymentsModal;
