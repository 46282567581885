import React from 'react';
import { Spin, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

import { useSavePaymentConfig } from '../../../queries/mutation';
import { usePaymentConfig } from '../../../queries/queries';
import { PaymentType } from '../../../utils/models/PaymentType';

import styles from './paymentWays.module.scss';

const PaymentWays = () => {
  const { data, isLoading } = usePaymentConfig();

  const save = useSavePaymentConfig();

  const { t } = useTranslation();

  // is active payment
  const isActivePayment = (type: PaymentType) => {
    if (data) {
      return !!data.types.includes(type);
    }
    return false;
  };

  // save
  const handleSave = (type: PaymentType) => {
    if (data) {
      const newData = [...data.types];

      if (newData.includes(type)) {
        const index = newData.indexOf(type);

        newData.splice(index, 1);
      } else {
        newData.push(type);
      }
      save.mutate({
        types: newData
      });
    }
    
  };

  return (
    <div className={styles.container}>
      <Spin spinning={isLoading || save.isLoading}>
        <div className={styles.container_sub_cont}>
          <div className={styles.container_item}>
            <span className={styles.container_item_title}>{t(`home.${PaymentType.CASH}`)}</span>
            <Switch onChange={() => handleSave(PaymentType.CASH)} checked={isActivePayment(PaymentType.CASH)} />
          </div>
          <div className={styles.container_item}>
            <span className={styles.container_item_title}>{t(`home.${PaymentType.CARD}`)}</span>
            <Switch onChange={() => handleSave(PaymentType.CARD)} checked={isActivePayment(PaymentType.CARD)} />
          </div>
          <div className={styles.container_item}>
            <span className={styles.container_item_title}>{t(`home.${PaymentType.BANK}`)}</span>
            <Switch onChange={() => handleSave(PaymentType.BANK)} checked={isActivePayment(PaymentType.BANK)} />
          </div>
          <div className={styles.container_item}>
            <span className={styles.container_item_title}>{t(`home.${PaymentType.TRANSFER}`)}</span>
            <Switch onChange={() => handleSave(PaymentType.TRANSFER)} checked={isActivePayment(PaymentType.TRANSFER)} />
          </div>
          <div className={styles.container_item}>
            <span className={styles.container_item_title}>{t(`home.${PaymentType.OTHERS}`)}</span>
            <Switch onChange={() => handleSave(PaymentType.OTHERS)} checked={isActivePayment(PaymentType.OTHERS)} />
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default PaymentWays;
