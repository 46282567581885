/* eslint-disable import/no-duplicates */
/* eslint-disable react/jsx-no-bind */
import React, { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { setClientPhone, setSearchByPhone } from '@reducers/ClientSlice';
import { defaultCountries } from '@utils/constants/countryData';
import { BronIdOrFlatIds } from '@utils/models/BronIdOrFlatIds';
import { Avatar, Button, Col, Form, Input, Modal, Radio, Row, Select, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactInputMask from 'react-input-mask';
import { PhoneInput } from 'react-international-phone';

import CalendarIcon from '../../assets/icons/CalendarIcon';
import EditIcon from '../../assets/icons/EditIcon';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { SearchParamsEmployee } from '../../pages/settingEmployee/SettingEmployee';
import { useEmployee, useEmployeeEdit, useUploadFile } from '../../queries/mutation';
import { useBranchCompact, useEmployeeId, useEmployeeRole, useGetDistrict, useRegions } from '../../queries/queries';
import { BASE_URL } from '../../service/RequestService';
import { setUser } from '../../store/reducers/UserSlice';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import { helper } from '../../utils/helper/helper';
import { validateDateString } from '../../utils/helper/validatorDateString';
import { FileObject } from '../../utils/models/File';
import { PasswordValidationColor } from '../../utils/models/PasswordValidationColor';
import { User } from '../../utils/models/User';
import PasswordValidationPopover from '../passwordValidationPopover/PasswordValidationPopover';

import BlockEmployee from './block-employee/BlockEmployee';
import { useGetAssignInfo, useGetLeadEmployee } from './block-employee/services/queries';
import EmployeeBranches from './employee-branches/EmployeeBranches';

import 'react-international-phone/style.css';
import styles from '../../pages/login/login.module.scss';

interface SettingEmployeeModalProps {
  visible: boolean;
  setVisible: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      id: null;
    }>
  >;
  id: number | null;
}

export interface FormI {
  fullName: string;
  phone: string;
  phoneCode: string;
  username: string;
  password: string;
  districtId: number;
  address: string;
  status: string;
  expiry: string;
  cityId: number;
  roleBranches: Array<{
    roleId: number;
    branchId?: number | null;
  }>;
  roleId: number;
  branchId: number;
}

const SettingEmployeeModal: FC<SettingEmployeeModalProps> = ({ visible, setVisible, id }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [openBlock, setOpenBlock] = useState(false);
  const { data: assignInfo } = useGetAssignInfo(id!);
  const { data: employee } = useGetLeadEmployee(id!);

  const { clientPhones } = useAppSelector(state => state.clientReducer);
  const { searchParams } = useQueryParam<SearchParamsEmployee, any>();
  const params = useParams<{ orderIdOrFlatId: string }>();
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.userReducer.user);

  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    letters: false,
    beginUppercase: false,
    number: false
  });
  const { data, isLoading } = useEmployeeId(id as number);
  const [regionId, setRegionId] = useState<number>(-1);
  const [fileId, setFileId] = useState<number>(0);
  const [imageSrc, setImageSrc] = useState('');

  const avatarPath = useRef(data?.avatarPath);
  const userAddress = user?.roleTokens?.find(item => item?.accessToken === localStorage.getItem('token'))?.branch;
  const { data: regions, isLoading: loadingRegions } = useRegions();
  const { data: districts, isLoading: districtsLoading } = useGetDistrict(regionId, 'region');
  const { data: roleData, isLoading: loadingRole } = useEmployeeRole();
  const { data: branchData, isLoading: loadingBranch } = useBranchCompact();

  function handleCancel() {
    setImageSrc('');
    setRegionId(-1);
    avatarPath.current = '';
    form.setFieldsValue({
      fullName: '',
      phone: '',
      username: '',
      password: '',
      districtId: undefined,
      address: '',
      roleId: undefined,
      phoneCode: '',
      status: '',
      expiry: '',
      cityId: undefined,
      roleBranches: []
    });
    setVisible({ visible: false, id: null });
  }

  const employeeMutation = useEmployee(handleCancel, searchParams);
  const employeeEditMutation = useEmployeeEdit(id as number, handleCancel, searchParams);

  function getFile(data: FileObject[]) {
    setFileId(data[0].id);
    avatarPath.current = data[0].thumbAbsolutePath;
  }

  const uploadFile = useUploadFile(getFile);

  const newData: any = data?.roleBranches?.filter(item => item?.branch?.id === userAddress?.id);

  useEffect(() => {
    if (data) {
      setFileId(data.avatarId!);
      setImageSrc(`${BASE_URL}/${data.avatarPath}`);
      setRegionId(data.cityId);
      if (user?.accessToken !== null) {
        form.setFieldsValue({
          ...data,
          roleBranches: data?.roleBranches?.map((item: any) => ({
            branchId: item?.branch?.id || -1,
            roleId: item?.role?.id
          }))
        });
      } else {
        form.setFieldsValue({
          ...data,
          roleBranches: newData?.map((item: any) => ({
            branchId: item?.branch?.id ? item?.branch?.id || -1 : item?.branch,
            roleId: item?.role?.id
          }))
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        roleBranches: [{ branchId: -1, roleId: undefined }],
        cityId: userAddress?.city?.id,
        districtId: userAddress?.district?.id
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !data && userAddress && setRegionId(userAddress?.city?.id!);
    }
  }, [visible]);

  const assignCounts = assignInfo && Object.values(assignInfo!);

  function checkValue(value: number) {
    return value === 0;
  }

  const onFinish = (values: FormI) => {
    if (values?.status === 'LOCKED' && !assignCounts?.every(checkValue)) {
      setOpenBlock(true);
    } else if (id) {
      employeeEditMutation.mutateAsync({
        fullName: values.fullName?.trim(),
        username: values.username?.trim(),
        ...(values?.password && {
          password: values.password !== '' ? values.password : undefined
        }),
        districtId: values.districtId,
        address: values.address,
        roleId: values.roleId,
        status: values.status,
        avatarId: fileId || null,
        expiry: values.expiry,
        branchId: values.branchId ? values.branchId : null,
        roleBranches: values.roleBranches?.length > 0 ? values.roleBranches?.map(item => ({ ...item, branchId: item?.branchId === -1 ? null : item?.branchId })) : null,
        phone: values.phone,
        phoneCode: values.phoneCode
      });
      if (id === user?.id) {
        dispatch(
          setUser({
            ...(user as User),
            fullName: values.fullName?.trim(),
            avatar: avatarPath.current || null
          })
        );
      }
    } else {
      employeeMutation.mutateAsync({
        fullName: values.fullName?.trim(),
        username: values.username?.trim(),
        password: values.password,
        districtId: values.districtId,
        address: values.address,
        roleId: values.roleId,
        avatarId: fileId || null,
        expiry: values.expiry,
        branchId: values.branchId ? values.branchId : null,
        roleBranches: values.roleBranches?.length > 0 ? values.roleBranches?.map(item => ({ ...item, branchId: item?.branchId === -1 ? null : item?.branchId })) : null,
        phone: values.phone,
        phoneCode: values.phoneCode
      });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    handleCancel();
  }, [employeeEditMutation.isSuccess, employeeMutation.isSuccess]);
  const changeRegion = (id: number) => {
    form.setFieldsValue({
      districtId: undefined
    });
    setRegionId(id);
  };

  // handle password change
  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setPasswordValidation({
      length: e.target.value?.length >= 8,
      beginUppercase: !!e.target.value[0]?.match(/[A-Z]/),
      letters: !!e.target.value?.match(/[A-Za-z]/),
      number: !!e.target.value?.match(/[0-9]/)
    });
  };

  // title password validation
  const titlePasswordValidation = () => {
    if (passwordValidation.number && passwordValidation.length && passwordValidation.letters && passwordValidation.beginUppercase) {
      return t('profile.Ajoyib_Ishonchli_parol');
    }
    if (passwordValidation.length && passwordValidation.letters && passwordValidation.beginUppercase) {
      return t('profile.Ishonchli_parol');
    }
    if (passwordValidation.length && passwordValidation.letters) {
      return t('profile.Orta_ishonchli_parol');
    }
    return t('profile.Ishonchsiz_parol');
  };

  // title password validation
  const titlePasswordValidationColor = () => {
    if (passwordValidation.length && passwordValidation.letters && passwordValidation.beginUppercase) {
      return PasswordValidationColor.strong;
    }
    if (passwordValidation.length && passwordValidation.letters) {
      return PasswordValidationColor.middle;
    }
    return PasswordValidationColor.low;
  };

  const handleSearchClient = (phone: string, index: number) => {
    dispatch(setClientPhone({ index, phone }));
    if (!params.orderIdOrFlatId?.includes(BronIdOrFlatIds.BRON_ID)) {
      const phoneNumber = helper.deleteNotNumbers(phone, 1);

      if (index === 0 && String(phoneNumber).length >= 3) {
        dispatch(setSearchByPhone(phoneNumber));
      }
    }
  };

  return (
    <>
      <Modal
        title={id ? t('settingEmployee.Xodim_ma’lumotlari') : t('settingEmployee.Xodim_yaratish')}
        wrapClassName="settingEmployeeModal"
        visible={visible}
        centered
        onCancel={handleCancel}
        footer={false}
        width={774}
      >
        <Spin spinning={isLoading}>
          <Form onFinish={onFinish} layout="vertical" form={form}>
            <div className="settingEmployeeModalImage">
              <Avatar size={171} src={imageSrc} icon={<UserOutlined />} />
              <input
                accept="image/*"
                type="file"
                id="editFile"
                style={{ display: 'none' }}
                onChange={(e: any) => {
                  if (e.target?.files[0] && e.target.files[0].size <= 10000000) {
                    const file = e.target.files[0];
                    const formData = new FormData();

                    formData.append('files', file);
                    setImageSrc(URL.createObjectURL(file));
                    uploadFile.mutate(formData);
                  }
                }}
              />
              <label htmlFor="editFile">
                <EditIcon /> {t('settingEmployee.Tahrirlash')}
              </label>
            </div>
            <Row className="d-flex justify-space-between" gutter={24}>
              <Col span={12}>
                <Form.Item label={t('settingEmployee.Hodim')}>
                  <Form.Item noStyle name="fullName" rules={[{ required: true, message: '' }]}>
                    <Input placeholder={t('settingEmployee.Familiya_ism_sharifi')} />
                  </Form.Item>
                </Form.Item>

                {clientPhones.map((phone, index) => (
                  <Space direction="horizontal" className="d-flex" key={phone?.id}>
                    <Form.Item name="phoneCode" className="d-none" />
                    <Form.Item name="phone" rules={[{ required: true }]} label={<span>{t(`changes.client.NaturalClient.Telefon_raqam`)}</span>}>
                      <PhoneInput
                        defaultCountry="uz"
                        className={`${styles.phoneInput} !w-full`}
                        onChange={(e, phone) => {
                          const formattedPhoneNumber = e;

                          form.setFieldsValue({
                            [`phoneCode`]: `${phone?.country?.iso2?.toUpperCase()}`
                          });

                          handleSearchClient(formattedPhoneNumber, index);
                        }}
                        countries={defaultCountries}
                      />
                    </Form.Item>
                  </Space>
                ))}

                <EmployeeBranches roleData={roleData} form={form} loadingRole={loadingRole} branchData={branchData} loadingBranch={loadingBranch} />

                <Form.Item label={t('settingEmployee.Username')}>
                  <Form.Item name="username" rules={[{ required: true, min: 8 }]} noStyle>
                    <Input prefix={<span className={styles.login_icon_at}>@</span>} placeholder={t('settingEmployee.example')} />
                  </Form.Item>
                </Form.Item>
                <div className={styles.empty_div} />
                <Form.Item label={t('settingEmployee.Amal_qilish_muddati')}>
                  <Form.Item
                    noStyle
                    name="expiry"
                    rules={[
                      {
                        validator: (rule, value) => {
                          if (value) {
                            form.setFieldsValue({
                              expiry: validateDateString(value, true)
                            });
                          }
                          return Promise.resolve();
                        }
                      }
                    ]}
                  >
                    <ReactInputMask
                      // @ts-ignore*
                      mask="99.99.9999"
                      maskChar=""
                    >
                      {/* @ts-ignore */}
                      {(inputProps: any) => <Input placeholder={t('settingEmployee.kk_oo_yy')} suffix={<CalendarIcon />} {...inputProps} />}
                    </ReactInputMask>
                  </Form.Item>
                </Form.Item>
                {!!id && (
                  <Form.Item label={t('settingEmployee.Status')}>
                    <Form.Item name="status" rules={[{ required: true }]} noStyle>
                      <Radio.Group>
                        <Radio value="ENABLED">{t('settingEmployee.Faol')}</Radio>
                        <Radio value="LOCKED">{t('settingEmployee.Bloklangan')}</Radio>
                        <Radio disabled value="EXPIRED">
                          {t('settingEmployee.Muddati_o’tgan')}
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Form.Item>
                )}
              </Col>
              <Col span={12}>
                <Form.Item label={t('settingEmployee.Viloyat')}>
                  <Form.Item name="cityId" noStyle rules={[{ required: true }]}>
                    <Select optionFilterProp="children" showSearch placeholder={t('settingEmployee.Tanlang')} loading={loadingRegions} onChange={(i: any) => changeRegion(i)}>
                      {regions?.map(i => (
                        <Select.Option key={i.id} value={i.id}>
                          {getCurrentLanguageWord(i)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form.Item>
                <Form.Item label={t('settingEmployee.Shahar_tuman')}>
                  <Form.Item name="districtId" noStyle rules={[{ required: true }]}>
                    <Select optionFilterProp="children" showSearch placeholder={t('settingEmployee.Tanlang')} loading={districtsLoading}>
                      {districts?.map(i => (
                        <Select.Option key={i.id} value={i.id}>
                          {getCurrentLanguageWord(i)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form.Item>
                <Form.Item label={t('settingEmployee.Manzil')}>
                  <Form.Item name="address" rules={[{ required: true }]} noStyle>
                    <Input placeholder={t('settingEmployee.Addres')} />
                  </Form.Item>
                </Form.Item>
                <Form.Item label={t('settingEmployee.Parol')}>
                  <Form.Item
                    name="password"
                    rules={
                      id
                        ? []
                        : [
                            { required: !id, min: 8 },
                            {
                              validator: (rule, value) => {
                                if (!!value.match(/[A-Za-z]/) && value[0]?.match(/[A-Z]/) && value.length >= 8) {
                                  return Promise.resolve();
                                }
                                return Promise.reject();
                              }
                            }
                          ]
                    }
                    noStyle
                  >
                    <Input.Password onChange={handlePasswordChange} placeholder={t('settingEmployee.Parolni_kiriting')} />
                  </Form.Item>
                </Form.Item>
                <div className={styles.passwordValidation}>
                  <PasswordValidationPopover
                    length={passwordValidation.length}
                    letters={passwordValidation.letters}
                    beginUppercase={passwordValidation.beginUppercase}
                    number={passwordValidation.number}
                  >
                    <p style={{ color: titlePasswordValidationColor() }} className={styles.passwordValidation_title}>
                      {titlePasswordValidation()}
                    </p>
                  </PasswordValidationPopover>
                  <div className={styles.passwordValidation_step}>
                    <div
                      style={{
                        background: passwordValidation.length ? PasswordValidationColor.low : undefined
                      }}
                    />
                    <div
                      style={{
                        background: passwordValidation.letters && passwordValidation.length ? PasswordValidationColor.middle : undefined
                      }}
                    />
                    <div
                      style={{
                        background: passwordValidation.beginUppercase && passwordValidation.letters && passwordValidation.length ? PasswordValidationColor.strong : undefined
                      }}
                    />
                    <div
                      style={{
                        background:
                          passwordValidation.beginUppercase && passwordValidation.number && passwordValidation.letters && passwordValidation.length
                            ? PasswordValidationColor.strong
                            : undefined
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <div className="settingEmployeeModalFooter">
              <Button onClick={handleCancel}>{t('settingEmployee.Yopish')}</Button>
              <Button htmlType="submit" type="primary" loading={employeeEditMutation?.isLoading || employeeMutation?.isLoading}>
                {id ? t('settingEmployee.Saqlash') : t('settingEmployee.Yaratish')}
              </Button>
            </div>
          </Form>
        </Spin>
      </Modal>
      <BlockEmployee
        openBlock={openBlock}
        setOpenBlock={setOpenBlock}
        id={id}
        data={assignInfo}
        employee={employee}
        formAssign={form}
        handleCancel={handleCancel}
        fileId={fileId}
      />
    </>
  );
};

export default SettingEmployeeModal;
