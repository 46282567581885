/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useEffect, useState } from 'react';
import DownloadIconV5 from '@icons/DownloadIconV5';
import FileIcon from '@icons/FileIcon';
import GlphIcon from '@icons/GlphIcon';
import ContractIdCommentModal from '@pages/contractIdV2/components/contract-id-payment-history/ContractIdCommentModal';
import { updatedColumnsFunc } from '@pages/paymentV2/utils/helpers/updatedTableColumns';
import { PaymentField } from '@pages/setting/payment-changes/utils/models/PaymentField';
import { BASE_URL } from '@service/RequestService';
import { PaymentBase } from '@utils/models/PaymentBase';
import { Checkbox, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import ArrowLineDownIcon from '../../../../assets/icons/ArrowLineDownIcon';
import DotIcon from '../../../../assets/icons/contract/DotIcon';
import TrushIcon from '../../../../assets/icons/crmIcons/TrushIcon';
import IncomeIcon from '../../../../assets/icons/IncomeIcon';
import MessageTextIcon from '../../../../assets/icons/MessageTextIcon';
import OutlayIcon from '../../../../assets/icons/OutlayIcon';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import DiscountInfo from '../../../../components/discount-info/DiscountInfo';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { useDownloadPaymentBookingToWord, useDownloadPaymentToWord } from '../../../../queries/mutation';
import { MainPaths } from '../../../../routing/main/mainPaths';
import { rootPaths } from '../../../../routing/rootPaths';
import { urlEncode } from '../../../../utils/helper/urlEncodeDecode';
import { PaymentList } from '../../../../utils/models/PaymentList';
import { TableConfig } from '../../../../utils/models/TableConfig';
import { SearchParamsPayment } from '../../../payment/Payment';
import PaymentDeleteModal from '../payment-delete-modal/PaymentDeleteModal';

import styles from './paymentHistoryTable.module.scss';

interface IProps {
  data: PaymentList[] | undefined;
  loading: boolean;
  tableConfig: TableConfig[];
  tableConfigFetch: boolean;
  customFields: PaymentField[] | undefined;
}

interface FileNameProps {
  originalName: string;
}

const PaymentHistoryTable: FC<IProps> = ({ data, loading, tableConfig, tableConfigFetch, customFields }) => {
  const { searchParams, push } = useQueryParam<SearchParamsPayment, any>();
  const downloadPayment = useDownloadPaymentToWord();
  const downloadPaymentBooking = useDownloadPaymentBookingToWord();
  const [offsetTop, setOffsetTop] = useState(0);
  const [deletePayment, setDeletePayment] = useState<{
    ids: number[];
    visible: boolean;
    contractId: number;
  }>({
    ids: [],
    visible: false,
    contractId: -1
  });
  const [selections, setSelections] = useState<React.Key[]>([]);
  const [commentCanceled, setCommentCanceled] = useState({
    visible: false,
    text: '',
    employee: '',
    date: ''
  });

  const [dropdown, setDropdown] = useState(0);
  const { t } = useTranslation();
  const { contractId } = searchParams;

  const [columnDebitWidth, setColumnDebitWidth] = useState(0);
  const [clientLenWidth, setClientLenWidth] = useState(0);
  const [createByLenWidth, setCreateByLenWidth] = useState(0);
  const [numberWidth, setNumberWidth] = useState(0);

  const arr: PaymentList[] =
    data?.map(item => ({
      ...item,
      ...customFields?.reduce(
        (p, c) => ({
          ...p,
          [`field_${c.id}`]: item?.fields?.find(el => el.id === c.id) ? item?.fields?.find(el => el.id === c.id)?.value : ''
        }),
        {}
      )
    })) || [];

  const paymentTypes = {
    CASH: (
      <span className={styles.paymentType}>
        <DotIcon color="#12B76A" /> {t('home.CASH')}
      </span>
    ),
    BANK: (
      <span className={styles.paymentType} style={{ color: '#FFBB54', background: '#FFFFE0' }}>
        <DotIcon color="#FFBB54" /> {t('home.BANK')}
      </span>
    ),
    BALANCE: (
      <span className={styles.paymentType} style={{ color: '#3772FF', background: '#ECFDF3' }}>
        <DotIcon color="#3772FF" /> {t('home.BALANCE')}
      </span>
    ),
    CARD: (
      <span className={styles.paymentType} style={{ color: '#FFBB54', background: '#FFFFE0' }}>
        <DotIcon color="#FFBB54" /> {t('home.CARD')}
      </span>
    ),
    UZUM: (
      <span className={styles.paymentType} style={{ color: '#FFBB54', background: '#FFFFE0' }}>
        <DotIcon color="#FFBB54" /> {t('home.UZUM')}
      </span>
    ),
    PAYME: (
      <span className={styles.paymentType} style={{ color: '#3772FF', background: '#ECFDF3' }}>
        <DotIcon color="#3772FF" /> {t('home.PAYME')}
      </span>
    ),
    MY_UZCARD: (
      <span className={styles.paymentType} style={{ color: '#FFBB54', background: '#FFFFE0' }}>
        <DotIcon color="#FFBB54" /> {t('home.MY_UZCARD')}
      </span>
    ),
    TRANSFER: (
      <span className={styles.paymentType}>
        <DotIcon color="#12B76A" /> {t('home.TRANSFER')}
      </span>
    ),
    OTHERS: (
      <span className={styles.paymentType} style={{ color: '#6927DA', background: '#F5F3FF' }}>
        <DotIcon color="#6927DA" /> {t('home.OTHERS')}
      </span>
    )
  };

  const toKilobytes = (bytes: number) => (bytes / 1024).toFixed(2);

  const FileName: React.FC<FileNameProps> = ({ originalName }) => {
    const truncateText = (text: string, maxLength: number) => {
      if (text.length > maxLength) {
        return `${text.slice(0, maxLength - 1)}...`;
      }
      return text;
    };

    const getTruncatedFileName = (fileName: string, maxLength: number) => {
      const extensionIndex = fileName.lastIndexOf('.');
      const name = fileName.substring(0, extensionIndex);
      const extension = fileName.substring(extensionIndex);

      if (name.length > maxLength) {
        return `${truncateText(name, maxLength)}(${extension})`;
      }
      return fileName;
    };

    const truncatedFileName = getTruncatedFileName(originalName, 17);

    return (
      <Tooltip title={originalName} overlayInnerStyle={{ borderRadius: '6px', border: '1px solid black' }}>
        <p className="text-[16px] font-medium text-[#344054]">{truncatedFileName}</p>
      </Tooltip>
    );
  };

  const columns: ColumnsType<PaymentList> = [
    {
      title: '№',
      dataIndex: 'number',
      key: '1',
      width: numberWidth + 50,
      fixed: 'left',
      render: prev => prev
    },
    {
      ...(!contractId && {
        title: 'Asos',
        dataIndex: 'debit',
        key: '2',
        align: 'center',
        render: (prev: string, record) =>
          prev === 'INCOME' ? (
            <span
              className={styles.income}
              ref={ref => {
                if (columnDebitWidth < ref?.offsetWidth!) {
                  setColumnDebitWidth(ref?.offsetWidth || 0);
                }
              }}
            >
              <Tooltip title={t(`home.${prev}`)}>
                <span>
                  <IncomeIcon />
                </span>
              </Tooltip>{' '}
              {t(`home.PAYMENT_${record?.base}`)}
            </span>
          ) : (
            <span
              className={styles.outcome}
              ref={ref => {
                if (columnDebitWidth < ref?.offsetWidth!) {
                  setColumnDebitWidth(ref?.offsetWidth || 0);
                }
              }}
            >
              <Tooltip title={t(`home.${prev}`)}>
                <span>
                  <OutlayIcon />
                </span>
              </Tooltip>
              {t(`home.PAYMENT_${record?.base}`)}
            </span>
          ),
        width: columnDebitWidth + 32
      })
    },
    {
      title: 'Mijoz',
      dataIndex: 'clientFullName',
      key: '3',
      align: 'center',
      render: (prev: string, obj: PaymentList) => (
        <div
          className={styles.table_client}
          onClick={() => {
            const paymentTypeUrl = {
              [PaymentBase.CONTRACT]: `${rootPaths.MAIN + MainPaths.CONTRACT}/${obj?.contractId}/№ ${urlEncode(obj?.contractNumber)}`,
              [PaymentBase.BOOKING]: `${rootPaths.MAIN + MainPaths.ORDER}/${obj?.bookingId}/№ ${urlEncode(obj?.bookingNumber)}`,
              [PaymentBase.CLIENT]: `${rootPaths.MAIN + MainPaths.PAYMENT}/${obj?.clientId}/client-balance`,
              [PaymentBase.SURCHARGE]: `${rootPaths.MAIN + MainPaths.CONTRACT}/${obj?.contractId}/№ ${urlEncode(obj?.contractNumber)}`
            };

            push(paymentTypeUrl[obj.base]);
          }}
        >
          <div className={styles.table_client_left}>{prev?.slice(0, 2)}</div>
          <div className={styles.table_client_right}>
            <h3>{prev}</h3>
            <p>{obj?.clientPhone}</p>
          </div>
        </div>
      ),
      width: clientLenWidth + 80
    },
    {
      ...(!contractId && {
        title: 'Shartnoma',
        dataIndex: 'contractNumber',
        key: '4',
        width: 150,
        render: (prev, obj: PaymentList) =>
          (
            <span
              onClick={() => {
                push(`${rootPaths.MAIN + MainPaths.CONTRACT}/${obj?.contractId}/№ ${urlEncode(obj?.contractNumber)}`);
              }}
              style={{ cursor: 'pointer' }}
            >
              {prev}
            </span>
          ) ?? t('home.Balans')
      })
    },
    {
      title: 'Sana',
      align: 'center',
      dataIndex: 'createDate',
      key: '5',
      render: (prev: string) => <span className={styles.container_table_text}>{prev}</span>,
      width: 180
    },
    {
      title: 'Summa',
      align: 'center',
      key: '6',
      render: (record: PaymentList) => (
        <span className={styles.container_table_text} style={{ textAlign: 'center', display: 'block' }}>
          {record.amount?.toLocaleString('ru')} {record?.currency?.ccy}
        </span>
      ),
      width: 250
    },
    {
      title: 'Tulov_turi',
      dataIndex: 'type',
      key: '7',
      render: (prev: string) => paymentTypes[prev as keyof typeof paymentTypes],
      width: 150
    },
    {
      ...(!contractId && {
        title: 'Masul',
        dataIndex: 'createdBy',
        key: '8',
        render: prev => prev,
        width: createByLenWidth + 50,
        align: 'center'
      })
    },
    {
      title: 'TJM_nomi',
      dataIndex: 'houseName',
      align: 'center',
      key: '9',
      width: 150,
      render: (prev: string) => (
        <span className={styles.container_table_text} style={{ color: 'black' }}>
          {prev}
        </span>
      )
    }
  ];

  const updatedColumns = updatedColumnsFunc(columns, customFields!);
  const columnsSort =
    (tableConfig?.map((config: TableConfig) => ({
      ...(config?.checked &&
        updatedColumns?.find(
          // eslint-disable-next-line no-nested-ternary
          item => item.key === String(config?.field ? (String(config?.id)?.includes('field') ? config?.id : `field_${config?.id}`) : config.id)
        )),
      checked: config ? config?.checked : true
    })) as {
      key: string;
      field: boolean;
      checked: boolean;
      title: string;
      dataIndex: string;
    }[]) || [];

  const realColumns = [
    ...(columnsSort || columns)
      .filter(item => item.title)
      .filter(item => item?.checked || !('checked' in item))
      .map((item, index: number) => ({
        ...item,
        ...(item?.title && {
          title: item?.dataIndex?.includes('field') ? item?.title : t(`home.${item?.title}`)
        })
      })),
    {
      fixed: 'right',
      className: styles.actionColumn,
      key: 'action',
      render: (i: PaymentList, j: any, k: any) => (
        <>
          <div className={styles.edit}>
            {j?.type === 'OTHERS' || j?.note ? (
              <DiscountInfo
                content={
                  <>
                    {j?.note ? (
                      <div className="mb-[20px]">
                        <p className="mr-[5px] text-[14px] font-normal leading-[26px] text-[#8c8c8c]">Izoh:</p>
                        <p>{j?.note}</p>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className="mt-[-10px]">
                      {j?.files?.map((item: { id: number; size: number; originalName: string; absolutePath: string }) => (
                        <div key={item?.id} className="mt-3 flex items-center justify-between rounded-xl bg-[#F9FAFB] p-[16px]" style={{ border: '1px solid #E5E7EB' }}>
                          <div className="flex items-center gap-3">
                            <span className="flex items-center justify-center rounded-[10px] border-[1px] bg-[#EFF1F5] p-[8px]" style={{ border: '1px solid #E5E7EB' }}>
                              <FileIcon />
                            </span>
                            <div>
                              <FileName originalName={item?.originalName} />
                              {/* <p className='text-[#344054] font-medium text-[16px]'>{item?.originalName}{' '}</p> */}
                              <p className="text-[16px] font-medium text-[#98A2B3]">{item?.size ? `${toKilobytes(item?.size)} KB` : 'N/A'}</p>
                            </div>
                          </div>

                          <a
                            href={`${BASE_URL}/${item?.absolutePath}`}
                            className={`flex items-center justify-center rounded-[8px] bg-[#00A389] px-[12px] py-[6px] ${styles.btn_download}`}
                            onClick={e => {
                              const fileUrl = `${BASE_URL}/${item?.absolutePath}`;
                              const fileName = item?.absolutePath.split('/').pop();
                              // @ts-expect-error
                              const fileExtension = fileName.split('.').pop().toLowerCase();
                              const viewableExtensions = ['pdf', 'txt', 'jpg', 'jpeg', 'png'];

                              if (viewableExtensions.includes(fileExtension)) {
                                e.preventDefault();
                                window.open(fileUrl, '_blank', 'noopener,noreferrer');
                              }
                            }}
                          >
                            <div className="flex items-center justify-center gap-[8px]">
                              <DownloadIconV5 />
                              <p className="m-0 text-[14px] text-[#FFFFFF]">{t('home.Yuklab_olish')}</p>
                            </div>
                          </a>
                        </div>
                      ))}
                    </div>
                  </>
                }
                title={t('contract.malumot')}
              >
                <Tooltip overlay={t('contract.malumot')}>
                  <span className={styles.edit_action} style={{ padding: '6px 0' }}>
                    <MessageTextIcon color="rgba(232, 230, 227, 0.8)" size={30} />
                  </span>
                </Tooltip>
              </DiscountInfo>
            ) : (
              ''
            )}

            {i?.canceledBy ? (
              <Tooltip overlay={t('home.Info')}>
                <span
                  className={styles.edit_action}
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    setCommentCanceled({
                      date: i?.canceledDate,
                      employee: i?.canceledBy,
                      text: i?.cancelNote,
                      visible: true
                    });
                  }}
                >
                  <GlphIcon color="transparent" size={30} />
                </span>
              </Tooltip>
            ) : (
              <>
                <Tooltip overlay={t('shaxmatka.Yuklash')}>
                  <span
                    style={{ border: '1px solid transparent' }}
                    className={styles.edit_action}
                    onClick={() => {
                      if (j?.base === 'CONTRACT') {
                        downloadPaymentToWord(j?.contractId, j?.id, j?.number);
                      } else if (j?.base === 'BOOKING') {
                        downloadPaymentBookingToWord(j?.bookingId, j?.id, j?.number);
                      }
                    }}
                  >
                    <ArrowLineDownIcon color="rgba(232, 230, 227, 0.8)" size={30} />
                  </span>
                </Tooltip>
                <Tooltip overlay={t('one_lid.Ochirish')}>
                  <span
                    className={styles.edit_action}
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-use-before-define
                      handleClose(j?.id, j?.contractId);
                    }}
                  >
                    <TrashIcon color="rgba(232, 230, 227, 0.8)" size={30} />
                  </span>
                </Tooltip>
              </>
            )}
          </div>
          {/* {item?.render && item?.render(i, j, k)} */}
        </>
      )
    }
  ];

  // downloadPaymentToWord
  const downloadPaymentToWord = (contractId: number, id: number, number: string) => {
    downloadPayment.mutate({
      id,
      number,
      contractId
    });
  };

  const downloadPaymentBookingToWord = (bookingId: number, id: number, number: string) => {
    downloadPaymentBooking.mutate({
      id,
      number,
      bookingId
    });
  };

  // handleClose
  const handleClose = (id: number, contractId: number) => {
    setDropdown(0);
    setDeletePayment({
      visible: true,
      ids: [id],
      contractId
    });
  };

  // handle delete modal close
  const handleDeleteModalClose = () => {
    setDeletePayment({
      visible: false,
      ids: [],
      contractId: -1
    });
  };

  // changeAllSelect
  const changeAllSelect = () => {
    setSelections([]);
  };

  // handleCloseSelect
  const handleCloseSelect = () => {
    setDeletePayment({
      visible: true,
      ids: [...(selections as number[])],
      contractId: 0
    });
  };

  useEffect(() => {
    setSelections([]);
    let clientLen = 0;
    let createByLen = 0;
    let numberLen = 0;

    data?.forEach(item => {
      if (clientLen < item?.clientFullName?.length) {
        clientLen = item?.clientFullName?.length;
      }
      if (createByLen < item?.createdBy?.length) {
        createByLen = item?.createdBy?.length;
      }
      if (numberLen <= item?.number?.length) {
        numberLen = item?.number?.length;
      }
    });
    setNumberWidth(numberLen * 9);
    setClientLenWidth(clientLen * 9);
    setCreateByLenWidth(createByLen * 9);
  }, [data]);

  return (
    <>
      {selections?.length > 0 && (
        <div className={styles.container_table_header}>
          <Checkbox onChange={changeAllSelect} checked>
            {selections?.length} ta belgilangan
          </Checkbox>
          <span className={styles.container_table_header_trush} onClick={handleCloseSelect}>
            <TrushIcon color="white" size={24} />
          </span>
        </div>
      )}
      <Table
        id="paymentHistoryTable"
        // eslint-disable-next-line no-nested-ternary
        dataSource={contractId ? (Number(contractId) ? arr : []) : arr}
        columns={realColumns as any}
        pagination={false}
        scroll={{
          y: `calc(100vh - ${offsetTop - (selections?.length > 0 ? 44 : 0)}px)`,
          x: 'max-content'
        }}
        className={styles.container_table}
        loading={loading || tableConfigFetch}
        rowSelection={{
          type: 'checkbox',
          onChange(selectedRowKeys, selectedRows, info) {
            setSelections(selectedRowKeys);
          },
          selectedRowKeys: [...selections]
        }}
        rowClassName={styles.tableRow}
        showHeader={!(selections?.length > 0)}
        rowKey={prev => prev.id}
        ref={ref => {
          setOffsetTop((ref?.getClientRects()[0].y || 0) + 130);
        }}
      />
      <PaymentDeleteModal contractId={deletePayment.contractId} visible={deletePayment.visible} onCancel={handleDeleteModalClose} ids={deletePayment.ids} />
      <ContractIdCommentModal comment={commentCanceled} setComment={setCommentCanceled} />
    </>
  );
};

export default PaymentHistoryTable;
