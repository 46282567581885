import { Input } from 'antd';
import React from 'react';
import SearchIcon from '../../assets/icons/SearchIcon';
import { useQueryParam } from '../../hooks/useQueryParams';
import styles from '../filters/mainFilters.module.scss';
import { SearchParamsCompanies } from '../../pages/companies/Companies';

const CompanyEmployeesFilter = () => {
  const { searchParams, remove, appendSingle } = useQueryParam<SearchParamsCompanies, any>();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setTimeout(() => {
      if (e.target.value) appendSingle('search', e.target.value);
      else remove('search', undefined);
    });
  };

  return (
    <div>
      <Input
        suffix={<SearchIcon />}
        onChange={handleSearch}
        defaultValue={searchParams.search}
        placeholder={'Qidirish'}
        className={styles.developer_search}
      />
    </div>
  );
};

export default CompanyEmployeesFilter;
