import MainHeader from '@components/headers/MainHeader';
import BreadcrumbHeader from '@components/headers/components/BreadcrumbHeader';
import React, { useState } from 'react';
import ShablonHeader from './components/shablon-header/ShablonHeader';
import ShablonTable from './components/shablon-table/ShablonTable';
import CreateShablon from './components/create-shablon/CreateShablon';

const VoronkaShablon = () => {
  const [visible, setVisible] = useState(false);
  const [shablonId, setShablonId] = useState<number>();
  return (
    <div>
      <MainHeader>
        <BreadcrumbHeader />
      </MainHeader>
      <div className="flex flex-col gap-4 p-5">
        <ShablonHeader setVisible={setVisible} />
        <ShablonTable setVisible={setVisible} setShablonId={setShablonId} />
      </div>
      <CreateShablon visible={visible} setVisible={setVisible} setShablonId={setShablonId} shablonId={shablonId} />
    </div>
  );
};

export default VoronkaShablon;
