import React, { useEffect, useRef } from 'react';
import { Form, Select, Spin, TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './myCalls.module.scss';
import LogoutIconMessengerIcon from '../../../../../assets/icons/LogoutIconMessengerIcon';
import { MyCalls } from '../../../../../utils/models/MyCalls';
import { useMyCallsEmployee, usePipeAll } from '../../../../../queries/queries';
import { useDeleteMyCalls, useMyCallsSetEmployee } from '../../../../../queries/mutation';
import ArrowDownIcon from '../../../../../assets/icons/ArrowDownIconCrm';
import SidebarArrowRightIcon from '../../../../../assets/icons/SidebarArrowRightIcon';

const { Option } = Select;

interface Props {
  data: MyCalls;
}

const EditMyCall: React.FC<Props> = ({ data }) => {
  const [form] = Form.useForm();
  const { data: employee, isLoading } = useMyCallsEmployee();

  const selectedEmployee = useRef(0);

  const { data: pipeAll } = usePipeAll();

  const deleteMyCalls = useDeleteMyCalls(data.id);
  const setEmployeeMutation = useMyCallsSetEmployee();

  const { t } = useTranslation();

  useEffect(() => {
    if (data.employees) {
      let values = {};
      for (let i = 0; i < data.employees.length; i++) {
        values = {
          ...values,
          [`status${i}`]: data.employees[i].pipeStatusResponse?.id
        };
      }
      form.setFieldsValue(values);
    }
  }, [data, form]);

  // handle delete my calls
  const handleDeleteMyCalls = () => {
    deleteMyCalls.mutate();
  };

  // set employee
  const setEmployee = (id: number, email: string, index: number, pipeStatusId: number) => {
    selectedEmployee.current = index;
    setEmployeeMutation.mutate({
      employeeId: id,
      email,
      moiZId: data.id,
      pipeStatusId
    });
  };

  return (
    <Spin spinning={deleteMyCalls.isLoading || isLoading}>
      <Form layout="vertical" name={'myCalls'} form={form}>
        <div className={styles.container_body_item}>
          <span>{t('my_calls.API_manzilingiz')}</span>
          <div className={styles.container_body_item_name}>
            <p>{data.domain}</p>
            <div onClick={handleDeleteMyCalls} className={styles.container_body_item_name_log_out}>
              <LogoutIconMessengerIcon />
              <span>{t('my_calls.Chiqish')}</span>
            </div>
          </div>
        </div>
        <hr />
        <p className={styles.container_body_sub_title}>Xodimlarni sozlash</p>
        <p className={styles.container_body_information}>
          {t('my_calls.Moi_zvonki_platformasi_va_Uysot_platformasidagi_foydalanuvchilarni_birlashtirish')}
        </p>
        {data.employees.map((emp, index) => (
          <Spin spinning={setEmployeeMutation.isLoading && selectedEmployee.current === index}>
            <div key={index}>
              <div className={styles.container_body_item}>
                <span>{emp.fullName}</span>
                <Select
                  placeholder={t('home.Tanlang')}
                  optionFilterProp={'children'}
                  showSearch
                  onChange={id => setEmployee(id, emp.email, index, Number(emp.pipeStatusResponse?.id))}
                  showArrow={false}
                  value={emp.employee?.id}
                  className={'customSelect ' + styles.container_body_item_select}
                >
                  {employee?.map((item, index) => (
                    <Option value={item.id} key={index}>
                      {item.fullName}
                    </Option>
                  ))}
                </Select>
                <Form.Item name={`status${index}`} noStyle>
                  <TreeSelect
                    placeholder={t('home.Tanlang')}
                    onChange={e => setEmployee(emp.employee.id, emp.email, index, e)}
                    showArrow={false}
                    treeExpandAction={'click'}
                    switcherIcon={<SidebarArrowRightIcon />}
                    suffixIcon={<ArrowDownIcon />}
                    className={styles.container_body_item_tree}
                    dropdownClassName="treeMyCals"
                  >
                    {pipeAll?.map(item => (
                      <TreeSelect.TreeNode
                        title={item.name}
                        value={item.id}
                        key={item.id}
                        selectable={false}
                        children={item.pipeStatuses.map(pipe => (
                          <TreeSelect.TreeNode
                            key={pipe?.id}
                            value={pipe.id!}
                            title={
                              <div
                                //@ts-ignore
                                pageName={item.name}
                              >
                                <span
                                  style={{
                                    background: pipe.color,
                                    color: '#FFFFFF',
                                    padding: '2px 7px',
                                    borderRadius: '5px',
                                    fontSize: 10
                                  }}
                                >
                                  {pipe.name}
                                </span>
                              </div>
                            }
                          />
                        ))}
                      />
                    ))}
                  </TreeSelect>
                </Form.Item>
              </div>
              <hr />
            </div>
          </Spin>
        ))}
      </Form>
    </Spin>
  );
};

export default EditMyCall;
