import React from 'react';

const WebsiteUserIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 4H16.5C17.12 4 17.67 4.02 18.16 4.09C20.79 4.38 21.5 5.62 21.5 9V15C21.5 18.38 20.79 19.62 18.16 19.91C17.67 19.98 17.12 20 16.5 20H7.5C6.88 20 6.33 19.98 5.84 19.91C3.21 19.62 2.5 18.38 2.5 15V9C2.5 5.62 3.21 4.38 5.84 4.09C6.33 4.02 6.88 4 7.5 4Z"
        stroke="#00A389"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13.5 10H17" stroke="#00A389" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 15.5H7.02H17" stroke="#00A389" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.0946 10H10.1036" stroke="#00A389" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.0946 10H7.10359" stroke="#00A389" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );

export default WebsiteUserIcon;
