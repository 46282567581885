import { Button, Form, Input } from 'global';

const Test = () => (
  <div className="p-[64px]">
    <Form
      onFinish={value => {
        console.log(value, 'values');
      }}
      values={{
        name: 'MRX',
        age: 18,
        sum: 21312
      }}
    >
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="Age" name="age">
        <Input type="number" />
      </Form.Item>
      <Form.Item label="Summa" name="sum">
        <Input
          type="currency"
          returnType="number"
          onChange={v => {
            console.log(v);
          }}
        />
      </Form.Item>
      <Button htmlType="submit" type="primary">
        Saqlash
      </Button>
    </Form>
  </div>
);

export default Test;
