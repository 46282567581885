import React, { useEffect, useState } from 'react';
import { Permissions } from '@utils/constants/permissions';
import { Button, Form, Grid, Input, message, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import CalendarIcon from '../../assets/icons/CalendarIcon';
import CancelBronGarbage from '../../assets/icons/CancelBronGarbage';
import DocumentCopyIcon from '../../assets/icons/DocumentCopyIcon';
import DocumentNormalIcon from '../../assets/icons/DocumentNormalIcon';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { changeOnePaymentAmount, changeOnePaymentDate, deleteItemTable, setMonthlyPaymentPartDtos } from '../../store/reducers/CreateContractSlice';
import { helper } from '../../utils/helper/helper';
import { validateDateString } from '../../utils/helper/validatorDateString';
import { MonthlyPayment } from '../../utils/models/CreateContract';
import PaginationCont from '../pagenation/PaginationCont';

import Buttons from './Buttons';
import Cards from './Cards';

import styles from '../../pages/createTablePayments/createTablePayments.module.scss';

const TableMonthlyPayments = () => {
  const [copiedValue, setCopiedValue] = useState<null | string>();
  const [currentPage, setCurrentPage] = useState(1);
  const permissions = useAppSelector(state => state.userReducer?.user?.role?.permissions);

  const isMultiPayment = permissions?.some(p => p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT || p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT);
  const { xxl } = Grid.useBreakpoint();
  const { t } = useTranslation();
  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  const [form] = Form.useForm();

  const dispatch = useAppDispatch();
  const monthlyPaymentPartDtos = useAppSelector(state => state.createContract.monthlyPaymentPartDtos);
  const currency = useAppSelector(state => state.createContract.currency);

  useEffect(() => {
    if (monthlyPaymentPartDtos) {
      monthlyPaymentPartDtos.forEach((item: MonthlyPayment, index) => {
        form.setFieldsValue({
          [index]: item.date
        });
      });
    }
  }, [monthlyPaymentPartDtos, form]);

  useEffect(() => {
    dispatch(setMonthlyPaymentPartDtos({ isMultiPayment }));
  }, []);

  const handleChangeDate = (index: number) => {
    dispatch(
      changeOnePaymentDate({
        date: form.getFieldValue(index),
        index
      })
    );
  };

  const handleCopy = (value: string) => {
    message.success(t('contract_table_update.Nusxa_olindi'));
    setCopiedValue(value);
  };

  const pasteValue = (index: number) => {
    if (copiedValue) {
      dispatch(
        changeOnePaymentAmount({
          index: (currentPage - 1) * 10 + index,
          amount: copiedValue
        })
      );
    } else {
      message.error(t('contract_table_update.Nusxa_olinmagan'));
    }
  };

  const handleChangeAmount = (value: string, index: number) => {
    const amount = helper.deleteNotNumbersAndParseFloatNumber(value).string;

    dispatch(changeOnePaymentAmount({ amount, index: (currentPage - 1) * 10 + index, isMultiPayment }));
  };

  const handleDelete = (index: number) => {
    dispatch(deleteItemTable({ index, isMultiPayment }));
  };

  const columns = [
    {
      title: '№',
      width: xxl ? 100 : 100 * 0.81,
      render: (text: any, record: MonthlyPayment, index: number) => currentPage * 10 - 9 + index
    },
    {
      title: t('contract_table_update.Tolov_qilish_sanasi'),
      render: (text: any, record: MonthlyPayment, index: number) => (
        <Form.Item
          noStyle
          rules={[
            {
              validator: (rule, value) => {
                if (value) {
                  form.setFieldsValue({
                    [currentPage * 10 - 10 + index]: validateDateString(value, true)
                  });
                }
                return Promise.resolve();
              }
            },
            {
              required: true,
              len: 10,
              message: ''
            }
          ]}
          name={(currentPage - 1) * 10 + index}
          className="changesInput"
        >
          <InputMask
            onChange={e => {
              if (e.target.value.length === 10) handleChangeDate((currentPage - 1) * 10 + index);
            }}
            mask="99.99.9999"
            // @ts-ignore*
            maskChar=""
          >
            {/* @ts-ignore */}
            {(inputProps: any) => (
              <Input style={{ width: '150px' }} className="customInput" placeholder={t('changes.contract.kk.oo.yyyy')} suffix={<CalendarIcon />} {...inputProps} />
            )}
          </InputMask>
        </Form.Item>
      )
    },
    {
      title: `${t('contract_table_update.Qolgan_summa_som')} (${currency?.ccy})`,
      render: (item: MonthlyPayment) => (
        <Input
          disabled={true}
          value={item?.sum?.toLocaleString('ru')}
          style={{
            textAlign: 'center',
            borderRadius: xxl ? '6px' : '6px*0.81',
            width: xxl ? 135 : 135 * 0.81
          }}
        />
      )
    },
    {
      title: `${t('home.Tolov_summasi_sum')} (${currency?.ccy})`,
      render: (text: any, record: MonthlyPayment, index: number) => (
        <Input
          onChange={e => handleChangeAmount(e.target.value, index)}
          value={record.amountString}
          style={{
            textAlign: 'center',
            borderRadius: xxl ? '6px' : '6px*0.81',
            width: xxl ? 135 : 135 * 0.81,
            borderColor: record.amount ? 'var(--bright-gray)' : 'var(--radical-red)'
          }}
        />
      )
    },
    {
      title: t('contract_table_update.Amallar'),
      render: (text: any, record: MonthlyPayment, index: number) => (
        <div>
          <Button
            onClick={() => handleCopy(record.amountString)}
            title={t('contract_table_update.nusxa_olish')}
            shape="circle"
            style={{
              border: 'none',
              marginRight: xxl ? 20 : 20 * 0.81
            }}
          >
            <DocumentCopyIcon />
          </Button>
          <Button onClick={() => pasteValue(index)} title={t('contract_table_update.Joylash')} shape="circle" style={{ border: 'none', marginRight: xxl ? 20 : 20 * 0.81 }}>
            <DocumentNormalIcon />
          </Button>
          <Button onClick={() => handleDelete(currentPage * 10 - 10 + index)} title={t('contract_table_update.Ochirish')} shape="circle" style={{ border: 'none' }}>
            <CancelBronGarbage />
          </Button>
        </div>
      )
    }
  ];

  return (
    <div>
      <Form form={form} name="create_table_payments">
        <div className={styles.header_table}>
          <Cards />
          <Buttons />
        </div>
        <Table
          className="custom_table contract_table"
          columns={columns}
          dataSource={monthlyPaymentPartDtos?.slice((currentPage - 1) * 10, currentPage * 10) || []}
          rowKey="sum"
          size="small"
          pagination={false}
          style={{ marginBottom: xxl ? 16 : 16 * 0.81 }}
          scroll={{ y: 'calc(100vh - 300px)' }}
        />
        <PaginationCont
          total={monthlyPaymentPartDtos?.length || 0}
          totalPages={Math.ceil((monthlyPaymentPartDtos?.length || 0) / 10) || 0}
          changePage={changePage}
          currentPage={currentPage}
          defaultPageSize={10}
        />
      </Form>
    </div>
  );
};

export default TableMonthlyPayments;
