import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import TrushCrmIcon from '@icons/crm/TrushCrmIcon';
import LeadUpdateIcon from '@icons/LeadUpdateIcon';
import { useDeleteLead } from '@queries/mutation';
import { setChangeNewLeadData } from '@reducers/ClientSlice';
import { ClientLeadModel } from '@utils/models/ClientLeadModel';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './client.module.scss';

type Props = {
  currentClientLead: ClientLeadModel;
  setSelectLead: React.Dispatch<React.SetStateAction<ClientLeadModel | undefined>>;
  isCliendLead: boolean;
};

const ClientLead: React.FC<Props> = ({ currentClientLead, setSelectLead, isCliendLead }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { searchParams } = useQueryParam<any, any>();
  const { leadContact, leadName, leadContactName } = useAppSelector(state => state.clientReducer);
  const deleteLead = useDeleteLead();
  const [checkStandart, setCheckStandart] = useState(false);
  const clientId = searchParams?.clientId;

  const handleDelete = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    currentClientLead?.isDefaultCreate && deleteLead.mutate(currentClientLead?.id);
  };

  // console.log(currentClientLead)

  useEffect(() => {
    dispatch(
      setChangeNewLeadData({
        contact: currentClientLead?.contacts[0]?.phones[0],
        contactName: currentClientLead?.contacts[0]?.name,
        name: currentClientLead?.name
      })
    );
  }, [currentClientLead]);

  return (
    <div className={styles.currentLead}>
      <div className={styles.currentLead_left}>
        <div className={styles.currentLead_left_item}>
          <span className={styles.title}>{t('home.Lid_nomi')}</span>
          <>
            {checkStandart ? (
              <Input
                value={leadName}
                onChange={e =>
                  dispatch(
                    setChangeNewLeadData({
                      name: e.target.value
                    })
                  )
                }
              />
            ) : (
              <span>{currentClientLead?.name}</span>
            )}
          </>
        </div>
        {currentClientLead?.contacts[0] && (
          <div className={styles.currentLead_left_item}>
            <span className={styles.title}>{t('one_lid.Kontakt_nomi')}</span>
            <>
              {checkStandart ? (
                <Input
                  value={leadContactName}
                  onChange={e => {
                    dispatch(
                      setChangeNewLeadData({
                        contactName: e.target.value
                      })
                    );
                  }}
                />
              ) : (
                <span>{currentClientLead?.contacts[0]?.name}</span>
              )}
            </>
          </div>
        )}
        {currentClientLead?.contacts[0]?.phones[0] && (
          <div className={styles.currentLead_left_item}>
            <span className={styles.title}>Kantakt</span>
            <>
              {checkStandart ? (
                <Input
                  value={leadContact}
                  onChange={e =>
                    dispatch(
                      setChangeNewLeadData({
                        contact: e.target.value
                      })
                    )
                  }
                />
              ) : (
                <span>{currentClientLead?.contacts[0]?.phones[0]}</span>
              )}
            </>
          </div>
        )}
        {currentClientLead?.tags && currentClientLead?.tags.length > 0 && (
          <div className={styles.currentLead_left_item}>
            <span className={styles.title}>Tag</span>
            <div className={styles.currentLead_left_item_tag}>
              {currentClientLead?.tags && currentClientLead?.tags?.slice(0, 5)?.map(item => <span key={item?.id}>{item?.name}</span>)}
              {currentClientLead?.tags?.length > 5 && '...'}
            </div>
          </div>
        )}
        {currentClientLead?.responsibleBy && (
          <div className={styles.currentLead_left_item}>
            <span className={styles.title}>{t('crm.assignedBy')}</span>
            <span>{currentClientLead?.responsibleBy}</span>
          </div>
        )}
        <div className={styles.currentLead_left_item}>
          <span className={styles.title}>{t('crm.BITIMLAR')?.toLowerCase()}</span>
          <div className={styles.currentLead_left_item_pipeline}>
            <div>{currentClientLead?.pipeStatusResponse?.pipeName}</div>
            <div
              style={{
                backgroundColor: currentClientLead?.pipeStatusResponse?.color
              }}
            >
              {currentClientLead?.pipeStatusResponse?.name}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.currentLead_right}>
        <div
          className={styles.currentLead_right_icon}
          onClick={() => {
            setSelectLead(undefined);
            handleDelete();
          }}
          style={{
            cursor: currentClientLead?.client !== null || isCliendLead ? 'no-drop' : 'pointer'
          }}
        >
          <TrushCrmIcon />
        </div>
        <div
          className={styles.currentLead_right_icon}
          onClick={() => currentClientLead?.isDefaultCreate && setCheckStandart(prev => !prev)}
          style={{
            cursor: currentClientLead?.isDefaultCreate ? 'pointer' : 'no-drop'
          }}
        >
          <LeadUpdateIcon />
        </div>
      </div>
    </div>
  );
};

export default ClientLead;
