import { useQuery } from 'react-query';
import { queryKeys } from '../utils/constants/queryKeys';
import { $api } from '@service/RequestService';
import { endPoints } from '../utils/constants/endPoints';
import { baseErrorHandler } from '@queries/baseErrorHandler';
import { PaymentField } from '../utils/models/PaymentField';

export function usePaymentFieldGet() {
  return useQuery<PaymentField[]>(
    [queryKeys.PAYMENT_FIELD],
    async () => {
      const res = await $api.get(endPoints.PAYMENT_FIELD);
      return res.data.data!;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (err: any) => {
        baseErrorHandler(err);
      }
    }
  );
}
