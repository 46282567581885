import React from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import styles from '../../../../pages/companyEmployeeRoles/companyEmployeeRoles.module.scss';

type Props = {
  newColumns: ColumnsType<any>;
  groupedData: any;
};

const NextPermissions: React.FC<Props> = ({ newColumns, groupedData }) => (
  <Table
    columns={newColumns}
    dataSource={groupedData || []}
    pagination={false}
    scroll={{ x: 1300, y: '76vh' }}
    className={styles.lastTableStyle}
    expandable={{ defaultExpandedRowKeys: groupedData?.map((item: any) => item?.type) }}
  />
);

export default NextPermissions;
