import React, { useEffect, useState } from 'react';
import { FlatId } from '@utils/models/Flat';
import { Button, Form, Input, Modal, Select, Switch } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import ReactInputMask from 'react-input-mask';

import { useAppSelector } from '../../../../../hooks/reduxHooks';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { useContractDownloadPdf, useContractDownloadPdfV2 } from '../../../../../queries/mutation';
import { useCompanyInformationForPdf, useCompanyLogo, useContractEmployee } from '../../../../../queries/queries';
import DatePicker from '../../../../../service/datePicker';
import i18n from '../../../../../service/I18nService';
import { DATE_WITH_TIME_FORMAT } from '../../../../../utils/constants/format';
import { roundFloatNumber } from '../../../../../utils/helper/roundFloatNumber';
import { FlatDiscount } from '../../../../../utils/models/Discount';
import Navbar from '../navbar/Navbar';

import CenterBasic from './CenterBasic';

import styles from './index.module.scss';

const { Option } = Select;

interface IProps {
  handleCancel: () => void;
  idFlats?: number[];
  visible?: boolean;
  beta2?: boolean;
}

const MainPage: React.FC<IProps> = ({ handleCancel, idFlats, visible, beta2 }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [target, setTarget] = useState(['']);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sendImages, setSendImages] = useState([[0]]);

  const [getTableData, setgetTableData] = useState<FlatDiscount[]>();

  const [form] = Form.useForm();
  const { data: employee } = useContractEmployee();
  const { totalAmount, discountSum, prePayment, delay, currency } = useAppSelector(state => state.createContract);
  const flats = useAppSelector(state => state.createContract);
  const { params } = useQueryParam<{ idFlat: string }, null>();
  const { data } = useCompanyInformationForPdf(idFlats || params?.idFlat?.split('=')[1]?.split(',') || []);
  const { data: companyLogo } = useCompanyLogo();
  const flatData = data && data[currentIndex];
  const rightData = flatData?.flatDto?.flat;
  const houseLogo = flatData?.houseDTO?.gallery?.find(item => item?.position === 'HOUSE_LOGO');

  const discount = roundFloatNumber((discountSum / totalAmount) * 100);
  const endPayment = roundFloatNumber(totalAmount - discountSum);

  const [updateValues, setUpdateValues] = useState({
    xodim: flatData?.flatDto?.flat?.createdBy,
    nomer: flatData?.builderDTO?.phone,
    sana: dayjs().format('DD.MM.YYYY, HH:mm'),
    ofis: `${flatData?.builderDTO?.city?.en}, ${flatData?.builderDTO?.districtDTO?.uz}, ${flatData?.builderDTO?.address}`,
    call_center: flatData?.builderDTO?.callCenterPhone
  });

  const { t } = useTranslation();

  const handleClose = () => {
    setIsOpenModal(false);
  };
  const handleOpen = () => {
    setIsOpenModal(true);
  };

  const onFinish = (values: any) => {
    setUpdateValues({
      xodim: values.xodim,
      nomer: values.nomer,
      sana: dayjs(values.sana).format('DD.MM.YYYY HH:mm'),
      ofis: values.ofis,
      call_center: values.call_center
    });

    setIsOpenModal(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      xodim: flatData?.flatDto?.flat?.createdBy,
      nomer: flatData?.builderDTO?.phone.substring(4, 13),
      sana: dayjs(),
      ofis: `${flatData?.builderDTO?.city?.en}, ${flatData?.builderDTO?.districtDTO?.uz}, ${flatData?.builderDTO?.address}`,
      call_center: flatData?.builderDTO?.callCenterPhone.substring(4, 13)
    });
    // eslint-disable-next-line
  }, []);

  const handleClick = (slideIndex: number) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    setCurrentIndex(0);
    if (data && data?.length > 0) {
      setTarget(Array.from({ length: data.length }).map((a, i) => '1'));
    }
    if (flats && flats.flats?.length > 0) {
      setSendImages(flats.flats?.map(item => [item?.flat && item?.flat?.gallery && item?.flat?.gallery[0]?.id ? item?.flat?.gallery[0]?.id : 0]));
    }
  }, [data, flats]);

  const hasTerracceArea = (flat: FlatId) => {
    if (flat?.terrace?.area) {
      if (flat?.repaired) {
        return flat?.pricePerAreaRepaired === flat?.terrace?.pricePerAreaRepaired;
      }
      return flat?.pricePerArea === flat?.terrace?.pricePerArea;
    }
    return false;
  };

  const sendDownloadPdf = useContractDownloadPdf();
  const sendDownloadPdfV2 = useContractDownloadPdfV2();
  const contractPdf = () => {
    if (beta2) {
      sendDownloadPdfV2.mutate(
        flats?.flats && [
          ...flats?.flats?.map((flats, i) => {
            const area = Number(flats?.flat?.area) + (hasTerracceArea(flats?.flat) ? flats?.flat?.terrace?.area : 0);
            const oncePleceAmount = endPayment / (Number(flats?.flat?.area) + (hasTerracceArea(flats?.flat) ? flats?.flat?.terrace?.area : 0));

            return {
              apartment: flats?.flat?.apartment || null,
              builderAddress:
                updateValues?.ofis === `${flatData?.builderDTO?.city?.en}, ${flatData?.builderDTO?.districtDTO?.en}, ${flatData?.builderDTO?.address}`
                  ? `${flatData?.builderDTO?.city?.en}, ${flatData?.builderDTO?.districtDTO?.en}, ${flatData?.builderDTO?.address}`
                  : updateValues?.ofis,
              builderCallCenterPhone:
                updateValues?.call_center === flatData?.builderDTO?.callCenterPhone ? updateValues?.call_center || null : `+998 ${updateValues?.call_center}` || null,
              buildingName: flats?.flat?.building?.name || null,
              clientContractDelay: delay || null,
              clientDiscountAmount: flats?.discountAmount,
              clientDiscountPercent: discount,
              clientMonthlyPayment: roundFloatNumber((flats?.totalAmount - (flats?.discountAmount || 0) - prePayment) / (delay || 1)),
              clientPerAreaPrice: oncePleceAmount,
              clientPrepayment: flats?.firstPayment,
              clientSaleAmount: endPayment,
              companyLogo: houseLogo ? houseLogo?.files?.absolutePath : companyLogo?.absolutePath || null,
              employee: checked ? updateValues?.xodim || null : null,
              employeePhone: checked ? `+998${updateValues?.nomer || null}` : null,
              entrance: flats?.flat?.entrance || null,
              entranceName: flats?.flat?.entranceName || null,
              facebookUrl: flatData?.houseDTO?.facebook ? flatData?.houseDTO?.facebook : flatData?.comConfRes?.urls?.find(item => item.key === 'COMPANY_FACEBOOK')?.value || '',
              flatNumber: flats?.flat?.number || null,
              flatPrice: flats?.totalAmount || null,
              flatRepaired: flats?.repaired,
              flatRooms: flats?.flat?.rooms || null,
              flatTableList: getTableData?.map(flats => ({
                discount: flats?.discountAmount || 0,
                discountPercent: flats?.discount === 0 ? 0 : flats?.discount || 0,
                prepayment: flats?.firstPayment,
                delay: flats?.month,
                monthlyPayment: flats?.monthlyPayment,
                prepaymentPercent: flats?.percent,
                saleAmount: flats?.total,
                amountPerArea: roundFloatNumber(flats?.total / area)
              }))!,
              flatTerraceArea: rightData?.terrace?.area || 0,
              flatTotalArea: flats?.flat?.area || null,
              floor: flats?.flat?.floor || null,
              floorPlanImage: flats?.flat?.gallery?.find(item => item.position === 'FLAT_FLOOR')?.files.absolutePath || null,
              flatImages: sendImages[i]?.map(item => flats?.flat?.gallery?.find(g => g?.id === item)?.files?.absolutePath).filter(item => item),
              houseAddress: `${flatData?.houseDTO?.city?.en}, ${flatData?.houseDTO?.district?.en}, ${flatData?.houseDTO?.address}`,
              houseName: flats?.flat?.houseName || null,
              instagramUrl: flatData?.houseDTO?.instagram
                ? flatData?.houseDTO?.instagram
                : flatData?.comConfRes?.urls?.find(item => item.key === 'COMPANY_INSTAGRAM')?.value || null,
              telegramUrl: flatData?.houseDTO?.telegram ? flatData?.houseDTO?.telegram : flatData?.comConfRes?.urls?.find(item => item.key === 'COMPANY_TELEGRAM')?.value || null,
              webSiteUrl: flatData?.houseDTO?.website ? flatData?.houseDTO?.website : flatData?.comConfRes?.urls?.find(item => item.key === 'COMPANY_WEBSITE')?.value || null,
              offerDatatime: dayjs().format(DATE_WITH_TIME_FORMAT),
              currency: currency?.ccy || '',
              lang: i18n.language.toUpperCase().slice(0, 2),
              fields: flats?.flat?.fields
            };
          })
        ]
      );
    } else {
      sendDownloadPdf.mutate(
        flats?.flats && [
          ...flats?.flats?.map((flats, i) => {
            const oncePleceAmount = endPayment / (Number(flats?.flat?.area) + (hasTerracceArea(flats?.flat) ? flats?.flat?.terrace?.area : 0));

            return {
              apartment: flats?.flat?.apartment || null,
              builderAddress:
                updateValues?.ofis === `${flatData?.builderDTO?.city?.en}, ${flatData?.builderDTO?.districtDTO?.en}, ${flatData?.builderDTO?.address}`
                  ? `${flatData?.builderDTO?.city?.en}, ${flatData?.builderDTO?.districtDTO?.en}, ${flatData?.builderDTO?.address}`
                  : updateValues?.ofis,
              builderCallCenterPhone:
                updateValues?.call_center === flatData?.builderDTO?.callCenterPhone ? updateValues?.call_center || null : `+998 ${updateValues?.call_center}` || null,
              buildingName: flats?.flat?.building?.name || null,
              clientContractDelay: delay || null,
              clientDiscountAmount: flats?.discountAmount,
              clientDiscountPercent: discount,
              clientMonthlyPayment: roundFloatNumber((flats?.totalAmount - (flats?.discountAmount || 0) - prePayment) / (delay || 1)),
              clientPerAreaPrice: oncePleceAmount,
              clientPrepayment: flats?.firstPayment,
              clientSaleAmount: endPayment,
              companyLogo: houseLogo ? houseLogo?.files?.absolutePath : companyLogo?.absolutePath || null,
              employee: checked ? updateValues?.xodim || null : null,
              employeePhone: checked ? `+998${updateValues?.nomer || null}` : null,
              entrance: flats?.flat?.entrance || null,
              entranceName: flats?.flat?.entranceName || null,
              facebookUrl: flatData?.houseDTO?.facebook ? flatData?.houseDTO?.facebook : flatData?.comConfRes?.urls?.find(item => item.key === 'COMPANY_FACEBOOK')?.value || '',
              flatNumber: flats?.flat?.number || null,
              flatPrice: flats?.totalAmount || null,
              flatRepaired: flats?.repaired,
              flatRooms: flats?.flat?.rooms || null,
              flatTableList: getTableData?.map(flats => ({
                discount: flats?.discountAmount || 0,
                discountPercent: flats?.discount === 0 ? 0 : flats?.discount || 0,
                prepayment: flats?.firstPayment,
                delay: flats?.month,
                monthlyPayment: flats?.monthlyPayment,
                prepaymentPercent: flats?.percent,
                saleAmount: flats?.total
              }))!,
              flatTerraceArea: rightData?.terrace?.area || 0,
              flatTotalArea: flats?.flat?.area || null,
              floor: flats?.flat?.floor,
              floorPlanImage: flats?.flat?.gallery?.find(item => item.position === 'FLAT_FLOOR')?.files.absolutePath || null,
              flatImages: sendImages[i]?.map(item => flats?.flat?.gallery?.find(g => g?.id === item)?.files?.absolutePath).filter(item => item),
              houseAddress: `${flatData?.houseDTO?.city?.en}, ${flatData?.houseDTO?.district?.en}, ${flatData?.houseDTO?.address}`,
              houseName: flats?.flat?.houseName || null,
              instagramUrl: flatData?.houseDTO?.instagram
                ? flatData?.houseDTO?.instagram
                : flatData?.comConfRes?.urls?.find(item => item.key === 'COMPANY_INSTAGRAM')?.value || null,
              telegramUrl: flatData?.houseDTO?.telegram ? flatData?.houseDTO?.telegram : flatData?.comConfRes?.urls?.find(item => item.key === 'COMPANY_TELEGRAM')?.value || null,
              webSiteUrl: flatData?.houseDTO?.website ? flatData?.houseDTO?.website : flatData?.comConfRes?.urls?.find(item => item.key === 'COMPANY_WEBSITE')?.value || null,
              offerDatatime: dayjs().format(DATE_WITH_TIME_FORMAT),
              currency: currency?.ccy || '',
              lang: i18n.language.toUpperCase().slice(0, 2),
              fields: flats?.flat?.fields
            };
          })
        ]
      );
    }
  };

  return (
    <>
      <div className={styles.container}>
        <Navbar
          handleCancel={handleCancel}
          target={target}
          setTarget={setTarget}
          contractPdf={contractPdf}
          currentIndex={currentIndex}
          setSendImages={setSendImages}
          flats={flats?.flats}
          sendImages={sendImages}
          sendDownloadPdf={sendDownloadPdf}
        />

        {flats?.flats &&
          flats?.flats?.map((item, index) => (
            // eslint-disable-next-line react/jsx-key
            <CenterBasic
              item={item}
              flatData={flatData}
              target={target}
              checked={checked}
              handleOpen={handleOpen}
              updateValues={updateValues}
              currentIndex={currentIndex}
              index={index}
              setSendImages={setSendImages}
              sendImages={sendImages}
              setgetTableData={setgetTableData}
              beta2={beta2}
            />
          ))}

        <div className={styles.footer}>
          {data && data?.length > 1
            ? data?.map((item, index) => (
                <h3 className={currentIndex === index ? styles.active : styles.inactive} key={item?.companyId} onClick={() => handleClick(index)}>
                  {index + 1}
                </h3>
              ))
            : ''}
        </div>
      </div>
      <div>
        <Modal className={styles.updateModal} onCancel={handleClose} visible={isOpenModal} footer={false}>
          <Form onFinish={onFinish} form={form} layout="vertical">
            <Form.Item label={t(`home_id.Sotuv_ofisi_manzili`)} name="ofis">
              <Input />
            </Form.Item>
            <Form.Item label={t`home_id.Call_center_markazi`} name="call_center">
              <ReactInputMask
                mask="99 999 99 99"
                // @ts-ignore*
                maskChar=""
              >
                {/* @ts-ignore */}
                {(inputProps: any) => <Input className="customInput" placeholder="XX XXX XX XX" addonBefore="+998" {...inputProps} />}
              </ReactInputMask>
            </Form.Item>
            <div>
              <Form.Item
                label={
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <p>{t(`contract_id.Masul_xodim`)}</p>
                    <Switch checked={checked} onChange={() => setChecked(!checked)} />
                  </div>
                }
                name="xodim"
              >
                <Select placeholder="F.I.SH" disabled={!checked}>
                  {employee?.map(item => (
                    <Option key={item?.id} value={item?.fullName}>
                      {item?.fullName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label={t(`contract_id.Masul_xodim_telefon_raqami`)} className={checked ? 'currentInput' : 'checkedInput'} name="nomer">
                <ReactInputMask
                  onKeyDown={e => !checked && e.preventDefault()}
                  mask="99 999 99 99"
                  // @ts-ignore*
                  maskChar=""
                >
                  {/* @ts-ignore */}
                  {(inputProps: any) => <Input placeholder="XX XXX XX XX" addonBefore="+998" {...inputProps} />}
                </ReactInputMask>
              </Form.Item>
            </div>
            <Form.Item label={t(`home_id.Taklif_sanasi`)} name="sana">
              {/* @ts-ignore */}
              <DatePicker format="DD.MM.YYYY HH:mm" placeholder="" showTime />
            </Form.Item>

            <div className={styles.updateModal_btns}>
              <Button onClick={handleClose}>{t(`home.Yopish`)}</Button>
              <Button htmlType="submit">{t(`home.Saqlash`)}</Button>
            </div>
          </Form>
        </Modal>
      </div>
      <style>
        {visible
          ? `
          @media print{
            #root{
              display: none;
            }
          }
          `
          : ``}
      </style>
    </>
  );
};

export default MainPage;
