import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import InternationalPhoneNumber from '@components/international-phone-input/InternationalPhoneNumber';
import ClientLead from '@pages/changes/components/client/ClientLead';
import { countrCode } from '@pages/changes/utils/helpers/CountryCodes';
import ConnectLeadModal from '@pages/createOrder/components/connect-lead/ConnectLeadModal';
import CreateLeadModal from '@pages/createOrder/components/create-lead/CreateLeadModal';
import { useGetForceData } from '@pages/crm-force/services/queries';
import i18n from '@service/I18nService';
import { ClientLeadModel } from '@utils/models/ClientLeadModel';
import { Button, Checkbox, Col, Form, Input, Row, Select, Space } from 'antd';
import { isValidNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';

import TelPlusIcon from '../../../assets/icons/TelPlusIcon';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useCreateAndUpdateJuridicalPerson, useLeadSave } from '../../../queries/mutation';
import { useGetDistrict, useRegions } from '../../../queries/queries';
import { addClientPhone, changeCountryCode, checkClientPhone, deleteClientPhones, setSearchByPhone } from '../../../store/reducers/ClientSlice';
import { getCurrentLanguageWord } from '../../../utils/helper/getCurrentLanguageWord';
import { helper } from '../../../utils/helper/helper';
import { BronIdOrFlatIds } from '../../../utils/models/BronIdOrFlatIds';
import { ClientPhone, JuridicalPersonCreate } from '../../../utils/models/Client';

import img from '../../../assets/img/addLead.png';

import 'react-international-phone/style.css';
import styles from '../../../pages/clientCreateContract/clientCreateContract.module.scss';

const { Option } = Select;

export interface JuridicalClientForm {
  name: string;
  phone1: string;
  phone2: string;
  phone3: string;
  phone4: string;
  phone5: string;
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  code5: string;
  districtId: number;
  address: string;
  cityID: number;
  inn: string;
  accountNumber: string;
  oked: string;
  bankName: string;
  mfo: string;
  director: string;
  countryCode: string;
}

const ClientMainData = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams<{ orderIdOrFlatId: string }>();
  const clientData = useAppSelector(state => state.clientReducer.juridicalPerson);
  const [form] = Form.useForm<JuridicalClientForm>();
  const [country, setCountry] = useState<string | undefined>('uz');
  const { data: force } = useGetForceData();
  const updateNewLead = useLeadSave();
  const { leadContact, leadName, leadContactName, countryCode } = useAppSelector(state => state.clientReducer);

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openLeadModal, setOpenLeadModal] = useState(false);
  const [selectLead, setSelectLead] = useState<ClientLeadModel | undefined>();

  useEffect(() => {
    if (clientData?.phones[0]?.code) {
      dispatch(changeCountryCode(clientData?.phones[0]?.code));
    } else {
      dispatch(changeCountryCode('UZ'));
    }
  }, [clientData]);

  const handleOpenLeadModal = () => {
    setOpenLeadModal(true);
  };

  const handleOpenCreateModal = () => {
    setOpenLeadModal(false);
    setOpenCreateModal(true);
  };

  const [typeAddress, setTypeAddress] = useState<{
    id: number;
    type: 'district' | 'region';
  }>({
    id: -1,
    type: 'region'
  });

  const clientPhones = useAppSelector(state => state.clientReducer.clientPhones);

  const createAndUpdateClient = useCreateAndUpdateJuridicalPerson(params.orderIdOrFlatId);
  const { data: regions, isLoading: loadingRegions } = useRegions();
  const { data: districts, isLoading: districtsLoading } = useGetDistrict(typeAddress.id, typeAddress.type);
  const currentClientLead =
    (selectLead && (clientData?.lead === null || clientData?.lead === undefined)) || clientData === null ? selectLead : clientData?.lead;

  useEffect(() => {
    if (clientData?.id) {
      if (clientData.districtId) {
        setTypeAddress({
          id: clientData.districtId,
          type: 'district'
        });
      }
      form.setFieldsValue({
        name: clientData.name,
        cityID: clientData.cityID,
        districtId: clientData.districtId,
        address: clientData.address,
        inn: clientData.inn,
        accountNumber: clientData.accountNumber,
        oked: clientData.oked,
        bankName: clientData.bankName,
        mfo: clientData.mfo,
        director: clientData.director,
        countryCode: clientData?.countryCode || country
      });
      for (let i = 0; i < clientData.phones.length; i++) {
        form.setFieldsValue({
          [`phone${i + 1}`]: clientData.phones[i].phone,
          [`code${i + 1}`]: clientData.phones[i].code ? clientData.phones[i].code : 'UZ'
        });
      }
    } else {
      form.resetFields();
      form.setFieldsValue({ countryCode: clientData?.countryCode || country });
    }
  }, [clientData, form]);

  const contactsCopy = (contacts?: any[]) => {
    const arr = [...(contacts || [])];

    arr[0].name = leadContactName;
    arr[0].phones = [leadContact];

    return arr;
  };

  const onFinish = (values: JuridicalClientForm) => {
    const newPhones: ClientPhone[] = [];

    for (let i = 0; i < clientPhones.length && i < 5; i++) {
      newPhones.push({
        ...clientPhones[i],
        phone: String(values[`phone${i + 1}` as keyof typeof values])?.includes('+')
          ? String(`${values[`phone${i + 1}` as keyof typeof values]}`)
          : String(`+${values[`phone${i + 1}` as keyof typeof values]}`),
        code: String(values[`code${i + 1}` as keyof typeof values])
      });
    }
    const newClientData: JuridicalPersonCreate = {
      ...clientData,
      phones: newPhones,
      name: values.name?.trim(),
      districtId: values.districtId,
      address: values.address,
      type: undefined,
      note: undefined,
      cityID: undefined,
      mfo: values.mfo?.trim(),
      bankName: values.bankName?.trim(),
      accountNumber: values.accountNumber?.trim(),
      director: values.director?.trim(),
      inn: values.inn?.trim(),
      oked: values.oked?.trim(),
      countryCode: clientData?.countryCode ? clientData?.countryCode : 'uz',
      leadId: selectLead?.id
    };

    createAndUpdateClient.mutateAsync(newClientData).then(() => {
      const updateLidData = {
        id: currentClientLead?.id,
        contacts: contactsCopy(currentClientLead?.contacts),
        balance: currentClientLead?.balance,
        responsibleById: currentClientLead?.responsibleById,
        pipeStatusId: currentClientLead?.pipeStatusResponse?.id,
        tagIds: currentClientLead?.tags?.map(item => item?.id),
        fields: currentClientLead?.fields,
        name: leadName,
        autoClosedLeadTask: false
      };

      setSelectLead(undefined);
      updateNewLead.mutateAsync(updateLidData as any);
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    // eslint-disable-next-line no-console
    console.log('Failed:', errorInfo);
  };

  const changeRegion = (value: number) => {
    form.setFieldsValue({
      districtId: undefined
    });
    setTypeAddress({
      type: 'region',
      id: value
    });
  };

  const handleAddClientPhoneJuridical = () => {
    dispatch(addClientPhone());
  };

  const handleRemoveClientPhone = (index: number) => {
    for (let i = 0; i < 5; i++) {
      if (index === i) {
        form.setFieldsValue({
          [`phone${i + 1}`]: '',
          [`code${i + 1}`]: ''
        });
      } else if (i > index) {
        form.setFieldsValue({
          [`phone${i}`]: form.getFieldValue(`phone${i + 1}`),
          [`code${i}`]: form.getFieldValue(`code${i + 1}`)
        });
      }
    }
    form.setFieldsValue({
      [`phone5`]: '',
      [`code5`]: ''
    });
    dispatch(deleteClientPhones(index));
  };

  const handleCheckedPhone = (index: number) => {
    dispatch(checkClientPhone(index));
  };

  const handleSearchClient = (phone: string, index: number) => {
    if (!params.orderIdOrFlatId?.includes(BronIdOrFlatIds.BRON_ID)) {
      const phoneNumber = helper.deleteNotNumbers(phone, 1);

      if (index === 0 && String(phoneNumber).length >= 3) {
        dispatch(setSearchByPhone(phoneNumber));
      }
    }
  };

  const getTitleSaveButton = () => {
    if (params.orderIdOrFlatId?.includes(BronIdOrFlatIds.BRON_ID) || params.orderIdOrFlatId?.includes('flatId'))
      return t('client_create_contract.Keyingisi');
    return t('shaxmatka.Saqlash');
  };

  const checkFieldsRequired = !(!params.orderIdOrFlatId || params?.orderIdOrFlatId?.includes('clientId'));

  const isCliendLead = !!clientData?.lead;
  const { countryCode: code } = useAppSelector(state => state.clientReducer);

  return (
    <Form
      form={form}
      name="basic"
      layout="vertical"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <h2 className={styles.inputs_cont_title}>{t('client_create_contract.Boshlangich_malumotlar')}</h2>
      <Row gutter={[8, 0]}>
        <Col span={12}>
          <Form.Item
            name="name"
            rules={[{ required: true, message: '' }]}
            label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Korxona_nomi')}</span>}
          >
            <Input className="customInput" />
          </Form.Item>

          {/* {clientPhones.map((phone, index) => (
            <Space direction="horizontal" className="d-flex" key={phone?.id}>
              <div>
                <Form.Item name={`code${index + 1}`} className="d-none" />
                <div className="flex flex-col gap-[5px]">
                  <Form.Item
                    name={`phone${index + 1}`}
                    rules={[
                      {
                        required: true
                      }
                    ]}
                    label={<span className={styles.inputs_cont_formLabel}>{t(`changes.client.NaturalClient.Telefon_raqam`)}</span>}
                  >
                    <PhoneInput
                      defaultCountry="uz"
                      onChange={(e, phone) => {
                        setPhone(phone.inputValue);

                        form.setFieldsValue({ [`code${index + 1}`]: `${phone?.country?.iso2?.toUpperCase()}` });
                        handleSearchClient(e, index);
                      }}
                      countries={defaultCountries}
                      inputStyle={{ borderColor: !isPhoneValid(phoneValue) ? 'red' : '' }}
                      inputClassName="ant-input"
                    />
                  </Form.Item>
                  {!isPhoneValid(phoneValue) && <span className="mt-[-10px] text-red-500">{t('profile.Telefon_raqamini_kiriting')}</span>}
                </div>
              </div>
              <Checkbox style={{ transform: 'translateY(15px)' }} onChange={() => handleCheckedPhone(index)} checked={phone.active} />
              {index !== 0 && (
                <Button onClick={() => handleRemoveClientPhone(index)} style={{ marginLeft: 5, transform: 'translateY(15px)' }} shape="circle">
                  <CloseOutlined />
                </Button>
              )}
            </Space>
          ))} */}

          {clientPhones.map((phone, index) => (
            <Space direction="horizontal" className="d-flex" key={phone?.id}>
              <div>
                <Form.Item name={`code${index + 1}`} className="d-none" />

                <div className="flex flex-col gap-[5px]">
                  <Form.Item
                    label={<span>{t(`changes.client.NaturalClient.Telefon_raqam`)}</span>}
                    name={`phone${index + 1}`}
                    rules={[
                      {
                        validator: (rule, value) => {
                          if (!(value && isValidNumber(value))) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise.reject('');
                          }
                          return Promise.resolve();
                        }
                      }
                    ]}
                  >
                    <InternationalPhoneNumber
                      defaultCountry="uz"
                      className={styles.phoneInput2}
                      onChange={(e, phone) => {
                        // form.setFieldsValue({
                        //   [`phoneCode`]: `${phone?.country?.iso2?.toUpperCase()}`
                        // });
                        form.setFieldsValue({ [`code${index + 1}`]: `${phone?.country?.iso2?.toUpperCase()}` });
                        const searchPhone = e?.replace(`${phone?.country?.dialCode}`, '');

                        handleSearchClient(searchPhone, index);
                      }}
                    />
                  </Form.Item>

                  {/* {!isPhoneValid(phoneValue) && <span className="mt-[-10px] text-red-500">{t('profile.Telefon_raqamini_kiriting')}</span>} */}
                </div>
              </div>
              <Checkbox style={{ transform: 'translateY(15px)' }} onChange={() => handleCheckedPhone(index)} checked={phone.active} />
              {index !== 0 && (
                <Button onClick={() => handleRemoveClientPhone(index)} style={{ marginLeft: 5, transform: 'translateY(15px)' }} shape="circle">
                  <CloseOutlined />
                </Button>
              )}
            </Space>
          ))}

          {clientPhones.length < 5 && (
            <p onClick={handleAddClientPhoneJuridical} className={styles.conditionTelPlus}>
              <TelPlusIcon />
              {t('client_create_contract.Telefon_raqam_qoshish')}
            </p>
          )}
        </Col>
        <Col span={12}>
          <Form.Item label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Mamlakat')}</span>} name="countryCode">
            <Select className="customSelect" value={country} showSearch onChange={e => setCountry(e)} optionFilterProp="children">
              {countrCode?.map(item => (
                <Select.Option key={item?.code} value={item?.code === 'uz' && clientData?.countryCode ? 'UZB' : item?.code}>
                  {item[`country_${i18n.language}` as keyof typeof item]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {(country === 'uz' || clientData?.countryCode === 'UZB') && (
            <>
              <Form.Item
                name="cityID"
                rules={[{ required: checkFieldsRequired, message: '' }]}
                label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Viloyat')}</span>}
              >
                <Select
                  showSearch
                  className="customSelect"
                  onChange={changeRegion}
                  loading={loadingRegions}
                  optionFilterProp="children"
                  placeholder={t('client_create_contract.Hududni_tanlang')}
                >
                  {regions?.map(region => (
                    <Option key={region.id} value={region.id}>
                      {getCurrentLanguageWord(region)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="districtId"
                rules={[{ required: checkFieldsRequired, message: '' }]}
                label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Shahar_tuman')}</span>}
              >
                <Select
                  loading={districtsLoading}
                  className="customSelect"
                  optionFilterProp="children"
                  showSearch
                  placeholder={t('client_create_contract.Hududni_tanlang')}
                >
                  {districts?.map(district => (
                    <Select.Option key={district.id} value={district.id}>
                      {district.uz}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
          <Form.Item
            name="address"
            rules={[{ required: checkFieldsRequired, message: '' }]}
            label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Uy')}</span>}
          >
            <Input className="customInput" />
          </Form.Item>
        </Col>
      </Row>
      <h2 className={styles.inputs_cont_title}>{t('client_create_contract.Yuridik_malumotlar')}</h2>
      <Row gutter={[8, 0]}>
        <Col span={12}>
          <Form.Item
            name="inn"
            rules={[{ required: checkFieldsRequired, message: '' }]}
            label={<span className={styles.inputs_cont_formLabel}>{t('client.INN')}</span>}
          >
            <Input className="customInput" type="number" />
          </Form.Item>
          <Form.Item
            name="mfo"
            rules={[{ required: checkFieldsRequired, message: '' }]}
            label={<span className={styles.inputs_cont_formLabel}>{t('client.MFO')}</span>}
          >
            <Input className="customInput" type="number" />
          </Form.Item>
          <Form.Item
            name="accountNumber"
            rules={[{ required: checkFieldsRequired, message: '' }]}
            label={<span className={styles.inputs_cont_formLabel}>{t('client.Hisob_raqami')}</span>}
          >
            <Input className="customInput" type="number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="oked"
            rules={[{ required: checkFieldsRequired, message: '' }]}
            label={<span className={styles.inputs_cont_formLabel}>{t('client.OKED')}</span>}
          >
            <Input className="customInput" type="number" />
          </Form.Item>
          <Form.Item
            name="bankName"
            rules={[{ required: checkFieldsRequired, message: '' }]}
            label={<span className={styles.inputs_cont_formLabel}>{t('client.Bank_nomi')}</span>}
          >
            <Input className="customInput" />
          </Form.Item>
          <Form.Item
            name="director"
            rules={[{ required: checkFieldsRequired, message: '' }]}
            label={<span className={styles.inputs_cont_formLabel}>{t('client.Direktor')}</span>}
          >
            <Input className="customInput" />
          </Form.Item>
        </Col>
      </Row>
      {force?.status && (
        <>
          <div className={styles.connectLead}>
            <span className={styles.connectLead_title}>{t('one_lid.Lead_malumotlari')}</span>
            {currentClientLead ? (
              <ClientLead setSelectLead={setSelectLead} currentClientLead={currentClientLead} isCliendLead={isCliendLead} />
            ) : (
              <Form.Item name="test" rules={[{ required: true, message: 'test' }]}>
                <div className={styles.connectLead_body}>
                  <img src={img} alt="err" />
                  <span onClick={handleOpenLeadModal}>{t('one_lid.lid_biriktirish')}</span>
                </div>
              </Form.Item>
            )}
          </div>
          <ConnectLeadModal
            visible={openLeadModal}
            setVisible={setOpenLeadModal}
            handleOpenCreateModal={handleOpenCreateModal}
            clientId={0}
            setSelectLead={setSelectLead}
          />
          <CreateLeadModal visible={openCreateModal} setVisible={setOpenCreateModal} setSelectLead={setSelectLead} />
        </>
      )}
      <Form.Item className="d-flex justify-center" style={{ width: '100%', marginTop: 20 }}>
        <div className="d-flex justify-center" style={{ width: '100%' }}>
          <Button className={styles.conditionCanceledBtn} disabled>
            {t('home.Bekor_qilish')}
          </Button>
          <Button loading={createAndUpdateClient.isLoading} className={styles.conditionSubmitBtn} type="primary" htmlType="submit">
            {getTitleSaveButton()}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default ClientMainData;
