import { PaymentType } from './PaymentType';

export interface Staff {
  id?: number;
  fullName: string;
  districtId: number;
  address: string;
  cityId?: number;
  phone: string;
  phoneCode: string;
}

export interface StaffTable {
  id: number;
  fullName: string;
  address: string;
  balance: number;
  createdBy: string;
  type: StaffType;
}

export enum StaffType {
  IN_COMPANY = 'IN_COMPANY',
  OUT_COMPANY = 'OUT_COMPANY'
}

export interface OneStaff {
  id: number;
  fullName: string;
  balance: number;
  balances: any[];
  contracts: number;
  activeContracts: number;
  reward: number;
  paymentSum: number;
  payments: StaffPayment[];
}

export interface StaffPayment {
  id: number;
  date: string;
  amount: number;
  type: PaymentType;
  createdBy: string;
}

export interface PayStaff {
  amount: number;
  type: PaymentType;
  currencyId?: number;
  balanceId?: number;
  currency?: {
    id?: number;
  };
}

export interface ListStaff {
  id: number;
  fullName: string;
}
