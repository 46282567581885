import React, { useEffect, useState } from 'react';
import styles from './montylyPayments.module.scss';
import { Button, Form, Input, message, Pagination, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { ChangesQueryParams } from '../../../../utils/models/Changes';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { MonthlyPayment } from '../../../../utils/models/CreateContract';
import { validateDateString } from '../../../../utils/helper/validatorDateString';
import CalendarIcon from '../../../../assets/icons/CalendarIcon';
import { helper } from '../../../../utils/helper/helper';
import CancelBronGarbage from '../../../../assets/icons/CancelBronGarbage';
import { changeDateMonthlyPaymentsTable, changeOneItemAmountTable, deleteItemMonthlyPaymentParts } from '../../../../store/reducers/ChangesSlice';
import PaginationPrev from '../../../../assets/icons/PaginationPrev';
import PaginationNext from '../../../../assets/icons/PaginationNext';
import { useChangeTableMonthlyPaymentsChangesPage } from '../../services/mutation';

const TableMonthlyPayments = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { searchParams } = useQueryParam<ChangesQueryParams, any>();
  const [currentPage, setCurrentPage] = useState(1);
  const { monthlyPaymentParts, residueTablePayments, currency } = useAppSelector(state => state.changesReducer);

  useEffect(() => {
    if (monthlyPaymentParts) {
      monthlyPaymentParts.forEach((item: MonthlyPayment, index) => {
        form.setFieldsValue({
          [index]: item.date
        });
      });
    }
  }, [monthlyPaymentParts, form]);

  const dispatch = useAppDispatch();
  const updateTable = useChangeTableMonthlyPaymentsChangesPage(searchParams.contractId);

  const columns = [
    {
      title: '№',
      align: 'start',
      width: '10%',
      render: (text: any, record: any, index: number) => {
        return currentPage * 10 - 9 + index;
      }
    },
    {
      title: `${t(`changes.monthlyPayments.To'lov_qilish_sanasi`)}`,
      align: 'center',
      render: (text: any, record: MonthlyPayment, index: number) => {
        return (
          <Form.Item
            noStyle
            rules={[
              {
                validator: (rule, value) => {
                  if (value) {
                    form.setFieldsValue({
                      [currentPage * 10 - 10 + index]: validateDateString(value, true)
                    });
                  }
                  return Promise.resolve();
                }
              },
              {
                required: true,
                len: 10,
                message: ''
              }
            ]}
            name={currentPage * 10 - 10 + index}
            className="changesInput"
          >
            <InputMask
              onChange={e => {
                if (e.target.value.length === 10) handleChangeDate(currentPage * 10 - 10 + index);
              }}
              mask="99.99.9999"
              // @ts-ignore*
              maskChar={''}
            >
              {(inputProps: any) => (
                <Input
                  style={{ width: '150px' }}
                  className="customInput"
                  placeholder={t('changes.contract.kk.oo.yyyy')}
                  suffix={<CalendarIcon />}
                  {...inputProps}
                />
              )}
            </InputMask>
          </Form.Item>
        );
      }
    },
    {
      title: `${t(`changes.monthlyPayments.To'lov_summasi`)} (` + currency?.ccy + ')',
      align: 'center',
      render: (text: any, record: MonthlyPayment, index: number) => {
        return (
          <Input
            onChange={e => {
              handleChangeOneItem(currentPage * 10 - 10 + index, e.target.value);
            }}
            style={{
              textAlign: 'center',
              borderRadius: '6px',
              width: 135
            }}
            value={helper.deleteNotNumbersAndParseFloatNumber(record.amountString || '').string}
          />
        );
      }
    },
    {
      title: '',
      align: 'center',
      width: '20%',
      render: (text: any, record: MonthlyPayment, index: number) => {
        return (
          <div>
            <span onClick={() => handleDeleteItem(currentPage * 10 - 10 + index, record.amount)} className={styles.table_delete_span}>
              <CancelBronGarbage />
            </span>
          </div>
        );
      }
    }
  ];

  const handleChangeOneItem = (index: number, value: string) => {
    dispatch(
      changeOneItemAmountTable({
        index,
        amount: value
      })
    );
  };

  const handleDeleteItem = (index: number, amount: number) => {
    dispatch(
      deleteItemMonthlyPaymentParts({
        index,
        amount
      })
    );
  };

  const handleChangeDate = (index: number) => {
    dispatch(
      changeDateMonthlyPaymentsTable({
        index,
        date: form.getFieldsValue()[index]
      })
    );
  };

  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleUpdate = () => {
    if (residueTablePayments === 0) {
      updateTable.mutate(monthlyPaymentParts || []);
    } else {
      message.error(`${t(`changes.monthlyPayments.Jadval_xato_shaklantirilgan`)}`);
    }
  };

  return (
    <div className={styles.table}>
      <Form form={form} name={'table-payments'}>
        <Table
          className="custom_table contract_table table_cursor_default"
          columns={columns as any}
          rowKey={'sum'}
          dataSource={monthlyPaymentParts?.slice((currentPage - 1) * 10, currentPage * 10)}
          size="small"
          pagination={false}
        />
        <div className={styles.table_footer}>
          <div className={styles.table_footer_pagination_cont}>
            <Pagination
              onChange={changePage}
              showSizeChanger={false}
              total={monthlyPaymentParts?.length || 0}
              current={currentPage}
              prevIcon={
                <div className={styles.next_prev_buttons}>
                  <PaginationPrev />
                </div>
              }
              nextIcon={
                <div className={styles.next_prev_buttons}>
                  <PaginationNext />
                </div>
              }
            />
          </div>
          <Button onClick={handleUpdate} loading={updateTable.isLoading} type={'primary'} className={styles.table_footer_button}>
            {t(`changes.monthlyPayments.Saqlash`)}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default TableMonthlyPayments;
