import './service/I18nService';

import { useEffect, useRef } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Loading from '@components/loading/Loading';
import { baseErrorHandler } from '@queries/baseErrorHandler';
import * as Sentry from '@sentry/react';
import { ConfigProvider, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import useNetworkStatus from './hooks/useNetworkStatus';
import RootRouter from './routing/RootRouter';
import { store } from './store/store';

import 'antd/dist/antd.variable.min.css';
import './styles/main.scss';
import './styles/table/tableNewStyle.scss';
import './styles/tailwind.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, retry: false, onError: baseErrorHandler },
    mutations: { onError: baseErrorHandler }
  }
});

// @ts-expect-error
window['__react-beautiful-dnd-disable-dev-warnings'] = true;

function App() {
  ConfigProvider.config({ theme: { primaryColor: '#00A389' } });

  const { t, i18n } = useTranslation();

  const isFirstConnection = useRef(true);
  const networkStatus = useNetworkStatus();

  useEffect(() => {
    if (!networkStatus) {
      notification.error({
        message: t('network_alert.Tarmoqqa_ulanish'),
        description: t('network_alert.Tarmoqqa_ulanishda_xatolik_yuz_berdi_Iltimos_tarmoqni_tekshirib_koring')
      });
      isFirstConnection.current = false;
    } else if (!isFirstConnection.current) {
      notification.success({
        message: t('network_alert.Tarmoqqa_ulanish'),
        description: t('network_alert.Tarmoqqa_ulanish_muvaffaqiyatli_amalga_oshirildi')
      });
      isFirstConnection.current = false;
    }
  }, [networkStatus, t]);

  useEffect(() => {
    const lan = localStorage.getItem('i18nextLng');

    if (lan === 'ru-RU' || lan === 'en-US' || lan === 'en-GB') {
      i18n.changeLanguage('uz');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    process.env.NODE_ENV === 'production' &&
      setTimeout(() => {
        // eslint-disable-next-line no-console
        console.clear();

        // eslint-disable-next-line no-console
        console.log('%cOgohlantirish!', 'color: red; font-size: 40px');
        // eslint-disable-next-line no-console
        console.log(
          "%cUshbu brauzer xususiyati dasturchilar uchun mo'ljallangan.Agar kimdir sizga Uysot funksiyasini yoqish yoki kimningdir akkauntini “buzish” uchun bu yerga biror narsani nusxalash va joylashtirishni aytgan bo‘lsa, ular firibgarlar. Ushbu amallarni bajarish orqali siz ularga Uysot akkauntingizga kirish huquqini berasiz.",
          'font-size: 16px'
        );
      }, 1000);

    function WindowResize() {
      document.documentElement.style.setProperty('--window-size', window.innerWidth.toString());
    }

    window.addEventListener('resize', WindowResize);

    return () => window.removeEventListener('resize', WindowResize);
  }, []);

  if (!i18n.isInitialized) return <Loading />;

  return (
    <BrowserRouter>
      <Provider store={store}>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <QueryClientProvider client={queryClient} contextSharing={true}>
            <RootRouter />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </QueryParamProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default Sentry.withProfiler(App);
