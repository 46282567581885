import React from 'react';
import { Modal, Spin } from 'antd';

import styles from './triggers.module.scss';
import Teg from './teg/Teg';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/reduxHooks';
import { clearAll } from '../../../../../../store/reducers/TriggerSlice';
import Responsible from './responsible/Responsible';
import Note from './note/Note';
import Buttons from './buttons/Buttons';
import DateFinish from './dateFinish/DateFinish';
import Type from './type/Type';
import Condition from './condition/Condition';
import DoneBy from './DoneBy/DoneBy';
import Budget from './budget/Budget';
import TextField from './textField/TextField';
import MultiResponsible from './multiResponsible/MultiResponsible';
import MultiSelectField from './multiSelectField/MultiSelectField';
import { FieldsCondition, OrderTriggerCondition, TriggerConditionType, TYPE_TRIGGER } from '../../../../../../utils/models/Trigger';
import { CrmFieldsType } from '../../../../../../utils/models/CrmFieldsType';
import { useCrmFields, useTriggersOneTrigger } from '../../../../../../queries/queries';
import { useTranslation } from 'react-i18next';
import PipeStatus from './pipeStatus/PipeStatus';
import ChangeResponsible from './changeResponsible/ChangeResponsible';

const Triggers = () => {
  const { data } = useCrmFields();

  const { t } = useTranslation();

  const visible = useAppSelector(state => state.triggerSlice.visible);
  const id = useAppSelector(state => state.triggerSlice.id);
  const orders = useAppSelector(state => state.triggerSlice.orderTriggerCondition);
  const fieldsValue = useAppSelector(state => state.triggerSlice.fields);
  const type = useAppSelector(state => state.triggerSlice.typeTrigger);

  const dispatch = useAppDispatch();

  const { isLoading } = useTriggersOneTrigger(id, type);

  // handle cancel
  const handleCancel = () => {
    dispatch(clearAll());
  };

  // show current
  const showConditions = (order: OrderTriggerCondition, index: number) => {
    switch (order.type) {
      case TriggerConditionType.BUDGET:
        return <Budget key={index} order={index} />;
      case TriggerConditionType.EMPLOYEE:
        return <MultiResponsible key={index} order={index} />;
      case TriggerConditionType.TAG:
        return <Teg key={index} order={index} />;
      case TriggerConditionType.FIELD:
        const field = data?.find(data => data.id === order.fieldId);
        const fieldValue = fieldsValue?.find(item => item.fieldId === order.fieldId);
        if (field?.type === CrmFieldsType.TEXT)
          return <TextField fieldValue={fieldValue as FieldsCondition} field={field} order={index} key={index} />;
        if (field?.type === CrmFieldsType.SELECT)
          return <MultiSelectField fieldValue={fieldValue as FieldsCondition} field={field} order={index} key={index} />;
        return null;
      default:
        return null;
    }
  };

  return (
    <Modal title={''} closable={false} footer={false} visible={visible} width={442} className={styles.container} onCancel={handleCancel}>
      <Spin spinning={isLoading}>
        <div className={styles.container_header}>
          <h1>{t('triggers.Ushbu_korinishdagi_barcha_bitimlar_uchun')}</h1>
          {orders.map((order, index) => showConditions(order, index))}
          <Condition orders={orders} />
        </div>
        <div className={styles.container_body}>
          <DoneBy />
          {type === TYPE_TRIGGER.TASK_TRIGGER && (
            <>
              <DateFinish />
              <Responsible />
              <Type />
              <Note showTextArea={true} />
              <Buttons />
            </>
          )}
          {type === TYPE_TRIGGER.PIPE_STATUS_TRIGGER && (
            <>
              <PipeStatus />
              <Note showTextArea={false} />
              <Buttons />
            </>
          )}
          {type === TYPE_TRIGGER.MANAGER_TRIGGER && (
            <>
              <ChangeResponsible />
              <Note showTextArea={false} />
              <Buttons />
            </>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default Triggers;
