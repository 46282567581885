import React from 'react';
import { message, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { ChangesPageSteps, ChangesQueryParams } from '../../../utils/models/Changes';
import { useAppSelector } from '../../../hooks/reduxHooks';

const { TabPane } = Tabs;

const TabsContainer = () => {
  const { searchParams, appendSingle, appendMultipleDifferent } = useQueryParam<ChangesQueryParams, any>();
  const { t } = useTranslation();
  const selectedClientId = useAppSelector(state => state.changesReducer?.selectedClient?.id);

  const selectedContractId = useAppSelector(state => state.changesReducer?.selectedContract?.id);

  const changeType = (key: string) => {
    switch (key) {
      case ChangesPageSteps.client: {
        appendSingle('page', ChangesPageSteps.client);
        break;
      }
      case ChangesPageSteps.contract: {
        if (!searchParams.clientId && selectedClientId) {
          appendMultipleDifferent(['page', 'clientId'], [ChangesPageSteps.contract, selectedClientId as any]);
        } else if (searchParams.clientId) {
          appendSingle('page', ChangesPageSteps.contract);
        } else {
          message.error(t('create_payment.Mijoz_tanlanmagan'));
        }
        break;
      }
      case ChangesPageSteps.monthlyPayments: {
        if (!searchParams.contractId && selectedContractId) {
          appendMultipleDifferent(['page', 'contractId'], [ChangesPageSteps.monthlyPayments, selectedContractId as any]);
        } else if (searchParams.contractId) {
          appendSingle('page', ChangesPageSteps.monthlyPayments);
        } else {
          message.error(t('changes.Shartnoma_tanlanmagan'));
        }
        break;
      }
      case ChangesPageSteps.payments: {
        if (!searchParams.contractId && selectedContractId) {
          appendMultipleDifferent(['page', 'contractId'], [ChangesPageSteps.payments, selectedContractId as any]);
        } else if (searchParams.contractId) {
          appendSingle('page', ChangesPageSteps.payments);
        } else {
          message.error(t('changes.Shartnoma_tanlanmagan'));
        }
        break;
      }
      default:
        break;
    }
  };

  return (
    <div>
      <Tabs activeKey={searchParams.page} defaultActiveKey={ChangesPageSteps.client} onChange={changeType} className={'headerTab'}>
        <TabPane tab={<span>{t(`changes.TabsContainer.Mijoz_sozlamalari`)}</span>} key={ChangesPageSteps.client} />
        <TabPane tab={<span>{t(`changes.TabsContainer.Shartnoma_sozlamalari`)}</span>} key={ChangesPageSteps.contract} />
        <TabPane tab={<span>{t(`changes.TabsContainer.To'lov_grafigi_sozlamalari`)}</span>} key={ChangesPageSteps.monthlyPayments} />
        <TabPane tab={<span>{t(`changes.TabsContainer.To'lovlar_sozlamalari`)}</span>} key={ChangesPageSteps.payments} />
      </Tabs>
    </div>
  );
};

export default TabsContainer;
