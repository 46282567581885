import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Avatar, Dropdown, Layout, Tooltip } from 'antd';

import LoginArrovRightIcon from '../../../assets/icons/LoginArrovRightIcon';
import LogOutIcon from '../../../assets/icons/LogOutIcon';
import SidebarArrowRightIcon from '../../../assets/icons/SidebarArrowRightIcon';
import UnionIcon from '../../../assets/icons/UnionIcon';
import UserAvatarIcon from '../../../assets/icons/UserAvatarIcon';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { BASE_URL } from '../../../service/RequestService';
import { getCurrentLanguageWord } from '../../../utils/helper/getCurrentLanguageWord';
import { logOut } from '../../../utils/helper/logOut';

import navBarlinks from './navBarLinks';

import logo from '../../../assets/img/logo.png';
import LoginImage from '../../../assets/img/unsplash_3TLl_97HNJo.png';

import styles from './navbar.module.scss';

const { Sider } = Layout;

const NavBar = () => {
  const [colapsed, setColapsed] = useState(false);
  const user = useAppSelector(state => state.userReducer.user);
  const logOutHandler = async () => {
    await logOut();
  };

  const LogOut = () => (
    <div className="LoginUser">
      <div>
        <Avatar
          style={{
            width: '60px',
            height: '60px'
          }}
          src={`${BASE_URL}/${user?.avatar}`}
          alt={LoginImage}
        />
        <div>
          <p>{user?.fullName}</p>
          <span>
            Profilni korish <LoginArrovRightIcon />
          </span>
        </div>
      </div>
      <span onClick={logOutHandler}>
        <LogOutIcon />
        Chiqish
      </span>
    </div>
  );

  return (
    <Sider collapsedWidth={88} width={235} className="navbar" theme="light" trigger={null} collapsible collapsed={colapsed}>
      <div className={styles.logo}>
        <img src={logo} alt="logo" />
        <span>{!colapsed && 'UYSOT'}</span>
        <div className={styles.logoArrow} />
      </div>
      <div className="SideBarArrow" onClick={() => setColapsed(!colapsed)}>
        <UnionIcon />
        <span
          style={{
            transform: colapsed ? 'rotateY(0deg)' : 'rotateY(180deg)'
          }}
        >
          <SidebarArrowRightIcon />
        </span>
      </div>
      <div className={styles.navbar_direction}>
        {navBarlinks.map((navlink: any) =>
          !colapsed ? (
            <NavLink key={navlink.path} to={navlink.path}>
              {navlink.icon}
              <span>{navlink.name}</span>
            </NavLink>
          ) : (
            // eslint-disable-next-line react/jsx-key
            <Tooltip placement="right" title={navlink.name}>
              <NavLink key={navlink.path} to={navlink.path}>
                {navlink.icon}
              </NavLink>
            </Tooltip>
          )
        )}
      </div>
      <div className={styles.navbar_footer}>
        {!colapsed ? (
          <>
            <Dropdown overlay={LogOut} trigger={['click']}>
              <div>
                <UserAvatarIcon />
                <div>
                  <h3>{user?.fullName}</h3>
                  <h4>{user?.role && getCurrentLanguageWord(user.role.lang)}</h4>
                </div>
              </div>
            </Dropdown>
          </>
        ) : (
          <>
            <Dropdown overlay={LogOut} trigger={['click']}>
              <Tooltip placement="right" title={user?.role && getCurrentLanguageWord(user.role.lang)}>
                <div>
                  <UserAvatarIcon />
                </div>
              </Tooltip>
            </Dropdown>
          </>
        )}
      </div>
    </Sider>
  );
};

export default NavBar;
