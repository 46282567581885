import React from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from '@hooks/reduxHooks';
import { ChartDataModel } from '@pages/dashboard/utils/models/ChartDataModel';
import { WidgetModel } from '@pages/dashboard/utils/models/WidgetModel';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { SettingsPaths } from '@routing/settings/settingsPaths';
import { CrmLeadList } from '@utils/models/CrmLeadList';
import { Spin } from 'antd';

import styles from './numberWidget.module.scss';

type Props = {
  data: (CrmLeadList & ChartDataModel) | undefined;
  item: WidgetModel;
  loading: boolean;
};

const NumberWidget: React.FC<Props> = ({ data, item, loading }) => {
  const { updateWidget } = useAppSelector(state => state.assigmentSlice);
  const navigate = useNavigate();
  const objectData = JSON.parse(item?.objectData.replaceAll(/'/g, '"'));
  const objectType = item?.objectType;

  function removeNullValues(obj: any) {
    // Obyektni yangi nusxa (kopiyasini) olamiz
    const newObj = { ...obj };

    // Obyektni tekshiramiz va null ga teng bo'lgan va qiymatga ega bo'lmagan elementlarni o'chiramiz
    for (const key in newObj) {
      const value = newObj[key];

      // Agar massiv bo'lsa va faqat null dan iborat bo'lsa, yoki qiymat null bo'lsa
      if (Array.isArray(value) && value.length === 1 && value[0] === null) {
        delete newObj[key]; // Kalitni o'chiramiz
      } else if (value === null) {
        delete newObj[key]; // Kalitni o'chiramiz
      }
    }

    return newObj;
  }

  const filteredObj = removeNullValues(objectData);

  function objectToQueryString(obj: any) {
    const queryString = Object.entries(obj)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          // Massiv qiymati uchun har bir elementni ajratib o'tkazish
          return value.map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&');
        }
        // Boshqa tipdagi qiymatlar uchun URL formatiga o'tkazish
        return `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`;
      })
      .join('&');

    return queryString;
  }

  const queryString = objectToQueryString(filteredObj);

  const handleNavigateFilter = () => {
    if (objectType === 'LEAD') {
      navigate(rootPaths.MAIN + MainPaths.CRM + (objectData?.voronka ? `?${queryString}` : `?voronka=${objectData?.pipeId}&${queryString}`));
    } else if (objectType === 'TASK') {
      navigate(`${rootPaths.MAIN + MainPaths.TASK}?${queryString}`);
    } else {
      navigate(`${rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.EVET_LIST}?${queryString}`);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className={styles.widgetBody} style={{ cursor: updateWidget ? 'grab' : '' }}>
        <span onClick={handleNavigateFilter} onMouseDown={e => e.stopPropagation()}>
          {
            // eslint-disable-next-line no-nested-ternary
            item?.objectType === 'LOG' ? data?.countLog : item?.objectType === 'TASK' ? data?.leadTaskCount : data?.countLead
          }
        </span>
      </div>
    </Spin>
  );
};

export default NumberWidget;
