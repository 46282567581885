import style from '../company-permission-config/companyPermissionConfig.module.scss';
import CompanyPermissionSearch from './components/CompanyPermissionSearch';
import CompanyPermissionSearchPermissions from './components/CompanyPermissionSearchPermissions';

const CompanyPermissionConfig = () => {
  return (
    <>
      <div className={style.main}>
        <div className={style.main_d}>
          <h1>Funksiyalar</h1>
        </div>
        <div className={style.wrapper}>
          <CompanyPermissionSearch />
          <CompanyPermissionSearchPermissions />
        </div>
      </div>
    </>
  );
};

export default CompanyPermissionConfig;
