import BreadcrumbHeader from '@components/headers/components/BreadcrumbHeader';
import MainHeader from '@components/headers/MainHeader';
import { useAppDispatch } from '@hooks/reduxHooks';
import PlusIcon from '@icons/PlusIcon';
import { editAndCreateModal } from '@reducers/WebsiteUserSlice';
import { Button, Layout } from 'antd';
import { t } from 'i18next';

import AddAndEditWebsiteUserModal from './components/AddAndEditWebsiteUserModal';
import WebsiteUserFilter from './components/WebsiteUserFilter';
import WebsiteUser from './pages/WebsiteUser';

import styles from './websiteUser.module.scss';

const { Content } = Layout;

const WebsiteUserMain = () => {
  const dispatch = useAppDispatch();

  const openCreateModal = () => {
    dispatch(editAndCreateModal({ id: 0, open: true }));
  };

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
      </MainHeader>
      <Content>
        <div>
          <div className={`d-flex align-center justify-space-between ${styles.developer} mb-[-10px] mt-5 px-11`}>
            <WebsiteUserFilter />
            <Button onClick={openCreateModal} className={styles.developer_button} type="primary">
              <PlusIcon />
              <span>{t('house_config.Yangi_yaratish')}</span>
            </Button>
          </div>
          <WebsiteUser />
          <AddAndEditWebsiteUserModal />
        </div>
      </Content>
    </>
  );
};

export default WebsiteUserMain;
