import RobotoCallsIconGrey from '@icons/RobotoCallsIconGrey'
import { useState } from 'react';

import styles from '../arrearage.module.scss';
import { Button } from 'antd';
import CreateRobotCallDebt from '@pages/roboto-calls/components/create-robot-call/CreateRobotCallDebt';


const ArrearageCallPopover = () => {
    const [visibleCall, setVisibleCall] = useState(false);
    const [modalData, setModalData] = useState<any | undefined>(undefined);

    const handleOpenRobotCall = () => {
        setVisibleCall(true);
    };

    return (
        <>
            <div onClick={handleOpenRobotCall} className={styles.sendMessageButton}>
                <RobotoCallsIconGrey className={styles.sendMessageButton_icon} />
            </div>
            <CreateRobotCallDebt visible={visibleCall} setVisible={setVisibleCall} />
        </>
    )
}

export default ArrearageCallPopover