import React from 'react';
import Status from './Status';

import styles from './header.module.scss';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={'d-flex align-center'}>
        <h2>{t('boss_crm.Mijozlar_oqimi')}</h2>
      </div>
      <div className={'d-flex align-center'}>
        <Status />
      </div>
    </div>
  );
};

export default Header;
