import React from 'react';
import PaginationCont from '@components/pagenation/PaginationCont';
import { useQueryParam } from '@hooks/useQueryParams';
import TrushIcon from '@icons/crmIcons/TrushIcon';
import DotsIcon from '@icons/DotsIcon';
import Edit2Icon from '@icons/Edit2Icon';
import { useDeleteCompagin } from '@pages/roboto-calls/services/mutation';
import { useGetAllCampagins } from '@pages/roboto-calls/services/queries';
import { OneCampagingModel } from '@pages/roboto-calls/utils/models/OneCampagingModel';
import { useTableConfig } from '@queries/queries';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { tableExamples } from '@utils/constants/tableExamples';
import { TableConfig } from '@utils/models/TableConfig';
import { TableKey } from '@utils/models/TableKey';
import { Dropdown, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { SearchParamsRobotCalls } from '../roboto-drawer/RobotoDrawer';

const calcCallCountPercent = (percent: number) => (186 / 100) * percent;

interface IProps {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setModalData: React.Dispatch<any>;
}

const Robototable: React.FC<IProps> = ({ setVisible, setModalData }) => {
  const { t } = useTranslation();
  const { appendMultipleDifferent, push, searchParams } = useQueryParam<SearchParamsRobotCalls, any>();
  const { data: allCampagins, isLoading, isFetching } = useGetAllCampagins(searchParams);
  const { data: tableConfig } = useTableConfig(TableKey.robot_call);

  const deleteCompagin = useDeleteCompagin();

  const handleDeleteCompagin = (id: number) => {
    deleteCompagin.mutate(id);
  };

  const robotoStatuses = {
    SCHEDULE: {
      label: 'Kutilmoqda',
      color: '#B54708',
      background: '#FFFAEB'
    },
    IN_PROGRESS: {
      label: 'Jarayonda',
      background: '#EFF8FF',
      color: '#175CD3'
    },
    CANCELLED: {
      label: 'Bekor qilingan',
      background: '#FEF3F2',
      color: '#B42318'
    },
    ERROR_OCCURRED: {
      label: 'Xatolik topildi',
      background: '#F5F5DC',
      color: '#CD7F32'
    },
    FINISHED: {
      label: 'Tugallangan',
      background: '#F0FFFF',
      color: '#008B8B'
    },
    BUSY: {
      label: 'Tarmoq band',
      background: '#F5F5F5',
      color: '#2A3439'
    }
  };

  const columns = [
    {
      title: t('home.№'),
      key: 1,
      width: 72,
      render: (_: any, index: any, j: any) => <span>{j + 1}</span>
    },
    {
      title: t('home.Call_name'),
      key: 2,
      dataIndex: 'name',
      width: 234
    },
    {
      title: t('home.Call_statistics'),
      key: 3,
      dataIndex: 'callCountPercent',
      width: 204,
      render: (record: number) => {
        const width = calcCallCountPercent(record);

        return (
          <div className="flex flex-col items-start">
            <div>{record} %</div>
            <div className="h-1 w-[186px] overflow-auto rounded-md bg-[#F2F4F7]">
              <div className="h-full bg-[#00A389]" style={{ width: `${width}px` }} />
            </div>
          </div>
        );
      }
    },
    {
      title: t('home.Created_date'),
      key: 4,
      dataIndex: 'createdDate',
      width: 160
    },
    {
      title: t('home.Start_date'),
      key: 5,
      dataIndex: 'startDate',
      width: 160
    },
    {
      title: t('home.Finish_date'),
      key: 6,
      dataIndex: 'endDate',
      width: 160
    },
    {
      title: t('home.Call_back'),
      key: 7,
      dataIndex: 'retryCount',
      width: 120,
      render: (record: number) => <div>{record} ta</div>
    },
    {
      title: t('home.Employee'),
      key: 8,
      dataIndex: 'createdBy',
      width: 200
    },
    {
      title: t('home.Holati'),
      key: 9,
      dataIndex: 'status',
      width: 190,
      render: (record: string) => (
        <div
          style={{ backgroundColor: robotoStatuses[record as keyof typeof robotoStatuses]?.background, color: robotoStatuses[record as keyof typeof robotoStatuses]?.color }}
          className="flex h-6 w-[167px] items-center justify-center rounded-full font-medium"
        >
          {t(`home.${robotoStatuses[record as keyof typeof robotoStatuses]?.label}`)}
        </div>
      )
    }
  ];

  const hasChildren = (item: TableConfig, column: any) => ({
    ...column,
    children: column?.children
      ? column?.children
          ?.map((child: any) => ({
            ...(item.values?.some(val => val?.name === child?.name && val?.checked) && child)
          }))
          .filter((item: any) => item?.name)
      : []
  });

  const columnsSort = (tableConfig?.config && tableConfig?.config?.length > 0 ? tableConfig?.config : tableExamples.robot_call)
    ?.map((item: any) => ({
      ...(item.checked &&
        hasChildren(
          item,
          columns?.find((col: any) => col?.key === item?.id)
        ))
    }))
    .filter((item: any) => item?.title);

  columnsSort.push({
    width: '62px',
    render: (record: any) => (
      <div>
        <Dropdown
          overlay={
            <div className="flex w-60 flex-col overflow-auto rounded-xl bg-white shadow-[0px_4px_6px_2px_#10182808]">
              <div
                className="flex cursor-pointer items-center gap-3 px-4 py-2 hover:bg-[#F2F4F7]"
                onClick={e => {
                  e.stopPropagation();
                  setVisible(true);
                  setModalData(record);
                }}
              >
                <Edit2Icon /> <span>{t('client.Tahrirlash')}</span>
              </div>
              <div
                className="flex cursor-pointer items-center gap-3 px-4 py-2 text-[#F04041] hover:bg-[#F2F4F7]"
                onClick={e => {
                  handleDeleteCompagin(Number(record?.id));
                  e.stopPropagation();
                }}
              >
                <TrushIcon color="#F04041" /> <span>{t('home.Bekor_qilish')}</span>
              </div>
            </div>
          }
          trigger={['click', 'hover']}
          mouseEnterDelay={1000}
          mouseLeaveDelay={0.3}
        >
          <span onClick={e => e.stopPropagation()} className="cursor-pointer">
            <DotsIcon />
          </span>
        </Dropdown>
      </div>
    )
  });

  const changePage = (page: number) => {
    appendMultipleDifferent(['page', 'size'], [page, 15]);
  };

  const goToOneCampaign = (record: OneCampagingModel) => {
    push(`${rootPaths.MAIN}${MainPaths.ROBOTO_CALLS}/${record?.id}/${record?.name}`);
  };

  return (
    <div>
      <Table
        columns={columnsSort}
        dataSource={allCampagins?.data}
        pagination={false}
        onRow={(record: OneCampagingModel) => ({
          onClick: () => goToOneCampaign(record)
        })}
        scroll={{ y: `calc(100vh - 310px)` }}
        loading={isLoading || isFetching}
        rowKey="key"
        className="custom_table_roboto"
      />
      <PaginationCont
        currentPage={allCampagins?.currentPage ? allCampagins?.currentPage : 1}
        defaultPageSize={15}
        total={allCampagins?.totalElements !== undefined ? allCampagins?.totalElements : -1}
        totalPages={allCampagins?.totalPages || 1}
        changePage={changePage}
      />
    </div>
  );
};

export default Robototable;
