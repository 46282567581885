/* eslint-disable no-nested-ternary */
import { useDispatch } from 'react-redux';
import NotificationButtons from '@components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '@components/headers/components/OnlinePbxNotifications';
import TaskContainer from '@components/task/taskContainer/TaskContainer';
import { useQueryParam } from '@hooks/useQueryParams';
import AddDashboardIcon from '@icons/AddDashboardIcon';
import { setOpenDashboard } from '@reducers/CrmSlice';
import { Permissions } from '@utils/constants/permissions';
import { Layout, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/reduxHooks';

import CalendarView from './components/calendarView/CalendarView';
import TableView from './components/table-view/TableView';
import TaskHeader from './components/taskHeader/TaskHeader';
import { useGetTaskTableView } from './services/queries';

import styles from './task.module.scss';

const { Content } = Layout;

export interface IViewTypeObj {
  [key: string]: React.ReactNode;
}

const calendarView = (
  <Content
    style={{
      height: '50vh'
    }}
  >
    <CalendarView />
  </Content>
);
const kanbanView = (
  <Content
    style={{
      padding: '1rem 2.68rem',
      height: '50vh'
    }}
  >
    <div className={styles.crm}>
      <TaskContainer />
    </div>
  </Content>
);

const tableView = (
  <Content>
    <TableView />
  </Content>
);

const Task = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { searchParams } = useQueryParam<any, any>();
  const viewType = useAppSelector(state => state.assigmentSlice.calendarView);
  const { data } = useGetTaskTableView(1, 15, searchParams);

  // useEffect(
  //   () => () => {
  //     dispatch(setClearTaskListView());
  //   },
  //   []
  // );

  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const isAccessCreateDashboard = permissions?.includes(Permissions.PERMISSION_PAGE_DASHBOARD);

  const viewTyObj: IViewTypeObj = {
    calendar: calendarView,
    kanban: kanbanView
  };

  const handleViewType = (viewType: string) => viewTyObj[viewType] || tableView;

  return (
    <>
      <div className={styles.crm_header}>
        <h1> {t('titles.task')}</h1>
        <div className="d-flex align-center">
          {isAccessCreateDashboard ? (
            <Tooltip
              overlay={
                <div className={styles.dashboardTooltip} onClick={() => dispatch(setOpenDashboard(true))}>
                  <AddDashboardIcon />
                  <span style={{ color: '#000' }}>{t('home.Widget_yaratish')}</span>
                </div>
              }
              color="#fff"
            >
              <span className={styles.dashboardTooltip_item}>
                {t('home.Umumiy_topshiriqlar')} : {data?.totalElements}
              </span>
            </Tooltip>
          ) : (
            <span className={styles.dashboardTooltip_item}>
              {t('home.Umumiy_topshiriqlar')} : {data?.totalElements}
            </span>
          )}

          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </div>
      <div>
        <div style={{ marginTop: 70 }}>
          <TaskHeader />
        </div>
        {handleViewType(viewType!)}
      </div>
    </>
  );
};

export default Task;
