import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import ArrowSquireDown from '@icons/ArrowSquareDown';
import { useGetAllTJMSWithBuildings } from '@pages/bossCrm/services/queries';
import { setChangeDiscountBuildings, setChangeDiscountHouses, setChangeMonthCurrency, setChangeMonthType } from '@reducers/BossCurrencySlice';
import { DATE_FORMAT } from '@utils/constants/format';
import { Segmented, TreeSelect } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { separateIds } from '../../utils/helpers/SeparateIds';
import { BossDiscountParam } from '../sunburst-header/SunburstHeader';

import styles from './monthDiscountHeader.module.scss';

const MonthDiscountHeader = () => {
  const dispatch = useAppDispatch();
  const { monthType, monthCurrency } = useAppSelector(state => state.bossCurrency);
  const { appendSingle } = useQueryParam<BossDiscountParam, any>();
  const [value, setValue] = useState<string>();
  const [count, setCount] = useState<number>(0);
  const currentYear = dayjs().get('year');
  const { t } = useTranslation();

  const monthStart = dayjs()?.add(count, 'year')?.startOf('year')?.format(DATE_FORMAT);

  const monthFinish = dayjs()?.add(count, 'year')?.endOf('year')?.format(DATE_FORMAT);

  const year = dayjs()?.add(count, 'year')?.startOf('year')?.get('year');

  const handleArrowLeft = () => {
    if (year === 2022) {
      setCount(count => count);
    } else {
      setCount(count => count - 1);
    }
  };
  const handleArrowRight = () => {
    if (currentYear + 1 === year) {
      setCount(count => count);
    } else {
      setCount(count => count + 1);
    }
  };

  useEffect(() => {
    appendSingle('monthStart', monthStart);
    appendSingle('monthFinish', monthFinish);
  }, [count]);

  const { data: tjms } = useGetAllTJMSWithBuildings();

  const treeData = tjms?.map(item => ({
    title: item?.name,
    value: `0-${item?.id}`,
    key: `0-${item?.id}`,
    children: item?.buildings?.map(prev => ({
      title: prev?.name,
      value: `0-${item?.id}-${prev?.id}`,
      key: `0-${item?.id}-${prev?.id}`
    }))
  }));

  const onChange = (newValue: string) => {
    setValue(newValue);

    const arr = Array.isArray(newValue) ? newValue : [newValue];
    const numberBuildings = separateIds(arr)?.buildings?.map(item => Number(item));
    const numberHouses = separateIds(arr)?.houses?.map(item => Number(item));

    dispatch(setChangeDiscountBuildings(numberBuildings));
    dispatch(setChangeDiscountHouses(numberHouses));
  };

  return (
    <div className={styles.container}>
      <h3>{t('boss_complex.Oylar_boyichachegirmalarstatistikasi')}</h3>
      <div className={styles.container_right}>
        <Segmented
          defaultValue={monthCurrency}
          options={[
            { label: 'UZS', value: 'UZS' },
            { label: 'USD', value: 'USD' }
          ]}
          onChange={e => dispatch(setChangeMonthCurrency(e as string))}
        />
        <Segmented
          defaultValue={monthType}
          options={[
            { label: t('broker.Tolov_miqdori'), value: 'count' },
            { label: t('home.Summa'), value: 'overallAmount' },
            { label: t('boss_complex.Nisbati'), value: 'ratio' }
          ]}
          onChange={e => dispatch(setChangeMonthType(e as string))}
        />
        <div style={{ width: 200 }}>
          <TreeSelect
            showSearch
            style={{ width: '100%' }}
            value={value}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder={t('home.Tanlang')}
            allowClear
            multiple
            maxTagCount="responsive"
            onChange={onChange}
            treeData={treeData}
          />
        </div>
        <div className={styles.container_arrows}>
          <ArrowSquireDown className={styles.arrow_left} onClick={handleArrowLeft} />
          <div className={styles.line_chart_middle_body}>
            <h2>{year}</h2>
          </div>
          <ArrowSquireDown className={styles.arrow_right} onClick={handleArrowRight} />
        </div>
      </div>
    </div>
  );
};

export default MonthDiscountHeader;
