import React from 'react';

const TrushSquareBlackStrokeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.1064 4.44577C10.2196 4.25536 8.31553 4.16016 6.42009 4.16016C5.29495 4.16016 4.16981 4.22074 3.05332 4.33325L1.89355 4.44577"
      stroke="#374151"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.01807 3.87521L5.13924 3.13089C5.22578 2.59428 5.29502 2.1875 6.25572 2.1875H7.74437C8.70507 2.1875 8.77431 2.61159 8.86086 3.13089L8.98202 3.86656"
      stroke="#374151"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.886 4.51562L10.5139 10.2279C10.4533 11.1193 10.4014 11.8117 8.8175 11.8117H5.17376C3.5899 11.8117 3.53797 11.1193 3.47738 10.2279L3.10522 4.51562"
      stroke="#374151"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TrushSquareBlackStrokeIcon;
