import React, { useEffect } from 'react';
import { Form, Modal } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import styles from './createTask.module.scss';
import { useTranslation } from 'react-i18next';
import ArrearageTask from '../../arrearageTask/ArrearageTask';
import { useAppSelector } from '../../../../../hooks/reduxHooks';

interface Props {
  visible: boolean;
  onClose: (visible: boolean) => void;
  id: number;
  arrearage: number;
  clientId: number;
}

interface FormI {
  date: Dayjs;
  time: string | undefined;
  assignedById: number;
  text: string;
  payment: string;
}

const CreateTask: React.FC<Props> = ({ visible, onClose, id, arrearage, clientId }) => {
  const [form] = Form.useForm<FormI>();
  const employeeId = useAppSelector(state => state?.userReducer?.user?.id);

  const { t } = useTranslation();

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        date: dayjs(new Date()),
        payment: arrearage.toLocaleString('ru'),
        assignedById: Number(employeeId)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, form]);

  // handle cancel modal
  const handleCancel = () => {
    form.setFieldsValue({
      time: '',
      assignedById: undefined,
      payment: '',
      text: undefined,
      date: undefined
    });
    onClose(false);
  };

  return (
    <Modal className={styles.container} width={710} footer={false} visible={visible} title={t('one_task.Topshiriq_yaratish')} onCancel={handleCancel}>
      <ArrearageTask id={id} clientId={clientId} handleCancel={handleCancel} />
    </Modal>
  );
};

export default CreateTask;
