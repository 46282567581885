const EditIconV3 = () => (
  <svg width="24" height="25" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.83325 1.33301H6.49992C3.16659 1.33301 1.83325 2.66634 1.83325 5.99967V9.99967C1.83325 13.333 3.16659 14.6663 6.49992 14.6663H10.4999C13.8333 14.6663 15.1666 13.333 15.1666 9.99967V8.66634"
      stroke="#7e7e7e"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1933 2.0135L5.93992 7.26684C5.73992 7.46684 5.53992 7.86017 5.49992 8.14684L5.21325 10.1535C5.10659 10.8802 5.61992 11.3868 6.34659 11.2868L8.35325 11.0002C8.63325 10.9602 9.02659 10.7602 9.23325 10.5602L14.4866 5.30684C15.3933 4.40017 15.8199 3.34684 14.4866 2.0135C13.1533 0.680168 12.0999 1.10684 11.1933 2.0135Z"
      stroke="#7e7e7e"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10.4399 2.7666C10.8866 4.35993 12.1333 5.6066 13.7333 6.05993" stroke="#7e7e7e" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default EditIconV3;
