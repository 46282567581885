import React, { useEffect, useState } from 'react';
import CustomPlayer from '@components/customPlayer/CustomPlayer';
import { useAppSelector } from '@hooks/reduxHooks';
import ArrowDownIcon from '@icons/ArrowDownIcon';
import TrushIcon from '@icons/crmIcons/TrushIcon';
import FeaturedIcon from '@icons/FeaturedIcon';
import SidebarArrowRightIcon from '@icons/SidebarArrowRightIcon';
import { useCreateCampaging } from '@pages/roboto-calls/services/mutation';
import { useGetAllChannelsShort } from '@pages/roboto-calls/services/queries';
import { callBackItems, collectionTypes, contractStatus } from '@pages/roboto-calls/utils/constants/callsStatus';
import { CampagingCreateModel } from '@pages/roboto-calls/utils/models/CampagingCreateModel';
import { ChannelModel } from '@pages/roboto-calls/utils/models/ChannelModel';
import { useUploadFile } from '@queries/mutation';
import { useHousesNameAndID, usePipeAll } from '@queries/queries';
import { DATE_WITH_TIME_FORMAT } from '@utils/constants';
import { FileObject } from '@utils/models/File';
import { Col, DatePicker, Form, Input, Modal, Row, Select, Spin, TreeSelect } from 'antd';
import dayjs from 'dayjs';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import ConfirmModal from '../confirm-modal/ConfirmModal';

const { Option } = Select;

type IProps =
  | {
      place: 'calls';
      visible: boolean;
      setVisible: React.Dispatch<React.SetStateAction<boolean>>;
      setModalData: React.Dispatch<any>;
      modalData: any;
    }
  | {
      place: 'crm';
      visible: boolean;
      setVisible: React.Dispatch<React.SetStateAction<boolean>>;
      setModalData?: React.Dispatch<any>;
      modalData?: any;
    };

const CreateRobotCall: React.FC<IProps> = ({ visible, setVisible, setModalData, modalData, place }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { data } = useHousesNameAndID();
  const { data: channels } = useGetAllChannelsShort();
  const [cardType, setCardType] = useState('ALL');
  const [callCount, setCallCount] = useState(1);
  const [files, setFiles] = useState<FileObject | undefined>();
  const [hasFile, setHasFile] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState({ visible: false, id: 0, name: '' });

  const activeIds = useAppSelector(state => state.assigmentSlice?.listViewActiveIds);

  const { data: pipeAll } = usePipeAll();

  const createCampaging = useCreateCampaging();

  function getFile(data: FileObject[]) {
    setFiles(data[0]);
  }

  const uploadFile = useUploadFile(getFile);

  const filterDeletedNotInArray = (arr1: any[], arr2: any[]) => modalData?.channelsIdList && arr1.filter(item => !(item.deleted && !arr2.includes(item.id)));

  const newChannels = modalData?.channelsIdList ? filterDeletedNotInArray(channels!, modalData?.channelsIdList) : channels?.filter(item => !item?.deleted);

  const handleClose = () => {
    setVisible(false);
    setCardType('ALL');
    setCallCount(1);
    setFiles(undefined);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setModalData && setModalData(undefined);
    form.resetFields();
    setHasFile(false);
  };

  function decr() {
    if (callCount! > 1) {
      setCallCount(prev => prev - 1);
    }
  }
  function incr() {
    if (callCount! < 10) {
      setCallCount(prev => prev + 1);
    }
  }

  const dropzoneChange = (e: any[]) => {
    const file = e[0];

    if (file.type === 'audio/wav') {
      const formData = new FormData();

      formData.append('files', file);
      uploadFile.mutate(formData);
    }
  };

  const onOk = () => {
    form.submit();
  };

  useEffect(() => {
    if (modalData) {
      form.setFieldsValue({ ...modalData, startDate: dayjs(modalData?.startDate, DATE_WITH_TIME_FORMAT) });
      setCallCount(modalData?.channelsParallelCallCount);
      setFiles({ ...modalData, format: modalData?.fileFormat, id: modalData?.fileId, originalName: modalData?.fileOriginalName });
    }
  }, [modalData]);

  const onFinish = (val: CampagingCreateModel) => {
    setHasFile(true);
    if (modalData) {
      createCampaging
        .mutateAsync({ ...val, id: modalData?.id, startDate: dayjs(val?.startDate).format(DATE_WITH_TIME_FORMAT), channelsParallelCallCount: callCount })
        .then(handleClose);
    } else if (hasFile && files?.id!) {
      createCampaging
        .mutateAsync({
          ...val,
          callTo: place === 'crm' ? 'SELECTED_LEADS' : cardType,
          fileId: files?.id!,
          channelsParallelCallCount: callCount!,
          startDate: dayjs(val?.startDate).format(DATE_WITH_TIME_FORMAT),
          repaired: Boolean(val?.repaired),
          pipeStatuses: cardType === 'CRM_LEADS' ? val?.pipeStatuses : null,
          leadIdList: place === 'crm' ? activeIds : null
        })
        .then(res => {
          if (place === 'crm') {
            handleClose();
            setVisibleConfirm({ id: res?.data?.id, name: res?.data?.name, visible: true });
          } else {
            handleClose();
          }
        });
    }
  };

  const disabledDate = (current: any) => current && current < dayjs().add(-1, 'day').endOf('day');

  function range(start: number, end: number) {
    const result = [];

    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  function disabledDateTime() {
    const currentHour = dayjs().get('hour');
    const currentMinute = dayjs().get('minute');

    return {
      disabledHours: () => range(0, 24).splice(0, currentHour),
      disabledMinutes: (a: any) => range(0, a <= currentHour ? currentMinute : 0)
    };
  }

  return (
    <>
      <Modal
        visible={visible}
        onCancel={handleClose}
        width={966}
        title={t('home.Qongiroq_toplamini_yaratish')}
        okText={t('home.Saqlash')}
        cancelText={t('home.Yopish')}
        okButtonProps={{ style: { borderRadius: 50, width: 160, height: 38 }, loading: createCampaging?.isLoading }}
        cancelButtonProps={{ style: { borderRadius: 50, width: 160, height: 38 } }}
        onOk={onOk}
        centered
        className="sipCustomModal"
      >
        {!modalData && place !== 'crm' && (
          <div className="mb-4 flex flex-col gap-4 rounded-2xl border border-solid border-[#D0D5DD] p-4">
            <h3>{t('home.Toplam_turi')}</h3>
            <div className="grid grid-cols-4 gap-4">
              {collectionTypes?.map(item => (
                <div
                  key={item?.id}
                  className={item?.className}
                  onClick={() => setCardType(item?.type)}
                  style={{ backgroundColor: item?.type === cardType ? '#E6F6F4' : '', borderColor: item?.type === cardType ? '#00A389' : '' }}
                >
                  <h3 className="mb-0 text-center text-base font-semibold text-[#464255]">{t(`home.${item?.title}`)}</h3>
                  {/* <p className="text-center text-[#87898E]">{item?.descr}</p> */}
                </div>
              ))}
            </div>
          </div>
        )}
        <Form layout="vertical" form={form} onFinish={onFinish} onFinishFailed={() => setHasFile(true)}>
          {cardType === 'CRM_LEADS' && !modalData && (
            <div className="mb-4 rounded-2xl border border-solid border-[#D0D5DD] p-4 pb-3">
              <Form.Item name="pipeStatuses" label={t('home.Potensial_mijozlar_manbaasi')}>
                <TreeSelect
                  treeExpandAction="click"
                  switcherIcon={<SidebarArrowRightIcon />}
                  suffixIcon={<ArrowDownIcon />}
                  className={`customRobotSelect ${'w-full'}`}
                  placeholder={t('home.Barcha_varonkalar_barcha_qadamlar')}
                  allowClear
                  multiple
                  maxTagCount="responsive"
                  treeCheckable={true}
                >
                  {pipeAll?.map(item => (
                    <TreeSelect.TreeNode
                      title={item.name}
                      value={item.id}
                      key={item.id}
                      // selectable={false}
                      children={item.pipeStatuses.map(pipe => (
                        <TreeSelect.TreeNode
                          key={pipe?.id}
                          value={pipe?.id!}
                          title={
                            <div
                              // @ts-ignore
                              // eslint-disable-next-line react/no-unknown-property
                              pageName={item?.name}
                            >
                              <span
                                style={{
                                  background: pipe.color,
                                  color: '#FFFFFF',
                                  padding: '2px 7px',
                                  borderRadius: '5px',
                                  fontSize: 10
                                }}
                              >
                                {pipe.name}
                              </span>
                            </div>
                          }
                        />
                      ))}
                    />
                  ))}
                </TreeSelect>
              </Form.Item>

              <p className="text-center text-[14px] font-normal text-[#87898E]">{t('home.potensial_text')}</p>
            </div>
          )}
          {cardType === 'CONTRACT' && !modalData && (
            <div className="mb-4 rounded-2xl border border-solid border-[#D0D5DD] p-4 pb-0">
              <Form.Item label="Turar - joy majmualari" name="houses">
                <Select className="customCallSelect" mode="multiple" maxTagCount="responsive" placeholder={t('home.Tanlang')}>
                  {data?.map(item => (
                    <Option key={item?.id} value={item?.id}>
                      {item?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item label="Ta‘mir holati" name="repaired">
                    <Select className="customCallSelect" placeholder={t('home.Tanlang')}>
                      <Option key={1} value="true">
                        {t('showroom.Tamirli')}
                      </Option>
                      <Option key={2} value="false">
                        {t('showroom.Tamirsiz')}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Shartnoma holati" name="statuses">
                    <Select className="customCallSelect" placeholder={t('home.Tanlang')} mode="multiple" maxTagCount="responsive">
                      {contractStatus?.map(item => (
                        <Option key={item?.value} value={item?.value}>
                          {item?.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}
          <div className="mb-4 rounded-2xl border border-solid border-[#D0D5DD] p-4 pb-0">
            <Form.Item label={t('home.Toplam_nomi')} name="name" rules={[{ required: true, message: '' }]}>
              <Input placeholder={t('home.Toplam_nomi')} className="customCallInput" />
            </Form.Item>
            <Row gutter={12}>
              <Col span={19}>
                <Form.Item label={t('home.Sip_user')} name="channelsIdList" rules={[{ required: true, message: '' }]}>
                  <Select className="customCallSelect" mode="multiple" maxTagCount="responsive" placeholder={t('home.Tanlang')}>
                    {newChannels?.map((item: ChannelModel) => (
                      <Option key={item?.id} value={item?.id} disabled={item?.deleted}>
                        <div className="flex items-center gap-3">
                          <span>{item?.name}</span>
                          <p
                            className="rounded-md px-2 py-0"
                            style={{
                              backgroundColor: item?.active ? '#E6F6F4' : '#FEF3F2',
                              color: item?.active ? '#2ECD6F' : '#FF0000',
                              border: '1px solid',
                              borderColor: item?.active ? '#2ECD6F' : '#FF0000',
                              opacity: item?.deleted ? '0.3' : 1
                            }}
                          >
                            {item?.active ? `${item?.deleted ? t('home.Ochirilgan') : t('home.Faol')}` : `${item?.deleted ? t('home.Ochirilgan') : t('home.Nofaol')}`}
                          </p>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label={t('home.Paraller_qongiroqlar')}>
                  <div className="flex h-11 w-full items-center justify-between overflow-auto rounded-lg border border-solid border-[#D0D5DD]">
                    <div
                      onClick={decr}
                      className="flex h-full cursor-pointer select-none items-center justify-center border-b-0 border-l-0 border-r border-t-0 border-solid border-[#D0D5DD] px-3.5 text-base text-[#1D2939]"
                    >
                      -
                    </div>
                    <div className="text-base text-[#1D2939]">{callCount}</div>
                    <div
                      onClick={incr}
                      className="flex h-full cursor-pointer select-none items-center justify-center border-b-0 border-l border-r-0 border-t-0 border-solid border-[#D0D5DD] px-3.5 text-base text-[#1D2939]"
                    >
                      +
                    </div>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item label={t('home.Boshlanish_vaqti')} name="startDate" rules={[{ required: true, message: '' }]}>
                  {/* @ts-ignore */}
                  <DatePicker
                    className="h-11 w-full rounded-md"
                    placeholder={t('home.Tanlang')}
                    showHour
                    showMinute
                    showTime
                    minuteStep={30}
                    format={DATE_WITH_TIME_FORMAT}
                    showNow={false}
                    showToday={false}
                    disabledDate={disabledDate}
                    // eslint-disable-next-line react/jsx-no-bind
                    disabledTime={disabledDateTime}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('home.Qayta_qongiroqlar')} name="retryCount" rules={[{ required: true, message: '' }]}>
                  <Select className="customCallSelect" placeholder={t('home.Tanlang')}>
                    {callBackItems?.map(item => (
                      <Option key={item?.id} value={item?.value}>
                        {item?.label} {t('home.marta')}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="rounded-2xl border border-solid border-[#D0D5DD] p-4 pb-0">
            <Form.Item label={t('home.Audio_yuklash')} name="fileId">
              {!files ? (
                <Spin spinning={uploadFile?.isLoading}>
                  <Dropzone multiple={false} onDrop={dropzoneChange}>
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className={`flex cursor-pointer flex-col items-center gap-1 rounded-xl border border-solid ${hasFile ? 'border-danger' : 'border-[#EAECF0]'} px-8 py-4`}
                      >
                        <input style={{ display: 'none' }} {...getInputProps()} />
                        <p className="flex w-full flex-col items-center">
                          <>
                            <FeaturedIcon />
                            <span className="font-semibold text-[#6941C6]">{t('home.Yuklash_uchun_bosing')}</span> {t('home.yoki_faylni_tortib_tashlang')}
                            <p style={{ textAlign: 'center' }}>{t('home.audio')}</p>
                          </>
                        </p>
                      </div>
                    )}
                  </Dropzone>
                </Spin>
              ) : (
                <div className="flex cursor-pointer flex-col items-center gap-1 rounded-xl border border-solid border-[#EAECF0] px-8 py-4">
                  <div className="flex w-full items-center gap-1 rounded-xl border border-solid border-[#EAECF0] px-5 py-2">
                    <CustomPlayer name={files?.originalName} type={files?.type} url={files?.absolutePath} forDownload={true} />
                    <TrushIcon onClick={() => setFiles(undefined)} />
                  </div>
                </div>
              )}
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <ConfirmModal visibleConfirm={visibleConfirm} setVisibleConfirm={setVisibleConfirm} />
    </>
  );
};

export default CreateRobotCall;
