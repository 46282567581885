import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AccessDenied from '../../components/accessDenied/AccessDenied';
import NavBar from '../../components/navbars/developer/NavBar';
import { Layout } from 'antd';
import developerRoutes from './developerRoutes';

const DeveloperRouter = () => {
  return (
    <div>
      <Layout style={{ height: '100vh' }}>
        <NavBar />
        <Layout>
          <Routes>
            {developerRoutes.map(route => (
              <Route element={<route.component />} path={route.exact ? route.path : `${route.path}/*`} key={route.path} />
            ))}
            <Route path={'*'} element={<AccessDenied />} />
          </Routes>
        </Layout>
      </Layout>
    </div>
  );
};

export default DeveloperRouter;
