import React, { useState } from 'react';
import { Select } from 'antd';

import styles from './header.module.scss';
import { BoosComplexSelectType } from '../../../../utils/models/BoosComplexSelectType';
import SpecialTimeModal from '../../../bossComplex/SpecialTimeModal';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { setChangeDefaultGlobalValue, setDatePipe } from '../../../../store/reducers/CrmStatisticsSlice';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../../../utils/constants/format';
import { START_DATE_DEFAULT } from '../../../../utils/constants/startDateDefault';

const { Option } = Select;

const DateSelect = () => {
  const { defaulGlobaltValue } = useAppSelector(state => state.crmStatistic);
  const [specialModal, setSpecialModal] = useState(false);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  // change date
  const changeDate = (value: string) => {
    switch (value) {
      case BoosComplexSelectType.TODAY:
        dispatch(setChangeDefaultGlobalValue(value));
        setToday();
        break;
      case BoosComplexSelectType.YESTERDAY:
        dispatch(setChangeDefaultGlobalValue(value));
        setYesterday();
        break;
      case BoosComplexSelectType.THIS_WEEK:
        dispatch(setChangeDefaultGlobalValue(value));
        setThisWeek();
        break;
      case BoosComplexSelectType.THIS_MONTH:
        dispatch(setChangeDefaultGlobalValue(value));
        setThisMonth();
        break;
      case BoosComplexSelectType.SPECIAL:
        setSpecial();
        break;
      default:
        dispatch(setChangeDefaultGlobalValue(value));
        setNow();
        break;
    }
  };

  // set now
  const setNow = () => {
    dispatch(
      setDatePipe({
        finishData: dayjs(new Date()).format(DATE_FORMAT),
        startDate: START_DATE_DEFAULT
      })
    );
  };

  // set today
  const setToday = () => {
    dispatch(
      setDatePipe({
        finishData: dayjs(new Date()).format(DATE_FORMAT),
        startDate: dayjs(new Date()).format(DATE_FORMAT)
      })
    );
  };

  // set yesterday
  const setYesterday = () => {
    dispatch(
      setDatePipe({
        finishData: dayjs(new Date()).add(-1, 'day').format(DATE_FORMAT),
        startDate: dayjs(new Date()).add(-1, 'day').format(DATE_FORMAT)
      })
    );
  };

  // set this week
  const setThisWeek = () => {
    if (dayjs(new Date()).startOf('week').format(DATE_FORMAT) === dayjs(new Date()).format(DATE_FORMAT)) {
      dispatch(
        setDatePipe({
          finishData: dayjs(new Date()).format(DATE_FORMAT),
          startDate: dayjs(new Date()).add(-6, 'day').format(DATE_FORMAT)
        })
      );
    } else {
      dispatch(
        setDatePipe({
          finishData: dayjs(new Date()).format(DATE_FORMAT),
          startDate: dayjs(new Date()).startOf('week').add(1, 'day').format(DATE_FORMAT)
        })
      );
    }
  };

  const setThisMonth = () => {
    dispatch(
      setDatePipe({
        finishData: dayjs(new Date()).format(DATE_FORMAT),
        startDate: dayjs(new Date()).startOf('month').format(DATE_FORMAT)
      })
    );
  };

  // set special
  const setSpecial = () => {
    handleSpecialModal(true);
  };

  // handle special modal
  const handleSpecialModal = (visible: boolean) => {
    setSpecialModal(visible);
  };

  // handle select date
  const handleSelectDate = (date: string[]) => {
    dispatch(setChangeDefaultGlobalValue(`${date[0]}-${date[1]}`));
    // dispatch(
    //   setDatePipe({
    //     finishData: String(dayjs(date[1], DATE_FORMAT).endOf('d').unix()),
    //     startDate: String(dayjs(date[0], DATE_FORMAT).unix()),
    //   })
    // )
    dispatch(
      setDatePipe({
        finishData: date[1],
        startDate: date[0]
      })
    );
  };

  return (
    <>
      <Select className={'customSelect ' + styles.container_select_date} value={defaulGlobaltValue} onChange={changeDate}>
        <Option value={BoosComplexSelectType.NOW}>{t('boss_complex.Shu_kungacha')}</Option>
        <Option value={BoosComplexSelectType.TODAY}>{t('boss_complex.Bugun')}</Option>
        <Option value={BoosComplexSelectType.YESTERDAY}>{t('boss_complex.Kecha')}</Option>
        <Option value={BoosComplexSelectType.THIS_WEEK}>{t('boss_complex.Joriy_hafta')}</Option>
        <Option value={BoosComplexSelectType.THIS_MONTH}>{t('boss_complex.Joriy_oy')}</Option>
        <Option value={BoosComplexSelectType.SPECIAL}>{t('boss_complex.Maxsus')}</Option>
      </Select>
      <SpecialTimeModal visible={specialModal} close={handleSpecialModal} handleSelect={handleSelectDate} />
    </>
  );
};

export default DateSelect;
