import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import ClearClientIcon from '@icons/ClearClientIcon';
import EditClientIcon from '@icons/client/EditClientIcon';
import MailClientIcon from '@icons/client/MailClientIcon';
import ClientStatus from '@icons/ClientStatus';
import DollarSquareBorderredIcon from '@icons/DollarSquareBorderredIcon';
import MiniMenuIcon from '@icons/MiniMenuIcon';
import PremiumClientIcon from '@icons/PremiumClientIcon';
import VipClientIcon from '@icons/VipClientIcon';
import { useUpdateClientLevel } from '@pages/arrearage/services/mutation';
import { setOpenClientMessage } from '@reducers/CrmOneLidSlice';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { Permissions } from '@utils/constants/permissions';
import { GetClientByType } from '@utils/models/Client';
import { Button, Dropdown, Popover, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { SearchParamsClient } from '../Client';

import styles from '../../arrearage/arrearage.module.scss';

type Props = {
  id: number;
  phone: string;
  name: string;
  type?: boolean;
};

const MenuDropDown: React.FC<Props> = ({ id, name, phone, type }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { push } = useQueryParam<SearchParamsClient, null>();
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);
  const [openPopover, setOpenPopover] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const isAccessUpdateClient = permissions?.includes(Permissions.PERMISSION_UPDATE_CLIENT);

  const isAccessSendMessage = permissions?.includes(Permissions.PERMISSION_SEND_MESSAGE_TO_CLIENT);

  const handeUpdateClient = (id: number) => {
    push(`${rootPaths.MAIN + MainPaths.CLIENT}/${GetClientByType.CLIENT_ID}=${id}${MainPaths.CLIENT_INFO}`);
  };

  // handleClientBalance
  const handleClientBalance = (clientId: number) => {
    push(`${rootPaths.MAIN + MainPaths.PAYMENT}/${clientId}${MainPaths.CLIENT_BALANCE}`);
  };

  // openDropdownFunc
  const handleOpenPopover = (isOpen: boolean) => {
    setOpenPopover(isOpen);
  };

  // openDropdownFunc
  const handleOpenDropdown = (isOpen: boolean) => {
    setOpenDropdown(isOpen);
  };

  const updateClientLevel = useUpdateClientLevel();

  const handleUpdateClientLevel = (level: string) => {
    updateClientLevel.mutate({ level, clientId: id });
  };

  return (
    <Dropdown
      overlayClassName={styles.arrearage_dropdown}
      overlay={
        <>
          {isAccessUpdateClient && (
            <div
              onClick={e => {
                handeUpdateClient(id);
                e.stopPropagation();
              }}
            >
              <EditClientIcon />
              <span>{t('client.Tahrirlash')}</span>
            </div>
          )}
          {isAccessSendMessage && !type && (
            <div
              onClick={e => {
                dispatch(
                  setOpenClientMessage({
                    id,
                    isVisible: true,
                    name,
                    phone
                  })
                );
                e.stopPropagation();
              }}
            >
              <MailClientIcon />
              <span>{t('client.SMS_yuborish')}</span>
            </div>
          )}
          <Popover
            content={
              <Spin spinning={updateClientLevel?.isLoading}>
                <div onClick={e => e.stopPropagation()}>
                  <div
                    className="flex cursor-pointer items-center gap-2 px-[5px] py-1.5"
                    onClick={() => {
                      handleUpdateClientLevel('VIP');
                      handleOpenDropdown(false);
                      handleOpenPopover(false);
                    }}
                  >
                    <VipClientIcon />
                    <Tooltip
                      overlayStyle={{ maxWidth: '490px' }}
                      overlay={
                        <div className="flex items-center gap-1">
                          <VipClientIcon />
                          <span className="text-xs font-medium text-[#ffffff]">{t('home.Vip_mijoz_text')}</span>
                        </div>
                      }
                    >
                      <span className="text-xs font-medium text-[#374151]">{t('home.Vip_mijoz')}</span>
                    </Tooltip>
                  </div>
                  <div
                    className="flex cursor-pointer items-center gap-2 px-[5px] py-1.5"
                    onClick={() => {
                      handleUpdateClientLevel('REGULAR');
                      handleOpenDropdown(false);
                      handleOpenPopover(false);
                    }}
                  >
                    <PremiumClientIcon />
                    <Tooltip
                      overlay={
                        <div className="flex items-center gap-1">
                          <PremiumClientIcon />
                          <span className="text-xs font-medium text-[#ffffff]">{t('home.Premium_mijoz_text')}</span>
                        </div>
                      }
                      overlayStyle={{ maxWidth: 440 }}
                    >
                      <span className="text-xs font-medium text-[#374151]">{t('home.Premium_mijoz')}</span>
                    </Tooltip>
                  </div>
                  <div
                    className="flex cursor-pointer items-center gap-2 px-[5px] py-1.5"
                    onClick={() => {
                      handleUpdateClientLevel('STANDARD');
                      handleOpenDropdown(false);
                      handleOpenPopover(false);
                    }}
                  >
                    <ClearClientIcon />
                    <span className="text-xs font-medium text-[#374151]">{t('home.Odatiy_mijoz')}</span>
                  </div>
                </div>
              </Spin>
            }
            placement="leftTop"
            trigger={['click', 'hover']}
            mouseEnterDelay={1000}
            mouseLeaveDelay={0.3}
            visible={openPopover}
            onVisibleChange={handleOpenPopover}
          >
            <div onClick={e => e.stopPropagation()}>
              <ClientStatus />
              <span>{t('home.Mijoz_statusi')}</span>
            </div>
          </Popover>
          <div
            onClick={e => {
              e.stopPropagation();
              handleClientBalance(id);
            }}
          >
            <DollarSquareBorderredIcon />
            <span>{t('payment.Mijoz_balansi')}</span>
          </div>
        </>
      }
      trigger={['click']}
      visible={openDropdown}
      onVisibleChange={handleOpenDropdown}
    >
      <Button
        onClick={e => {
          e.stopPropagation();
        }}
        shape="circle"
        className="d-flex align-center justify-center"
        style={{ border: 'none' }}
      >
        <MiniMenuIcon />
      </Button>
    </Dropdown>
  );
};

export default MenuDropDown;
