import React, { FC, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import Info from '@icons/Info';
import { Table } from 'antd';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import ArrowLineDownIcon from '../../../../assets/icons/ArrowLineDownIcon';
import ArrowLeftIcon from '../../../../assets/icons/contract/ArrowLeftIcon';
import ArrowRightIcon from '../../../../assets/icons/contract/ArrowRightIcon';
import DotIcon from '../../../../assets/icons/contract/DotIcon';
import TrashIcon from '../../../../assets/icons/contract/TrashIcon';
import GlphIcon from '../../../../assets/icons/GlphIcon';
import DeletePaymentModal from '../../../../components/createPayment/DeletePaymentModal';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { useDownloadPaymentToWord } from '../../../../queries/mutation';
import { usePayment } from '../../../../queries/queries';
import { ContractId } from '../../../../utils/models/ContractId';
import { Payment } from '../../../../utils/models/Payment';
import ContractIdPaymentInfo from '../contract-id-payment-info-modal/ContractIdPaymentInfo';

import ContractIdCommentModal from './ContractIdCommentModal';

import styles from './contractIdPaymentHistory.module.scss';

interface IProps {
  data: ContractId | undefined;
}

const ContractIdPaymentHistory: FC<IProps> = ({ data }) => {
  const [activePaymentDownload, setActivePaymentDownload] = useState(0);
  const [paymentInfoVisible, setPaymentInfoVisible] = useState(false);
  const [paymentId, setPayment] = useState<number>();
  const downloadPayment = useDownloadPaymentToWord();
  const { t } = useTranslation();
  const paymentTypes = {
    CASH: (
      <span className={styles.paymentType}>
        <DotIcon color="#12B76A" /> {t('home.CASH')}
      </span>
    ),
    BANK: (
      <span className={styles.paymentType} style={{ color: '#FFBB54', background: '#FFFFE0' }}>
        <DotIcon color="#FFBB54" /> {t('home.BANK')}
      </span>
    ),
    BALANCE: (
      <span className={styles.paymentType} style={{ color: '#3772FF', background: '#ECFDF3' }}>
        <DotIcon color="#3772FF" /> {t('home.BALANCE')}
      </span>
    ),
    CARD: (
      <span className={styles.paymentType} style={{ color: '#FFBB54', background: '#FFFFE0' }}>
        <DotIcon color="#FFBB54" /> {t('home.CARD')}
      </span>
    ),
    UZUM: (
      <span className={styles.paymentType} style={{ color: '#FFBB54', background: '#FFFFE0' }}>
        <DotIcon color="#FFBB54" /> {t('home.UZUM')}
      </span>
    ),
    PAYME: (
      <span className={styles.paymentType} style={{ color: '#3772FF', background: '#ECFDF3' }}>
        <DotIcon color="#3772FF" /> {t('home.PAYME')}
      </span>
    ),
    MY_UZCARD: (
      <span className={styles.paymentType} style={{ color: '#FFBB54', background: '#FFFFE0' }}>
        <DotIcon color="#FFBB54" /> {t('home.MY_UZCARD')}
      </span>
    ),
    TRANSFER: (
      <span className={styles.paymentType}>
        <DotIcon color="#12B76A" /> {t('home.TRANSFER')}
      </span>
    ),
    OTHERS: (
      <span className={styles.paymentType} style={{ color: '#6927DA', background: '#F5F3FF' }}>
        <DotIcon color="#6927DA" /> {t('home.OTHERS')}
      </span>
    )
  };
  const [deleteData, setDeleteData] = useState({
    visible: false,
    id: 0
  });
  const { params, location } = useQueryParam();
  const query = queryString.parse(location.search);
  const { data: payments, isLoading: paymentsLoading } = usePayment(params.id || Number(query?.contractId === '0' ? '' : query?.contractId) || '');
  const [comment, setComment] = useState({
    visible: false,
    text: '',
    employee: '',
    date: ''
  });

  const handleComment = (prev: Payment) => {
    setComment({
      date: prev?.canceledDate,
      employee: prev.canceledBy,
      text: prev?.cancelNote,
      visible: true
    });
  };

  const handleDelete = (prev: Payment) => {
    setDeleteData({ id: prev.id, visible: true });
  };

  const onCancel = () => {
    setDeleteData({
      id: 0,
      visible: false
    });
  };

  console.log('order-table', payments!);

  const columns = [
    {
      title: '№',
      dataIndex: 'number',
      key: 'number',
      width: 90
    },
    {
      title: t('home.Mijoz'),
      dataIndex: 'payer',
      key: 'payer',
      width: 220,
      render: (prev: string) => <span title={prev}>{prev.split(' ').slice(0, 2).join(' ')}</span>
    },
    {
      title: t('home.Sana'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (prev: string) => <span className={styles.container_table_text}>{prev}</span>,
      width: 200
    },
    {
      title: t('home.Summa'),
      key: 'amount',
      render: (record: Payment) => (
        <span className={styles.container_table_text}>
          {record.amount?.toLocaleString('ru')} {record?.currency?.ccy}
        </span>
      ),
      width: 150
    },
    {
      title: t('contract_id.Tolov_turi'),
      dataIndex: 'type',
      key: 'type',
      render: (prev: string) => paymentTypes[prev as keyof typeof paymentTypes],
      width: 160
    },
    {
      dataIndex: 'cancelNote',
      key: 'cancelNote',
      render: (cancelNote: string, prev: Payment) => (
        <div className={styles.actions}>
          {cancelNote ? (
            <span className={styles.spanCursor} onClick={() => handleComment(prev)}>
              <GlphIcon size={24} />{' '}
            </span>
          ) : (
            <>
              <span
                onClick={() => {
                  setPayment(prev?.id);
                  setPaymentInfoVisible(true);
                }}
              >
                <Info size={25} />
              </span>
              <span
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  downloadPaymentToWord(data?.id!, prev?.id, prev?.number);
                  setActivePaymentDownload(prev?.id);
                }}
              >
                {downloadPayment?.isLoading && prev?.id === activePaymentDownload ? <LoadingOutlined /> : <ArrowLineDownIcon color="#3772FF" size={24} />}
              </span>
              <span className={styles.spanCursor} onClick={() => handleDelete(prev)}>
                <TrashIcon />
              </span>
            </>
          )}
        </div>
      ),
      width: 100
    }
  ];

  const downloadPaymentToWord = (contractId: number, id: number, number: string) => {
    downloadPayment.mutate({
      id,
      number,
      contractId
    });
  };

  return (
    <div className={styles.container}>
      <Table
        dataSource={payments}
        columns={columns}
        loading={paymentsLoading}
        pagination={{
          hideOnSinglePage: true,
          className: styles.container_pagination,
          prevIcon: (
            <div className={styles.container_pagination_prev}>
              <ArrowLeftIcon /> {t('integration.Oldingi')}
            </div>
          ),
          nextIcon: (
            <div className={styles.container_pagination_next}>
              {t('profile.Keyingi')} <ArrowRightIcon />
            </div>
          )
        }}
        className={styles.container_table}
        rowClassName={prev => (prev.cancelNote ? styles.container_table_closed : '')}
      />
      <ContractIdCommentModal comment={comment} setComment={setComment} />
      <DeletePaymentModal contractId={data?.id!} id={deleteData?.id} visible={deleteData?.visible} onCancel={onCancel} />
      {paymentInfoVisible && <ContractIdPaymentInfo visible={paymentInfoVisible} setVisible={setPaymentInfoVisible} data={payments!} paymentId={paymentId!} />}
    </div>
  );
};

export default ContractIdPaymentHistory;
