/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import { usePipeAll } from '@queries/queries';
import i18n from '@service/I18nService';
import { queryNames } from '@utils/constants/queryNames';
import { encodeString } from '@utils/helper';
import { textCapitalize } from '@utils/helper/textConverter';
import { CrmField } from '@utils/models/CrmField';
import { CrmFieldsType } from '@utils/models/CrmFieldsType';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { MainPaths } from '../../../../../../routing/main/mainPaths';
import { rootPaths } from '../../../../../../routing/rootPaths';
import { urlEncode } from '../../../../../../utils/helper/urlEncodeDecode';
import { LEAD_EVENT_TYPE, OneEvent } from '../../../../../../utils/models/LeadEventsAndTaskList';
import { convertLocation } from '../../../information/info/Info';

import styles from '../taskChatContainer.module.scss';

interface Props {
  event: OneEvent;
}

const Event: React.FC<Props> = ({ event }) => {
  const { t } = useTranslation();
  const qc = useQueryClient();
  const fields: CrmField[] | undefined = qc.getQueryData([queryNames.CRM_FIELDS]);
  const { data: dataPipeAll } = usePipeAll();

  // const openMap = () => {
  //   if (event.fieldType === CrmFieldsType.LOCATION) {
  //     const location = convertLocation(event.current);

  //     if (location) {
  //       const { lat, lng, radius } = location;

  //       window.open(`https://yandex.uz/maps/?ll=${lng}%2C${lat}&z=${radius}`, '_blank');
  //     }
  //   } else if (event.currentAddress) {
  //     const address = `${event.currentAddress[`country_${i18n.language}` as keyof typeof event.currentAddress]}, ${event.currentAddress[`region_${i18n.language}` as keyof typeof event.currentAddress] === null
  //       ? ''
  //       : event.currentAddress[`region_${i18n.language}` as keyof typeof event.currentAddress]
  //       } ${event.currentAddress[`region_${i18n.language}` as keyof typeof event.currentAddress] === null ? '' : ','} ${event.currentAddress[`city_${i18n.language}` as keyof typeof event.currentAddress] === null
  //         ? ''
  //         : event.currentAddress[`city_${i18n.language}` as keyof typeof event.currentAddress]
  //       } ${event.currentAddress[`city_${i18n.language}` as keyof typeof event.currentAddress] === null ? '' : ','
  //       } ${event.currentAddress?.street}`;

  //   }
  // };

  const getAddress = (event: OneEvent) => {
    let address = ''; // Объявляем переменную здесь

    if (event.fieldType !== CrmFieldsType.LOCATION && event.currentAddress) {
      address = `${event.currentAddress[`country_${i18n.language}` as keyof typeof event.currentAddress]}, ${event.currentAddress[`region_${i18n.language}` as keyof typeof event.currentAddress] === null
        ? ''
        : event.currentAddress[`region_${i18n.language}` as keyof typeof event.currentAddress]
        } ${event.currentAddress[`region_${i18n.language}` as keyof typeof event.currentAddress] === null ? '' : ','} ${event.currentAddress[`city_${i18n.language}` as keyof typeof event.currentAddress] === null
          ? ''
          : event.currentAddress[`city_${i18n.language}` as keyof typeof event.currentAddress]
        } ${event.currentAddress[`city_${i18n.language}` as keyof typeof event.currentAddress] === null ? '' : ','} ${event.currentAddress?.street}`;
    }

    return address; // Возвращаем адрес
  };

  const openMap = () => {
    if (event.fieldType === CrmFieldsType.LOCATION) {
      const location = convertLocation(event.current);

      if (location) {
        const { lat, lng, radius } = location;

        window.open(`https://www.google.com/maps/?daddr=${lat},${lng}`, '_blank');
      }
    } else if (event.currentAddress) {
      const address = getAddress(event); // Вызываем функцию getAddress здесь
    }
  };

  const field = fields?.find(item => item.type === event?.fieldType);
  const fieldName =
    field && field[`name${textCapitalize(i18n.language)}` as keyof typeof field] ? field[`name${textCapitalize(i18n.language)}` as keyof typeof field] : event?.fieldName;

  if (event.type === LEAD_EVENT_TYPE.CREATE_LED) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.Yangi_lead_yaratildi')}
        </span>
        <span className={styles.container_event_value_name}>
          {event?.fieldType === CrmFieldsType.LOCATION ? convertLocation(event.leadCurrentName)?.name || 'Location' : event?.leadCurrentName}
        </span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.SET_VALUE) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {fieldName} {t('one_lid.qiymatini_ozgartirildi')}{' '}
          {event?.previous && (
            <>
              {' '}
              {t('one_lid.Avvaligi_qiymati')}
              {event?.fieldType === CrmFieldsType.LOCATION ? (
                (event?.previous && convertLocation(event.previous)?.name) || 'Location'
              ) : event?.prevAddress && event?.fieldType === CrmFieldsType.ADDRESS ? (
                `${event?.prevAddress[`country_${i18n.language}` as keyof typeof event.prevAddress]}, ${event?.prevAddress[`region_${i18n.language}` as keyof typeof event.prevAddress] === null
                  ? ''
                  : event?.prevAddress[`region_${i18n.language}` as keyof typeof event.prevAddress]
                } ${event?.prevAddress[`region_${i18n.language}` as keyof typeof event.prevAddress] === null ? '' : ','} ${event?.prevAddress[`city_${i18n.language}` as keyof typeof event.prevAddress] === null
                  ? ''
                  : event?.prevAddress[`city_${i18n.language}` as keyof typeof event.prevAddress]
                } ${event?.prevAddress[`city_${i18n.language}` as keyof typeof event.prevAddress] === null ? '' : ','} ${event?.prevAddress?.street && event?.prevAddress?.street}`
              ) : (
                <b style={{ color: 'var(--gun-powder)' }}>{event?.previous}</b>
              )}{' '}
              {t('one_lid.Hozirgi_qiymati')}
            </>
          )}
          {event?.current && !event?.currentAddress && <span style={{ color: 'var(--gun-powder)', fontWeight: 600 }}>{event?.current}</span>}
        </span>
        {/* <span className={styles.container_event_value_name}>
          {event?.fieldType === CrmFieldsType.LOCATION
            ? convertLocation(event.current)?.name || 'Location'
            : event?.currentAddress &&
            `${event?.currentAddress[`country_${i18n.language}` as keyof typeof event.currentAddress]}, ${event?.currentAddress[`region_${i18n.language}` as keyof typeof event.currentAddress] === null
              ? ''
              : event?.currentAddress[`region_${i18n.language}` as keyof typeof event.currentAddress]
            } ${event?.currentAddress[`region_${i18n.language}` as keyof typeof event.currentAddress] === null ? '' : ','} ${event?.currentAddress[`city_${i18n.language}` as keyof typeof event.currentAddress] === null
              ? ''
              : event?.currentAddress[`city_${i18n.language}` as keyof typeof event.currentAddress]
            } ${event?.currentAddress[`city_${i18n.language}` as keyof typeof event.currentAddress] === null ? '' : ','
            } ${event?.currentAddress?.street}`}
        </span> */}
        {/* <span className={styles.container_event_value_name} onClick={openMap}>
          {event?.fieldType === CrmFieldsType.LOCATION
            ? convertLocation(event.current)?.name || 'Location'
            : event?.currentAddress && address}
        </span> */}

        <span className={styles.container_event_value_name} onClick={openMap} style={{ cursor: 'pointer' }}>
          {event?.fieldType === CrmFieldsType.LOCATION ? convertLocation(event.current)?.name || 'Location' : event?.currentAddress && getAddress(event)}
        </span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.CREATE_CONTACT) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.Yangi_kontakt_yaratildi')}
        </span>
        <span className={styles.container_event_value_name}>{event.contactCurrentName}</span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.CREATE_TAG) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.Teg_qoshildi')}
        </span>
        <span className={styles.container_event_value_tag}>{event.currentTagName}</span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.REMOVE_TAG) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.Teg_olib_tashlandi')}
        </span>
        <span className={styles.container_event_value_tag}>{event.previousTagName}</span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.CHANGE_STATUS) {
    const currentPipeName = dataPipeAll?.find(item => item?.pipeStatuses?.find(prev => prev?.id === Number(event?.currentId)))?.name;
    const previusPipeName = dataPipeAll?.find(item => item?.pipeStatuses?.find(prev => prev?.id === Number(event?.previousId)))?.name;

    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.Yangi_bosqich')}
        </span>
        {event.previous && (
          <span className={styles.container_event_old_status}>
            {previusPipeName} | {event.previous}
          </span>
        )}
        <span
          style={{
            // @ts-ignore
            '--color': event.currentColor
          }}
          className={styles.container_event_newStatus}
        >
          {currentPipeName} | {event.current}
        </span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.CHANGE_VALUE) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {fieldName} {t('one_lid.ozgartirildi')}
          {t('one_lid.Avvaligi_qiymati')} {event.previous}. {t('one_lid.Hozirgi_qiymati')}
        </span>
        <span className={styles.container_event_value_tag}>{event.current}</span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.DELETE_VALUE) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {fieldName} {t('one_lid.ochirildi')} {t('one_lid.Avvaligi_qiymati')}
        </span>
        <span className={styles.container_event_value_tag}>
          {event?.fieldType === CrmFieldsType.LOCATION
            ? convertLocation(event.previous)?.name || 'Location'
            : event?.fieldType === CrmFieldsType.ADDRESS && event?.prevAddress
              ? `${event?.prevAddress[`country_${i18n.language}` as keyof typeof event.prevAddress]}, ${event?.prevAddress[`region_${i18n.language}` as keyof typeof event.prevAddress] === null
                ? ''
                : event?.prevAddress[`region_${i18n.language}` as keyof typeof event.prevAddress]
              } ${event?.prevAddress[`region_${i18n.language}` as keyof typeof event.prevAddress] === null ? '' : ','} ${event?.prevAddress[`city_${i18n.language}` as keyof typeof event.prevAddress] === null
                ? ''
                : event?.prevAddress[`city_${i18n.language}` as keyof typeof event.prevAddress]
              } ${event?.prevAddress[`city_${i18n.language}` as keyof typeof event.prevAddress] === null ? '' : ','} ${event?.prevAddress?.street && event?.prevAddress?.street}`
              : event?.previous}
        </span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.CHANGE_LEAD_NAME) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.Nom_ozgartirildi')} {t('one_lid.Hozirgi_qiymati')} {event.leadPreviousName}. {t('one_lid.Hozirgi_qiymati')}
        </span>
        <span className={styles.container_event_value_tag}>{event.leadCurrentName}</span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.CHANGE_LEAD_BALANCE) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.Balans_ozgartirildi')} {t('one_lid.Avvaligi_qiymati')} {event.previousBalance.toLocaleString('ru')}.{' '}
          {t('one_lid.Hozirgi_qiymati')}
        </span>
        <span className={styles.container_event_value_tag}>{event.currentBalance.toLocaleString('ru')}</span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.CHANGE_LEAD_EMPLOYEE) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.Masul_shaxs_ozgartirildi')} {t('one_lid.Avvaligi_qiymati')} {event.previousEmployeeName}. {t('one_lid.Hozirgi_qiymati')}
        </span>
        <span className={styles.container_event_value_tag}>{event.currentEmployeeName}</span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.SET_LEAD_EMPLOYEE) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.Masul_shaxs_biriktirildi')}
        </span>
        <span className={styles.container_event_value_tag}>{event.currentEmployeeName}</span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.CHANGE_CONTACT_NAME) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.Kontakt_nomi_ozgartirildi')} {t('one_lid.Avvaligi_qiymati')} {event.contactPreviousName}. {t('one_lid.Hozirgi_qiymati')}
        </span>
        <span className={styles.container_event_value_tag}>{event.contactCurrentName}</span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.REMOVE_CONTACT_PHONE) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.Kontakt_raqami_ochirildi')}. {t('one_lid.Avvaligi_qiymati')}
        </span>
        <span className={styles.container_event_value_tag}>{event.contactPreviousPhone}</span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.CREATE_CONTACT_PHONE) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.Kontakt_raqami_yaratildi')}:
        </span>
        <span className={styles.container_event_value_tag}>{event.contactCurrentPhone}</span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.CREATE_REASONS_FOR_REFUSAL) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.Muvaffaqiyatsiz_yopildi')}:
        </span>
        <span className={styles.container_event_value_tag}>{event.reasonsPreviousValue}</span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.CHANGE_REASONS_FOR_REFUSAL) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.Muvaffaqiyatsiz_yopildi')}:
        </span>
        <span className={styles.container_event_value_tag}>{event.reasonsPreviousValue}</span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.REMOVE_CONTACT) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.Kontakt_ochirildi')}:
        </span>
        <span className={styles.container_event_value_tag}>{event.contactPreviousPhone}</span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.DELETE_LEAD) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.lidni_ochirdi')}
        </span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.RECOVERY_LEAD) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.lidni_qayta_tikladi')}
        </span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.CREATE_STATUS) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.Yangi_bosqich')}{' '}
          <span
            style={{
              // @ts-ignore
              '--color': event.currentColor,
              borderRadius: 30
            }}
            className={styles.container_event_newStatus}
          >
            {event.current}
          </span>
        </span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.LEAD_MERGE) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.birlashtirdi')}:{' '}
          <Link to={`${rootPaths.MAIN + MainPaths.CRM}/${event?.mergeLeadId}/${encodeString(event?.mergeLeadName)}?prev=true`} className={styles.container_event_value_name}>
            {event?.mergeLeadName}
          </Link>
        </span>
      </p>
    );
  }

  if (event.type === LEAD_EVENT_TYPE.ATTACH_LEAD_TO_CLIENT) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.Lidga_mijoz_biriktirdi')}
          <Link to={`${rootPaths.MAIN + MainPaths.CLIENT}/${event?.clientId}/${urlEncode(String(event?.clientName))}`} className={styles.container_event_value_name}>
            {event?.clientName}
          </Link>
        </span>
      </p>
    );
  }
  if (event.type === LEAD_EVENT_TYPE.REMOVE_CLIENT_FROM_LEAD) {
    return (
      <p className={styles.container_event}>
        <span className={styles.container_event_title}>
          {event.date} {event.createdBy} {t('one_lid.Liddan_mijozni_ajratdi')}
          <Link to={`${rootPaths.MAIN + MainPaths.CLIENT}/${event?.clientId}/${event?.clientName}`} className={styles.container_event_value_name}>
            {event?.clientName}
          </Link>
        </span>
      </p>
    );
  }

  return <div />;
};

export default Event;
