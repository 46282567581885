import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryParam } from '@hooks/useQueryParams';
import { Input, Spin } from 'antd';
import { t } from 'i18next';

import { useCompanyCompact } from '../services/queries';

import rightA from '../assets/right_arrow.svg';

import style from '../companyPermissionConfig.module.scss';

// Компонент для поиска компании
const CompanyPermissionSearch = () => {
  const { searchParams } = useQueryParam<any, any>();
  const companyId = Number(searchParams?.companyId); // Получение ID компании из параметров запроса
  const { data: dataCompanies, isLoading } = useCompanyCompact(); // Получение компактной информации о компании
  const navigate = useNavigate(); // Функция для навигации
  const [searchTerm, setSearchTerm] = useState(''); // Состояние для хранения строки поиска

  // Обработчик клика по компании
  const handleClick = (companyId: number | undefined) => {
    navigate(`/developer/company-permission-config?companyId=${companyId}`); // Навигация на страницу настроек разрешений компании
  };

  // Обработчик поиска
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value); // Обновление состояния строки поиска
  };

  // Фильтрация компаний по строке поиска
  const filteredCompanies = dataCompanies?.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

  // Возвращаем компонент поиска компании
  return (
    <div className={style.cmps}>
      <Input type="search" className={style.input} placeholder={t('home.Qidirish')} onChange={handleSearch} />

      <ul className={style.cmps_list}>
        {isLoading && <Spin />}
        {filteredCompanies?.map(item => (
          <li
            className={`${style.cmps_item} ${companyId === item.id ? style.active : ''}`}
            key={item.id}
            onClick={() => handleClick(item.id)}
            style={{ cursor: 'pointer' }}
          >
            <p>{item.name}</p>
            {companyId === item.id && <img src={rightA} alt="arrow-right" />}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CompanyPermissionSearch;
