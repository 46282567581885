/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import InternationalPhoneNumber from '@components/international-phone-input/InternationalPhoneNumber';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { setClientPhone, setSearchByPhone } from '@reducers/ClientSlice';
import { BronIdOrFlatIds } from '@utils/models/BronIdOrFlatIds';
import { Button, Col, Form, Modal, Row, Space } from 'antd';
import { isValidNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';

import { useEditProfile } from '../../queries/mutation';
import { helper } from '../../utils/helper/helper';
import { Profile } from '../../utils/models/Profile';

import 'react-international-phone/style.css';
import styles from '../../pages/builderSettings/builderSettings.module.scss';

interface Props {
  visible: boolean;
  onClose: (visible: boolean) => void;
  profile?: Profile;
}

interface IForm {
  phone: string;
  phoneCode: string;
}

const ChangePhone: React.FC<Props> = ({ visible, onClose, profile }) => {
  const [form] = Form.useForm<IForm>();

  const { clientPhones } = useAppSelector(state => state.clientReducer);
  const params = useParams<{ orderIdOrFlatId: string }>();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const editProfile = useEditProfile(handleCancel);

  useEffect(() => {
    if (profile) {
      form.setFieldsValue({
        phone: profile.phone
      });
    }
  }, [profile, visible, form]);

  // handle close modal
  function handleCancel() {
    onClose(false);
    form.setFieldsValue({
      phone: ''
    });
  }

  // handle submit form
  const onFinish = (values: IForm) => {
    if (profile) {
      editProfile.mutate({
        ...profile,
        phone: values.phone,
        phoneCode: values.phoneCode
      });
    }
  };

  const handleSearchClient = (phone: string, index: number) => {
    dispatch(setClientPhone({ index, phone }));
    if (!params.orderIdOrFlatId?.includes(BronIdOrFlatIds.BRON_ID)) {
      const phoneNumber = helper.deleteNotNumbers(phone, 1);

      if (index === 0 && String(phoneNumber).length >= 3) {
        dispatch(setSearchByPhone(phoneNumber));
      }
    }
  };

  return (
    <Modal
      width={546}
      className="createBuilderModal"
      title={t('profile.Telefon_raqamini_ozgartirish')}
      visible={visible}
      footer={false}
      onCancel={handleCancel}
    >
      <Form onFinish={onFinish} form={form} layout="vertical" className={styles.formBuilderModal}>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            {clientPhones.map((phone, index) => (
              <Space direction="horizontal" className="d-flex" key={phone?.id}>
                <div>
                  <Form.Item name="phoneCode" className="d-none" />

                  <div className="flex flex-col gap-[5px]">
                    <Form.Item
                      label={<span>{t(`changes.client.NaturalClient.Telefon_raqam`)}</span>}
                      name="phone"
                      rules={[
                        {
                          validator: (rule, value) => {
                            if (!(value && isValidNumber(value))) {
                              // eslint-disable-next-line prefer-promise-reject-errors
                              return Promise.reject('');
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                    >
                      <InternationalPhoneNumber
                        defaultCountry="uz"
                        className={styles.phoneInput}
                        onChange={(e, phone) => {
                          form.setFieldsValue({
                            [`phoneCode`]: `${phone?.country?.iso2?.toUpperCase()}`
                          });
                        }}
                      />
                    </Form.Item>

                    {/* {!isPhoneValid(phoneValue) && <span className="mt-[-10px] text-red-500">{t('profile.Telefon_raqamini_kiriting')}</span>} */}
                  </div>
                </div>
              </Space>
            ))}
          </Col>
        </Row>
        <div className={styles.modalFooter}>
          <Button onClick={handleCancel}> {t('home.Yopish')}</Button>
          <Button loading={editProfile.isLoading} htmlType="submit" type="primary">
            {t('profile.Tasdiqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangePhone;
