// import React from 'react'
// import { Layout } from 'antd'

// import styles from './bossContract.module.scss'
// import BossContractPeriod from '../../components/bossContract/bossContractPeriod/BossContractPeriod'
// import BossContractGraphic from '../../components/bossContract/bossContractGraphic/BossContractGraphic'
// import BossContractClient from '../../components/bossContract/bossContractClient/BossContractClient'
// import NotificationButtons from '../../components/headers/components/NotificationButtons'
// import MainHeader from '../../components/headers/MainHeader'
// import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader'

// const { Content } = Layout

// const BossContract = () => {
//   return (
//     <>
//       <MainHeader>
//         <BreadcrumbHeader />
//         <NotificationButtons />
//       </MainHeader>
//       <Content
//         style={{
//           padding: '1rem 2.68rem',
//           overflowY: 'auto',
//           height: '50vh',
//         }}
//       >
//
//       </Content>
//     </>
//   )
// }

// export default BossContract
import React from 'react';
import NavBar from '@components/navbars/boss-contract/Navbar';
import ContractStatisticsRouter from '@routing/contract-statistics/ContractStatisticsRouter';
import { t } from 'i18next';

import NotificationButtons from '../../components/headers/components/NotificationButtons';
import MainHeader from '../../components/headers/MainHeader';

import styles from './bossContract.module.scss';

const BossContract = () => (
  <>
    <MainHeader>
      <h1 className={styles.header}>{t(`titles.contract`)}</h1>
      <NotificationButtons />
    </MainHeader>
    <div className={styles.wrapper}>
      <NavBar />
      <ContractStatisticsRouter />
    </div>
  </>
);

export default BossContract;
