import React, { FC, useState } from 'react';
import { useTags } from '../../queries/queries';
import { Select } from 'antd';
// import styles from './tagSelect.module.scss'
import { useCreateTag } from '../../queries/mutation';

interface IProps {
  onChange?: (value: string[]) => void;
  value?: string[] | null | undefined;
}

const TagSelectV2: FC<IProps> = ({ onChange, value }) => {
  const { data } = useTags();
  const createTag = useCreateTag();
  const [search, setSearch] = useState('');
  return (
    <Select
      mode="multiple"
      size="middle"
      className={`customSelect`}
      value={value}
      onChange={onChange}
      searchValue={search}
      maxTagCount={'responsive'}
      onSearch={e => {
        setSearch(e);
      }}
      onInputKeyDown={e => {
        if (e.key === 'Enter') {
          createTag.mutateAsync(e?.currentTarget?.value).then(val => {
            onChange && onChange([...(value ? value : []), val?.data?.name as string]);
            setSearch('');
          });
        }
      }}
    >
      {data?.tagList?.map(item => <Select.Option value={item?.name}>{item?.name}</Select.Option>)}
    </Select>
  );
};

export default TagSelectV2;
