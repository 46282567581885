import { $api } from "@service/RequestService";
import { message } from "antd";
import { useMutation, useQueryClient } from "react-query"

import { endPoints } from "../end-points";
import { keys } from "../keys";
import { UzumIntegration, uzumSchema } from "../schema";

export const useCreateUzumConfig = () => {

  const client = useQueryClient()

  return useMutation<UzumIntegration, any, UzumIntegration>(
    async values => {

      const { data } = await $api.post(`${endPoints.UZUM_CONFIG}`, values);
      const service = uzumSchema.parse(data.data);

      return service;
    },
    {
      onSuccess: () => {
        client.invalidateQueries({ queryKey: [keys.UZUM_CONFIG] })
        message.success("Muvaffaqqiyatli")
      }
    }

  )
}