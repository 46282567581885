export enum PaymentType {
  CARD = 'CARD',
  CASH = 'CASH',
  TRANSFER = 'TRANSFER',
  BALANCE = 'BALANCE',
  BANK = 'BANK',
  APELSIN = 'APELSIN',
  UZUM = 'UZUM',
  PAYME = 'PAYME',
  MY_UZCARD = 'MY_UZCARD',
  OTHERS = 'OTHERS'
}
