import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { LocalStorage } from '../../service/LocalStorage';
import { TOKEN_KEY, USER_KEY } from '../../utils/constants/localStorageKeys';
import { User } from '../../utils/models/User';

const user = LocalStorage.get<User>(USER_KEY);

interface UserState {
  user: User | null;
  lifetime: number;
  widgets?: User['widgets'];
}

const initialState: UserState = {
  user: typeof user !== 'string' ? user : null,
  lifetime: Number(LocalStorage.get('lifetime')) || 0,
  widgets: LocalStorage.get('widgets')
    ? (LocalStorage.get('widgets') as User['widgets'])
    : [
        {
          icon: '',
          id: 'files',
          status: true,
          type: 'FILES'
        }
      ]
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      if (action.payload.roleTokens?.length! > 0) {
        state.user = { ...action.payload, role: action.payload?.roleTokens![0].role, branchId: action.payload?.roleTokens![0]?.branch?.id };
        LocalStorage.set(USER_KEY, JSON.stringify({ ...action.payload, role: action.payload.roleTokens![0].role, branchId: action.payload?.roleTokens![0]?.branch?.id }));
        LocalStorage.set(TOKEN_KEY, action.payload.roleTokens![0].accessToken);
      } else {
        state.user = action.payload;
        LocalStorage.set(USER_KEY, JSON.stringify(action.payload));
        LocalStorage.set(TOKEN_KEY, action.payload.accessToken);
      }
    },
    clearAvatar: state => {
      const user = { ...current(state.user) };

      if (user) {
        user.avatar = null;
        LocalStorage.set(USER_KEY, JSON.stringify(user));
        state.user = user as User;
      }
    },
    addAvatar: (state, action: PayloadAction<string>) => {
      const user = { ...current(state.user) };

      if (user) {
        user.avatar = action.payload;
        LocalStorage.set(USER_KEY, JSON.stringify(user));
        state.user = user as User;
      }
    },
    setLifeTime: (state, action: PayloadAction<number>) => {
      state.lifetime = action?.payload;
      LocalStorage.set('lifetime', String(action?.payload));
    },
    setNewBranch: (state, action: PayloadAction<number>) => {
      const foundBranch = state.user?.roleTokens?.find(el => el.branch.id === action.payload);

      if (foundBranch) {
        LocalStorage.set(USER_KEY, JSON.stringify({ ...state.user, role: foundBranch.role, branchId: action.payload } as User));
        LocalStorage.set(TOKEN_KEY, foundBranch.accessToken);
        state.user = { ...state.user, role: foundBranch.role } as User;
      }
    },
    setWidget: (state, action: PayloadAction<Partial<User['widgets'][number]> & { id: User['widgets'][number]['id'] }>) => {
      const { widgets } = current(state);

      const widgetsCopy = [...(widgets || [])].map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload
          };
        }
        return item;
      });

      state.widgets = widgetsCopy;
      LocalStorage.set('widgets', JSON.stringify(widgetsCopy));
    }
  }
});

export const { setUser, clearAvatar, addAvatar, setLifeTime, setNewBranch, setWidget } = userSlice.actions;
export default userSlice.reducer;
