import React from 'react';
import ArrowLeftMainHeader from '../../../assets/icons/ArrowLeftMainHeader'; // Импорт компонента стрелки влево
import styles from '../../headers/header.module.scss'; // Импорт стилей
import { pagesNameList } from '../../../utils/constants/pagesNameList'; // Импорт списка страниц
import ArrowRight from '../../../assets/icons/ArrowRight'; // Импорт компонента стрелки вправо
import { urlDecode } from '../../../utils/helper/urlEncodeDecode'; // Импорт утилиты декодирования URL
import { useQueryParam } from '../../../hooks/useQueryParams'; // Импорт хука для получения параметров запроса
import { useTranslation } from 'react-i18next'; // Импорт хука для перевода
import { useNavigate } from 'react-router-dom'; // Импорт хука для навигации
import { rootPaths } from '../../../routing/rootPaths'; // Импорт корневых путей
import { MainPaths } from '../../../routing/main/mainPaths'; // Импорт путей для главной страницы

const SettingsHeader = () => {
  const { splitUrl, push } = useQueryParam(); // Получение параметров запроса из URL

  const { t } = useTranslation(); // Инициализация перевода

  // Функция для определения глобального заголовка
  const globalTitle = () => {
    const findIndex = pagesNameList.indexOf(splitUrl[splitUrl.length - 1]); // Поиск индекса текущей страницы в списке страниц
    if (findIndex !== -1) {
      return t(`titles.${splitUrl[splitUrl.length - 1]}`); // Возврат переведенного заголовка, если он найден
    }
    return urlDecode(splitUrl[splitUrl.length - 1]); // Возврат декодированного заголовка, если перевод не найден
  };

  // Функция для навигации по URL
  const goTo = (index: number) => {
    let url = '';
    if (index === splitUrl.length - 1) {
      return;
    }
    for (let i = 0; i <= index; i++) {
      url += splitUrl[i];
      if (i !== index) {
        url += '/';
      }
    }
    push(url); // Обновление URL
  };

  // История назад
  const navigate = useNavigate(); // Получение функции навигации
  const historyBack = () => {
    navigate(rootPaths.MAIN + MainPaths.SETTING); // Навигация на страницу настроек
  };

  // Рендеринг компонента
  return (
    <div className={'d-flex align-center'}>
      {splitUrl.length !== 3 && (
        <ArrowLeftMainHeader
          onClick={historyBack} // Обработчик клика назад
          className={styles.icon_arrow_back} // Применение стиля для стрелки назад
        />
      )}
      <div className={styles.titleCont}>
        <div className={styles.main}>{globalTitle()}</div> {/* Отображение глобального заголовка */}
        {splitUrl.length !== 3 && (
          <div className={'d-flex align-center'}>
            {splitUrl.map((item, index) => {
              if (index > 0 && index % 2 === 0) {
                const findIndex = pagesNameList.indexOf(splitUrl[index]); // Поиск индекса текущей страницы в списке страниц
                if (findIndex !== -1) {
                  return (
                    <div
                      onClick={() => goTo(index)} // Обработчик клика для навигации
                      className={styles.sub_title} // Применение стиля для подзаголовка
                      key={index}
                    >
                      {t(`titles.${splitUrl[index]}`)} {/* Переведенный подзаголовок */}
                      {index !== splitUrl.length - 1 && (
                        <ArrowRight className={styles.icon_arrow} /> // Отображение стрелки вправо
                      )}
                    </div>
                  );
                }
                return (
                  <div
                    onClick={() => goTo(index)} // Обработчик клика для навигации
                    className={styles.sub_title} // Применение стиля для подзаголовка
                    key={index}
                  >
                    {urlDecode(splitUrl[index])} {/* Декодированный подзаголовок */}
                    {index !== splitUrl.length - 1 && (
                      <ArrowRight className={styles.icon_arrow} /> // Отображение стрелки вправо
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsHeader; // Экспорт компонента
