import { useSearchParams } from 'react-router-dom';
import { baseErrorHandler } from '@queries/baseErrorHandler';
import { $api } from '@service/RequestService';
import { useQuery } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { WebsiteUserType } from '../utils/models/WebsiteUserType';

export function useGetWebsiteUser() {
  const [searchParams] = useSearchParams();

  const search = searchParams.get('search');
  const page = searchParams.get('page');

  let url = `${endPoints.GET_WEBSITE_ALL_COMPANY}?page=${page || 1}&size=10`;

  if (search) {
    url += `&search=${search}`;
  }

  return useQuery<WebsiteUserType>(
    [queryKeys.GET_WEBSITE_ALL_COMPANY, page, search],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchInterval: 60000,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useGetCompanyAllCompanies() {
  return useQuery<any>(
    [queryKeys.GET_ALL_COMPANIES],
    async () => {
      const res = await $api.get(endPoints.GET_ALL_COMPANIES);

      return res.data.data;
    },
    {
      // enabled: !!id,
      refetchOnWindowFocus: false,
      // onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}
