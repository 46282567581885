import KonversiyaIcon from '@icons/KonversiyaIcon';
import { LocalStorage } from '@service/LocalStorage';
import { USER_KEY } from '@utils/constants/localStorageKeys';
import { Permissions } from '@utils/constants/permissions';
import { User } from '@utils/models/User';

import BarLineChartIcon from '../../../assets/icons/BarLineChartIcon';
import CreditCardIcon from '../../../assets/icons/CreditCardIcon';
import NotificationBoxIcon from '../../../assets/icons/NotificationBoxIcon';
import StarIcon from '../../../assets/icons/StarIcon';
import { bossCrmPaths } from '../../../routing/bossCrm/bossCrmPaths';
import { BossPagePaths } from '../../../routing/bossPage/bossPagePaths';
import { rootPaths } from '../../../routing/rootPaths';

const user = LocalStorage.get<User>(USER_KEY);

const defaultUrl = rootPaths.BOSS + BossPagePaths.CRM;

const navBarLinks = [
  {
    name: 'Umumiy',
    path: rootPaths.BOSS + BossPagePaths.CRM + bossCrmPaths.COMMON,
    icon: NotificationBoxIcon,
    permission: Permissions.PERMISSION_STATS_CRM_GENERAL
  },
  {
    name: 'Qongiroqlar',
    path: defaultUrl + bossCrmPaths.CALLS,
    icon: BarLineChartIcon,
    permission: Permissions.PERMISSION_STATS_CRM_CALLS
  },
  {
    name: 'task',
    path: defaultUrl + bossCrmPaths.TASKS,
    permission: Permissions.PERMISSION_STATS_CRM_TASKS,
    icon: StarIcon
  },
  {
    name: 'Lidlar',
    path: defaultUrl + bossCrmPaths.LEADS,
    permission: Permissions.PERMISSION_STATS_CRM_LEADS,
    icon: CreditCardIcon
  },
  {
    name: 'Konversiya',
    path: defaultUrl + bossCrmPaths.CONVERSION,
    icon: KonversiyaIcon
  }
];

const fullNavBarLinks: typeof navBarLinks = [];

if (typeof user === 'object' && user) {
  navBarLinks.forEach(link => {
    if (link.permission) {
      if (user.role.permissions.includes(link.permission) || user.role.permissions.includes(Permissions.PERMISSION_MOBILE)) {
        fullNavBarLinks.push(link);
      }
    } else {
      fullNavBarLinks.push(link);
    }
  });
}

export default fullNavBarLinks;
