import React, { FC, useState } from 'react';
import styles from './monetarySetting.module.scss';
import { Switch } from 'antd';
import { MonetaryConfig } from '../../../utils/models/MonetaryConfig';
import { useMonetaryConfigSet } from '../../../queries/mutation';

interface IProps {
  data: MonetaryConfig | undefined;
}

const MonetarySetting: FC<IProps> = ({ data }) => {
  const [activeId, setActiveId] = useState(0);
  const monetaryConfig = useMonetaryConfigSet();
  const changeCompanyActivite = (checked: boolean, id: number) => {
    setActiveId(id);
    if (data) {
      monetaryConfig.mutate({
        ...data,
        companies: data?.companies?.map(item => ({
          ...item,
          active: item?.id === id ? checked : item?.active
        }))
      });
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Kompaniyalar</h1>
      <div className={styles.header}>
        <div className={styles.header_item}>Kompaniya</div>
        <div className={styles.header_item}>Status</div>
      </div>
      <div className={styles.content}>
        {data?.companies?.map(item => (
          <div className={styles.content_item} key={item?.id}>
            <h1>{item?.name}</h1>
            <Switch
              checked={item?.active}
              onChange={checked => changeCompanyActivite(checked, item?.id)}
              loading={monetaryConfig?.isLoading && activeId === item?.id}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonetarySetting;
