import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ArrowRightMessage from '../../assets/icons/ArrowRightMessage';
import MessageText from '../../assets/icons/MessageText';
import SMSStarIcon from '../../assets/icons/SMSStarIcon';
import SmsTracking from '../../assets/icons/SmsTracking';
import { useQueryParam } from '../../hooks/useQueryParams';
import { MainPaths } from '../../routing/main/mainPaths';
import { messagePath } from '../../routing/messageConfigRoutes/MessagePaths';
import { rootPaths } from '../../routing/rootPaths';
import { SettingsPaths } from '../../routing/settings/settingsPaths';

import styles from '../../pages/messageSettings/MessageSettings.module.scss';

const MessageSettingsNavbar = () => {
  const param = useQueryParam();
  const { t } = useTranslation();

  const data = [
    {
      icon: SmsTracking,
      text: t('message_settings.Xabarlar_yuborilishi'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.MESSAGE + messagePath.SENDING_MESSAGE,
      class: param.splitUrl[param.splitUrl.length - 1] === 'sending-message' ? `${styles.active}` : `${styles.navLinkClass}`
    },
    {
      icon: MessageText,
      text: t('message_settings.Xabar_shablonlari'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.MESSAGE + messagePath.TEMPLATES_MESSAGE,

      class: param.splitUrl[param.splitUrl.length - 1] === 'templates-message' ? `${styles.active}` : `${styles.navLinkClass}`
    },
    {
      icon: SMSStarIcon,
      text: t('message_settings.Xabarnoma_tizimi'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.MESSAGE + messagePath.MESSAGE_SYSTEM,
      class: param.splitUrl[param.splitUrl.length - 1] === 'message-system' ? `${styles.active}` : `${styles.navLinkClass}`
    }
  ];

  return (
    <div className={styles.navbar}>
      {data.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <NavLink key={index} to={item.path} className={item.class}>
          <span className="d-flex align-center">
            <item.icon /> &nbsp; &nbsp; {item.text}
          </span>
          <span className={styles.arrowDiv}>
            <ArrowRightMessage />
          </span>
        </NavLink>
      ))}
    </div>
  );
};

export default MessageSettingsNavbar;
