export interface GroupActions {
  leadIds: number[];
  requestMethodType: RequestMethodTypeGroupActions;
  responsibleEmployeeId?: number;
  pipeStatusId?: number;
  tagIds?: number[];
  leadTaskTypeId?: number;
  assignedById?: number;
  deadlineStart?: string;
  deadlineFinish?: string;
  text?: string;
  originator?: string;
  changeTaskResponsible?: boolean;
}

export enum RequestMethodTypeGroupActions {
  CHANGED_RESPONSIBLE_EMPLOYEE = 'CHANGED_RESPONSIBLE_EMPLOYEE',
  CHANGED_PIPE_STATUS = 'CHANGED_PIPE_STATUS ',
  CREATE_TASK_FOR_LEAD = 'CREATE_TASK_FOR_LEAD ',
  DELETE_LEAD = 'DELETE_LEAD ',
  ADDED_TAG_FOR_LEAD = 'ADDED_TAG_FOR_LEAD ',
  SEND_MESSAGE_USERS = 'SEND_MESSAGE_USERS ',
  SEND_SMS_USERS = 'SEND_SMS_USERS'
}
