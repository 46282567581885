import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, message, Modal, Tabs } from 'antd';
import styles from './createPayment.module.scss';
import CardPayment from '../../assets/icons/CardPayment';
import EmptyWalletTick from '../../assets/icons/EmptyWalletTick';
import SimplePaymentInputs from './SimplePaymentInputs';
import BalancePaymentInputs from './BalancePaymentInputs';
import { PaymentType } from '../../utils/models/PaymentType';
import { useCreatePayment, useDownloadPaymentToWord, usePrintCheckAuto } from '../../queries/mutation';
import { useQueryParam } from '../../hooks/useQueryParams';
import { CreatePaymentBody, PaymentReason } from '../../utils/models/CreatePaymentBody';
import { helper } from '../../utils/helper/helper';
import { useTranslation } from 'react-i18next';
import { usePaymentNumber } from '../../queries/queries';

const { TabPane } = Tabs;

interface Props {
  residue: number;
  visible: boolean;
  close: () => void;
  contractId: number;
}

export interface FormIPaymentCreate {
  amount: string;
  type: PaymentType;
  name: string;
  note: string;
  date: string;
  number: string;
}

const PaymentCreateModal: React.FC<Props> = ({ visible, close, residue, contractId }) => {
  const [form] = Form.useForm<FormIPaymentCreate>();
  const { params } = useQueryParam<{ id: string }, any>();
  const paymentReason = useRef(PaymentReason.CONTRACT);
  const clientId = useRef(-1);
  const [activeType, setActiveType] = useState<'simple' | 'balance'>('simple');

  const { data } = usePaymentNumber();
  const createPayment = useCreatePayment(params.id, handleClose);
  const downloadPayment = useDownloadPaymentToWord();
  const autoPrintCheck = usePrintCheckAuto();
  const { t } = useTranslation();

  useEffect(() => {
    if (data && visible) {
      form.setFieldsValue({
        number: data.number
      });
    }
  }, [data, form, visible]);

  const printAutoCheck = (id: number) => {
    const type: PaymentType = form.getFieldValue('type');
    if (type !== PaymentType.BANK) {
      autoPrintCheck.mutate({
        id,
        contractId
      });
    }
  };

  function handleClose(id?: number, number?: string) {
    setActiveType('simple');
    if (id && number) {
      downloadPayment.mutate({
        id,
        number,
        contractId
      });
      printAutoCheck(id);
    }
    form.setFieldsValue({
      date: '',
      note: undefined,
      amount: '',
      name: '',
      type: undefined,
      number: ''
    });
    paymentReason.current = PaymentReason.CONTRACT;
    close();
  }

  const handleChangeType = (activeType: string) => {
    form.resetFields();
    form.setFieldsValue({
      number: data?.number
    });
    clientId.current = -1;
    setActiveType(activeType as 'simple' | 'balance');
  };

  const setClientId = (id: number) => {
    clientId.current = id;
  };

  const getActivePage = () => {
    if (activeType === 'simple') return <SimplePaymentInputs residue={residue} form={form} />;
    else return <BalancePaymentInputs form={form} residue={residue} setClientId={setClientId} />;
  };

  const handleCreatePayment = async (reason: PaymentReason) => {
    paymentReason.current = reason;
    await form.validateFields();
    if (activeType === 'simple') {
      const createData: CreatePaymentBody = {
        amount: helper.parseFloatStringToNumber(form.getFieldValue('amount')),
        base: reason,
        type: form.getFieldValue('type') as PaymentType,
        clientId: null,
        note: form.getFieldValue('note'),
        date: form.getFieldValue('date') || undefined,
        currencyId: 0,
        number: form.getFieldValue('number') === data?.number ? null : form.getFieldValue('number')
      };
      createPayment.mutate(createData);
    } else {
      if (clientId.current === -1) {
        message.error(t('create_payment.Mijoz_tanlanmagan'));
        return;
      }
      const createData: CreatePaymentBody = {
        amount: helper.parseFloatStringToNumber(form.getFieldValue('amount')),
        base: reason,
        type: PaymentType.BALANCE,
        currencyId: 0,
        clientId: clientId.current,
        note: form.getFieldValue('note'),
        number: form.getFieldValue('number') === data?.number ? null : form.getFieldValue('number')
      };
      createPayment.mutate(createData);
    }
  };

  return (
    <Modal
      title={t("create_payment.To'lov_qilish")}
      className={styles.paymentCreateModal}
      visible={visible}
      width={432}
      onCancel={() => handleClose()}
      footer={null}
    >
      <div className={`d-flex align-center justify-space-between`}>
        <Tabs activeKey={activeType} onChange={handleChangeType} className="createPaymentTabs">
          <TabPane
            tab={
              <span>
                <span className={'icon'}>
                  <CardPayment />
                </span>
                <span className={'title'}>{t("create_payment.Odatiy_to'lov")}</span>
              </span>
            }
            key={'simple'}
          />
          <TabPane
            tab={
              <span>
                <span className={'icon'}>
                  <EmptyWalletTick />
                </span>
                <span className={'title'}>{t("create_payment.Balansdan_to'lash")}</span>
              </span>
            }
            key={'balance'}
          />
        </Tabs>
      </div>
      <div className={styles.body}>
        <Form
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          layout="vertical"
          className={styles.createOrderForm}
        >
          {getActivePage()}
          <Form.Item>
            <Form.Item noStyle name={'note'}>
              <Input.TextArea placeholder={t('flat_condition.Izoh_qoldirish')} autoSize={{ minRows: 4, maxRows: 4 }} className="customInput" />
            </Form.Item>
          </Form.Item>
          <Button
            loading={createPayment.isLoading && paymentReason.current === PaymentReason.CONTRACT}
            onClick={() => handleCreatePayment(PaymentReason.CONTRACT)}
            className={styles.body_pay_simple}
            type={'primary'}
          >
            {t("create_payment.To'lov_qilish")}
          </Button>
          <Button
            loading={createPayment.isLoading && paymentReason.current === PaymentReason.SURCHARGE}
            onClick={() => handleCreatePayment(PaymentReason.SURCHARGE)}
            className={styles.body_pay_surcharge}
            type={'primary'}
          >
            {t('create_payment.Peniya')}
          </Button>
          <Button onClick={() => handleClose()} className={styles.body_close_button} type={'primary'}>
            {t('create_payment.Yopish')}
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

export default PaymentCreateModal;
