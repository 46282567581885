// import PlusIcon from '@icons/PlusIcon';
import React from 'react';
import styles from './createShablon.module.scss';
import { Col, Form, FormInstance, Input, Row, Select } from 'antd';
import TrushIcon from '@icons/crmIcons/TrushIcon';
import { PipeTemplateModel } from '@pages/voronka-shablon/utils/models/PipeTemplateModel';
import PlusCircleIcon from '@icons/PlusCircleIcon';

type Props = {
  language: string;
  form: FormInstance<any>;
  data: PipeTemplateModel | undefined;
};

const InitialForm: React.FC<Props> = ({ language, form, data }) => {
  return (
    <div>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item label="Nomi" name="nameTop" rules={[{ required: true, message: '' }]}>
            <Input placeholder="Shablon nomi" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Shablon turi" name="type" rules={[{ required: true, message: '' }]}>
            <Select placeholder="Turini tanlang">
              <Select.Option key={'CALL_CENTER'} value={'CALL_CENTER'}>
                Call markaz
              </Select.Option>
              <Select.Option key={'SALES_DEPARTMENT'} value={'SALES_DEPARTMENT'}>
                Sotuv offisi
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.List name="items">
        {(fields, { add, remove }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 12 }}>
              {fields.map(field => {
                return (
                  <div key={field.key}>
                    <Row className="flex items-center" gutter={8}>
                      <Col span={23}>
                        <Form.Item label="Maqsad" name={[field.name, `name${language}`]} rules={[{ required: true, message: '' }]}>
                          <Input placeholder="Maqsad qo'shish" />
                        </Form.Item>
                      </Col>
                      {field?.name !== 0 && field?.name !== 1 && (
                        <Col span={1}>
                          <TrushIcon
                            className="mt-6 cursor-pointer"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      )}
                    </Row>
                    <Form.Item label="Bosqichlar">
                      <Form.List name={[field.name, 'list']}>
                        {(subFields, subOpt) => (
                          <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                            {subFields.map(subField => {
                              const status = data && form.getFieldsValue()?.items[field?.key]?.list[subField?.key]?.pipeStatusType;
                              const isDeleted =
                                form.getFieldsValue()?.items && !data && form.getFieldsValue()?.items[field?.key]?.list[subField?.key]?.isDelete;

                              return (
                                <Row key={subField.key} gutter={8}>
                                  <Col span={21}>
                                    <Form.Item noStyle name={[subField.name, `name${language}`]} rules={[{ required: true, message: '' }]}>
                                      <Input placeholder="Bosqich kiriting" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={2}>
                                    <Form.Item noStyle name={[subField.name, `color`]}>
                                      <Input type="color" style={{ padding: '2px 4px', cursor: 'pointer' }} />
                                    </Form.Item>
                                  </Col>
                                  {data
                                    ? status !== 'FIRST' &&
                                      status !== 'SUCCESS' &&
                                      status !== 'FAILED' && (
                                        <Col span={1} className="flex items-center">
                                          <TrushIcon
                                            className="cursor-pointer"
                                            onClick={() => {
                                              subOpt.remove(subField.name);
                                            }}
                                          />
                                        </Col>
                                      )
                                    : !isDeleted && (
                                        <Col span={1} className="flex items-center">
                                          <TrushIcon
                                            className="cursor-pointer"
                                            onClick={() => {
                                              subOpt.remove(subField.name);
                                            }}
                                          />
                                        </Col>
                                      )}
                                </Row>
                              );
                            })}
                            <div onClick={() => subOpt.add()} className={styles.addBtn}>
                              <PlusCircleIcon stroke="#1e90ff" />
                              <span>Bosqich qo‘shish</span>
                            </div>
                          </div>
                        )}
                      </Form.List>
                    </Form.Item>
                  </div>
                );
              })}

              <div onClick={add} className={styles.addBtn}>
                <PlusCircleIcon stroke="#1e90ff" />
                <span>Maqsad qo'shish</span>
              </div>
            </div>
          );
        }}
      </Form.List>
    </div>
  );
};

export default InitialForm;
